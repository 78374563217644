import * as React from 'react';
import { Redirect } from 'react-router';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';

/** GraphQL */
import { getUserDetails } from '../../graphql/custom-queries';

/** Context API */
import { UserSpaceContextConsumer } from '../UserSpaceContextProvider';

/**  Presentation UI */
import PageLoaderWrapper from '../../Components/Layouts/PageLoaderWrapper';

/** Local components */
import HeaderWrapper from './HeaderWrapper';

/** Styled elements */
import { Wrapper } from './StyledElements';
import { updateUser } from '../../graphql/mutations';
import { UpdateUserMutation, UpdateUserMutationVariables } from '../../API';

type Props = {
  routeUserGroups: Array<string>;
};

const Header: React.FC<Props> = ({ routeUserGroups }) => {
  return (
    <UserSpaceContextConsumer>
      {({
        userId,
        company,
        updateCompany,
        updateSpaceId,
        updateSpaceIds,
        updateUserPermission,
        updateSubscriptionPlan
      }) => {
        return (
          <Query
            query={getUserDetails}
            variables={{ id: userId }}
            fetchPolicy="cache-first"
          >
            {({ loading, error, data }) => {
              if (loading) {
                return (
                  <PageLoaderWrapper height="70px">
                    <Wrapper open={true} />
                  </PageLoaderWrapper>
                );
              }

              if (error || !data || !data.getUser) {
                return <div>There was a problem loading your user data</div>;
              }

              // Check if the company account has been disabled
              if (
                data.getUser &&
                data.getUser.company &&
                data.getUser.company.accountDisabled
              ) {
                window.localStorage.clear();
                return <Redirect to="/subscription-cancelled" />;
              }

              const user = data.getUser;

              return (
                <Mutation<UpdateUserMutation, UpdateUserMutationVariables>
                  mutation={gql(updateUser)}
                >
                  {updateUserMutation => (
                    <HeaderWrapper
                      company={company}
                      user={user}
                      updateCompany={updateCompany}
                      updateSpaceId={updateSpaceId}
                      updateSpaceIds={updateSpaceIds}
                      updateSubscriptionPlan={updateSubscriptionPlan}
                      updateUserPermission={updateUserPermission}
                      updateUserMutation={updateUserMutation}
                      routeUserGroups={routeUserGroups}
                    />
                  )}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </UserSpaceContextConsumer>
  );
};

export default Header;
