import * as React from 'react';
import { Query } from 'react-apollo';
import { Row, Col } from 'reactstrap';

/** GraphQL */
import { getCompanyProductItemsByUser } from '../../graphql/custom-queries';

/** Custom types */
import { FilterRangeType } from '../../CustomTypes';

/** Local components */
import ProductItemsWeekProgress from './ProductItemsWeekProgress';
import ProductItemsMonthProgress from './ProductItemsMonthProgress';
import ProductItemsYearProgress from './ProductItemsYearProgress';

/** Presentation/UI */
import {
  DashboardSummaryCardContainer,
  DashboardSummaryCardHeader,
  DashboardSummaryCardNumberContainer
} from '../../Components/Styled/Dashboard';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { returnProductItemsNotAssociatedWithBatchCard } from '../NewProductItems/NewProductItemsHelpers';
import { TIME_RANGE } from '../../Utils/Consts';

type Props = {
  currentMonth: string;
  currentYear: string;
  formattedDates: Array<string>;
  range: FilterRangeType;
  userId: string;
};

const ProductItems: React.FC<Props> = ({
  currentMonth,
  currentYear,
  range,
  userId,
  formattedDates
}) => {
  /** Return items card summary data based on selected range
   * @param itemStatus
   * @param range
   */
  const returnProductItemsCardSummaryBasedOnSelectedRange = (
    productItemStatus: 'complete' | 'incomplete',
    filterRange: FilterRangeType
  ): React.ReactNode => {
    switch (filterRange) {
      case TIME_RANGE.week:
        // Week
        return (
          <ProductItemsWeekProgress
            productItemStatus={productItemStatus}
            userId={userId}
            formattedDates={formattedDates}
          />
        );
      case TIME_RANGE.month:
        // Month
        return (
          <ProductItemsMonthProgress
            productItemStatus={productItemStatus}
            month={currentMonth}
            userId={userId}
          />
        );
      case TIME_RANGE.year:
        // Year
        return (
          <ProductItemsYearProgress
            productItemStatus={productItemStatus}
            year={currentYear}
            userId={userId}
          />
        );
      default:
        // Week
        return (
          <ProductItemsWeekProgress
            productItemStatus={productItemStatus}
            userId={userId}
            formattedDates={formattedDates}
          />
        );
    }
  };

  return (
    <Row>
      <Col xs={12} md={4} lg={4}>
        <DashboardSummaryCardContainer>
          <DashboardSummaryCardHeader>
            New Items in Sorting
          </DashboardSummaryCardHeader>
          <DashboardSummaryCardNumberContainer>
            <Query
              query={getCompanyProductItemsByUser}
              variables={{ id: userId }}
              fetchPolicy="cache-and-network"
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <Loader color={Colors.flumeGreen} />;
                }
                if (error) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                if (!data || !data.getUser) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                const company = data.getUser.company;

                if (
                  company.productItems &&
                  company.productItems.items &&
                  company.productItems.items.length
                ) {
                  const unbatchedProductItems = returnProductItemsNotAssociatedWithBatchCard(
                    company.productItems.items
                  ).length;

                  return <span>{unbatchedProductItems}</span>;
                }

                return <span>0</span>;
              }}
            </Query>
          </DashboardSummaryCardNumberContainer>
        </DashboardSummaryCardContainer>
      </Col>
      <Col xs={12} md={4} lg={4}>
        <DashboardSummaryCardContainer>
          <DashboardSummaryCardHeader>
            Items In Production
          </DashboardSummaryCardHeader>
          <DashboardSummaryCardNumberContainer>
            {returnProductItemsCardSummaryBasedOnSelectedRange(
              'incomplete',
              range
            )}
          </DashboardSummaryCardNumberContainer>
        </DashboardSummaryCardContainer>
      </Col>
      <Col xs={12} md={4} lg={4}>
        <DashboardSummaryCardContainer>
          <DashboardSummaryCardHeader>
            Items Completed
          </DashboardSummaryCardHeader>
          <DashboardSummaryCardNumberContainer>
            {returnProductItemsCardSummaryBasedOnSelectedRange(
              'complete',
              range
            )}
          </DashboardSummaryCardNumberContainer>
        </DashboardSummaryCardContainer>
      </Col>
    </Row>
  );
};

export default ProductItems;
