import gql from 'graphql-tag';

/** Query to list user details */
export const getUserDetails = gql(`query GetUserDetails($id: ID!) {
  getUser(id: $id) {
    id
    avatarUrl
    avatarKey
    email
    firstName
    lastName
    invitedBy
    inviteAccepted
    status
    userRole {
      id
      name
      permission
    }
    company {
      id
      name
      accountDisabled
      address
      state
      country
      industry
      companySize
      subscriptionType
      roles (
        limit: 100
      ){
        items {
          id
        }
      }
      services (
        limit: 100
      ){
        items {
          id
        }
      }
      spaces (
        limit: 100
      ){
        items {
          id
        }
      }
      brands (
        limit: 100
      ){
        items {
          id
          subBrands (
            limit: 100
          ) {
            items {
              id
            }
          }
        }
      }
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        clientName
        serviceName
        quantity
        unit
      }
    }
  }
}
`);

/** Query to list company details */
export const getCompanyByUser = gql(`query GetCompanyByUser($id: ID!) {
    getUser(id: $id) {
      id
      company {
        id
        name
        address
        state
        country
        industry
        companySize
        itemTemplate {
          id
          uic
          brand
          subBrand
          category
          subCategory
          type
          gender
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
        }
      }
    }
  }
  `);

/** Query to list a batch card, its services and the company spaces */
export const getBatchCardServicesAndCompanySpaces = gql(`query GetBatchCardServicesAndCompanySpaces($batchCardId: ID!, $userId: ID!) {
  getUser(id: $userId) {
    id
    company {
      id
      spaces(
        limit: 1000
      ) {
        items {
          id
          name
          colorCode
          status
        }
        nextToken
      }
      batchCards(
        limit: 50,
        filter: {
          id: {
            eq: $batchCardId
          }
        }
      ) {
        items {
          id
          name
          description
          inBacklog
          services {
            items {
              id
              service {
                id
                name
                icon
              }
            }
          }
        }
        nextToken
      }
    }
  }
}`);

/** Query to list company roles, users and space */
export const getCompanyRolesUsersAndSpaceByUser = gql(`query GetCompanyRolesUsersAndSpaceByUser($spaceId: ID!, $userId: ID!) {
  getUser(id: $userId) {
    id
    company {
      id
      roles (
        limit: 100
      ){
        items {
          id
          name
          permission
        }
        nextToken
      }
      users (
        limit: 1000,
        filter: {
          id: {
            ne: $userId
          }
        }
      ) {
        items {
          id
          firstName
          lastName
          email
          phoneNumber
          avatarKey
          avatarUrl
          archived
          verified
          status
          userRole {
            id
            name
            permission
          }
        }
        nextToken
      }
      spaces(
        limit: 100,
        filter: {
          id: {
            eq: $spaceId
          }
        }
      ) {
        items {
          id
          name
          colorCode
          status
          spaceAllocation {
            items {
              id
              date
              user {
                  id
                  firstName
                  lastName
                  email
                  status
                  userRole {
                    id
                    name
                    permission
                  }
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
}`);

/** Query to get company batch card */
export const getCompanyBatchCardByUser = gql(`query GetCompanyBatchCardByUser($userId: ID!, $batchCardId: ID!) {
  getUser(id: $userId) {
    id
    company {
      id
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        clientName
        serviceName
        quantity
        unit
      }
      batchCards(
        limit: 100,
        filter: {
          id: {
            eq: $batchCardId
          }
        }
      ) {
        items {
          id
          name
          description
          images
          inBacklog
          batchCardAllocations (
            filter: {
              or: [{ obsolete: { ne: true } }, { obsolete: { eq: null } }]
            }
          ){
            items {
              id
              date
              obsolete
              batchCardServices{
                items {
                  id
                  isComplete
                  service {
                    id
                    icon
                    name
                  }
                }
              }
              space{
                id
                name
                colorCode
              }
            }
          }
          productItems(
            limit: 1000
          ) {
            items {
              id
              uic
              brand {
                id
                name
              }
              subBrand {
                id
                name
              }
              content {
                items {
                  id
                  header
                  content
                  createdAt
                }
              }
              category
              completedServices
              subCategory
              type
              gender
              age
              size
              look
              other1
              other2
              other3
              season
              collectionName
              productName
              clientName
              serviceName
              quantity
              unit
              complete
              imageKeys
              qcApproved
              previousTagDescriptions
              notes {
                items {
                  id 
                  note
                  timestamp
                  author {
                    id
                    firstName
                    lastName
                    email
                  }
                }
              }
            }
            nextToken
          }
          services (
            limit: 100
          ){
            items {
              service {
                id
                name
                icon
              }
            }
          }
          spaces (
            limit: 100
          ){
            items {
              space {
                id
                name
                colorCode
              }
            }
          }
        }
        nextToken
      }
    }
  }
}`);

/** Query to list unallocated company batch cards */
export const getCompanyBatchCardsByUser = gql(`query GetCompanyBatchCardsByUser($id: ID!) {
  getUser(id: $id) {
    id
    company {
      id
      services {
				items {
					id
          name
          icon
        }
        nextToken
      }
      spaces {
        items {
          id
          name
          colorCode
          status
          spaceAllocation {
          items {
            id
            date
            user {
              id
              firstName
              lastName
              email
              status
              userRole {
                id
                name
                permission
              }
            }
          }
          nextToken
        }
        }
        nextToken
      }
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        clientName
        serviceName
        quantity
        unit
      }
      batchCards(
        limit: 100,
        filter: {
          inBacklog: {
            eq: true
          }
        }
      ) {
        items {
          id
          createdAt
          name
          description
          images
          inBacklog
          batchCardAllocations (
            limit: 100,
            filter: {
              or: [{ obsolete: { ne: true } }, { obsolete: { eq: null } }]
            }
          ){
            items {
              id
              date
              obsolete
              batchCard {
								id
              	name
                productItems {
                  items {
                    id
                    completedServices
                  }
                }
              }
              space {
                  id
                  name
                  colorCode
                  status
                  spaceAllocation {
                  items {
                    id
                    date
                    user {
                      id
                      firstName
                      lastName
                      email
                      status
                      userRole {
                        id
                        name
                        permission
                      }
                    }
                  }
                  nextToken
                }
              }
              batchCardServices {
                items {
                  id 
                  service {
                    id
                    icon
                    name
                  }
                }
              }
            }
          }
          productItems(limit: 1000) {
            items {
              id
              uic
              brand {
                id
                name
              }
              subBrand {
                id
                name
              }
              category
              subCategory
              type
              gender
              imageKeys
              age
              size
              look
              other1
              other2
              other3
              season
              collectionName
              productName
              clientName
              serviceName
              quantity
              unit
              complete
              previousTagDescriptions
              content {
                items {
                  id
                  header
                  content
                }
              }
            }
          }
          services {
            items {
              id
              service {
                id
                name
                icon
              }
            }
          }
          spaces {
            items {
              space {
                id
                name
                colorCode
              }
            }
          }
        }
        nextToken
      }
    }
  }
}`);

/** Query to get batch card allocation */
export const getBatchCardAllocationById = gql(`query GetBatchCardAllocationById($id: ID!) {
  getBatchCardAllocation(id: $id) {
    id
    date
    batchCard {
      id
      batchCardAllocations(
        limit: 50,
        filter: {
          obsolete: {
            ne: true
          }
        }
      ) {
				items {
          id
        }
        nextToken
      }
    }
  }
}`);

/** Query to list company batch card allocations */
export const getCompanyBatchCardAllocationsByUser = gql(`query GetCompanyBatchCardAllocationsByUser($id: ID!) {
  getUser(id: $id) {
    id
    company {
      id
      spaces (limit: 100) {
        items {
          id
          name
          colorCode
          status
          spaceAllocation {
          items {
            id
            date
            user {
              id
              firstName
              lastName
              email
              status
              userRole {
                id
                name
                permission
              }
            }
          }
          nextToken
        }
        }
        nextToken
      }
      batchCards(
        limit: 100,
        filter: {
          inBacklog: {
            eq: true
          }
        }
      ) {
        items {
          id
          name
          description
          inBacklog
          batchCardAllocations(
            limit: 100,
            filter: {
              or: [{ obsolete: { ne: true } }, { obsolete: { eq: null } }]
            }
          ) {
            items {
              id
              date
              obsolete
              batchCard {
								id
              	name
                productItems {
                  items {
                    id
                  }
                }
              }
              space {
                  id
                  name
                  colorCode
                  status
                  spaceAllocation {
                  items {
                    id
                    date
                    user {
                      id
                      firstName
                      lastName
                      email
                      status
                      userRole {
                        id
                        name
                        permission
                      }
                    }
                  }
                  nextToken
                }
              }
              batchCardServices {
                items {
                  id 
                  service {
                    id
                    icon
                    name
                  }
                }
              }
            }
          }
          productItems (
            limit: 100
          ){
            items {
              id
              uic
              brand {
                id
                name
              }
              subBrand {
                id
                name
              }
              category
              subCategory
              type
              gender
              age
              size
              look
              other1
              other2
              other3
              season
              collectionName
              productName
              clientName
              serviceName
              quantity
              unit
              complete
            }
          }
          services (
            limit: 100
          ){
            items {
              service {
                id
                name
                icon
              }
            }
          }
          spaces (
            limit: 100
          ){
            items {
              space {
                id
                name
                colorCode
              }
            }
          }
        }
        nextToken
      }
    }
  }
}`);

/** Query to list batch card allocations for the week on main planner */
export const getCompanyBatchCardsByUserMainPlanner = gql(`query GetCompanyBatchCardsByUserMainPlanner(
  $id: ID!, 
  $dayOne: String,
  $dayTwo: String,
  $dayThree: String,
  $dayFour: String,
  $dayFive: String,
  $daySix: String,
  $daySeven: String
) {
  getUser(id: $id) {
    id
    company {
      id
      services {
				items {
					id
          name
          icon
        }
        nextToken
      }
      spaces {
        items {
          id
          name
          colorCode
          status
          spaceAllocation {
          items {
            id
            date
            user {
              id
              firstName
              lastName
              email
              status
              userRole {
                id
                name
                permission
              }
            }
          }
          nextToken
        }
        }
        nextToken
      }
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        clientName
        serviceName
        quantity
        unit
      }
      batchCards(
        limit: 100
      ) {
        items {
          id
          createdAt
          name
          description
          images
          inBacklog
          batchCardAllocations (
            limit: 100,
            filter: {
              and: [
                {
                  or: [{ obsolete: { ne: true } }, { obsolete: { eq: null } }]
                }
                {
                  or: [
                    { date: { eq: $dayOne } }
                    { date: { eq: $dayTwo } }
                    { date: { eq: $dayThree } }
                    { date: { eq: $dayFour } }
                    { date: { eq: $dayFive } }
                    { date: { eq: $daySix } }
                    { date: { eq: $daySeven } }
                  ]
                }
              ]
            }
          ){
            items {
              id
              date
              obsolete
              batchCard {
								id
              	name
                productItems (
                  limit: 1000
                ){
                  items {
                    id
                    complete
                    completedServices
                  }
                }
              }
              batchCardServices{
                items {
                  id
                  isComplete
                  service {
                    id
                    icon
                    name
                  }
                }
              }
              space {
                  id
                  name
                  colorCode
                  status
                  spaceAllocation {
                  items {
                    id
                    date
                    user {
                      id
                      firstName
                      lastName
                      email
                      status
                      userRole {
                        id
                        name
                        permission
                      }
                    }
                  }
                  nextToken
                }
              }
            }
          }
          productItems (
            limit: 1000,
            filter: {
              complete: {
                ne: true
              }
            }
          ) {
            items {
              id
              uic
              brand {
                id
                name
              }
              subBrand {
                id
                name
              }
              category
              subCategory
              type
              gender
              imageKeys
              age
              size
              look
              other1
              other2
              other3
              season
              collectionName
              productName
              clientName
              serviceName
              quantity
              unit
              complete
              content (
                limit: 100
              ) {
                items {
                  id
                  header
                  content
                }
              }
            }
          }
          services (
            limit: 100
          ) {
            items {
              id
              service {
                id
                name
                icon
              }
            }
          }
          spaces (
            limit: 100
          ) {
            items {
              space {
                id
                name
                colorCode
              }
            }
          }
        }
        nextToken
      }
    }
  }
}`);

/** Query to list company batch card allocations in QC */
export const getCompanyBatchCardAllocationsForTheWeekByUser = gql(`query GetCompanyBatchCardAllocationsForTheWeekByUser(
  $id: ID!, 
  $dayOne: String,
  $dayTwo: String,
  $dayThree: String,
  $dayFour: String,
  $dayFive: String,
  $daySix: String,
  $daySeven: String
) {
  getUser(id: $id) {
    id
    company {
      id
      spaces {
        items {
          id
          name
          colorCode
          status
          spaceAllocation {
          items {
            id
            date
            user {
              id
              firstName
              lastName
              email
              status
              userRole {
                id
                name
                permission
              }
            }
          }
          nextToken
        }
        }
        nextToken
      }
      batchCards(
        limit: 50
      ) {
        items {
          id
          name
          description
          inBacklog
          services {
            items {
              id
              isComplete
            }
          }
          batchCardAllocations(
            limit: 100,
            filter: {
              or: [
                { date: { eq: $dayOne } },
                { date: { eq: $dayTwo } },
                { date: { eq: $dayThree } },
                { date: { eq: $dayFour } },
                { date: { eq: $dayFive } },
                { date: { eq: $daySix } },
                { date: { eq: $daySeven } }
              ] 
            }
          ) {
            items {
              id
              date
              obsolete
              batchCard {
								id
                name
                productItems(limit: 1000) {
                  items {
                    id
                    complete
                    qcApproved
                  }
                }
              }
              space {
                  id
                  name
                  colorCode
                  status
                  spaceAllocation {
                  items {
                    id
                    date
                    user {
                      id
                      firstName
                      lastName
                      email
                      status
                      userRole {
                        id
                        name
                        permission
                      }
                    }
                  }
                  nextToken
              }
            }
              batchCardServices {
                items {
                  id 
                  service {
                    id
                    icon
                    name
                  }
                }
              }
            }
          }
          productItems(limit: 1000) {
            items {
              id
              uic
              brand {
                id
                name
              }
              subBrand {
                id
                name
              }
              category
              subCategory
              type
              gender
              age
              size
              look
              other1
              other2
              other3
              season
              collectionName
              productName
              clientName
              serviceName
              quantity
              unit
              complete
            }
          }
          services {
            items {
              service {
                id
                name
                icon
              }
            }
          }
          spaces {
            items {
              space {
                id
                name
                colorCode
              }
            }
          }
        }
        nextToken
      }
    }
  }
}`);

/** Query to list company batch card allocations */
export const getCompanyBatchCardAllocationsForTheMonthByUser = gql(`query GetCompanyBatchCardAllocationsForTheMonthByUser(
  $id: ID!, 
  $month: String
) {
  getUser(id: $id) {
    id
    company {
      id
      spaces {
        items {
          id
          name
          colorCode
          status
          spaceAllocation {
            items {
              id
              date
              user {
                id
                firstName
                lastName
                email
                status
                userRole {
                  id
                  name
                  permission
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }
      batchCards(
        limit: 50
      ) {
        items {
          id
          name
          description
          inBacklog
          services {
            items {
              id
              isComplete
            }
          }
          batchCardAllocations(
            limit: 100,
            filter: { 
              date: {
								contains: $month
              }
            }
          ) {
            items {
              id
              date
              obsolete
              batchCard {
								id
                name
                productItems {
                  items {
                    id
                    complete
                  }
                }
              }
              space {
                id
                colorCode
                name
                spaceAllocation {
                  items {
                    id
                    date
                    user {
                      id
                      firstName
                      lastName
                      email
                      status
                      userRole {
                        id
                        name
                        permission
                      }
                    }
                  }
                  nextToken
                }
              }
              batchCardServices {
                items {
                  id 
                  service {
                    id
                    icon
                    name
                  }
                }
              }
            }
          }
          productItems {
            items {
              id
              uic
              brand {
                id
                name
              }
              subBrand {
                id
                name
              }
              category
              subCategory
              type
              gender
              age
              size
              look
              other1
              other2
              other3
              season
              collectionName
              productName
              clientName
              serviceName
              quantity
              unit
              complete
            }
          }
          services {
            items {
              service {
                id
                name
                icon
              }
            }
          }
          spaces {
            items {
              space {
                id
                name
                colorCode
              }
            }
          }
        }
        nextToken
      }
    }
  }
}`);

/** Query to list company batch card allocations */
export const getCompanyBatchCardAllocationsForTheYearByUser = gql(`query GetCompanyBatchCardAllocationsForTheYearByUser(
  $id: ID!, 
  $year: String
) {
  getUser(id: $id) {
    id
    company {
      id
      spaces {
        items {
          id
          name
          colorCode
          status
          spaceAllocation {
            items {
              id
              date
              user {
                id
                firstName
                lastName
                email
                status
                userRole {
                  id
                  name
                  permission
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }
      batchCards(
        limit: 50
      ) {
        items {
          id
          name
          description
          inBacklog
          services {
            items {
              id
              isComplete
            }
          }
          batchCardAllocations(
            limit: 100,
            filter: { 
              date: {
								contains: $year
              }
            }
          ) {
            items {
              id
              date
              obsolete
              batchCard {
								id
                name
                productItems {
                  items {
                    id
                    complete
                  }
                }
              }
              space {
                id
                colorCode
                name
                spaceAllocation {
                  items {
                    id
                    date
                    user {
                      id
                      firstName
                      lastName
                      email
                      status
                      userRole {
                        id
                        name
                        permission
                      }
                    }
                  }
                  nextToken
                }
              }
              batchCardServices {
                items {
                  id 
                  service {
                    id
                    icon
                    name
                  }
                }
              }
            }
          }
          productItems {
            items {
              id
              uic
              brand {
                id
                name
              }
              subBrand {
                id
                name
              }
              category
              subCategory
              type
              gender
              age
              size
              look
              other1
              other2
              other3
              season
              collectionName
              productName
              clientName
              serviceName
              quantity
              unit
              complete
            }
          }
          services {
            items {
              service {
                id
                name
                icon
              }
            }
          }
          spaces {
            items {
              space {
                id
                name
                colorCode
              }
            }
          }
        }
        nextToken
      }
    }
  }
}`);

/** Query to list company product items */
export const getCompanyProductItemsByUser = gql(`query getCompanyProductItemsByUser($id: ID!) {
    getUser(id: $id) {
      id
      company {
        id
        brands (
          limit: 100
        ){
          items {
            id
            name
            subBrands (
              limit: 100
            ){
              items {
                id
                name
              }
            }
          }
          nextToken
        }
        spaces (
          limit: 100
        ){
          items {
            id
            name
            colorCode
            status
          }
          nextToken
        }
        services (
          limit: 100
        ){
          items {
            id
            name
            icon
          }
          nextToken
        }
        itemTemplate {
          id
          uic
          brand
          subBrand
          category
          subCategory
          type
          gender
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
        }
        productItems(
          limit: 1000,
          filter: {
            or: [{ tagged: { ne: true } }, { returnedToItems: { ne: false } }]
          }
        ) {
          items {
            id
            createdAt
            uic
            brand {
              id
              name
            }
            subBrand {
              id
              name
            }
            category
            subCategory
            type
            tagged
            tagDescription
            previousTagDescriptions
            gender
            age
            size
            look
            other1
            other2
            other3
            season
            collectionName
            productName
            clientName
            serviceName
            quantity
            unit
            complete
            returnedToItems
            batchCard {
              id
              createdAt
              name
              description
              
            }
            content {
              items {
                id
                header
                content
              }
            }
          }
          nextToken
        }
      }
    }
  }
  `);

export const getCompanyProductItemsForTheWeekByUser = gql(`query GetCompanyProductItemsForTheWeekByUser(
    $id: ID!
    $dayOne: String
    $dayTwo: String
    $dayThree: String
    $dayFour: String
    $dayFive: String
    $daySix: String
    $daySeven: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        brands {
          items {
            id
            name
            subBrands {
              items {
                id
                name
              }
            }
          }
          nextToken
        }
        spaces {
          items {
            id
            name
            colorCode
            status
          }
          nextToken
        }
        services {
          items {
            id
            name
            icon
          }
          nextToken
        }
        itemTemplate {
          id
          uic
          brand
          subBrand
          category
          subCategory
          type
          gender
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
        }
        productItems(
          limit: 100
          filter: {
            and: [
              { tagged: { ne: true } }
              {
                or: [
                  { createdAt: { contains: $dayOne } }
                  { createdAt: { contains: $dayTwo } }
                  { createdAt: { contains: $dayThree } }
                  { createdAt: { contains: $dayFour } }
                  { createdAt: { contains: $dayFive } }
                  { createdAt: { contains: $daySix } }
                  { createdAt: { contains: $daySeven } }
                ]
              }
            ]
          }
        ) {
          items {
            id
            createdAt
            uic
            brand {
              id
              name
            }
            subBrand {
              id
              name
            }
            category
            subCategory
            type
            tagged
            tagDescription
            previousTagDescriptions
            gender
            age
            size
            look
            other1
            other2
            other3
            season
            collectionName
            productName
            clientName
            serviceName
            quantity
            unit
            complete
            batchCard {
              id
              createdAt
              name
              description
            }
            content {
              items {
                id
                header
                content
              }
            }
          }
          nextToken
        }
      }
    }
  }
`);

export const listProductItemsCreatedInSelectedWeek = gql(`query ListProductItemsCreatedInSelectedWeek(
  $dayOne: String
  $dayTwo: String
  $dayThree: String
  $dayFour: String
  $dayFive: String
  $daySix: String
  $daySeven: String
) {
  listProductItems(
    limit: 1000,
    filter: {
      or: [
        { createdAt: { contains: $dayOne } }
        { createdAt: { contains: $dayTwo } }
        { createdAt: { contains: $dayThree } }
        { createdAt: { contains: $dayFour } }
        { createdAt: { contains: $dayFive } }
        { createdAt: { contains: $daySix } }
        { createdAt: { contains: $daySeven } }
      ]
    }
  ) {
    items {
      id
      createdAt
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      season
      collectionName
      productName
      clientName
      serviceName
      quantity
      unit
      uic
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company{
        id
        name
      }
    }
  }
}
`);

export const listProductItemsCreatedInSelectedMonth = gql(`query ListProductItemsCreatedInSelectedMonth(
  $month: String
) {
  listProductItems(
    limit: 1000,
    filter: {
      or: [
        { createdAt: { contains: $month } }
      ]
    }
  ) {
    items {
      id
      createdAt
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      season
      collectionName
      productName
      clientName
      serviceName
      quantity
      unit
      uic
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        name
      }
    }
  }
}
`);

export const listProductItemsCreatedInSelectedYear = gql(`query ListProductItemsCreatedInSelectedYear(
  $year: String
) {
  listProductItems(
    limit: 1000,
    filter: {
      or: [
        { createdAt: { contains: $year } }
      ]
    }
  ) {
    items {
      id
      createdAt
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      season
      collectionName
      productName
      clientName
      serviceName
      quantity
      unit
      uic
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        name
      }
    }
  }
}
`);

export const listSpacesCreatedInSelectedWeek = gql(`query ListSpacesCreatedInSelectedWeek(
  $dayOne: String
  $dayTwo: String
  $dayThree: String
  $dayFour: String
  $dayFive: String
  $daySix: String
  $daySeven: String
) {
  listSpaces(
    limit: 1000,
    filter: {
      or: [
        { createdAt: { contains: $dayOne } }
        { createdAt: { contains: $dayTwo } }
        { createdAt: { contains: $dayThree } }
        { createdAt: { contains: $dayFour } }
        { createdAt: { contains: $dayFive } }
        { createdAt: { contains: $daySix } }
        { createdAt: { contains: $daySeven } }
      ]
    }
  ) {
    items {
      id
      createdAt
      colorCode
      name
      status
      company {
        id
        name
      }
    }
  }
}
`);

export const listSpacesCreatedInSelectedMonth = gql(`query ListSpacesCreatedInSelectedMonth(
  $month: String
) {
  listSpaces(
    limit: 1000,
    filter: {
      or: [
        { createdAt: { contains: $month } }
      ]
    }
  ) {
    items {
      id
      createdAt
      colorCode
      name
      status
      company {
        id
        name
      }
    }
  }
}
`);

export const listSpacesCreatedInSelectedYear = gql(`query ListSpacesCreatedInSelectedYear(
  $year: String
) {
  listSpaces(
    limit: 1000,
    filter: {
      or: [
        { createdAt: { contains: $year } }
      ]
    }
  ) {
    items {
      id
      createdAt
      colorCode
      name
      status
      company {
        id
        name
      }
    }
  }
}
`);

export const listUsersCreatedInSelectedWeek = gql(`query ListUsersCreatedInSelectedWeek(
  $dayOne: String
  $dayTwo: String
  $dayThree: String
  $dayFour: String
  $dayFive: String
  $daySix: String
  $daySeven: String
) {
  listUsers(
    limit: 1000,
    filter: {
      or: [
        { createdAt: { contains: $dayOne } }
        { createdAt: { contains: $dayTwo } }
        { createdAt: { contains: $dayThree } }
        { createdAt: { contains: $dayFour } }
        { createdAt: { contains: $dayFive } }
        { createdAt: { contains: $daySix } }
        { createdAt: { contains: $daySeven } }
      ]
    }
  ) {
    items {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      company {
        id
        name
      }
      status
    }
  }
}
`);

export const listUsersCreatedInSelectedMonth = gql(`query ListUsersCreatedInSelectedMonth(
  $month: String
) {
  listUsers(
    limit: 1000,
    filter: {
      or: [
        { createdAt: { contains: $month } }
      ]
    }
  ) {
    items {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      company {
        id
        name
      }
      status
    }
  }
}
`);

export const listUsersCreatedInSelectedYear = gql(`query ListUsersCreatedInSelectedYear(
  $year: String
) {
  listUsers(
    limit: 1000,
    filter: {
      or: [
        { createdAt: { contains: $year } }
      ]
    }
  ) {
    items {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      company {
        id
        name
      }
      status
    }
  }
}
`);

export const listCompaniesCreatedInSelectedWeek = gql(`query ListCompaniesCreatedInSelectedWeek(
  $dayOne: String
  $dayTwo: String
  $dayThree: String
  $dayFour: String
  $dayFive: String
  $daySix: String
  $daySeven: String
) {
  listCompanys(
    limit: 1000,
    filter: {
      or: [
        { createdAt: { contains: $dayOne } }
        { createdAt: { contains: $dayTwo } }
        { createdAt: { contains: $dayThree } }
        { createdAt: { contains: $dayFour } }
        { createdAt: { contains: $dayFive } }
        { createdAt: { contains: $daySix } }
        { createdAt: { contains: $daySeven } }
      ]
    }
  ) {
    items {
      id
      createdAt
      name 
      address
      state
      industry
      country
      companySize
      subscriptionType
      users {
        items {
          id
        }
      }
    }
  }
}
`);

export const listCompaniesCreatedInSelectedMonth = gql(`query ListCompaniesCreatedInSelectedMonth(
  $month: String
  ) {
    listCompanys(
      limit: 1000
      filter: { or: [{ createdAt: { contains: $month } }] }
    ) {
      items {
        id
        createdAt
        name 
        address
        state
        industry
        country
        companySize
        subscriptionType
        users {
          items {
            id
          }
        }
      }
    }
  }
`);

export const listCompaniesCreatedInSelectedYear = gql(`query ListCompaniesCreatedInSelectedYear(
  $year: String
  ) {
    listCompanys(
      limit: 1000
      filter: { or: [{ createdAt: { contains: $year } }] }
    ) {
      items {
        id
        createdAt
        name 
        address
        state
        industry
        country
        companySize
        subscriptionType
        users {
          items {
            id
            email
          }
        }
      }
    }
  }
`);

export const getCompanyProductItemsForTheMonthByUser = gql(`query GetCompanyProductItemsForTheMonthByUser($id: ID!, $month: String) {
  getUser(id: $id) {
    id
    company {
      id
      brands {
        items {
          id
          name
          subBrands {
            items {
              id
              name
            }
          }
        }
        nextToken
      }
      spaces {
        items {
          id
          name
          colorCode
          status
        }
        nextToken
      }
      services {
        items {
          id
          name
          icon
        }
        nextToken
      }
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        clientName
        serviceName
        quantity
        unit
      }
      productItems(
        limit: 100
        filter: {
          and: [{ tagged: { ne: true } }, { createdAt: { contains: $month } }]
        }
      ) {
        items {
          id
          createdAt
          uic
          brand {
            id
            name
          }
          subBrand {
            id
            name
          }
          category
          subCategory
          type
          tagged
          tagDescription
          previousTagDescriptions
          gender
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
          complete
          batchCard {
            id
            createdAt
            name
            description
          }
          content {
            items {
              id
              header
              content
            }
          }
        }
        nextToken
      }
    }
  }
}

`);

export const getAllCompanyProductItemsForTheWeekByUser = gql(`query GetAllCompanyProductItemsForTheWeekByUser(
    $id: ID!
    $dayOne: String
    $dayTwo: String
    $dayThree: String
    $dayFour: String
    $dayFive: String
    $daySix: String
    $daySeven: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        brands {
          items {
            id
            name
            subBrands {
              items {
                id
                name
              }
            }
          }
          nextToken
        }
        spaces {
          items {
            id
            name
            colorCode
            status
          }
          nextToken
        }
        services {
          items {
            id
            name
            icon
          }
          nextToken
        }
        itemTemplate {
          id
          uic
          brand
          subBrand
          category
          subCategory
          type
          gender
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
        }
        productItems(
          limit: 100
          filter: {
            or: [
              { createdAt: { contains: $dayOne } }
              { createdAt: { contains: $dayTwo } }
              { createdAt: { contains: $dayThree } }
              { createdAt: { contains: $dayFour } }
              { createdAt: { contains: $dayFive } }
              { createdAt: { contains: $daySix } }
              { createdAt: { contains: $daySeven } }
            ]
          }
        ) {
          items {
            id
            createdAt
            uic
            brand {
              id
              name
            }
            subBrand {
              id
              name
            }
            category
            subCategory
            type
            tagged
            tagDescription
            previousTagDescriptions
            gender
            age
            size
            look
            other1
            other2
            other3
            season
            collectionName
            productName
            clientName
            serviceName
            quantity
            unit
            complete
            batchCard {
              id
              createdAt
              name
              description
            }
            content {
              items {
                id
                header
                content
              }
            }
          }
          nextToken
        }
      }
    }
  }
`);

export const getAllCompanyProductItemsForTheMonthByUser = gql(`query GetAllCompanyProductItemsForTheMonthByUser(
    $id: ID!
    $month: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        brands {
          items {
            id
            name
            subBrands {
              items {
                id
                name
              }
            }
          }
          nextToken
        }
        spaces {
          items {
            id
            name
            colorCode
            status
          }
          nextToken
        }
        services {
          items {
            id
            name
            icon
          }
          nextToken
        }
        itemTemplate {
          id
          uic
          brand
          subBrand
          category
          subCategory
          type
          gender
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
        }
        productItems(
          limit: 100
          filter: { createdAt: { contains: $month } }
        ) {
          items {
            id
            createdAt
            uic
            brand {
              id
              name
            }
            subBrand {
              id
              name
            }
            category
            subCategory
            type
            tagged
            tagDescription
            previousTagDescriptions
            gender
            age
            size
            look
            other1
            other2
            other3
            season
            collectionName
            productName
            clientName
            serviceName
            quantity
            unit
            complete
            batchCard {
              id
              createdAt
              name
              description
            }
            content {
              items {
                id
                header
                content
              }
            }
          }
          nextToken
        }
      }
    }
  }
`);

/** Query to list tagged company product items */
export const getCompanyTaggedProductItemsByUser = gql(`query getCompanyTaggedProductItemsByUser($id: ID!) {
  getUser(id: $id) {
    id
    company {
      id
      brands {
        items {
          id
          name
          subBrands {
            items {
              id
              name
            }
          }
        }
        nextToken
      }
      spaces {
        items {
          id
          name
          colorCode
          status
        }
        nextToken
      }
      services {
        items {
          id
          name
          icon
        }
        nextToken
      }
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        clientName
        serviceName
        quantity
        unit
      }
      productItems(
        limit: 1000,
        filter: {
          tagged: {
            eq: true
          }
        }
      ) {
        items {
          id
          createdAt
          uic
          brand {
            id
            name
          }
          subBrand {
            id
            name
          }
          imageKeys
          category
          completedServices
          subCategory
          tagged
          taggedAt
          tagDescription
          previousTagDescriptions
          type
          gender
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
          complete
          qcApproved
          returnedToItems
          notes {
            items {
              id
              note
              timestamp
              author {
                id
                firstName
                lastName
                email
              }
            }
            nextToken
          }
          batchCard {
            id
            name
            description
            createdAt
          }
          content {
            items {
              id
              header
              content
              createdAt
            }
          }
        }
        nextToken
      }
    }
  }
}
`);

/** query to get tagged company product items for specified week */
export const getTaggedCompanyProductItemsForTheWeekByUser = gql(`query GetTaggedCompanyProductItemsForTheWeekByUser(
    $id: ID!
    $dayOne: String
    $dayTwo: String
    $dayThree: String
    $dayFour: String
    $dayFive: String
    $daySix: String
    $daySeven: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        brands {
          items {
            id
            name
            subBrands {
              items {
                id
                name
              }
            }
          }
          nextToken
        }
        spaces {
          items {
            id
            name
            colorCode
            status
          }
          nextToken
        }
        services {
          items {
            id
            name
            icon
          }
          nextToken
        }
        itemTemplate {
          id
          uic
          brand
          subBrand
          category
          subCategory
          type
          gender
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
        }
        productItems(
          limit: 50
          filter: {
            and: [
              { tagged: { eq: true } }
              {
                or: [
                  { taggedAt: { contains: $dayOne } }
                  { taggedAt: { contains: $dayTwo } }
                  { taggedAt: { contains: $dayThree } }
                  { taggedAt: { contains: $dayFour } }
                  { taggedAt: { contains: $dayFive } }
                  { taggedAt: { contains: $daySix } }
                  { taggedAt: { contains: $daySeven } }
                ]
              }
            ]
          }
        ) {
          items {
            id
            createdAt
            uic
            brand {
              id
              name
            }
            subBrand {
              id
              name
            }
            category
            subCategory
            type
            tagged
            taggedAt
            tagDescription
            previousTagDescriptions
            gender
            age
            size
            look
            other1
            other2
            other3
            season
            collectionName
            productName
            clientName
            serviceName
            quantity
            unit
            complete
            batchCard {
              id
              createdAt
              name
              description
            }
            content {
              items {
                id
                header
                content
              }
            }
          }
          nextToken
        }
      }
    }
  }
`);

/** query to get tagged company product items for specified month */
export const getTaggedCompanyProductItemsForTheMonthByUser = gql(`query GetTaggedCompanyProductItemsForTheMonthByUser(
    $id: ID!
    $month: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        brands {
          items {
            id
            name
            subBrands {
              items {
                id
                name
              }
            }
          }
          nextToken
        }
        spaces {
          items {
            id
            name
            colorCode
            status
          }
          nextToken
        }
        services {
          items {
            id
            name
            icon
          }
          nextToken
        }
        itemTemplate {
          id
          uic
          brand
          subBrand
          category
          subCategory
          type
          gender
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
        }
        productItems(
          limit: 50
          filter: {
            and: [
              { tagged: { eq: true } }
              { taggedAt: { contains: $month } }
            ]
          }
        ) {
          items {
            id
            createdAt
            uic
            brand {
              id
              name
            }
            subBrand {
              id
              name
            }
            category
            subCategory
            type
            tagged
            taggedAt
            tagDescription
            previousTagDescriptions
            gender
            age
            size
            look
            other1
            other2
            other3
            season
            collectionName
            productName
            clientName
            serviceName
            quantity
            unit
            complete
            batchCard {
              id
              createdAt
              name
              description
            }
            content {
              items {
                id
                header
                content
              }
            }
          }
          nextToken
        }
      }
    }
  }
`);

/** query to get tagged company product items for specified week */
export const getCompanyTaggedProductItemsForTheWeekByUser = gql(`query GetCompanyTaggedProductItemsForTheWeekByUser(
    $id: ID!
    $dayOne: String
    $dayTwo: String
    $dayThree: String
    $dayFour: String
    $dayFive: String
    $daySix: String
    $daySeven: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        brands {
          items {
            id
            name
            subBrands {
              items {
                id
                name
              }
            }
          }
          nextToken
        }
        spaces {
          items {
            id
            name
            colorCode
            status
          }
          nextToken
        }
        services {
          items {
            id
            name
            icon
          }
          nextToken
        }
        itemTemplate {
          id
          uic
          brand
          subBrand
          category
          subCategory
          type
          gender
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
        }
        productItems(
          limit: 50
          filter: {
            and: [
              { tagged: { eq: true } }
              {
                or: [
                  { createdAt: { eq: $dayOne } }
                  { createdAt: { eq: $dayTwo } }
                  { createdAt: { eq: $dayThree } }
                  { createdAt: { eq: $dayFour } }
                  { createdAt: { eq: $dayFive } }
                  { createdAt: { eq: $daySix } }
                  { createdAt: { eq: $daySeven } }
                ]
              }
            ]
          }
        ) {
          items {
            id
            createdAt
            uic
            brand {
              id
              name
            }
            subBrand {
              id
              name
            }
            category
            subCategory
            type
            tagged
            taggedAt
            tagDescription
            previousTagDescriptions
            gender
            age
            size
            look
            other1
            other2
            other3
            season
            collectionName
            productName
            clientName
            serviceName
            quantity
            unit
            complete
            batchCard {
              id
              createdAt
              name
              description
            }
            content {
              items {
                id
                header
                content
              }
            }
          }
          nextToken
        }
      }
    }
  }
`);

/** Query to list company product items */
export const getCompanyCompletedProductItemsByUser = gql(`query getCompanyCompletedProductItemsByUser($id: ID!) {
  getUser(id: $id) {
    id
    company {
      id
      brands (
        limit: 100
      ){
        items {
          id
          name
          subBrands (
            limit: 100
          ){
            items {
              id
              name
            }
          }
        }
        nextToken
      }
      spaces (
        limit: 100
      ){
        items {
          id
          name
          colorCode
          status
        }
        nextToken
      }
      services (
        limit: 100
      ){
        items {
          id
          name
          icon
        }
        nextToken
      }
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        clientName
        serviceName
        quantity
        unit
      }
      productItems(
        limit: 1000,
        filter: {
          complete: {
            eq: true
          }
        }
      ) {
        items {
          id
          uic
          brand {
            id
            name
          }
          subBrand {
            id
            name
          }
          category
          completedServices
          subCategory
          tagged
          tagDescription
          previousTagDescriptions
          type
          gender
          imageKeys
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
          complete
          qcApproved
          content (
            limit: 100
          ){
            items {
              id
              header
              content
              createdAt
            }
          }
          notes (
            limit: 100
          ){
            items {
              id
              note
              timestamp
              author {
                id
                firstName
                lastName
                email
              }
            }
            nextToken
          }
          batchCard {
            id
            name
            description
            productItems (
              limit: 100
            ){
              items {
                id 
                complete
                qcApproved
                content (
                  limit: 100
                ){
                  items {
                    id
                    header
                    content
                    createdAt
                  }
                }
              }
            }
            services (
              limit: 100
            ) {
              items {
                service {
                  id
                  name
                  icon 
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
}
`);

/** Query to list company product items */
export const getCompanyArchivedProductItemsByUser = gql(`query getCompanyArchivedProductItemsByUser($id: ID!) {
  getUser(id: $id) {
    id
    company {
      id
      brands (
        limit: 100
      ){
        items {
          id
          name
          subBrands {
            items {
              id
              name
            }
          }
        }
        nextToken
      }
      spaces (
        limit: 100
      ){
        items {
          id
          name
          colorCode
          status
        }
        nextToken
      }
      services (
        limit: 100
      ){
        items {
          id
          name
          icon
        }
        nextToken
      }
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        clientName
        serviceName
        quantity
        unit
      }
      productItems(
        limit: 100,
        filter: {
          archived: {
            eq: true
          }
        }
      ) {
        items {
          id
          uic
          brand {
            id
            name
          }
          subBrand {
            id
            name
          }
          category
          completedServices
          subCategory
          tagged
          tagDescription
          previousTagDescriptions
          type
          gender
          imageKeys
          age
          size
          look
          other1
          other2
          other3
          season
          collectionName
          productName
          clientName
          serviceName
          quantity
          unit
          complete
          notes {
            items {
              id
              note
              timestamp
              author {
                id
                firstName
                lastName
                email
              }
            }
            nextToken
          }
          batchCard {
            id
            name
            description
            services {
              items {
                service {
                  id
                  name
                  icon 
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
}
`);

/** Query to list company services */
export const getCompanyServicesByUser = gql(`query GetCompanyServicesByUser($id: ID!) {
    getUser(id: $id) {
      id
      company {
        id
        services(
          limit: 100
        ) {
          items {
            id
            name
            icon
          }
          nextToken
        }
      }
    }
  }
  `);
/** Query to list company spaces */
export const getCompanySpacesByUser = gql(`query GetCompanySpacesByUser($id: ID!) {
      getUser(id: $id) {
        id
        company {
          id
          spaces(
            limit: 100
          ) {
            items {
              id
              name
              colorCode
              status
            }
            nextToken
          }
        }
      }
    }
    `);
/** Query to list company spaces */
export const getCompanyActiveSpacesByUser = gql(`query GetCompanyActiveSpacesByUser($id: ID!) {
  getUser(id: $id) {
    id
    company {
      id
      spaces(
        limit: 100,
        filter: {
          status: {
            eq: ACTIVE
          }
        }
      ) {
        items {
          id
          name
          colorCode
          status
        }
        nextToken
      }
    }
  }
}
`);
/** Query to list company roles */
export const getCompanyRolesByUser = gql(`query GetCompanyRolesByUser($id: ID!) {
      getUser(id: $id) {
        id
        company {
          id
          roles(
            limit: 100
          ) {
            items {
              id
              name
              permission
            }
            nextToken
          }
        }
      }
    }
    `);
/** Query to list company users */
export const getCompanyUsersByUser = gql(`query GetCompanyUsersByUser($id: ID!) {
    getUser(id: $id) {
      id
      userRole {
        id
        name
        permission
      }
      company {
        id
        roles(
          limit: 50
        ) {
          items {
            id
            name
            permission
          }
          nextToken
        }
        users (
          limit: 1000,
          filter: {
            id: {
              ne: $id
            }
          }
        ) {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            avatarKey
            avatarUrl
            archived
            verified
            status
            inviteLater
            userRole {
              id
              name
              permission
            }
          }
          nextToken
        }
      }
    }
  }`);
/** Query to list company users */
export const getCompanyActiveUsersByUser = gql(`query GetCompanyActiveUsersByUser($id: ID!) {
  getUser(id: $id) {
    id
    userRole {
      id
      name
      permission
    }
    company {
      id
      roles {
        items {
          id
          name
          permission
        }
        nextToken
      }
      users (
        limit: 100,
        filter: {
          status: {
            eq: ACTIVE
          }
        }
      ) {
        items {
          id
          firstName
          lastName
          email
          phoneNumber
          avatarKey
          avatarUrl
          archived
          verified
          status
          userRole {
            id
            name
            permission
          }
        }
        nextToken
      }
    }
  }
}`);
/** Query to list company brands and sub brands */
export const getCompanyBrandsAndSubBrandsByUser = gql(`query GetCompanyBrandsAndSubBrandsByUser($id: ID!) {
    getUser(id: $id) {
      id
      company {
        id
        brands(limit: 1000) {
          items {
            id
            name
            subBrands(limit: 1000) {
              items {
                id
                name
              }
            }
          }
          nextToken
        }
      }
    }
  }`);

/** Query to get all batch cards for a specified week */
export const getCompanyBatchCardsForTheWeekByUser = gql(`query GetCompanyBatchCardsForTheWeekByUser(
    $id: ID!
    $dayOne: String
    $dayTwo: String
    $dayThree: String
    $dayFour: String
    $dayFive: String
    $daySix: String
    $daySeven: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        batchCards(
          limit: 1000
          filter: {
            and: [
              {
                or: [
                  { createdAt: { contains: $dayOne } }
                  { createdAt: { contains: $dayTwo } }
                  { createdAt: { contains: $dayThree } }
                  { createdAt: { contains: $dayFour } }
                  { createdAt: { contains: $dayFive } }
                  { createdAt: { contains: $daySix } }
                  { createdAt: { contains: $daySeven } }
                ]
              }
            ]
          }
        ) {
          items {
            id
            createdAt
            productItems(limit: 1000) {
              items {
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`);

/** Query to get all batch cards for a specified month */
export const getCompanyBatchCardsForTheMonthByUser = gql(`
  query GetCompanyBatchCardsForTheMonthByUser($id: ID!, $month: String) {
    getUser(id: $id) {
      id
      company {
        id
        batchCards(
          limit: 1000
          filter: { and: [{ or: [{ createdAt: { contains: $month } }] }] }
        ) {
          items {
            id
            createdAt
             productItems(limit: 1000) {
              items {
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`);

/** query to get completed company product items for specified week */
export const getCompletedProductItemsForTheWeekByUser = gql(`query GetCompletedProductItemsForTheWeekByUser(
    $id: ID!
    $dayOne: String
    $dayTwo: String
    $dayThree: String
    $dayFour: String
    $dayFive: String
    $daySix: String
    $daySeven: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        productItems(
          limit: 1000
          filter: {
            and: [
              { complete: { eq: true } }
              {
                or: [
                  { completedAt: { contains: $dayOne } }
                  { completedAt: { contains: $dayTwo } }
                  { completedAt: { contains: $dayThree } }
                  { completedAt: { contains: $dayFour } }
                  { completedAt: { contains: $dayFive } }
                  { completedAt: { contains: $daySix } }
                  { completedAt: { contains: $daySeven } }
                ]
              }
            ]
          }
        ) {
          items {
            id
            completedAt
          }
          nextToken
        }
      }
    }
  }
`);

/** query to get completed company product items for specified month */
export const getCompletedProductItemsForTheMonthByUser = gql(`query GetCompletedProductItemsForTheMonthByUser(
  $id: ID!, $month: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        productItems(
          limit: 1000
          filter: {
            and: [{ complete: { eq: true } }, { completedAt: { contains: $month } }]
          }
        ) {
          items {
            id
            completedAt
            batchCard {
              id
              createdAt
            }
          }
          nextToken
        }
      }
    }
  }
`);

/** query to get cqc approved company product items for specified week */
export const getQCApprovedProductItemsForTheWeekByUser = gql(`query GetQCApprovedProductItemsForTheWeekByUser(
    $id: ID!
    $dayOne: String
    $dayTwo: String
    $dayThree: String
    $dayFour: String
    $dayFive: String
    $daySix: String
    $daySeven: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        productItems(
          limit: 1000
          filter: {
            and: [
              { qcApproved: { eq: true } }
              {
                or: [
                  { qcApprovedAt: { contains: $dayOne } }
                  { qcApprovedAt: { contains: $dayTwo } }
                  { qcApprovedAt: { contains: $dayThree } }
                  { qcApprovedAt: { contains: $dayFour } }
                  { qcApprovedAt: { contains: $dayFive } }
                  { qcApprovedAt: { contains: $daySix } }
                  { qcApprovedAt: { contains: $daySeven } }
                ]
              }
            ]
          }
        ) {
          items {
            id
            qcApprovedAt
            batchCard {
              id
              createdAt
            }
          }
          nextToken
        }
      }
    }
  }
`);

/** query to get qc approved company product items for specified month */
export const getQCApprovedProductItemsForTheMonthByUser = gql(`query GetQCApprovedProductItemsForTheMonthByUser(
  $id: ID!, $month: String
  ) {
    getUser(id: $id) {
      id
      company {
        id
        productItems(
          limit: 1000
          filter: {
            and: [{ qcApproved: { eq: true } }, { qcApprovedAt: { contains: $month } }]
          }
        ) {
          items {
            id
            qcApprovedAt
            batchCard {
              id
              createdAt
            }
          }
          nextToken
        }
      }
    }
  }
`);

/** Query to get batch cards for a space within a given month */
export const getBatchCardsForTheMonthByUserAndSpace = gql(`query GetBatchCardsForTheMonthByUserAndSpace(
  $spaceId: ID!
  $month: String
) {
  listSpaces(limit: 1000, filter: { id: { eq: $spaceId } }) {
    items {
      id
      batchCardAllocations(
        limit: 1000
        filter: { or: [{ createdAt: { contains: $month } }] }
      ) {
        items {
          id
          createdAt
          space {
            id
            name
          }
          batchCard {
            id
            createdAt
            productItems {
              items {
                id
                createdAt
              }
            }
          }
        }
      }
    }
  }
}
`);

/** Query to get batch cards for a space within a given week */
export const getBatchCardsForTheWeekByUserAndSpace = gql(`query GetBatchCardsForTheWeekByUserAndSpace(
  $spaceId: ID!
  $dayOne: String
  $dayTwo: String
  $dayThree: String
  $dayFour: String
  $dayFive: String
  $daySix: String
  $daySeven: String
) {
  listSpaces(limit: 1000, filter: { id: { eq: $spaceId } }) {
    items {
      id
      batchCardAllocations(
        limit: 1000
        filter: {
          or: [
            { createdAt: { contains: $dayOne } }
            { createdAt: { contains: $dayTwo } }
            { createdAt: { contains: $dayThree } }
            { createdAt: { contains: $dayFour } }
            { createdAt: { contains: $dayFive } }
            { createdAt: { contains: $daySix } }
            { createdAt: { contains: $daySeven } }
          ]
        }
      ) {
        items {
          id
          createdAt
          space {
            id
            name
          }
          batchCard {
            id
            createdAt
            productItems {
              items {
                id
                createdAt
              }
            }
          }
        }
      }
    }
  }
}
`);

export const getProductItemServicesForTheMonthByUser = gql(`query GetProductItemServicesForTheMonthByUser(
  $id: ID!
  $month: String!
) {
  getUser(id: $id) {
    id
    company {
      id
      productItems(
        limit: 1000
        filter: { or: [{ createdAt: { contains: $month } }] }
      ) {
        items {
          id
          createdAt
          productItemServices
        }
      }
    }
  }
}
`);

export const getProductItemServicesForTheWeekByUser = gql(`query GetProductItemServicesForTheWeekByUser(
  $id: ID!
  $dayOne: String
  $dayTwo: String
  $dayThree: String
  $dayFour: String
  $dayFive: String
  $daySix: String
  $daySeven: String
) {
  getUser(id: $id) {
    id
    company {
      id
      productItems(
        limit: 1000
        filter: {
          or: [
            { createdAt: { contains: $dayOne } }
            { createdAt: { contains: $dayTwo } }
            { createdAt: { contains: $dayThree } }
            { createdAt: { contains: $dayFour } }
            { createdAt: { contains: $dayFive } }
            { createdAt: { contains: $daySix } }
            { createdAt: { contains: $daySeven } }
          ]
        }
      ) {
        items {
          id
          createdAt
          productItemServices
        }
      }
    }
  }
}

`);

export const getCompanyProductItemsByUserAndUic = gql(`query getCompanyProductItemsByUserAndUic($userId: ID!, $uic: String!) {
    getUser(id: $userId) {
      id
      company {
        id
        productItems(
          limit: 1000
          filter: {
            or: [{uic: { eq: $uic } }]
          }
          ) {
          items {
            id
            uic
          }
        }
      }
    }
  }
  `);
