import * as React from 'react';
import { Query } from 'react-apollo';

/** GraphQL */
import { listUsersCreatedInSelectedYear } from '../../../../graphql/custom-queries';

/** Presentation/UI */
import ErrorMessage from '../../../../Components/Styled/ErrorMessage';
import Loader from '../../../../Components/Loader';
import UserDataAndExport from '../DataAndExport';

/** Themes */
import { Colors } from '../../../../Themes';

/** Utils */
import { getUserDataFromKeys } from '../Utils/Helpers';
import { USER_HEADINGS, USER_KEYS } from '../Utils/Consts';

const UsersCreatedInSelectedYear: React.FC<{
  year: string;
}> = ({ year }) => {
  return (
    <Query
      query={listUsersCreatedInSelectedYear}
      variables={{
        year
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loader color={Colors.flumeGreen} />;
        }

        if (error || !data || !data.listUsers) {
          return <ErrorMessage errorMessage="Error" />;
        }

        const users = data.listUsers;

        if (users && users.items && users.items.length) {
          // CSV data of users to be exported
          const csvData = [
            USER_HEADINGS,
            ...getUserDataFromKeys(users.items, USER_KEYS)
          ];

          return (
            <UserDataAndExport
              dataTotal={users.items.length}
              filename="users.csv"
              csvData={csvData}
            />
          );
        }

        return (
          <UserDataAndExport dataTotal={0} csvData={[]} filename="users.csv" />
        );
      }}
    </Query>
  );
};

export default UsersCreatedInSelectedYear;
