import * as React from 'react';
import { Form, Label, FormGroup, Input } from 'reactstrap';

/** Presentation/UI */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import LottieWrapper from '../../Components/Anim/LottieWrapper';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import Button from '../../Components/Styled/Button';
import {
  StyledPopOver,
  StyledPopoverHeader,
  StyledPopOverBody,
  StyledList
} from '../../Components/Styled/PopOver';

/** Custom Types */
import { ForgotPasswordViews, Error } from '../../CustomTypes';

// Themes
import { Colors } from '../../Themes';

/** Utils */
import { COGNITO_CONFIRM_USER } from '../../Utils/LambdaEndpoints';
import { HTTP_METHODS } from '../../Utils/Consts';

import AppSyncConfig from '../../aws-exports';

/** API */
import { apiRequest } from '../../Utils/API';

type IProps = {
  username: string;
  session: string;
  challengeName: string;
  closeModal: () => void;
};

type NewPasswordFieldProps = {
  loading: boolean;
  password: string;
  error: Error;
  validateNewPasswordForm: () => boolean;
  confirmNewUser: () => void;
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  setNewPassword(password: string): void;
  togglePopOver: () => void;
  validationError: string;
};

type IState = {
  view: ForgotPasswordViews;
  password: string;
  loading: boolean;
  error: Error;
  popoverOpen: boolean;
  digits: boolean;
  uppercase: boolean;
  lowercase: boolean;
  symbols: boolean;
  min: boolean;
  validationError: string;
};

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema
  .is()
  .min(8) // Minimum length 8
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits() // Must have digits
  .has()
  .symbols(); // Must have symbols

const NewPasswordField: React.FC<NewPasswordFieldProps> = props => {
  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        if (props.validateNewPasswordForm()) {
          props.confirmNewUser();
        }
      }}
    >
      <FormGroup>
        <Label for="newUserPassword">New Password</Label>
        <div className={props.validationError ? 'input-group-error' : 'hidden'}>
          <span>{props.validationError}</span>
        </div>
        <br />
        <Input
          className={props.validationError && 'input-error'}
          type="password"
          name="password"
          value={props.password}
          id="newUserPassword"
          placeholder="Password"
          onChange={props.handleChange}
          onFocus={props.togglePopOver}
          onBlur={props.togglePopOver}
        />
        <br />
      </FormGroup>
      <Button
        type="submit"
        label={props.loading ? <Loader /> : <span>Update Password</span>}
        background={Colors.flumeGreen}
      />
      {props.error && <ErrorMessage errorMessage={props.error} />}
    </Form>
  );
};

class SetNewPasswordForm extends React.Component<IProps, IState> {
  state: IState = {
    view: 'newPassword',
    password: '',
    loading: false,
    error: null,
    popoverOpen: false,
    digits: false,
    uppercase: false,
    lowercase: false,
    symbols: false,
    min: false,
    validationError: ''
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  validateNewPasswordForm = (): boolean => {
    const { password } = this.state;
    // Check for undefined or empty input fields
    if (!password) {
      this.setError('Please enter a valid new password');
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Set new state for password */
  setNewPassword = (password: string): void => {
    this.setState({ password });
  };

  // Handle change
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value }: { value: string } = e.currentTarget;
    const validationRulesErrors = schema.validate(value, { list: true });

    if (validationRulesErrors.length > 0) {
      this.setState({
        validationError: this.formatPasswordValidateError(validationRulesErrors)
      });
    } else {
      this.setState({
        min: true,
        lowercase: true,
        uppercase: true,
        symbols: true,
        digits: true,
        validationError: ''
      });
    }

    this.setState({ password: value });
  };

  formatPasswordValidateError = errors => {
    let validationError = '';

    for (let i = 0; i < errors.length; i++) {
      if (errors[i] === 'min') {
        validationError = 'password length should be a at least 8 characters';

        this.setState({ min: false });

        break;
      } else if (errors[i] === 'lowercase') {
        validationError = 'password should contain lowercase letters';

        this.setState({ min: true, lowercase: false });

        break;
      } else if (errors[i] === 'uppercase') {
        validationError = 'password should contain uppercase letters';

        this.setState({
          min: true,
          lowercase: true,
          uppercase: false
        });

        break;
      } else if (errors[i] === 'symbols') {
        validationError = 'password should contain symbols';

        this.setState({
          min: true,
          lowercase: true,
          uppercase: true,
          digits: true,
          symbols: false
        });

        break;
      } else if (errors[i] === 'digits') {
        validationError = 'password should contain digits';

        this.setState({
          min: true,
          lowercase: true,
          uppercase: true,
          digits: false
        });

        break;
      }
    }

    return validationError;
  };

  // Handle setting of a new password
  confirmNewUser = async () => {
    const { password } = this.state;
    const { username, session, challengeName } = this.props;

    this.setState({ loading: true });

    const bodyParams = {
      username,
      password,
      challengeName,
      session,
      clientId: AppSyncConfig.aws_user_pools_web_client_id
    };

    const response = await apiRequest(
      COGNITO_CONFIRM_USER,
      HTTP_METHODS.POST,
      bodyParams
    ).catch(e => {
      this.setError(e.message);
    });

    if (response) {
      this.setState({ loading: false, view: 'success' });
    }
  };

  togglePopOver = () => {
    const { min, digits, symbols, uppercase, lowercase } = this.state;
    this.setState({
      popoverOpen: !(min && digits && symbols && uppercase && lowercase)
    });
  };

  passwordPolicyPopUp = () => {
    const {
      digits,
      uppercase,
      lowercase,
      symbols,
      min,
      popoverOpen
    } = this.state;

    return (
      <StyledPopOver
        placement="top"
        isOpen={popoverOpen}
        target="newUserPassword"
      >
        <StyledPopoverHeader>Password Policy</StyledPopoverHeader>
        <StyledPopOverBody>
          <h6>Your password should contain: </h6>
          <StyledList>
            <li className={min ? 'strike-through' : ''}>
              Minimum length of 8 characters
            </li>
            <li className={digits ? 'strike-through' : ''}>
              Numerical characters (0-9)
            </li>
            <li className={symbols ? 'strike-through' : ''}>
              Special characters
            </li>
            <li className={uppercase ? 'strike-through' : ''}>
              Uppercase letter
            </li>
            <li className={lowercase ? 'strike-through' : ''}>
              Lowercase letter
            </li>
          </StyledList>
        </StyledPopOverBody>
      </StyledPopOver>
    );
  };

  renderForm = (view: ForgotPasswordViews): React.ReactNode => {
    const { loading, error, password, validationError } = this.state;

    switch (view) {
      case 'newPassword':
        return (
          <FullWidthContainer>
            <NewPasswordField
              loading={loading}
              error={error}
              password={password}
              setNewPassword={this.setNewPassword}
              confirmNewUser={this.confirmNewUser}
              validateNewPasswordForm={this.validateNewPasswordForm}
              handleChange={this.handleChange}
              togglePopOver={this.togglePopOver}
              validationError={validationError}
            />
            {this.passwordPolicyPopUp()}
          </FullWidthContainer>
        );
      case 'success':
        return (
          <FullWidthContainer align="center">
            <LottieWrapper
              loop={false}
              width={140}
              height={110}
              anim={require('../../LottieFiles/success1.json')}
            />
            <br />
            Password reset successful
          </FullWidthContainer>
        );
      default:
        return (
          <FullWidthContainer>
            <NewPasswordField
              loading={loading}
              error={error}
              password={password}
              setNewPassword={this.setNewPassword}
              confirmNewUser={this.confirmNewUser}
              validateNewPasswordForm={this.validateNewPasswordForm}
              togglePopOver={this.togglePopOver}
              validationError={validationError}
              handleChange={this.handleChange}
            />
            {this.passwordPolicyPopUp()}
          </FullWidthContainer>
        );
    }
  };

  render() {
    const { view } = this.state;
    return <FullWidthContainer>{this.renderForm(view)}</FullWidthContainer>;
  }
}

export default SetNewPasswordForm;
