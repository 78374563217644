import * as React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { MdMoreVert } from 'react-icons/md';

import {
  Permission,
  UpdateUserInput,
  UpdateSpaceInput,
  UpdateRoleInput,
  UpdateCompanyInput
} from '../../API';

/**  Header Nav */
import { NAV_ITEMS } from '../../Navigation/HeaderNavItems';

/**  Presentation UI */
import GlobalModalContainer from '../../Components/Modal';
import { DisabledHyperLink } from '../Styled/Nav';

/** Custom types */
import { NavItemType, CompanyType } from '../../CustomTypes';

/**  Context API */
import { AppContextConsumer } from '../ContextProvider';

/**  Themes */
import { Colors, Images } from '../../Themes';

/** Local components */
import EditProfile from './EditProfile';

/** Utils */
import {
  getUserGroup,
  returnImageFromStorage,
  checkIfArrayOfStrings,
  isOwner
} from '../../Utils/Helpers';
import { AUTH_USER_TOKEN_KEY } from '../../Utils/LocalStorage';
import { checkIfSetupIsComplete } from './Utils/Helpers';

/** Styled elements */
import {
  Avatar,
  AvatarContainer,
  AvatarContainerItem,
  Wrapper
} from './StyledElements';
import { getUserDetails } from '../../graphql/custom-queries';
import { HTTP_METHODS } from '../../Utils/Consts';
import { SEND_EMAIL } from '../../Utils/LambdaEndpoints';
import { apiRequest } from '../../Utils/API';

type Props = RouteComponentProps & {
  company: CompanyType;
  user: UpdateUserInput & {
    company?: UpdateCompanyInput;
    space?: UpdateSpaceInput;
    userRole?: UpdateRoleInput;
  };
  updateCompany: React.Dispatch<React.SetStateAction<CompanyType>>;
  updateSpaceId: React.Dispatch<React.SetStateAction<string>>;
  updateSpaceIds: React.Dispatch<React.SetStateAction<Array<string>>>;
  updateUserPermission: React.Dispatch<
    React.SetStateAction<Permission | string>
  >;
  updateSubscriptionPlan: React.Dispatch<
    React.SetStateAction<Permission | string>
  >;
  routeUserGroups: Array<string>;
  updateUserMutation: ({}) => Promise<any>; // eslint-disable-line
};

type State = {
  dropdownOpen: boolean;
  modal: boolean;
  avatarUrl?: object | string;
};

class HeaderWrapper extends React.Component<Props, State> {
  static async getDerivedStateFromProps(props: Props) {
    if (props && props.user && props.user.avatarKey) {
      const response = await returnImageFromStorage(props.user.avatarKey);
      const avatarUrl = response || '';
      return {
        avatarUrl
      };
    }
    if (props && props.user && props.user.company) {
      const { updateCompany } = props;

      updateCompany(props.user.company);
    }
    return {
      avatarUrl: Images.defaultAvatar
    };
  }

  state: State = {
    dropdownOpen: false,
    modal: false,
    avatarUrl: ''
  };

  async componentDidMount() {
    const {
      user,
      updateCompany,
      updateSpaceId,
      updateSpaceIds,
      updateUserPermission,
      updateUserMutation,
      updateSubscriptionPlan
    } = this.props;
    // User Permission
    if (user && user.userRole) {
      const { permission } = user.userRole;
      updateUserPermission(permission || '');
    }
    // User company subscription plan
    if (user && user.company) {
      const { subscriptionType } = user.company;
      updateSubscriptionPlan(subscriptionType || '');
      updateCompany(user.company);
    }
    // User Space
    if (user && user.space) {
      const { id } = user.space;
      updateSpaceId(id);
    }
    // User Spaces
    if (user && user.userSpaces) {
      const { userSpaces } = user;
      updateSpaceIds(checkIfArrayOfStrings(userSpaces));
    }
    if (user.avatarKey) {
      const response = await returnImageFromStorage(user.avatarKey);
      const avatarUrl = response || '';
      this.setState({ avatarUrl });
    }
    // Send notification to admin that invited user has accepted and signed in
    if (user && user.invitedBy && !user.inviteAccepted) {
      const { id } = user;
      // Send email notification
      await apiRequest(SEND_EMAIL, HTTP_METHODS.POST, {
        recipients: [user.invitedBy],
        subject: `User Invite To ${user.email} Accepted`,
        message: `The invite you sent to ${user.email} has been accepted.`
      });

      // Update user profile details
      await updateUserMutation({
        variables: {
          input: {
            id,
            inviteAccepted: true
          }
        },
        refetchQueries: [
          {
            query: getUserDetails,
            variables: {
              id
            }
          }
        ]
      });
    }
  }

  /** Return header navigation items
   * @param userGroup - the user group that the authenticated user belongs to
   */
  displayHeaderNavItems = (
    userGroup: string | null,
    company: CompanyType
  ): Array<React.ReactNode> => {
    const userAccessibleRoutes: Array<NavItemType> = NAV_ITEMS.filter(
      navItem => navItem.cognitoGroups.indexOf(userGroup || '') !== -1
    );

    if (
      checkIfSetupIsComplete(
        company.itemTemplate,
        company.roles,
        company.services,
        company.spaces
      )
    ) {
      return userAccessibleRoutes.map(navItem => {
        return (
          <NavLink
            key={navItem.to}
            to={navItem.to}
            style={
              this.checkIfActive(navItem.to)
                ? { borderBottom: `4px solid ${Colors.flumeGreen}` }
                : {}
            }
          >
            {navItem.icon}
            {'    '}
            {navItem.title}
          </NavLink>
        );
      });
    } else {
      return userAccessibleRoutes.map(navItem => {
        if (['Production', 'Reporting'].indexOf(navItem.title) !== -1) {
          return (
            <DisabledHyperLink key={navItem.to} fontSize="1.2em">
              {navItem.icon}
              {'    '}
              {navItem.title}
            </DisabledHyperLink>
          );
        }

        return (
          <NavLink
            key={navItem.to}
            to={navItem.to}
            style={
              this.checkIfActive(navItem.to)
                ? { borderBottom: `4px solid ${Colors.flumeGreen}` }
                : {}
            }
          >
            {navItem.icon}
            {'    '}
            {navItem.title === 'Manage' ? 'Setup' : navItem.title}
          </NavLink>
        );
      });
    }
  };

  /** Handle sign out */
  handleLogout = (): void => {
    window.localStorage.clear();
    const { history, location } = this.props;

    isOwner(location.pathname)
      ? history.push('/admin/sign-in')
      : history.push('/sign-in');
  };

  /** Toggle dropdown menu */
  toggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  /** Open modal */
  openModal = (): void => {
    this.setState({
      modal: true,
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  /** Check if the link/path is the active one
   * @param path - route path
   */
  checkIfActive = (path: string): boolean => {
    const { location } = this.props;
    return (
      path === location.pathname ||
      this.checkParentPath(path, location.pathname)
    );
  };

  /** Check the "parent" path of a particular sub-route (i.e. parent/sub-route)  */
  checkParentPath = (path: string, locationPathname: string): boolean => {
    const pathLinkArray = path.split('/').filter(pathPart => pathPart !== '');
    const locationPathnameLinkArray = locationPathname
      .split('/')
      .filter(pathPart => pathPart !== '');

    return pathLinkArray[0] === locationPathnameLinkArray[0];
  };

  /** Return username */
  returnUsername = (
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null
  ): string => {
    if (firstName || lastName) {
      return `${firstName} ${lastName || ''}`;
    }

    return email || '';
  };

  render() {
    const { dropdownOpen, modal, avatarUrl } = this.state;
    const { user, company, history, location } = this.props;
    const avatar = avatarUrl || Images.defaultAvatar;

    return (
      <AppContextConsumer>
        {({ drawerOpen }) => {
          const sideBarOpen = drawerOpen;

          return (
            <Wrapper open={sideBarOpen}>
              <GlobalModalContainer
                toggleModal={this.closeModal}
                title="Personal Profile"
                modalDisplay={
                  <EditProfile userId={user.id} closeModal={this.closeModal} />
                }
                modal={modal}
              />
              {this.displayHeaderNavItems(
                getUserGroup(window.localStorage.getItem(AUTH_USER_TOKEN_KEY)),
                company
              )}
              <AvatarContainer>
                <Avatar
                  style={{
                    backgroundImage: `url(${avatar})`,
                    backgroundSize: avatar ? 'cover' : '50%'
                  }}
                />
                <AvatarContainerItem>
                  {this.returnUsername(
                    user.email,
                    user.firstName,
                    user.lastName
                  )}
                </AvatarContainerItem>
                <AvatarContainerItem>
                  <Dropdown
                    className="avatar-dropdown"
                    isOpen={dropdownOpen}
                    toggle={this.toggle}
                  >
                    <DropdownToggle
                      tag="span"
                      onClick={this.toggle}
                      data-toggle="dropdown"
                      aria-expanded={dropdownOpen}
                    >
                      <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
                    </DropdownToggle>
                    <DropdownMenu>
                      <div
                        onClick={() => this.openModal()}
                        className="avatar-dropdown-menu-item"
                      >
                        Personal Profile
                      </div>
                      <div
                        className="avatar-dropdown-menu-item"
                        onClick={() =>
                          isOwner(location.pathname)
                            ? history.push('/admin/support')
                            : history.push('/support')
                        }
                      >
                        Help
                      </div>
                      <div
                        className="avatar-dropdown-menu-item"
                        onClick={this.handleLogout}
                      >
                        Log Out
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </AvatarContainerItem>
              </AvatarContainer>
            </Wrapper>
          );
        }}
      </AppContextConsumer>
    );
  }
}

export default withRouter(HeaderWrapper);
