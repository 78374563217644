import * as React from 'react';
import styled from 'styled-components';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from 'reactstrap';
import classnames from 'classnames';

// presentational components
import CustomWidthContainer from '../Layouts/CustomWidthContainer';
import Loader from '../Loader';
import StyledDivider from '../Styled/Divider';
import { StyledTabContainer } from '../Styled/TabContainers';

/* app theme */
import Colors from '../../Themes/Colors';

// helper methods
import { convertPixelsToRem } from '../../Utils/Helpers';

// stylesheet
import './index.css';

interface IProps {
  titles: object;
  tabsContent: object;
  loading?: boolean;
}

interface IState {
  activeTab: string;
}

const StyledTabHeader = styled.div`
  height: ${convertPixelsToRem(60)}
  width: 100%;
  border-radius: 0 0 ${convertPixelsToRem(5)} ${convertPixelsToRem(5)};
  background-color: #ffffff;
  box-shadow: 0 -2px 0 0 ${Colors.backendBackground};
  text-transform: uppercase;
`;

const StyledTabHeaderTitle = styled.span`
  width: 50%;
  position: relative;
  top: ${convertPixelsToRem(8)};
  left: ${convertPixelsToRem(16)};
  font-size: ${convertPixelsToRem(12)};
`;

export default class Tabs extends React.Component<IProps, IState> {
  state = {
    activeTab: '1'
  };

  toggle = (tab: string): void => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    const { loading, titles, tabsContent } = this.props;

    return (
      <CustomWidthContainer
        width="80%"
        maxHeight="50vh"
        textAlign="left"
        style={{ marginTop: convertPixelsToRem(100) }}
      >
        <Row style={{ position: 'relative', height: '100%' }}>
          <StyledTabContainer xs="4" sm="3" md="3" marginleft="4%">
            <Nav tabs={true} vertical={true} pills={true}>
              {Object.keys(titles).map((title, key) => {
                return (
                  <NavItem key={key}>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === title
                      })}
                      onClick={() => {
                        this.toggle(title);
                      }}
                    >
                      {titles[title]}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </StyledTabContainer>
          <StyledTabContainer
            xs="8"
            sm="8"
            md="8"
            marginleft="2%"
            overflowy="scroll"
          >
            <StyledTabHeader>
              <StyledTabHeaderTitle>
                {titles[this.state.activeTab]}
              </StyledTabHeaderTitle>
              <StyledDivider borderSize={1} borderColor={Colors.divider} />
            </StyledTabHeader>
            <TabContent activeTab={this.state.activeTab}>
              {loading ? (
                <Loader size={150} color={Colors.flumeGreen} />
              ) : (
                Object.keys(tabsContent).map((content, key) => {
                  return (
                    <TabPane key={key} tabId={content}>
                      {tabsContent[content]}
                    </TabPane>
                  );
                })
              )}
            </TabContent>
          </StyledTabContainer>
        </Row>
      </CustomWidthContainer>
    );
  }
}
