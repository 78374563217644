import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../Themes';

type IProps = {
  children: React.ReactNode;
  width?: string;
  height?: string;
};

type ICardWrapper = {
  width?: string;
  height?: string;
};

const CardWrapper = styled.div`
  width: ${(props: ICardWrapper) => (props.width ? `${props.width}` : '520px')};
  max-height: 90vh;
  height: ${(props: ICardWrapper) =>
    props.height ? `${props.height}` : '610px'};
  overflow: scroll;
  background: ${Colors.snow};
  padding: 3rem;
  border-radius: 3px;
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);

  @media (max-width: 850px) {
    width: calc(100% - 20px);
  }
`;

const SignUpWrapper: React.SFC<IProps> = (props: IProps) => {
  return (
    <CardWrapper width={props.width} height={props.height}>
      {props.children}
    </CardWrapper>
  );
};

export default SignUpWrapper;
