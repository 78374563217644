import * as React from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';

import { Colors } from '../../Themes';
import { convertPixelsToRem } from '../../Utils/Helpers';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${convertPixelsToRem(56)};
  width: ${convertPixelsToRem(56)};
  border-radius: ${convertPixelsToRem(5)};
  background-color: ${Colors.flumeGreenLight};
  margin: ${convertPixelsToRem(8)};

  img {
    width: 100%;
    max-height: 70%;
    zoom: 2;
  }

  &.active {
    border: 2px solid ${Colors.flumeDarkGreen};
    background: ${Colors.snow};
  }

  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  serviceIcons: Array<{}>;
  activeIcon: string;
  handleIconSelect(icon: string): void;
};

const ChooseServiceIconModal = (props: Props) => {
  const { serviceIcons, activeIcon, handleIconSelect } = props;
  return (
    <Row>
      {serviceIcons.map(
        // @ts-ignore
        (serviceIcon: string, i: number) => {
          return (
            <Col key={i} xs={2} md={2} lg={1}>
              <IconContainer
                className={activeIcon === serviceIcon ? 'active' : 'inactive'}
                onClick={() => handleIconSelect(serviceIcon)}
              >
                <img alt="serviceIcon" src={serviceIcon} />
              </IconContainer>
            </Col>
          );
        }
      )}
    </Row>
  );
};

export default ChooseServiceIconModal;
