/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { adopt } from 'react-adopt';

/** GraphQL */
import {
  UpdateProductItemContentMutation,
  UpdateProductItemContentMutationVariables,
  DeleteProductItemContentMutation,
  DeleteProductItemContentMutationVariables
} from '../../API';
import {
  updateProductItemContent,
  deleteProductItemContent
} from '../../graphql/mutations';

/** Mutation component for updating a product item content */
const updateProductItem = (props: { render: ({}) => Mutation }) => (
  <Mutation<
    UpdateProductItemContentMutation,
    UpdateProductItemContentMutationVariables
  >
    mutation={gql(updateProductItemContent)}
  >
    {(mutation, result) => props.render({ mutation, result })}
  </Mutation>
);

/** Mutation for deleting an item content */
const deleteItemContent = (props: { render: ({}) => Mutation }) => (
  <Mutation<
    DeleteProductItemContentMutation,
    DeleteProductItemContentMutationVariables
  >
    mutation={gql(deleteProductItemContent)}
  >
    {(mutation, result) => props.render({ mutation, result })}
  </Mutation>
);

const Composed = adopt({
  updateProductItem,
  deleteItemContent
});

export default Composed;
