import * as React from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Form, Row, Col, Button } from 'reactstrap';

/** GraphQL */
import { updateBatchCardAllocation } from '../../graphql/mutations';
import {
  getBatchCardAllocationById,
  getCompanyBatchCardsByUser,
  getCompanyBatchCardsByUserMainPlanner
} from '../../graphql/custom-queries';

/** Generated types */
import {
  UpdateBatchCardAllocationMutation,
  UpdateBatchCardAllocationMutationVariables
} from '../../API';

/** Presentation/UI */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';

/** Context API */
import DefaultWeekContextProvider from '../QualityControlCalendar/DefaultWeekContextProvider';
import { DefaultWeekContextConsumer } from '../QualityControlCalendar/DefaultWeekContextProvider';
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Custom types */
import { ModalWithMessageType, Error } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

type Props = ModalWithMessageType & {
  batchCardAllocationId: string;
};

type State = {
  error: Error;
  loading: boolean;
};

class MoveBatchCardBackToBacklog extends React.Component<Props, State> {
  timeoutId: number = 0;

  state = {
    error: null,
    loading: false
  };

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Update batch card allocations
   * @param batchCardAllocations - an array of ids for allocations from the batch card being moved to backlog
   * @param updateUserMutation - graphql mutation for updating batch card allocations
   * @param currentWeekFormattedDates - an array of dates in string format
   */
  executeMutation = async (
    userId: string,
    batchCardAllocations: Array<{ id: string }>,
    updateBatchCardAllocationsMutation: ({}) => Promise<any>, // eslint-disable-line
    currentWeekFormattedDates: Array<string>
  ) => {
    if (batchCardAllocations && batchCardAllocations.length) {
      const { closeModal, notification } = this.props;

      this.setState({ loading: true });

      try {
        await Promise.all(
          batchCardAllocations.map(
            async (batchCardAllocation: { id: string }) => {
              const { id } = batchCardAllocation;
              return await updateBatchCardAllocationsMutation({
                variables: {
                  input: {
                    id,
                    obsolete: true
                  }
                },
                refetchQueries: [
                  {
                    query: getCompanyBatchCardsByUser,
                    variables: {
                      id: userId
                    }
                  },
                  {
                    query: getCompanyBatchCardsByUserMainPlanner,
                    variables: {
                      id: userId,
                      dayOne: currentWeekFormattedDates[0],
                      dayTwo: currentWeekFormattedDates[1],
                      dayThree: currentWeekFormattedDates[2],
                      dayFour: currentWeekFormattedDates[3],
                      dayFive: currentWeekFormattedDates[4],
                      daySix: currentWeekFormattedDates[5],
                      daySeven: currentWeekFormattedDates[6]
                    }
                  }
                ]
              });
            }
          )
        );

        closeModal();
        notification('Batch card has been moved back to Queue');
      } catch (e) {
        this.setError(e.message);
      }
    }
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { closeModal, batchCardAllocationId } = this.props;
    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <DefaultWeekContextProvider>
              <DefaultWeekContextConsumer>
                {({ currentWeekFormattedDates }) => {
                  return (
                    <Query
                      query={getBatchCardAllocationById}
                      variables={{ id: batchCardAllocationId }}
                    >
                      {({ loading, error, data }) => {
                        if (loading) {
                          return (
                            <FullWidthContainer align="center">
                              <Loader color={Colors.flumeGreen} />
                            </FullWidthContainer>
                          );
                        }
                        if (error || !data || !data.getBatchCardAllocation) {
                          return (
                            <ErrorMessage errorMessage="There was a problem loading your company data" />
                          );
                        }

                        const {
                          batchCardAllocations
                        } = data.getBatchCardAllocation.batchCard;

                        return (
                          <Mutation<
                            UpdateBatchCardAllocationMutation,
                            UpdateBatchCardAllocationMutationVariables
                          >
                            mutation={gql(updateBatchCardAllocation)}
                          >
                            {updateBatchCardAllocations => (
                              <Form
                                onSubmit={e => {
                                  e.preventDefault();
                                  this.executeMutation(
                                    userId,
                                    batchCardAllocations.items,
                                    updateBatchCardAllocations,
                                    currentWeekFormattedDates
                                  );
                                }}
                              >
                                <FullWidthContainer align="center">
                                  Are you sure you want to move this card back
                                  to Batch Card Queue?
                                </FullWidthContainer>
                                <br />
                                <br />
                                <Row>
                                  <Col
                                    xs={12}
                                    md={6}
                                    lg={6}
                                    className="center-align"
                                  >
                                    <Button type="submit">
                                      {this.state.loading ? <Loader /> : 'Yes'}
                                    </Button>
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={6}
                                    lg={6}
                                    className="center-align"
                                  >
                                    <Button
                                      type="button"
                                      onClick={closeModal}
                                      className="secondary-btn"
                                    >
                                      No
                                    </Button>
                                  </Col>
                                </Row>
                                {error && (
                                  <ErrorMessage
                                    errorMessage={this.state.error}
                                  />
                                )}
                              </Form>
                            )}
                          </Mutation>
                        );
                      }}
                    </Query>
                  );
                }}
              </DefaultWeekContextConsumer>
            </DefaultWeekContextProvider>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default MoveBatchCardBackToBacklog;
