import * as React from 'react';
import { Nav } from 'reactstrap';
import HamburgerIcon from './HamburgerIcon';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { MdTrendingUp, MdLayers } from 'react-icons/md';

/** Context API */
import { AppContextConsumer } from '../ContextProvider';

/** Styled elements and presentation components */
import Divider from '../Styled/Divider';
import Header from '../Header';
import SideBarNavItem from './SideBarNavItem';

/** Custom types */
import { NavItemType } from '../../CustomTypes';

/** Utils */
import {
  convertToSelector,
  convertPixelsToRem,
  getUserGroup
} from '../../Utils/Helpers';
import { AUTH_USER_TOKEN_KEY } from '../../Utils/LocalStorage';
import { SUBSCRIPTION_PACKAGES } from '../../Utils/Consts';

import {
  PageWrap,
  Spacer,
  LogoCont,
  Logo,
  SideBarWrapper,
  ReportingHeader
} from './StyledElements';

// Themes
import { Images } from '../../Themes';

// CSS styling
import './index.css';

type PropsType = RouteComponentProps & {
  cognitoGroups: Array<string>;
  render: React.ReactNode;
  title: string;
  navItems: Array<NavItemType>;
  path: string;
  parent: string;
  subscriptionPlans: Array<SUBSCRIPTION_PACKAGES>;
};

class Sidebar extends React.Component<PropsType> {
  /**
   * Display side bar navigation items
   * @param navItems - navigation items to be displayed on menu
   * @param parent - parent path for navigation route
   * @param userGroup - the user group that the autheticated user belongs to
   */
  displaySidebarNavItems = (
    navItems: Array<NavItemType>,
    parent: string,
    userGroup: string | null,
    isReportingPath: boolean
  ) => {
    const accessibleNavItems = navItems.filter(
      navItem =>
        navItem.parent === parent &&
        navItem.cognitoGroups.indexOf(userGroup || '') !== -1
    );

    return accessibleNavItems.map((item, i) => {
      return !item.footer ? (
        <SideBarNavItem
          key={i}
          to={item.to}
          navId={convertToSelector(item.title)}
          icon={item.icon}
          title={item.title}
          subMenu={item.subMenu}
          subscriptionPlans={item.subscriptionPlans || undefined}
        />
      ) : item.footer && isReportingPath ? (
        <React.Fragment key={i}>
          {item.showHeader && (
            <ReportingHeader marginTop={convertPixelsToRem(50)}>
              <MdTrendingUp
                size="1.3rem"
                style={{
                  position: 'relative',
                  left: convertPixelsToRem(-16)
                }}
              />
              <span>Perfomance Metrics</span>
            </ReportingHeader>
          )}
          <SideBarNavItem
            to={item.to}
            navId={convertToSelector(item.title)}
            icon={item.icon}
            title={item.title}
            subMenu={item.subMenu}
          />
        </React.Fragment>
      ) : (
        <React.Fragment key={i}>
          <Divider />
          <SideBarNavItem
            to={item.to}
            navId={convertToSelector(item.title)}
            icon={item.icon}
            title={item.title}
            subMenu={item.subMenu}
            subscriptionPlans={item.subscriptionPlans || undefined}
          />
        </React.Fragment>
      );
    });
  };

  render() {
    const {
      render,
      path,
      navItems,
      parent,
      cognitoGroups,
      history
    } = this.props;
    const isReportingPath = path.includes('/reporting');
    const isSupportPath = path.includes('/support');
    const isAdminPath = path.includes('/admin');

    return (
      <AppContextConsumer>
        {({ drawerOpen, toggleDrawer }) => {
          const sideBarOpen = drawerOpen;

          return (
            <PageWrap>
              {path === '/' ? null : <Spacer open={sideBarOpen} />}
              <LogoCont open={sideBarOpen}>
                <HamburgerIcon onClick={toggleDrawer} open={sideBarOpen} />
                <Logo
                  open={sideBarOpen}
                  src={Images.logo}
                  className="pointer-cursor"
                  onClick={() =>
                    isAdminPath
                      ? history.push('/admin/dashboard')
                      : history.push('/')
                  }
                />
              </LogoCont>
              {path === '/' || isSupportPath ? null : (
                <SideBarWrapper
                  className="custom-sidebar"
                  dark={isReportingPath}
                  open={sideBarOpen}
                >
                  {isReportingPath && (
                    <ReportingHeader>
                      <MdLayers
                        size="1.3rem"
                        style={{
                          position: 'relative',
                          left: convertPixelsToRem(-16)
                        }}
                      />
                      <span>Workflow Metrics</span>
                    </ReportingHeader>
                  )}
                  <Nav>
                    {this.displaySidebarNavItems(
                      navItems,
                      parent,
                      getUserGroup(
                        window.localStorage.getItem(AUTH_USER_TOKEN_KEY)
                      ),
                      isReportingPath
                    )}
                  </Nav>
                </SideBarWrapper>
              )}
              <React.Fragment>
                <Header routeUserGroups={cognitoGroups} />
                <PageWrap style={{ paddingTop: 70, flex: 1 }}>
                  {render}
                </PageWrap>
              </React.Fragment>
            </PageWrap>
          );
        }}
      </AppContextConsumer>
    );
  }
}

export default withRouter(Sidebar);
