/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { MdSearch } from 'react-icons/md';
import styled from 'styled-components';
import Select from 'react-select';
import * as uuid from 'uuid';
import * as moment from 'moment';
import { Storage } from 'aws-amplify';

/** Context API */
import FileUploadContextProvider from './FileUploadContextProvider';
import { FileUploadContextConsumer } from './FileUploadContextProvider';
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import Table from '../../Components/Table';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import UploadImages from '../../Components/DataImport/UploadImages';
import StyledButton from '../../Components/Styled/Button';
import GlobalModalContainer from '../../Components/Modal';
import {
  SelectedIcon,
  ServiceIconsContainer,
  TableHeaderContainer
} from '../../Components/Styled/ListViewElements';

/** GraphQL */
import Composed from './ComposedMutationsForCreatingNewBatchCard';
import { getCompanyBatchCardsByUser } from '../../graphql/custom-queries';
import {
  UpdateProductItemInput,
  CreateItemTemplateInput,
  UpdateServiceInput,
  UpdateSpaceInput
} from '../../API';

/** Custom Types */
import {
  UserAndCompanyId,
  ModalWithMessageType,
  SelectOptionsType,
  Error,
  SelectType
} from '../../CustomTypes';

/** Themes */
import { Colors, ServiceIcons } from '../../Themes';

/** Utils */
import {
  returnNewProductItemColumns,
  returnServicesFromDropDownOptions,
  sortDropDownDataAlphabetically,
  sortArrayAlphabetically
} from '../../Utils/Helpers';

type Props = UserAndCompanyId &
  ModalWithMessageType & {
    selectedProductItems: Array<UpdateProductItemInput>;
    itemTemplate: CreateItemTemplateInput;
    services: Array<UpdateServiceInput>;
    spaces: Array<UpdateSpaceInput>;
  };

type State = {
  name: string;
  description: string;
  searchFilter: boolean;
  uploadModal: boolean;
  loading: boolean;
  selectedProductItemIds: Array<string>;
  services: SelectOptionsType;
  selectedServices: SelectOptionsType;
  selectedSpaces: SelectOptionsType;
  error: Error;
};

const IconsContainer = styled.div`
  display: inline-flex;
  & div {
    margin-right: 10px;
  }
  margin-top: 5px;
  margin-bottom: 10px;
`;

class CreateNewBatchCard extends React.Component<Props, State> {
  timeoutId: number = 0;
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      searchFilter: false,
      uploadModal: false,
      selectedProductItemIds: [],
      services: this.returnCompanyServices(props.services),
      selectedServices: [],
      selectedSpaces: [],
      error: null,
      loading: false
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Close import images modal */
  closeUploadModal = (): void => {
    this.setState({
      uploadModal: false
    });
  };

  /** Open import images modal */
  openUploadModal = (): void => {
    this.setState({
      uploadModal: true
    });
  };

  /** Return company services as react select options
   * @param services - created company services
   */
  returnCompanyServices = (
    services: Array<UpdateServiceInput>
  ): SelectOptionsType => {
    if (services && services.length) {
      return services.map((service: UpdateServiceInput) => {
        return {
          label: service.name || service.id,
          value: service.id,
          object: service
        };
      });
    }

    return [];
  };

  /** Toggle main table search filter */
  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Select services
   * @param selectedServices - selected services
   */
  selectServices = (selectedServices: SelectOptionsType): void => {
    this.setState({ selectedServices });
  };

  /** Return selected services as JSX */
  returnSelectedServices = (): React.ReactNode => {
    const { selectedServices } = this.state;
    return selectedServices.map((selectedService: SelectType) => {
      return (
        <SelectedIcon
          background={Colors.snow}
          largeImage={true}
          key={selectedService.value}
        >
          <img
            alt="service_icon"
            src={
              selectedService.object
                ? selectedService.object.icon
                : ServiceIcons.Editing
            }
          />
        </SelectedIcon>
      );
    });
  };

  /** Validate form */
  validateForm = (): boolean => {
    const { name, selectedServices } = this.state;
    // Check for undefined or empty input fields
    if (!name) {
      this.setError('Please fill in the name');
      return false;
    }
    // Check for services
    if (!selectedServices.length) {
      this.setError('Please select at least one service');
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Create batch card */
  createBatchCard = async (
    userId: string,
    createBatchCardMutation: ({}) => Promise<any>,
    createBatchCardServiceMutation: ({}) => Promise<any>,
    updateProductItemMutation: ({}) => Promise<any>,
    selectedProductItems: Array<UpdateProductItemInput>,
    files: Array<File>
  ) => {
    const { name, description, selectedServices } = this.state;
    const { closeModal, notification, companyId } = this.props;

    this.setState({ loading: true });

    let images: any = [];
    // First upload all batch card images to S3 storage bucket
    try {
      const uploadedFiles = await Promise.all(
        files.map(async file => {
          return await Storage.put(`${uuid()}_${file.name}`, file, {
            contentType: file.type
          });
        })
      );

      // If image files were uploaded successfully, get the keys for each file
      if (uploadedFiles && uploadedFiles.length) {
        // @ts-ignore
        images = uploadedFiles.map((image: { key: string }) => {
          return image.key;
        });
      }

      const createdBatchCard = await createBatchCardMutation({
        variables: {
          input: {
            name,
            description: description || null,
            images,
            inBacklog: true,
            batchCardCompanyId: companyId,
            createdAt: moment().toISOString()
          }
        },
        refetchQueries: [
          {
            query: getCompanyBatchCardsByUser,
            variables: {
              id: userId
            }
          }
        ]
      }).catch(err => {
        this.setState({ loading: false });
        this.setError(err.message);
      });

      const batchCardId = createdBatchCard.data.createBatchCard.id;

      if (batchCardId) {
        // Create batch card services
        await Promise.all(
          selectedServices.map(async (service: SelectType) => {
            await createBatchCardServiceMutation({
              variables: {
                input: {
                  batchCardServiceBatchCardId: batchCardId,
                  batchCardServiceServiceId: service.value,
                  isComplete: false
                }
              },
              refetchQueries: [
                {
                  query: getCompanyBatchCardsByUser,
                  variables: {
                    id: userId
                  }
                }
              ]
            });
          })
        );

        // Create relation between batch card and selected product items
        await Promise.all(
          selectedProductItems.map(async (product: UpdateProductItemInput) => {
            await updateProductItemMutation({
              variables: {
                input: {
                  id: product.id,
                  productItemBatchCardId: batchCardId,
                  productItemServices: returnServicesFromDropDownOptions(
                    selectedServices
                  )
                }
              },
              refetchQueries: [
                {
                  query: getCompanyBatchCardsByUser,
                  variables: {
                    id: userId
                  }
                }
              ]
            });
          })
        );
      }

      closeModal();
      notification('Batch card created successfully');
    } catch (err) {
      this.setState({ loading: false });
      this.setError(err.message);
    }
  };

  render() {
    const {
      name,
      description,
      uploadModal,
      searchFilter,
      services,
      selectedServices,
      error,
      loading
    } = this.state;
    const { selectedProductItems, itemTemplate } = this.props;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <FileUploadContextProvider>
              <FileUploadContextConsumer>
                {({ uploadedFiles }) => {
                  return (
                    <Composed>
                      {(composedMutations: {
                        addBatchCardService: {
                          mutation: ({  }: {}) => Promise<any>;
                        };
                        createNewBatchCard: {
                          mutation: ({  }: {}) => Promise<any>;
                        };
                        updateAProductItem: {
                          mutation: ({  }: {}) => Promise<any>;
                        };
                      }) => (
                        <div>
                          <GlobalModalContainer
                            toggleModal={this.closeUploadModal}
                            title="Upload Images"
                            modalDisplay={
                              <UploadImages
                                closeModal={this.closeUploadModal}
                              />
                            }
                            modal={uploadModal}
                          />
                          <Form
                            onSubmit={e => {
                              e.preventDefault();
                              if (this.validateForm()) {
                                this.createBatchCard(
                                  userId,
                                  composedMutations.createNewBatchCard.mutation,
                                  composedMutations.addBatchCardService
                                    .mutation,
                                  composedMutations.updateAProductItem.mutation,
                                  selectedProductItems,
                                  uploadedFiles
                                );
                              }
                            }}
                          >
                            <Row>
                              <Col xs={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="name">Batch Card Name</Label>
                                  <Input
                                    type="text"
                                    name="name"
                                    value={name}
                                    id="name"
                                    placeholder="Name"
                                    onChange={e =>
                                      this.setState({
                                        name: e.target.value
                                      })
                                    }
                                  />
                                </FormGroup>
                                <br />
                                <FormGroup>
                                  <Label for="services">Services</Label>
                                  <br />
                                  {selectedServices.length > 0 && (
                                    <IconsContainer>
                                      {this.returnSelectedServices()}
                                    </IconsContainer>
                                  )}
                                  <Select
                                    // @ts-ignore
                                    onChange={this.selectServices}
                                    options={sortDropDownDataAlphabetically(
                                      services
                                    )}
                                    isMulti={true}
                                    isSearchable={true}
                                    placeholder="Services"
                                    className="select-styling"
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="description">Description</Label>
                                  <Input
                                    type="textarea"
                                    name="description"
                                    value={description}
                                    id="description"
                                    placeholder="Add a detailed description"
                                    onChange={e =>
                                      this.setState({
                                        description: e.target.value
                                      })
                                    }
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="images">Images</Label>
                                  <br />
                                  <StyledButton
                                    type="button"
                                    label="Add Images"
                                    width="120px"
                                    onClick={this.openUploadModal}
                                    color={Colors.flumeGreen}
                                    background={Colors.grey}
                                  />
                                  <br />
                                  <br />
                                  {uploadedFiles.map((f: File, i) => (
                                    <React.Fragment key={i}>
                                      <div>
                                        {i + 1}. {f.name} -{' '}
                                        {`${f.size / 1000} kb`}
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </FormGroup>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                <TableHeaderContainer>
                                  <StyledButton
                                    type="button"
                                    label={
                                      <div>
                                        Search{' '}
                                        <MdSearch
                                          size="1.3em"
                                          color={Colors.flumeDarkGreen}
                                        />
                                      </div>
                                    }
                                    width="auto"
                                    onClick={this.toggleSearchFilter}
                                    color={Colors.flumeDarkGreen}
                                    background={Colors.flumeGreen}
                                  />
                                </TableHeaderContainer>
                                <Table
                                  data={sortArrayAlphabetically(
                                    selectedProductItems
                                  )}
                                  columns={[
                                    ...returnNewProductItemColumns(
                                      itemTemplate,
                                      searchFilter
                                    ),
                                    {
                                      id: uuid(),
                                      Header: 'SERVICES',
                                      accessor: () => {
                                        return selectedServices.length > 0 ? (
                                          <ServiceIconsContainer>
                                            {this.returnSelectedServices()}
                                          </ServiceIconsContainer>
                                        ) : null;
                                      },
                                      sortable: false,
                                      filterable: false
                                    }
                                  ]}
                                  defaultPageSize={5}
                                  showPaginationTop={false}
                                  showPaginationBottom={true}
                                />
                              </Col>
                            </Row>
                            <br />
                            <Col xs={12} md={4} lg={4}>
                              <FormGroup>
                                <Button disabled={loading} type="submit">
                                  {loading ? <Loader /> : 'Move To Planner'}
                                </Button>
                              </FormGroup>
                            </Col>
                            {error && <ErrorMessage errorMessage={error} />}
                          </Form>
                        </div>
                      )}
                    </Composed>
                  );
                }}
              </FileUploadContextConsumer>
            </FileUploadContextProvider>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default CreateNewBatchCard;
