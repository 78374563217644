import * as React from 'react';
import { Row, Col, Form, Label, FormGroup, Input, Button } from 'reactstrap';

/* presentational components */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/* custom types */
import { Error } from '../../CustomTypes';

type Props = {
  loading: boolean;
  error: Error;
  cardHolderName: string;
  CardElement: new (props: any) => React.Component;
  handleSubmit: (event: React.FormEvent) => void;
  handleChange: (event: React.FormEvent) => void;
};

const AddCardForm = (props: Props) => {
  const { loading, error, cardHolderName, CardElement, handleChange } = props;

  return (
    <Form onSubmit={props.handleSubmit}>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <FormGroup>
            <Label for="cardHolderName">Card Holder Name</Label>
            <Input
              type="text"
              name="cardHolderName"
              value={cardHolderName}
              id="cardHolderName"
              placeholder="Card Holder Name"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Col xs={12} md={12} lg={12} style={{ margin: '1rem auto' }}>
        <CardElement />
      </Col>
      <br />
      <Row>
        <Col xs={12} md={6} lg={{ size: 12 }}>
          <FormGroup>
            <Button>{!loading ? 'Add card' : <Loader />}</Button>
          </FormGroup>
        </Col>
      </Row>
      {error && <ErrorMessage errorMessage={error} />}
    </Form>
  );
};

export default AddCardForm;
