// tslint:disable
// this is an auto generated file. This will be overwritten

export const createTask = `mutation CreateTask($input: CreateTaskInput!) {
  createTask(input: $input) {
    id
    title
    description
    status
    assignedTo {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
  }
}
`;
export const updateTask = `mutation UpdateTask($input: UpdateTaskInput!) {
  updateTask(input: $input) {
    id
    title
    description
    status
    assignedTo {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
  }
}
`;
export const deleteTask = `mutation DeleteTask($input: DeleteTaskInput!) {
  deleteTask(input: $input) {
    id
    title
    description
    status
    assignedTo {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
  }
}
`;
export const createPrivateNote = `mutation CreatePrivateNote($input: CreatePrivateNoteInput!) {
  createPrivateNote(input: $input) {
    id
    content
    author {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
  }
}
`;
export const updatePrivateNote = `mutation UpdatePrivateNote($input: UpdatePrivateNoteInput!) {
  updatePrivateNote(input: $input) {
    id
    content
    author {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
  }
}
`;
export const deletePrivateNote = `mutation DeletePrivateNote($input: DeletePrivateNoteInput!) {
  deletePrivateNote(input: $input) {
    id
    content
    author {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
  }
}
`;
export const createCompany = `mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    id
    createdAt
    name
    address
    state
    industry
    country
    companySize
    stripeCustomerId
    stripeSubscriptionPlanId
    subscriptionType
    accountDisabled
    itemTemplate {
      id
      uic
      brand
      subBrand
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      season
      collectionName
      productName
      quantity
      clientName
      serviceName
      unit
      itemTemplateType
      companies {
        nextToken
      }
    }
    roles {
      items {
        id
        name
        permission
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
      nextToken
    }
    spaces {
      items {
        id
        createdAt
        name
        colorCode
        status
      }
      nextToken
    }
    brands {
      items {
        id
        name
      }
      nextToken
    }
    services {
      items {
        id
        name
        icon
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
    batchCards {
      items {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      nextToken
    }
  }
}
`;
export const updateCompany = `mutation UpdateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) {
    id
    createdAt
    name
    address
    state
    industry
    country
    companySize
    stripeCustomerId
    stripeSubscriptionPlanId
    subscriptionType
    accountDisabled
    itemTemplate {
      id
      uic
      brand
      subBrand
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      season
      collectionName
      productName
      quantity
      clientName
      serviceName
      unit
      itemTemplateType
      companies {
        nextToken
      }
    }
    roles {
      items {
        id
        name
        permission
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
      nextToken
    }
    spaces {
      items {
        id
        createdAt
        name
        colorCode
        status
      }
      nextToken
    }
    brands {
      items {
        id
        name
      }
      nextToken
    }
    services {
      items {
        id
        name
        icon
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
    batchCards {
      items {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      nextToken
    }
  }
}
`;
export const deleteCompany = `mutation DeleteCompany($input: DeleteCompanyInput!) {
  deleteCompany(input: $input) {
    id
    createdAt
    name
    address
    state
    industry
    country
    companySize
    stripeCustomerId
    stripeSubscriptionPlanId
    subscriptionType
    accountDisabled
    itemTemplate {
      id
      uic
      brand
      subBrand
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      season
      collectionName
      productName
      quantity
      clientName
      serviceName
      unit
      itemTemplateType
      companies {
        nextToken
      }
    }
    roles {
      items {
        id
        name
        permission
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
      nextToken
    }
    spaces {
      items {
        id
        createdAt
        name
        colorCode
        status
      }
      nextToken
    }
    brands {
      items {
        id
        name
      }
      nextToken
    }
    services {
      items {
        id
        name
        icon
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
    batchCards {
      items {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      nextToken
    }
  }
}
`;
export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    createdAt
    firstName
    lastName
    email
    phoneNumber
    avatarKey
    avatarUrl
    archived
    verified
    inviteLater
    inviteAccepted
    invitedBy
    status
    userRole {
      id
      name
      permission
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      users {
        nextToken
      }
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    spaceAllocation {
      items {
        id
        date
        createdAt
      }
      nextToken
    }
    productItemNotes {
      items {
        id
        note
        timestamp
      }
      nextToken
    }
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    createdAt
    firstName
    lastName
    email
    phoneNumber
    avatarKey
    avatarUrl
    archived
    verified
    inviteLater
    inviteAccepted
    invitedBy
    status
    userRole {
      id
      name
      permission
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      users {
        nextToken
      }
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    spaceAllocation {
      items {
        id
        date
        createdAt
      }
      nextToken
    }
    productItemNotes {
      items {
        id
        note
        timestamp
      }
      nextToken
    }
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    createdAt
    firstName
    lastName
    email
    phoneNumber
    avatarKey
    avatarUrl
    archived
    verified
    inviteLater
    inviteAccepted
    invitedBy
    status
    userRole {
      id
      name
      permission
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      users {
        nextToken
      }
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    spaceAllocation {
      items {
        id
        date
        createdAt
      }
      nextToken
    }
    productItemNotes {
      items {
        id
        note
        timestamp
      }
      nextToken
    }
  }
}
`;
export const createUserAllocation = `mutation CreateUserAllocation($input: CreateUserAllocationInput!) {
  createUserAllocation(input: $input) {
    id
    date
    createdAt
    user {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
  }
}
`;
export const updateUserAllocation = `mutation UpdateUserAllocation($input: UpdateUserAllocationInput!) {
  updateUserAllocation(input: $input) {
    id
    date
    createdAt
    user {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
  }
}
`;
export const deleteUserAllocation = `mutation DeleteUserAllocation($input: DeleteUserAllocationInput!) {
  deleteUserAllocation(input: $input) {
    id
    date
    createdAt
    user {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
  }
}
`;
export const createRole = `mutation CreateRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    id
    name
    permission
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    users {
      items {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
      nextToken
    }
  }
}
`;
export const updateRole = `mutation UpdateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    id
    name
    permission
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    users {
      items {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
      nextToken
    }
  }
}
`;
export const deleteRole = `mutation DeleteRole($input: DeleteRoleInput!) {
  deleteRole(input: $input) {
    id
    name
    permission
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    users {
      items {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
      nextToken
    }
  }
}
`;
export const createSpace = `mutation CreateSpace($input: CreateSpaceInput!) {
  createSpace(input: $input) {
    id
    createdAt
    name
    colorCode
    status
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    batchCards {
      items {
        id
      }
      nextToken
    }
    batchCardAllocations {
      items {
        id
        createdAt
        date
        obsolete
      }
      nextToken
    }
    spaceAllocation {
      items {
        id
        date
        createdAt
      }
      nextToken
    }
  }
}
`;
export const updateSpace = `mutation UpdateSpace($input: UpdateSpaceInput!) {
  updateSpace(input: $input) {
    id
    createdAt
    name
    colorCode
    status
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    batchCards {
      items {
        id
      }
      nextToken
    }
    batchCardAllocations {
      items {
        id
        createdAt
        date
        obsolete
      }
      nextToken
    }
    spaceAllocation {
      items {
        id
        date
        createdAt
      }
      nextToken
    }
  }
}
`;
export const deleteSpace = `mutation DeleteSpace($input: DeleteSpaceInput!) {
  deleteSpace(input: $input) {
    id
    createdAt
    name
    colorCode
    status
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    batchCards {
      items {
        id
      }
      nextToken
    }
    batchCardAllocations {
      items {
        id
        createdAt
        date
        obsolete
      }
      nextToken
    }
    spaceAllocation {
      items {
        id
        date
        createdAt
      }
      nextToken
    }
  }
}
`;
export const createBrand = `mutation CreateBrand($input: CreateBrandInput!) {
  createBrand(input: $input) {
    id
    name
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    subBrands {
      items {
        id
        name
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
  }
}
`;
export const updateBrand = `mutation UpdateBrand($input: UpdateBrandInput!) {
  updateBrand(input: $input) {
    id
    name
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    subBrands {
      items {
        id
        name
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
  }
}
`;
export const deleteBrand = `mutation DeleteBrand($input: DeleteBrandInput!) {
  deleteBrand(input: $input) {
    id
    name
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    subBrands {
      items {
        id
        name
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
  }
}
`;
export const createSubBrand = `mutation CreateSubBrand($input: CreateSubBrandInput!) {
  createSubBrand(input: $input) {
    id
    name
    brand {
      id
      name
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      subBrands {
        nextToken
      }
      productItems {
        nextToken
      }
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
  }
}
`;
export const updateSubBrand = `mutation UpdateSubBrand($input: UpdateSubBrandInput!) {
  updateSubBrand(input: $input) {
    id
    name
    brand {
      id
      name
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      subBrands {
        nextToken
      }
      productItems {
        nextToken
      }
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
  }
}
`;
export const deleteSubBrand = `mutation DeleteSubBrand($input: DeleteSubBrandInput!) {
  deleteSubBrand(input: $input) {
    id
    name
    brand {
      id
      name
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      subBrands {
        nextToken
      }
      productItems {
        nextToken
      }
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
  }
}
`;
export const createService = `mutation CreateService($input: CreateServiceInput!) {
  createService(input: $input) {
    id
    name
    icon
    batchCards {
      items {
        id
        isComplete
      }
      nextToken
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
  }
}
`;
export const updateService = `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    id
    name
    icon
    batchCards {
      items {
        id
        isComplete
      }
      nextToken
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
  }
}
`;
export const deleteService = `mutation DeleteService($input: DeleteServiceInput!) {
  deleteService(input: $input) {
    id
    name
    icon
    batchCards {
      items {
        id
        isComplete
      }
      nextToken
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
  }
}
`;
export const createItemTemplate = `mutation CreateItemTemplate($input: CreateItemTemplateInput!) {
  createItemTemplate(input: $input) {
    id
    uic
    brand
    subBrand
    category
    subCategory
    type
    gender
    age
    size
    look
    other1
    other2
    other3
    season
    collectionName
    productName
    quantity
    clientName
    serviceName
    unit
    itemTemplateType
    companies {
      items {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      nextToken
    }
  }
}
`;
export const updateItemTemplate = `mutation UpdateItemTemplate($input: UpdateItemTemplateInput!) {
  updateItemTemplate(input: $input) {
    id
    uic
    brand
    subBrand
    category
    subCategory
    type
    gender
    age
    size
    look
    other1
    other2
    other3
    season
    collectionName
    productName
    quantity
    clientName
    serviceName
    unit
    itemTemplateType
    companies {
      items {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      nextToken
    }
  }
}
`;
export const deleteItemTemplate = `mutation DeleteItemTemplate($input: DeleteItemTemplateInput!) {
  deleteItemTemplate(input: $input) {
    id
    uic
    brand
    subBrand
    category
    subCategory
    type
    gender
    age
    size
    look
    other1
    other2
    other3
    season
    collectionName
    productName
    quantity
    clientName
    serviceName
    unit
    itemTemplateType
    companies {
      items {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      nextToken
    }
  }
}
`;
export const createBatchCard = `mutation CreateBatchCard($input: CreateBatchCardInput!) {
  createBatchCard(input: $input) {
    id
    createdAt
    name
    description
    inBacklog
    images
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    spaces {
      items {
        id
      }
      nextToken
    }
    services {
      items {
        id
        isComplete
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
    batchCardAllocations {
      items {
        id
        createdAt
        date
        obsolete
      }
      nextToken
    }
  }
}
`;
export const updateBatchCard = `mutation UpdateBatchCard($input: UpdateBatchCardInput!) {
  updateBatchCard(input: $input) {
    id
    createdAt
    name
    description
    inBacklog
    images
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    spaces {
      items {
        id
      }
      nextToken
    }
    services {
      items {
        id
        isComplete
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
    batchCardAllocations {
      items {
        id
        createdAt
        date
        obsolete
      }
      nextToken
    }
  }
}
`;
export const deleteBatchCard = `mutation DeleteBatchCard($input: DeleteBatchCardInput!) {
  deleteBatchCard(input: $input) {
    id
    createdAt
    name
    description
    inBacklog
    images
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    spaces {
      items {
        id
      }
      nextToken
    }
    services {
      items {
        id
        isComplete
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
    batchCardAllocations {
      items {
        id
        createdAt
        date
        obsolete
      }
      nextToken
    }
  }
}
`;
export const createBatchCardAllocation = `mutation CreateBatchCardAllocation($input: CreateBatchCardAllocationInput!) {
  createBatchCardAllocation(input: $input) {
    id
    createdAt
    date
    obsolete
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
    batchCardServices {
      items {
        id
        isComplete
      }
      nextToken
    }
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
  }
}
`;
export const updateBatchCardAllocation = `mutation UpdateBatchCardAllocation($input: UpdateBatchCardAllocationInput!) {
  updateBatchCardAllocation(input: $input) {
    id
    createdAt
    date
    obsolete
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
    batchCardServices {
      items {
        id
        isComplete
      }
      nextToken
    }
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
  }
}
`;
export const deleteBatchCardAllocation = `mutation DeleteBatchCardAllocation($input: DeleteBatchCardAllocationInput!) {
  deleteBatchCardAllocation(input: $input) {
    id
    createdAt
    date
    obsolete
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
    batchCardServices {
      items {
        id
        isComplete
      }
      nextToken
    }
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
  }
}
`;
export const createProductItem = `mutation CreateProductItem($input: CreateProductItemInput!) {
  createProductItem(input: $input) {
    id
    createdAt
    uic
    category
    subCategory
    type
    gender
    age
    size
    look
    other1
    other2
    other3
    productName
    clientName
    serviceName
    quantity
    unit
    season
    collectionName
    tagDescription
    taggedAt
    tagged
    previousTagDescriptions
    archived
    complete
    completedAt
    qcApproved
    returnedToItems
    qcApprovedAt
    completedServices
    productItemServices
    imageKeys
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    brand {
      id
      name
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      subBrands {
        nextToken
      }
      productItems {
        nextToken
      }
    }
    subBrand {
      id
      name
      brand {
        id
        name
      }
      productItems {
        nextToken
      }
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    notes {
      items {
        id
        note
        timestamp
      }
      nextToken
    }
    content {
      items {
        id
        header
        content
        createdAt
      }
      nextToken
    }
  }
}
`;
export const updateProductItem = `mutation UpdateProductItem($input: UpdateProductItemInput!) {
  updateProductItem(input: $input) {
    id
    createdAt
    uic
    category
    subCategory
    type
    gender
    age
    size
    look
    other1
    other2
    other3
    productName
    clientName
    serviceName
    quantity
    unit
    season
    collectionName
    tagDescription
    taggedAt
    tagged
    previousTagDescriptions
    archived
    complete
    completedAt
    qcApproved
    returnedToItems
    qcApprovedAt
    completedServices
    productItemServices
    imageKeys
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    brand {
      id
      name
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      subBrands {
        nextToken
      }
      productItems {
        nextToken
      }
    }
    subBrand {
      id
      name
      brand {
        id
        name
      }
      productItems {
        nextToken
      }
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    notes {
      items {
        id
        note
        timestamp
      }
      nextToken
    }
    content {
      items {
        id
        header
        content
        createdAt
      }
      nextToken
    }
  }
}
`;
export const deleteProductItem = `mutation DeleteProductItem($input: DeleteProductItemInput!) {
  deleteProductItem(input: $input) {
    id
    createdAt
    uic
    category
    subCategory
    type
    gender
    age
    size
    look
    other1
    other2
    other3
    productName
    clientName
    serviceName
    quantity
    unit
    season
    collectionName
    tagDescription
    taggedAt
    tagged
    previousTagDescriptions
    archived
    complete
    completedAt
    qcApproved
    returnedToItems
    qcApprovedAt
    completedServices
    productItemServices
    imageKeys
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    brand {
      id
      name
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      subBrands {
        nextToken
      }
      productItems {
        nextToken
      }
    }
    subBrand {
      id
      name
      brand {
        id
        name
      }
      productItems {
        nextToken
      }
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    notes {
      items {
        id
        note
        timestamp
      }
      nextToken
    }
    content {
      items {
        id
        header
        content
        createdAt
      }
      nextToken
    }
  }
}
`;
export const createProductItemNote = `mutation CreateProductItemNote($input: CreateProductItemNoteInput!) {
  createProductItemNote(input: $input) {
    id
    note
    timestamp
    author {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
    product {
      id
      createdAt
      uic
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      productName
      clientName
      serviceName
      quantity
      unit
      season
      collectionName
      tagDescription
      taggedAt
      tagged
      previousTagDescriptions
      archived
      complete
      completedAt
      qcApproved
      returnedToItems
      qcApprovedAt
      completedServices
      productItemServices
      imageKeys
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      notes {
        nextToken
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const updateProductItemNote = `mutation UpdateProductItemNote($input: UpdateProductItemNoteInput!) {
  updateProductItemNote(input: $input) {
    id
    note
    timestamp
    author {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
    product {
      id
      createdAt
      uic
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      productName
      clientName
      serviceName
      quantity
      unit
      season
      collectionName
      tagDescription
      taggedAt
      tagged
      previousTagDescriptions
      archived
      complete
      completedAt
      qcApproved
      returnedToItems
      qcApprovedAt
      completedServices
      productItemServices
      imageKeys
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      notes {
        nextToken
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const deleteProductItemNote = `mutation DeleteProductItemNote($input: DeleteProductItemNoteInput!) {
  deleteProductItemNote(input: $input) {
    id
    note
    timestamp
    author {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
    product {
      id
      createdAt
      uic
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      productName
      clientName
      serviceName
      quantity
      unit
      season
      collectionName
      tagDescription
      taggedAt
      tagged
      previousTagDescriptions
      archived
      complete
      completedAt
      qcApproved
      returnedToItems
      qcApprovedAt
      completedServices
      productItemServices
      imageKeys
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      notes {
        nextToken
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const createProductItemContent = `mutation CreateProductItemContent($input: CreateProductItemContentInput!) {
  createProductItemContent(input: $input) {
    id
    header
    content
    product {
      id
      createdAt
      uic
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      productName
      clientName
      serviceName
      quantity
      unit
      season
      collectionName
      tagDescription
      taggedAt
      tagged
      previousTagDescriptions
      archived
      complete
      completedAt
      qcApproved
      returnedToItems
      qcApprovedAt
      completedServices
      productItemServices
      imageKeys
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      notes {
        nextToken
      }
      content {
        nextToken
      }
    }
    createdAt
  }
}
`;
export const updateProductItemContent = `mutation UpdateProductItemContent($input: UpdateProductItemContentInput!) {
  updateProductItemContent(input: $input) {
    id
    header
    content
    product {
      id
      createdAt
      uic
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      productName
      clientName
      serviceName
      quantity
      unit
      season
      collectionName
      tagDescription
      taggedAt
      tagged
      previousTagDescriptions
      archived
      complete
      completedAt
      qcApproved
      returnedToItems
      qcApprovedAt
      completedServices
      productItemServices
      imageKeys
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      notes {
        nextToken
      }
      content {
        nextToken
      }
    }
    createdAt
  }
}
`;
export const deleteProductItemContent = `mutation DeleteProductItemContent($input: DeleteProductItemContentInput!) {
  deleteProductItemContent(input: $input) {
    id
    header
    content
    product {
      id
      createdAt
      uic
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      productName
      clientName
      serviceName
      quantity
      unit
      season
      collectionName
      tagDescription
      taggedAt
      tagged
      previousTagDescriptions
      archived
      complete
      completedAt
      qcApproved
      returnedToItems
      qcApprovedAt
      completedServices
      productItemServices
      imageKeys
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      notes {
        nextToken
      }
      content {
        nextToken
      }
    }
    createdAt
  }
}
`;
export const createBatchCardSpace = `mutation CreateBatchCardSpace($input: CreateBatchCardSpaceInput!) {
  createBatchCardSpace(input: $input) {
    id
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
  }
}
`;
export const updateBatchCardSpace = `mutation UpdateBatchCardSpace($input: UpdateBatchCardSpaceInput!) {
  updateBatchCardSpace(input: $input) {
    id
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
  }
}
`;
export const deleteBatchCardSpace = `mutation DeleteBatchCardSpace($input: DeleteBatchCardSpaceInput!) {
  deleteBatchCardSpace(input: $input) {
    id
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
  }
}
`;
export const createBatchCardService = `mutation CreateBatchCardService($input: CreateBatchCardServiceInput!) {
  createBatchCardService(input: $input) {
    id
    isComplete
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    batchCardAllocation {
      id
      createdAt
      date
      obsolete
      space {
        id
        createdAt
        name
        colorCode
        status
      }
      batchCardServices {
        nextToken
      }
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
    }
    service {
      id
      name
      icon
      batchCards {
        nextToken
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
    }
  }
}
`;
export const updateBatchCardService = `mutation UpdateBatchCardService($input: UpdateBatchCardServiceInput!) {
  updateBatchCardService(input: $input) {
    id
    isComplete
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    batchCardAllocation {
      id
      createdAt
      date
      obsolete
      space {
        id
        createdAt
        name
        colorCode
        status
      }
      batchCardServices {
        nextToken
      }
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
    }
    service {
      id
      name
      icon
      batchCards {
        nextToken
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
    }
  }
}
`;
export const deleteBatchCardService = `mutation DeleteBatchCardService($input: DeleteBatchCardServiceInput!) {
  deleteBatchCardService(input: $input) {
    id
    isComplete
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    batchCardAllocation {
      id
      createdAt
      date
      obsolete
      space {
        id
        createdAt
        name
        colorCode
        status
      }
      batchCardServices {
        nextToken
      }
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
    }
    service {
      id
      name
      icon
      batchCards {
        nextToken
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
    }
  }
}
`;
export const createFaq = `mutation CreateFaq($input: CreateFaqInput!) {
  createFaq(input: $input) {
    id
    question
    answer
  }
}
`;
export const updateFaq = `mutation UpdateFaq($input: UpdateFaqInput!) {
  updateFaq(input: $input) {
    id
    question
    answer
  }
}
`;
export const deleteFaq = `mutation DeleteFaq($input: DeleteFaqInput!) {
  deleteFaq(input: $input) {
    id
    question
    answer
  }
}
`;
