import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';

// GraphQL
import { createUser } from '../../graphql/mutations';
import { CreateUserMutation, CreateUserMutationVariables } from '../../API';

// Presentation/UI
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import PageWrapper from '../../Components/Layouts/PageWrapper';
import SignUpWrapper from '../../Components/Layouts/SignUpWrapper';
import Logo from '../../Components/Layouts/LogoWrapper';

// Local Components
import SignUpForm from './SignUpForm';

// Themes
import { Images } from '../../Themes';

export default class Login extends React.Component {
  render() {
    return (
      <PageWrapper>
        <SignUpWrapper>
          <React.Fragment>
            <FullWidthContainer align="center">
              <Link to="/">
                <Logo image={Images.flumeLogo} />
              </Link>
            </FullWidthContainer>
            <Mutation<CreateUserMutation, CreateUserMutationVariables>
              mutation={gql(createUser)}
            >
              {createUserMutation => {
                return <SignUpForm createUserMutation={createUserMutation} />;
              }}
            </Mutation>
          </React.Fragment>
        </SignUpWrapper>
        <Helmet title="Sign Up" />
      </PageWrapper>
    );
  }
}
