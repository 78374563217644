/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as moment from 'moment';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

/** GraphQL */
import { createProductItemNote } from '../../graphql/mutations';
import {
  getCompanyBatchCardByUser,
  getCompanyTaggedProductItemsByUser,
  getCompanyCompletedProductItemsByUser
} from '../../graphql/custom-queries';

/** Generated types */
import {
  CreateProductItemNoteMutation,
  CreateProductItemNoteMutationVariables
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Local components */
import PreviousNotes from './PreviousNotes';

/** Custom types */
import {
  Error,
  ModalWithMessageType,
  ProductItemNoteType
} from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

type Props = ModalWithMessageType & {
  batchCardId?: string;
  bulkAction?: boolean;
  productItemId: string;
  productItemIds?: Array<string>;
  previousNotes: Array<ProductItemNoteType>;
  readOnly?: boolean;
  userId: string;
};

type State = {
  error: Error;
  note: string;
};

class AddTagToProductItem extends React.Component<Props, State> {
  timeoutId: number = 0;
  state = {
    error: null,
    note: ''
  };

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Validation */
  validateForm = (note: string): boolean => {
    // Check for undefined or empty input fields
    if (!note) {
      this.setError('Please add a note');
      return false;
    }

    return true;
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** A product item isn't always associated with a batch card (i.e. tagged item).
   * In the case that a new note has been added to a product item, the refetch query
   * will be based on the tagged items
   * @param userId - id for signed in user
   * @param batchCardId - id for batch that the product item may be related to
   */
  returnRefetchQuery = (userId: string, batchCardId?: string) => {
    return batchCardId && batchCardId !== ''
      ? [
          {
            query: getCompanyBatchCardByUser,
            variables: {
              userId,
              batchCardId
            }
          },
          {
            query: getCompanyCompletedProductItemsByUser,
            variables: {
              id: userId
            }
          }
        ]
      : [
          {
            query: getCompanyTaggedProductItemsByUser,
            variables: {
              id: userId
            }
          }
        ];
  };

  /** Execute mutation to add a note to a product item(s)
   * @param note - the actual note to be saved
   * @param productItemNoteAuthorId - author id (the logged in user's id)
   * @param productItemNoteProductId - product item id
   * @param timestamp - timestamp for the moment the execution was carried out
   * @param createProductItemNoteMutation - graphql mutation for creating the note
   * @param batchCardId - batch card id
   * @param closeModal - function to close modal
   * @param notification - function to display toast message
   * @param bulkAction - flag to determine whether or not it's a bulk action being carried out
   * @param productIds - array of ids for selected product items
   */
  executeMutation = async (
    note: string,
    productItemNoteAuthorId: string,
    productItemNoteProductId: string,
    timestamp: string,
    createProductItemNoteMutation: ({}) => Promise<any>,
    batchCardId: string | undefined,
    closeModal: () => void,
    notification: (message: string, appearance?: string | undefined) => void,
    bulkAction?: boolean,
    productIds?: Array<string>
  ) => {
    if (bulkAction && productIds && productIds.length) {
      // Add a note to multiple product items

      await Promise.all(
        productIds.map(async (productItemId: string) => {
          await createProductItemNoteMutation({
            variables: {
              input: {
                note,
                productItemNoteAuthorId,
                productItemNoteProductId: productItemId,
                timestamp
              }
            },
            refetchQueries: this.returnRefetchQuery(
              productItemNoteAuthorId,
              batchCardId
            )
          });
          closeModal();
          notification('Notes have been added to the selected product items');
        })
      );
    } else {
      // Add a note to a single product item

      createProductItemNoteMutation({
        variables: {
          input: {
            note,
            productItemNoteAuthorId,
            productItemNoteProductId,
            timestamp
          }
        },
        refetchQueries: this.returnRefetchQuery(
          productItemNoteAuthorId,
          batchCardId
        )
      })
        .then(() => {
          closeModal();
          notification('Note has been added to product item');
        })
        .catch(err => {
          this.setError(err.message);
        });
    }
  };

  render() {
    const { error, note } = this.state;
    const {
      batchCardId,
      bulkAction,
      closeModal,
      notification,
      productItemId,
      productItemIds,
      previousNotes,
      readOnly,
      userId
    } = this.props;

    return (
      <div>
        <Mutation<
          CreateProductItemNoteMutation,
          CreateProductItemNoteMutationVariables
        >
          mutation={gql(createProductItemNote)}
        >
          {(createProductItemNoteMutation, { loading }) => (
            <Form
              onSubmit={e => {
                e.preventDefault();
                if (this.validateForm(note)) {
                  this.executeMutation(
                    note,
                    userId,
                    productItemId,
                    moment().toISOString(),
                    createProductItemNoteMutation,
                    batchCardId,
                    closeModal,
                    notification,
                    bulkAction,
                    productItemIds
                  );
                }
              }}
            >
              {!readOnly && (
                <div>
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <FormGroup>
                        <Label for="note">Product item note</Label>
                        <Input
                          type="textarea"
                          name="note"
                          value={note}
                          id="note"
                          placeholder="Type note here..."
                          onChange={e =>
                            this.setState({ note: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <FormGroup>
                        <StyledButton
                          type="submit"
                          label={!loading ? 'Add note' : <Loader />}
                          color={Colors.flumeDarkGreen}
                          background={Colors.flumeGreen}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              )}

              {error && <ErrorMessage errorMessage={error} />}
            </Form>
          )}
        </Mutation>
        <br />
        {!bulkAction && (
          <PreviousNotes
            batchCardId={batchCardId}
            previousNotes={previousNotes}
          />
        )}
      </div>
    );
  }
}

export default AddTagToProductItem;
