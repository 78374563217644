import * as React from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { MdCopyright } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

/** Presentation/UI */
import Button from '../../../Components/Styled/Button';
import ErrorMessage from '../../../Components/Styled/ErrorMessage';
import ForgotPassword from '../../Login/ForgotPassword';
import FullWidthContainer from '../../../Components/Layouts/FullWidthContainer';
import GlobalModalContainer from '../../../Components/Modal';
import Loader from '../../../Components/Loader';
import Span from '../../../Components/Styled/Span';

/** Context API */
import { UserSpaceContextConsumer } from '../../../Components/UserSpaceContextProvider';

/** Custom Hooks */
import useErrorHandler from '../../../CustomHooks/ErrorHandler';

/** Themes */
import { Colors } from '../../../Themes';

/** Utils */
import { signIn, validateForm } from './Utils/Helpers';

type Props = RouteComponentProps & {
  history: History;
};

const LoginForm: React.FC<Props> = ({ history }) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [forgotPasswordModal, displayForgotPasswordModal] = React.useState(
    false
  );
  const { error, showError } = useErrorHandler(null);

  return (
    <UserSpaceContextConsumer>
      {({ updateUserId }) => {
        return (
          <FullWidthContainer>
            <GlobalModalContainer
              toggleModal={() => displayForgotPasswordModal(false)}
              title="Forgot Password"
              modalDisplay={
                <ForgotPassword
                  closeModal={() => displayForgotPasswordModal(false)}
                />
              }
              modal={forgotPasswordModal}
            />
            <Form
              onSubmit={e => {
                e.preventDefault();
                if (validateForm(email, password, showError)) {
                  signIn(
                    email,
                    password,
                    history,
                    setLoading,
                    showError,
                    updateUserId
                  );
                }
              }}
            >
              <FormGroup>
                <Label for="userEmail">Email Address</Label>
                <Input
                  type="email"
                  name="email"
                  value={email.toLowerCase()}
                  id="userEmail"
                  placeholder="john@mail.com"
                  onChange={e => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="userPassword">Password</Label>
                <Input
                  type="password"
                  name="password"
                  value={password}
                  id="userPassword"
                  placeholder="Password"
                  onChange={e => setPassword(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="right-align">
                <Span
                  text="Forgot Password?"
                  color={Colors.flumeGreen}
                  pointer={true}
                  onClick={() => displayForgotPasswordModal(true)}
                />
              </FormGroup>
              <br />
              <br />
              <Button
                type="submit"
                disabled={loading}
                label={!loading ? 'Sign In' : <Loader />}
                background={Colors.flumeGreen}
              />
              <br />
              {error !== '' && <ErrorMessage errorMessage={error} />}
              <br />
              <br />
              <FullWidthContainer align="center">
                <Span text="FLUME" />{' '}
                <MdCopyright
                  size="1em"
                  style={{ position: 'relative', top: '-2px' }}
                />{' '}
                {new Date().getFullYear()}
              </FullWidthContainer>
            </Form>
          </FullWidthContainer>
        );
      }}
    </UserSpaceContextConsumer>
  );
};

export default withRouter(LoginForm);
