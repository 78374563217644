import * as React from 'react';
import { Query } from 'react-apollo';

/** GraphQL */
import { getCompanyBatchCardAllocationsForTheWeekByUser } from '../../graphql/custom-queries';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { returnNumberOfItemsInProgressForTheWeek } from './DashboardHelpers';

const ProductItemsWeekProgress: React.FC<{
  formattedDates: Array<string>;
  userId: string;
  productItemStatus: 'complete' | 'incomplete';
}> = ({ productItemStatus, formattedDates, userId }) => {
  return (
    <Query
      query={getCompanyBatchCardAllocationsForTheWeekByUser}
      variables={{
        id: userId,
        dayOne: formattedDates[0],
        dayTwo: formattedDates[1],
        dayThree: formattedDates[2],
        dayFour: formattedDates[3],
        dayFive: formattedDates[4],
        daySix: formattedDates[5],
        daySeven: formattedDates[6]
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loader color={Colors.flumeGreen} />;
        }
        if (error) {
          return <ErrorMessage errorMessage="Error" />;
        }

        if (!data || !data.getUser) {
          return <ErrorMessage errorMessage="Error" />;
        }

        const company = data.getUser.company;

        if (
          company.batchCards &&
          company.batchCards.items &&
          company.batchCards.items.length
        ) {
          const batchCards = company.batchCards.items;

          return (
            <span>
              {returnNumberOfItemsInProgressForTheWeek(
                batchCards,
                productItemStatus
              )}
            </span>
          );
        }

        return <span>0</span>;
      }}
    </Query>
  );
};

export default ProductItemsWeekProgress;
