import styled from 'styled-components';

/** Theme */
import { Colors } from '../../Themes';

const BatchCardDropzoneContainer = styled.section`
  & .dropzone div {
    width: 100% !important;
    height: 100%;
    border-radius: 5px;
    background-color: ${Colors.paleGrey};
  }

  & .dropzone:hover {
    cursor: pointer;
  }

  & .dropzone p {
    line-height: 25px;
    text-align: center;
  }

  & .dropzone h5 {
    line-height: 50px;
  }

  & .dropzone div input {
    width: 100%;
  }

  & .dropzone-content {
    position: relative;
    top: 45%;
  }

  & .choose-files-container {
    margin: 30px auto;
  }
`;

export default BatchCardDropzoneContainer;
