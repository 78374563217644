import * as React from 'react';
import { Query } from 'react-apollo';
import { Row, Col } from 'reactstrap';

/** GraphQL */
import { getCompanyBatchCardsByUser } from '../../graphql/custom-queries';

/** Presentation/UI */
import {
  DashboardSummaryCardContainer,
  DashboardSummaryCardHeader,
  DashboardSummaryCardNumberContainer
} from '../../Components/Styled/Dashboard';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/** Custom types */
import { FilterRangeType } from '../../CustomTypes';

/** Local components */
import BatchCardsWeekProgress from './BatchCardsWeekProgress';
import BatchCardsMonthProgress from './BatchCardsMonthProgress';
import BatchCardsYearProgress from './BatchCardsYearProgress';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { returnBatchCardsWithoutAllocations } from '../MainPlanner/BatchCardAllocationHelpers';
import { TIME_RANGE } from '../../Utils/Consts';

type Props = {
  currentMonth: string;
  currentYear: string;
  formattedDates: Array<string>;
  range: FilterRangeType;
  userId: string;
};

const BatchCards: React.FC<Props> = ({
  currentMonth,
  currentYear,
  range,
  userId,
  formattedDates
}) => {
  /** Return batch card summary data based on selected range
   * @param batchCardStatus
   * @param range
   */
  const returnBatchCardSummaryBasedOnSelectedRange = (
    batchCardStatus: 'complete' | 'incomplete',
    filterRange: FilterRangeType
  ): React.ReactNode => {
    switch (filterRange) {
      case TIME_RANGE.week:
        // Week
        return (
          <BatchCardsWeekProgress
            batchCardStatus={batchCardStatus}
            userId={userId}
            formattedDates={formattedDates}
          />
        );
      case TIME_RANGE.month:
        // Month
        return (
          <BatchCardsMonthProgress
            batchCardStatus={batchCardStatus}
            userId={userId}
            month={currentMonth}
          />
        );
      case TIME_RANGE.year:
        // Year
        return (
          <BatchCardsYearProgress
            batchCardStatus={batchCardStatus}
            year={currentYear}
            userId={userId}
          />
        );
      default:
        // Week
        return (
          <BatchCardsWeekProgress
            batchCardStatus={batchCardStatus}
            userId={userId}
            formattedDates={formattedDates}
          />
        );
    }
  };

  return (
    <Row>
      <Col xs={12} md={4} lg={4}>
        <DashboardSummaryCardContainer>
          <DashboardSummaryCardHeader>
            Batch Cards in Queue
          </DashboardSummaryCardHeader>
          <DashboardSummaryCardNumberContainer>
            <Query
              query={getCompanyBatchCardsByUser}
              variables={{ id: userId }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <Loader color={Colors.flumeGreen} />;
                }
                if (error) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                if (!data || !data.getUser) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                const company = data.getUser.company;

                if (
                  company.batchCards &&
                  company.batchCards.items &&
                  company.batchCards.items.length
                ) {
                  const batchCards = data.getUser.company.batchCards.items;

                  const batchCardsWithoutAllocations = returnBatchCardsWithoutAllocations(
                    batchCards
                  ).length;

                  return <span>{batchCardsWithoutAllocations}</span>;
                }

                return <span>0</span>;
              }}
            </Query>
          </DashboardSummaryCardNumberContainer>
        </DashboardSummaryCardContainer>
      </Col>
      <Col xs={12} md={4} lg={4}>
        <DashboardSummaryCardContainer>
          <DashboardSummaryCardHeader>
            Batch Cards In Production
          </DashboardSummaryCardHeader>
          <DashboardSummaryCardNumberContainer>
            {returnBatchCardSummaryBasedOnSelectedRange('incomplete', range)}
          </DashboardSummaryCardNumberContainer>
        </DashboardSummaryCardContainer>
      </Col>
      <Col xs={12} md={4} lg={4}>
        <DashboardSummaryCardContainer>
          <DashboardSummaryCardHeader>
            Batch Cards Completed
          </DashboardSummaryCardHeader>
          <DashboardSummaryCardNumberContainer>
            {returnBatchCardSummaryBasedOnSelectedRange('complete', range)}
          </DashboardSummaryCardNumberContainer>
        </DashboardSummaryCardContainer>
      </Col>
    </Row>
  );
};

export default BatchCards;
