import * as React from 'react';
import { IoMdPricetag } from 'react-icons/io';

/** Presentation/UI */
import PlaceholderWrapper from '../../Components/Layouts/PlaceholderWrapper';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { BRANDS_DESCRIPTION } from '../../Utils/Consts';

type Props = {
  openModal: () => void;
};

const Placeholder: React.FC<Props> = props => {
  return (
    <PlaceholderWrapper
      icon={<IoMdPricetag size="8.5em" color={Colors.flumeGreen} />}
      title="Add your Brands"
      text={BRANDS_DESCRIPTION}
      buttonLabel="Add Brand"
      buttonAction={props.openModal}
    />
  );
};

export default Placeholder;
