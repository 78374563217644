import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, Label, FormGroup } from 'reactstrap';
import Select from 'react-select';

/** Custom Hooks */
import useErrorHandler from '../../CustomHooks/ErrorHandler';

/** Generated types */
import {
  UpdateRoleInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  DeleteUserAllocationMutation,
  DeleteBatchCardAllocationMutationVariables
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Custom types */
import {
  UserType,
  ModalWithMessageType,
  SpacesWithUserAllocationType
} from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';
import {
  createUserAllocation,
  deleteUserAllocation
} from '../../graphql/mutations';

/** Utils */
import {
  filterByRoles,
  previouslySelectedUsers,
  returnUserRoles,
  returnUsers,
  selectUsers
} from './Utils';
import { executeMutations } from './Utils/mutationFunctions';
import { sortDropDownDataAlphabetically } from '../../Utils/Helpers';

type Props = ModalWithMessageType & {
  roles: Array<UpdateRoleInput>;
  space: SpacesWithUserAllocationType;
  addedUsers: Array<UserType>;
  userId: string;
  selectedSpaceId: string;
  allocationDate: string;
};

const allRoles = { value: 'All', label: 'All', permission: '' };

const AddMembersToSpaceForm: React.FC<Props> = ({
  addedUsers,
  closeModal,
  notification,
  space,
  userId,
  roles,
  selectedSpaceId,
  allocationDate
}) => {
  const [spaceId] = React.useState(space.id || '');
  const [loading, setLoading] = React.useState(false);
  const [userRoles] = React.useState([allRoles, ...returnUserRoles(roles)]);
  const [users, setUsers] = React.useState(returnUsers(addedUsers));
  const [existingUsers] = React.useState(
    previouslySelectedUsers(space, allocationDate)
  );
  const [selectedUsers, updateSelectedUsers] = React.useState(
    previouslySelectedUsers(space, allocationDate)
  );
  const { error } = useErrorHandler(null);

  return (
    <Mutation<UpdateUserMutation, UpdateUserMutationVariables>
      mutation={gql(createUserAllocation)}
    >
      {createUserAllocationMutation => (
        <Mutation<
          DeleteUserAllocationMutation,
          DeleteBatchCardAllocationMutationVariables
        >
          mutation={gql(deleteUserAllocation)}
        >
          {deleteUserAllocationMutation => (
            <Form
              onSubmit={e => {
                e.preventDefault();
                executeMutations(
                  spaceId,
                  setLoading,
                  selectedUsers,
                  userId,
                  users,
                  createUserAllocationMutation,
                  closeModal,
                  notification,
                  selectedSpaceId,
                  allocationDate,
                  space,
                  existingUsers,
                  deleteUserAllocationMutation
                );
              }}
            >
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <Label for="roles">Filter By Roles</Label>
                    <Select
                      onChange={selectedRole =>
                        filterByRoles(selectedRole, addedUsers, setUsers)
                      }
                      options={sortDropDownDataAlphabetically(userRoles)}
                      isSearchable={true}
                      className="select-styling"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <Label for="teamMembers">Select Team Members</Label>
                    <Select
                      onChange={chosenUsers =>
                        selectUsers(chosenUsers, updateSelectedUsers)
                      }
                      value={selectedUsers}
                      options={sortDropDownDataAlphabetically(users)}
                      isSearchable={true}
                      isMulti={true}
                      className="select-styling"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <StyledButton
                      disabled={loading}
                      type="submit"
                      label={
                        !loading ? 'Update Space Team Members' : <Loader />
                      }
                      color={Colors.flumeDarkGreen}
                      background={Colors.flumeGreen}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {error && <ErrorMessage errorMessage={error} />}
            </Form>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default AddMembersToSpaceForm;
