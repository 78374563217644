import * as React from 'react';
import * as moment from 'moment';
/** Presentation/UI */
import { ImageContainer } from '../../../Components/Styled/ImageContainer';

/** Generated types */
import { UpdateRoleInput } from '../../../API';

/** Custom types */
import {
  ImageType,
  SelectRoleType,
  SelectType,
  UserType,
  ValueType
  // SpaceWithUsersType
} from '../../../CustomTypes';

/** Return/display batch card images
 * @param productItemImages - array of details for each image related to a product item
 */
export const displayProductItemImages = (
  productItemImages: Array<ImageType>
): React.ReactNode => {
  if (productItemImages && productItemImages.length) {
    return productItemImages.map((productItemImage: ImageType) => {
      return (
        <div key={productItemImage.key}>
          <ImageContainer
            width="200px"
            height="200px"
            imageUrl={productItemImage.url}
          />
        </div>
      );
    });
  }
  return <span>No images have been uploaded</span>;
};

/** Return user roles created by company
 * @param roles - array of company roles
 */
export const returnUserRoles = (
  roles: Array<UpdateRoleInput>
): Array<SelectRoleType> => {
  if (roles && roles.length) {
    return roles.map((role: UpdateRoleInput) => {
      return {
        value: role.id || '',
        label: role.name || '',
        permission: role.permission || ''
      };
    });
  }
  return [];
};

/**
 * Return the users already added to this space
 * @param space - space
 * @param date - allocation date
 */
export const previouslySelectedUsers = (
  space,
  date: string
): Array<SelectType> => {
  if (space.spaceAllocation && space.spaceAllocation.items) {
    const userAllocations = space.spaceAllocation.items.filter(allocation => {
      return (
        moment(allocation.date).format('DD MMM YYYY') ===
        moment(date).format('DD MMM YYYY')
      );
    });
    const addedUsers = userAllocations.map(userAllocation => {
      return userAllocation.user;
    });
    return returnUsers(addedUsers);
  }
  return [];
};

/** Return users created by company
 * @param users - users created by company
 */
export const returnUsers = (users: Array<UserType>): Array<SelectType> => {
  if (users && users.length) {
    return users.map((user: UserType) => {
      const displayName =
        !user.firstName && !user.lastName
          ? `${user.email} (${
              user.userRole && user.userRole.name
                ? user.userRole.name
                : 'No assigned role'
            })`
          : `${user.firstName} ${user.lastName} (${
              user.userRole && user.userRole.name
                ? user.userRole.name
                : 'No assigned role'
            })`;

      return {
        value: user.id || '',
        label: displayName || '',
        object: user
      };
    });
  }

  return [];
};

/** Filter users list by selected role
 * @param selectedRole - role selected to filter users list
 * @param addedUsers - users already added to a space
 * @param setUsers - update local state of users in a particular space
 */
export const filterByRoles = (
  selectedRole: ValueType<SelectRoleType>,
  addedUsers: Array<UserType>,
  setUsers: React.Dispatch<React.SetStateAction<Array<SelectType>>>
) => {
  const userOptions = returnUsers(addedUsers);
  if (selectedRole.value === 'All') {
    setUsers(userOptions);
  } else {
    const filteredUsersByRole = userOptions.filter(
      (user: SelectType) =>
        user.object.userRole && user.object.userRole.id === selectedRole.value
    );

    if (filteredUsersByRole && filteredUsersByRole.length) {
      setUsers(filteredUsersByRole);
    } else {
      setUsers(userOptions);
    }
  }
};

/** Select users for a space and update space
 * @param chosenUsers - users selected from dropdown list
 * @param updateSelectedUsers - function to update the local state of selected users
 */
export const selectUsers = (
  chosenUsers: ValueType<SelectType>,
  updateSelectedUsers: React.Dispatch<React.SetStateAction<Array<SelectType>>>
) => {
  updateSelectedUsers(chosenUsers);
};
