import * as React from 'react';
import { Query, Mutation } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { withToastManager } from 'react-toast-notifications';
import { MdPeople, MdSearch, MdFileDownload } from 'react-icons/md';
import { CSVLink } from 'react-csv';

/** Presentation/UI */
import DataImport from '../../Components/DataImport/MembersDataImport';
import Table from '../../Components/Table';
import StyledButton from '../../Components/Styled/Button';
import PlaceholderWrapper from '../../Components/Layouts/PlaceholderWrapper';
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import GlobalModalContainer from '../../Components/Modal';
import PageLoader from '../../Components/PageLoader';
import {
  TableHeader,
  TableHeaderContainer
} from '../../Components/Styled/ListViewElements';
import DialogModal from '../../Components/DialogModal';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Local components */
import AddTeamMember from './AddTeamMember';
import EditTeamMember from './EditTeamMember';
import RowActions from './RowActions';

/** Themes */
import { Colors } from '../../Themes';

// types
import {
  UpdateUserInput,
  UserStatus,
  Permission,
  UpdateUserMutation,
  UpdateUserMutationVariables
} from '../../API';

/** GraphQL */
import { getCompanyUsersByUser } from '../../graphql/custom-queries';

/** Utils */
import {
  TEAM_DESCRIPTION,
  HTTP_METHODS,
  SUPER_USER_GROUP,
  TEAM_MEMBERS_CSV_HEADINGS,
  DEFAULT_USER_ROLE
} from '../../Utils/Consts';
import { convertPixelsToRem } from '../../Utils/Helpers';

/** Custom Types */
import {
  ToastNotificationType,
  SytemUserType,
  UserStatusType
} from '../../CustomTypes';

/** API */
import AppSyncConfig from '../../aws-exports';
import { COGNITO_INVITE_USER } from '../../Utils/LambdaEndpoints';
import { apiRequest } from '../../Utils/API';
import { updateUser } from '../../graphql/mutations';
import gql from 'graphql-tag';

type UserType = UpdateUserInput & {
  company?: {
    id?: string;
  };
  userRole?: {
    id: string;
    name: string;
    permission: Permission;
  };
};

type TeamProps = {
  toastManager: ToastNotificationType;
  data: any;
};

type State = {
  modal: boolean;
  importModal: boolean;
  editModal: boolean;
  searchFilter: boolean;
  user: UserType;
  dialogModal: boolean;
  loadingRequest: boolean;
};

class Team extends React.Component<TeamProps> {
  state: State = {
    modal: false,
    importModal: false,
    searchFilter: false,
    editModal: false,
    user: { id: '', email: '' },
    dialogModal: false,
    loadingRequest: false
  };

  handleInviteUser = async (updateUserMutation: any): Promise<any> => {
    this.setState({ loadingRequest: true });
    const { email } = this.state.user;

    const bodyParams = {
      userPoolId: AppSyncConfig.aws_user_pools_id,
      email
    };

    const user = await apiRequest(
      COGNITO_INVITE_USER,
      HTTP_METHODS.POST,
      bodyParams
    ).catch(e => {
      this.setState({ loading: false });
      this.toastNotification(e.message, 'error');
    });
    // create the user in dynamodb
    if (user && user.Attributes && user.Attributes.length) {
      const id = user.Attributes[0].Value;

      updateUserMutation({
        variables: {
          input: {
            id,
            inviteLater: false
          }
        },
        refetchQueries: [
          {
            query: getCompanyUsersByUser,
            variables: {
              id
            }
          }
        ]
      })
        .then(() => {
          this.setState({ loadingRequest: false });
          this.closeDialogModal();
          this.toastNotification(`Invite email has been sent to ${email} `);
        })
        .catch(err => {
          this.setState({ loadingRequest: false });
          this.toastNotification(err.message, 'error');
        });
    }

    if (typeof user === 'string') {
      // cognito response
      this.setState({ loading: false });
      this.toastNotification(user, 'error');
    }
  };

  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Close import file modal */
  closeImportModal = (): void => {
    this.setState({
      importModal: false
    });
  };

  /** Open import file modal */
  openImportModal = (): void => {
    this.setState({
      importModal: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  /** Open modal */
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  /** Close edit modal */
  closeEditModal = (): void => {
    this.setState({
      editModal: false
    });
  };

  /** Open edit modal */
  openEditModal = (user: UserType): void => {
    this.setState({ user, editModal: true });
  };

  /** Close dialog modal */
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false
    });
  };

  /** Open dialog modal */
  openDialogModal = (user: UserType): void => {
    this.setState({
      dialogModal: true,
      user
    });
  };

  /** Return permission */
  returnUserPermission = (user: UserType): SytemUserType => {
    const permission =
      user.userRole && user.userRole.permission
        ? user.userRole.permission
        : Permission.NON_USER;
    switch (permission) {
      case Permission.SUPER_USER:
        return 'Super User';
      case Permission.USER:
        return 'User';
      case Permission.NON_USER:
        return 'Non User';
      default:
        return 'Non User';
    }
  };

  /** Return status */
  returnUserStatus = (user: UpdateUserInput): UserStatusType => {
    const status = user.status || UserStatus.INACTIVE;
    return status === UserStatus.ACTIVE ? 'Active' : 'Inactive';
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const {
      modal,
      importModal,
      editModal,
      searchFilter,
      loadingRequest,
      dialogModal
    } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId, userRole, userEmail }) => {
          return (
            <BackendWrapper>
              <Query query={getCompanyUsersByUser} variables={{ id: userId }}>
                {({ loading, error, data }) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (error || !data || !data.getUser) {
                    return (
                      <div>There was a problem loading your company data</div>
                    );
                  }

                  const company = data.getUser.company;
                  const companyId = data.getUser.company.id;
                  const createdCompanyRoles = company.roles.items;

                  // add new field invited. Used in csv file
                  company.users.items.forEach((user: UserType) => {
                    user['invited'] = (!user.inviteLater).toString() || 'false';
                    user.userRole = user.userRole || DEFAULT_USER_ROLE;
                    return user;
                  });

                  if (
                    company.users &&
                    company.users.items &&
                    company.users.items.length
                  ) {
                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeModal}
                          title="Add Team Member"
                          modalDisplay={
                            <AddTeamMember
                              companyId={companyId}
                              roles={createdCompanyRoles}
                              userEmail={userEmail}
                              notification={this.toastNotification}
                              closeModal={this.closeModal}
                              userId={userId}
                            />
                          }
                          modal={modal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeImportModal}
                          title="Import Members"
                          modalDisplay={
                            <DataImport
                              companyId={companyId}
                              roles={createdCompanyRoles}
                              closeModal={this.closeImportModal}
                              notification={this.toastNotification}
                            />
                          }
                          modal={importModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeEditModal}
                          title="Edit Team Member"
                          modalDisplay={
                            <EditTeamMember
                              user={this.state.user}
                              roles={createdCompanyRoles}
                              notification={this.toastNotification}
                              closeModal={this.closeEditModal}
                            />
                          }
                          modal={editModal}
                        />
                        <Mutation<
                          UpdateUserMutation,
                          UpdateUserMutationVariables
                        >
                          mutation={gql(updateUser)}
                        >
                          {updateUserMutation => (
                            <React.Fragment>
                              <GlobalModalContainer
                                toggleModal={this.closeModal}
                                title=""
                                modalDisplay={
                                  <DialogModal
                                    loading={loadingRequest}
                                    title="Are you sure you want to invite this user?"
                                    handleAccept={() =>
                                      this.handleInviteUser(updateUserMutation)
                                    }
                                    toggleModal={this.closeDialogModal}
                                  />
                                }
                                modal={dialogModal}
                              />
                              <TableHeaderContainer>
                                <TableHeader>
                                  <span>Manage Team Members</span>
                                </TableHeader>
                                <StyledButton
                                  type="button"
                                  label="Add Member"
                                  width="120px"
                                  onClick={this.openModal}
                                  color={Colors.flumeDarkGreen}
                                  background={Colors.flumeGreen}
                                />
                                <StyledButton
                                  type="button"
                                  label="Import Members"
                                  width="120px"
                                  onClick={this.openImportModal}
                                  color={Colors.flumeGreen}
                                  background={Colors.grey}
                                />
                                <CSVLink
                                  data={company.users.items}
                                  headers={TEAM_MEMBERS_CSV_HEADINGS}
                                  target="_blank"
                                  filename="TeamMembers.csv"
                                >
                                  <StyledButton
                                    type="button"
                                    label={
                                      <MdFileDownload
                                        size="1.3em"
                                        color={Colors.flumeDarkGreen}
                                      />
                                    }
                                    width="auto"
                                    color={Colors.flumeDarkGreen}
                                    background={Colors.flumeGreen}
                                  />
                                </CSVLink>
                                <StyledButton
                                  type="button"
                                  label={
                                    <MdSearch
                                      size="1.3em"
                                      color={Colors.flumeDarkGreen}
                                    />
                                  }
                                  width="auto"
                                  onClick={this.toggleSearchFilter}
                                  color={Colors.flumeDarkGreen}
                                  background={Colors.flumeGreen}
                                />
                              </TableHeaderContainer>
                              <Table
                                data={company.users.items}
                                columns={[
                                  {
                                    Header: 'First Name',
                                    accessor: 'firstName',
                                    sortable: true,
                                    filterable: searchFilter,
                                    Filter: ({ filter, onChange }) => (
                                      <div className="searchContainer">
                                        <MdSearch
                                          size={convertPixelsToRem(18)}
                                          className="searchIcon"
                                        />
                                        <input
                                          type="text"
                                          className="searchBox"
                                          value={filter ? filter.value : ''}
                                          onChange={event =>
                                            onChange(event.target.value)
                                          }
                                          style={{
                                            width: '100%'
                                          }}
                                        />
                                      </div>
                                    )
                                  },
                                  {
                                    Header: 'Last Name',
                                    accessor: 'lastName',
                                    sortable: true,
                                    filterable: searchFilter,
                                    Filter: ({ filter, onChange }) => (
                                      <div className="searchContainer">
                                        <MdSearch
                                          size={convertPixelsToRem(18)}
                                          className="searchIcon"
                                        />
                                        <input
                                          type="text"
                                          className="searchBox"
                                          value={filter ? filter.value : ''}
                                          onChange={event =>
                                            onChange(event.target.value)
                                          }
                                          style={{
                                            width: '100%'
                                          }}
                                        />
                                      </div>
                                    )
                                  },
                                  {
                                    Header: 'Email Address',
                                    accessor: 'email',
                                    sortable: true,
                                    filterable: searchFilter,
                                    Filter: ({ filter, onChange }) => (
                                      <div className="searchContainer">
                                        <MdSearch
                                          size={convertPixelsToRem(18)}
                                          className="searchIcon"
                                        />
                                        <input
                                          type="text"
                                          className="searchBox"
                                          value={filter ? filter.value : ''}
                                          onChange={event =>
                                            onChange(event.target.value)
                                          }
                                          style={{
                                            width: '100%'
                                          }}
                                        />
                                      </div>
                                    )
                                  },
                                  {
                                    Header: 'Role',
                                    accessor: 'userRole.name',
                                    sortable: true,
                                    filterable: searchFilter,
                                    Filter: ({ filter, onChange }) => (
                                      <div className="searchContainer">
                                        <MdSearch
                                          size={convertPixelsToRem(18)}
                                          className="searchIcon"
                                        />
                                        <input
                                          type="text"
                                          className="searchBox"
                                          value={filter ? filter.value : ''}
                                          onChange={event =>
                                            onChange(event.target.value)
                                          }
                                          style={{
                                            width: '100%'
                                          }}
                                        />
                                      </div>
                                    )
                                  },
                                  {
                                    id: 'permission',
                                    Header: 'Permission',
                                    accessor: (user: UserType) => {
                                      return this.returnUserPermission(user);
                                    },
                                    sortable: true,
                                    filterable: searchFilter,
                                    Filter: ({ filter, onChange }) => (
                                      <div className="searchContainer">
                                        <MdSearch
                                          size={convertPixelsToRem(18)}
                                          className="searchIcon"
                                        />
                                        <input
                                          type="text"
                                          className="searchBox"
                                          value={filter ? filter.value : ''}
                                          onChange={event =>
                                            onChange(event.target.value)
                                          }
                                          style={{
                                            width: '100%'
                                          }}
                                        />
                                      </div>
                                    )
                                  },
                                  {
                                    id: 'status',
                                    Header: 'Status',
                                    accessor: (user: UpdateUserInput) => {
                                      return this.returnUserStatus(user);
                                    },
                                    sortable: true,
                                    filterable: searchFilter,
                                    Filter: ({ filter, onChange }) => (
                                      <div className="searchContainer">
                                        <MdSearch
                                          size={convertPixelsToRem(18)}
                                          className="searchIcon"
                                        />
                                        <input
                                          type="text"
                                          className="searchBox"
                                          value={filter ? filter.value : ''}
                                          onChange={event =>
                                            onChange(event.target.value)
                                          }
                                          style={{
                                            width: '100%'
                                          }}
                                        />
                                      </div>
                                    )
                                  },
                                  {
                                    id: 'invite-user',
                                    Header: 'Invite User',
                                    accessor: (user: UpdateUserInput) => {
                                      const { inviteLater } = user;

                                      if (inviteLater) {
                                        return (
                                          <StyledButton
                                            type="button"
                                            label="Invite user"
                                            width={convertPixelsToRem(100)}
                                            onClick={() =>
                                              this.openDialogModal(user)
                                            }
                                          />
                                        );
                                      }

                                      return (
                                        <div>
                                          <span>Invited</span>
                                        </div>
                                      );
                                    },
                                    sortable: true,
                                    filterable: false,
                                    Filter: ({ filter, onChange }) => (
                                      <div className="searchContainer">
                                        <MdSearch
                                          size={convertPixelsToRem(18)}
                                          className="searchIcon"
                                        />
                                        <input
                                          type="text"
                                          className="searchBox"
                                          value={filter ? filter.value : ''}
                                          onChange={event =>
                                            onChange(event.target.value)
                                          }
                                          style={{
                                            width: '100%'
                                          }}
                                        />
                                      </div>
                                    )
                                  },
                                  {
                                    id: 'row-actions',
                                    Header: '',
                                    accessor: (user: UserType) => {
                                      if (userRole === SUPER_USER_GROUP) {
                                        return (
                                          <RowActions
                                            openModal={this.openEditModal}
                                            user={user}
                                          />
                                        );
                                      }
                                      return null;
                                    },
                                    sortable: false,
                                    filterable: false,
                                    width: 30
                                  }
                                ]}
                                defaultPageSize={5}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                              />
                            </React.Fragment>
                          )}
                        </Mutation>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeModal}
                          title="Add Team Member"
                          modalDisplay={
                            <AddTeamMember
                              companyId={companyId}
                              roles={createdCompanyRoles}
                              userEmail={userEmail}
                              notification={this.toastNotification}
                              closeModal={this.closeModal}
                              userId={userId}
                            />
                          }
                          modal={modal}
                        />
                        <PlaceholderWrapper
                          icon={
                            <MdPeople size="8.5em" color={Colors.flumeGreen} />
                          }
                          title="Add Team Members"
                          text={TEAM_DESCRIPTION}
                          buttonLabel="Add Team Member"
                          buttonAction={this.openModal}
                        />
                      </div>
                    );
                  }
                }}
              </Query>
              <Helmet title="Manage Team" />
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(Team);
