/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { adopt } from 'react-adopt';

/** GraphQL */
import {
  createBatchCard,
  createBatchCardService,
  updateProductItem
} from '../../graphql/mutations';

/** Types */
import {
  CreateBatchCardMutation,
  CreateBatchCardMutationVariables,
  CreateBatchCardServiceMutation,
  CreateBatchCardServiceMutationVariables,
  UpdateProductItemMutation,
  UpdateProductItemMutationVariables
} from '../../API';

/** Mutation component for creating a new batch card */
const createNewBatchCard = (props: { render: ({}) => Mutation }) => (
  <Mutation<CreateBatchCardMutation, CreateBatchCardMutationVariables>
    mutation={gql(createBatchCard)}
  >
    {(mutation, result) => props.render({ mutation, result })}
  </Mutation>
);

/** Mutation component for creating a new batch card service */
const addBatchCardService = (props: { render: ({}) => Mutation }) => (
  <Mutation<
    CreateBatchCardServiceMutation,
    CreateBatchCardServiceMutationVariables
  >
    mutation={gql(createBatchCardService)}
  >
    {(mutation, result) => props.render({ mutation, result })}
  </Mutation>
);

/** Mutation for updating a product item */
const updateAProductItem = (props: { render: ({}) => Mutation }) => (
  <Mutation<UpdateProductItemMutation, UpdateProductItemMutationVariables>
    mutation={gql(updateProductItem)}
  >
    {(mutation, result) => props.render({ mutation, result })}
  </Mutation>
);

const Composed = adopt({
  addBatchCardService,
  createNewBatchCard,
  updateAProductItem
});

export default Composed;
