import * as React from 'react';
import { Button } from 'reactstrap';

// Presentation/UI
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import LottieWrapper from '../../Components/Anim/LottieWrapper';
import Link from '../../Components/Styled/Link';

// Themes
import { Colors } from '../../Themes';

class Success extends React.Component<{}> {
  render() {
    return (
      <FullWidthContainer>
        <LottieWrapper
          loop={false}
          width={320}
          height={260}
          anim={require('../../LottieFiles/success2.json')}
        />
        <br />
        <br />
        <p className="center-align">
          Initial setup complete!
          <br />
          <br />
          <Link
            to="/"
            label={<Button>Take me to login</Button>}
            color={Colors.flumeGreen}
          />
        </p>
      </FullWidthContainer>
    );
  }
}

export default Success;
