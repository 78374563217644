/** Generated types */
import { UpdateProductItemInput } from '../../API';

/** Custom types */
import { BatchCard } from '../../CustomTypes';

type ProductItem = UpdateProductItemInput & {
  batchCard?: BatchCard | null;
};

/** Return product items not associated with a batch card
 * @param productItems - all product items created by a company
 */
export const returnProductItemsNotAssociatedWithBatchCard = (
  productItems: Array<ProductItem>
): Array<ProductItem> => {
  return productItems.filter(
    (productItem: ProductItem) => !productItem.batchCard
  );
};
