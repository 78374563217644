import * as React from 'react';
import * as moment from 'moment';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import * as uuid from 'uuid';
import { FaPlusCircle } from 'react-icons/fa';

/** GraphQL */
import { createSpace } from '../../graphql/mutations';
import { getCompany } from '../../graphql/queries';
import { getCompanySpacesByUser } from '../../graphql/custom-queries';

import {
  CreateSpaceMutation,
  CreateSpaceMutationVariables,
  SpaceStatus
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';
import Span from '../../Components/Styled/Span';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Custom Types */
import { Error, SpaceType } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

/** Local components */
import SpaceRow from './SpaceRow';

type Props = {
  companyId: string;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  spaces: Array<SpaceType | null>;
  error: Error;
};

const DEFAULT_SPACE = {
  id: uuid(),
  name: '',
  colorCode: Colors.flumeGreen,
  status: SpaceStatus.ACTIVE
};

class AddSpaceModal extends React.Component<Props, State> {
  state: State = {
    spaces: [DEFAULT_SPACE],
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Add another space */
  addAnotherSpace = (): void => {
    this.setState({
      spaces: [
        ...this.state.spaces,
        {
          id: uuid(),
          name: '',
          colorCode: Colors.snow,
          status: SpaceStatus.ACTIVE
        }
      ]
    });
  };

  /** Validate Form */
  validateForm = (): boolean => {
    const { spaces } = this.state;

    const noNamedSpaces = spaces.filter(
      // @ts-ignore
      (space: SpaceType) => space.name === ''
    );

    if (noNamedSpaces && noNamedSpaces.length) {
      this.setError('Please name every space.');
      return false;
    }

    return true;
  };

  /** Update a space row name */
  updateSpaceRowName = (id: string, name: string): void => {
    const { spaces } = this.state;
    // @ts-ignore
    const updatedSpaces = spaces.map((space: SpaceType) => {
      if (space.id === id) {
        space.name = name;
        return space;
      }
      return space;
    });
    this.setState({ spaces: updatedSpaces });
  };

  /** Update space row color code */
  updateSpaceRowColorCode = (id: string, colorCode: string): void => {
    const { spaces } = this.state;
    // @ts-ignore
    const updatedSpaces = spaces.map((space: SpaceType) => {
      if (space.id === id) {
        space.colorCode = colorCode;
        return space;
      }
      return space;
    });
    this.setState({ spaces: updatedSpaces });
  };

  /** Delete space row */
  deleteRow = (id: string): void => {
    const { spaces } = this.state;
    // @ts-ignore
    const newSpaces = spaces.filter((space: SpaceType) => space.id !== id);
    this.setState({ spaces: newSpaces });
  };

  /** Render spaces */
  renderSpaces = (): React.ReactNode | null => {
    const { spaces } = this.state;
    // @ts-ignore
    return spaces.map((space: SpaceType) => {
      return (
        <SpaceRow
          key={space.id}
          id={space.id}
          deleteRow={this.deleteRow}
          updateSpaceRowName={this.updateSpaceRowName}
          updateSpaceRowColorCode={this.updateSpaceRowColorCode}
          name={space.name}
          status={SpaceStatus.ACTIVE}
          colorCode={space.colorCode}
        />
      );
    });
  };

  render() {
    const { error, spaces } = this.state;
    const { companyId, notification, closeModal } = this.props;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <Mutation<CreateSpaceMutation, CreateSpaceMutationVariables>
              mutation={gql(createSpace)}
            >
              {(createSpaceMutation, { loading }) => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    if (this.validateForm()) {
                      // Loop through all spaces
                      spaces.forEach(space => {
                        createSpaceMutation({
                          variables: {
                            input: {
                              createdAt: moment().toISOString(),
                              name: !space || !space.name ? '' : space.name,
                              colorCode:
                                !space || !space.colorCode
                                  ? ''
                                  : space.colorCode,
                              status:
                                !space || !space.status
                                  ? SpaceStatus.ACTIVE
                                  : space.status,
                              spaceCompanyId: companyId
                            }
                          },
                          refetchQueries: [
                            {
                              query: gql(getCompany),
                              variables: {
                                id: companyId
                              }
                            },
                            {
                              query: getCompanySpacesByUser,
                              variables: {
                                id: userId
                              }
                            }
                          ]
                        })
                          .then(res => {
                            notification('Space created successfully');
                          })
                          .catch(err => {
                            this.setState({
                              spaces: [DEFAULT_SPACE]
                            });
                            this.setError(err.message);
                          });
                      });

                      this.setState({ spaces: [DEFAULT_SPACE] });
                      closeModal();
                    }
                  }}
                >
                  <React.Fragment>{this.renderSpaces()}</React.Fragment>
                  <br />
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <FormGroup>
                        <FaPlusCircle size="1.15em" />
                        &nbsp;&nbsp;
                        <Span
                          onClick={this.addAnotherSpace}
                          pointer={true}
                          text="Add Another Space"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <FormGroup>
                        <StyledButton
                          type="submit"
                          label={!loading ? 'Save' : <Loader />}
                          color={Colors.flumeDarkGreen}
                          background={Colors.flumeGreen}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {error && <ErrorMessage errorMessage={error} />}
                </Form>
              )}
            </Mutation>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default AddSpaceModal;
