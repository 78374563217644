import * as React from 'react';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import { Row, Col } from 'reactstrap';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { RouteComponentProps } from 'react-router';

/** Presentational components */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import {
  ToggleWeekContainer,
  ArrowContainer,
  DateContainer
} from '../../Components/Styled/MainPlanner';
import StyledButton from '../../Components/Styled/Button';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Local components */
import ReportingSorting from './ReportingSorting';
import ReportingMainPlanner from './ReportingMainPlanner';
import ReportingQualityControl from './ReportingQualityControl';
import ReportingComplete from './ReportingComplete';
import ReportingSpaces from './ReportingSpaces';

/** Utils */
import {
  returnDatePeriod,
  generateWeek,
  returnIncrementedTimeValue,
  returnDecrementedTimeValue,
  returnDataCount,
  returnDataKeys,
  sortDropDownDataAlphabetically
} from '../../Utils/Helpers';
import { FILTER_REPORT_OPTIONS, REPORTING_PATH } from '../../Utils/Consts';

/** Custom Types */
import {
  SelectType,
  ValueType,
  ChartData,
  SelectOptionsType
} from '../../CustomTypes';
import { UpdateSpaceInput } from '../../API';

/** App theme */
import Colors from '../../Themes/Colors';
import { Query } from 'react-apollo';
import {
  getCompanySpacesByUser,
  getCompanyServicesByUser
} from '../../graphql/custom-queries';
import ReportingServices from './ReportingServices';

type State = {
  elementWidth: number | undefined;
  elementHeight: number | undefined;
  currentWeek: Array<Date>;
  date: moment.Moment | null;
  filter: string;
  filterPeriod: moment.Moment;
  loadingNewItemsRequest: boolean;
  loadingTaggedItemsRequest: boolean;
  serviceName: string;
};

type Props = RouteComponentProps & {
  productItems: any;
  client: any;
  match: string;
};

class Reporting extends React.Component<Props, State> {
  private chartDataSetOne: ChartData = [];
  private chartDataSetTwo: ChartData = [];

  state = {
    elementWidth: 0,
    elementHeight: 0,
    currentWeek: [],
    date: null,
    chartDataSetTwo: [],
    filter: 'week',
    filterPeriod: moment(),
    loadingNewItemsRequest: false,
    loadingTaggedItemsRequest: false,
    serviceName: ''
  };

  componentDidMount() {
    const currentWeek = this.returnWeek(null);

    this.setState({ currentWeek });
  }

  /** Select parameter to filter by
   * @param filter - param to filter calendar view by
   */
  selectFilter = (filter: ValueType<SelectType>) => {
    this.setState({ filter: filter.value });
  };

  /**
   *  Get current filter period
   *
   * @returns {React.ReactNode}
   */
  returnFilterPeriod = (): React.ReactNode => {
    const { filter, filterPeriod, currentWeek } = this.state;

    if (filter === 'week') {
      return returnDatePeriod(currentWeek);
    }

    if (filter === 'month') {
      return moment(filterPeriod).format('MMM YYYY');
    }

    if (filter === 'year') {
      return moment(filterPeriod).format('YYYY');
    }

    return null;
  };

  /**
   * Return  dates for upcoming week based on current choosen date
   *
   * @param {(moment.Moment | null)} chosenDate
   * @returns {Array<Date>}
   */
  returnWeek = (chosenDate: moment.Moment | null): Array<Date> => {
    const startOfWeek = moment().startOf('isoWeek');

    const daysOfCurrentWeek = generateWeek(startOfWeek, moment.weekdays());

    return daysOfCurrentWeek;
  };

  /** Toggle calendar to next week */
  nextWeek = () => {
    const { currentWeek } = this.state;
    const endOfCurrentWeek = moment(currentWeek[0]).endOf('isoWeek');
    const startOfNewWeek = endOfCurrentWeek.add(1, 'days');

    const daysOfNextWeek = generateWeek(startOfNewWeek, moment.weekdays());

    this.setState({ currentWeek: daysOfNextWeek });
  };

  /** Toggle calendar to previous week */
  prevWeek = () => {
    const { currentWeek } = this.state;
    const startOfCurrentWeek = moment(currentWeek[0]).startOf('isoWeek');
    const startOfPreviousWeek = startOfCurrentWeek.subtract(7, 'days');

    const daysOfPreviousWeek = generateWeek(
      startOfPreviousWeek,
      moment.weekdays()
    );

    this.setState({ currentWeek: daysOfPreviousWeek });
  };

  /** Increase time based on current filter */
  incrementTimeValue = (): void => {
    const { filter, filterPeriod } = this.state;

    if (filter === 'week') {
      this.nextWeek();
    } else if (filter === 'month') {
      this.setState({
        filterPeriod: returnIncrementedTimeValue(
          moment(filterPeriod),
          1,
          'month'
        )
      });
    } else if (filter === 'year') {
      this.setState({
        filterPeriod: returnIncrementedTimeValue(
          moment(filterPeriod),
          1,
          'year'
        )
      });
    }
  };

  /** Decrease time based on current filter */
  decrementTimeValue = (): void => {
    const { filter, filterPeriod } = this.state;

    if (filter === 'week') {
      this.prevWeek();
    } else if (filter === 'month') {
      this.setState({
        filterPeriod: returnDecrementedTimeValue(
          moment(filterPeriod),
          1,
          'month'
        )
      });
    } else if (filter === 'year') {
      this.setState({
        filterPeriod: returnDecrementedTimeValue(
          moment(filterPeriod),
          1,
          'year'
        )
      });
    }
  };

  /**
   * Update calendar to the week of a selected date
   *
   * @param {Date} - the chosen date
   */
  updateCurrentWeekByDate = (date: Date | null) => {
    if (date) {
      const startOfWeek = moment(date).startOf('isoWeek');
      const weekArray = moment.weekdays();
      const daysOfWeekInSelectedDate = weekArray.map((d, i) => {
        return startOfWeek
          .clone()
          .add(i, 'd')
          .toDate();
      });

      this.setState({ currentWeek: daysOfWeekInSelectedDate });
    }
  };

  /**
   * update first data set
   *
   * @param {data} - data array
   */
  updateChartDataSetOne = (data: ChartData) => {
    this.chartDataSetOne = data;
  };

  /**
   * update second data set
   *
   * @param {data} - data array
   */
  updateChartDataSetTwo = (data: ChartData): void => {
    this.chartDataSetTwo = data;
  };

  /**
   * update the service name when dropdown changes
   *
   * @param {string} serviceName
   */
  updateServiceName = (serviceName: string): void => {
    this.setState({ serviceName });
  };

  /** Return company spaces as react select options
   * @param spaces - created company spaces
   */
  returnCompanySpaces = (
    spaces: Array<UpdateSpaceInput>
  ): SelectOptionsType => {
    if (spaces && spaces.length) {
      return sortDropDownDataAlphabetically(
        spaces.map((space: UpdateSpaceInput) => {
          return {
            label: space.name || space.id,
            value: space.id,
            object: space
          };
        })
      );
    }

    return [];
  };

  /**
   *  return csv array
   *
   * @param {string} pathname
   *
   * @returns {(Array<Array<string | number>>)}
   */
  returnCSVDataObject = (pathname: string): Array<Array<string | number>> => {
    const { filter, filterPeriod, serviceName } = this.state;
    const { chartDataSetOne, chartDataSetTwo } = this;

    switch (pathname) {
      case `${REPORTING_PATH}/sorting`:
        return [
          [
            'Item Type',
            ...returnDataKeys(
              chartDataSetOne,
              filter,
              filterPeriod,
              this.returnFilterPeriod()
            )
          ],
          ['new', ...returnDataCount(chartDataSetOne)],
          ['tagged', ...returnDataCount(chartDataSetTwo)]
        ];

      case `${REPORTING_PATH}/main-planner`:
        return [
          [
            'Item Type',
            ...returnDataKeys(
              chartDataSetTwo,
              filter,
              filterPeriod,
              this.returnFilterPeriod()
            )
          ],
          ['batch card', ...returnDataCount(chartDataSetTwo)],
          ['items within batch card', ...returnDataCount(chartDataSetOne)]
        ];

      case `${REPORTING_PATH}/quality-control`:
        return [
          [
            'Item Type',
            ...returnDataKeys(
              chartDataSetTwo,
              filter,
              filterPeriod,
              this.returnFilterPeriod()
            )
          ],
          ['QC Approved items', ...returnDataCount(chartDataSetOne)],
          [
            'batch cards for QC Approved items',
            ...returnDataCount(chartDataSetTwo)
          ]
        ];

      case `${REPORTING_PATH}/complete`:
        return [
          [
            'Item Type',
            ...returnDataKeys(
              chartDataSetOne,
              filter,
              filterPeriod,
              this.returnFilterPeriod()
            )
          ],
          ['completed items', ...returnDataCount(chartDataSetOne)]
        ];

      case `${REPORTING_PATH}/perfomance-spaces`:
        return [
          [
            'Item Type',
            ...returnDataKeys(
              chartDataSetOne,
              filter,
              filterPeriod,
              this.returnFilterPeriod()
            )
          ],
          ['product item', ...returnDataCount(chartDataSetOne)],
          ['batch card', ...returnDataCount(chartDataSetTwo)]
        ];

      case `${REPORTING_PATH}/perfomance-services`:
        return [
          [
            'Service Name',
            ...returnDataKeys(
              chartDataSetOne,
              filter,
              filterPeriod,
              this.returnFilterPeriod()
            )
          ],
          [serviceName, ...returnDataCount(chartDataSetOne)]
        ];

      default:
        return [];
    }
  };

  _renderRoutePath = (
    userId: string,
    pathname: string,
    spaceId: string,
    serviceName: string
  ): React.ReactNode => {
    const { filter, filterPeriod, currentWeek } = this.state;

    switch (pathname) {
      case `${REPORTING_PATH}/sorting`:
        return (
          <ReportingSorting
            userId={userId}
            filter={filter}
            filterPeriod={filterPeriod}
            currentWeek={currentWeek}
            updateChartDataSetOne={this.updateChartDataSetOne}
            updateChartDataSetTwo={this.updateChartDataSetTwo}
          />
        );
      case `${REPORTING_PATH}/main-planner`:
        return (
          <ReportingMainPlanner
            userId={userId}
            filter={filter}
            filterPeriod={filterPeriod}
            currentWeek={currentWeek}
            updateChartDataSetOne={this.updateChartDataSetOne}
            updateChartDataSetTwo={this.updateChartDataSetTwo}
          />
        );

      case `${REPORTING_PATH}/quality-control`:
        return (
          <ReportingQualityControl
            userId={userId}
            filter={filter}
            filterPeriod={filterPeriod}
            currentWeek={currentWeek}
            updateChartDataSetOne={this.updateChartDataSetOne}
            updateChartDataSetTwo={this.updateChartDataSetTwo}
          />
        );

      case `${REPORTING_PATH}/complete`:
        return (
          <ReportingComplete
            userId={userId}
            filter={filter}
            filterPeriod={filterPeriod}
            currentWeek={currentWeek}
            updateChartDataSetOne={this.updateChartDataSetOne}
          />
        );

      case `${REPORTING_PATH}/perfomance-spaces`:
        return (
          <ReportingSpaces
            userId={userId}
            spaceId={spaceId}
            filter={filter}
            filterPeriod={filterPeriod}
            currentWeek={currentWeek}
            updateChartDataSetOne={this.updateChartDataSetOne}
            updateChartDataSetTwo={this.updateChartDataSetTwo}
          />
        );

      case `${REPORTING_PATH}/perfomance-services`:
        return (
          <ReportingServices
            userId={userId}
            serviceName={serviceName}
            filter={filter}
            filterPeriod={filterPeriod}
            currentWeek={currentWeek}
            updateChartDataSetOne={this.updateChartDataSetOne}
          />
        );

      default:
        return null;
    }
  };

  render() {
    const {
      location: { pathname }
    } = this.props;

    return (
      <UserSpaceContextConsumer>
        {({ userId, updateSpaceId, spaceId }) => {
          return (
            <BackendWrapper>
              <FullWidthContainer>
                <Row
                  style={{
                    justifyContent: 'flex-end',
                    marginRight: '20px'
                  }}
                >
                  <Col
                    xs={12}
                    md={6}
                    lg={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <CSVLink
                      data={this.returnCSVDataObject(pathname)}
                      target="_blank"
                      filename={`reporting-for-section-${
                        pathname.split('/')[2]
                      }-${this.returnFilterPeriod()}.csv`}
                      onClick={() => this.forceUpdate()}
                    >
                      <StyledButton
                        type="button"
                        label="Export to CSV"
                        width="120px"
                        margin="0px"
                        color={Colors.flumeGreen}
                        background={Colors.flumeDarkGreen}
                      />
                    </CSVLink>
                  </Col>
                  {pathname === `${REPORTING_PATH}/perfomance-spaces` && (
                    <Query
                      query={getCompanySpacesByUser}
                      variables={{ id: userId }}
                      notifyOnNetworkStatusChange={true}
                    >
                      {({ loading, error, data }) => {
                        if (loading) {
                          return <Loader size={50} color={Colors.flumeGreen} />;
                        }

                        if (error || !data || !data.getUser) {
                          return (
                            <ErrorMessage errorMessage=" There was a problem loading your spaces" />
                          );
                        }
                        const items = data.getUser.company.spaces.items;

                        return (
                          <Col xs={12} md={6} lg={2}>
                            <Select
                              // @ts-ignore
                              onChange={(space: SelectType) => {
                                updateSpaceId(space.value);
                              }}
                              options={this.returnCompanySpaces(items)}
                              isSearchable={true}
                              placeholder="Select a space"
                              className="select-styling"
                            />
                          </Col>
                        );
                      }}
                    </Query>
                  )}
                  {pathname === `${REPORTING_PATH}/perfomance-services` && (
                    <Query
                      query={getCompanyServicesByUser}
                      variables={{ id: userId }}
                      notifyOnNetworkStatusChange={true}
                    >
                      {({ loading, error, data }) => {
                        if (loading) {
                          return <Loader size={50} color={Colors.flumeGreen} />;
                        }

                        if (error || !data || !data.getUser) {
                          return (
                            <ErrorMessage errorMessage=" There was a problem loading your services" />
                          );
                        }
                        const items = data.getUser.company.services.items;

                        return (
                          <Col xs={12} md={6} lg={2}>
                            <Select
                              // @ts-ignore
                              onChange={(service: SelectType) => {
                                this.updateServiceName(service.label);
                              }}
                              options={this.returnCompanySpaces(items)}
                              isSearchable={true}
                              placeholder="Select a service"
                              className="select-styling"
                            />
                          </Col>
                        );
                      }}
                    </Query>
                  )}
                  <Col xs={12} md={6} lg={2}>
                    <Select
                      // @ts-ignore
                      onChange={this.selectFilter}
                      options={FILTER_REPORT_OPTIONS}
                      isSearchable={true}
                      placeholder="Filter"
                      className="select-styling"
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    lg={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <ToggleWeekContainer>
                      <ArrowContainer onClick={this.decrementTimeValue}>
                        <MdNavigateBefore size="1.5em" color={Colors.grey} />
                      </ArrowContainer>
                      <DatePicker
                        onChange={date => this.updateCurrentWeekByDate(date)}
                        customInput={
                          <DateContainer>
                            {this.returnFilterPeriod()}
                          </DateContainer>
                        }
                        dateFormat="dd/MM/yyyy"
                      />
                      <ArrowContainer onClick={this.incrementTimeValue}>
                        <MdNavigateNext size="1.5em" color={Colors.grey} />
                      </ArrowContainer>
                    </ToggleWeekContainer>
                  </Col>
                </Row>
                {this._renderRoutePath(
                  userId,
                  pathname,
                  spaceId,
                  this.state.serviceName
                )}
              </FullWidthContainer>
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default Reporting;
