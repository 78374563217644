import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

/** GraphQL */
import { updateProductItem } from '../../graphql/mutations';
import { getCompanyTaggedProductItemsByUser } from '../../graphql/custom-queries';

/** Generated types */
import {
  UpdateProductItemMutation,
  UpdateProductItemMutationVariables
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Local components */
import PreviousTags from '../MainPlanner/PreviousTags';

/** Themes */
import { Colors } from '../../Themes';

/** Custom types */
import { Error, ModalWithMessageType } from '../../CustomTypes';

type Props = ModalWithMessageType & {
  previousTagDescriptions: Array<string>;
  productItemId: string;
  tagDescription: string;
  userId: string;
};

type State = {
  error: Error;
  tagDescription: string;
};

class UpdateTagDescription extends React.Component<Props, State> {
  timeoutId: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      tagDescription: props.tagDescription
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Validation */
  validateForm = (tagDescription: string): boolean => {
    // Check for undefined or empty input fields
    if (!tagDescription) {
      this.setError('Please add a tag description');
      return false;
    }

    return true;
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { error, tagDescription } = this.state;
    const {
      closeModal,
      notification,
      previousTagDescriptions,
      productItemId,
      userId
    } = this.props;
    const allTags = [tagDescription, ...previousTagDescriptions];

    return (
      <Mutation<UpdateProductItemMutation, UpdateProductItemMutationVariables>
        mutation={gql(updateProductItem)}
      >
        {(updateProductItemMutation, { loading }) => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateForm(tagDescription)) {
                updateProductItemMutation({
                  variables: {
                    input: {
                      id: productItemId,
                      tagDescription,
                      previousTagDescriptions: allTags
                    }
                  },
                  refetchQueries: [
                    {
                      query: getCompanyTaggedProductItemsByUser,
                      variables: {
                        id: userId
                      }
                    }
                  ]
                })
                  .then(() => {
                    closeModal();
                    notification(
                      'Product item has been tagged and moved back to sorting'
                    );
                  })
                  .catch(err => {
                    this.setError(err.message);
                  });
              }
            }}
          >
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="tag">Tag description</Label>
                  <Input
                    type="textarea"
                    name="tag"
                    value={tagDescription}
                    id="tag"
                    placeholder="Type description here..."
                    onChange={e =>
                      this.setState({ tagDescription: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <StyledButton
                    type="submit"
                    label={!loading ? 'Update' : <Loader />}
                    color={Colors.flumeDarkGreen}
                    background={Colors.flumeGreen}
                  />
                </FormGroup>
              </Col>
            </Row>
            {error && <ErrorMessage errorMessage={error} />}
            {previousTagDescriptions && previousTagDescriptions.length ? (
              <PreviousTags
                previousTags={previousTagDescriptions}
                subHeading={true}
                height={200}
              />
            ) : null}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default UpdateTagDescription;
