import * as React from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  Col,
  Label,
  FormGroup,
  Input
} from 'reactstrap';
import { IoIosTrash } from 'react-icons/io';
import { SketchPicker } from 'react-color';

/** Presentation/UI */
import { SelectedColor } from '../../Components/Styled/ListViewElements';
import SketchPickerContainer from '../../Components/Styled/SketchPickerContainer';

/** Custom Types */
import { SpaceType } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

/* helper methods */
import { convertPixelsToRem } from '../../Utils/Helpers';

type State = SpaceType & {
  dropdownOpen: boolean;
};

type Props = SpaceType & {
  deleteRow(id: string): void;
  updateSpaceRowName(id: string, spaceName?: string): void;
  updateSpaceRowColorCode(id: string, colorCode?: string): void;
};

class SpaceRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.id || '',
      name: props.name || '',
      status: props.status,
      colorCode: props.colorCode || '#6666ff',
      dropdownOpen: false
    };
  }

  /** Update space name */
  handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { id } = this.state;
    const { updateSpaceRowName } = this.props;
    const { value }: { value: string } = e.currentTarget;
    this.setState({
      name: value
    });
    updateSpaceRowName(id, value);
  };

  /** Update space color */
  handleChangeComplete = (color: any) => {
    const { id } = this.state;
    const { updateSpaceRowColorCode } = this.props;
    this.setState({ colorCode: color.hex });
    updateSpaceRowColorCode(id, color.hex);
  };

  /** Toggle color picker dropdown */
  toggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  render() {
    const { id, name, colorCode, dropdownOpen } = this.state;
    const { deleteRow } = this.props;
    return (
      <Row>
        <Col xs={12} md={8} lg={8}>
          <FormGroup>
            <Label for="spaceName">Space Name</Label>
            <Input
              type="text"
              name="spaceName"
              value={name}
              id="spaceName"
              placeholder="Space name"
              onChange={this.handleNameChange}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={2} lg={2}>
          <FormGroup>
            <Label>Color</Label>
            <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
              <DropdownToggle
                tag="span"
                onClick={this.toggle}
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
              >
                <SelectedColor
                  style={{
                    border: `0.5px solid ${Colors.catSkillWhite}`,
                    height: '2.00rem'
                  }}
                  background={colorCode}
                />
              </DropdownToggle>
              <DropdownMenu>
                <SketchPickerContainer>
                  <SketchPicker
                    color={colorCode}
                    onChangeComplete={this.handleChangeComplete}
                  />
                </SketchPickerContainer>
              </DropdownMenu>
            </Dropdown>
          </FormGroup>
        </Col>
        <Col xs={12} md={2} lg={2}>
          <FormGroup>
            <Label />
            <IoIosTrash
              viewBox="0 30 450 512"
              size={convertPixelsToRem(42)}
              color={Colors.pink}
              onClick={() => deleteRow(id)}
              style={{ position: 'relative', top: '25px', cursor: 'pointer' }}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default SpaceRow;
