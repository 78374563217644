import * as React from 'react';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import AppSyncConfig from './aws-exports';
import { ApolloProvider } from 'react-apollo';
import Amplify, { Auth } from 'aws-amplify';
import { Rehydrated } from 'aws-appsync-react';
import { ToastProvider } from 'react-toast-notifications';
import { Elements, StripeProvider } from 'react-stripe-elements';

import 'whatwg-fetch';

// Context Provider
import UserSpaceContextProvider from './Components/UserSpaceContextProvider';
import ContextProvider from './Components/ContextProvider';

// Root Container
import RootContainer from './Containers/RootContainer';

/** utils */
import { STRIPE_PUBLISHABLE_KEY } from './Utils/LambdaEndpoints';

// Amplify/AppSync config
Amplify.configure(AppSyncConfig);

const client: any = new AWSAppSyncClient(
  {
    url: AppSyncConfig.aws_appsync_graphqlEndpoint,
    region: AppSyncConfig.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken()
    }
  },
  {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  }
);

const App: React.FC<{}> = () => {
  return (
    <ContextProvider>
      <UserSpaceContextProvider>
        <ApolloProvider client={client}>
          <Rehydrated>
            <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
              <Elements>
                <ToastProvider placement="bottom-center">
                  <RootContainer />
                </ToastProvider>
              </Elements>
            </StripeProvider>
          </Rehydrated>
        </ApolloProvider>
      </UserSpaceContextProvider>
    </ContextProvider>
  );
};

export default App;
