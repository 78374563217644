import * as React from 'react';
import { Button, FormGroup, Form, Label, Input } from 'reactstrap';
import { withToastManager } from 'react-toast-notifications';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/** Custom Hooks */
import useErrorHandler from '../../CustomHooks/ErrorHandler';

/** Utils */
import { validateForm, sendMessageToSupport } from './Utils/Helpers';
import { ToastNotificationType } from '../../CustomTypes';

type Props = {
  toastManager: ToastNotificationType;
};

const ContactForm: React.FC<Props> = ({ toastManager }) => {
  const [name, setName] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { error, showError } = useErrorHandler(null);

  /** Success notification
   * @param message - message to be displayed
   * @param appearance - the type of notification (i.e. success or error)
   */
  const toastNotification = (message: string, appearance?: string) => {
    toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        if (validateForm(name, subject, message, showError)) {
          // Send message
          sendMessageToSupport(
            name,
            subject,
            message,
            setLoading,
            showError,
            toastNotification
          );
          // Restore default state values
          setName('');
          setSubject('');
          setMessage('');
        }
      }}
    >
      <FormGroup>
        <Label for="name">Name</Label>
        <Input
          type="text"
          name="name"
          value={name}
          id="name"
          placeholder="Name"
          onChange={e => setName(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="subject">Subject</Label>
        <Input
          type="text"
          name="subject"
          value={subject}
          id="subject"
          placeholder="Subject"
          onChange={e => setSubject(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="message">Message</Label>
        <Input
          type="textarea"
          name="message"
          value={message}
          id="message"
          placeholder="Your message"
          onChange={e => setMessage(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Button type="submit">{loading ? <Loader /> : 'Send'}</Button>
      </FormGroup>
      {error && <ErrorMessage errorMessage={error} />}
    </Form>
  );
};

export default withToastManager(ContactForm);
