import styled from 'styled-components';

/** Themes */
import { Colors } from '../../Themes';

type Props = {
  small?: boolean;
  width?: string;
  height?: string;
  imageUrl?: string;
};

export const ImageContainer = styled.div<Props>`
  width: ${props =>
    props.small ? '100px' : props.width ? props.width : '100%'};
  height: ${props =>
    props.small ? '100px' : props.height ? props.height : '400px'};
  margin: 5px;
  border-radius: 5px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.4);
  background: ${(props: Props) =>
    props.imageUrl ? `url(${props.imageUrl})` : Colors.lightGray};
  display: block;
  float: left;
`;

export const ImagesWrapper = styled.div`
  width: 100%;
  overflow: auto;
  white-space: nowrap;
`;

export const ImageWrapper = styled.div`
  float: left;
`;
