import * as React from 'react';
import { Query } from 'react-apollo';
import { FiLayers } from 'react-icons/fi';
import { Draggable } from 'react-beautiful-dnd';
import { withToastManager } from 'react-toast-notifications';

/** GraphQL */
import { getCompanyBatchCardsByUser } from '../../graphql/custom-queries';
import { UpdateServiceInput, CreateItemTemplateInput } from '../../API';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Local components */
import BacklogView from './BacklogView';
import BacklogBatchCard from './BacklogBatchCard';

/** Presentation/UI */
import GlobalModalContainer from '../../Components/Modal';
import {
  BatchCardContainer,
  BatchCardHeading,
  BatchCardSpaceIdentifier,
  SelectedBatchCardIcon
} from '../../Components/Styled/MainPlanner';
import { ServiceIconsContainer } from '../../Components/Styled/ListViewElements';
import { Wrapper, Container } from '../../Components/Styled/Backlog';
import ErrorMessageContainer from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/** Theme */
import { Colors, ServiceIcons } from '../../Themes';

/** Custom types */
import {
  BatchCard,
  SelectType,
  ToastNotificationType
} from '../../CustomTypes';

/** Utils */
import { BATCH_TYPE, SORTING_OPTIONS } from '../../Utils/Consts';

type SelectedService = UpdateServiceInput & {
  service: UpdateServiceInput;
};

type State = {
  batchCard: BatchCard | null;
  itemTemplate: CreateItemTemplateInput | null;
  modal: boolean;
  services: Array<UpdateServiceInput> | null;
  sortingOrder: SelectType;
};

type Props = {
  toastManager: ToastNotificationType;
};

class Backlog extends React.Component<Props, State> {
  state = {
    batchCard: null,
    itemTemplate: null,
    services: null,
    modal: false,
    sortingOrder: SORTING_OPTIONS[0]
  };

  /** Close calendar view modal */
  closeModal = (): void => {
    this.setState({
      batchCard: null,
      modal: false
    });
  };

  /** Open calendar view  modal
   * @param batchCard - selected batch card
   */
  openModal = (
    batchCard: BatchCard,
    itemTemplate: CreateItemTemplateInput,
    services: Array<UpdateServiceInput>
  ): void => {
    this.setState({
      batchCard,
      itemTemplate,
      services,
      modal: true
    });
  };

  /* Select sorting order */
  selectSortingOrder = (sortingOrder: SelectType): void => {
    this.setState({ sortingOrder });
  };

  /** Return selected services as JSX
   * @param batchCardServices - services added to a batch card
   */
  returnSelectedServices = (
    batchCardServices: Array<UpdateServiceInput>
  ): React.ReactNode => {
    // @ts-ignore
    return batchCardServices.map((selectedService: SelectedService) => {
      if (selectedService && selectedService.service) {
        return (
          <SelectedBatchCardIcon key={selectedService.service.id}>
            <img
              alt=""
              src={
                selectedService.service.icon
                  ? selectedService.service.icon
                  : ServiceIcons.Editing
              }
            />
          </SelectedBatchCardIcon>
        );
      }
      return null;
    });
  };

  /** Return batch cards
   * @param batchCards - batch cards in backlog
   */
  returnBatchCards = (
    batchCards: Array<BatchCard>,
    itemTemplate: CreateItemTemplateInput,
    services: Array<UpdateServiceInput>
  ): React.ReactNode => {
    return batchCards.map((batchCard: BatchCard, i) => {
      const numberOfProductItems = batchCard.productItems.items.length;
      const completeOfProductItems = batchCard.productItems.items.filter(
        card => card.complete === true
      ).length;

      return (
        <Draggable
          key={batchCard.id}
          draggableId={`${BATCH_TYPE.card}_${batchCard.id}`}
          index={i}
        >
          {(provided, snapshot) => (
            <BatchCardContainer
              key={batchCard.id}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={() => this.openModal(batchCard, itemTemplate, services)}
            >
              <BatchCardHeading>{batchCard.name}</BatchCardHeading>
              <span>
                {completeOfProductItems} / {numberOfProductItems}
              </span>
              {'   '}
              <FiLayers size="1em" color={Colors.lightGray} />
              <br />
              <br />
              <ServiceIconsContainer>
                {this.returnSelectedServices(batchCard.services.items)}
              </ServiceIconsContainer>
              <br />
              <BatchCardSpaceIdentifier />
            </BatchCardContainer>
          )}
        </Draggable>
      );
    });
  };

  /** Success notification
   * @param message - message on notification
   * @param appearance - type of message (success/error)
   */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const {
      batchCard,
      itemTemplate,
      modal,
      services,
      sortingOrder
    } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <Wrapper>
              <GlobalModalContainer
                toggleModal={this.closeModal}
                largeModal={true}
                title="Update Batch Card"
                modalDisplay={
                  batchCard && itemTemplate && services ? (
                    <BacklogBatchCard
                      batchCard={batchCard}
                      closeModal={this.closeModal}
                      itemTemplate={itemTemplate || {}}
                      notification={this.toastNotification}
                      services={services || { items: [] }}
                      userId={userId}
                    />
                  ) : (
                    <span>Please select a batch card</span>
                  )
                }
                modal={modal}
              />
              <Container>
                <Query
                  query={getCompanyBatchCardsByUser}
                  variables={{ id: userId }}
                >
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <Loader />;
                    }
                    if (error || !data) {
                      return (
                        <ErrorMessageContainer errorMessage="There was a problem loading your company batch cards" />
                      );
                    }

                    return (
                      <BacklogView
                        data={data}
                        returnBatchCards={this.returnBatchCards}
                        sortingOrder={sortingOrder}
                      />
                    );
                  }}
                </Query>
              </Container>
            </Wrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(Backlog);
