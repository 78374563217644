import styled from 'styled-components';

/** Themes */
import { Colors } from '../../Themes';

export const SubscriptionPlansWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const SubscriptionPlanCard = styled.div`
  margin: auto;
  padding: 10px;
  border-radius: 8px;
  border-top: 5px solid ${Colors.flumeGreen};
  height: auto;
  box-shadow: 0 2px 2px 0 rgba(14, 30, 37, 0.32);
  width: 100%;
`;
export const SubscriptionPlanCardHeading = styled.h2`
  text-align: center;
  font-size: 1.65em;
  color: ${Colors.darkGreen};
  padding: 7px;
  text-transform: capitalize;
`;

export const SubscriptionPlanCardPrice = styled.h2`
  color: ${Colors.darkGrey};
  text-align: center;
  font-size: 2.95em;
`;
export const CurrencySymbol = styled.sup`
  color: #666;
  font-size: 0.5em;
`;

export const SubscriptionPlanCardSubHeading = styled.p`
  color: ${Colors.grey};
  font-weight: 100;
  text-align: center;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 10px;
`;
