/** Stripe Products */
import {
  SUBSCRIPTION_PACKAGES,
  FREE_TRIAL,
  STANDARD_PACKAGE,
  PREMIUM_PACKAGE
} from '../../Utils/Consts';

export type Product = {
  id: string;
  name: string;
  description: string;
  users: string;
  price: number;
  package: SUBSCRIPTION_PACKAGES;
  period: string;
};

/** Stripe product plans */
export const PRODUCT_PLANS: Array<Product> = [
  FREE_TRIAL,
  STANDARD_PACKAGE,
  PREMIUM_PACKAGE
];
