import * as React from 'react';
import { Row } from 'reactstrap';
import * as moment from 'moment';
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';

/** Presentation/UI */
import BackendWrapper from '../../../Components/Layouts/BackendWrapper';
import { DashboardWrapper } from '../../../Components/Styled/Dashboard';
import {
  CalendarControls,
  ToggleWeekContainer,
  ArrowContainer,
  DateContainer
} from '../../../Components/Styled/MainPlanner';

/** Custom types */
import { ValueType, FilterRangeType } from '../../../CustomTypes';

/** Themes */
import { Colors } from '../../../Themes';

/** Local components */
import Companies from './Companies';
import Items from './Items';
import Spaces from './Spaces';
import Users from './Users';

/** Utils */
import {
  returnDatePeriod,
  returnFormattedDates,
  returnWeek,
  prevWeek,
  nextWeek,
  updateCurrentWeekByDate
} from '../../../Utils/Helpers';
import { TIME_PERIOD_FILTER, TIME_RANGE } from '../../../Utils/Consts';

type SelectType = {
  value: FilterRangeType;
  label: string;
};

const Dashboard = () => {
  const [currentWeek, updateWeek] = React.useState(returnWeek());
  const [currentMonth, updateMonth] = React.useState(
    moment().format('MMM YYYY')
  );
  const [currentYear, updateCurrentYear] = React.useState(
    moment().format('YYYY')
  );
  const [filterRange, updateFilterRange] = React.useState({
    value: TIME_RANGE.week,
    label: 'Week'
  });

  /** Select space to filter by
   * @param filterRange - space to filter calendar view by
   */
  const selectFilterRange = (filterRange: ValueType<SelectType>) => {
    updateFilterRange(filterRange);
  };

  /** Next range (range is based on week, month or year)
   * @param range - range based on selected period (week, month or year)
   * @param currentWeek - the current week in focus
   * @param currentMonth - the current month in focus
   * @param currentYear - the current year in focus
   */
  const nextRange = (
    range: FilterRangeType,
    currentWeek: Array<Date>,
    currentMonth: string,
    currentYear: string
  ) => {
    switch (range) {
      case TIME_RANGE.week:
        updateWeek(nextWeek(currentWeek));
        break;
      case TIME_RANGE.month:
        updateMonth(
          moment(currentMonth)
            .add(1, 'M')
            .format('MMM YYYY')
        );
        break;
      case TIME_RANGE.year:
        updateCurrentYear(
          moment(currentYear)
            .add('years', 1)
            .format('YYYY')
        );
        break;
      default:
        break;
    }
  };

  /** Previous range (range is based on week, month or year)
   * @param range - range based on selected period (week, month or year)
   * @param currentWeek - the current week in focus
   * @param currentMonth - the current month in focus
   * @param currentYear - the current year in focus
   */
  const previousRange = (
    range: FilterRangeType,
    currentWeek: Array<Date>,
    currentMonth: string,
    currentYear: string
  ) => {
    switch (range) {
      case TIME_RANGE.week:
        updateWeek(prevWeek(currentWeek));
        break;
      case TIME_RANGE.month:
        updateMonth(
          moment(currentMonth)
            .subtract(1, 'M')
            .format('MMM YYYY')
        );
        break;
      case TIME_RANGE.year:
        updateCurrentYear(
          moment(currentYear)
            .subtract('years', 1)
            .format('YYYY')
        );
        break;
      default:
        break;
    }
  };

  /** Return range display
   * @param range - range based on selected period (week, month or year)
   * @param currentWeek - the current week in focus
   * @param currentMonth - the current month in focus
   * @param currentYear - the current year in focus
   */
  const returnRangeDisplay = (
    range: FilterRangeType,
    currentWeek: Array<Date>,
    currentMonth: string,
    currentYear: string
  ): string => {
    switch (range) {
      case TIME_RANGE.week:
        return returnDatePeriod(currentWeek);
      case TIME_RANGE.month:
        return currentMonth;
      case TIME_RANGE.year:
        return currentYear;
      default:
        return returnDatePeriod(currentWeek);
    }
  };

  const selectedWeek = returnFormattedDates(currentWeek, 'YYYY-MM-DD[T]');

  return (
    <BackendWrapper>
      <DashboardWrapper fullWidth={true}>
        <CalendarControls>
          <Select
            onChange={selectFilterRange}
            value={filterRange}
            options={TIME_PERIOD_FILTER}
            isSearchable={true}
            placeholder="Filter By Range"
            className="select-styling"
          />
          <ToggleWeekContainer>
            <ArrowContainer
              onClick={() =>
                previousRange(
                  filterRange.value,
                  currentWeek,
                  currentMonth,
                  currentYear
                )
              }
            >
              <MdNavigateBefore size="1.5em" color={Colors.grey} />
            </ArrowContainer>
            <DatePicker
              onChange={date => {
                updateWeek(updateCurrentWeekByDate(date, currentWeek));
              }}
              customInput={
                <DateContainer>
                  {returnRangeDisplay(
                    filterRange.value,
                    currentWeek,
                    currentMonth,
                    currentYear
                  )}
                </DateContainer>
              }
              dateFormat="dd/MM/yyyy"
            />
            <ArrowContainer
              onClick={() =>
                nextRange(
                  filterRange.value,
                  currentWeek,
                  currentMonth,
                  currentYear
                )
              }
            >
              <MdNavigateNext size="1.5em" color={Colors.grey} />
            </ArrowContainer>
          </ToggleWeekContainer>
        </CalendarControls>
        <Row>
          <Items
            range={filterRange.value}
            currentMonth={currentMonth}
            currentYear={currentYear}
            formattedDates={selectedWeek}
          />
          <Spaces
            range={filterRange.value}
            currentMonth={currentMonth}
            currentYear={currentYear}
            formattedDates={selectedWeek}
          />
        </Row>
        <br />
        <Row>
          <Users
            range={filterRange.value}
            currentMonth={currentMonth}
            currentYear={currentYear}
            formattedDates={selectedWeek}
          />
          <Companies
            range={filterRange.value}
            currentMonth={currentMonth}
            currentYear={currentYear}
            formattedDates={selectedWeek}
          />
        </Row>
      </DashboardWrapper>
      <Helmet title="Admin Dashboard" />
    </BackendWrapper>
  );
};

export default Dashboard;
