/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Form, Row, Col, FormGroup } from 'reactstrap';
import * as moment from 'moment';
import Select from 'react-select';
import styled from 'styled-components';

/** GraphQL */
import {
  getCompanyBatchCardsByUser,
  getCompanyBatchCardsByUserMainPlanner
} from '../../graphql/custom-queries';

import Composed from './ComposedMutationsForBatchCardAllocations';

/** Generated types */
import { UpdateServiceInput } from '../../API';

/** Custom types */
import {
  BatchCardAllocationType,
  BatchCardAllocationsType,
  ModalWithMessageType,
  SelectOptionsType,
  SelectType,
  Error,
  ValueType
} from '../../CustomTypes';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import StyledButton from '../../Components/Styled/Button';

/** Context API */
import DefaultWeekContextProvider from '../QualityControlCalendar/DefaultWeekContextProvider';
import { DefaultWeekContextConsumer } from '../QualityControlCalendar/DefaultWeekContextProvider';
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Themes */
import { Colors } from '../../Themes';

/** Helpers */
import * as Helpers from './BatchCardAllocationHelpers';
import { sortDropDownDataAlphabetically } from '../../Utils/Helpers';

const DateRowContainer = styled.div`
  border-bottom: 1px solid ${Colors.flumeGreen};
  & .row {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  & .col-4 {
    line-height: 38px;
  }

  & .col-1 {
    position: relative;
    top: 5px;
  }

  & span.date {
    margin-left: 10px;
    font-size: 0.75em;
    color: ${Colors.grey};
    text-transform: uppercase;
  }
`;

const IconsContainer = styled.div`
  display: inline-flex;
  & div {
    margin-right: 10px;
  }
`;

type Props = ModalWithMessageType & {
  batchCardId: string;
  batchCardAllocations: BatchCardAllocationsType;
  batchCardServices: Array<UpdateServiceInput>;
  companySpaces: SelectOptionsType;
  currentWeek: Array<Date>;
  error: Error;
  returnBatchCardServices(
    batchCardServices: Array<UpdateServiceInput>,
    date: Date
  ): React.ReactNode;
  selectSpace(space: ValueType<SelectType>, formattedDate: string): void;
  validateBatchCardAllocations(
    batchCardServices: Array<UpdateServiceInput>
  ): boolean;
};

type State = {
  loading: boolean;
};

class CreateBatchCardAllocationForm extends React.Component<Props, State> {
  state = {
    loading: false
  };

  /** Execute mutations to create create batch allocations
   * and create relation between created allocations and batch card services
   */
  executeMutations = async (
    userId: string,
    addBatchCardAllocationMutation: ({}) => Promise<any>,
    updateBatchCardServiceWithAllocationMutation: ({}) => Promise<any>,
    currentWeekFormattedDates: Array<string>
  ) => {
    const {
      batchCardAllocations,
      batchCardId,
      closeModal,
      notification
    } = this.props;

    this.setState({ loading: true });
    try {
      // Create all allocations
      const response = await Promise.all(
        batchCardAllocations.map(
          async (batchCardAllocation: BatchCardAllocationType) => {
            const createdBatchCardAllocation = await addBatchCardAllocationMutation(
              {
                variables: {
                  input: {
                    createdAt: new Date().toISOString(),
                    date: batchCardAllocation.date,
                    batchCardAllocationSpaceId: batchCardAllocation.spaceId,
                    batchCardAllocationBatchCardId: batchCardId
                  }
                }
              }
            );
            // Create relation between new allocation and batch card services associated with it
            const batchCardServiceBatchCardAllocationId =
              createdBatchCardAllocation.data.createBatchCardAllocation.id;
            return await Promise.all(
              batchCardAllocation.batchCardServiceIds.map(
                async (id: string) => {
                  return await updateBatchCardServiceWithAllocationMutation({
                    variables: {
                      input: {
                        id,
                        batchCardServiceBatchCardAllocationId
                      }
                    },
                    refetchQueries: [
                      {
                        query: getCompanyBatchCardsByUser,
                        variables: {
                          id: userId
                        }
                      },
                      {
                        query: getCompanyBatchCardsByUserMainPlanner,
                        variables: {
                          id: userId,
                          dayOne: currentWeekFormattedDates[0],
                          dayTwo: currentWeekFormattedDates[1],
                          dayThree: currentWeekFormattedDates[2],
                          dayFour: currentWeekFormattedDates[3],
                          dayFive: currentWeekFormattedDates[4],
                          daySix: currentWeekFormattedDates[5],
                          daySeven: currentWeekFormattedDates[6]
                        }
                      }
                    ]
                  });
                }
              )
            );
          }
        )
      );
      if (response) {
        closeModal();
        notification('Batch card services allocated successfully');
      } else {
        closeModal();
        notification(
          'An error was encountered whilst allocating the services',
          'error'
        );
      }
    } catch (e) {
      closeModal();
      notification(e.message, 'error');
    }
  };

  render() {
    const {
      validateBatchCardAllocations,
      returnBatchCardServices,
      companySpaces,
      batchCardServices,
      batchCardAllocations,
      currentWeek,
      selectSpace,
      error
    } = this.props;

    const { loading } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <DefaultWeekContextProvider>
              <DefaultWeekContextConsumer>
                {({ currentWeekFormattedDates }) => {
                  return (
                    <Composed>
                      {(composedMutations: {
                        addBatchCardAllocation: {
                          mutation: ({  }: {}) => Promise<any>;
                        };
                        updateBatchCardServiceWithAllocation: {
                          mutation: ({  }: {}) => Promise<any>;
                        };
                      }) => (
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            if (
                              validateBatchCardAllocations(batchCardServices)
                            ) {
                              this.executeMutations(
                                userId,
                                composedMutations.addBatchCardAllocation
                                  .mutation,
                                composedMutations
                                  .updateBatchCardServiceWithAllocation
                                  .mutation,
                                currentWeekFormattedDates
                              );
                            }
                          }}
                        >
                          {currentWeek.map((date: Date, i) => {
                            const formattedDate = moment(date).format(
                              'DD MMM YYYY'
                            );
                            /** Check if this date has a selected service, if so, then
                             * enable the dropdown selection of spaces
                             */
                            const dateHasAllocation = Helpers.dateExistsInCurrentAllocations(
                              batchCardAllocations,
                              formattedDate
                            )
                              ? true
                              : false;

                            return (
                              <DateRowContainer key={i}>
                                <Row>
                                  <Col xs={4} md={4} lg={4}>
                                    {moment(date).format('dddd')}{' '}
                                    <span className="date">
                                      {moment(date).format('DD MMM')}
                                    </span>
                                  </Col>
                                  <Col xs={7} md={7} lg={7}>
                                    <Select
                                      // @ts-ignore
                                      onChange={e =>
                                        selectSpace(e, formattedDate)
                                      }
                                      options={sortDropDownDataAlphabetically(
                                        companySpaces
                                      )}
                                      isSearchable={true}
                                      isDisabled={!dateHasAllocation}
                                      placeholder="Spaces"
                                      className="select-styling"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} lg={12}>
                                    <IconsContainer>
                                      {returnBatchCardServices(
                                        batchCardServices,
                                        date
                                      )}
                                    </IconsContainer>
                                  </Col>
                                </Row>
                              </DateRowContainer>
                            );
                          })}
                          <br />
                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              <FormGroup>
                                <StyledButton
                                  type="submit"
                                  label={loading ? <Loader /> : 'Add To Spaces'}
                                  color={Colors.flumeDarkGreen}
                                  background={Colors.flumeGreen}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {error && <ErrorMessage errorMessage={error} />}
                        </Form>
                      )}
                    </Composed>
                  );
                }}
              </DefaultWeekContextConsumer>
            </DefaultWeekContextProvider>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default CreateBatchCardAllocationForm;
