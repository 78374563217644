import styled from 'styled-components';

/** Themes */
import { Colors } from '../../Themes';

export const Wrapper = styled.div`
  width: 250px;
  height: 1200px;
  border-right: 1px solid ${Colors.lightCoal};
`;

export const Container = styled.div`
  width: calc(100% - 15px);
  height: 100%;
  overflow: auto;
  border-radius: 5px;
  background: ${Colors.aquaGrey};
`;

export const HeaderContainer = styled.div`
  color: ${Colors.snow};
  height: 40px;
  line-height: 40px;
  text-transform: capitalize;
  text-align: center;
`;

export const BacklogContainer = styled.div`
  color: #666;
  background: ${Colors.snow};
  letter-spacing: 1px;
  font-size: 0.88em;
  text-transform: capitalize;
  border-bottom: 1px solid ${Colors.lightGray};
  padding: 10px;
  display: flex;
  justify-content: space-between;
  & div {
    display: inline-flex;
  }
`;

export const BatchCardsListContainer = styled.div`
  min-height: 100vh;
`;
