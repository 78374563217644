import * as React from 'react';
import { FiGrid, FiSettings, FiFilm } from 'react-icons/fi';
import { TiChartBarOutline } from 'react-icons/ti';

/** Custom types */
import { NavItemType } from '../CustomTypes';
/** Cognito user groups */
import { USER_GROUP, SUPER_USER_GROUP } from '../Utils/Consts';

export const NAV_ITEMS: Array<NavItemType> = [
  {
    to: '/',
    title: 'Dashboard',
    cognitoGroups: [USER_GROUP, SUPER_USER_GROUP],
    icon: <FiGrid size="1.3em" />
  },
  {
    to: '/manage/teams-and-roles/roles',
    title: 'Manage',
    cognitoGroups: [SUPER_USER_GROUP],
    icon: <FiSettings size="1.3em" />
  },
  {
    to: '/production/sorting/new-items',
    title: 'Production',
    cognitoGroups: [USER_GROUP, SUPER_USER_GROUP],
    icon: <FiFilm size="1.3em" />
  },
  {
    to: '/reporting/sorting',
    title: 'Reporting',
    cognitoGroups: [SUPER_USER_GROUP],
    icon: <TiChartBarOutline size="1.3em" />
  }
];
