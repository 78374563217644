import * as React from 'react';
import { Query } from 'react-apollo';

/** GraphQL */
import { listProductItemsCreatedInSelectedYear } from '../../../../graphql/custom-queries';

/** Presentation/UI */
import ErrorMessage from '../../../../Components/Styled/ErrorMessage';
import Loader from '../../../../Components/Loader';
import ProductDataAndExport from '../DataAndExport';

/** Themes */
import { Colors } from '../../../../Themes';

/** Utils */
import { returnProductItemsBasedOnProductItemKeys } from '../../../MainPlanner/BatchCardAllocationHelpers';
import {
  ADMIN_PRODUCT_ITEM_KEYS,
  PRODUCT_ITEM_HEADINGS
} from '../../../../Utils/Consts';

const ProductItemsCreatedInSelectedYear: React.FC<{
  year: string;
}> = ({ year }) => {
  return (
    <Query
      query={listProductItemsCreatedInSelectedYear}
      variables={{
        year
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loader color={Colors.flumeGreen} />;
        }

        if (error || !data || !data.listProductItems) {
          return (
            <ErrorMessage errorMessage={error ? error.message : 'Error'} />
          );
        }

        const productItems = data.listProductItems;

        if (productItems && productItems.items && productItems.items.length) {
          // CSV data of product items to be exported
          const csvData = [
            PRODUCT_ITEM_HEADINGS,
            ...returnProductItemsBasedOnProductItemKeys(
              productItems.items,
              ADMIN_PRODUCT_ITEM_KEYS
            )
          ];

          return (
            <ProductDataAndExport
              dataTotal={productItems.items.length}
              filename="Items.csv"
              csvData={csvData}
            />
          );
        }

        return (
          <ProductDataAndExport
            dataTotal={0}
            csvData={[]}
            filename="Items.csv"
          />
        );
      }}
    </Query>
  );
};

export default ProductItemsCreatedInSelectedYear;
