/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import * as moment from 'moment';
import styled from 'styled-components';
import { Form, Row, Col, FormGroup } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/** GraphQL */
import {
  getCompanyBatchCardsByUser,
  getCompanyBatchCardsByUserMainPlanner
} from '../../graphql/custom-queries';

import Composed from './ComposedMutationsForBatchCardAllocations';

/** Generated Types */
import { UpdateServiceInput } from '../../API';

/** Presentation/UI */
import Table from '../../Components/Table';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import StyledButton from '../../Components/Styled/Button';
import {
  SelectedColor,
  SelectedIcon
} from '../../Components/Styled/ListViewElements';

/** Context API */
import DefaultWeekContextProvider from '../QualityControlCalendar/DefaultWeekContextProvider';
import { DefaultWeekContextConsumer } from '../QualityControlCalendar/DefaultWeekContextProvider';

/** Custom types */
import {
  SpaceType,
  ServiceType,
  BatchCardAllocationsType,
  BatchCardAllocationType,
  Error
} from '../../CustomTypes';

/** Themes */
import { Colors, ServiceIcons } from '../../Themes';

/** Utils */
import * as Helpers from './BatchCardAllocationHelpers';

type BatchCardService = {
  id: string;
  service: ServiceType;
};

type Props = {
  batchCardId: string;
  batchCardServices: Array<UpdateServiceInput>;
  companySpaces: Array<SpaceType>;
  defaultSpace: string;
  searchFilter: boolean;
  userId: string;
  closeModal: () => void;
  notification(message: string, appearance?: string): void;
};

type State = {
  batchCardAllocations: BatchCardAllocationsType;
  error: Error;
  loading: boolean;
};

const DatePickerContainer = styled.div`
  & input {
    border: none;
    cursor: pointer;
    color: ${Colors.grey};
    outline: 0;
  }
`;

const IconsContainer = styled.div`
  display: inline-flex;
  & div {
    margin-right: 10px;
  }
`;

class CreateSpaceCardAllocations extends React.Component<Props, State> {
  timeoutId: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      batchCardAllocations: this.returnBatchCardAllocations(props.companySpaces)
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Validate batch card allocations
   * Every batch card service should be allocated
   * Every allocation should have a selected space
   * @param batchCardServices - company services associated with a particular batch card
   */
  validateBatchCardAllocations = (
    batchCardServices: Array<UpdateServiceInput>
  ): boolean => {
    const { batchCardAllocations } = this.state;

    const allServicesAllocated: Array<number> = batchCardServices.map(
      // @ts-ignore
      (batchCardService: BatchCardService) => {
        const allocation = batchCardAllocations.filter(
          (batchCardAllocation: BatchCardAllocationType) =>
            batchCardAllocation.batchCardServiceIds.find(
              (bcsId: string) => bcsId === batchCardService.id
            )
        );

        return allocation && allocation.length;
      }
    );

    // Check if all services are allocated
    if (allServicesAllocated.indexOf(0) > -1) {
      this.setError('Please allocate all services to a particular date');
      return false;
    }
    return true;
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Return allocations with space id and default date
   * @param companySpaces - array of spaces created by company
   */
  returnBatchCardAllocations = (
    companySpaces: Array<SpaceType>
  ): BatchCardAllocationsType => {
    const { batchCardServices, defaultSpace } = this.props;

    return companySpaces.map((space: SpaceType) => {
      if (defaultSpace === space.id) {
        return {
          spaceId: space.id,
          date: moment().format('DD MMM YYYY'),
          batchCardServiceIds:
            batchCardServices && batchCardServices.length
              ? // @ts-ignore
                batchCardServices.map(batchCardService => {
                  return batchCardService.id;
                })
              : []
        };
      }

      return {
        spaceId: space.id,
        date: moment().format('DD MMM YYYY'),
        batchCardServiceIds: []
      };
    });
  };

  /** Return the date from space date allocations based on the space id
   * @param spaceId - id for company space
   */
  returnSelectedDate = (spaceId: string) => {
    const filteredbatchCardAllocation = this.state.batchCardAllocations.filter(
      (batchCardAllocation: BatchCardAllocationType) =>
        batchCardAllocation.spaceId === spaceId
    );
    if (filteredbatchCardAllocation && filteredbatchCardAllocation.length) {
      return moment(
        filteredbatchCardAllocation[0].date,
        'DD MMM YYYY'
      ).toDate();
    }

    return moment().toDate();
  };

  /** Return batch card services
   * @param batchCardServices - company services added to a batch card
   * @param spaceId - id for a space
   */
  returnBatchCardServices = (
    batchCardServices: Array<UpdateServiceInput>,
    spaceId: string
  ): React.ReactNode => {
    const { batchCardAllocations } = this.state;

    // @ts-ignore
    return batchCardServices.map((batchCardService: BatchCardService) => {
      return (
        <SelectedIcon
          small={true}
          background={
            Helpers.checkIfServiceHasBeenSelectedInSpace(
              batchCardAllocations,
              batchCardService.id,
              spaceId
            )
              ? Colors.flumeGreen
              : Colors.snow
          }
          key={batchCardService.service.id}
          onClick={() =>
            this.updateBatchCardAllocations(batchCardService.id, spaceId)
          }
        >
          <img
            alt="serviceIcon"
            src={batchCardService.service.icon || ServiceIcons.Editing}
          />
        </SelectedIcon>
      );
    });
  };

  /** Add or update batch card allocations with the service selection and the space it's
   * been selected on
   * @param batchCardServiceId - id of the service associated with particular batch card
   * @param spaceId - space in which the service is to be performed
   */
  updateBatchCardAllocations = (
    batchCardServiceId: string,
    spaceId: string
  ) => {
    const { batchCardAllocations } = this.state;

    if (batchCardAllocations && batchCardAllocations.length) {
      const allocationWithThisService = batchCardAllocations.filter(
        (batchCardAllocation: BatchCardAllocationType) =>
          batchCardAllocation.batchCardServiceIds.find(
            (bcsId: string) => bcsId === batchCardServiceId
          )
      );
      if (allocationWithThisService && allocationWithThisService.length) {
        // Remove batch card service id from current allocation of batch card service ids
        const updatedBatchCardAllocations = Helpers.removeBatchCardServiceIdFromAllocation(
          batchCardAllocations,
          batchCardServiceId
        );

        if (
          Helpers.spaceExistsInCurrentAllocations(batchCardAllocations, spaceId)
        ) {
          // Existing space allocation
          const newBatchCardAllocations = Helpers.addServiceIdToExistingSpace(
            updatedBatchCardAllocations,
            batchCardServiceId,
            spaceId
          );

          this.setState({
            batchCardAllocations: Helpers.removeBatchCardAllocationsWithoutService(
              newBatchCardAllocations
            )
          });
        } else {
          // New space allocation
          this.setState({
            batchCardAllocations: [
              ...Helpers.removeBatchCardAllocationsWithoutService(
                updatedBatchCardAllocations
              ),
              {
                spaceId,
                date: Helpers.returnDefaultDate(),
                batchCardServiceIds: [batchCardServiceId]
              }
            ]
          });
        }
      } else {
        // New service id to be added to either an existing space or a new space
        if (
          Helpers.spaceExistsInCurrentAllocations(batchCardAllocations, spaceId)
        ) {
          // New service id on existing space allocation
          const updatedBatchCardAllocations = Helpers.addServiceIdToExistingSpace(
            batchCardAllocations,
            batchCardServiceId,
            spaceId
          );

          this.setState({
            batchCardAllocations: Helpers.removeBatchCardAllocationsWithoutService(
              updatedBatchCardAllocations
            )
          });
        } else {
          // New service in new space allocation
          this.setState({
            batchCardAllocations: [
              ...Helpers.removeBatchCardAllocationsWithoutService(
                batchCardAllocations
              ),
              {
                spaceId,
                date: Helpers.returnDefaultDate(),
                batchCardServiceIds: [batchCardServiceId]
              }
            ]
          });
        }
      }
    } else {
      this.setState({
        batchCardAllocations: [
          {
            spaceId,
            date: Helpers.returnDefaultDate(),
            batchCardServiceIds: [batchCardServiceId]
          }
        ]
      });
    }
  };

  /** Change/selection of date
   * @param date - selected date
   * @param spaceId - space id
   */
  handleChange = (date: Date | null, spaceId: string) => {
    const { batchCardAllocations } = this.state;
    const newbatchCardAllocations = batchCardAllocations.map(
      (batchCardAllocation: BatchCardAllocationType) => {
        if (batchCardAllocation.spaceId === spaceId) {
          const chosenDate = date ? date : new Date();

          return {
            spaceId,
            date: moment(chosenDate).format('DD MMM YYYY'),
            batchCardServiceIds: batchCardAllocation.batchCardServiceIds
          };
        }
        return batchCardAllocation;
      }
    );
    this.setState({ batchCardAllocations: newbatchCardAllocations });
  };

  /** Execute mutations to create create batch allocations
   * and create relation between created allocations and batch card services
   * @param addBatchCardAllocationMutation
   * @param updateBatchCardServiceWithAllocationMutation
   */
  executeMutations = async (
    addBatchCardAllocationMutation: ({}) => Promise<any>,
    updateBatchCardServiceWithAllocationMutation: ({}) => Promise<any>,
    currentWeekFormattedDates: Array<string>
  ) => {
    const { batchCardAllocations } = this.state;
    const { batchCardId, closeModal, notification, userId } = this.props;

    this.setState({ loading: true });

    try {
      // Create all allocations
      const response = await Promise.all(
        batchCardAllocations.map(
          async (batchCardAllocation: BatchCardAllocationType) => {
            const createdBatchCardAllocation = await addBatchCardAllocationMutation(
              {
                variables: {
                  input: {
                    createdAt: new Date().toISOString(),
                    date: batchCardAllocation.date,
                    batchCardAllocationSpaceId: batchCardAllocation.spaceId,
                    batchCardAllocationBatchCardId: batchCardId
                  }
                }
              }
            );
            // Create relation between new allocation and batch card services associated with it
            const batchCardServiceBatchCardAllocationId =
              createdBatchCardAllocation.data.createBatchCardAllocation.id;
            return await Promise.all(
              batchCardAllocation.batchCardServiceIds.map(
                async (id: string) => {
                  return await updateBatchCardServiceWithAllocationMutation({
                    variables: {
                      input: {
                        id,
                        batchCardServiceBatchCardAllocationId
                      }
                    },
                    refetchQueries: [
                      {
                        query: getCompanyBatchCardsByUser,
                        variables: {
                          id: userId
                        }
                      },
                      {
                        query: getCompanyBatchCardsByUserMainPlanner,
                        variables: {
                          id: userId,
                          dayOne: currentWeekFormattedDates[0],
                          dayTwo: currentWeekFormattedDates[1],
                          dayThree: currentWeekFormattedDates[2],
                          dayFour: currentWeekFormattedDates[3],
                          dayFive: currentWeekFormattedDates[4],
                          daySix: currentWeekFormattedDates[5],
                          daySeven: currentWeekFormattedDates[6]
                        }
                      }
                    ]
                  });
                }
              )
            );
          }
        )
      );
      if (response) {
        closeModal();
        notification('Batch card services allocated successfully');
      } else {
        closeModal();
        notification(
          'An error was encountered whilst allocating the services',
          'error'
        );
      }
    } catch (e) {
      closeModal();
      notification(e.message, 'error');
    }
  };

  render() {
    const { searchFilter, batchCardServices, companySpaces } = this.props;
    const { loading, error } = this.state;

    return (
      <DefaultWeekContextProvider>
        <DefaultWeekContextConsumer>
          {({ currentWeekFormattedDates }) => {
            return (
              <Composed>
                {(composedMutations: {
                  addBatchCardAllocation: {
                    mutation: ({  }: {}) => Promise<any>;
                  };
                  updateBatchCardServiceWithAllocation: {
                    mutation: ({  }: {}) => Promise<any>;
                  };
                }) => (
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      if (
                        this.validateBatchCardAllocations(batchCardServices)
                      ) {
                        this.executeMutations(
                          composedMutations.addBatchCardAllocation.mutation,
                          composedMutations.updateBatchCardServiceWithAllocation
                            .mutation,
                          currentWeekFormattedDates
                        );
                      }
                    }}
                  >
                    <Table
                      data={companySpaces}
                      columns={[
                        {
                          Header: '',
                          accessor: 'name',
                          sortable: true,
                          filterable: searchFilter,
                          width: 150
                        },
                        {
                          id: 'spaceColorIdentifiers',
                          Header: '',
                          accessor: (companySpace: SpaceType) => {
                            return (
                              <SelectedColor
                                background={companySpace.colorCode}
                                small={true}
                              />
                            );
                          },
                          sortable: false,
                          filterable: false,
                          width: 50
                        },
                        {
                          id: 'batchCardServices',
                          Header: '',
                          accessor: (companySpace: SpaceType) => {
                            return (
                              <IconsContainer>
                                {this.returnBatchCardServices(
                                  batchCardServices,
                                  companySpace.id
                                )}
                              </IconsContainer>
                            );
                          },
                          sortable: false
                        },
                        {
                          id: 'dateSelector',
                          Header: '',
                          accessor: (companySpace: SpaceType) => {
                            return (
                              <DatePickerContainer>
                                <DatePicker
                                  selected={this.returnSelectedDate(
                                    companySpace.id
                                  )}
                                  onChange={date =>
                                    this.handleChange(date, companySpace.id)
                                  }
                                  dateFormat="dd/MM/yyyy"
                                />
                              </DatePickerContainer>
                            );
                          },
                          sortable: false,
                          filterable: false,
                          width: 300
                        }
                      ]}
                      defaultPageSize={5}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                    />
                    <br />
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        <FormGroup>
                          <StyledButton
                            type="submit"
                            label={loading ? <Loader /> : 'Add To Spaces'}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {error && <ErrorMessage errorMessage={error} />}
                  </Form>
                )}
              </Composed>
            );
          }}
        </DefaultWeekContextConsumer>
      </DefaultWeekContextProvider>
    );
  }
}

export default CreateSpaceCardAllocations;
