import * as React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Form } from 'reactstrap';

/** GraphQL */
import { updateProductItem } from '../../graphql/mutations';
import {
  getCompanyArchivedProductItemsByUser,
  getCompanyCompletedProductItemsByUser
} from '../../graphql/custom-queries';

/** Generated types */
import {
  UpdateProductItemMutation,
  UpdateProductItemMutationVariables
} from '../../API';

/** Presentation/UI */
import DialogModal from '../../Components/DialogModal';

/** Custom types */
import { ModalWithMessageType } from '../../CustomTypes';

/** Utils */
import {
  archiveDialogMessage,
  completeDialogMessage,
  archiveSuccessMessage,
  completeSuccessMessage
} from '../../Utils/Consts';

type Props = ModalWithMessageType & {
  archived: boolean;
  complete: boolean;
  selectedProductItems: Array<string>;
  userId: string;
};

class ArchiveOrCompleteProductItems extends React.Component<Props> {
  /** Execute mutation to update product items as either archived or completed
   * @param selectedProductIems - ids of selected product items
   * @param mutationToUpdateProductItem - graphql mutation to update product item
   */
  executeMutation = async (
    archived: boolean,
    complete: boolean,
    selectedProductIems: Array<string>,
    userId: string,
    mutationToUpdateProductItem: ({}) => Promise<any>, // eslint-disable-line
    closeModal: () => void,
    notification: (message: string, appearance?: string | undefined) => void
  ) => {
    // Determine which type of message to show based
    /**
     * Determine which type of message to show based
     * on the action
     */
    const notificationMessage = archived
      ? archiveSuccessMessage
      : completeSuccessMessage;

    try {
      await Promise.all(
        selectedProductIems.map(async (id: string) => {
          await mutationToUpdateProductItem({
            variables: {
              input: {
                id,
                archived,
                complete
              }
            },
            refetchQueries: [
              {
                query: getCompanyCompletedProductItemsByUser,
                variables: {
                  id: userId
                }
              },
              {
                query: getCompanyArchivedProductItemsByUser,
                variables: {
                  id: userId
                }
              }
            ]
          });
        })
      );

      closeModal();
      notification(notificationMessage);
    } catch (err) {
      closeModal();
      notification(err.message, 'error');
    }
  };

  render() {
    const {
      archived,
      complete,
      closeModal,
      selectedProductItems,
      notification,
      userId
    } = this.props;

    const dialogMessage = archived
      ? archiveDialogMessage
      : completeDialogMessage;

    return (
      <Mutation<UpdateProductItemMutation, UpdateProductItemMutationVariables>
        mutation={gql(updateProductItem)}
      >
        {(updateProductItemMutation, updateMutation) => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              // execute mutation
              this.executeMutation(
                archived,
                complete,
                selectedProductItems,
                userId,
                updateProductItemMutation,
                closeModal,
                notification
              );
            }}
          >
            <DialogModal
              loading={updateMutation.loading}
              title={dialogMessage}
              toggleModal={closeModal}
            />
          </Form>
        )}
      </Mutation>
    );
  }
}

export default ArchiveOrCompleteProductItems;
