import styled from 'styled-components';
import {
  AccordionItemHeading,
  AccordionItem,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion';

/** App Theme */
import { Colors } from '../../Themes';

/** Helpers */
import { convertPixelsToRem } from '../../Utils/Helpers';

export const StyledAccordionItem = styled(AccordionItem)`
  position: relative;
  width: 90%;
  left: 5%;
  border-radius: 2px;
  background-color: ${Colors.snow};
  margin-bottom: ${convertPixelsToRem(15)};

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

export const StyledAccordionItemHeading = styled(AccordionItemHeading)`
  display: block;
  font-family: Gibson;
  font-size: 16px;
  line-height: 21px;
  padding: ${convertPixelsToRem(12)};
  color: ${Colors.flumeDarkGreen};
  border: 1px solid ${Colors.aquaGrey};
`;

export const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  text-align: left;
  padding: 5px;
`;

export const StyledAccordionItemButton = styled(AccordionItemButton)`
  &:focus {
    outline: none;
  }
`;

export const StyledAccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 90%;
  left: 5%;
  margin: ${convertPixelsToRem(15)} 0;
  color: ${Colors.coal};
  font-weight: 600;

  div {
    width: 50%;
  }
`;
