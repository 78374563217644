import * as React from 'react';
import Select from 'react-select';
import { Droppable } from 'react-beautiful-dnd';

/** Custom types */
import { BatchCard, SelectType } from '../../CustomTypes';

/** Generated types */
import {
  CreateItemTemplateInput,
  UpdateServiceInput,
  UpdateCompanyInput
} from '../../API';

/** Presentation/UI */
import {
  BacklogContainer,
  BatchCardsListContainer,
  HeaderContainer
} from '../../Components/Styled/Backlog';

/** Utils */
import {
  companyFromResponseData,
  returnCompanyBatchCardsFromResponseData
} from './QCBatchCardHelpers';
import { returnSortedArrayByDate } from '../../Utils/Helpers';
import {
  returnBatchCardsWithoutAllocations,
  returnTotalItemsInBacklog
} from './BatchCardAllocationHelpers';
import { SORTING_OPTIONS } from '../../Utils/Consts';

type Props = {
  data: any;
  sortingOrder: SelectType;
  returnBatchCards(
    batchCards: Array<BatchCard>,
    itemTemplate: CreateItemTemplateInput,
    services: Array<UpdateServiceInput>
  ): React.ReactNode;
};

type State = {
  batchCards: Array<BatchCard>;
  company: UpdateCompanyInput & {
    itemTemplate: CreateItemTemplateInput;
    services: Array<UpdateServiceInput>;
  };
};

class BacklogView extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps && nextProps.data && nextProps.data.getUser) {
      const { data } = nextProps;
      return {
        batchCards: returnCompanyBatchCardsFromResponseData(data),
        company: companyFromResponseData(data)
      };
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      batchCards: returnCompanyBatchCardsFromResponseData(props.data),
      company: companyFromResponseData(props.data)
    };
  }

  render() {
    const { returnBatchCards, sortingOrder } = this.props;
    const { batchCards, company } = this.state;
    const batchCardsWithoutAllocations = returnSortedArrayByDate(
      returnBatchCardsWithoutAllocations(batchCards),
      sortingOrder.value,
      'createdAt'
    );

    return (
      <div>
        <HeaderContainer>Batch Card Queue</HeaderContainer>
        <Select
          // @ts-ignore
          onChange={this.selectSortingOrder}
          options={SORTING_OPTIONS}
          isSearchable={true}
          value={sortingOrder}
          placeholder=""
          className="select-styling select-styling-padded"
        />
        <BacklogContainer>
          <div>{batchCardsWithoutAllocations.length} batch cards</div>
          <div>
            {returnTotalItemsInBacklog(batchCardsWithoutAllocations)} items
          </div>
        </BacklogContainer>
        <Droppable droppableId="backlog">
          {(provided, snapshot) => (
            <BatchCardsListContainer ref={provided.innerRef}>
              {returnBatchCards(
                batchCardsWithoutAllocations,
                company.itemTemplate,
                company.services
              )}
            </BatchCardsListContainer>
          )}
        </Droppable>
      </div>
    );
  }
}

export default BacklogView;
