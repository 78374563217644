import * as React from 'react';
import * as ReactDOM from 'react-dom';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import App from './App';
/** Bootstrap */
import 'bootstrap/dist/css/bootstrap.css';
/** Date picker */
import 'react-datepicker/dist/react-datepicker.css';
/** Custom CSS */
import './index.css';
import { unregister } from './registerServiceWorker';

// Error boundary fallback component
import FallbackComponent from './Components/ErrorBoundaryFallback';

const bugsnagClient = bugsnag('dc426c3f1fc56ddc83fdedf586be276f');
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

const Root: React.SFC<{}> = () => (
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <App />
  </ErrorBoundary>
);

ReactDOM.render(<Root />, document.getElementById('root') as HTMLElement);
unregister(); // TODO
