import * as React from 'react';
import { FiLayers } from 'react-icons/fi';
import { Droppable, Draggable } from 'react-beautiful-dnd';

/** Presentation/UI */
import {
  BatchCardContainer,
  BatchCardHeading,
  BatchCardSpaceIdentifier,
  DayContainer,
  DayHeader,
  DayWrapper
} from '../../Components/Styled/MainPlanner';
import { ServiceIconsContainer } from '../../Components/Styled/ListViewElements';

/** Local components */
import ViewUsersInSpace from './ViewUsersInSpace';

/** Generated types */
import { UpdateSpaceInput, UpdateUserInput, Permission } from '../../API';

/** Custom types */
import {
  BatchCard,
  SavedBatchCardAllocation,
  BatchCardServices
} from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import {
  returnAllocationsFromBatchCards,
  checkIfBatchCardHasAllocationsInCurrentWeek,
  arrayOfActiveBatchCardServices,
  numberOfItemsWithCompletedServices
} from './BatchCardAllocationHelpers';
import {
  companySpaces,
  returnCompanyBatchCardsFromResponseData,
  selectedBatchCardServices
} from './QCBatchCardHelpers';
import {
  returnFlattenedArray,
  returnFormattedDates,
  getTotal,
  sortArrayAlphabetically
} from '../../Utils/Helpers';
import { MAIN_PLANNER_VIEWS, BATCH_TYPE, USER_ROLES } from '../../Utils/Consts';

type SpaceType = UpdateSpaceInput & {
  users: {
    items: Array<UpdateUserInput>;
  };
};

type Props = {
  currentWeek: Array<Date>;
  data: any;
  spaceId: string;
  spaceIds: Array<string>;
  userId: string;
  userPermission: Permission | string;
  openBatchCard(
    batchCardId: string,
    batchCardServices: Array<BatchCardServices>
  ): void;
};

type State = {
  batchCards: Array<BatchCard>;
  companySpaces: Array<SpaceType>;
  membersModal: boolean;
  spaceToAddMembersTo: string;
};

class MainPlannerSpaceWeekView extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps && nextProps.data && nextProps.data.getUser) {
      const { data } = nextProps;
      return {
        batchCards: returnCompanyBatchCardsFromResponseData(data),
        companySpaces: companySpaces(data)
      };
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      batchCards: returnCompanyBatchCardsFromResponseData(props.data),
      companySpaces: [],
      membersModal: false,
      spaceToAddMembersTo: ''
    };
  }

  /** Close members view modal */
  closeMembersModal = (): void => {
    this.setState({
      membersModal: false
    });
  };

  /** Open members view  modal */
  openMembersModal = (spaceToAddMembersTo: string): void => {
    this.setState({
      membersModal: true,
      spaceToAddMembersTo
    });
  };

  /** Return batch card allocations that are in a particular space (specified by id)
   * for the current week selected
   * @param batchCardAllocations - allocations from batch cards
   * @param selectedWeek - array of dates for the selected week
   * @param spaceId - id for a particular company space
   */
  returnAllocationsInASpaceForCurrentWeek = (
    batchCardAllocations: Array<{
      items: Array<SavedBatchCardAllocation>;
    }>,
    selectedWeek: Array<string>,
    spaceId: string
  ): React.ReactNode => {
    if (batchCardAllocations && batchCardAllocations.length) {
      const { openBatchCard } = this.props;
      const mergedAllocations = returnFlattenedArray(batchCardAllocations);
      // Get allocations for the current week
      const allocationsForTheWeek = mergedAllocations.filter(
        (batchCardAllocation: SavedBatchCardAllocation) => {
          return selectedWeek.find(
            (date: string) => date === batchCardAllocation.date
          );
        }
      );

      if (allocationsForTheWeek && allocationsForTheWeek.length) {
        // Get allocations for the week in the specified space
        const allocationsForTheWeekInSpace = allocationsForTheWeek.filter(
          (batchCardAllocation: SavedBatchCardAllocation) =>
            batchCardAllocation.space.id === spaceId
        );

        if (
          allocationsForTheWeekInSpace &&
          allocationsForTheWeekInSpace.length
        ) {
          return allocationsForTheWeekInSpace.map(
            (bcAllocation: SavedBatchCardAllocation, i: number) => {
              if (!bcAllocation.batchCard.productItems.items.length) {
                return; // eslint-disable-line
              }
              const numberOfProductItems =
                bcAllocation.batchCard.productItems.items.length;
              const activeBatchCardServices =
                bcAllocation.batchCardServices &&
                arrayOfActiveBatchCardServices(
                  bcAllocation.batchCardServices.items
                );

              return (
                activeBatchCardServices.length > 0 && (
                  <Draggable
                    key={bcAllocation.id}
                    draggableId={`${BATCH_TYPE.allocation}_${bcAllocation.id}`}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <BatchCardContainer
                        key={bcAllocation.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() =>
                          openBatchCard(
                            bcAllocation.batchCard.id,
                            bcAllocation.batchCardServices.items
                          )
                        }
                      >
                        <BatchCardHeading>
                          {bcAllocation.batchCard.name}
                        </BatchCardHeading>
                        <span>
                          {numberOfItemsWithCompletedServices(
                            bcAllocation.batchCard.productItems.items,
                            activeBatchCardServices
                          )}{' '}
                          /{numberOfProductItems}
                        </span>
                        {'   '}
                        <FiLayers size="1em" color={Colors.lightGray} />
                        <br />
                        <br />
                        <ServiceIconsContainer>
                          {bcAllocation.batchCardServices &&
                            selectedBatchCardServices(
                              bcAllocation.batchCardServices.items
                            )}
                        </ServiceIconsContainer>
                        <br />
                        <BatchCardSpaceIdentifier
                          background={bcAllocation.space.colorCode}
                        />
                      </BatchCardContainer>
                    )}
                  </Draggable>
                )
              );
            }
          );
        }
        return null;
      }
      return null;
    }
    return null;
  };

  /** Return no. of batch cards in a space for the current selected week
   * @param batchCards - allocated batch cards
   * @param spaceId - company space
   */
  returnNumberOfBatchCardsInSpace = (
    batchCards: Array<BatchCard>,
    spaceId: string
  ): number => {
    const { currentWeek } = this.props;
    const batchCardAllocations = returnAllocationsFromBatchCards(batchCards);
    // Get array of dates in the same format used by batch card allocations
    const formattedDatesOfCurrentWeek = returnFormattedDates(
      currentWeek,
      'DD MMM YYYY'
    );
    if (batchCardAllocations && batchCardAllocations.length) {
      const mergedAllocations = returnFlattenedArray(batchCardAllocations);

      const savedBatchCardAllocations = mergedAllocations.filter(
        (batchCardAllocation: SavedBatchCardAllocation) =>
          batchCardAllocation.space.id === spaceId
      );

      // Get allocations for the current week
      const allocationsForTheWeek = savedBatchCardAllocations.filter(
        (batchCardAllocation: SavedBatchCardAllocation) => {
          return formattedDatesOfCurrentWeek.find(
            (date: string) => date === batchCardAllocation.date
          );
        }
      );

      return allocationsForTheWeek.length;
    }

    return 0;
  };

  /** Return number of product items for a particular space
   * @param batchCards - allocated batch cards
   * @param spaceId - id of a particular space for a company
   */
  returnNumberOfItemsInSpace = (
    batchCards: Array<BatchCard>,
    spaceId: string
  ) => {
    const { currentWeek } = this.props;
    // Get array of dates in the same format used by batch card allocations
    const formattedDatesOfCurrentWeek = returnFormattedDates(
      currentWeek,
      'DD MMM YYYY'
    );
    // Get batch cards with allocations
    const batchCardsWithAllocations = batchCards.filter(
      (batchCard: BatchCard) =>
        batchCard.batchCardAllocations &&
        batchCard.batchCardAllocations.items.length
    );
    // Get batch cards with allocations in this space (identified by space id)
    const batchCardsWithAllocationsInThisSpace = batchCardsWithAllocations.filter(
      (batchCard: BatchCard) =>
        batchCard.batchCardAllocations.items.find(
          (batchCardAllocation: SavedBatchCardAllocation) =>
            batchCardAllocation.space &&
            batchCardAllocation.space.id === spaceId
        )
    );
    // Get batch cards with allocations in the right space and in the current selected week
    const bcsWithAllocationsInThisSpaceForCurrentWeek = batchCardsWithAllocationsInThisSpace.filter(
      (batchCard: BatchCard) => {
        return checkIfBatchCardHasAllocationsInCurrentWeek(
          batchCard,
          formattedDatesOfCurrentWeek
        );
      }
    );

    // Get product items per batch card
    const productItemsPerBatchCard = bcsWithAllocationsInThisSpaceForCurrentWeek.map(
      (batchCard: BatchCard) => {
        return batchCard.productItems.items.length;
      }
    );

    return productItemsPerBatchCard.reduce(getTotal, 0);
  };

  /** Return company spaces
   * @param spaces - spaces created by company
   * @param batchCards - array of batch cards created by company
   */
  returnCompanySpaces = (
    spaces: Array<SpaceType>,
    batchCards: Array<BatchCard>
  ) => {
    const { currentWeek, spaceId, spaceIds, userPermission } = this.props;

    // Get allocations from each batch card
    const batchCardAllocations = returnAllocationsFromBatchCards(batchCards);
    // Get array of dates in the same format used by batch card allocations
    const formattedDatesOfCurrentWeek = returnFormattedDates(
      currentWeek,
      'DD MMM YYYY'
    );

    /** Check if there are space id's set and if the account is for a normal user */
    if (USER_ROLES.user === userPermission && spaceIds.length) {
      const filteredSpaces: Array<SpaceType> = spaces.filter(
        (space: SpaceType) => spaceIds.indexOf(space.id) > -1
      );

      if (filteredSpaces && filteredSpaces.length) {
        return filteredSpaces.map(chosenSpace => {
          return (
            <DayContainer key={chosenSpace.id}>
              <Droppable
                droppableId={`${MAIN_PLANNER_VIEWS.space}_${chosenSpace.id}`}
              >
                {(provided, snapshot) => (
                  <DayWrapper
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    <DayHeader>
                      <span className="space-header">{chosenSpace.name}</span>
                    </DayHeader>
                    <DayHeader>
                      <ViewUsersInSpace spaceIds={[chosenSpace.id]} />
                    </DayHeader>
                    <DayHeader>
                      <div>
                        {this.returnNumberOfBatchCardsInSpace(
                          batchCards,
                          chosenSpace.id
                        )}{' '}
                        batch cards
                      </div>
                      <div>
                        {this.returnNumberOfItemsInSpace(
                          batchCards,
                          chosenSpace.id
                        )}{' '}
                        items
                      </div>
                    </DayHeader>
                    {this.returnAllocationsInASpaceForCurrentWeek(
                      batchCardAllocations,
                      formattedDatesOfCurrentWeek,
                      chosenSpace.id
                    )}
                    {provided.placeholder}
                  </DayWrapper>
                )}
              </Droppable>
            </DayContainer>
          );
        });
      }

      return <div>You have not been added to any spaces yet.</div>;
    }

    /**
     * Check if the space id is set, if so, only show the selected space
     */
    if (spaceId !== '') {
      const filteredSpace: Array<SpaceType> = spaces.filter(
        (space: SpaceType) => space.id === spaceId
      );
      if (filteredSpace && filteredSpace.length) {
        const chosenSpace: SpaceType = filteredSpace[0];

        return (
          <DayContainer key={chosenSpace.id}>
            <Droppable
              droppableId={`${MAIN_PLANNER_VIEWS.space}_${chosenSpace.id}`}
            >
              {(provided, snapshot) => (
                <DayWrapper
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  <DayHeader>
                    <span className="space-header">{chosenSpace.name}</span>
                  </DayHeader>
                  <DayHeader>
                    <ViewUsersInSpace spaceIds={[chosenSpace.id]} />
                  </DayHeader>
                  <DayHeader>
                    <div>
                      {this.returnNumberOfBatchCardsInSpace(
                        batchCards,
                        chosenSpace.id
                      )}{' '}
                      batch cards
                    </div>
                    <div>
                      {this.returnNumberOfItemsInSpace(
                        batchCards,
                        chosenSpace.id
                      )}{' '}
                      items
                    </div>
                  </DayHeader>
                  {this.returnAllocationsInASpaceForCurrentWeek(
                    batchCardAllocations,
                    formattedDatesOfCurrentWeek,
                    chosenSpace.id
                  )}
                  {provided.placeholder}
                </DayWrapper>
              )}
            </Droppable>
          </DayContainer>
        );
      }
      return <div>Sorry, the selected space doesn't exist.</div>;
    }

    return sortArrayAlphabetically(spaces).map((space: SpaceType) => {
      return (
        <DayContainer key={space.id}>
          <Droppable droppableId={`${MAIN_PLANNER_VIEWS.space}_${space.id}`}>
            {(provided, snapshot) => (
              <DayWrapper
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <DayHeader>
                  <span className="space-header">{space.name}</span>
                </DayHeader>
                <DayHeader height="2.30rem">
                  <ViewUsersInSpace spaceIds={[space.id]} />
                </DayHeader>
                <DayHeader>
                  <div>
                    {this.returnNumberOfBatchCardsInSpace(batchCards, space.id)}{' '}
                    batch cards
                  </div>
                  <div>
                    {this.returnNumberOfItemsInSpace(batchCards, space.id)}{' '}
                    items
                  </div>
                </DayHeader>
                {this.returnAllocationsInASpaceForCurrentWeek(
                  batchCardAllocations,
                  formattedDatesOfCurrentWeek,
                  space.id
                )}
                {provided.placeholder}
              </DayWrapper>
            )}
          </Droppable>
        </DayContainer>
      );
    });
  };

  render() {
    const { companySpaces, batchCards } = this.state;

    return (
      <section>{this.returnCompanySpaces(companySpaces, batchCards)}</section>
    );
  }
}

export default MainPlannerSpaceWeekView;
