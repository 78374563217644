import * as React from 'react';
import styled from 'styled-components';

// app theme
import { Colors } from '../../Themes';

const StyledGroup = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledGroupLabel = styled('span')`
  color: ${Colors.coal};
`;

const StyledOptions = (data): React.ReactNode => (
  <StyledGroup>
    <StyledGroupLabel>{data.label}</StyledGroupLabel>
  </StyledGroup>
);

export default StyledOptions;
