import { apiRequest } from '../../../Utils/API';
import { SEND_EMAIL } from '../../../Utils/LambdaEndpoints';
import { HTTP_METHODS } from '../../../Utils/Consts';

/** Handle form validation for the send message form
 * @param name - user's name (or name of sender)
 * @param subject - subject of the message
 * @param message - actual message to be sent
 * @param setError - set the error message to be displayed
 */
export const validateForm = (
  name: string,
  subject: string,
  message: string,
  setError: (error: string | null) => void
): boolean => {
  // Check for undefined or empty input fields
  if (!name || !subject || !message) {
    setError('Please complete all the fields.');
    return false;
  }

  return true;
};

/** Send email to support
 * @param name - user's name (or name of sender)
 * @param subject - subject of the message
 * @param message - actual message to be sent
 * @param setError - set the error message to be displayed
 * @param notification - function that displays toast notification
 */
export const sendMessageToSupport = async (
  name: string,
  subject: string,
  message: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: (error: string | null) => void,
  notification: (message: string, appearance?: string) => void
) => {
  try {
    setLoading(true);
    await apiRequest(SEND_EMAIL, HTTP_METHODS.POST, {
      recipients: ['luke@sov.tech', 'brian@sov.tech'],
      subject: `${subject} from ${name}`,
      message
    });
    setLoading(false);
    notification('Your message has been sent.');
  } catch (e) {
    setLoading(false);
    setError(e.message);
  }
};
