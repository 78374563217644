import * as React from 'react';
import { Helmet } from 'react-helmet';
import { FaFrown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

/** Themes */
import { Colors, Images } from '../../Themes';

/** Presentation/UI */
import {
  PlaceholderContainer,
  PlaceholderIconContainer,
  PlaceholderHeading,
  PlaceholderText
} from '../../Components/Styled/Placeholder';
import Logo from '../../Components/Layouts/LogoWrapper';

const SubscriptionCancelled: React.FC<{}> = () => {
  return (
    <PlaceholderContainer>
      <Link to="/">
        <Logo image={Images.flumeLogo} />
      </Link>
      <PlaceholderIconContainer>
        <FaFrown size="8.5em" color={Colors.flumeGreen} />
      </PlaceholderIconContainer>
      <br />
      <PlaceholderHeading>We are sad to see you go</PlaceholderHeading>
      <br />
      <PlaceholderText>
        Your subscription has been cancelled and your account is now disabled.
        If this was a mistake click below to re-subscribe.
      </PlaceholderText>
      <Helmet title="Subscription Cancelled" />
    </PlaceholderContainer>
  );
};

export default SubscriptionCancelled;
