import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { withToastManager } from 'react-toast-notifications';
import { MdSearch, MdFileDownload } from 'react-icons/md';
import { IoMdPricetag } from 'react-icons/io';
import * as uuid from 'uuid';
import styled from 'styled-components';
import { Form } from 'reactstrap';
import { CSVLink } from 'react-csv';

/** GraphQL */
import { deleteBrand, deleteSubBrand } from '../../graphql/mutations';
import { getCompanyBrandsAndSubBrandsByUser } from '../../graphql/custom-queries';
import {
  UpdateBrandInput,
  DeleteBrandMutation,
  DeleteBrandMutationVariables,
  UpdateSubBrandInput,
  DeleteSubBrandMutation,
  DeleteSubBrandMutationVariables
} from '../../API';

/** Presentation/UI */
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import Table from '../../Components/Table';
import DialogModal from '../../Components/DialogModal';
import PageLoader from '../../Components/PageLoader';
import GlobalModalContainer from '../../Components/Modal';
import StyledButton from '../../Components/Styled/Button';
import DataImport from '../../Components/DataImport/BrandDataImport';
import {
  TableHeader,
  TableHeaderContainer
} from '../../Components/Styled/ListViewElements';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Local components */
import AddBrandModal from './AddBrandModal';
import EditBrandModal from './EditBrandModal';
import EditSubBrandModal from './EditSubBrandModal';
import Placeholder from './Placeholder';
import RowActions from './RowActions';
import SubRowActions from './SubRowActions';
import AddSubBrandModal from './AddSubBrandModal';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import {
  sortSubBrandsAlphabetically,
  sortArrayAlphabetically
} from '../../Utils/Helpers';
import { BRANDS_CSV_HEADINGS } from '../../Utils/Consts';

/** Custom Types */
import {
  ToastNotificationType,
  BrandType,
  SubBrandType
} from '../../CustomTypes';

type Props = {
  toastManager: ToastNotificationType;
};

type State = {
  modal: boolean;
  subModal: boolean;
  importModal: boolean;
  editModal: boolean;
  editSubModal: boolean;
  searchFilter: boolean;
  dialogModal: boolean;
  dialogSubModal: boolean;
  brand: UpdateBrandInput;
  brandId: string;
  subBrand: UpdateSubBrandInput;
  subBrandId: string;
};

const SubTableWrapper = styled.div`
  margin-top: 20px;
  & .ReactTable .rt-tbody .rt-tr-group {
    background: #f2f2f2;
  }
  & .rt-tr-group {
    box-shadow: none !important;
  }
  & .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none !important;
  }
`;

class Brands extends React.Component<Props, State> {
  state: State = {
    modal: false,
    subModal: false,
    importModal: false,
    editModal: false,
    editSubModal: false,
    searchFilter: false,
    dialogModal: false,
    dialogSubModal: false,
    brand: { id: '' },
    brandId: '',
    subBrand: { id: '' },
    subBrandId: ''
  };

  /** Close dialog modal for brand */
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false
    });
  };

  /** Open dialog modal for brand */
  openDialogModal = (brand: UpdateBrandInput): void => {
    const brandId = brand ? brand.id : '';
    this.setState({
      dialogModal: true,
      brand,
      brandId
    });
  };

  /** Close dialog modal for brand */
  closeDialogSubModal = (): void => {
    this.setState({
      dialogSubModal: false
    });
  };

  /** Open dialog modal for deleting a sub brand */
  openDialogSubModal = (subBrand: UpdateSubBrandInput): void => {
    const subBrandId = subBrand ? subBrand.id : '';
    this.setState({
      dialogSubModal: true,
      subBrand,
      subBrandId
    });
  };

  /** Toggle main table search filter */
  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Filter/search by brand name and sub brand name */
  tableFilter = (filter: any, row: any): boolean => {
    if (
      String(row[filter.id].toLowerCase()).startsWith(
        filter.value.toLowerCase()
      )
    ) {
      return true;
    } else if (
      row._original.subBrands &&
      row._original.subBrands.items &&
      row._original.subBrands.items.length
    ) {
      const results = row._original.subBrands.items.filter(
        (rowItem: SubBrandType) =>
          String(rowItem[filter.id].toLowerCase()).startsWith(
            filter.value.toLowerCase()
          )
      );

      return results && results.length;
    }
    return false;
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  /** Open modal */
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  /** Close import file modal */
  closeImportModal = (): void => {
    this.setState({
      importModal: false
    });
  };

  /** Open import file modal */
  openImportModal = (): void => {
    this.setState({
      importModal: true
    });
  };

  /** Close sub modal */
  closeSubModal = (): void => {
    this.setState({
      subModal: false,
      brandId: ''
    });
  };

  /** Open sub modal */
  openSubModal = (brandId: string): void => {
    this.setState({
      subModal: true,
      brandId
    });
  };

  /** Close edit modal */
  closeEditModal = (): void => {
    this.setState({
      editModal: false,
      brand: { id: '' }
    });
  };

  /** Open edit modal */
  openEditModal = (brand: UpdateBrandInput): void => {
    this.setState({
      editModal: true,
      brand
    });
  };

  /** Close edit sub brand modal */
  closeSubEditModal = (): void => {
    this.setState({
      editSubModal: false,
      subBrand: { id: '' }
    });
  };

  /** Open edit modal for sub brand */
  openSubEditModal = (subBrand: UpdateSubBrandInput): void => {
    this.setState({
      editSubModal: true,
      subBrand
    });
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  formatCsvData = (createdBrands: any) => {
    let data: any = [];
    createdBrands.forEach(item => {
      if (item.subBrands.items.length === 0) {
        return data.push([item.name]);
      }
      item.subBrands.items.map(subBrand => {
        return data.push([item.name, subBrand.name]);
      });
    });
    return data;
  };

  render() {
    const {
      modal,
      subModal,
      importModal,
      dialogModal,
      dialogSubModal,
      editModal,
      editSubModal,
      searchFilter,
      brand,
      brandId,
      subBrand,
      subBrandId
    } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <BackendWrapper>
              <Query
                query={getCompanyBrandsAndSubBrandsByUser}
                variables={{ id: userId }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (error || !data || !data.getUser) {
                    return (
                      <div>There was a problem loading your company brands</div>
                    );
                  }

                  const company = data.getUser.company;
                  const companyId = data.getUser.company.id;

                  if (
                    company.brands &&
                    company.brands.items &&
                    company.brands.items.length
                  ) {
                    const createdCompanyBrands = sortArrayAlphabetically(
                      company.brands.items
                    );
                    const formatedCsvData = this.formatCsvData(
                      company.brands.items
                    );

                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeModal}
                          title="Create Brand"
                          modalDisplay={
                            <AddBrandModal
                              companyId={companyId}
                              userId={userId}
                              notification={this.toastNotification}
                              closeModal={this.closeModal}
                            />
                          }
                          modal={modal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeImportModal}
                          title="Import Brands"
                          modalDisplay={
                            <DataImport
                              companyId={companyId}
                              closeModal={this.closeImportModal}
                              notification={this.toastNotification}
                            />
                          }
                          modal={importModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeSubModal}
                          title="Create Sub Brand"
                          modalDisplay={
                            <AddSubBrandModal
                              brandId={brandId}
                              userId={userId}
                              notification={this.toastNotification}
                              closeModal={this.closeSubModal}
                            />
                          }
                          modal={subModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeDialogModal}
                          title=""
                          modalDisplay={
                            <Mutation<
                              DeleteBrandMutation,
                              DeleteBrandMutationVariables
                            >
                              mutation={gql(deleteBrand)}
                            >
                              {(deleteBrandMutation, deleteMutation) => (
                                <Form
                                  onSubmit={e => {
                                    e.preventDefault();
                                    deleteBrandMutation({
                                      variables: {
                                        input: {
                                          id: brandId
                                        }
                                      },
                                      refetchQueries: [
                                        {
                                          query: getCompanyBrandsAndSubBrandsByUser,
                                          variables: {
                                            id: userId
                                          }
                                        }
                                      ]
                                    })
                                      .then(res => {
                                        this.closeDialogModal();
                                        this.toastNotification(
                                          'Brand deleted successfully',
                                          'success'
                                        );
                                      })
                                      .catch(err => {
                                        this.toastNotification(
                                          'Sorry, there was a problem deleting the brand',
                                          'error'
                                        );
                                      });
                                  }}
                                >
                                  <DialogModal
                                    loading={deleteMutation.loading}
                                    title="Are you sure you want to delete this brand?"
                                    toggleModal={this.closeDialogModal}
                                  />
                                </Form>
                              )}
                            </Mutation>
                          }
                          modal={dialogModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeDialogSubModal}
                          title=""
                          modalDisplay={
                            <Mutation<
                              DeleteSubBrandMutation,
                              DeleteSubBrandMutationVariables
                            >
                              mutation={gql(deleteSubBrand)}
                            >
                              {(deleteSubBrandMutation, deleteMutation) => (
                                <Form
                                  onSubmit={e => {
                                    e.preventDefault();
                                    deleteSubBrandMutation({
                                      variables: {
                                        input: {
                                          id: subBrandId
                                        }
                                      },
                                      refetchQueries: [
                                        {
                                          query: getCompanyBrandsAndSubBrandsByUser,
                                          variables: {
                                            id: userId
                                          }
                                        }
                                      ]
                                    })
                                      .then(res => {
                                        this.closeDialogSubModal();
                                        this.toastNotification(
                                          'Sub brand deleted successfully',
                                          'success'
                                        );
                                      })
                                      .catch(err => {
                                        this.toastNotification(
                                          'Sorry, there was a problem deleting the sub brand',
                                          'error'
                                        );
                                      });
                                  }}
                                >
                                  <DialogModal
                                    loading={deleteMutation.loading}
                                    title="Are you sure you want to delete this sub brand?"
                                    toggleModal={this.closeDialogSubModal}
                                  />
                                </Form>
                              )}
                            </Mutation>
                          }
                          modal={dialogSubModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeEditModal}
                          title="Update Brand"
                          modalDisplay={
                            <EditBrandModal
                              userId={userId}
                              brand={brand}
                              closeModal={this.closeEditModal}
                              notification={this.toastNotification}
                            />
                          }
                          modal={editModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeSubEditModal}
                          title="Update Sub Brand"
                          modalDisplay={
                            <EditSubBrandModal
                              userId={userId}
                              brand={subBrand}
                              closeModal={this.closeSubEditModal}
                              notification={this.toastNotification}
                            />
                          }
                          modal={editSubModal}
                        />
                        <TableHeaderContainer>
                          <TableHeader>
                            <span>Manage Brands</span>
                          </TableHeader>
                          <StyledButton
                            type="button"
                            label="Add Brand"
                            width="120px"
                            onClick={this.openModal}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                          <StyledButton
                            type="button"
                            label="Import Brands"
                            width="120px"
                            onClick={this.openImportModal}
                            color={Colors.flumeGreen}
                            background={Colors.grey}
                          />
                          <CSVLink
                            data={formatedCsvData}
                            headers={BRANDS_CSV_HEADINGS}
                            target="_blank"
                            filename="Brands.csv"
                          >
                            <StyledButton
                              type="button"
                              label={
                                <MdFileDownload
                                  size="1.3em"
                                  color={Colors.flumeDarkGreen}
                                />
                              }
                              width="auto"
                              color={Colors.flumeDarkGreen}
                              background={Colors.flumeGreen}
                            />
                          </CSVLink>
                          <StyledButton
                            type="button"
                            label={
                              <MdSearch
                                size="1.3em"
                                color={Colors.flumeDarkGreen}
                              />
                            }
                            width="auto"
                            onClick={this.toggleSearchFilter}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                        </TableHeaderContainer>
                        <Table
                          data={createdCompanyBrands}
                          defaultFilterMethod={this.tableFilter}
                          columns={[
                            {
                              Header: 'Brand Name',
                              accessor: 'name',
                              sortable: false,
                              filterable: searchFilter
                            },
                            {
                              id: uuid(),
                              Header: '',
                              accessor: (companyBrand: BrandType) => {
                                const numberOfSubBrands = companyBrand.subBrands
                                  .items
                                  ? companyBrand.subBrands.items.length
                                  : 0;
                                return (
                                  <div>
                                    <IoMdPricetag
                                      size="1.25em"
                                      color={Colors.flumeGreen}
                                    />
                                    &nbsp; {numberOfSubBrands}
                                  </div>
                                );
                              },
                              sortable: false,
                              filterable: false,
                              width: 100
                            },
                            {
                              id: uuid(),
                              Header: '',
                              accessor: (companyBrand: BrandType) => {
                                return (
                                  <RowActions
                                    openModal={this.openEditModal}
                                    openDialogModal={this.openDialogModal}
                                    brand={companyBrand}
                                  />
                                );
                              },
                              sortable: false,
                              filterable: false,
                              width: 30
                            }
                          ]}
                          SubComponent={(row: any) => (
                            <SubTableWrapper>
                              <TableHeaderContainer noMargin={true}>
                                <StyledButton
                                  type="button"
                                  label="Add Sub Brand"
                                  width="120px"
                                  color={Colors.flumeDarkGreen}
                                  background={Colors.flumeGreen}
                                  onClick={() =>
                                    this.openSubModal(row.original.id)
                                  }
                                />
                              </TableHeaderContainer>
                              <Table
                                data={sortSubBrandsAlphabetically(
                                  row.original.subBrands.items
                                )}
                                columns={[
                                  {
                                    id: 'subBrandIcon',
                                    Header: '',
                                    accessor: () => {
                                      return (
                                        <div>
                                          <IoMdPricetag
                                            size="1.25em"
                                            color={Colors.grey}
                                          />
                                        </div>
                                      );
                                    },
                                    sortable: false,
                                    filterable: false,
                                    width: 30
                                  },
                                  {
                                    id: 'subBrandName',
                                    Header: '',
                                    accessor: (
                                      companySubBrand: SubBrandType
                                    ) => {
                                      return companySubBrand.name;
                                    },
                                    sortable: true,
                                    filterable: false
                                  },
                                  {
                                    id: 'subBrandType',
                                    Header: '',
                                    accessor: (
                                      companySubBrand: SubBrandType
                                    ) => {
                                      return (
                                        <SubRowActions
                                          openModal={this.openSubEditModal}
                                          openDialogModal={
                                            this.openDialogSubModal
                                          }
                                          brand={companySubBrand}
                                        />
                                      );
                                    },
                                    sortable: false,
                                    filterable: false,
                                    width: 30
                                  }
                                ]}
                                defaultPageSize={3}
                                showPagination={true}
                              />
                            </SubTableWrapper>
                          )}
                          defaultPageSize={5}
                          showPaginationTop={false}
                          showPaginationBottom={true}
                        />
                      </div>
                    );
                  }

                  return (
                    <div>
                      <GlobalModalContainer
                        toggleModal={this.closeModal}
                        title="Create Brand"
                        modalDisplay={
                          <AddBrandModal
                            companyId={companyId}
                            userId={userId}
                            notification={this.toastNotification}
                            closeModal={this.closeModal}
                          />
                        }
                        modal={modal}
                      />
                      <Placeholder openModal={this.openModal} />
                    </div>
                  );
                }}
              </Query>
              <Helmet title="Manage Brands" />
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(Brands);
