import * as React from 'react';
import { Row, Col, Form, Label, FormGroup, Input, Button } from 'reactstrap';
import { Auth } from 'aws-amplify';

/** Presenetation/UI */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import StyledButton from '../../Components/Styled/Button';

/** Custom Types */
import { Error, UpdatePersonalProfileViews } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

type Props = {
  closeModal: () => void;
  notification(message: string): void;
  updateView(view: UpdatePersonalProfileViews): void;
};

type State = {
  oldPassword: string;
  newPassword: string;
  loading: boolean;
  error: Error;
};

class ChangePasswordForm extends React.Component<Props, State> {
  state: State = {
    oldPassword: '',
    newPassword: '',
    loading: false,
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  validateNewPasswordForm = (): boolean => {
    const { oldPassword, newPassword } = this.state;
    // Check for undefined or empty input fields
    if (!oldPassword || !newPassword) {
      this.setError('Please enter your old password and the new one');
      return false;
    }

    if (oldPassword === newPassword) {
      this.setError(
        'The password you entered is unchanged. Please enter a new password.'
      );
      return false;
    }

    return true;
  };

  // Handle change password
  changePassword = (oldPassword: string, newPassword: string): void => {
    const { closeModal, notification } = this.props;
    this.setState({ loading: true });

    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then(() => {
        this.setState({ loading: false });
        closeModal();
        notification('Password changed successfully');
      })
      .catch(err => {
        this.setState({
          loading: false,
          oldPassword: '',
          newPassword: ''
        });
        this.setError(err.message);
      });
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { updateView } = this.props;
    const { loading, error, oldPassword, newPassword } = this.state;

    return (
      <FullWidthContainer>
        <Form
          onSubmit={e => {
            e.preventDefault();
            if (this.validateNewPasswordForm()) {
              this.setState({ loading: true });
              this.changePassword(oldPassword, newPassword);
            }
          }}
        >
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="oldPassword">Old Password</Label>
                <Input
                  type="password"
                  name="oldPassword"
                  value={oldPassword}
                  id="oldPassword"
                  placeholder="Old Password"
                  onChange={e => this.setState({ oldPassword: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="newPassword">New Password</Label>
                <Input
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  id="newPassword"
                  placeholder="New Password"
                  onChange={e => this.setState({ newPassword: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <StyledButton
                  label="Change Personal Details"
                  type="button"
                  onClick={() => updateView('profileDetails')}
                  background={Colors.grey}
                  color={Colors.flumeGreen}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Button>{!loading ? 'Update Password' : <Loader />}</Button>
              </FormGroup>
            </Col>
          </Row>
          {error && <ErrorMessage errorMessage={error} />}
        </Form>
      </FullWidthContainer>
    );
  }
}

export default ChangePasswordForm;
