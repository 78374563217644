import * as React from 'react';
import { ClipLoader } from 'react-spinners';

interface IProps {
  size?: number;
  color?: string;
}

const Loader = (props: IProps) => (
  <div className="sweet-loading">
    <ClipLoader
      sizeUnit="px"
      size={!props.size ? 20 : props.size}
      color={!props.color ? '#fff' : props.color}
      loading={true}
    />
  </div>
);

export default Loader;
