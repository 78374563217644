import * as React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

type State = {
  dropdownOpen: boolean;
};

type Props = {
  productItemId: string;
  productItemImageKeys?: Array<string | null> | null;
  previousTagDescriptions: Array<string>;
  tagDescription?: string | null;
  openDeleteModal(productItemId: string): void;
  openNoteModal(productItemId: string): void;
  openUpdateModal(
    productItemId: string,
    tagDescription?: string | null,
    previousTagDescriptions?: Array<string>
  ): void;
  openUntagModal(productItemId: string): void;
  openAddTextModal(
    productItemId: string,
    productItemImageKeys?: Array<string | null> | null
  ): void;
  openMoveItemToItemsList(productItemId: string): void;
};

class RowActions extends React.Component<Props, State> {
  state: State = {
    dropdownOpen: false
  };

  toggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  render() {
    const { dropdownOpen } = this.state;
    const {
      openUpdateModal,
      openDeleteModal,
      openUntagModal,
      openNoteModal,
      openAddTextModal,
      previousTagDescriptions,
      productItemId,
      tagDescription,
      openMoveItemToItemsList,
      productItemImageKeys
    } = this.props;

    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={this.toggle}
        style={{ position: 'absolute' }}
      >
        <DropdownToggle
          tag="span"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
        </DropdownToggle>
        <DropdownMenu data-testid="dropdown-menu">
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openNoteModal(productItemId)}
          >
            Add Note
          </div>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() =>
              openUpdateModal(
                productItemId,
                tagDescription,
                previousTagDescriptions
              )
            }
          >
            Update Tag
          </div>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openUntagModal(productItemId)}
          >
            Untag Item
          </div>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openDeleteModal(productItemId)}
          >
            Delete Item
          </div>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() =>
              openAddTextModal(productItemId, productItemImageKeys)
            }
          >
            Add Text
          </div>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openMoveItemToItemsList(productItemId)}
          >
            Return To Items
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default RowActions;
