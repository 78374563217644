import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import { withToastManager } from 'react-toast-notifications';
import countryList from 'react-select-country-list';

// GraphQL
import { updateCompany } from '../../graphql/mutations';
import {
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
} from '../../API';

// Presentation/UI
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import StyledOptions from '../../Components/Styled/ProductionIndustryDropdDown';

// Utils
import { COMPANY_SIZE, PRODUCTION_INDUSTRY } from '../../Utils/Consts';
import { ToastNotificationType } from '../../CustomTypes';
import {
  convertPixelsToRem,
  sortDropDownDataAlphabetically
} from '../../Utils/Helpers';

type SelectType = {
  value: string;
  label: string;
};

type Props = {
  userId: string;
  companyId: string;
  name: string;
  state: string;
  address: string;
  industry: SelectType;
  companySize: SelectType;
  country: SelectType;
  toastManager: ToastNotificationType;
};

type State = {
  companyName: string;
  state: string;
  address: string;
  industry: SelectType;
  companySize: SelectType;
  country: SelectType;
  loading: boolean;
  error: string | null;
};

// list all countries
const COUNTRIES = countryList().getData();

class CompanyDetails extends React.Component<Props, State> {
  timeoutId: number = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      companyName: props.name,
      address: props.address,
      state: props.state,
      industry: props.industry,
      companySize: props.companySize,
      country: props.country,
      loading: false,
      error: null
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  // Select country
  selectCountry = (country: SelectType): void => {
    this.setState({ country });
  };

  /* Select production industry */
  selectIndustry = (industry: SelectType): void => {
    this.setState({ industry });
  };

  /* Select company size */
  selectCompanySize = (companySize: SelectType): void => {
    this.setState({ companySize });
  };

  /* Handle changeto input field */
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    switch (name) {
      case 'companyName':
        this.setState({ companyName: value });
        break;
      case 'address':
        this.setState({ address: value });
        break;
      case 'state':
        this.setState({ state: value });
        break;
      default:
        break;
    }
  };

  /* Validation */
  validateForm = (): boolean => {
    const {
      companyName,
      country,
      address,
      state,
      industry,
      companySize
    } = this.state;
    // Check for undefined or empty input fields
    if (
      !companyName ||
      !address ||
      !state ||
      !industry.value ||
      !companySize.value ||
      !country.value
    ) {
      this.setError('Please complete the form.');
      return false;
    }

    return true;
  };

  /**
   * display an error
   *
   * @param {string} error - error to display
   *
   * @returns {void}
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /**
   * display a toast appearance
   *
   * @param {string} message - message to display
   * @param {string} appearance - type of message to display
   *
   *
   * @returns {void}
   */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const {
      companyName,
      companySize,
      country,
      address,
      state,
      industry
    } = this.state;

    return (
      <Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>
        mutation={gql(updateCompany)}
      >
        {updateCompanyMutation => (
          <Form
            onSubmit={event => {
              event.preventDefault();

              if (this.validateForm()) {
                this.setState({ loading: true });
                const { companyId } = this.props;

                // Start by creating brand
                updateCompanyMutation({
                  variables: {
                    input: {
                      id: companyId,
                      name: companyName,
                      address,
                      state,
                      industry: industry.label,
                      companySize: companySize.label,
                      country: country.label
                    }
                  }
                }).then((res: any) => {
                  this.setState({ loading: false });
                  this.toastNotification(
                    'Company details edited successfully',
                    'success'
                  );
                });
              }
            }}
            style={{
              padding: convertPixelsToRem(20)
            }}
          >
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="name">Company Name</Label>
                  <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    value={companyName}
                    placeholder="Company name"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="address">Company Address</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    value={address}
                    placeholder="Company address"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label>Country</Label>
                  <Select
                    // @ts-ignore
                    onChange={this.selectCountry}
                    options={sortDropDownDataAlphabetically(COUNTRIES)}
                    value={country}
                    isSearchable={true}
                    placeholder=""
                    className="select-styling"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label for="state">State</Label>
                  <Input
                    type="text"
                    name="state"
                    id="state"
                    value={state}
                    placeholder="State"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label>Production Industry</Label>
                  <Select
                    // @ts-ignore
                    onChange={this.selectIndustry}
                    options={PRODUCTION_INDUSTRY}
                    value={industry}
                    formatGroupLabel={StyledOptions}
                    isSearchable={true}
                    placeholder=""
                    className="select-styling"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label>Company Size</Label>
                  <Select
                    // @ts-ignore
                    onChange={this.selectCompanySize}
                    options={COMPANY_SIZE}
                    isSearchable={true}
                    value={companySize}
                    placeholder=""
                    className="select-styling"
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <br />
            <Button disabled={this.state.loading}>
              {!this.state.loading ? <span>Save</span> : <Loader />}
            </Button>
            {this.state.error && (
              <ErrorMessage errorMessage={this.state.error} />
            )}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default withToastManager(CompanyDetails);
