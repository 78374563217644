import styled from 'styled-components';

/** Themes */
import { Colors } from '../../Themes';

export const DashboardWrapper = styled.div`
  width: ${(props: { fullWidth?: boolean }) =>
    props.fullWidth ? '100%' : 'calc(100% - 400px)'};
  margin: 0 auto;
  height: auto;
`;

export const DashboardSummaryCardContainer = styled.div`
  background: ${Colors.snow};
  height: ${(props: { height?: string }) =>
    props.height ? props.height : '150px'};
  border-radius: 5px;
`;

export const DashboardSummaryCardHeader = styled.h3`
  height: 30px;
  font-size: 1.05em;
  padding: 7px 10px;
  border-bottom: 1px solid ${Colors.backendBackground};
`;

export const DashboardSummaryCardNumberContainer = styled.div`
  height: ${(props: { height?: string }) =>
    props.height ? props.height : '120px'};
  line-height: ${(props: { height?: string }) =>
    props.height ? props.height : '120px'};
  text-align: center;
  font-size: 45px;
  color: ${Colors.lime};
`;
