import styled from 'styled-components';
import { Colors } from '../../Themes';

export const DisabledLink = styled.div`
  display: block;
  padding-left: 21px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: ${Colors.grey};
  font-size: ${(props: { fontSize?: string }) =>
    props.fontSize ? props.fontSize : '0.97em'};
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 2px;
  outline: 0;
  cursor: not-allowed;
`;

export const DisabledHyperLink = styled.a`
  display: block;
  padding-left: 21px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: ${Colors.grey};
  font-size: ${(props: { fontSize?: string }) =>
    props.fontSize ? props.fontSize : '0.97em'};
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 2px;
  outline: 0;
`;
