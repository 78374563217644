import * as React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

export const Wrapper = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  height: 100%;
`;

const QCCalendarWrapper: React.FC<Props> = props => {
  return <Wrapper>{props.children}</Wrapper>;
};

export default QCCalendarWrapper;
