import * as React from 'react';
import * as moment from 'moment';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Dropzone from 'react-dropzone';
import { Form } from 'reactstrap';
import * as Papa from 'papaparse';
import { MdFileDownload } from 'react-icons/md';

/** GraphQL */
import { createSpace } from '../../graphql/mutations';
import { getCompanySpacesByUser } from '../../graphql/custom-queries';
import {
  CreateSpaceMutation,
  CreateSpaceMutationVariables,
  SpaceStatus,
  CreateSpaceInput
} from '../../API';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Local component */
import DropzoneContainer from './DropzoneContainer';

/** Custom types */
import { Error } from '../../CustomTypes';

/** Theme */
import { Colors } from '../../Themes';

/** Utils */
import { VALID_FILE_FORMATS, DEFAULT_SPACE_COLOR } from '../../Utils/Consts';

type Props = {
  companyId: string;
  closeModal: () => void;
  notification(message: string, appearance?: string): void;
};

type State = {
  files: Array<File>;
  loading: boolean;
  error: Error;
};

class SpaceDataImport extends React.Component<Props, State> {
  state: State = {
    files: [],
    loading: false,
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** On dropping file */
  onDrop = (acceptedFiles: any): void => {
    if (!acceptedFiles || !acceptedFiles.length) {
      this.setError('Sorry, there was a problem uploading your selected file.');
    }
    // Check if the uploaded file is a valid csv file
    const uploadedFile = acceptedFiles[0];

    if (uploadedFile.name.split('.').pop() === VALID_FILE_FORMATS.csv) {
      this.setState({ files: [...this.state.files, ...acceptedFiles] });
    } else {
      this.setError('Please upload a valid CSV file.');
    }
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Get valid data to be created in the system
   * @param validSpaces - the valid spaces/data that has been parsed and returned as an array
   */
  getSpacesToBeCreated = (validSpaces: Array<Array<string>>) => {
    const { companyId } = this.props;

    return validSpaces.map((validSpace: Array<string>) => {
      /**
       * Only the values referenced by the first two indices are of relevance for this import
       * validSpace[0] - this references the space name value
       * validSpace[1] - this references the space name color code
       */
      return {
        createdAt: moment().toISOString(),
        name: validSpace[0],
        colorCode: validSpace[1],
        status: SpaceStatus.INACTIVE,
        spaceCompanyId: companyId
      };
    });
  };

  render() {
    const { files, error } = this.state;
    const { notification, closeModal } = this.props;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <Mutation<CreateSpaceMutation, CreateSpaceMutationVariables>
              mutation={gql(createSpace)}
            >
              {createSpaceMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    if (!files.length) {
                      this.setError('Please upload a valid CSV file.');
                    } else {
                      /**
                       * `Papa.parse` will return an object with a data property which consists
                       *  of an array of all the rows in the uploaded csv file. Each row is also an array.
                       *  The elements of a row array is determined by the number of columns created
                       * by the separator in the csv file (e.g. a comma `,`).
                       */
                      Papa.parse(files[0], {
                        complete: (results: any) => {
                          // Check if there are rows of data in the CSV file
                          if (results && results.data && results.data.length) {
                            const spacesDataToImport = results.data.slice(1);

                            // filter rows/lines and obtain valid ones (Must have at least a space name)
                            const validSpaces = spacesDataToImport.filter(
                              (resultRow: Array<string>) => resultRow[0] !== ''
                            );

                            if (validSpaces && validSpaces.length) {
                              /**
                               * Use array of valid space data to retrieve array of
                               * objects that match space type to be created using
                               * mutation
                               */
                              const spacesToBeCreated: Array<
                                CreateSpaceInput
                              > = this.getSpacesToBeCreated(validSpaces);

                              spacesToBeCreated.forEach(
                                (space: CreateSpaceInput) => {
                                  createSpaceMutation({
                                    variables: {
                                      input: {
                                        createdAt: space.createdAt,
                                        name:
                                          space && space.name ? space.name : '',
                                        colorCode:
                                          space && space.colorCode
                                            ? space.colorCode
                                            : DEFAULT_SPACE_COLOR,
                                        status: space.status,
                                        spaceCompanyId: space.spaceCompanyId
                                      }
                                    },
                                    refetchQueries: [
                                      {
                                        query: getCompanySpacesByUser,
                                        variables: {
                                          id: userId
                                        }
                                      }
                                    ]
                                  })
                                    .then(res => {
                                      notification(
                                        'Space created successfully'
                                      );
                                    })
                                    .catch((err: any) => {
                                      notification(
                                        'There was a problem importing the space',
                                        'error'
                                      );
                                    });
                                }
                              );
                            }
                            closeModal();
                          } else {
                            this.setError(
                              'No rows were found in the CSV file you uploaded.'
                            );
                          }
                        }
                      });
                    }
                  }}
                >
                  <DropzoneContainer>
                    <div className="dropzone">
                      {!files.length && (
                        <Dropzone onDrop={this.onDrop}>
                          {({ getRootProps, getInputProps, isDragActive }) => {
                            return (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                  <p>Drop files here...</p>
                                ) : (
                                  <p>
                                    Drop a file here, or download the sample
                                    file below
                                  </p>
                                )}
                              </div>
                            );
                          }}
                        </Dropzone>
                      )}
                      {files.map((f: File, i) => (
                        <React.Fragment key={f.size}>
                          <div
                            style={{
                              textAlign: 'center',
                              marginTop: '20px',
                              marginBottom: '20px',
                              background: Colors.snow
                            }}
                          >
                            Chosen file: {f.name} - {`${f.size} bytes`}
                          </div>
                        </React.Fragment>
                      ))}
                      <br />
                      {error && <ErrorMessage errorMessage={error} />}
                      <a
                        href={require('../../Assets/FileTemplates/SampleSpaces.csv')}
                        download="SampleSpaces.csv"
                      >
                        <StyledButton
                          type="button"
                          label={[
                            'Sample File',
                            {
                              ...(
                                <MdFileDownload
                                  size="1.3em"
                                  color={Colors.formWhite}
                                />
                              )
                            }
                          ]}
                          width="10rem"
                          color={Colors.formWhite}
                          background={Colors.grey}
                        />
                        <StyledButton
                          label="Import"
                          type="submit"
                          className="importFile"
                          background={Colors.flumeDarkGreen}
                        />
                      </a>
                    </div>
                  </DropzoneContainer>
                </Form>
              )}
            </Mutation>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default SpaceDataImport;
