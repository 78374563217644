import * as React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { UpdateSpaceInput } from '../../API';

type Props = {
  space: UpdateSpaceInput;
  openModal(role: UpdateSpaceInput): void;
  openDialogModal(brand: UpdateSpaceInput): void;
};

const RowActions: React.FC<Props> = ({ openModal, space }) => {
  const [dropdownOpen, toggleDropdown] = React.useState(false);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => toggleDropdown(!dropdownOpen)}
      style={{ position: 'absolute' }}
    >
      <DropdownToggle
        tag="span"
        onClick={() => toggleDropdown(!dropdownOpen)}
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
      </DropdownToggle>
      <DropdownMenu>
        <div
          className="avatar-dropdown-menu-item"
          onClick={() => openModal(space)}
        >
          Edit Space
        </div>
        {/* TODO */}
        {/* <div
          className="avatar-dropdown-menu-item"
          onClick={() => openDialogModal(space)}
        >
          Delete Space
        </div> */}
      </DropdownMenu>
    </Dropdown>
  );
};

export default RowActions;
