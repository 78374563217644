import * as React from 'react';

/** Generated types */
import {
  UpdateSpaceInput,
  UpdateServiceInput,
  UpdateProductItemInput
} from '../../../API';

/** Presentation/UI */
import FullWidthContainer from '../../../Components/Layouts/FullWidthContainer';
import Loader from '../../../Components/Loader';
import {
  SelectedIcon,
  SelectedColorForBatchCard
} from '../../../Components/Styled/ListViewElements';
import {
  DayContainer,
  DayHeader,
  DayWrapper
} from '../../../Components/Styled/MainPlanner';
import { SelectedBatchCardIcon } from '../../../Components/Styled/MainPlanner';

/** Custom Types */
import {
  BatchCardServiceType,
  BatchCard,
  BatchCardServices
} from '../../../CustomTypes';

/** Themes */
import { Colors, ServiceIcons } from '../../../Themes';

/** Utils */
import {
  arrayOfActiveBatchCardServices,
  checkIfServiceIsCompleted,
  returnDayOfTheWeek
} from '../BatchCardAllocationHelpers';
import { CALENDAR_WEEK } from '../../../Utils/Consts';

type SelectedService = UpdateServiceInput & {
  service: UpdateServiceInput;
};

/** Return selected services as on batch card
 * @param selectedServices - batch card services for particular batch card
 * @param productItem - product item on batch card
 */
export const returnSelectedServices = (
  selectedServices: Array<BatchCardServiceType>,
  productItem?: UpdateProductItemInput
): React.ReactNode => {
  return selectedServices.map((batchCardService: BatchCardServiceType, i) => {
    const { service } = batchCardService;
    if (service && productItem) {
      // Get the current completed services (if any) on a product item
      const completedServices: Array<string | null> =
        productItem.completedServices && productItem.completedServices.length
          ? productItem.completedServices.filter(
              (completedService: string | null) => completedService !== null
            )
          : [];

      return (
        <SelectedIcon
          background={
            productItem.qcApproved
              ? Colors.lightPurple
              : checkIfServiceIsCompleted(service.id, completedServices)
              ? Colors.flumeGreen
              : Colors.snow
          }
          small={true}
          key={i}
        >
          <img
            alt="serviceIcon"
            src={service ? service.icon : ServiceIcons.Editing}
          />
        </SelectedIcon>
      );
    }
    return (
      <SelectedIcon background={Colors.snow} small={true} key={i}>
        <img
          alt="serviceIcon"
          src={service ? service.icon : ServiceIcons.Editing}
        />
      </SelectedIcon>
    );
  });
};

/** Return colors of selected spaces as JSX
 * @param selectedSpaces - spaces selected for batch card allocations
 */
export const returnColorsOfSelectedSpaces = (
  selectedSpaces: Array<UpdateSpaceInput>
): React.ReactNode => {
  return selectedSpaces.map((selectedSpace: UpdateSpaceInput, i) => {
    return (
      <SelectedColorForBatchCard
        key={i}
        background={selectedSpace ? selectedSpace.colorCode : Colors.flumeGreen}
        small={true}
      >
        {selectedSpace.name}
      </SelectedColorForBatchCard>
    );
  });
};

/** Return selected services as JSX for qc calendar
 * @param batchCardServices - services to be performed on a particular batch card
 */
export const returnQCSelectedServices = (
  batchCardServices: Array<SelectedService>
): React.ReactNode => {
  return batchCardServices.map((selectedService: SelectedService) => {
    return (
      <SelectedBatchCardIcon key={selectedService.service.id}>
        <img
          alt="serviceIcon"
          src={
            selectedService.service.icon
              ? selectedService.service.icon
              : ServiceIcons.Editing
          }
        />
      </SelectedBatchCardIcon>
    );
  });
};

/** Return user's company spaces based on the response data from the query
 * @param responseData - data returned from the query
 */
export const companyFromResponseData = (responseData: any) => {
  if (responseData && responseData.getUser && responseData.getUser.company) {
    return responseData.getUser.company;
  }
  return {
    id: '',
    itemTemplate: {
      id: ''
    },
    services: []
  };
};

/** Return user's company spaces based on the response data from the query
 * @param responseData - data returned from the query
 */
export const companySpaces = (responseData: any) => {
  if (responseData && responseData.getUser) {
    const company = responseData.getUser.company;
    if (company.spaces && company.spaces.items && company.spaces.items.length) {
      return company.spaces.items;
    }
    return [];
  }
  return [];
};

/** Return user's company batch cards based on the response data from the query
 * @param responseData - data returned from the query
 */
export const returnCompanyBatchCardsFromResponseData = (
  responseData: any
): Array<BatchCard> => {
  if (responseData && responseData.getUser) {
    const company = responseData.getUser.company;
    const batchCards =
      company.batchCards.items && company.batchCards.items.length
        ? company.batchCards.items
        : [];
    return batchCards;
  }
  return [];
};

/** Return selected services as JSX
 * @param batchCardServices - services to be performed on a particular batch card
 */
export const selectedBatchCardServices = (
  batchCardServices: Array<BatchCardServices>
): React.ReactNode => {
  const activeBatchCardServices = arrayOfActiveBatchCardServices(
    batchCardServices
  );
  // @ts-ignore
  return activeBatchCardServices.map((selectedService: SelectedService) => {
    return (
      <SelectedBatchCardIcon key={selectedService.service.id}>
        <img
          alt="serviceIcon"
          src={
            selectedService.service.icon
              ? selectedService.service.icon
              : ServiceIcons.Editing
          }
        />
      </SelectedBatchCardIcon>
    );
  });
};

export const returnPlaceholderWeek = () => {
  const calendarWeekKeys = Object.keys(CALENDAR_WEEK).map(
    key => CALENDAR_WEEK[key]
  );

  return calendarWeekKeys.map((dayKey: string, i) => {
    return (
      <DayContainer key={dayKey}>
        <DayWrapper isDraggingOver={false}>
          <DayHeader>
            <span className="day-header">
              {returnDayOfTheWeek(parseInt(dayKey, 10))}
            </span>
            <br />
            <span className="date-header" />
          </DayHeader>
          <DayHeader>
            <div>0 batch cards</div>
            <div>0 items</div>
          </DayHeader>
          <br />
          <FullWidthContainer align="center">
            <Loader color={Colors.flumeGreen} />
          </FullWidthContainer>
        </DayWrapper>
      </DayContainer>
    );
  });
};
