/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Select from 'react-select';
import { Row, Col, Form, Label, FormGroup, Input } from 'reactstrap';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Custom Types */
import { Error } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

// Utils
import { USER_STATUS_OPTIONS, HTTP_METHODS } from '../../Utils/Consts';
import {
  sortArrayAlphabetically,
  mapUserPermissionToCognitoGroup
} from '../../Utils/Helpers';
import { apiRequest } from '../../Utils/API';
import {
  COGNITO_DISABLE_USER,
  COGNITO_ENABLE_USER,
  COGNITO_ADD_USER_TO_GROUP,
  COGNITO_REMOVE_USER_FROM_GROUP
} from '../../Utils/LambdaEndpoints';

/** GraphQL */
import {
  UpdateRoleInput,
  UpdateUserInput,
  Permission,
  UpdateUserMutationVariables,
  UpdateUserMutation,
  UserStatus
} from '../../API';
import { updateUser } from '../../graphql/mutations';
import { getCompanyUsersByUser } from '../../graphql/custom-queries';

/** API */
import AppSyncConfig from '../../aws-exports';

type UserType = UpdateUserInput & {
  company?: {
    id?: string;
  };
  userRole?: {
    id: string;
    name: string;
    permission: Permission;
  };
};
type SelectType = { value: UserStatus; label: string };
type SelectRoleType = { value: string; label: string; permission: string };

type Props = {
  user: UserType;
  roles?: Array<UpdateRoleInput>;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  fname: string;
  lname: string;
  email: string;
  userStatus: SelectType;
  userRole: SelectRoleType;
  prevUserRole: SelectRoleType;
  changeRole: boolean;
  inviteUser: boolean;
  loading: boolean;
  changeStatus: boolean;
  error: Error;
};

class EditUserForm extends React.Component<Props, State> {
  timeoutId: number = 0;

  constructor(props: Props) {
    super(props);

    const { user } = props;
    const status = user.status || UserStatus.INACTIVE;

    this.state = {
      fname: user.firstName || '',
      lname: user.lastName || '',
      email: user.email || '',
      userStatus: { value: status, label: status.toLowerCase() },
      userRole: {
        value: user.userRole ? user.userRole.id : '',
        label: user.userRole ? user.userRole.name : '',
        permission: user.userRole ? user.userRole.permission : ''
      },
      prevUserRole: {
        value: user.userRole ? user.userRole.id : '',
        label: user.userRole ? user.userRole.name : '',
        permission: user.userRole ? user.userRole.permission : ''
      },
      changeRole: false,
      inviteUser: true,
      loading: false,
      changeStatus: false,
      error: null
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Select user status
   * @param userStatus - space status (active/inactive)
   */
  selectSpaceStatus = (userStatus: SelectType): void => {
    this.setState({ userStatus, changeStatus: true });
  };

  /** Select user role
   * @param userRole - user role based on created company roles
   */
  selectRoleType = (userRole: SelectRoleType): void => {
    this.setState({ userRole, changeRole: true });
  };

  /** Company role options */
  listRoleOptions = (): Array<SelectRoleType> => {
    const { roles } = this.props;
    if (roles && roles.length) {
      const sortedRoles = sortArrayAlphabetically(roles);
      return sortedRoles.map(
        // @ts-ignore
        (role: { id: string; name: string; permission: Permission }) => {
          return {
            value: role.id,
            label: role.name,
            permission: role.permission
          };
        }
      );
    }

    return [];
  };

  /** Validation */
  validateForm = (): boolean => {
    const { userRole } = this.state;

    if (!userRole.value || userRole.value === '') {
      this.setError('Please select a company role');
      return false;
    }

    return true;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Update user
   *
   * @param {promise} updateUserMutation - grahpql mutation to update a user
   *
   * @returns void - no return data
   */

  updateUser = async (updateUserMutation: ({}) => Promise<any>) => {
    const { closeModal, user, notification } = this.props;
    const {
      fname,
      lname,
      userRole,
      userStatus,
      changeStatus,
      email,
      changeRole,
      prevUserRole
    } = this.state;
    const { id } = user;

    this.setState({ loading: true });

    // update the user in dynamodb
    updateUserMutation({
      variables: {
        input: {
          id,
          firstName: fname,
          lastName: lname,
          status: userStatus.value,
          userUserRoleId: userRole.value
        }
      },
      refetchQueries: [
        {
          query: getCompanyUsersByUser,
          variables: {
            id
          }
        }
      ]
    })
      .then(async () => {
        const bodyParams = {
          userPoolId: AppSyncConfig.aws_user_pools_id,
          userName: id
        };
        if (changeStatus && userStatus.value === UserStatus.INACTIVE) {
          await apiRequest(
            COGNITO_DISABLE_USER,
            HTTP_METHODS.POST,
            bodyParams
          ).catch(e => {
            this.setError(e.message);
          });
        }
        if (changeStatus && userStatus.value === UserStatus.ACTIVE) {
          await apiRequest(
            COGNITO_ENABLE_USER,
            HTTP_METHODS.POST,
            bodyParams
          ).catch(e => {
            this.setError(e.message);
          });
        }

        if (changeRole) {
          const removeFromGroupBodyParams = {
            groupName: mapUserPermissionToCognitoGroup(prevUserRole.permission),
            userPoolId: AppSyncConfig.aws_user_pools_id,
            email
          };
          const addToGroupBodyParams = {
            groupName: mapUserPermissionToCognitoGroup(userRole.permission),
            userPoolId: AppSyncConfig.aws_user_pools_id,
            email
          };

          await apiRequest(
            COGNITO_REMOVE_USER_FROM_GROUP,
            HTTP_METHODS.POST,
            removeFromGroupBodyParams
          ).catch(e => {
            this.setState({ loading: false });
            this.setError(e.message);
          });

          await apiRequest(
            COGNITO_ADD_USER_TO_GROUP,
            HTTP_METHODS.POST,
            addToGroupBodyParams
          ).catch(e => {
            this.setState({ loading: false });
            this.setError(e.message);
          });
        }
        this.setState({ loading: false });
        closeModal();
        notification(`User details updated successfully`);
      })
      .catch(err => {
        this.setState({ loading: false });
        this.setError(err.message);
      });
  };

  /**
   * Toggle whether or not to invite a user to the platform via email
   */
  toggleInviteUser = (inviteUser: boolean) => {
    this.setState({ inviteUser });
  };

  render() {
    const { fname, lname, userStatus, userRole, error, loading } = this.state;

    return (
      <Mutation<UpdateUserMutation, UpdateUserMutationVariables>
        mutation={gql(updateUser)}
      >
        {updateUserMutation => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateForm()) {
                this.updateUser(updateUserMutation);
              }
            }}
          >
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="fname">First Name</Label>
                  <Input
                    type="text"
                    name="fname"
                    value={fname}
                    id="fname"
                    placeholder="First Name"
                    onChange={e => this.setState({ fname: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="lname">Last Name</Label>
                  <Input
                    type="text"
                    name="lname"
                    value={lname}
                    id="lname"
                    placeholder="Last Name"
                    onChange={e => this.setState({ lname: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="userRole">User Role</Label>
                  <Select
                    // @ts-ignore
                    onChange={this.selectRoleType}
                    options={this.listRoleOptions()}
                    value={userRole}
                    isSearchable={true}
                    className="select-styling"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="userStatus">User Status</Label>
                  <Select
                    // @ts-ignore
                    onChange={this.selectSpaceStatus}
                    options={USER_STATUS_OPTIONS}
                    value={userStatus}
                    isSearchable={true}
                    className="select-styling capitalized"
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <StyledButton
                    type="submit"
                    disabled={loading}
                    label={!loading ? 'Save' : <Loader />}
                    color={Colors.flumeDarkGreen}
                    background={Colors.flumeGreen}
                  />
                </FormGroup>
              </Col>
            </Row>
            {error && <ErrorMessage errorMessage={error} />}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default EditUserForm;
