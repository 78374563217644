/** Custom types */
import { BatchCard, SavedBatchCardAllocation } from '../../CustomTypes';

/** Utils */
import {
  returnAllocationsFromBatchCards,
  checkIfBatchCardHasAllocationsInCurrentWeek,
  checkIfBatchCardHasAllocationsInCurrentMonth,
  checkIfBatchCardHasAllocationsInSelectedYear
} from '../MainPlanner/BatchCardAllocationHelpers';
import {
  returnFlattenedArray,
  returnArrayWithUniqValues
} from '../../Utils/Helpers';
import { UpdateProductItemInput, UpdateServiceInput } from '../../API';

type BatchCardService = {
  id: string;
  isComplete: boolean;
  service: UpdateServiceInput;
};

/** Return number of product items in progress for the week
 * @param batchCards - array of batch cards
 * @param status - product item status (complete/incomplete)
 */
export const returnNumberOfItemsInProgressForTheWeek = (
  batchCards: Array<BatchCard>,
  status: 'complete' | 'incomplete'
) => {
  let allProductItemsBasedOnStatus: Array<UpdateProductItemInput> = [];

  const batchCardsWithAllocations = batchCards.filter(
    (batchCard: BatchCard) =>
      batchCard.batchCardAllocations &&
      batchCard.batchCardAllocations.items.length
  );

  const batchCardAllocations = returnAllocationsFromBatchCards(
    batchCardsWithAllocations
  );
  if (batchCardAllocations && batchCardAllocations.length) {
    const mergedAllocations = returnFlattenedArray(batchCardAllocations);
    // Get non obsolete allocations from batch cards
    const nonObsoleteBatchCardAllocations = mergedAllocations.filter(
      (batchCardAllocation: SavedBatchCardAllocation) =>
        !batchCardAllocation.obsolete
    );

    // Get product items per batch card
    const productItemsPerBatchCard = nonObsoleteBatchCardAllocations.map(
      (batchCardAllocation: SavedBatchCardAllocation) => {
        return batchCardAllocation.batchCard.productItems;
      }
    );
    // Get all product items into single array
    const allProductItems = returnFlattenedArray(productItemsPerBatchCard);
    // Get all product items based on status
    if (status === 'complete') {
      allProductItemsBasedOnStatus = allProductItems.filter(
        (productItem: UpdateProductItemInput) => productItem.complete
      );
    } else {
      allProductItemsBasedOnStatus = allProductItems.filter(
        (productItem: UpdateProductItemInput) => !productItem.complete
      );
    }

    // Get id's of product items based on status
    const productItemIds = allProductItemsBasedOnStatus.map(
      (productItem: UpdateProductItemInput) => productItem.id
    );

    return returnArrayWithUniqValues(productItemIds).length;
  }

  return 0;
};

/** Return number of product items in progress for the month
 * @param batchCards - array of batch cards
 * @param chosenMonth - selected month
 * @param status - product item status (complete/incomplete)
 */
export const returnNumberOfItemsInProgressForTheMonth = (
  batchCards: Array<BatchCard>,
  chosenMonth: string,
  status: 'complete' | 'incomplete'
) => {
  let allProductItemsBasedOnStatus: Array<UpdateProductItemInput> = [];

  const batchCardsWithAllocations = batchCards.filter(
    (batchCard: BatchCard) =>
      batchCard.batchCardAllocations &&
      batchCard.batchCardAllocations.items.length
  );

  // Batch cards with allocations in the chosen month
  const batchCardsWithAllocationsForChosenMonth = batchCardsWithAllocations.filter(
    (batchCard: BatchCard) => {
      return checkIfBatchCardHasAllocationsInCurrentMonth(
        batchCard,
        chosenMonth
      );
    }
  );

  if (
    batchCardsWithAllocationsForChosenMonth &&
    batchCardsWithAllocationsForChosenMonth.length
  ) {
    // Get product items per batch card
    const productItemsPerBatchCard = batchCardsWithAllocationsForChosenMonth.map(
      (batchCard: BatchCard) => {
        return batchCard.productItems;
      }
    );
    // Get all product items into single array
    const allProductItems = returnFlattenedArray(productItemsPerBatchCard);

    // Get all product items based on status
    if (status === 'complete') {
      allProductItemsBasedOnStatus = allProductItems.filter(
        (productItem: UpdateProductItemInput) => productItem.complete
      );
    } else {
      allProductItemsBasedOnStatus = allProductItems.filter(
        (productItem: UpdateProductItemInput) => !productItem.complete
      );
    }

    return allProductItemsBasedOnStatus.length;
  }

  return 0;
};

/** Return number of product items in progress for the year
 * @param batchCards - array of batch cards
 * @param chosenYear - selected year
 * @param status - product item status (complete/incomplete)
 */
export const returnNumberOfItemsInProgressForTheYear = (
  batchCards: Array<BatchCard>,
  chosenYear: string,
  status: 'complete' | 'incomplete'
) => {
  let allProductItemsBasedOnStatus: Array<UpdateProductItemInput> = [];

  const batchCardsWithAllocations = batchCards.filter(
    (batchCard: BatchCard) =>
      batchCard.batchCardAllocations &&
      batchCard.batchCardAllocations.items.length
  );

  // Batch cards with allocations in the chosen month
  const batchCardsWithAllocationsForSelectedYear = batchCardsWithAllocations.filter(
    (batchCard: BatchCard) => {
      return checkIfBatchCardHasAllocationsInSelectedYear(
        batchCard,
        chosenYear
      );
    }
  );

  if (
    batchCardsWithAllocationsForSelectedYear &&
    batchCardsWithAllocationsForSelectedYear.length
  ) {
    // Get product items per batch card
    const productItemsPerBatchCard = batchCardsWithAllocationsForSelectedYear.map(
      (batchCard: BatchCard) => {
        return batchCard.productItems;
      }
    );
    // Get all product items into single array
    const allProductItems = returnFlattenedArray(productItemsPerBatchCard);

    // Get all product items based on status
    if (status === 'complete') {
      allProductItemsBasedOnStatus = allProductItems.filter(
        (productItem: UpdateProductItemInput) => productItem.complete
      );
    } else {
      allProductItemsBasedOnStatus = allProductItems.filter(
        (productItem: UpdateProductItemInput) => !productItem.complete
      );
    }

    return allProductItemsBasedOnStatus.length;
  }

  return 0;
};

/** Return number of batch cards for the week based on status (complete/incomplete)
 * @param batchCards - array of batch cards
 * @param formattedDatesOfChosenWeek - array of dates in string format for a particular week
 * @param status - batch card status (complete/incomplete)
 */
export const returnNumberOfBatchCardsForTheWeekBasedOnStatus = (
  batchCards: Array<BatchCard>,
  formattedDatesOfChosenWeek: Array<string>,
  status: 'complete' | 'incomplete'
) => {
  const batchCardsWithAllocations = batchCards.filter(
    (batchCard: BatchCard) =>
      batchCard.batchCardAllocations &&
      batchCard.batchCardAllocations.items.length
  );

  // Batch cards with allocations in the chosen week
  const batchCardsWithAllocationsForChosenWeek = batchCardsWithAllocations.filter(
    (batchCard: BatchCard) => {
      return checkIfBatchCardHasAllocationsInCurrentWeek(
        batchCard,
        formattedDatesOfChosenWeek
      );
    }
  );

  const incompleteBatchCards = batchCardsWithAllocationsForChosenWeek.filter(
    (batchCard: BatchCard) => {
      return batchCard.services.items.find(
        // @ts-ignore
        (batchCardService: BatchCardService) => !batchCardService.isComplete
      );
    }
  );

  if (status === 'complete') {
    return (
      batchCardsWithAllocationsForChosenWeek.length -
      incompleteBatchCards.length
    );
  } else {
    return incompleteBatchCards.length;
  }
};

/** Return number of batch cards for the week based on status (complete/incomplete)
 * @param batchCards - array of batch cards
 */
export const returnNumberOfBatchCardsForTheMonthBasedOnStatus = (
  batchCards: Array<BatchCard>,
  chosenMonth: string,
  status: 'complete' | 'incomplete'
) => {
  const batchCardsWithAllocations = batchCards.filter(
    (batchCard: BatchCard) =>
      batchCard.batchCardAllocations &&
      batchCard.batchCardAllocations.items.length
  );

  // Batch cards with allocations in the chosen month
  const batchCardsWithAllocationsForChosenMonth = batchCardsWithAllocations.filter(
    (batchCard: BatchCard) => {
      return checkIfBatchCardHasAllocationsInCurrentMonth(
        batchCard,
        chosenMonth
      );
    }
  );

  const incompleteBatchCards = batchCardsWithAllocationsForChosenMonth.filter(
    (batchCard: BatchCard) => {
      return batchCard.services.items.find(
        // @ts-ignore
        (batchCardService: BatchCardService) => !batchCardService.isComplete
      );
    }
  );

  if (status === 'complete') {
    return (
      batchCardsWithAllocationsForChosenMonth.length -
      incompleteBatchCards.length
    );
  } else {
    return incompleteBatchCards.length;
  }
};

/** Return number of batch cards in progress for the year
 * @param batchCards - array of batch cards
 * @param chosenYear - selected year
 * @param status - product item status (complete/incomplete)
 */
export const returnNumberOfBatchCardsForTheYearBasedOnStatus = (
  batchCards: Array<BatchCard>,
  chosenYear: string,
  status: 'complete' | 'incomplete'
) => {
  const batchCardsWithAllocations = batchCards.filter(
    (batchCard: BatchCard) =>
      batchCard.batchCardAllocations &&
      batchCard.batchCardAllocations.items.length
  );

  // Batch cards with allocations in the chosen month
  const batchCardsWithAllocationsForSelectedYear = batchCardsWithAllocations.filter(
    (batchCard: BatchCard) => {
      return checkIfBatchCardHasAllocationsInSelectedYear(
        batchCard,
        chosenYear
      );
    }
  );

  const incompleteBatchCards = batchCardsWithAllocationsForSelectedYear.filter(
    (batchCard: BatchCard) => {
      return batchCard.services.items.find(
        // @ts-ignore
        (batchCardService: BatchCardService) => !batchCardService.isComplete
      );
    }
  );

  if (status === 'complete') {
    return (
      batchCardsWithAllocationsForSelectedYear.length -
      incompleteBatchCards.length
    );
  } else {
    return incompleteBatchCards.length;
  }
};
