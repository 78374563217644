import * as React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Container } from 'reactstrap';
import { IoIosTrash } from 'react-icons/io';
import * as moment from 'moment';

/* presentation components */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import StyledDivider from '../../Components/Styled/Divider';
import GlobalModalContainer from '../../Components/Modal';
import DialogModal from '../../Components/DialogModal';
import AddCardForm from './AddCardForm';
import { CardText } from '../../Components/Styled/BankCard';
import { TableHeaderContainer } from '../../Components/Styled/ListViewElements';
import StyledButton from '../../Components/Styled/Button';
import Table from '../../Components/Table';

/* Themes */
import { Colors } from '../../Themes';

/* Stripe API Utils */
import {
  CUSTOMER_ADD_CARD,
  CUSTOMER_STRIPE_DATA,
  CUSTOMER_DELETE_CARD
} from '../../Utils/LambdaEndpoints';

/* Custom types */
import { Error, ToastNotificationType } from '../../CustomTypes';

/* Utils */
import { HTTP_METHODS } from '../../Utils/Consts';
import { convertPixelsToRem, checkIfCardIsExpired } from '../../Utils/Helpers';

/* API */
import { apiRequest } from '../../Utils/API';

type Props = {
  stripeCustomerId: string | null;
  stripe?: any;
  toastManager: ToastNotificationType;
};

type State = {
  loading: boolean;
  loadingRequest: boolean;
  dialogModal: boolean;
  addCardModal: boolean;
  error: Error;
  cards: Array<{}>;
  cardNumber: string;
  cardHolderName: string;
  cardCVC: string;
  cardId: string;
  expiryDate: Date;
};

type CardData = {
  id: string;
  exp_month: string;
  exp_year: string;
  name: string;
  last4: string;
};

class Cards extends React.Component<Props, State> {
  timeoutId: number = 0;

  state: State = {
    loading: false,
    loadingRequest: false,
    dialogModal: false,
    addCardModal: false,
    error: null,
    cards: [],
    cardNumber: '',
    cardHolderName: '',
    cardCVC: '',
    cardId: '',
    expiryDate: new Date()
  };

  componentDidMount() {
    this.getCustomerData();
  }

  /**
   * Get a customer's stripe data
   *
   * TODO: use this method in the accounts and billing container to fetch all
   * customer data including plans and cards etc
   *
   * @returns {void}
   */
  getCustomerData = async () => {
    this.setState({ loading: true });
    const { stripeCustomerId } = this.props;

    const response = await apiRequest(
      `${CUSTOMER_STRIPE_DATA}/${stripeCustomerId}`,
      HTTP_METHODS.GET
    ).catch(e => {
      this.setState({ loading: false });
      this.setError(e.message);
    });

    if (response) {
      this.setState({
        loading: false,
        cards: response.sources ? response.sources.data : []
      });
    }
  };

  /** Close dialog modal for brand */
  closeDialogModal = (): void => {
    this.setState({ dialogModal: false });
  };

  /** Open dialog modal for brand
   * @param {React.FormEvent} event - browser event
   * @param {string} cardId - id for the card been deleted
   *
   * @returns {void}
   */
  openDialogModal = (event: React.FormEvent, cardId: string) => {
    this.setState({ dialogModal: true, cardId });
  };

  /** Open card modal */
  openAddCardModal = () => {
    this.setState({ addCardModal: true });
  };

  /** Close card modal */
  closeAddCardModal = () => {
    this.setState({ addCardModal: false });
  };

  /** toast notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  /* Handle input change */
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    switch (name) {
      case 'cardNumber':
        this.setState({ cardNumber: value });
        break;
      case 'cardHolderName':
        this.setState({ cardHolderName: value });
        break;
      case 'cardCVC':
        this.setState({ cardCVC: value });
        break;

      default:
        break;
    }
  };

  handleDeleteCard = async (event: React.FormEvent) => {
    this.setState({ loadingRequest: true });

    const { cards, cardId } = this.state;
    const { stripeCustomerId } = this.props;

    const bodyParams = {
      customerId: stripeCustomerId,
      cardId
    };

    const response = await apiRequest(
      CUSTOMER_DELETE_CARD,
      HTTP_METHODS.POST,
      bodyParams
    ).catch(e => {
      this.setState({ loading: false });
      this.setError(e.message);
    });

    if (response) {
      const { id } = response;

      // @ts-ignore
      const remainingCards = cards.filter((card: CardData) => card.id !== id);

      this.setState({
        loadingRequest: false,
        dialogModal: false,
        cards: remainingCards
      });
      this.toastNotification('Card deleted successfully!', 'success');
    }
  };

  handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const { cardHolderName } = this.state;

    if (!cardHolderName) {
      this.setError('card holder name cannot be empty');
    } else {
      this.setState({ loadingRequest: true });
      let token: { id: string } = { id: '' };

      this.props.stripe.createToken({ name: cardHolderName }).then(result => {
        if (result.error) {
          // Inform the customer that there was an error.
          this.setError(result.error.message);
          this.setState({ loadingRequest: false });
        } else {
          token = result.token;
          const bodyParams = {
            customerId: this.props.stripeCustomerId,
            token: token.id
          };

          apiRequest(CUSTOMER_ADD_CARD, HTTP_METHODS.POST, bodyParams)
            .then(response => {
              const allCards = Object.assign([], this.state.cards, [
                ...this.state.cards,
                response
              ]);

              this.setState({
                addCardModal: false,
                loadingRequest: false,
                cards: allCards
              });
              this.toastNotification('Card added successfully!', 'success');
            })
            .catch(e => {
              this.setState({ loadingRequest: false });
              this.setError(e.message);
            });
        }
      });
    }
  };

  /**
   * display an error
   *
   * @param {string} error - error to display
   *
   * @returns {void}
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const {
      loading,
      loadingRequest,
      error,
      cards,
      cardHolderName,
      dialogModal,
      addCardModal
    } = this.state;

    if (loading) {
      return (
        <FullWidthContainer align="center">
          <Loader size={150} color={Colors.flumeGreen} />
        </FullWidthContainer>
      );
    }

    return (
      <React.Fragment>
        <GlobalModalContainer
          toggleModal={this.closeDialogModal}
          title=""
          modalDisplay={
            <DialogModal
              loading={loadingRequest}
              title="Are you sure you want to delete this card?"
              toggleModal={this.closeDialogModal}
              handleAccept={this.handleDeleteCard}
            />
          }
          modal={dialogModal}
        />
        <GlobalModalContainer
          toggleModal={this.closeAddCardModal}
          title="Add new card"
          modalDisplay={
            <React.Fragment>
              <AddCardForm
                loading={loadingRequest}
                error={error}
                // @ts-ignore
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                cardHolderName={cardHolderName}
                CardElement={CardElement}
              />
            </React.Fragment>
          }
          modal={addCardModal}
        />
        <Container
          style={{
            marginBottom: convertPixelsToRem(20),
            padding: convertPixelsToRem(20),
            maxWidth: '100%'
          }}
        >
          {error && !addCardModal ? (
            <ErrorMessage errorMessage={error} />
          ) : (
            <React.Fragment>
              <TableHeaderContainer
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <StyledButton
                  type="button"
                  onClick={this.openAddCardModal}
                  label="Add New"
                  color={Colors.snow}
                  background={Colors.coalLight}
                  width={convertPixelsToRem(120)}
                />
              </TableHeaderContainer>
              <StyledDivider borderSize={2} borderColor={Colors.mystic} />

              <Table
                data={cards}
                columns={[
                  {
                    id: 'card-name',
                    Header: 'Card Name',
                    accessor: ({ name, exp_month, exp_year }) => {
                      const cardExpiry = moment(
                        new Date(`${exp_month + 1}/01/${exp_year}`)
                      );

                      return (
                        <CardText expired={checkIfCardIsExpired(cardExpiry)}>
                          {name}
                        </CardText>
                      );
                    },
                    sortable: false,
                    filterable: false
                  },
                  {
                    id: 'card-epiry',
                    Header: 'Expiry Date',
                    accessor: ({ exp_month, exp_year }) => {
                      const cardExpiry = moment(
                        new Date(`${exp_month + 1}/01/${exp_year}`)
                      );

                      return (
                        <div
                          style={{
                            textIndent: convertPixelsToRem(16)
                          }}
                        >
                          <CardText expired={checkIfCardIsExpired(cardExpiry)}>
                            {exp_month}/{exp_year}
                          </CardText>
                        </div>
                      );
                    },
                    sortable: false,
                    filterable: false
                  },
                  {
                    id: 'last-4',
                    Header: 'Last 4 Digits',
                    accessor: ({ exp_month, exp_year, last4 }) => {
                      const cardExpiry = moment(
                        new Date(`${exp_month + 1}/01/${exp_year}`)
                      );

                      return (
                        <div style={{ textIndent: convertPixelsToRem(16) }}>
                          <CardText expired={checkIfCardIsExpired(cardExpiry)}>
                            ******{last4}
                          </CardText>
                        </div>
                      );
                    },
                    sortable: false,
                    filterable: false
                  },
                  {
                    id: 'delete-icon',
                    accessor: ({ id }) => {
                      return (
                        <div
                          style={{
                            position: 'relative',
                            minHeight: convertPixelsToRem(30)
                          }}
                        >
                          <IoIosTrash
                            onClick={event => this.openDialogModal(event, id)}
                            size={convertPixelsToRem(30)}
                            color={Colors.pink}
                            className="pointer-cursor"
                            style={{
                              position: 'absolute',
                              right: convertPixelsToRem(16)
                            }}
                          />
                        </div>
                      );
                    },
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={5}
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </React.Fragment>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default injectStripe(Cards);
