import * as React from 'react';
import Dropzone from 'react-dropzone';

/** Context API */
import { FileUploadContextConsumer } from '../../Containers/NewProductItems/FileUploadContextProvider';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';

/** Local component */
import DropzoneContainer from '../../Components/Styled/BatchCardDropzoneContainer';

/** Custom types */
import { Error } from '../../CustomTypes';

/** Utils */
import { VALID_FILE_FORMATS } from '../../Utils/Consts';

type Props = {
  bulkAction?: boolean | null;
};

type State = {
  loading: boolean;
  error: Error;
};

class UploadImages extends React.Component<Props, State> {
  state: State = {
    loading: false,
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** On dropping file
   * @param acceptedFiles - files dropped in dropzone
   */
  onDrop = (
    acceptedFiles: Array<File>,
    rejectedFiles: Array<File>,
    uploadFiles: (acceptedFiles: Array<File>) => void
  ): void => {
    if (!acceptedFiles || !acceptedFiles.length) {
      this.setError('Sorry, there was a problem uploading your selected file.');
    }
    if (rejectedFiles && rejectedFiles.length) {
      this.setError('Some of the files we unable to upload.');
    }
    // Get uploaded files of a valid type (jpg or png)
    const files = acceptedFiles.filter(
      (acceptedFile: File) =>
        acceptedFile.type === VALID_FILE_FORMATS.png ||
        acceptedFile.type === VALID_FILE_FORMATS.jpg
    );

    uploadFiles(files);
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { error } = this.state;
    return (
      <FileUploadContextConsumer>
        {({ uploadFiles }) => {
          return (
            <DropzoneContainer>
              <div className="dropzone">
                <Dropzone
                  onDrop={(accepted, rejected) => {
                    this.onDrop(accepted, rejected, uploadFiles);
                  }}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p>Drop files here...</p>
                        ) : this.props.bulkAction ? (
                          <p>
                            <h5>Drop Files Here</h5>
                            To match Items, file names must contain the Unique
                            Item Code, an underscore(_) and a numbered suffix to
                            the end of the code eg UIC_1, UIC_2
                          </p>
                        ) : (
                          <p>
                            Drop image files here, or click to select an image
                            from your local disk.
                          </p>
                        )}
                      </div>
                    );
                  }}
                </Dropzone>
                <br />
                {error && <ErrorMessage errorMessage={error} />}
              </div>
            </DropzoneContainer>
          );
        }}
      </FileUploadContextConsumer>
    );
  }
}

export default UploadImages;
