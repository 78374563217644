import styled from 'styled-components';

/** Themes */
import { Colors } from '../../Themes';
import { convertPixelsToRem } from '../../Utils/Helpers';

type Props = {
  noMargin?: boolean;
  height?: number;
};

export const PreviousTagContainer = styled.div<Props>`
  margin-top: ${(props: Props) =>
    props.noMargin ? '0rem' : convertPixelsToRem(20)};
  height: ${(props: Props) =>
    props.height ? convertPixelsToRem(props.height) : 'auto'};
  overflow: auto;
`;

export const SubHeading = styled.h6`
  margin-bottom: ${convertPixelsToRem(10)};
`;

export const Tag = styled.div`
  background: ${Colors.lightBlueGrey};
  color: ${Colors.grey};
  padding: 0.375rem 0.75rem;
  border: none !important;
  border-radius: ${convertPixelsToRem(5)};
`;

export const TagContainer = styled.div`
  margin-bottom: ${convertPixelsToRem(30)};
`;
