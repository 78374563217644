import * as React from 'react';
import { CSVLink } from 'react-csv';

/** Presentation/UI */
import FullWidthContainer from '../../../Components/Layouts/FullWidthContainer';
import StyledButton from '../../../Components/Styled/Button';

/** Themes */
import { Colors } from '../../../Themes';

type Props = {
  dataTotal: number;
  filename: string;
  csvData: Array<Array<string>>;
};

const DataAndExport: React.FC<Props> = ({ csvData, dataTotal, filename }) => {
  return (
    <FullWidthContainer>
      <span>{dataTotal}</span>
      <br />
      <CSVLink data={csvData} target="_blank" filename={filename}>
        <StyledButton
          disabled={dataTotal === 0}
          type="button"
          label="Export to CSV"
          width="120px"
          color={Colors.flumeGreen}
          background={Colors.flumeDarkGreen}
        />
      </CSVLink>
    </FullWidthContainer>
  );
};

export default DataAndExport;
