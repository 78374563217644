import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

/** Presentation/UI */
import BackendWrapper from '../../Components/Layouts/BackendWrapper';

/** Local components */
import CalendarContainer from './CalendarContainer';

/** Utils */
import {
  returnWeek,
  nextWeek,
  prevWeek,
  updateCurrentWeekByDate
} from '../../Utils/Helpers';

const MainPlannerContainer = styled.div`
  display: inline-flex;
  display: grid;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  grid-template-columns: 250px calc(100% - 250px) 250px;
`;

type State = {
  currentWeek: Array<Date>;
};

class QualityControlCalendar extends React.Component<{}, State> {
  state = {
    currentWeek: returnWeek()
  };

  /** Toggle calendar to previous week */
  changeToPrevWeek = () => {
    const { currentWeek } = this.state;
    this.setState({ currentWeek: prevWeek(currentWeek) });
  };

  /** Toggle calendar to next week */
  changeToNextWeek = () => {
    const { currentWeek } = this.state;
    this.setState({ currentWeek: nextWeek(currentWeek) });
  };

  /** Update calendar to the week of a selected date
   * @param date - the chosen date
   */
  changeWeekByDate = (date: Date | null) => {
    const { currentWeek } = this.state;
    this.setState({ currentWeek: updateCurrentWeekByDate(date, currentWeek) });
  };

  render() {
    const { currentWeek } = this.state;

    return (
      <BackendWrapper>
        <MainPlannerContainer>
          <CalendarContainer
            currentWeek={currentWeek}
            nextWeek={this.changeToNextWeek}
            prevWeek={this.changeToPrevWeek}
            updateCurrentWeekByDate={this.changeWeekByDate}
          />
        </MainPlannerContainer>
        <Helmet title="Quality Control Calendar" />
      </BackendWrapper>
    );
  }
}

export default QualityControlCalendar;
