import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Query } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { MdSearch, MdDone, MdFileDownload } from 'react-icons/md';
import { CSVLink } from 'react-csv';

/** GraphQL */
import { UpdateProductItemInput } from '../../API';
import { getCompanyProductItemsByUser } from '../../graphql/custom-queries';

/** Presentation/UI */
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import PageLoader from '../../Components/PageLoader';
import Table from '../../Components/Table';
import {
  TableHeader,
  TableHeaderContainer,
  CircleIcon,
  Checkbox
} from '../../Components/Styled/ListViewElements';
import DataImport from '../../Components/DataImport/ProductItemsDataImport';
import GlobalModalContainer from '../../Components/Modal';
import PreviousTags from '../MainPlanner/PreviousTags';
import StyledButton from '../../Components/Styled/Button';
import RowActions from './RowActions';

/** Local components */
import AddProductModal from './AddProductModal';
import CreateNewBatchCard from './CreateNewBatchCard';
import EditProductModal from './EditProductModal';

/** Custom Types */
import { ToastNotificationType } from '../../CustomTypes';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import {
  returnNewProductItemColumns,
  checkIfArrayOfStrings,
  returnValidProductItem,
  checkForNullBrandAndSubBrandCsvData,
  sortArrayAlphabetically
} from '../../Utils/Helpers';
import { PRODUCT_ITEMS_CSV_HEADINGS } from '../../Utils/Consts';
import { returnProductItemsNotAssociatedWithBatchCard } from './NewProductItemsHelpers';

type Props = {
  toastManager: ToastNotificationType;
};

type State = {
  modal: boolean;
  editModal: boolean;
  tagsModal: boolean;
  searchFilter: boolean;
  allSelected: boolean;
  importModal: boolean;
  batchCardModal: boolean;
  previousTagDescriptions: Array<string>;
  product: UpdateProductItemInput;
  productId: string;
  selectedProductItems: Array<string> | Array<null>;
};

class NewProductItems extends React.Component<Props, State> {
  state = {
    modal: false,
    editModal: false,
    tagsModal: false,
    searchFilter: false,
    allSelected: false,
    importModal: false,
    batchCardModal: false,
    previousTagDescriptions: [],
    product: { id: '' },
    productId: '',
    selectedProductItems: []
  };

  /** Toggle main table search filter */
  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  /** Open modal */
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  /** Open tags modal */
  openTagsModal = (previousTagDescriptions: Array<string> = []): void => {
    this.setState({
      tagsModal: true,
      previousTagDescriptions
    });
  };

  /** Close batch card modal */
  closeBatchCardModal = (): void => {
    this.setState({
      batchCardModal: false,
      allSelected: false,
      selectedProductItems: []
    });
  };

  /** Open batch card modal */
  openBatchCardModal = (): void => {
    const { selectedProductItems } = this.state;
    // Don't open batch card modal if not product items have been selected
    if (selectedProductItems.length) {
      this.setState({
        batchCardModal: true
      });
    } else {
      this.toastNotification('Please select items first', 'error');
    }
  };

  /** Close edit modal */
  closeEditModal = (): void => {
    this.setState({
      editModal: false,
      product: { id: '' }
    });
  };

  /** Open edit modal */
  openEditModal = (product: UpdateProductItemInput): void => {
    this.setState({
      editModal: true,
      product
    });
  };

  /** Close import file modal */
  closeImportModal = (): void => {
    this.setState({
      importModal: false
    });
  };

  /** Open import file modal */
  openImportModal = (): void => {
    this.setState({
      importModal: true
    });
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  /** Select/deselect product items from table. A product id can only appear once in the array of selected items
   * @param productItemId - the selected product item's id
   */
  selectProductItem = (productItemId: string): void => {
    const { selectedProductItems } = this.state;
    // Check if the selected item exists in the array before adding it
    const newSelectedProductItems = selectedProductItems.find(
      cProductItemId => cProductItemId === productItemId
    )
      ? selectedProductItems.filter(
          sProductItemId => sProductItemId !== productItemId
        )
      : [...selectedProductItems, productItemId];
    this.setState({ selectedProductItems: newSelectedProductItems });
  };

  /** Toggle selection of all product items
   * @param productItems - array of all the product items listed
   */
  toggleSelectAll = (productItems: Array<UpdateProductItemInput>): void => {
    const { selectedProductItems } = this.state;
    // the local state currently consists of all product items, then deselect each of them
    if (selectedProductItems.length === productItems.length) {
      this.setState({ selectedProductItems: [], allSelected: false });
    } else {
      const productItemsIds = productItems.map(
        (productItem: UpdateProductItemInput) => {
          return productItem.id;
        }
      );
      this.setState({
        selectedProductItems: productItemsIds,
        allSelected: true
      });
    }
  };

  /** Return created product items for batch card based on ids of selected items
   * @param selectedProductItemIds - ids of selected product items
   * @param createdProductItems - created product items
   */
  returnSelectedProductItems = (
    selectedProductItemIds: Array<string>,
    createdProductItems: Array<UpdateProductItemInput>
  ): Array<UpdateProductItemInput> => {
    return createdProductItems.filter(createdProduct =>
      selectedProductItemIds.find(itemId => itemId === createdProduct.id)
    );
  };

  render() {
    const {
      modal,
      searchFilter,
      selectedProductItems,
      allSelected,
      importModal,
      batchCardModal,
      tagsModal,
      editModal,
      previousTagDescriptions,
      product
    } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <BackendWrapper>
              <Query
                query={getCompanyProductItemsByUser}
                variables={{ id: userId }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (error) {
                    return (
                      <ErrorMessage errorMessage="There was a problem loading your company brands" />
                    );
                  }

                  if (!data || !data.getUser) {
                    return (
                      <ErrorMessage errorMessage="There was a problem loading your company data" />
                    );
                  }

                  const company = data.getUser.company;
                  const companyId = data.getUser.company.id;
                  const itemTemplate = data.getUser.company.itemTemplate;
                  const brands = data.getUser.company.brands.items;
                  const services = data.getUser.company.services.items;
                  const spaces = data.getUser.company.spaces.items;

                  const createdProductItems = returnProductItemsNotAssociatedWithBatchCard(
                    company.productItems.items
                  );
                  const selectedItemTemplate = returnValidProductItem(
                    itemTemplate
                  );
                  const selectedItemsHeaders = PRODUCT_ITEMS_CSV_HEADINGS.filter(
                    item => {
                      if (item.key === 'subBrand.name') {
                        return selectedItemTemplate.hasOwnProperty('subBrand');
                      }
                      if (item.key === 'brand.name') {
                        return selectedItemTemplate.hasOwnProperty('brand');
                      }
                      return selectedItemTemplate.hasOwnProperty(item.key);
                    }
                  );

                  return (
                    <div>
                      <GlobalModalContainer
                        toggleModal={this.closeModal}
                        title="Create Items"
                        modalDisplay={
                          <AddProductModal
                            companyId={companyId}
                            userId={userId}
                            itemTemplate={itemTemplate}
                            brands={brands}
                            closeModal={this.closeModal}
                            notification={this.toastNotification}
                          />
                        }
                        modal={modal}
                      />
                      <GlobalModalContainer
                        toggleModal={this.closeEditModal}
                        title="Update Product"
                        modalDisplay={
                          <EditProductModal
                            companyId={companyId}
                            userId={userId}
                            product={product}
                            itemTemplate={itemTemplate}
                            brands={brands}
                            closeModal={this.closeEditModal}
                            notification={this.toastNotification}
                          />
                        }
                        modal={editModal}
                      />
                      <GlobalModalContainer
                        toggleModal={() =>
                          this.setState({
                            tagsModal: false,
                            previousTagDescriptions: []
                          })
                        }
                        title="Product Tags"
                        modalDisplay={
                          <PreviousTags
                            previousTags={previousTagDescriptions}
                            noMargin={true}
                          />
                        }
                        modal={tagsModal}
                      />
                      <GlobalModalContainer
                        toggleModal={this.closeImportModal}
                        title="Import Items"
                        modalDisplay={
                          <DataImport
                            companyId={companyId}
                            itemTemplate={itemTemplate}
                            brands={brands}
                            closeModal={this.closeImportModal}
                            notification={this.toastNotification}
                          />
                        }
                        modal={importModal}
                      />
                      <GlobalModalContainer
                        largeModal={true}
                        toggleModal={this.closeBatchCardModal}
                        title="Create Batch Card"
                        modalDisplay={
                          <CreateNewBatchCard
                            userId={userId}
                            companyId={companyId}
                            itemTemplate={itemTemplate}
                            services={services}
                            spaces={spaces}
                            selectedProductItems={this.returnSelectedProductItems(
                              selectedProductItems,
                              createdProductItems
                            )}
                            closeModal={this.closeBatchCardModal}
                            notification={this.toastNotification}
                          />
                        }
                        modal={batchCardModal}
                      />
                      <TableHeaderContainer>
                        <TableHeader>
                          <span>New Items</span>
                        </TableHeader>
                        <StyledButton
                          type="button"
                          label="Add Item"
                          onClick={this.openModal}
                          width="120px"
                          color={Colors.flumeDarkGreen}
                          background={Colors.flumeGreen}
                        />
                        <StyledButton
                          type="button"
                          label="Import Items"
                          width="120px"
                          onClick={this.openImportModal}
                          color={Colors.flumeGreen}
                          background={Colors.grey}
                        />
                        <StyledButton
                          type="button"
                          label="Create Batch Card"
                          width="150px"
                          onClick={this.openBatchCardModal}
                          color={Colors.snow}
                          background={Colors.flumeDarkGreen}
                        />
                        <CSVLink
                          data={checkForNullBrandAndSubBrandCsvData(
                            createdProductItems
                          )}
                          headers={selectedItemsHeaders}
                          target="_blank"
                          filename="Items.csv"
                        >
                          <StyledButton
                            type="button"
                            label={
                              <MdFileDownload
                                size="1.3em"
                                color={Colors.flumeDarkGreen}
                              />
                            }
                            width="auto"
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                        </CSVLink>
                        <StyledButton
                          type="button"
                          label={
                            <MdSearch
                              size="1.3em"
                              color={Colors.flumeDarkGreen}
                            />
                          }
                          width="auto"
                          onClick={this.toggleSearchFilter}
                          color={Colors.flumeDarkGreen}
                          background={Colors.flumeGreen}
                        />
                      </TableHeaderContainer>
                      <Table
                        data={sortArrayAlphabetically(createdProductItems)}
                        columns={[
                          {
                            id: 'select-all',
                            Header: () => (
                              <Checkbox
                                onClick={() =>
                                  this.toggleSelectAll(createdProductItems)
                                }
                              >
                                {allSelected ? (
                                  <MdDone size="0.95em" color={Colors.grey} />
                                ) : null}
                              </Checkbox>
                            ),
                            accessor: (productItem: UpdateProductItemInput) => {
                              const thisProductItem = selectedProductItems.find(
                                cProductItemId =>
                                  cProductItemId === productItem.id
                              );

                              return (
                                <CircleIcon
                                  selected={thisProductItem || false}
                                  onClick={() =>
                                    this.selectProductItem(productItem.id)
                                  }
                                />
                              );
                            },
                            sortable: false,
                            filterable: false,
                            width: 60
                          },
                          ...returnNewProductItemColumns(
                            itemTemplate,
                            searchFilter
                          ),
                          {
                            id: 'row-actions',
                            Header: '',
                            accessor: (productItem: UpdateProductItemInput) => {
                              return (
                                <RowActions
                                  openModal={this.openEditModal}
                                  openTagsModal={this.openTagsModal}
                                  previousTagDescriptions={checkIfArrayOfStrings(
                                    productItem.previousTagDescriptions
                                  )}
                                  productItem={productItem}
                                  notification={this.toastNotification}
                                />
                              );
                            },
                            sortable: false,
                            filterable: false,
                            width: 30
                          }
                        ]}
                        defaultPageSize={50}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                      />
                    </div>
                  );
                }}
              </Query>
              <Helmet title="New Items" />
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(NewProductItems);
