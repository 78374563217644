import * as React from 'react';
import { FiLayers } from 'react-icons/fi';

/** Presentation/UI */
import PlaceholderWrapper from '../../Components/Layouts/PlaceholderWrapper';

/** Themes */
import { Colors } from '../../Themes';

type Props = {
  path?: string;
  buttonLink: boolean;
  buttonLabel: string;
  buttonAction?: () => void;
  title: string;
  text: string;
};

const Placeholder: React.FC<Props> = props => {
  return (
    <PlaceholderWrapper
      icon={<FiLayers size="8.5em" color={Colors.flumeGreen} />}
      title={props.title}
      text={props.text}
      buttonLabel={props.buttonLabel}
      path={props.path}
      buttonAction={props.buttonAction}
      buttonLink={true}
    />
  );
};

export default Placeholder;
