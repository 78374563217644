import * as React from 'react';

/** Presentation/UI */
import GlobalModalContainer from '../../Components/Modal';

/** Local components */
import TermsAndConditionsContent from './TermsAndConditionsContent';

type Props = {
  modal: boolean;
  closeModal(): void;
};

const TermsAndConditions: React.FC<Props> = props => {
  return (
    <GlobalModalContainer
      toggleModal={props.closeModal}
      title="Terms &amp; Conditions"
      modalDisplay={<TermsAndConditionsContent />}
      modal={props.modal}
    />
  );
};

export default TermsAndConditions;
