import * as React from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Helmet } from 'react-helmet';
import { MdVerifiedUser, MdSearch } from 'react-icons/md';
import { withToastManager } from 'react-toast-notifications';
import { Form } from 'reactstrap';
import * as uuid from 'uuid';
import { Redirect } from 'react-router';

/** GraphQL */
import { getCompanyRolesByUser } from '../../graphql/custom-queries';
import { deleteRole } from '../../graphql/mutations';

import {
  UpdateRoleInput,
  DeleteRoleMutation,
  DeleteRoleMutationVariables
} from '../../API';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Presentation/UI */
import PlaceholderWrapper from '../../Components/Layouts/PlaceholderWrapper';
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import GlobalModalContainer from '../../Components/Modal';
import DialogModal from '../../Components/DialogModal';
import Table from '../../Components/Table';
import PageLoader from '../../Components/PageLoader';
import StyledButton from '../../Components/Styled/Button';
import {
  TableHeader,
  TableHeaderContainer
} from '../../Components/Styled/ListViewElements';

/** Local components */
import AddRoleModal from './AddRoleModal';
import EditRoleModal from './EditRoleModal';
import RoleActions from './RoleActions';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { ROLES_DESCRIPTION } from '../../Utils/Consts';
import {
  returnUserRole,
  convertPixelsToRem,
  sortArrayAlphabetically
} from '../../Utils/Helpers';

/** Custom Types */
import { ToastNotificationType } from '../../CustomTypes';

type RoleType = UpdateRoleInput & {
  company?: {
    id?: string;
  };
};

type Props = {
  toastManager: ToastNotificationType;
  mutate: any;
};

type State = {
  roleId: string;
  modal: boolean;
  editModal: boolean;
  dialogModal: boolean;
  searchFilter: boolean;
  role: RoleType;
};

class Roles extends React.Component<Props, State> {
  state: State = {
    roleId: '',
    modal: false,
    editModal: false,
    dialogModal: false,
    searchFilter: false,
    role: { id: '' }
  };

  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  // Close dialog modal
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false
    });
  };

  // Open dialog modal
  openDialogModal = (role: RoleType): void => {
    const roleId = !role ? '' : role.id;
    this.setState({
      dialogModal: true,
      role,
      roleId
    });
  };

  // Close modal
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  // Close edit modal
  closeEditModal = (): void => {
    this.setState({
      editModal: false,
      role: { id: '' }
    });
  };

  // Open modal
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  // Open edit modal
  openEditModal = (role: UpdateRoleInput): void => {
    this.setState({
      editModal: true,
      role
    });
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const {
      modal,
      editModal,
      dialogModal,
      role,
      roleId,
      searchFilter
    } = this.state;
    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <BackendWrapper>
              <Query query={getCompanyRolesByUser} variables={{ id: userId }}>
                {({ loading, error, data }) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (error) {
                    return (
                      <div>There was a problem loading your company data</div>
                    );
                  }

                  if (!data || !data.getUser) {
                    return (
                      <div>There was a problem loading your company data</div>
                    );
                  }

                  const company = data.getUser.company;
                  if (!company) {
                    return <Redirect to="/company-setup" />;
                  }

                  const companyId = data.getUser.company.id;

                  if (
                    company.roles &&
                    company.roles.items &&
                    company.roles.items.length
                  ) {
                    const createdCompanyRoles = company.roles.items;

                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeModal}
                          title="Create Roles"
                          modalDisplay={
                            <AddRoleModal
                              companyId={companyId}
                              closeModal={this.closeModal}
                              notification={this.toastNotification}
                              userId={userId}
                            />
                          }
                          modal={modal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeEditModal}
                          title="Edit Role"
                          modalDisplay={
                            <EditRoleModal
                              closeModal={this.closeEditModal}
                              notification={this.toastNotification}
                              companyId={companyId}
                              role={role}
                            />
                          }
                          modal={editModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeDialogModal}
                          title=""
                          modalDisplay={
                            <Mutation<
                              DeleteRoleMutation,
                              DeleteRoleMutationVariables
                            >
                              mutation={gql(deleteRole)}
                            >
                              {(deleteRoleMutation, deleteMutation) => (
                                <Form
                                  onSubmit={e => {
                                    e.preventDefault();
                                    deleteRoleMutation({
                                      variables: {
                                        input: {
                                          id: roleId
                                        }
                                      },
                                      refetchQueries: [
                                        {
                                          query: getCompanyRolesByUser,
                                          variables: {
                                            id: userId
                                          }
                                        }
                                      ]
                                    })
                                      .then(res => {
                                        this.closeDialogModal();
                                        this.toastNotification(
                                          'Role delete successfully',
                                          'success'
                                        );
                                      })
                                      .catch(err => {
                                        this.toastNotification(
                                          'Sorry, there was a problem deleting the role',
                                          'error'
                                        );
                                      });
                                  }}
                                >
                                  <DialogModal
                                    loading={deleteMutation.loading}
                                    title="Are you sure you want to delete this role?"
                                    toggleModal={this.closeDialogModal}
                                  />
                                </Form>
                              )}
                            </Mutation>
                          }
                          modal={dialogModal}
                        />
                        <TableHeaderContainer>
                          <TableHeader>
                            <span>Manage Roles</span>
                          </TableHeader>
                          <StyledButton
                            type="button"
                            label="Add Role"
                            width="120px"
                            onClick={this.openModal}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                          <StyledButton
                            type="button"
                            label={
                              <MdSearch
                                size="1.3em"
                                color={Colors.flumeDarkGreen}
                              />
                            }
                            width="auto"
                            onClick={this.toggleSearchFilter}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                        </TableHeaderContainer>
                        <Table
                          data={sortArrayAlphabetically(createdCompanyRoles)}
                          columns={[
                            {
                              Header: 'Role Name',
                              accessor: 'name',
                              sortable: false,
                              filterable: searchFilter,
                              Filter: ({ filter, onChange }) => (
                                <div className="searchContainer">
                                  <MdSearch
                                    size={convertPixelsToRem(18)}
                                    className="searchIcon"
                                  />
                                  <input
                                    type="text"
                                    className="searchBox"
                                    placeholder="Search for a role name"
                                    value={filter ? filter.value : ''}
                                    onChange={event =>
                                      onChange(event.target.value)
                                    }
                                    style={{
                                      width: '100%'
                                    }}
                                  />
                                </div>
                              )
                            },
                            {
                              id: uuid(),
                              Header: 'Permission',
                              accessor: (userRole: any) => {
                                return returnUserRole(userRole.permission);
                              },
                              sortable: false,
                              filterable: searchFilter,
                              Filter: ({ filter, onChange }) => (
                                <div className="searchContainer">
                                  <MdSearch
                                    size={convertPixelsToRem(18)}
                                    className="searchIcon"
                                  />
                                  <input
                                    type="text"
                                    className="searchBox"
                                    placeholder="Search for a permission"
                                    value={filter ? filter.value : ''}
                                    onChange={event =>
                                      onChange(event.target.value)
                                    }
                                    style={{
                                      width: '100%'
                                    }}
                                  />
                                </div>
                              )
                            },
                            {
                              id: uuid(),
                              Header: '',
                              accessor: (userRole: UpdateRoleInput) => {
                                return (
                                  <RoleActions
                                    openModal={this.openEditModal}
                                    openDialogModal={this.openDialogModal}
                                    role={userRole}
                                  />
                                );
                              },
                              sortable: false,
                              filterable: false,
                              width: 30
                            }
                          ]}
                          defaultPageSize={5}
                          showPaginationTop={false}
                          showPaginationBottom={true}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeModal}
                          title="Create Roles"
                          modalDisplay={
                            <AddRoleModal
                              companyId={companyId}
                              closeModal={this.closeModal}
                              notification={this.toastNotification}
                              userId={userId}
                            />
                          }
                          modal={modal}
                        />
                        <PlaceholderWrapper
                          icon={
                            <MdVerifiedUser
                              size="8.5em"
                              color={Colors.flumeGreen}
                            />
                          }
                          title="Create Roles and Assign Permissions"
                          text={ROLES_DESCRIPTION}
                          buttonLabel="Add Role"
                          buttonAction={this.openModal}
                        />
                      </div>
                    );
                  }
                }}
              </Query>
              <Helmet title="Manage Roles" />
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(Roles);
