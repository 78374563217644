import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

type Props = {
  title: string;
  modalDisplay: any;
  largeModal?: boolean;
  mediumModal?: boolean;
  modal: boolean;
  toggleModal(): void;
};

const GlobalModalContainer: React.FC<Props> = props => {
  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggleModal}
        className={
          props.largeModal
            ? 'large-modal'
            : props.mediumModal
            ? 'medium-modal'
            : ''
        }
      >
        <ModalHeader toggle={props.toggleModal}>{props.title}</ModalHeader>
        <ModalBody>{props.modalDisplay}</ModalBody>
      </Modal>
    </div>
  );
};

function GlobalModal(props: Props) {
  return ReactDOM.createPortal(
    <GlobalModalContainer {...props} />,
    (document.getElementById('modal') as HTMLElement) ||
      document.createElement('div') // for testing purposes
  );
}

export default GlobalModal;
