import * as React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

type Props = {
  productItemId: string;
  previousTagDescriptions: Array<string>;
  productItemImageKeys: Array<string>;
  openModal?: (
    productItemId: string,
    bulkAction?: boolean,
    previousTagDescriptions?: Array<string>
  ) => void;
  openImageModal?: (
    productItemImageKeys: Array<string>,
    productItemId: string
  ) => void;
  openNoteModal?: (productItemId: string, bulkAction?: boolean) => void;
  openAddTextModal(
    productItemId: string,
    bulkAction?: boolean,
    productItemImageKeys?: Array<string>
  ): void;
  openQCApproveModal?: (
    productItemId: string,
    bultAction?: boolean,
    qcApproval?: boolean
  ) => void;
  qcForm?: boolean;
};

const RowActions: React.FC<Props> = ({
  openModal,
  openImageModal,
  openNoteModal,
  openAddTextModal,
  productItemId,
  productItemImageKeys,
  previousTagDescriptions,
  qcForm,
  openQCApproveModal
}) => {
  const [dropdownOpen, toggleDropdown] = React.useState(false);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => toggleDropdown(!dropdownOpen)}
      style={{ position: 'absolute' }}
    >
      <DropdownToggle
        tag="span"
        onClick={() => toggleDropdown(!dropdownOpen)}
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
      </DropdownToggle>
      <DropdownMenu>
        {openNoteModal && (
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openNoteModal(productItemId)}
          >
            Add Note
          </div>
        )}
        {openImageModal && (
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openImageModal(productItemImageKeys, productItemId)}
          >
            Add Image
          </div>
        )}
        <div
          className="avatar-dropdown-menu-item"
          onClick={() =>
            openAddTextModal(productItemId, false, productItemImageKeys || [])
          }
        >
          {qcForm ? 'View Images and Text' : 'Add text'}
        </div>
        {openQCApproveModal && (
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openQCApproveModal(productItemId, false, true)}
          >
            QC Approve
          </div>
        )}
        {openModal && (
          <div
            className="avatar-dropdown-menu-item"
            onClick={() =>
              openModal(productItemId, false, previousTagDescriptions)
            }
          >
            {qcForm ? 'QC Reject' : 'Add Tag'}
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default RowActions;
