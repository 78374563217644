import * as React from 'react';
import * as validator from 'validator';
import { Auth } from 'aws-amplify';

/** Handle authentication
 * @param email - user's auth email
 * @param password - user's auth password
 */
export const signIn = (
  email: string,
  password: string,
  history: History & {
    push(path: string, state?: any): void;
  },
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: (error: string | null) => void,
  updateUserId: (jwtToken: string) => void
) => {
  setLoading(true);

  Auth.signIn(email, password)
    .then(user => {
      // Authenticated user
      if (user.signInUserSession) {
        // Authorised user token
        updateUserId(user.signInUserSession.accessToken.jwtToken);
        history.push('/admin/dashboard');
      }
    })
    .catch(err => {
      setError(err.message);
      setLoading(false);
    });
};

/** Handle form validation for the login form
 * @param email - user's auth email
 * @param password - user's auth password
 */
export const validateForm = (
  email: string,
  password: string,
  setError: (error: string | null) => void
): boolean => {
  // Check for undefined or empty input fields
  if (!email || !password) {
    setError('Please enter a valid email and password.');
    return false;
  }

  // Validate email
  if (!validator.isEmail(email)) {
    setError('Please enter a valid email address.');
    return false;
  }

  return true;
};
