import * as React from 'react';
import { Query } from 'react-apollo';

/** GraphQL */
import { getCompanyBatchCardAllocationsForTheMonthByUser } from '../../graphql/custom-queries';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { returnNumberOfBatchCardsForTheMonthBasedOnStatus } from './DashboardHelpers';

const BatchCardsMonthProgress: React.FC<{
  month: string;
  userId: string;
  batchCardStatus: 'complete' | 'incomplete';
}> = ({ batchCardStatus, month, userId }) => {
  return (
    <Query
      query={getCompanyBatchCardAllocationsForTheMonthByUser}
      variables={{
        id: userId,
        month
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loader color={Colors.flumeGreen} />;
        }
        if (error) {
          return <ErrorMessage errorMessage="Error" />;
        }

        if (!data || !data.getUser) {
          return <ErrorMessage errorMessage="Error" />;
        }

        const company = data.getUser.company;

        if (
          company.batchCards &&
          company.batchCards.items &&
          company.batchCards.items.length
        ) {
          const batchCards = company.batchCards.items;

          const batchCardsForSelectedStatus = returnNumberOfBatchCardsForTheMonthBasedOnStatus(
            batchCards,
            month,
            batchCardStatus
          );

          return <span>{batchCardsForSelectedStatus}</span>;
        }

        return <span>0</span>;
      }}
    </Query>
  );
};

export default BatchCardsMonthProgress;
