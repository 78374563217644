import * as React from 'react';
import { Row, Col } from 'reactstrap';

/** Presentation/UI */
import {
  PreviousTagContainer,
  TagContainer,
  Tag,
  SubHeading
} from '../../Components/Styled/PreviousTags';

const PreviousTags: React.FC<{
  previousTags: Array<string>;
  subHeading?: boolean;
  noMargin?: boolean;
  height?: number;
}> = ({ previousTags, subHeading, noMargin, height }) => {
  /** Return previous notes
   * @param previousNotes - previous notes by users
   */
  const displayPreviousTags = (
    previousTags: Array<string>
  ): React.ReactNode => {
    if (previousTags && previousTags.length) {
      return previousTags.map((previousTag: string, i: number) => {
        return (
          <Row key={i}>
            <Col xs={12} md={12} lg={12}>
              <TagContainer>
                <Tag>{previousTag}</Tag>
              </TagContainer>
            </Col>
          </Row>
        );
      });
    }
    return (
      <Row>
        <Col xs={12} md={12} lg={12}>
          <span>No tags have been added to this product.</span>
        </Col>
      </Row>
    );
  };

  return (
    <PreviousTagContainer noMargin={noMargin} height={height}>
      {subHeading && (
        <Row>
          <Col xs={12} md={12} lg={12}>
            <SubHeading>Previously added tags</SubHeading>
          </Col>
        </Row>
      )}
      {displayPreviousTags(previousTags)}
    </PreviousTagContainer>
  );
};

export default PreviousTags;
