import styled from 'styled-components';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { convertPixelsToRem } from '../../Utils/Helpers';

export const PlaceholderContainer = styled.div`
  text-align: center;
  margin: ${convertPixelsToRem(50)};
  height: 100vh;
  & button {
    width: 100px;
    margin: 0 auto;
    padding: 6px 10px;
  }
`;

export const PlaceholderIconContainer = styled.div`
  height: ${convertPixelsToRem(200)};
  width: ${convertPixelsToRem(200)};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${Colors.lightBlueGrey};
`;

export const PlaceholderHeading = styled.h2`
  color: ${Colors.grey};
  text-align: center;
  font-size: ${convertPixelsToRem(20)};
`;

export const PlaceholderText = styled.p`
  color: ${Colors.grey};
  width: ${convertPixelsToRem(400)};
  margin: 0 auto;
`;
