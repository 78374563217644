import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, Label, FormGroup, Input } from 'reactstrap';

/** GraphQL */
import { updateBrand } from '../../graphql/mutations';
import { getCompanyBrandsAndSubBrandsByUser } from '../../graphql/custom-queries';
import {
  UpdateBrandInput,
  UpdateBrandMutation,
  UpdateBrandMutationVariables
} from '../../API';

/** Custom Types */
import { Error } from '../../CustomTypes';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Themes */
import { Colors } from '../../Themes';

type Props = {
  userId: string;
  brand: UpdateBrandInput;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  id: string;
  name: string;
  error: Error;
};

class EditBrandModal extends React.Component<Props, State> {
  timeoutId: number = 0;
  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.brand.id || '',
      name: props.brand.name || '',
      error: null
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Validation */
  validateForm = (): boolean => {
    const { name } = this.state;
    // Check for undefined or empty input fields
    if (!name) {
      this.setError('Please add a name.');
      return false;
    }

    return true;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { id, name, error } = this.state;
    const { notification, closeModal, userId } = this.props;

    return (
      <Mutation<UpdateBrandMutation, UpdateBrandMutationVariables>
        mutation={gql(updateBrand)}
      >
        {(updateBrandMutation, { loading }) => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateForm()) {
                updateBrandMutation({
                  variables: {
                    input: {
                      id,
                      name
                    }
                  },
                  refetchQueries: [
                    {
                      query: getCompanyBrandsAndSubBrandsByUser,
                      variables: {
                        id: userId
                      }
                    }
                  ]
                })
                  .then(res => {
                    closeModal();
                    notification('Brand updated successfully');
                  })
                  .catch(err => {
                    this.setError(err.message);
                  });
              }
            }}
          >
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="name">Brand Name</Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    id="name"
                    placeholder="Brand name"
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <StyledButton
                    type="submit"
                    label={!loading ? 'Update' : <Loader />}
                    color={Colors.flumeDarkGreen}
                    background={Colors.flumeGreen}
                  />
                </FormGroup>
              </Col>
            </Row>
            {error && <ErrorMessage errorMessage={error} />}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default EditBrandModal;
