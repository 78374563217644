import * as React from 'react';
import { Moment } from 'moment';
import { Col, Row } from 'reactstrap';
import { Query } from 'react-apollo';
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

/** Presentational components */
import CardWithTitleAndContent from '../../Components/Styled/CardWithTitleAndContent';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import Loader from '../../Components/Loader';

/** App Theme */
import { Colors } from '../../Themes';

/** Custom types */
import { ChartData } from '../../CustomTypes';

/** Helper methods */
import {
  returnItemsFilteredUsingMonth,
  returnItemsFilteredUsingWeek,
  returnItemsFilteredUsingYear
} from '../../Utils/Helpers';

type Props = {
  query: any;
  variables: object;
  filter: string;
  title: string;
  itemsType?: string;
  filterPeriod: Moment;
  formattedDates: Array<string>;
  serviceName: string;
  updateDataCallback?: (data: ChartData) => void;
};

const ServicesChart: React.FC<Props> = props => {
  const {
    query,
    filter,
    variables,
    formattedDates,
    filterPeriod,
    itemsType,
    title,
    serviceName,
    updateDataCallback
  } = props;

  return (
    <Query
      query={query}
      variables={variables}
      notifyOnNetworkStatusChange={true}
    >
      {({ loading, error, data }) => {
        let chartData: ChartData = [];
        let items: any = [];

        if (loading) {
          return (
            <FullWidthContainer align="center">
              <CardWithTitleAndContent
                id="card-container"
                title={title}
                height="42.5vh"
              >
                <Loader size={150} color={Colors.flumeGreen} />
              </CardWithTitleAndContent>
            </FullWidthContainer>
          );
        }

        if (error || !data || !data.getUser) {
          return (
            <ErrorMessage
              errorMessage=" There was a problem loading your user
                      data"
            />
          );
        }

        if (data.getUser && data.getUser.company) {
          const pItems = data.getUser.company.productItems.items;

          for (let i = 0; i < Object.keys(pItems).length; i++) {
            if (pItems[i].productItemServices) {
              /* eslint-disable */
              pItems[i].productItemServices.map(service => {
                if (service === serviceName) {
                  items.push(pItems[i]);
                }
              });
            }
          }
        }

        if (filter === 'week') {
          chartData = returnItemsFilteredUsingWeek(
            items,
            formattedDates,
            itemsType
          );
        }

        if (filter === 'month' && items) {
          chartData = returnItemsFilteredUsingMonth(items, filterPeriod);
        }

        if (filter === 'year' && items) {
          chartData = returnItemsFilteredUsingYear(items, itemsType);
        }

        if (updateDataCallback) {
          updateDataCallback(chartData);
        }

        const containerDiv = document.getElementById('card-container');
        let elementWidth = 0;
        let elementHeight = 0;

        if (
          containerDiv &&
          containerDiv.clientHeight &&
          containerDiv.clientWidth
        ) {
          elementHeight = containerDiv.clientHeight;
          elementWidth = containerDiv.clientWidth;
        }
        const chartHeight = elementHeight && elementHeight - 100;
        const chartWidth = elementWidth && elementWidth - 100;

        return (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <CardWithTitleAndContent
                id="card-container"
                title={title}
                height="42.5vh"
              >
                <LineChart
                  height={chartHeight}
                  width={chartWidth}
                  data={chartData}
                >
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke={Colors.flumeDarkGreen}
                  />
                  <CartesianGrid
                    vertical={false}
                    stroke={Colors.lightBlueGrey}
                  />
                  <XAxis tickLine={false} dataKey="name" />
                  <YAxis tickLine={false} />
                </LineChart>
              </CardWithTitleAndContent>
            </Col>
          </Row>
        );
      }}
    </Query>
  );
};

export default ServicesChart;
