/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Form } from 'reactstrap';
import { MdCancel } from 'react-icons/md';
import { Storage } from 'aws-amplify';

/** GraphQL */
import { updateBatchCard } from '../../graphql/mutations';
import { getCompanyBatchCardsByUser } from '../../graphql/custom-queries';
import {
  UpdateBatchCardMutation,
  UpdateBatchCardMutationVariables
} from '../../API';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Presentation/UI */
import GlobalModalContainer from '../../Components/Modal';
import DialogModal from '../../Components/DialogModal';
import {
  ImageContainer,
  ImagesWrapper,
  ImageWrapper
} from '../../Components/Styled/ImageContainer';

/** Custom Types */
import { BatchCard } from '../../CustomTypes';

/** Utils */
import { returnImageFromStorage } from '../../Utils/Helpers';

type Image = { key: string; url: string };

type Props = {
  batchCard: BatchCard;
  notification(message: string, appearance?: string | undefined): void;
};

type State = {
  id: string;
  batchCardImages: Array<Image>;
  dialogModal: boolean;
  imageUrl: string;
  imageKey: string;
};

class BatchCardImages extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.batchCard.id,
      batchCardImages: [],
      dialogModal: false,
      imageUrl: '',
      imageKey: ''
    };
  }

  async componentDidMount() {
    const { batchCard } = this.props;
    if (batchCard) {
      const imageKeys = batchCard.images;
      if (imageKeys && imageKeys.length) {
        const batchCardImages = await Promise.all(
          // @ts-ignore
          imageKeys.map(async (imageKey: string) => {
            const img = await returnImageFromStorage(imageKey);
            const imgUrl = typeof img === 'string' ? img : '';
            return {
              key: imageKey,
              url: imgUrl
            };
          })
        );

        // @ts-ignore
        this.setState({ batchCardImages });
      }
    }
  }

  /** Close dialog modal */
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false
    });
  };

  /** Open dialog modal
   * @param imageKey - key used to get access to the url for image uploaded to S3
   */
  openDialogModal = (imageKey: string): void => {
    this.setState({
      dialogModal: true,
      imageKey
    });
  };

  /** Return/display batch card images
   * @param batchCardImages - array of details for each image related to a batch card
   */
  displayBatchCardImages = (batchCardImages: Array<Image>): React.ReactNode => {
    if (batchCardImages && batchCardImages.length) {
      return batchCardImages.map((batchCardImage: Image) => {
        return (
          <ImageWrapper key={batchCardImage.key}>
            <MdCancel
              style={{ cursor: 'pointer' }}
              size="1.3em"
              onClick={() => this.openDialogModal(batchCardImage.key)}
            />
            <ImageContainer small={true} imageUrl={batchCardImage.url} />
          </ImageWrapper>
        );
      });
    }
    return <span>No images have been uploaded</span>;
  };

  /** Delete image from S3 bucket and from batch card
   * @param upateBatchCardMutation - graphql mutation to update a batch card
   */
  deleteImageFromBatchCard = async (
    userId: string,
    upateBatchCardMutation: ({}) => Promise<any>
  ) => {
    const { id, imageKey, batchCardImages } = this.state;
    const { notification } = this.props;

    // Get all current image keys
    const currentImageKeys = batchCardImages.map((batchCardImage: Image) => {
      return batchCardImage.key;
    });

    try {
      // Delete the image from S3 bucket
      await Storage.remove(imageKey).catch(err =>
        notification(err.message, 'error')
      );

      /**
       * Filter out the key of deleted image and
       * update the image keys for this batch card
       */
      const images = currentImageKeys.filter((key: string) => key !== imageKey);

      // Update batch card
      upateBatchCardMutation({
        variables: {
          input: {
            id,
            images
          }
        },
        refetchQueries: [
          {
            query: getCompanyBatchCardsByUser,
            variables: {
              id: userId
            }
          }
        ]
      })
        .then(() => {
          notification('Image deleted successfully');
          this.closeDialogModal();
        })
        .catch(err => {
          notification(err.message, 'error');
          this.closeDialogModal();
        });
    } catch (e) {
      notification(e.message, 'error');
      this.closeDialogModal();
    }
  };

  render() {
    const { batchCardImages, dialogModal } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <div>
              <GlobalModalContainer
                toggleModal={this.closeDialogModal}
                title=""
                modalDisplay={
                  <Mutation<
                    UpdateBatchCardMutation,
                    UpdateBatchCardMutationVariables
                  >
                    mutation={gql(updateBatchCard)}
                  >
                    {(updateBatchCardMutation, updateMutation) => (
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          this.deleteImageFromBatchCard(
                            userId,
                            updateBatchCardMutation
                          );
                        }}
                      >
                        <DialogModal
                          loading={updateMutation.loading}
                          title="Are you sure you want to delete this image?"
                          toggleModal={this.closeDialogModal}
                        />
                      </Form>
                    )}
                  </Mutation>
                }
                modal={dialogModal}
              />
              <ImagesWrapper>
                {this.displayBatchCardImages(batchCardImages)}
              </ImagesWrapper>
            </div>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default BatchCardImages;
