import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Form } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

/** GraphQL */
import { updateCompany } from '../../graphql/mutations';

/** Custom Hooks */
import useErrorHandler from '../../CustomHooks/ErrorHandler';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import DialogModal from '../../Components/DialogModal';

/** Generated types */
import {
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
} from '../../API';

/** Utils */
import { cancelSubscriptionAndDisableAccount } from './Utils/Helpers';

type Props = RouteComponentProps & {
  companyId: string;
  subscriptionId: string;
  closeDialogModal: React.Dispatch<React.SetStateAction<boolean>>;
  history: History;
};

const CancelSubscriptionPlanModal: React.FC<Props> = ({
  companyId,
  closeDialogModal,
  history,
  subscriptionId
}) => {
  const [loading, setLoading] = React.useState(false);
  const { error, showError } = useErrorHandler(null);

  const signOutUser = (
    history: History & {
      push(path: string, state?: any): void;
    }
  ) => {
    window.localStorage.clear();
    history.push('/subscription-cancelled');
  };

  return (
    <Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>
      mutation={gql(updateCompany)}
    >
      {updateCompanyMutation => (
        <Form
          onSubmit={async e => {
            e.preventDefault();
            await cancelSubscriptionAndDisableAccount(
              companyId,
              subscriptionId,
              updateCompanyMutation,
              setLoading,
              showError
            );
            signOutUser(history);
          }}
        >
          <DialogModal
            loading={loading}
            title={`Cancelling your subscription plan will disable your 
          company's Flume account. Are you sure you want to proceed with the cancellation ?`}
            toggleModal={() => closeDialogModal(false)}
          />
          {error && <ErrorMessage errorMessage={error} />}
        </Form>
      )}
    </Mutation>
  );
};

export default withRouter(CancelSubscriptionPlanModal);
