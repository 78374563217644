import * as React from 'react';
import { FiLayers } from 'react-icons/fi';

/** Presentation/UI */
import {
  BatchCardContainer,
  BatchCardHeading,
  BatchCardSpaceIdentifier,
  DayContainer,
  DayHeader,
  DayWrapper
} from '../../Components/Styled/MainPlanner';
import { ServiceIconsContainer } from '../../Components/Styled/ListViewElements';

/** Utils */
import {
  returnAllocationsFromBatchCards,
  returnAllocationsWithAtLeastOneCompleteProduct,
  returnDayOfTheWeek,
  returnNumberOfBatchCardsOnDateInQC,
  returnNumberOfItemsOnDateInQC
} from '../MainPlanner/BatchCardAllocationHelpers';
import {
  returnQCSelectedServices,
  returnCompanyBatchCardsFromResponseData
} from '../MainPlanner/QCBatchCardHelpers';
import {
  returnDateInGivenWeek,
  returnFlattenedArray
} from '../../Utils/Helpers';
import { CALENDAR_WEEK } from '../../Utils/Consts';

/** Custom types */
import { BatchCard, SavedBatchCardAllocation } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

type Props = {
  currentWeek: Array<Date>;
  data: any;
  openBatchCard(batchCardId: string): void;
};

type State = {
  currentWeek: Array<Date>;
  batchCards: Array<BatchCard>;
};

class CalendarWeekView extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps && nextProps.data && nextProps.data.getUser) {
      const { data } = nextProps;
      return {
        batchCards: returnCompanyBatchCardsFromResponseData(data)
      };
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      currentWeek: props.currentWeek,
      batchCards: returnCompanyBatchCardsFromResponseData(props.data)
    };
  }

  /**
   * Return the week dates with the batch card allocations
   * @param batchCards
   * @param currentWeek
   */
  returnWeek = (currentWeek: Array<Date>, batchCards: Array<BatchCard>) => {
    const calendarWeekKeys = Object.keys(CALENDAR_WEEK).map(
      key => CALENDAR_WEEK[key]
    );

    return calendarWeekKeys.map((dayKey: string, i) => {
      return (
        <DayContainer key={dayKey}>
          <DayWrapper isDraggingOver={false}>
            <DayHeader>
              <span className="day-header">
                {returnDayOfTheWeek(parseInt(dayKey, 10))}
              </span>
              <br />
              <span className="date-header">
                {returnDateInGivenWeek(i, currentWeek)}
              </span>
            </DayHeader>
            <DayHeader>
              <div>
                {returnNumberOfBatchCardsOnDateInQC(
                  batchCards,
                  returnDateInGivenWeek(i, currentWeek, true)
                )}{' '}
                batch cards
              </div>
              <div>
                {returnNumberOfItemsOnDateInQC(
                  batchCards,
                  returnDateInGivenWeek(i, currentWeek, true)
                )}{' '}
                items
              </div>
            </DayHeader>
            {this.returnAllocationsFromBatchCardsOnADate(
              batchCards,
              returnDateInGivenWeek(i, currentWeek, true)
            )}
          </DayWrapper>
        </DayContainer>
      );
    });
  };

  /** Return batch card allocations on a particular date
   * @param batchCards
   * @param date
   */
  returnAllocationsFromBatchCardsOnADate = (
    batchCards: Array<BatchCard>,
    date: string
  ): React.ReactNode => {
    const { openBatchCard } = this.props;
    const batchCardAllocations = returnAllocationsFromBatchCards(batchCards);
    if (batchCardAllocations && batchCardAllocations.length) {
      const mergedAllocations = returnFlattenedArray(batchCardAllocations);

      const savedBatchCardAllocations = mergedAllocations.filter(
        (batchCardAllocation: SavedBatchCardAllocation) =>
          batchCardAllocation.date === date && !batchCardAllocation.obsolete
      );

      const allocationsWithAtLeastOneCompleteProduct = returnAllocationsWithAtLeastOneCompleteProduct(
        savedBatchCardAllocations
      );

      if (
        allocationsWithAtLeastOneCompleteProduct &&
        allocationsWithAtLeastOneCompleteProduct.length
      ) {
        return allocationsWithAtLeastOneCompleteProduct.map(
          (bcAllocation: SavedBatchCardAllocation, i: number) => {
            const numberOfProductItems =
              bcAllocation.batchCard.productItems.items.length;
            const completeOfProductItems = bcAllocation.batchCard.productItems.items.filter(
              productItem => productItem.complete === true
            ).length;

            return (
              <BatchCardContainer
                key={i}
                onClick={() => openBatchCard(bcAllocation.batchCard.id)}
              >
                <BatchCardHeading>
                  {bcAllocation.batchCard.name}
                </BatchCardHeading>
                <span>
                  {completeOfProductItems} / {numberOfProductItems}
                </span>
                {'   '}
                <FiLayers size="1em" color={Colors.lightGray} />
                <br />
                <br />
                <ServiceIconsContainer>
                  {returnQCSelectedServices(
                    bcAllocation.batchCardServices.items
                  )}
                </ServiceIconsContainer>
                <br />
                <BatchCardSpaceIdentifier
                  background={bcAllocation.space.colorCode}
                />
              </BatchCardContainer>
            );
          }
        );
      }

      return null;
    }

    return null;
  };

  render() {
    const { currentWeek, batchCards } = this.state;

    return <section>{this.returnWeek(currentWeek, batchCards)}</section>;
  }
}

export default CalendarWeekView;
