import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Query } from 'react-apollo';
import { CSVLink } from 'react-csv';
import { MdSearch, MdDone } from 'react-icons/md';
import { withToastManager } from 'react-toast-notifications';

/** GraphQL */
import { getCompanyCompletedProductItemsByUser } from '../../graphql/custom-queries';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Generated types */
import { UpdateProductItemInput } from '../../API';

/** Custom types */
import {
  SavedProductItem,
  BatchCardServiceType,
  ToastNotificationType
} from '../../CustomTypes';

/** Presentation/UI */
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import GlobalModalContainer from '../../Components/Modal';
import PageLoader from '../../Components/PageLoader';
import StyledButton from '../../Components/Styled/Button';
import Table from '../../Components/Table';
import {
  CircleIcon,
  Checkbox,
  SelectedIcon,
  TableHeader,
  TableHeaderContainer,
  ServiceIconsContainer
} from '../../Components/Styled/ListViewElements';
import PreviousTags from '../MainPlanner/PreviousTags';

/** Local components */
import AddTextToProductItem from '../MainPlanner/AddTextToProductItem';
import AddNoteToProductItem from '../MainPlanner/AddNoteToProductItem';
import AddImageToProductItem from '../MainPlanner/AddImageToProductItem';
import RowActions from './RowActions';
import ArchiveOrCompleteProductItems from '../CompleteProductItems/ArchiveOrCompleteProductItems';

/** Themes */
import { Colors, ServiceIcons } from '../../Themes';

/** Utils */
import {
  checkIfArrayOfStrings,
  returnNewProductItemColumns,
  returnProductItemKeysBasedOnCompanyTemplate
} from '../../Utils/Helpers';
import {
  returnPreviousNotes,
  returnProductItemsBasedOnIds,
  returnProductItemsBasedOnProductItemKeys,
  returnPreviousTextContent
} from '../MainPlanner/BatchCardAllocationHelpers';

type Props = {
  toastManager: ToastNotificationType;
};

type State = {
  addTextModal: boolean;
  batchCardId: string;
  bulkAction: boolean;
  dialogModal: boolean;
  imageModal: boolean;
  tagsModal: boolean;
  noteModal: boolean;
  allSelected: boolean;
  productItemId: string;
  previousTagDescriptions: Array<string>;
  productItemImageKeys: Array<string>;
  selectedProductItems: Array<string>;
  searchFilter: boolean;
};

class QualityControl extends React.Component<Props, State> {
  state = {
    addTextModal: false,
    allSelected: false,
    batchCardId: '',
    bulkAction: false,
    dialogModal: false,
    imageModal: false,
    noteModal: false,
    searchFilter: false,
    tagsModal: false,
    previousTagDescriptions: [],
    productItemId: '',
    productItemImageKeys: [],
    selectedProductItems: []
  };

  /** Open tags modal */
  openTagsModal = (previousTagDescriptions: Array<string> = []): void => {
    this.setState({
      tagsModal: true,
      previousTagDescriptions
    });
  };

  /** Close dialog modal for products */
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false
    });
  };

  /** Open dialog modal for products */
  openDialogModal = (): void => {
    this.setState({
      dialogModal: true
    });
  };

  /** Close image modal */
  closeImageModal = (): void => {
    this.setState({
      imageModal: false,
      productItemId: '',
      batchCardId: ''
    });
  };

  /** Open image modal
   * @param productItemImageKeys - image keys for product item
   * @param productItemId - id for a product item
   * @param batchCardId - batch card id
   */
  openImageModal = (
    productItemImageKeys: Array<string>,
    productItemId: string,
    batchCardId: string
  ): void => {
    this.setState({
      imageModal: true,
      productItemImageKeys,
      productItemId,
      batchCardId
    });
  };

  /** Close note modal */
  closeNoteModal = (): void => {
    this.setState({
      noteModal: false,
      productItemId: '',
      batchCardId: ''
    });
  };

  /** Open note modal
   * @param productItemId - id for a product item
   * @param batchCardId - batch card id
   */
  openNoteModal = (productItemId: string, batchCardId: string): void => {
    this.setState({
      noteModal: true,
      productItemId,
      batchCardId
    });
  };

  /** Open text modal
   * @param productItemId - id for a product item
   * @param bulkAction - whether its a bulk action
   */
  openAddTextModal = (
    productItemId?: string,
    bulkAction: boolean = false,
    productItemImageKeys?: Array<string>
  ): void => {
    this.setState({
      addTextModal: true,
      productItemId: productItemId || '',
      bulkAction,
      productItemImageKeys: productItemImageKeys || []
    });
  };

  /** Close text modal */
  closeAddTextModal = (): void => {
    this.setState({
      addTextModal: false,
      productItemId: ''
    });
  };

  /** Toggle main table search filter */
  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Select/deselect product items from table. A product id can only appear once in the array of selected items
   * @param productItemId - the selected product item's id
   */
  selectProductItem = (productItemId: string): void => {
    const { selectedProductItems } = this.state;
    // Check if the selected item exists in the array before adding it
    const newSelectedProductItems = selectedProductItems.find(
      cProductItemId => cProductItemId === productItemId
    )
      ? selectedProductItems.filter(
          sProductItemId => sProductItemId !== productItemId
        )
      : [...selectedProductItems, productItemId];
    this.setState({ selectedProductItems: newSelectedProductItems });
  };

  /** Toggle selection of all product items
   * @param productItems - array of all the product items listed
   */
  toggleSelectAll = (productItems: Array<UpdateProductItemInput>): void => {
    const { selectedProductItems } = this.state;
    // the local state currently consists of all product items, then deselect each of them
    if (selectedProductItems.length === productItems.length) {
      this.setState({ selectedProductItems: [], allSelected: false });
    } else {
      const productItemsIds = productItems.map(
        (productItem: UpdateProductItemInput) => {
          return productItem.id;
        }
      );
      this.setState({
        selectedProductItems: productItemsIds,
        allSelected: true
      });
    }
  };

  /** Return product item services
   * @param productItem - product item on batch card
   */
  returnProductItemServices = (
    productItem: UpdateProductItemInput,
    batchCardServices: Array<BatchCardServiceType>
  ): React.ReactNode => {
    return batchCardServices.map(
      (batchCardService: BatchCardServiceType, i) => {
        if (batchCardService) {
          return (
            <SelectedIcon
              key={i}
              background={
                batchCardService.service
                  ? productItem.qcApproved
                    ? Colors.lightPurple
                    : Colors.flumeGreen
                  : null
              }
              small={true}
            >
              <img
                alt="serviceIcon"
                src={
                  batchCardService.service
                    ? batchCardService.service.icon
                    : ServiceIcons.Editing
                }
              />
            </SelectedIcon>
          );
        }
        return null;
      }
    );
  };

  /**
   * display a toast appearance
   *
   * @param {string} message - message to display
   * @param {string} appearance - type of message to display
   *
   *
   * @returns {void}
   */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const {
      allSelected,
      addTextModal,
      batchCardId,
      bulkAction,
      dialogModal,
      imageModal,
      tagsModal,
      searchFilter,
      productItemId,
      productItemImageKeys,
      previousTagDescriptions,
      noteModal,
      selectedProductItems
    } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <BackendWrapper>
              <Query
                query={getCompanyCompletedProductItemsByUser}
                variables={{ id: userId }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (error) {
                    return (
                      <ErrorMessage errorMessage="There was a problem loading your company's product items" />
                    );
                  }

                  if (!data || !data.getUser) {
                    return (
                      <ErrorMessage errorMessage="There was a problem loading your company data" />
                    );
                  }

                  const company = data.getUser.company;
                  const itemTemplate = data.getUser.company.itemTemplate;

                  if (
                    company.productItems &&
                    company.productItems.items &&
                    company.productItems.items.length
                  ) {
                    const completedProductItems = company.productItems.items;
                    // Product item keys
                    const productItemKeys = returnProductItemKeysBasedOnCompanyTemplate(
                      itemTemplate
                    );
                    // Product items to be exported
                    const productItemsToExport = returnProductItemsBasedOnIds(
                      selectedProductItems,
                      completedProductItems
                    );
                    // CSV data of product items to be exported
                    const csvData = [
                      productItemKeys,
                      ...returnProductItemsBasedOnProductItemKeys(
                        productItemsToExport,
                        productItemKeys
                      )
                    ];

                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeNoteModal}
                          title="View notes on the product item"
                          modalDisplay={
                            <AddNoteToProductItem
                              batchCardId={batchCardId}
                              productItemId={productItemId}
                              notification={this.toastNotification}
                              previousNotes={returnPreviousNotes(
                                completedProductItems,
                                productItemId
                              )}
                              readOnly={true}
                              userId={userId}
                              closeModal={this.closeNoteModal}
                            />
                          }
                          modal={noteModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeAddTextModal}
                          title="View Text And Images In An Item"
                          largeModal={true}
                          modalDisplay={
                            <AddTextToProductItem
                              batchCardId={batchCardId}
                              bulkAction={bulkAction}
                              productItemId={productItemId}
                              productItemIds={selectedProductItems}
                              productItemImageKeys={productItemImageKeys}
                              notification={this.toastNotification}
                              previousContent={returnPreviousTextContent(
                                completedProductItems,
                                productItemId
                              )}
                              userId={userId}
                              closeModal={this.closeAddTextModal}
                            />
                          }
                          modal={addTextModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeImageModal}
                          title="View text and images in an item"
                          modalDisplay={
                            <AddImageToProductItem
                              batchCardId={batchCardId}
                              bulkAction={false}
                              productItemId={productItemId}
                              productItems={[]}
                              notification={this.toastNotification}
                              productItemImageKeys={productItemImageKeys}
                              readOnly={true}
                              userId={userId}
                              closeModal={this.closeImageModal}
                            />
                          }
                          modal={imageModal}
                        />
                        <GlobalModalContainer
                          toggleModal={() =>
                            this.setState({
                              tagsModal: false,
                              previousTagDescriptions: []
                            })
                          }
                          title="Product Tags"
                          modalDisplay={
                            <PreviousTags
                              previousTags={previousTagDescriptions}
                              noMargin={true}
                            />
                          }
                          modal={tagsModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeDialogModal}
                          title="Archive items"
                          modalDisplay={
                            <ArchiveOrCompleteProductItems
                              archived={true}
                              complete={false}
                              notification={this.toastNotification}
                              closeModal={this.closeDialogModal}
                              selectedProductItems={selectedProductItems}
                              userId={userId}
                            />
                          }
                          modal={dialogModal}
                        />
                        <TableHeaderContainer>
                          <TableHeader>
                            <span>Quality Control</span>
                          </TableHeader>
                          <StyledButton
                            type="button"
                            label="Archive Items"
                            width="120px"
                            onClick={this.openDialogModal}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                          <CSVLink
                            data={csvData}
                            target="_blank"
                            filename="complete-product-items.csv"
                          >
                            <StyledButton
                              type="button"
                              label="Export to CSV"
                              width="120px"
                              color={Colors.flumeGreen}
                              background={Colors.flumeDarkGreen}
                            />
                          </CSVLink>
                          <StyledButton
                            type="button"
                            label={
                              <MdSearch
                                size="1.3em"
                                color={Colors.flumeDarkGreen}
                              />
                            }
                            width="auto"
                            onClick={this.toggleSearchFilter}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                        </TableHeaderContainer>
                        <Table
                          data={completedProductItems}
                          columns={[
                            {
                              id: 'checkbox-column',
                              Header: () => (
                                <Checkbox
                                  onClick={() =>
                                    this.toggleSelectAll(completedProductItems)
                                  }
                                >
                                  {allSelected ? (
                                    <MdDone size="0.95em" color={Colors.grey} />
                                  ) : null}
                                </Checkbox>
                              ),
                              accessor: (
                                productItem: UpdateProductItemInput
                              ) => {
                                const thisProductItem = selectedProductItems.find(
                                  tProductItemId =>
                                    tProductItemId === productItem.id
                                );

                                return (
                                  <CircleIcon
                                    selected={thisProductItem || false}
                                    onClick={() =>
                                      this.selectProductItem(productItem.id)
                                    }
                                  />
                                );
                              },
                              sortable: false,
                              filterable: false,
                              width: 60
                            },
                            ...returnNewProductItemColumns(
                              itemTemplate,
                              searchFilter
                            ),
                            {
                              id: 'product-item-services',
                              Header: 'SERVICES',
                              accessor: (productItem: SavedProductItem) => {
                                return (
                                  <ServiceIconsContainer>
                                    {this.returnProductItemServices(
                                      productItem,
                                      productItem.batchCard.services.items
                                    )}
                                  </ServiceIconsContainer>
                                );
                              },
                              sortable: false,
                              filterable: false
                            },
                            {
                              id: 'row-actions',
                              Header: '',
                              accessor: (productItem: SavedProductItem) => {
                                return (
                                  <RowActions
                                    openNoteModal={this.openNoteModal}
                                    openAddTextModal={this.openAddTextModal}
                                    openTagsModal={this.openTagsModal}
                                    batchCardId={productItem.batchCard.id}
                                    productItemId={productItem.id}
                                    previousTagDescriptions={checkIfArrayOfStrings(
                                      productItem.previousTagDescriptions
                                    )}
                                    productItemImageKeys={checkIfArrayOfStrings(
                                      productItem.imageKeys
                                    )}
                                  />
                                );
                              },
                              sortable: false,
                              filterable: false,
                              width: 30
                            }
                          ]}
                          defaultPageSize={5}
                          showPaginationTop={false}
                          showPaginationBottom={true}
                        />
                        <Helmet title="Quality Control" />
                      </div>
                    );
                  }

                  return <div>There are no completed product items</div>;
                }}
              </Query>
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(QualityControl);
