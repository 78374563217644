import * as React from 'react';
import { Query } from 'react-apollo';
import { Row, Col } from 'reactstrap';

/** GraphQL */
import {
  getCompanyActiveSpacesByUser,
  getCompanyActiveUsersByUser,
  getCompanyTaggedProductItemsByUser
} from '../../graphql/custom-queries';

/** Presentation/UI */
import {
  DashboardSummaryCardContainer,
  DashboardSummaryCardHeader,
  DashboardSummaryCardNumberContainer
} from '../../Components/Styled/Dashboard';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/** Themes */
import { Colors } from '../../Themes';

const CompanyInfo: React.FC<{ userId: string }> = ({ userId }) => {
  return (
    <Row>
      <Col xs={12} md={4} lg={4}>
        <DashboardSummaryCardContainer>
          <DashboardSummaryCardHeader>Tagged Items</DashboardSummaryCardHeader>
          <DashboardSummaryCardNumberContainer>
            <Query
              query={getCompanyTaggedProductItemsByUser}
              variables={{ id: userId }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <Loader color={Colors.flumeGreen} />;
                }
                if (error) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                if (!data || !data.getUser) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                const company = data.getUser.company;

                if (
                  company.productItems &&
                  company.productItems.items &&
                  company.productItems.items.length
                ) {
                  const taggedProductItems = company.productItems.items.length;

                  return <span>{taggedProductItems}</span>;
                }

                return <span>0</span>;
              }}
            </Query>
          </DashboardSummaryCardNumberContainer>
        </DashboardSummaryCardContainer>
      </Col>
      <Col xs={12} md={4} lg={4}>
        <DashboardSummaryCardContainer>
          <DashboardSummaryCardHeader>
            Active Members
          </DashboardSummaryCardHeader>
          <DashboardSummaryCardNumberContainer>
            <Query
              query={getCompanyActiveUsersByUser}
              variables={{ id: userId }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <Loader color={Colors.flumeGreen} />;
                }
                if (error) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                if (!data || !data.getUser) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                const company = data.getUser.company;

                if (
                  company.users &&
                  company.users.items &&
                  company.users.items.length
                ) {
                  const activeCompanyUsers = company.users.items.length;

                  return <span>{activeCompanyUsers}</span>;
                }

                return <span>0</span>;
              }}
            </Query>
          </DashboardSummaryCardNumberContainer>
        </DashboardSummaryCardContainer>
      </Col>
      <Col xs={12} md={4} lg={4}>
        <DashboardSummaryCardContainer>
          <DashboardSummaryCardHeader>Active Spaces</DashboardSummaryCardHeader>
          <DashboardSummaryCardNumberContainer>
            <Query
              query={getCompanyActiveSpacesByUser}
              variables={{ id: userId }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <Loader color={Colors.flumeGreen} />;
                }
                if (error) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                if (!data || !data.getUser) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                const company = data.getUser.company;

                if (
                  company.spaces &&
                  company.spaces.items &&
                  company.spaces.items.length
                ) {
                  const activeCompanySpaces = company.spaces.items.length;

                  return <span>{activeCompanySpaces}</span>;
                }

                return <span>0</span>;
              }}
            </Query>
          </DashboardSummaryCardNumberContainer>
        </DashboardSummaryCardContainer>
      </Col>
    </Row>
  );
};

export default CompanyInfo;
