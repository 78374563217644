import * as React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';

/** Presentation/UI */
import { TableHeaderContainer } from '../../Components/Styled/ListViewElements';
import {
  HeaderWrapper,
  ViewWrapper,
  ToggleWeekContainer,
  ArrowContainer,
  DateContainer
} from '../../Components/Styled/MainPlanner';

/** Local components */
import CalendarView from './CalendarView';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { returnDatePeriod } from '../../Utils/Helpers';

const Wrapper = styled.div`
  height: 1200px;
`;

const Container = styled.div`
  width: calc(100% - 30px);
  display: table-row;
  margin: 0 auto;
`;

type Props = {
  currentWeek: Array<Date>;
  nextWeek: () => void;
  prevWeek: () => void;
  updateCurrentWeekByDate(date: Date | null): void;
};

class CalendarContainer extends React.Component<Props> {
  render() {
    const {
      currentWeek,
      nextWeek,
      prevWeek,
      updateCurrentWeekByDate
    } = this.props;
    return (
      <Wrapper>
        <Container>
          <HeaderWrapper>
            <TableHeaderContainer>
              <ToggleWeekContainer>
                <ArrowContainer onClick={prevWeek}>
                  <MdNavigateBefore size="1.5em" color={Colors.grey} />
                </ArrowContainer>
                <DatePicker
                  onChange={date => updateCurrentWeekByDate(date)}
                  customInput={
                    <DateContainer>
                      {returnDatePeriod(currentWeek)}
                    </DateContainer>
                  }
                  dateFormat="dd/MM/yyyy"
                />
                <ArrowContainer onClick={nextWeek}>
                  <MdNavigateNext size="1.5em" color={Colors.grey} />
                </ArrowContainer>
              </ToggleWeekContainer>
            </TableHeaderContainer>
          </HeaderWrapper>
          <ViewWrapper>
            <CalendarView currentWeek={currentWeek} />
          </ViewWrapper>
        </Container>
      </Wrapper>
    );
  }
}

export default CalendarContainer;
