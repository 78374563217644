/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Form } from 'reactstrap';
import { MdCancel } from 'react-icons/md';
import { Storage } from 'aws-amplify';

/** GraphQL */
import { updateProductItem } from '../../graphql/mutations';
import {
  getCompanyBatchCardByUser,
  getCompanyBatchCardsByUser
} from '../../graphql/custom-queries';
import {
  UpdateProductItemMutation,
  UpdateProductItemMutationVariables
} from '../../API';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Presentaion/UI */
import GlobalModalContainer from '../../Components/Modal';
import DialogModal from '../../Components/DialogModal';
import {
  ImageContainer,
  ImagesWrapper,
  ImageWrapper
} from '../../Components/Styled/ImageContainer';

/** Custom types */
import { ModalWithMessageType } from '../../CustomTypes';

/** Utils */
import { returnImageFromStorage } from '../../Utils/Helpers';

type Image = { key: string; url: string };

type Props = ModalWithMessageType & {
  batchCardId?: string;
  productItemId: string;
  productItemImageKeys: Array<string>;
  userId: string;
};

type State = {
  id: string;
  productItemImages: Array<Image>;
  dialogModal: boolean;
  imageUrl: string;
  imageKey: string;
};

class ProductItemImages extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.productItemId,
      productItemImages: [],
      dialogModal: false,
      imageUrl: '',
      imageKey: ''
    };
  }

  async componentDidMount() {
    const { productItemImageKeys } = this.props;
    if (productItemImageKeys && productItemImageKeys.length) {
      const productItemImages = await Promise.all(
        productItemImageKeys.map(async (imageKey: string) => {
          const img = await returnImageFromStorage(imageKey);
          const imgUrl = typeof img === 'string' ? img : '';
          return {
            key: imageKey,
            url: imgUrl
          };
        })
      );

      this.setState({ productItemImages });
    }
  }

  /** Close dialog modal */
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false
    });
  };

  /** Open dialog modal
   * @param imageKey - key used to get access to the url for image uploaded to S3
   */
  openDialogModal = (imageKey: string): void => {
    this.setState({
      dialogModal: true,
      imageKey
    });
  };

  /** Return/display batch card images
   * @param productItemImages - array of details for each image related to a batch card
   */
  displayproductItemImages = (
    productItemImages: Array<Image>
  ): React.ReactNode => {
    if (productItemImages && productItemImages.length) {
      return productItemImages.map((productItemImage: Image) => {
        return (
          <ImageWrapper key={productItemImage.key}>
            <MdCancel
              style={{ cursor: 'pointer' }}
              size="1.3em"
              onClick={() => this.openDialogModal(productItemImage.key)}
            />
            <ImageContainer small={true} imageUrl={productItemImage.url} />
          </ImageWrapper>
        );
      });
    }
    return <span>No images have been uploaded</span>;
  };

  /** Delete image from S3 bucket and from product item
   * @param updateProductItemMutation - graphql mutation to update a product item
   */
  deleteImageFromProductItem = async (
    userId: string,
    updateProductItemMutation: ({}) => Promise<any>
  ) => {
    const { id, imageKey, productItemImages } = this.state;
    const { batchCardId, closeModal, notification } = this.props;

    // Get all current image keys
    const currentImageKeys = productItemImages.map(
      (productItemImage: Image) => {
        return productItemImage.key;
      }
    );

    try {
      // Delete the image from S3 bucket
      await Storage.remove(imageKey).catch(err =>
        notification(err.message, 'error')
      );

      /**
       * Filter out the key of deleted image and
       * update the image keys for this product item
       */
      const imageKeys = currentImageKeys.filter(
        (key: string) => key !== imageKey
      );

      // Update product item
      updateProductItemMutation({
        variables: {
          input: {
            id,
            imageKeys
          }
        },
        refetchQueries: [
          {
            query: getCompanyBatchCardByUser,
            variables: {
              userId,
              batchCardId
            }
          },
          {
            query: getCompanyBatchCardsByUser,
            variables: {
              id: userId
            }
          }
        ]
      })
        .then(() => {
          notification('Image deleted successfully');
          closeModal();
        })
        .catch(err => {
          notification(err.message, 'error');
          this.closeDialogModal();
        });
    } catch (e) {
      notification(e.message, 'error');
      this.closeDialogModal();
    }
  };

  render() {
    const { productItemImages, dialogModal } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <div>
              <GlobalModalContainer
                toggleModal={this.closeDialogModal}
                title=""
                modalDisplay={
                  <Mutation<
                    UpdateProductItemMutation,
                    UpdateProductItemMutationVariables
                  >
                    mutation={gql(updateProductItem)}
                  >
                    {(updateProductItemMutation, updateMutation) => (
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          this.deleteImageFromProductItem(
                            userId,
                            updateProductItemMutation
                          );
                        }}
                      >
                        <DialogModal
                          loading={updateMutation.loading}
                          title="Are you sure you want to delete this image?"
                          toggleModal={this.closeDialogModal}
                        />
                      </Form>
                    )}
                  </Mutation>
                }
                modal={dialogModal}
              />
              <ImagesWrapper>
                {this.displayproductItemImages(productItemImages)}
              </ImagesWrapper>
            </div>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default ProductItemImages;
