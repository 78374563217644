import * as React from 'react';
import * as jwtDecode from 'jwt-decode';

/** Utils */
import { AUTH_USER_TOKEN_KEY } from '../Utils/LocalStorage';
import { USER_GROUP } from '../Utils/Consts';
import { getGroupFromToken } from '../Utils/Helpers';

const useUserId = (id: string, group: string, email: string) => {
  const [userId, setUserId] = React.useState(id);
  const [userRole, setUserRole] = React.useState(group);
  const [userEmail, setUserEmail] = React.useState(email);

  const updateUserId = (jwtToken: string) => {
    window.localStorage.setItem(AUTH_USER_TOKEN_KEY, jwtToken);
    const decodedJwt: any = jwtDecode(jwtToken);
    setUserId(decodedJwt.sub);
  };

  const updateUserRole = (jwtToken: string) => {
    const cognitoGroup = getGroupFromToken(jwtToken);
    setUserRole(cognitoGroup || USER_GROUP);
  };

  const updateUserEmail = (jwtToken: string) => {
    const decodedJwt: any = jwtDecode(jwtToken);
    setUserEmail(decodedJwt.username);
  };

  return {
    userId,
    updateUserId,
    userRole,
    updateUserRole,
    userEmail,
    updateUserEmail
  };
};

export default useUserId;
