import * as React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Form } from 'reactstrap';

/** GraphQL */
import {
  getCompanyBatchCardByUser,
  getCompanyBatchCardAllocationsForTheWeekByUser
} from '../../graphql/custom-queries';

/** Context API */
import DefaultWeekContextProvider from '../QualityControlCalendar/DefaultWeekContextProvider';
import { DefaultWeekContextConsumer } from '../QualityControlCalendar/DefaultWeekContextProvider';

/** Generated types */
import {
  UpdateProductItemMutation,
  UpdateProductItemMutationVariables
} from '../../API';

/** Presentation/UI */
import DialogModal from '../../Components/DialogModal';

/** Custom types */
import { ModalWithMessageType } from '../../CustomTypes';

/** Utils */
import { joinMutations } from '../../Utils/Helpers';
import {
  qcApproveDialogMessage,
  qcDisapproveDialogMessage,
  qcApproveDialogSuccessMessage,
  qcDisapproveDialogSuccessMessage
} from '../../Utils/Consts';

type Props = ModalWithMessageType & {
  batchCardId?: string;
  bulkAction?: boolean;
  productItemId: string;
  productItemIds?: Array<string>;
  qcApproval: boolean;
  selectedProductItems: Array<string>;
  userId: string;
};

class QCapproveItems extends React.Component<Props> {
  /** Execute mutation to update product items as either archived or completed
   * @param notificationMessage - notification to be displayed after mutation
   * @param closeModal - function for closing modal
   * @param notification - function to display notification
   */
  executeMutation = async (
    notificationMessage: string,
    mutationToUpdateProductItems: () => Promise<any>,
    closeModal: () => void,
    notification: (message: string, appearance?: string | undefined) => void
  ) => {
    try {
      await mutationToUpdateProductItems();
      closeModal();
      notification(notificationMessage);
    } catch (err) {
      closeModal();
      notification(err.message, 'error');
    }
  };

  /** Create mutation with alias mutations to update product items
   * @param selectedProductIems - ids of selected product items
   * @param qcApproval - a flag which determines whether product items are being qc approveda= an disapproved
   */
  returnMutation = (
    selectedProductItems: Array<string>,
    qcApproval: boolean
  ) => {
    const productItems = this.props.bulkAction
      ? selectedProductItems
      : [this.props.productItemId];

    if (productItems && productItems.length) {
      const updateProductItemMutations = productItems.map(productItemId => {
        const alias = `alias_updateProductItem_${Math.random()
          .toString(36)
          .slice(2)}`;

        return `
            ${alias}: updateProductItem(
              input: {
                id: "${productItemId}",
                qcApprovedAt: "${new Date().toISOString()}",
                qcApproved: ${qcApproval}
              }
            ){
              id
              qcApproved
              qcApprovedAt
            }
        `;
      });

      const mutations = [...updateProductItemMutations];
      return joinMutations(mutations);
    } else {
      return ``;
    }
  };

  render() {
    const {
      batchCardId,
      closeModal,
      qcApproval,
      selectedProductItems,
      notification,
      userId
    } = this.props;

    const dialogMesaage = qcApproval
      ? qcApproveDialogMessage
      : qcDisapproveDialogMessage;
    const notificationMessage = qcApproval
      ? qcApproveDialogSuccessMessage
      : qcDisapproveDialogSuccessMessage;

    return (
      <DefaultWeekContextProvider>
        <DefaultWeekContextConsumer>
          {({ currentWeekFormattedDates }) => {
            return (
              <Mutation<
                UpdateProductItemMutation,
                UpdateProductItemMutationVariables
              >
                mutation={gql(
                  this.returnMutation(selectedProductItems, qcApproval)
                )}
                refetchQueries={[
                  {
                    query: getCompanyBatchCardByUser,
                    variables: {
                      userId,
                      batchCardId
                    }
                  },
                  {
                    query: getCompanyBatchCardAllocationsForTheWeekByUser,
                    variables: {
                      id: userId,
                      dayOne: currentWeekFormattedDates[0],
                      dayTwo: currentWeekFormattedDates[1],
                      dayThree: currentWeekFormattedDates[2],
                      dayFour: currentWeekFormattedDates[3],
                      dayFive: currentWeekFormattedDates[4],
                      daySix: currentWeekFormattedDates[5],
                      daySeven: currentWeekFormattedDates[6]
                    }
                  }
                ]}
              >
                {(updateProductItemMutation, updateMutation) => (
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      // execute mutation
                      this.executeMutation(
                        notificationMessage,
                        updateProductItemMutation,
                        closeModal,
                        notification
                      );
                    }}
                  >
                    <DialogModal
                      loading={updateMutation.loading}
                      title={dialogMesaage}
                      toggleModal={closeModal}
                    />
                  </Form>
                )}
              </Mutation>
            );
          }}
        </DefaultWeekContextConsumer>
      </DefaultWeekContextProvider>
    );
  }
}

export default QCapproveItems;
