/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import * as validator from 'validator';
import { Mutation, MutationFn } from 'react-apollo';
import gql from 'graphql-tag';
import Dropzone from 'react-dropzone';
import { Form } from 'reactstrap';
import * as Papa from 'papaparse';
import AppSyncConfig from '../../aws-exports';
import { MdFileDownload } from 'react-icons/md';

/** GraphQL */
import { createUser } from '../../graphql/mutations';
import { getCompanyUsersByUser } from '../../graphql/custom-queries';

import {
  CreateUserMutation,
  CreateUserMutationVariables,
  UserStatus,
  CreateRoleInput
} from '../../API';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Local component */
import DropzoneContainer from './DropzoneContainer';

/** Custom types */
import { Error, ImportUserType } from '../../CustomTypes';

/** Theme */
import { Colors } from '../../Themes';

/** Utils */
import { COGNITO_CREATE_USER } from '../../Utils/LambdaEndpoints';
import {
  VALID_FILE_FORMATS,
  USER_GROUP,
  HTTP_METHODS,
  USER_ROLES,
  NON_USER_GROUP,
  SUPER_USER_GROUP
} from '../../Utils/Consts';

/** API */
import { apiRequest } from '../../Utils/API';

type Props = {
  companyId: string;
  roles: Array<CreateRoleInput>;
  closeModal: () => void;
  notification(message: string, appearance?: string): void;
};

type State = {
  files: Array<File>;
  loading: boolean;
  error: Error;
  permission: any;
};

class MembersDataImport extends React.Component<Props, State> {
  state: State = {
    files: [],
    loading: false,
    error: null,
    permission: USER_GROUP
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** On dropping file */
  onDrop = (acceptedFiles: any): void => {
    if (!acceptedFiles || !acceptedFiles.length) {
      this.setError('Sorry, there was a problem uploading your selected file.');
    }
    // Check if the uploaded file is a valid csv file
    const uploadedFile = acceptedFiles[0];
    if (uploadedFile.name.split('.').pop() === VALID_FILE_FORMATS.csv) {
      this.setState({ files: [...this.state.files, ...acceptedFiles] });
    } else {
      this.setError('Please upload a valid CSV file.');
    }
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Parse uploaded data from file */
  parseFileData = (
    userId: string,
    userEmail: string,
    files: Array<File>,
    createUserMutation: ({  }: any) => Promise<any>
  ) => {
    Papa.parse(files[0], {
      complete: (results: any) => {
        // Check if there are rows of data in the CSV file
        if (results && results.data && results.data.length) {
          // filter rows/lines and obtain valid ones (Must have at least a valid username/email)
          const validUsers = results.data.filter((resultRow: Array<string>) =>
            validator.isEmail(resultRow[0])
          );

          if (validUsers && validUsers.length) {
            /**
             * Use array of valid user data to retrieve array of
             * objects that match user type to be created using
             * mutation
             */
            const usersToBeCreated: Array<
              ImportUserType
            > = this.getUsersToBeCreated(validUsers);

            this.createNewUsers(
              userId,
              userEmail,
              createUserMutation,
              usersToBeCreated
            );
          }
        } else {
          this.setError('No rows were found in the CSV file you uploaded.');
        }
      }
    });
  };

  // return user role object
  getUserRoleId = (userRole: string) => {
    const { roles } = this.props;
    const role = roles.find(
      role => role.name.toLowerCase() === userRole.toLowerCase()
    );

    return role;
  };

  // return user group as per user's permission
  returnCognitoPoolUserGroup = (role: CreateRoleInput): string => {
    if (role) {
      switch (role.permission) {
        case USER_ROLES.user:
          return USER_GROUP;
        case USER_ROLES.superUser:
          return SUPER_USER_GROUP;
        case USER_ROLES.nonUser:
          return NON_USER_GROUP;
        default:
          return USER_GROUP;
      }
    }

    return this.state.permission;
  };

  /** Get valid data to be created in the system
   * @param validUsers - the valid members/data that has been parsed and returned as an array
   */
  getUsersToBeCreated = (validUsers: Array<Array<string>>) => {
    const { companyId } = this.props;

    return validUsers.map((validUser: Array<string>) => {
      /**
       * Only the values referenced by the first three indices are of relevance for this import
       * validUser[0] - this references the username/email value
       * validUser[1] - this references the user's first name
       * validUser[2] - this references the user's last name
       */
      const role: any = this.getUserRoleId(validUser[3]);
      this.setState({ permission: this.returnCognitoPoolUserGroup(role) });

      return {
        createdAt: new Date().toISOString(),
        email: validUser[0].toLowerCase(),
        firstName: validUser[1],
        lastName: validUser[2],
        userUserRoleId: role ? role.id : null,
        inviteAccepted: false,
        status:
          validUser[4].toUpperCase() === UserStatus.INACTIVE
            ? UserStatus.INACTIVE
            : UserStatus.ACTIVE,
        userCompanyId: companyId,
        inviteLater: validUser[5] && validUser[5] === 'FALSE' ? true : false,
        permission: this.returnCognitoPoolUserGroup(role)
      };
    });
  };

  /** Create new users in cognito pool and Dynamo DB */
  createNewUsers = (
    userId: string,
    userEmail: string,
    createUserMutation: MutationFn,
    usersToBeCreated: Array<ImportUserType>
  ): void => {
    const { closeModal, notification } = this.props;

    usersToBeCreated.forEach(async user => {
      const {
        createdAt,
        email,
        firstName,
        lastName,
        status,
        userCompanyId,
        inviteLater,
        userUserRoleId
      } = user;
      const inviteUserParams = {
        inviteUser: true,
        invitedBy: userEmail
      };
      const bodyParams = {
        userPoolId: AppSyncConfig.aws_user_pools_id,
        groupName: user.permission,
        email: user.email,
        ...(!inviteLater && inviteUserParams)
      };
      // Newly created user in cognito pool
      const cognitoUser = await apiRequest(
        COGNITO_CREATE_USER,
        HTTP_METHODS.POST,
        bodyParams
      ).catch(e => {
        this.setState({ loading: false });
        this.setError(e.message);
      });

      // create the user in dynamodb
      if (
        cognitoUser &&
        cognitoUser.Attributes &&
        cognitoUser.Attributes.length
      ) {
        const id = cognitoUser.Attributes[0].Value;

        createUserMutation({
          variables: {
            input: {
              createdAt,
              id,
              email,
              firstName,
              lastName,
              status,
              userCompanyId,
              inviteLater,
              userUserRoleId
            }
          },
          refetchQueries: [
            {
              query: getCompanyUsersByUser,
              variables: {
                id: userId
              }
            }
          ]
        }).catch(err => {
          this.setError(err.message);
        });
      }
    });
    closeModal();
    notification('Member data is being imported from file');
  };

  render() {
    const { files, error } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId, userEmail }) => {
          return (
            <Mutation<CreateUserMutation, CreateUserMutationVariables>
              mutation={gql(createUser)}
            >
              {createUserMutation => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    if (!files.length) {
                      this.setError('Please upload a valid CSV file.');
                    } else {
                      /**
                       * `Papa.parse` will return an object with a data property which consists
                       *  of an array of all the rows in the uploaded csv file. Each row is also an array.
                       *  The elements of a row array is determined by the number of columns created
                       * by the separator in the csv file (e.g. a comma `,`).
                       */
                      this.parseFileData(
                        userId,
                        userEmail,
                        files,
                        createUserMutation
                      );
                    }
                  }}
                >
                  <DropzoneContainer>
                    <div className="dropzone">
                      {!files.length && (
                        <Dropzone onDrop={this.onDrop}>
                          {({ getRootProps, getInputProps, isDragActive }) => {
                            return (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                  <p>Drop files here...</p>
                                ) : (
                                  <p>
                                    Drop a file here, or download the sample
                                    file below
                                  </p>
                                )}
                              </div>
                            );
                          }}
                        </Dropzone>
                      )}
                      {files.map((f: File, i) => (
                        <React.Fragment key={f.size}>
                          <div
                            style={{
                              textAlign: 'center',
                              marginTop: '20px',
                              marginBottom: '20px',
                              background: Colors.snow
                            }}
                          >
                            Chosen file: {f.name} - {`${f.size} bytes`}
                          </div>
                        </React.Fragment>
                      ))}
                      <br />
                      {error && <ErrorMessage errorMessage={error} />}
                      <a
                        href={require('../../Assets/FileTemplates/SampleTeamMembers.csv')}
                        download="SampleTeamMembers.csv"
                      >
                        <StyledButton
                          type="button"
                          label={[
                            'Sample File',
                            {
                              ...(
                                <MdFileDownload
                                  size="1.3em"
                                  color={Colors.formWhite}
                                />
                              )
                            }
                          ]}
                          width="10rem"
                          color={Colors.formWhite}
                          background={Colors.grey}
                        />
                        <StyledButton
                          label="Import"
                          type="submit"
                          className="importFile"
                          background={Colors.flumeDarkGreen}
                        />
                      </a>
                    </div>
                  </DropzoneContainer>
                </Form>
              )}
            </Mutation>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default MembersDataImport;
