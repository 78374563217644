import * as React from 'react';
import styled from 'styled-components';

/** Helpers */
import { convertPixelsToRem } from '../../Utils/Helpers';

interface IProps {
  children: React.ReactNode;
  align?: 'right' | 'center' | 'left';
  marginTop?: number;
}

const Container = styled.div`
  width: 100%;
  text-align: ${(props: { align?: string }) =>
    props.align ? `${props.align}` : 'auto'};
  margin-top: ${(props: { align?: string; marginTop?: number }) =>
    props.marginTop ? convertPixelsToRem(props.marginTop) : 0};
`;

const FullWidthContainer: React.SFC<IProps> = (props: IProps) => {
  return (
    <Container align={props.align} marginTop={props.marginTop}>
      {props.children}
    </Container>
  );
};

export default FullWidthContainer;
