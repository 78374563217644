import * as React from 'react';
import { Mutation } from 'react-apollo';
import { Row, Col, FormGroup, Form, Label } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Query } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import gql from 'graphql-tag';

/** GraphQL */
import { listItemTemplates } from '../../graphql/queries';
import { getCompanyByUser } from '../../graphql/custom-queries';
import { updateCompany } from '../../graphql/mutations';
import {
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  UpdateItemTemplateInput,
  ItemTemplateType
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import PageLoader from '../../Components/PageLoader';
import StyledButton from '../../Components/Styled/Button';
import {
  TableHeader,
  TableHeaderContainer,
  TemplateContainer,
  ListOfRadioButtons
} from '../../Components/Styled/ListViewElements';

/** Local components */
import Placeholder from './Placeholder';

/** Themes */
import { Colors } from '../../Themes';

/** Custom Types */
import { ToastNotificationType } from '../../CustomTypes';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Utils */
import {
  PRODUCT_ITEM_DESCRIPTION,
  ITEM_TEMPLATE_DESCRIPTION
} from '../../Utils/Consts';

type Props = {
  toastManager: ToastNotificationType;
};

type State = {
  selectedTemplate?: string;
  showItemTemplateTable?: boolean;
};

class ItemTemplates extends React.Component<Props, State> {
  state = {
    selectedTemplate: '',
    showItemTemplateTable: false
  };

  /** select the product template */
  selectTemplate = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ selectedTemplate: e.currentTarget.value });
  };

  /** Return the various product item templates */
  returnProductItemTemplates = (
    itemTemplates: Array<UpdateItemTemplateInput>
  ): React.ReactNode => {
    // Get production studios templates
    const productionStudioTemplates = itemTemplates.filter(
      itemTemplate =>
        itemTemplate.itemTemplateType === ItemTemplateType.PRODUCTION_STUDIOS
    );

    // Get runway shows templates
    const runwayStudioTemplates = itemTemplates.filter(
      itemTemplate =>
        itemTemplate.itemTemplateType === ItemTemplateType.RUNWAY_SHOWS
    );

    const manufacturingStudioTemplates = itemTemplates.filter(
      itemTemplate =>
        itemTemplate.itemTemplateType === ItemTemplateType.MANUFACTURING
    );

    // Get service shows templates
    const serviceStudioTemplates = itemTemplates.filter(
      itemTemplate =>
        itemTemplate.itemTemplateType === ItemTemplateType.SERVICES
    );

    // Get universal shows templates
    const universalStudioTemplates = itemTemplates.filter(
      itemTemplate =>
        itemTemplate.itemTemplateType === ItemTemplateType.UNIVERSAL
    );

    return (
      <ListOfRadioButtons>
        <ul>
          <li>
            <Label>
              <span>Studios</span>
            </Label>
          </li>
          {productionStudioTemplates.map(itemTemplate => {
            return (
              <li key={itemTemplate.id}>
                <input
                  type="radio"
                  id={itemTemplate.id}
                  value={itemTemplate.id}
                  className="template-selector"
                  name="selector"
                  onChange={this.selectTemplate}
                />
                <Label for={itemTemplate.id}>
                  <TemplateContainer key={itemTemplate.id}>
                    {itemTemplate.brand && <div>Brand</div>}
                    {itemTemplate.subBrand && <div>Sub-Brand</div>}
                    {itemTemplate.category && <div>Category</div>}
                    {itemTemplate.subCategory && <div>Sub Category</div>}
                    {itemTemplate.type && <div>Type</div>}
                    {itemTemplate.gender && <div>Gender</div>}
                    {itemTemplate.age && <div>Age</div>}
                    {itemTemplate.size && <div>Size</div>}
                    {itemTemplate.collectionName && <div>Collection Name</div>}
                    {itemTemplate.season && <div>Season</div>}
                    {itemTemplate.look && <div>Look</div>}
                    {itemTemplate.other1 && <div>Other 1</div>}
                    {itemTemplate.other2 && <div>Other 2</div>}
                    {itemTemplate.uic && <div>UIC</div>}
                  </TemplateContainer>
                </Label>
                <div className="check">
                  <div className="inside" />
                </div>
              </li>
            );
          })}
          <li>
            <Label>
              <span>Runway Shows</span>
            </Label>
          </li>
          {runwayStudioTemplates.map(itemTemplate => {
            return (
              <li key={itemTemplate.id}>
                <input
                  type="radio"
                  id={itemTemplate.id}
                  value={itemTemplate.id}
                  name="selector"
                  onChange={this.selectTemplate}
                />
                <Label for={itemTemplate.id}>
                  <TemplateContainer key={itemTemplate.id}>
                    {itemTemplate.brand && <div>Brand</div>}
                    {itemTemplate.subBrand && <div>Sub-Brand</div>}
                    {itemTemplate.category && <div>Category</div>}
                    {itemTemplate.subCategory && <div>Sub Category</div>}
                    {itemTemplate.type && <div>Type</div>}
                    {itemTemplate.gender && <div>Gender</div>}
                    {itemTemplate.age && <div>Age</div>}
                    {itemTemplate.size && <div>Size</div>}
                    {itemTemplate.collectionName && <div>Collection Name</div>}
                    {itemTemplate.season && <div>Season</div>}
                    {itemTemplate.look && <div>Look</div>}
                    {itemTemplate.other1 && <div>Other 1</div>}
                    {itemTemplate.other2 && <div>Other 2</div>}
                    {itemTemplate.uic && <div>UIC</div>}
                  </TemplateContainer>
                </Label>
                <div className="check">
                  <div className="inside" />
                </div>
              </li>
            );
          })}
          <li>
            <Label>
              <span>Manufacturing</span>
            </Label>
          </li>
          {manufacturingStudioTemplates.map(itemTemplate => {
            return (
              <li key={itemTemplate.id}>
                <input
                  type="radio"
                  id={itemTemplate.id}
                  value={itemTemplate.id}
                  name="selector"
                  onChange={this.selectTemplate}
                />
                <Label for={itemTemplate.id}>
                  <TemplateContainer key={itemTemplate.id}>
                    {itemTemplate.brand && <div>Brand</div>}
                    {itemTemplate.subBrand && <div>Sub-Brand</div>}
                    {itemTemplate.category && <div>Category</div>}
                    {itemTemplate.subCategory && <div>Sub Category</div>}
                    {itemTemplate.type && <div>Type</div>}
                    {itemTemplate.productName && <div>Product Name</div>}
                    {itemTemplate.quantity && <div>Quantity</div>}
                    {itemTemplate.unit && <div>Unit</div>}
                    {itemTemplate.uic && <div>UIC</div>}
                  </TemplateContainer>
                </Label>
                <div className="check">
                  <div className="inside" />
                </div>
              </li>
            );
          })}
          <li>
            <Label>
              <span>Services</span>
            </Label>
          </li>
          {serviceStudioTemplates.map(itemTemplate => {
            return (
              <li key={itemTemplate.id}>
                <input
                  type="radio"
                  id={itemTemplate.id}
                  value={itemTemplate.id}
                  name="selector"
                  onChange={this.selectTemplate}
                />
                <Label for={itemTemplate.id}>
                  <TemplateContainer key={itemTemplate.id}>
                    {itemTemplate.brand && <div>Brand</div>}
                    {itemTemplate.category && <div>Category</div>}
                    {itemTemplate.subCategory && <div>Sub Category</div>}
                    {itemTemplate.type && <div>Type</div>}
                    {itemTemplate.serviceName && <div>Service Name</div>}
                    {itemTemplate.other1 && <div>Other 1</div>}
                    {itemTemplate.other2 && <div>Other 2</div>}
                    {itemTemplate.uic && <div>UIC</div>}
                  </TemplateContainer>
                </Label>
                <div className="check">
                  <div className="inside" />
                </div>
              </li>
            );
          })}
          <li>
            <Label>
              <span>Universal</span>
            </Label>
          </li>
          {universalStudioTemplates.map(itemTemplate => {
            return (
              <li key={itemTemplate.id}>
                <input
                  type="radio"
                  id={itemTemplate.id}
                  value={itemTemplate.id}
                  name="selector"
                  onChange={this.selectTemplate}
                />
                <Label for={itemTemplate.id}>
                  <TemplateContainer key={itemTemplate.id}>
                    {itemTemplate.brand && <div>Brand</div>}
                    {itemTemplate.subBrand && <div>Sub-Brand</div>}
                    {itemTemplate.clientName && <div>Client Name</div>}
                    {itemTemplate.productName && <div>Product Name</div>}
                    {itemTemplate.serviceName && <div>Service Name</div>}
                    {itemTemplate.category && <div>Category</div>}
                    {itemTemplate.subCategory && <div>Sub Category</div>}
                    {itemTemplate.type && <div>Type</div>}
                    {itemTemplate.other1 && <div>Other 1</div>}
                    {itemTemplate.other2 && <div>Other 2</div>}
                    {itemTemplate.other3 && <div>Other 3</div>}
                    {itemTemplate.uic && <div>UIC</div>}
                  </TemplateContainer>
                </Label>
                <div className="check">
                  <div className="inside" />
                </div>
              </li>
            );
          })}
        </ul>
      </ListOfRadioButtons>
    );
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  displayItemTemplates = (): void => {
    this.setState({ showItemTemplateTable: true });
  };

  render() {
    const { selectedTemplate, showItemTemplateTable } = this.state;
    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <BackendWrapper>
              <Query query={getCompanyByUser} variables={{ id: userId }}>
                {getCompanyResponse => {
                  if (getCompanyResponse.loading) {
                    return <PageLoader />;
                  }
                  if (getCompanyResponse.error) {
                    return (
                      <div>
                        There was a problem loading the product item brands
                      </div>
                    );
                  }

                  // Get company from the user query
                  const company = getCompanyResponse.data.getUser.company;

                  // Get company id from the user query
                  const companyId = getCompanyResponse.data.getUser.company.id;

                  if (company.itemTemplate) {
                    return (
                      <div>
                        <TableHeaderContainer>
                          <TableHeader>
                            <span>
                              Your Selected Item Template Has Been Saved!
                            </span>
                          </TableHeader>
                        </TableHeaderContainer>
                        <Placeholder
                          path="/production/sorting/new-items"
                          title="Production"
                          text={PRODUCT_ITEM_DESCRIPTION}
                          buttonLabel="Go To Production"
                          buttonLink={true}
                        />
                      </div>
                    );
                  }

                  return (
                    <div>
                      {showItemTemplateTable ? (
                        <div>
                          <TableHeaderContainer>
                            <TableHeader>
                              <p>
                                <b>
                                  Please note, once you have selected an item
                                  template you will not be able to change it.
                                </b>
                              </p>
                              <span>Select An Item Template</span>
                            </TableHeader>
                          </TableHeaderContainer>
                          <Query
                            query={gql(listItemTemplates)}
                            variables={{ limit: 12 }}
                          >
                            {({ loading, error, data }) => {
                              if (loading) {
                                return <PageLoader />;
                              }
                              if (error) {
                                return (
                                  <div>
                                    There was a problem loading the product item
                                    brands
                                  </div>
                                );
                              }

                              if (
                                data.listItemTemplates.items &&
                                data.listItemTemplates.items.length
                              ) {
                                return (
                                  <Mutation<
                                    UpdateCompanyMutation,
                                    UpdateCompanyMutationVariables
                                  >
                                    mutation={gql(updateCompany)}
                                  >
                                    {(
                                      updateCompanyMutation,
                                      updateCompanyResponse
                                    ) => (
                                      <Form
                                        onSubmit={e => {
                                          e.preventDefault();
                                          updateCompanyMutation({
                                            variables: {
                                              input: {
                                                companyItemTemplateId: selectedTemplate,
                                                id: companyId
                                              }
                                            },
                                            refetchQueries: [
                                              {
                                                query: getCompanyByUser,
                                                variables: {
                                                  id: userId
                                                }
                                              }
                                            ]
                                          })
                                            .then(res => {
                                              this.toastNotification(
                                                'Item template saved successfully',
                                                'success'
                                              );
                                            })
                                            .catch(err => {
                                              this.toastNotification(
                                                'Sorry, there was a problem updating the space',
                                                'error'
                                              );
                                            });
                                        }}
                                      >
                                        <div>
                                          {this.returnProductItemTemplates(
                                            data.listItemTemplates.items
                                          )}
                                        </div>
                                        <br />
                                        <br />
                                        <Row>
                                          <Col xs={12} md={3} lg={3}>
                                            <FormGroup>
                                              <StyledButton
                                                type="submit"
                                                label={
                                                  updateCompanyResponse.loading ? (
                                                    <Loader />
                                                  ) : (
                                                    'Save'
                                                  )
                                                }
                                                color={Colors.flumeDarkGreen}
                                                background={Colors.flumeGreen}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        {error && (
                                          <ErrorMessage errorMessage={error} />
                                        )}
                                      </Form>
                                    )}
                                  </Mutation>
                                );
                              }

                              return (
                                <div>There are no product item templates.</div>
                              );
                            }}
                          </Query>
                        </div>
                      ) : (
                        <Placeholder
                          buttonLink={true}
                          title="Select your Item Template"
                          text={ITEM_TEMPLATE_DESCRIPTION}
                          buttonLabel="Go To Item Templates"
                          buttonAction={this.displayItemTemplates}
                        />
                      )}
                    </div>
                  );
                }}
              </Query>
              <Helmet title="Item Templates" />
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(ItemTemplates);
