import * as React from 'react';
import { Form, Label, FormGroup, Input } from 'reactstrap';

// Presentation/UI
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import Button from '../../Components/Styled/Button';

// Custom Types
import { ConfirmationCodeError, Error } from '../../CustomTypes';

// Themes
import { Colors } from '../../Themes';

type IProps = {
  confirmationCodeError: ConfirmationCodeError | null;
  confirmSignUp(confirmationCode: string): void;
};

type IState = {
  confirmationCode: string;
  loading: boolean;
  error: Error;
};

class ConfirmationCodeForm extends React.Component<IProps, IState> {
  state: IState = {
    confirmationCode: '',
    loading: false,
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  validateForm = (): boolean => {
    const { confirmationCode } = this.state;
    // Check for undefined or empty input fields
    if (!confirmationCode) {
      this.setError('Please enter the confirmation code');
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { confirmSignUp, confirmationCodeError } = this.props;
    const { confirmationCode, loading } = this.state;
    return (
      <FullWidthContainer>
        <Form
          onSubmit={e => {
            e.preventDefault();
            if (this.validateForm()) {
              this.setState({ loading: true });
              confirmSignUp(confirmationCode);
            }
          }}
        >
          <FormGroup>
            <Label for="confirmationCode">Enter Confirmation Code</Label>
            <Input
              type="text"
              name="confirmationCode"
              id="confirmationCode"
              placeholder="Confirmation code"
              onChange={e =>
                this.setState({ confirmationCode: e.target.value })
              }
            />
          </FormGroup>
          <Button
            type="submit"
            label={!loading ? <span>Verify</span> : <Loader />}
            background={Colors.flumeGreen}
          />
          {this.state.error && (
            <p
              style={{
                textAlign: 'center',
                color: Colors.error,
                marginTop: '10px'
              }}
            >
              {this.state.error}
            </p>
          )}
          {confirmationCodeError && (
            <ErrorMessage errorMessage={confirmationCodeError.message} />
          )}
        </Form>
      </FullWidthContainer>
    );
  }
}

export default ConfirmationCodeForm;
