/* eslint jsx-a11y/alt-text: 0 */
import * as React from 'react';
import { Query } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import * as moment from 'moment';

/** GraphQL */
import { getBatchCardServicesAndCompanySpaces } from '../../graphql/custom-queries';
import { UpdateServiceInput, UpdateSpaceInput } from '../../API';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import { SelectedIcon } from '../../Components/Styled/ListViewElements';

/** Local component */
import CreateBatchCardAllocationForm from './CreateBatchCardAllocationForm';

/** Custom types */
import {
  BatchCard,
  BatchCardAllocationType,
  BatchCardAllocationsType,
  MessageNotificationType,
  SpaceType,
  SelectOptionsType,
  SelectType,
  ToastNotificationType,
  Error,
  ValueType
} from '../../CustomTypes';

/** Utils */
import * as Helpers from './BatchCardAllocationHelpers';

/** Themes */
import { Colors, ServiceIcons } from '../../Themes';

type BatchCardService = {
  id: string;
  service: UpdateServiceInput;
};

type Props = {
  batchCardId: string;
  currentWeek: Array<Date>;
  toastManager: ToastNotificationType;
  closeModal: () => void;
};

type State = {
  error: Error;
  batchCardAllocations: BatchCardAllocationsType;
};

const BatchCardAllocationError: React.FC = () => {
  return <div>There was a problem loading your batch card</div>;
};

class BatchCardAllocation extends React.Component<Props, State> {
  state = {
    error: null,
    batchCardAllocations: []
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Return company spaces as react select options
   * @param spaces - created company spaces
   */
  returnCompanySpaces = (
    spaces: Array<UpdateSpaceInput>
  ): SelectOptionsType => {
    if (spaces && spaces.length) {
      // Prepare to hold an array of spaces
      let selectOptions: any[] = [];
      // Filter only spaces that are active
      spaces.map((space: any) => {
        if (space.status === 'ACTIVE') {
          return selectOptions.push(space);
        }
        return {};
      });
      //  return list of active spaces to the select
      return selectOptions.map((space: UpdateSpaceInput) => {
        return {
          label: space.name || space.id,
          value: space.id,
          object: space
        };
      });
    }

    return [];
  };

  /** Return batch card services
   * @param batchCardServices - company services added to a batch card
   * @param date - the particular date on which services for the batch card are to be done
   */
  returnBatchCardServices = (
    batchCardServices: Array<BatchCardService>,
    date: Date
  ): React.ReactNode => {
    const { batchCardAllocations } = this.state;

    const activeBatchCardServices = Helpers.arrayOfActiveBatchCardServices(
      batchCardServices
    );
    if (activeBatchCardServices && activeBatchCardServices.length) {
      return activeBatchCardServices.map(
        (batchCardService: BatchCardService) => {
          return (
            <SelectedIcon
              largeImage={true}
              background={
                Helpers.checkIfServiceHasBeenSelected(
                  batchCardAllocations,
                  batchCardService.id,
                  moment(date).format('DD MMM YYYY')
                )
                  ? Colors.flumeGreen
                  : Colors.snow
              }
              key={batchCardService.service.id}
              onClick={() =>
                this.updateBatchCardAllocations(batchCardService.id, date)
              }
            >
              <img
                src={batchCardService.service.icon || ServiceIcons.Editing}
              />
            </SelectedIcon>
          );
        }
      );
    }
    return null;
  };

  /** Select space for a particular allocation
   * @param space - company space object
   * @param formattedDate - formatted date
   */
  selectSpace = (space: ValueType<SelectType>, formattedDate: string): void => {
    const { batchCardAllocations } = this.state;

    const updatedBatchCardAllocations: BatchCardAllocationsType = batchCardAllocations.map(
      (batchCardAllocation: BatchCardAllocationType) => {
        if (batchCardAllocation.date === formattedDate) {
          const { date, batchCardServiceIds } = batchCardAllocation;

          return {
            date,
            batchCardServiceIds,
            spaceId: space.value
          };
        }

        return batchCardAllocation;
      }
    );

    this.setState({ batchCardAllocations: updatedBatchCardAllocations });
  };

  /** Validate batch card allocations
   * Every batch card service should be allocated
   * Every allocation should have a selected space
   * @param batchCardServices - company services associated with a particular batch card
   */
  validateBatchCardAllocations = (
    batchCardServices: Array<BatchCardService>
  ): boolean => {
    const { batchCardAllocations } = this.state;
    const activeBatchCardServices = Helpers.arrayOfActiveBatchCardServices(
      batchCardServices
    );

    const allServicesAllocated: Array<number> = activeBatchCardServices.map(
      (batchCardService: BatchCardService) => {
        const allocation = batchCardAllocations.filter(
          (batchCardAllocation: BatchCardAllocationType) =>
            batchCardAllocation.batchCardServiceIds.find(
              (bcsId: string) => bcsId === batchCardService.id
            )
        );

        return allocation && allocation.length;
      }
    );

    // Get total allocated services
    const totalServicesAllocated = allServicesAllocated.reduce(
      (serviceCount1, serviceCount2) => serviceCount1 + serviceCount2
    );

    // First, check if all service are allocated
    if (totalServicesAllocated !== batchCardServices.length) {
      this.setError('Please allocate all services to a particular date');
      return false;
    }

    // Second, check that a space has been selected for each allocation
    const allocationsWithoutSpaces = batchCardAllocations.filter(
      (batchCardAllocation: BatchCardAllocationType) =>
        !batchCardAllocation.spaceId
    );

    if (allocationsWithoutSpaces && allocationsWithoutSpaces.length) {
      this.setError('Please select a space for each service allocation');
      return false;
    }

    return true;
  };

  /** Return days of week with batch card services and company spaces
   * @param batchCard - batch card created by a company
   * @param companySpaces - spaces created by a company
   */
  returnDaysOfWeekWithBatchCardServices = (
    batchCard: BatchCard,
    companySpaces: Array<SpaceType>
  ): React.ReactNode => {
    const { batchCardAllocations, error } = this.state;
    const { currentWeek, closeModal } = this.props;
    const batchCardServices: Array<UpdateServiceInput> =
      batchCard.services.items;
    const companySpaceOptions = this.returnCompanySpaces(companySpaces);

    return (
      <CreateBatchCardAllocationForm
        batchCardId={batchCard.id}
        batchCardServices={batchCardServices}
        batchCardAllocations={batchCardAllocations}
        closeModal={closeModal}
        companySpaces={companySpaceOptions}
        currentWeek={currentWeek}
        error={error}
        notification={this.toastNotification}
        returnBatchCardServices={this.returnBatchCardServices}
        selectSpace={this.selectSpace}
        validateBatchCardAllocations={this.validateBatchCardAllocations}
      />
    );
  };

  /** Add or update batch card allocations with the service selection and the date it's
   * been selected on
   * @param batchCardServiceId - id of the service associated with particular batch card
   * @param date - date on which the service is to be performed
   */
  updateBatchCardAllocations = (batchCardServiceId: string, date: Date) => {
    const { batchCardAllocations } = this.state;
    const formattedDate = moment(date).format('DD MMM YYYY');

    if (batchCardAllocations && batchCardAllocations.length) {
      const allocationWithThisService = batchCardAllocations.filter(
        (batchCardAllocation: BatchCardAllocationType) =>
          batchCardAllocation.batchCardServiceIds.find(
            (bcsId: string) => bcsId === batchCardServiceId
          )
      );
      if (allocationWithThisService && allocationWithThisService.length) {
        // Remove batch card service id from current allocation of batch card service ids
        const updatedBatchCardAllocations = Helpers.removeBatchCardServiceIdFromAllocation(
          batchCardAllocations,
          batchCardServiceId
        );

        if (
          Helpers.dateExistsInCurrentAllocations(
            batchCardAllocations,
            formattedDate
          )
        ) {
          // Existing date allocation
          const newBatchCardAllocations = Helpers.addServiceIdToExistingDate(
            batchCardAllocations,
            batchCardServiceId,
            formattedDate
          );

          this.setState({
            batchCardAllocations: Helpers.removeBatchCardAllocationsWithoutService(
              newBatchCardAllocations
            )
          });
        } else {
          // New date allocation
          this.setState({
            batchCardAllocations: [
              ...Helpers.removeBatchCardAllocationsWithoutService(
                updatedBatchCardAllocations
              ),
              { date: formattedDate, batchCardServiceIds: [batchCardServiceId] }
            ]
          });
        }
      } else {
        // New service id to be added to either an existing date or a new date
        if (
          Helpers.dateExistsInCurrentAllocations(
            batchCardAllocations,
            formattedDate
          )
        ) {
          // New service id on existing date allocation
          const updatedBatchCardAllocations = Helpers.addServiceIdToExistingDate(
            batchCardAllocations,
            batchCardServiceId,
            formattedDate
          );

          this.setState({
            batchCardAllocations: Helpers.removeBatchCardAllocationsWithoutService(
              updatedBatchCardAllocations
            )
          });
        } else {
          // New service on new date allocation
          this.setState({
            batchCardAllocations: [
              ...Helpers.removeBatchCardAllocationsWithoutService(
                batchCardAllocations
              ),
              { date: formattedDate, batchCardServiceIds: [batchCardServiceId] }
            ]
          });
        }
      }
    } else {
      this.setState({
        batchCardAllocations: [
          { date: formattedDate, batchCardServiceIds: [batchCardServiceId] }
        ]
      });
    }
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Success/error notification
   * @param message - message/text to be displayed on toast notification
   * @param appearance - the type of notification (success or error)
   */
  toastNotification = (
    message: string,
    appearance?: MessageNotificationType
  ) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const { batchCardId } = this.props;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <Query
              query={getBatchCardServicesAndCompanySpaces}
              variables={{ userId, batchCardId }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <Loader color={Colors.flumeGreen} />;
                }
                if (error) {
                  return <BatchCardAllocationError />;
                }

                if (!data || !data.getUser) {
                  return (
                    <div>There was a problem loading your company data</div>
                  );
                }

                const company = data.getUser.company;

                if (
                  company.batchCards &&
                  company.batchCards.items.length &&
                  company.spaces.items &&
                  company.spaces.items.length
                ) {
                  return (
                    <React.Fragment>
                      {this.returnDaysOfWeekWithBatchCardServices(
                        company.batchCards.items[0],
                        company.spaces.items
                      )}
                    </React.Fragment>
                  );
                }
                return <BatchCardAllocationError />;
              }}
            </Query>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(BatchCardAllocation);
