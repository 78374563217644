import * as React from 'react';
import { Query } from 'react-apollo';

/** GraphQL */
import { getCompanyBatchCardsByUserMainPlanner } from '../../graphql/custom-queries';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import { Wrapper } from '../../Components/Styled/MainPlanner';

/** Local components */
import MainPlannerSpaceWeekView from './MainPlannerSpaceWeekView';

/** Utils */
import { returnFormattedDates } from '../../Utils/Helpers';
import { Permission } from '../../API';

/** Custom types */
import { BatchCardServices } from '../../CustomTypes';

type Props = {
  currentWeek: Array<Date>;
  spaceId: string;
  spaceIds: Array<string>;
  userPermission: Permission | string;
  userId: string;
  openBatchCard(
    batchCardId: string,
    batchCardServices: Array<BatchCardServices>
  ): void;
};

const SpaceView: React.FC<Props> = ({
  currentWeek,
  openBatchCard,
  spaceId,
  spaceIds,
  userPermission,
  userId
}) => {
  const formattedDates = returnFormattedDates(currentWeek, 'DD MMM YYYY');
  return (
    <Query
      query={getCompanyBatchCardsByUserMainPlanner}
      variables={{
        id: userId,
        dayOne: formattedDates[0],
        dayTwo: formattedDates[1],
        dayThree: formattedDates[2],
        dayFour: formattedDates[3],
        dayFive: formattedDates[4],
        daySix: formattedDates[5],
        daySeven: formattedDates[6]
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <Wrapper>
              <Loader />
            </Wrapper>
          );
        }
        if (error || !data) {
          return (
            <ErrorMessage errorMessage="There was a problem loading company spaces" />
          );
        }

        return (
          <Wrapper>
            <MainPlannerSpaceWeekView
              currentWeek={currentWeek}
              data={data}
              openBatchCard={openBatchCard}
              spaceId={spaceId}
              spaceIds={spaceIds}
              userPermission={userPermission}
              userId={userId}
            />
          </Wrapper>
        );
      }}
    </Query>
  );
};

export default SpaceView;
