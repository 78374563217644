import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, FormGroup, Input, Tooltip } from 'reactstrap';

/** GraphQL */
import { updateService } from '../../graphql/mutations';
import { getCompanyServicesByUser } from '../../graphql/custom-queries';
import {
  UpdateServiceMutation,
  UpdateServiceMutationVariables
} from '../../API';

/** Custom Types */
import { Error } from '../../CustomTypes';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';
import {
  SelectedIconServiceModal,
  IconLibraryContainer
} from '../../Components/Styled/ListViewElements';
import GlobalModalContainer from '../../Components/Modal';
import ChooseServiceIconModal from './ChooseServiceIconModal';

/** Themes */
import { Colors, ServiceIcons } from '../../Themes';
import { ServiceType } from '../../CustomTypes';

type Props = {
  userId: string;
  companyId: string;
  service: ServiceType;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  id: string;
  name: string;
  icon: string;
  error: Error;
  dropdownOpen: boolean;
  renderTooltip: boolean;
  chooseIconModalOpen: boolean;
  modalOpenedOnce: boolean;
};

class EditServiceModal extends React.Component<Props, State> {
  private timeoutId: number = 0;
  private showTimeout: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.service.id || '',
      name: props.service.name || '',
      icon: props.service.icon,
      dropdownOpen: false,
      error: null,
      renderTooltip: false,
      chooseIconModalOpen: false,
      modalOpenedOnce: false
    };
  }

  componentDidMount() {
    /* Due to the animation in the reactstrap modal,
     the dom nodes position are not calculated properly if we render the tooltip 
     immidiately hence the timeout so as to render the tooltip after the animation is done
     */
    this.showTimeout = window.setTimeout(() => {
      this.setState({ renderTooltip: true });
    }, 800);
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
    window.clearTimeout(this.showTimeout);
  }

  /** Validation */
  validateForm = (): boolean => {
    // Check for undefined or empty input fields
    if (!this.state.name) {
      this.setError('Please enter a service name.');
      return false;
    }

    return true;
  };

  /** Toggle color picker dropdown */
  toggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Open modal */
  openIconSelectModal = (): void => {
    this.setState({
      chooseIconModalOpen: true,
      modalOpenedOnce: true
    });
  };

  /** Close modal */
  closeIconSelectModal = (): void => {
    this.setState({
      chooseIconModalOpen: false
    });
  };

  handleIconSelect = (icon: string) => {
    this.setState({ icon });
  };

  render() {
    const {
      id,
      name,
      icon,
      error,
      renderTooltip,
      chooseIconModalOpen,
      modalOpenedOnce
    } = this.state;
    const { notification, closeModal, userId } = this.props;
    const serviceIcons = Object.keys(ServiceIcons).map(
      key => ServiceIcons[key]
    );

    return (
      <Mutation<UpdateServiceMutation, UpdateServiceMutationVariables>
        mutation={gql(updateService)}
      >
        {(updateServiceMutation, { loading }) => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateForm()) {
                updateServiceMutation({
                  variables: {
                    input: {
                      id,
                      name,
                      icon
                    }
                  },
                  refetchQueries: [
                    {
                      query: getCompanyServicesByUser,
                      variables: {
                        id: userId
                      }
                    }
                  ]
                })
                  .then(res => {
                    closeModal();
                    notification('Service updated successfully');
                  })
                  .catch(err => {
                    this.setError(err.message);
                  });
              }
            }}
          >
            <GlobalModalContainer
              toggleModal={this.closeIconSelectModal}
              mediumModal={true}
              title="Choose an icon for your new service"
              modalDisplay={
                <ChooseServiceIconModal
                  handleIconSelect={this.handleIconSelect}
                  serviceIcons={serviceIcons}
                  activeIcon={icon}
                />
              }
              modal={chooseIconModalOpen}
            />
            <Row>
              <Col xs={12} md={10} lg={10}>
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    id="name"
                    placeholder="Service name"
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={2} lg={2}>
                <FormGroup>
                  <IconLibraryContainer onClick={this.openIconSelectModal}>
                    <div>
                      {renderTooltip ? (
                        <Tooltip
                          placement="top"
                          isOpen={!modalOpenedOnce}
                          target="iconSelect"
                          style={{
                            backgroundColor: Colors.sirocco
                          }}
                        >
                          Choose icon here
                        </Tooltip>
                      ) : null}
                      <SelectedIconServiceModal
                        id="iconSelect"
                        background={Colors.mystic}
                      >
                        <img alt="icon" src={icon} />
                      </SelectedIconServiceModal>
                    </div>
                  </IconLibraryContainer>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <StyledButton
                    type="submit"
                    label={!loading ? 'Update' : <Loader />}
                    color={Colors.flumeDarkGreen}
                    background={Colors.flumeGreen}
                  />
                </FormGroup>
              </Col>
            </Row>
            {error && <ErrorMessage errorMessage={error} />}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default EditServiceModal;
