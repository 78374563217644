import * as React from 'react';
import { Query } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';

/** GraphQL */
import { getCompanyBatchCardByUser } from '../../graphql/custom-queries';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';

/** Local Components */
import SavedBatchCard from './SavedBatchCard';
import QCBatchCard from './QCBatchCard';

/** Custom Types */
import { ToastNotificationType, BatchCardServices } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

type Props = {
  batchCardId: string;
  qualityAssurance?: boolean;
  toastManager: ToastNotificationType;
  userId: string;
  currentWeek: Array<Date>;
  closeModal: () => void;
  batchCardServices: Array<BatchCardServices>;
};

type State = {
  modal: boolean;
};

class BatchCardInProgress extends React.Component<Props, State> {
  /** Success notification
   * @param message - message to be displayed on notification
   * @param apearance - appearance based on type of message (success/error)
   */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const {
      batchCardId,
      closeModal,
      qualityAssurance,
      userId,
      currentWeek,
      batchCardServices
    } = this.props;

    return (
      <Query
        query={getCompanyBatchCardByUser}
        variables={{ userId, batchCardId }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <Loader color={Colors.flumeGreen} />;
          }

          if (error || !data || !data.getUser) {
            return (
              <ErrorMessage errorMessage="There was a problem loading the batch card" />
            );
          }

          const { batchCards, itemTemplate } = data.getUser.company;

          if (batchCards && batchCards.items.length) {
            return qualityAssurance ? (
              <QCBatchCard
                batchCard={batchCards.items[0]}
                itemTemplate={itemTemplate}
                notification={this.toastNotification}
                closeModal={closeModal}
                userId={userId}
              />
            ) : (
              <SavedBatchCard
                batchCardServices={batchCardServices}
                currentWeek={currentWeek}
                batchCard={batchCards.items[0]}
                itemTemplate={itemTemplate}
                notification={this.toastNotification}
                closeModal={closeModal}
                userId={userId}
              />
            );
          }

          return (
            <ErrorMessage errorMessage="There was a problem loading the batch card" />
          );
        }}
      </Query>
    );
  }
}

export default withToastManager(BatchCardInProgress);
