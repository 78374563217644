import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, NavItem, Tooltip } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

/** Context API */
import { AppContextConsumer } from '../ContextProvider';

/** Custom types */
import { SubMenuNavItem } from '../../CustomTypes';

/** Utils */
import { getArrayFromObject } from '../../Utils/Helpers';
import { SUBSCRIPTION_PACKAGES } from '../../Utils/Consts';

/** Themes */
import { Colors } from '../../Themes';

type Props = RouteComponentProps & {
  navId: string;
  to: string;
  title: string;
  icon: React.ReactNode;
  subMenu?: Array<SubMenuNavItem>;
  subscriptionPlans?: Array<SUBSCRIPTION_PACKAGES | string>;
};

class SideBarNavItem extends React.Component<
  Props,
  { tooltipOpen: boolean; expanded: boolean }
> {
  state = {
    expanded: false,
    tooltipOpen: false
  };

  componentDidMount() {
    const {
      subMenu,
      location: { pathname }
    } = this.props;

    if (getArrayFromObject(subMenu) && getArrayFromObject(subMenu).length) {
      if (
        getArrayFromObject(subMenu).some(
          (path: { to: string; title: string }) =>
            (path.to as string) === pathname
        )
      ) {
        // we are in the this route so we open submenu
        this.setState({ expanded: true });
      }
    }
  }

  checkIfActive = (path: string): boolean => {
    const {
      subMenu,
      location: { pathname }
    } = this.props;

    /**
     * Check if this nav item has a sub menu, and if it does, check
     * if the link is active
     */
    if (subMenu) {
      return getArrayFromObject(subMenu).some(
        (subMenuPath: { to: string; title: string }) =>
          (subMenuPath.to as string) === pathname
      );
    }

    if (path === pathname) {
      return true;
    }
    return false;
  };

  // Toggle tooltip
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  // Toggle sub menu
  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  // Return sub menu
  renderSubMenu = (subMenu: Array<SubMenuNavItem>): React.ReactNode => {
    const { location } = this.props;

    return subMenu.map((navItem: SubMenuNavItem) => {
      return (
        <NavItem key={navItem.to}>
          <NavLink
            to={navItem.to}
            style={
              navItem.to === location.pathname ? { color: Colors.snow } : {}
            }
          >
            <span>- {navItem.title}</span>
          </NavLink>
        </NavItem>
      );
    });
  };

  render() {
    const { navId, to, title, icon, subMenu } = this.props;
    const { expanded } = this.state;
    const isReportingPath = to.includes('/reporting');
    const activeLinkColor = isReportingPath ? Colors.snow : Colors.flumeGreen;

    return (
      <AppContextConsumer>
        {({ drawerOpen }) => {
          const sideBarOpen = drawerOpen;
          return (
            <React.Fragment>
              <NavItem
                id={'Tooltip-' + navId.split('& ').join('')}
                onClick={this.toggleExpanded}
              >
                <NavLink
                  to={to}
                  style={
                    this.checkIfActive(to) ? { color: activeLinkColor } : {}
                  }
                >
                  {icon}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>{title}</span>
                </NavLink>
                {!sideBarOpen && (
                  <Tooltip
                    placement="right"
                    className="nav-tooltip"
                    isOpen={this.state.tooltipOpen}
                    target={'Tooltip-' + navId.split('& ').join('')}
                    toggle={this.toggle}
                  >
                    {title}
                  </Tooltip>
                )}
              </NavItem>
              <Collapse isOpen={expanded} style={{ width: '100%' }}>
                {subMenu && this.renderSubMenu(subMenu)}
              </Collapse>
            </React.Fragment>
          );
        }}
      </AppContextConsumer>
    );
  }
}

export default withRouter(SideBarNavItem);
