import * as React from 'react';
import * as moment from 'moment';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { withToastManager } from 'react-toast-notifications';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Presentation/UI */
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import GlobalModalContainer from '../../Components/Modal';

/** Local components */
import Backlog from './Backlog';
import BatchCardAllocation from './BatchCardAllocation';
import ContainerForViews from './ContainerForViews';
import MoveBatchCardBackToBacklog from './MoveBatchCardBackToBacklog';
import SpaceBatchCardAllocation from './SpaceBatchCardAllocation';

/** Custom types */
import { ToastNotificationType } from '../../CustomTypes';

/** Utils */
import {
  MAIN_PLANNER_VIEWS,
  BATCH_TYPE,
  SUPER_USER_GROUP
} from '../../Utils/Consts';
import { generateWeek } from '../../Utils/Helpers';

const MainPlannerContainer = styled.div`
  display: inline-flex;
  position: absolute;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

type State = {
  batchCardId: string;
  batchCardAllocationId: string;
  backlogModal: boolean;
  currentView: string;
  currentWeek: Array<Date>;
  defaultSpace: string;
  date: moment.Moment | null;
  focused: boolean;
  modal: boolean;
  spaceModal: boolean;
};

type Props = {
  toastManager: ToastNotificationType;
};

class MainPlanner extends React.Component<Props, State> {
  state = {
    currentView: MAIN_PLANNER_VIEWS.calendar,
    batchCardId: '',
    batchCardAllocationId: '',
    defaultSpace: '',
    currentWeek: [],
    date: null,
    modal: false,
    backlogModal: false,
    spaceModal: false,
    focused: false
  };

  componentDidMount() {
    const currentWeek = this.returnWeek(null);
    this.setState({ currentWeek });
  }

  /** Close calendar view modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  /** Open calendar view  modal */
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  /** Close space view modal */
  closeSpaceModal = (): void => {
    this.setState({
      spaceModal: false
    });
  };

  /** Open space view  modal */
  openSpaceModal = (): void => {
    this.setState({
      spaceModal: true
    });
  };

  /** Close backlog view modal */
  closeBacklogModal = (): void => {
    this.setState({
      backlogModal: false
    });
  };

  /** Open backlog view  modal */
  openBacklogModal = (): void => {
    this.setState({
      backlogModal: true
    });
  };

  /**
   * Update the current view
   * @param currentView - the new current view (calendar or space)
   */
  updateCurrentView = (currentView: string): void => {
    this.setState({ currentView });
  };

  returnWeek = (chosenDate: moment.Moment | null): Array<Date> => {
    const startOfWeek = moment().startOf('isoWeek');
    const weekArray = moment.weekdays();

    return generateWeek(startOfWeek, weekArray);
  };

  /** Toggle calendar to next week */
  nextWeek = () => {
    const { currentWeek } = this.state;
    const endOfCurrentWeek = moment(currentWeek[0]).endOf('isoWeek');
    const startOfNewWeek = endOfCurrentWeek.add(1, 'days');

    const weekArray = moment.weekdays();
    const daysOfNextWeek = weekArray.map((d, i) => {
      return startOfNewWeek
        .clone()
        .add(i, 'd')
        .toDate();
    });

    this.setState({ currentWeek: daysOfNextWeek });
  };

  /** Toggle calendar to previous week */
  prevWeek = () => {
    const { currentWeek } = this.state;
    const startOfCurrentWeek = moment(currentWeek[0]).startOf('isoWeek');
    const startOfPreviousWeek = startOfCurrentWeek.subtract(7, 'days');
    const weekArray = moment.weekdays();

    this.setState({
      currentWeek: generateWeek(startOfPreviousWeek, weekArray)
    });
  };

  /** Update calendar to the week of a selected date
   * @param date - the chosen date
   */
  updateCurrentWeekByDate = (date: Date | null) => {
    if (date) {
      const startOfWeek = moment(date).startOf('isoWeek');
      const weekArray = moment.weekdays();

      this.setState({ currentWeek: generateWeek(startOfWeek, weekArray) });
    }
  };

  /** On drag end, check if the object being dragged is a batch card or a batch card
   * allocation. Batch cards can be dragged into either the space view or the calendar view.
   * Batch card allocations can only be dragged back into backlog.
   * @param result - object with information on the draggable object
   * including its ID, and the details of its source, destination.
   */
  onDragEnd = (result: DropResult) => {
    const batch: Array<string> = result.draggableId.split('_');
    const batchType: string = batch[0];
    const id: string = batch[1];

    const dragEndView: string | null = result.destination
      ? result.destination.droppableId
      : null;

    if (dragEndView) {
      // Check the current view to determine which modal to open
      if (
        dragEndView.split('_') &&
        dragEndView.split('_')[0] === MAIN_PLANNER_VIEWS.space
      ) {
        // Open space view modal if it is a batch card being dragged and dropped (not a batch card allocation)
        if (batchType === BATCH_TYPE.card) {
          this.setState({
            spaceModal: true,
            defaultSpace: dragEndView.split('_')[1],
            batchCardId: id
          });
        }
      } else if (
        dragEndView.split('_') &&
        dragEndView.split('_')[0] === MAIN_PLANNER_VIEWS.calendar
      ) {
        // Open calendar view modal if it is a batch card being dragged and droppped (not a batch card allocation)
        if (batchType === BATCH_TYPE.card) {
          this.setState({ modal: true, batchCardId: id });
        }
      } else if (dragEndView === 'backlog') {
        // Ask user if they want to move a batch card back to Queue
        if (batchType === BATCH_TYPE.allocation) {
          this.setState({ backlogModal: true, batchCardAllocationId: id });
        }
      }
    }
  };

  /** Success notification
   * @param message - message on notification
   * @param appearance - type of message (success/error)
   */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const {
      batchCardAllocationId,
      batchCardId,
      backlogModal,
      currentView,
      currentWeek,
      date,
      defaultSpace,
      focused,
      modal,
      spaceModal
    } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ spaceId, spaceIds, userPermission, userRole }) => {
          return (
            <BackendWrapper>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <MainPlannerContainer>
                  <GlobalModalContainer
                    toggleModal={this.closeModal}
                    title="Select Services &amp; Spaces"
                    modalDisplay={
                      <BatchCardAllocation
                        currentWeek={currentWeek}
                        batchCardId={batchCardId}
                        closeModal={this.closeModal}
                      />
                    }
                    modal={modal}
                  />
                  <GlobalModalContainer
                    toggleModal={this.closeSpaceModal}
                    largeModal={true}
                    title="Select Services &amp; Spaces"
                    modalDisplay={
                      <SpaceBatchCardAllocation
                        batchCardId={batchCardId}
                        closeModal={this.closeSpaceModal}
                        defaultSpace={defaultSpace}
                      />
                    }
                    modal={spaceModal}
                  />
                  <GlobalModalContainer
                    toggleModal={this.closeBacklogModal}
                    title="Move Batch Card back to Queue"
                    modalDisplay={
                      <MoveBatchCardBackToBacklog
                        notification={this.toastNotification}
                        batchCardAllocationId={batchCardAllocationId}
                        closeModal={this.closeBacklogModal}
                      />
                    }
                    modal={backlogModal}
                  />
                  {userRole === SUPER_USER_GROUP && <Backlog />}

                  <ContainerForViews
                    calFocused={focused}
                    currentView={currentView}
                    currentWeek={currentWeek}
                    date={date}
                    nextWeek={this.nextWeek}
                    prevWeek={this.prevWeek}
                    spaceId={spaceId}
                    spaceIds={spaceIds}
                    userPermission={userPermission}
                    updateCurrentView={this.updateCurrentView}
                    updateCurrentWeekByDate={this.updateCurrentWeekByDate}
                  />
                </MainPlannerContainer>
              </DragDropContext>
              <Helmet title="Planner" />
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(MainPlanner);
