import * as React from 'react';
import { Link } from 'react-router-dom';

type IProps = {
  label: string | React.ReactNode;
  to: string;
  color?: string;
  size?: string;
};

const LinkWrapper: React.SFC<IProps> = (props: IProps) => {
  return (
    <Link
      to={props.to}
      style={{
        color: props.color,
        fontSize: props.size,
        textDecoration: 'none'
      }}
    >
      {props.label}
    </Link>
  );
};

export default LinkWrapper;
