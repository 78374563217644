import * as React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

/** Presentation/UI */
import GlobalModalContainer from '../../Components/Modal';

/** Generated types */
import { UpdateProductItemInput } from '../../API';

/** Local component */
import DeleteProductModal from './DeleteProductModal';

type Props = {
  previousTagDescriptions: Array<string>;
  productItem: UpdateProductItemInput;
  notification(message: string, appearance?: string): void;
  openModal(productItem: UpdateProductItemInput): void;
  openTagsModal(previousTagDescriptions: Array<string>): void;
};

const RowActions: React.FC<Props> = ({
  openModal,
  openTagsModal,
  previousTagDescriptions,
  productItem,
  notification
}) => {
  const [dropdownOpen, toggleDropdown] = React.useState(false);
  const [modal, toggleModal] = React.useState(false);

  return (
    <React.Fragment>
      <GlobalModalContainer
        toggleModal={() => toggleModal(false)}
        title="Delete product item"
        modalDisplay={
          <DeleteProductModal
            productItemId={productItem.id}
            notification={notification}
            updateDialogModal={() => toggleModal(!modal)}
          />
        }
        modal={modal}
      />
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => toggleDropdown(!dropdownOpen)}
        style={{ position: 'absolute' }}
      >
        <DropdownToggle
          tag="span"
          onClick={() => toggleDropdown(!dropdownOpen)}
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
        </DropdownToggle>
        <DropdownMenu>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openModal(productItem)}
          >
            Edit Item
          </div>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openTagsModal(previousTagDescriptions)}
          >
            View Tags
          </div>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => toggleModal(true)}
          >
            Delete Item
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default RowActions;
