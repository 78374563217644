import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// Components
import PageWrapper from '../../Components/Layouts/PageWrapper';
import LoginPanel from '../../Components/Layouts/LoginPanel';
import Logo from '../../Components/Layouts/LogoWrapper';

// Local Component
import LoginForm from './LoginForm';

// Themes
import { Images } from '../../Themes';

export default class Login extends React.Component {
  render() {
    return (
      <PageWrapper background={Images.landingBackground} overlay={true}>
        <LoginPanel>
          <React.Fragment>
            <Link to="/">
              <Logo image={Images.flumeLogo} />
            </Link>
            <LoginForm />
          </React.Fragment>
        </LoginPanel>
        <Helmet title="Sign In" />
      </PageWrapper>
    );
  }
}
