import { apiRequest } from '../../../Utils/API';
import { HTTP_METHODS } from '../../../Utils/Consts';
import { CANCEL_CUSTOMER_SUBSCRIPTIONS } from '../../../Utils/LambdaEndpoints';

/** Cancel a company's subscription plan and disable its account
 * @param companyId - company id
 * @param subscriptionId - subscription id
 * @param updateCompanyMutation - mutation to update company details
 * @param setLoading - function to update loading state value
 * @param setError - function to update error state value
 */
export const cancelSubscriptionAndDisableAccount = async (
  companyId: string,
  subscriptionId: string,
  updateCompanyMutation: ({}) => Promise<any>, // eslint-disable-line
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: (error: string | null) => void
) => {
  try {
    setLoading(true);

    // Cancel subscription in Stripe
    await apiRequest(CANCEL_CUSTOMER_SUBSCRIPTIONS, HTTP_METHODS.POST, {
      subscriptionId
    });
    // Disable company account
    await updateCompanyMutation({
      variables: {
        input: {
          id: companyId,
          accountDisabled: true
        }
      }
    });

    setLoading(false);
  } catch (e) {
    setLoading(false);
    setError(e.message);
  }
};
