import * as React from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import Select from 'react-select';
import { Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import * as moment from 'moment';

/** GraphQL */
import { createProductItem } from '../../graphql/mutations';
import { CreateItemTemplateInput } from '../../API';
import {
  getCompanyProductItemsByUser,
  getCompanyProductItemsByUserAndUic
} from '../../graphql/custom-queries';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Themes */
import { Colors } from '../../Themes';

/** Custom Types */
import {
  ProductItemType,
  BrandType,
  SubBrandType,
  Error,
  ModalWithMessageType,
  UserAndCompanyId
} from '../../CustomTypes';

/** Utils */
import { GENDER, APOLLO_FETCH_POLICY } from '../../Utils/Consts';
import { sortDropDownDataAlphabetically } from '../../Utils/Helpers';

type SelectType = { value: string; label: string };

type Props = UserAndCompanyId &
  ModalWithMessageType & {
    itemTemplate: CreateItemTemplateInput;
    brands: Array<BrandType>;
  };

type State = ProductItemType & {
  brands: Array<SelectType>;
  error: Error;
  loading: boolean;
};

class AddProductModal extends React.Component<WithApolloClient<Props>, State> {
  timeoutId: number = 0;
  constructor(props: WithApolloClient<Props>) {
    super(props);
    this.state = {
      uic: '',
      category: '',
      subCategory: '',
      type: '',
      gender: '',
      age: '',
      size: '',
      look: '',
      other1: '',
      other2: '',
      other3: '',
      season: '',
      collectionName: '',
      productName: '',
      clientName: '',
      serviceName: '',
      unit: '',
      quantity: '',
      productItemBrandId: '',
      productItemSubBrandId: '',
      brands: this.returnCompanyBrands(props.brands),
      error: null,
      loading: false
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Return company product brands
   * @param brands - created company brands
   */
  returnCompanyBrands = (brands?: Array<BrandType>): Array<SelectType> => {
    if (brands && brands.length) {
      return sortDropDownDataAlphabetically(
        brands.map((brand: BrandType) => {
          return {
            value: brand.id,
            label: brand.name
          };
        })
      );
    }
    return [];
  };

  /** Return sub brands of selected brand  */
  returnSubBrandsOfSelectedBrand = (
    selectedBrandId?: string
  ): Array<SelectType> => {
    const { brands } = this.props;
    if (selectedBrandId) {
      const filteredBrands: Array<BrandType> = brands.filter(
        pBrand => pBrand.id === selectedBrandId
      );
      if (
        filteredBrands &&
        filteredBrands[0] &&
        filteredBrands[0].subBrands &&
        filteredBrands[0].subBrands.items
      ) {
        const subBrands = filteredBrands[0].subBrands.items;
        return subBrands
          ? sortDropDownDataAlphabetically(
              subBrands.map((subBrand: SubBrandType) => {
                return {
                  value: subBrand.id,
                  label: subBrand.name
                };
              })
            )
          : [];
      }
    }

    return [];
  };

  /** Select gender
   * @param gender - gender for product item
   */
  selectGender = (gender: SelectType): void => {
    this.setState({ gender: gender.value });
  };

  /** Select brand
   * @param brand - company brand related to the product item
   */
  selectBrand = (brand: SelectType): void => {
    this.setState({ productItemBrandId: brand.value });
  };

  /** Select sub brand
   * @param subBrand - company sub brand related to the product item brand
   */
  selectSubBrand = (subBrand: SelectType): void => {
    this.setState({ productItemSubBrandId: subBrand.value });
  };

  /**
   * Return object without empty string properties
   * DynamoDB does not support empty string properties, so
   * the input being passed in the mutation must only
   * include properties with values
   */
  removeEmptyStringElements = () => {
    const { companyId } = this.props;

    const productItemMutationInput = {
      createdAt: moment().toISOString(),
      uic: this.state.uic,
      category: this.state.category,
      subCategory: this.state.subCategory,
      type: this.state.type,
      gender: this.state.gender,
      age: this.state.age,
      size: this.state.size,
      look: this.state.look,
      other1: this.state.other1,
      other2: this.state.other2,
      other3: this.state.other3,
      season: this.state.season,
      collectionName: this.state.collectionName,
      productName: this.state.productName,
      clientName: this.state.clientName,
      serviceName: this.state.serviceName,
      unit: this.state.unit,
      quantity: this.state.quantity,
      productItemBrandId: this.state.productItemBrandId,
      productItemSubBrandId: this.state.productItemSubBrandId,
      productItemCompanyId: companyId
    };
    for (const prop in productItemMutationInput) {
      if (productItemMutationInput[prop] === '') {
        delete productItemMutationInput[prop];
      }
    }
    return productItemMutationInput;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const {
      uic,
      category,
      subCategory,
      type,
      age,
      size,
      look,
      other1,
      other2,
      other3,
      season,
      collectionName,
      productName,
      clientName,
      serviceName,
      unit,
      quantity,
      productItemBrandId,
      brands,
      error,
      loading
    } = this.state;
    const { userId, itemTemplate, notification, closeModal } = this.props;

    return (
      <Form
        onSubmit={e => {
          e.preventDefault();
          const input = this.removeEmptyStringElements();

          this.setState({ loading: true });
          this.props.client
            .query({
              query: getCompanyProductItemsByUserAndUic,
              variables: {
                userId,
                uic: input.uic
              },
              fetchPolicy: APOLLO_FETCH_POLICY.NETWORK_ONLY
            })
            .then((result: any) => {
              if (!result && error) {
                this.setError('An error occured.');
              }

              if (
                result &&
                result.data &&
                result.data.getUser.company.productItems.items.length > 0
              ) {
                this.setError('UIC code already exists.');
                this.setState({ loading: false });
              } else {
                // Create product item
                this.props.client
                  .mutate({
                    variables: {
                      input
                    },
                    mutation: gql(createProductItem),
                    refetchQueries: [
                      {
                        query: getCompanyProductItemsByUser,
                        variables: {
                          id: userId
                        }
                      }
                    ]
                  })
                  .then((res: any) => {
                    notification('Product saved successfully', 'success');
                    closeModal();
                  })
                  .catch(err => {
                    this.setError(err.message);
                  });
              }
            })
            .catch(error => {
              this.setError('An error occured.');
            });
        }}
      >
        {itemTemplate.uic && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="uic">Unique Identifier Code (UIC)</Label>
                <Input
                  type="text"
                  name="uic"
                  value={uic}
                  id="uic"
                  placeholder="Unique Identifier Code (UIC)"
                  onChange={e => this.setState({ uic: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.brand && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="brand">Brand</Label>
                <Select
                  // @ts-ignore
                  onChange={this.selectBrand}
                  options={brands}
                  isSearchable={true}
                  className="select-styling"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.subBrand && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="subBrand">Sub Brand</Label>
                <Select
                  // @ts-ignore
                  onChange={this.selectSubBrand}
                  options={this.returnSubBrandsOfSelectedBrand(
                    productItemBrandId
                  )}
                  isSearchable={true}
                  className="select-styling"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.clientName && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="clientName">Client Name</Label>
                <Input
                  type="text"
                  name="clientName"
                  value={clientName}
                  id="clientName"
                  placeholder="Client Name"
                  onChange={e => this.setState({ clientName: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.productName && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="productName">Product Name</Label>
                <Input
                  type="text"
                  name="productName"
                  value={productName}
                  id="productName"
                  placeholder="Product Name"
                  onChange={e => this.setState({ productName: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.serviceName && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="serviceName">Service Name</Label>
                <Input
                  type="text"
                  name="serviceName"
                  value={serviceName}
                  id="serviceName"
                  placeholder="Service Name"
                  onChange={e => this.setState({ serviceName: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.category && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="category">Category</Label>
                <Input
                  type="text"
                  name="category"
                  value={category}
                  id="category"
                  placeholder="Category"
                  onChange={e => this.setState({ category: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.subCategory && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="subCategory">Sub Category</Label>
                <Input
                  type="text"
                  name="subCategory"
                  value={subCategory}
                  id="subCategory"
                  placeholder="Sub Category"
                  onChange={e => this.setState({ subCategory: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          {itemTemplate.type && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="type">Type</Label>
                <Input
                  type="text"
                  name="type"
                  value={type}
                  id="type"
                  placeholder="Type"
                  onChange={e => this.setState({ type: e.target.value })}
                />
              </FormGroup>
            </Col>
          )}
          {itemTemplate.gender && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <Select
                  // @ts-ignore
                  onChange={this.selectGender}
                  options={sortDropDownDataAlphabetically(GENDER)}
                  isSearchable={true}
                  className="select-styling"
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          {itemTemplate.age && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="age">Age</Label>
                <Input
                  type="text"
                  name="age"
                  value={age}
                  id="age"
                  placeholder="Age"
                  onChange={e => this.setState({ age: e.target.value })}
                />
              </FormGroup>
            </Col>
          )}
          {itemTemplate.size && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="size">Size</Label>
                <Input
                  type="text"
                  name="size"
                  value={size}
                  id="size"
                  placeholder="Size"
                  onChange={e => this.setState({ size: e.target.value })}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          {itemTemplate.look && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="look">Look</Label>
                <Input
                  type="text"
                  name="look"
                  value={look}
                  id="look"
                  placeholder="Look"
                  onChange={e => this.setState({ look: e.target.value })}
                />
              </FormGroup>
            </Col>
          )}
          {itemTemplate.season && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="season">Season</Label>
                <Input
                  type="text"
                  name="season"
                  value={season}
                  id="season"
                  placeholder="Season"
                  onChange={e => this.setState({ season: e.target.value })}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        {itemTemplate.other1 && (
          <Row>
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="other1">Other 1</Label>
                <Input
                  type="text"
                  name="other1"
                  value={other1}
                  id="other1"
                  placeholder="Other 1"
                  onChange={e => this.setState({ other1: e.target.value })}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="other2">Other 2</Label>
                <Input
                  type="text"
                  name="other2"
                  value={other2}
                  id="other2"
                  placeholder="Other 2"
                  onChange={e => this.setState({ other2: e.target.value })}
                />
              </FormGroup>
            </Col>
            {itemTemplate.other3 && (
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label for="other3">Other 3</Label>
                  <Input
                    type="text"
                    name="other3"
                    value={other3}
                    id="other3"
                    placeholder="Other 3"
                    onChange={e => this.setState({ other3: e.target.value })}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        )}
        {itemTemplate.collectionName && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="collectionName">Collection Name</Label>
                <Input
                  type="text"
                  name="collectionName"
                  value={collectionName}
                  id="collectionName"
                  placeholder="Collection Name"
                  onChange={e =>
                    this.setState({ collectionName: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.quantity && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="text"
                  name="quantity"
                  value={quantity}
                  id="quantity"
                  placeholder="Quantity"
                  onChange={e => this.setState({ quantity: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.unit && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="unit">Unit</Label>
                <Input
                  type="text"
                  name="unit"
                  value={unit}
                  id="unit"
                  placeholder="Unit"
                  onChange={e => this.setState({ unit: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <br />
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <StyledButton
                type="submit"
                label={loading ? <Loader /> : 'Save'}
                color={Colors.flumeDarkGreen}
                background={Colors.flumeGreen}
              />
            </FormGroup>
          </Col>
        </Row>
        {error && <ErrorMessage errorMessage={error} />}
      </Form>
    );
  }
}

export default withApollo(AddProductModal);
