import * as React from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import Select from 'react-select';
import { Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';

/** GraphQL */
import { updateProductItem } from '../../graphql/mutations';
import {
  CreateItemTemplateInput,
  UpdateProductItemInput,
  UpdateBrandInput
} from '../../API';
import {
  getCompanyProductItemsByUser,
  getCompanyProductItemsByUserAndUic
} from '../../graphql/custom-queries';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Themes */
import { Colors } from '../../Themes';

/** Custom Types */
import {
  ProductItemType,
  BrandType,
  SubBrandType,
  SelectType,
  Error,
  ModalWithMessageType,
  UserAndCompanyId
} from '../../CustomTypes';

/** Utils */
import { GENDER, APOLLO_FETCH_POLICY } from '../../Utils/Consts';
import {
  selectedOption,
  sortDropDownDataAlphabetically
} from '../../Utils/Helpers';

type Props = UserAndCompanyId &
  ModalWithMessageType & {
    itemTemplate: CreateItemTemplateInput;
    brands: Array<BrandType>;
    product: UpdateProductItemInput & {
      brand?: UpdateBrandInput;
    };
  };

type State = ProductItemType & {
  productId: string;
  brands: Array<SelectType>;
  error: Error;
  invalidInput: boolean;
  loading: boolean;
  oldUic: string;
};

class EditProductModal extends React.Component<WithApolloClient<Props>, State> {
  timeoutId: number = 0;
  constructor(props: WithApolloClient<Props>) {
    super(props);
    this.state = {
      productId: props.product.id || '',
      uic: props.product.uic || '',
      oldUic: props.product.uic || '',
      category: props.product.category || '',
      subCategory: props.product.subCategory || '',
      type: props.product.type || '',
      gender: props.product.gender || '',
      age: props.product.age || '',
      size: props.product.size || '',
      look: props.product.look || '',
      other1: props.product.other1 || '',
      other2: props.product.other2 || '',
      other3: props.product.other2 || '',
      season: props.product.season || '',
      collectionName: props.product.collectionName || '',
      productName: props.product.productName || '',
      clientName: props.product.clientName || '',
      serviceName: props.product.serviceName || '',
      unit: props.product.unit || '',
      quantity: props.product.quantity || '',
      productItemBrandId: props.product.brand ? props.product.brand.id : '',
      productItemSubBrandId: '',
      brands: this.returnCompanyBrands(props.brands),
      error: null,
      invalidInput: false,
      loading: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Return company product brands
   * @param brands - created company brands
   */
  returnCompanyBrands = (brands?: Array<BrandType>): Array<SelectType> => {
    if (brands && brands.length) {
      return brands.map((brand: BrandType) => {
        return {
          value: brand.id,
          label: brand.name
        };
      });
    }
    return [];
  };

  /** Return sub brands of selected brand
   * @param selectedBrandId - id of the selected brand
   */
  returnSubBrandsOfSelectedBrand = (
    selectedBrandId?: string
  ): Array<SelectType> => {
    const { brands } = this.props;
    if (selectedBrandId) {
      const filteredBrands: Array<BrandType> = brands.filter(
        pBrand => pBrand.id === selectedBrandId
      );
      if (
        filteredBrands &&
        filteredBrands[0] &&
        filteredBrands[0].subBrands &&
        filteredBrands[0].subBrands.items
      ) {
        const subBrands = filteredBrands[0].subBrands.items;
        return subBrands
          ? sortDropDownDataAlphabetically(
              subBrands.map((subBrand: SubBrandType) => {
                return {
                  value: subBrand.id,
                  label: subBrand.name
                };
              })
            )
          : [];
      }
    }

    return [];
  };

  /** Select gender
   * @param gender - gender for product item
   */
  selectGender = (gender: SelectType): void => {
    this.setState({ gender: gender.value });
  };

  /** Select brand
   * @param brand - company brand related to the product item
   */
  selectBrand = (brand: SelectType): void => {
    this.setState({ productItemBrandId: brand.value });
  };

  /** Select sub brand
   * @param subBrand - company sub brand related to the product item brand
   */
  selectSubBrand = (subBrand: SelectType): void => {
    this.setState({ productItemSubBrandId: subBrand.value });
  };

  /**
   * Return object without empty string properties
   * DynamoDB does not support empty string properties, so
   * the input being passed in the mutation must only
   * include properties with values
   */
  removeEmptyStringElements = () => {
    const { companyId } = this.props;

    const productItemMutationInput = {
      id: this.state.productId,
      uic: this.state.uic,
      category: this.state.category,
      subCategory: this.state.subCategory,
      type: this.state.type,
      gender: this.state.gender,
      age: this.state.age,
      size: this.state.size,
      look: this.state.look,
      other1: this.state.other1,
      other2: this.state.other2,
      other3: this.state.other3,
      season: this.state.season,
      collectionName: this.state.collectionName,
      productName: this.state.productName,
      clientName: this.state.clientName,
      serviceName: this.state.serviceName,
      unit: this.state.unit,
      quantity: this.state.quantity,
      productItemBrandId: this.state.productItemBrandId,
      productItemSubBrandId: this.state.productItemSubBrandId,
      productItemCompanyId: companyId
    };
    for (const prop in productItemMutationInput) {
      if (productItemMutationInput[prop] === '') {
        delete productItemMutationInput[prop];
      }
    }
    return productItemMutationInput;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  validateFields = () => {
    return (
      <div className="input-group-error">
        <span>This field is required</span>
      </div>
    );
  };

  handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
      invalidInput: !e.currentTarget.value ? true : false
    } as any);
  };

  handleUpdateItem = input => {
    const { userId, client, notification, closeModal } = this.props;
    // Create product item
    client
      .mutate({
        variables: {
          input
        },
        mutation: gql(updateProductItem),
        refetchQueries: [
          {
            query: getCompanyProductItemsByUser,
            variables: {
              id: userId
            }
          }
        ]
      })
      .then((res: any) => {
        notification('Product saved successfully', 'success');
        closeModal();
      })
      .catch(err => {
        this.setError(err.message);
      });
  };

  render() {
    const {
      uic,
      category,
      subCategory,
      type,
      age,
      gender,
      size,
      look,
      other1,
      other2,
      other3,
      season,
      collectionName,
      productName,
      clientName,
      serviceName,
      unit,
      quantity,
      productItemBrandId,
      brands,
      error,
      loading,
      oldUic
    } = this.state;
    const { userId, itemTemplate, client } = this.props;

    return (
      <Form
        onSubmit={e => {
          e.preventDefault();
          const input = this.removeEmptyStringElements();

          this.setState({ loading: true });

          if (oldUic !== uic) {
            client
              .query({
                query: getCompanyProductItemsByUserAndUic,
                variables: {
                  userId,
                  uic: input.uic
                },
                fetchPolicy: APOLLO_FETCH_POLICY.NETWORK_ONLY
              })
              .then((result: any) => {
                if (!result && error) {
                  this.setError('An error occured.');
                }

                if (
                  result &&
                  result.data &&
                  result.data.getUser.company.productItems.items.length > 0
                ) {
                  this.setError('UIC code already exists.');
                  this.setState({ loading: false });
                } else {
                  // Create product item
                  this.handleUpdateItem(input);
                }
              })
              .catch(error => {
                this.setError('An error occured.');
              });
          } else {
            this.handleUpdateItem(input);
          }
        }}
      >
        {itemTemplate.uic && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="uic">Unique Identifier Code (UIC)</Label>
                <Input
                  type="text"
                  name="uic"
                  value={uic}
                  invalid={!uic}
                  id="uic"
                  placeholder="Unique Identifier Code (UIC)"
                  onChange={this.handleInputChange}
                />
                {!uic && this.validateFields()}
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.brand && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="brand">Brand</Label>
                <Select
                  // @ts-ignore
                  onChange={this.selectBrand}
                  value={selectedOption(productItemBrandId || '', brands)}
                  options={sortDropDownDataAlphabetically(brands)}
                  isSearchable={true}
                  className="select-styling"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.subBrand && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="subBrand">Sub Brand</Label>
                <Select
                  // @ts-ignore
                  onChange={this.selectSubBrand}
                  options={this.returnSubBrandsOfSelectedBrand(
                    productItemBrandId
                  )}
                  isSearchable={true}
                  className="select-styling"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.clientName && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="clientName">Client Name</Label>
                <Input
                  type="text"
                  name="clientName"
                  value={clientName}
                  invalid={!clientName}
                  id="clientName"
                  placeholder="Client Name"
                  onChange={this.handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.productName && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="productName">Product Name</Label>
                <Input
                  type="text"
                  name="productName"
                  value={productName}
                  invalid={!productName}
                  id="productName"
                  placeholder="Product Name"
                  onChange={this.handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.serviceName && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="serviceName">Service Name</Label>
                <Input
                  type="text"
                  name="serviceName"
                  value={serviceName}
                  invalid={!serviceName}
                  id="serviceName"
                  placeholder="Service Name"
                  onChange={this.handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.category && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="category">Category</Label>
                <Input
                  type="text"
                  name="category"
                  value={category}
                  id="category"
                  invalid={!category}
                  placeholder="Category"
                  onChange={this.handleInputChange}
                />
                {!category && this.validateFields()}
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.subCategory && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="subCategory">Sub Category</Label>
                <Input
                  type="text"
                  name="subCategory"
                  value={subCategory}
                  invalid={!subCategory}
                  id="subCategory"
                  placeholder="Sub Category"
                  onChange={this.handleInputChange}
                />
                {!subCategory && this.validateFields()}
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          {itemTemplate.type && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="type">Type</Label>
                <Input
                  type="text"
                  name="type"
                  value={type}
                  id="type"
                  invalid={!type}
                  placeholder="Type"
                  onChange={this.handleInputChange}
                />
                {!type && this.validateFields()}
              </FormGroup>
            </Col>
          )}
          {itemTemplate.gender && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <Select
                  // @ts-ignore
                  onChange={this.selectGender}
                  value={selectedOption(gender || '', GENDER)}
                  options={sortDropDownDataAlphabetically(GENDER)}
                  isSearchable={true}
                  className="select-styling"
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          {itemTemplate.age && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="age">Age</Label>
                <Input
                  type="text"
                  name="age"
                  value={age}
                  invalid={!age}
                  id="age"
                  placeholder="Age"
                  onChange={this.handleInputChange}
                />
                {!age && this.validateFields()}
              </FormGroup>
            </Col>
          )}
          {itemTemplate.size && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="size">Size</Label>
                <Input
                  type="text"
                  name="size"
                  value={size}
                  invalid={!size}
                  id="size"
                  placeholder="Size"
                  onChange={this.handleInputChange}
                />
                {!size && this.validateFields()}
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          {itemTemplate.look && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="look">Look</Label>
                <Input
                  type="text"
                  name="look"
                  value={look}
                  invalid={!look}
                  id="look"
                  placeholder="Look"
                  onChange={this.handleInputChange}
                />
                {!look && this.validateFields()}
              </FormGroup>
            </Col>
          )}
          {itemTemplate.season && (
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="season">Season</Label>
                <Input
                  type="text"
                  name="season"
                  value={season}
                  invalid={!season}
                  id="season"
                  placeholder="Season"
                  onChange={this.handleInputChange}
                />
                {!season && this.validateFields()}
              </FormGroup>
            </Col>
          )}
        </Row>
        {itemTemplate.other1 && (
          <Row>
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="other1">Other 1</Label>
                <Input
                  type="text"
                  name="other1"
                  value={other1}
                  invalid={!other1}
                  id="other1"
                  placeholder="Other 1"
                  onChange={this.handleInputChange}
                />
                {!other1 && this.validateFields()}
              </FormGroup>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <FormGroup>
                <Label for="other2">Other 2</Label>
                <Input
                  type="text"
                  name="other2"
                  value={other2}
                  invalid={!other2}
                  id="other2"
                  placeholder="Other 2"
                  onChange={this.handleInputChange}
                />
                {!other2 && this.validateFields()}
              </FormGroup>
            </Col>
            {itemTemplate.other3 && (
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <Label for="other3">Other 3</Label>
                  <Input
                    type="text"
                    name="other3"
                    value={other3}
                    id="other3"
                    placeholder="Other 3"
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        )}
        {itemTemplate.collectionName && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="collectionName">Collection Name</Label>
                <Input
                  type="text"
                  name="collectionName"
                  value={collectionName}
                  invalid={!collectionName}
                  id="collectionName"
                  placeholder="Collection Name"
                  onChange={this.handleInputChange}
                />
                {!collectionName && this.validateFields()}
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.quantity && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="text"
                  name="quantity"
                  value={quantity}
                  invalid={!quantity}
                  id="quantity"
                  placeholder="Quantity"
                  onChange={this.handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {itemTemplate.unit && (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <FormGroup>
                <Label for="unit">Unit</Label>
                <Input
                  type="text"
                  name="unit"
                  value={unit}
                  invalid={!unit}
                  id="unit"
                  placeholder="Unit"
                  onChange={this.handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <br />
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <StyledButton
                type="submit"
                label={loading ? <Loader /> : 'Save'}
                color={Colors.flumeDarkGreen}
                background={Colors.flumeGreen}
              />
            </FormGroup>
          </Col>
        </Row>
        {error && <ErrorMessage errorMessage={error} />}
      </Form>
    );
  }
}

export default withApollo(EditProductModal);
