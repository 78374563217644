import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Dropzone from 'react-dropzone';
import { Form } from 'reactstrap';
import * as Papa from 'papaparse';
import { MdFileDownload } from 'react-icons/md';
import StyledButton from '../../Components/Styled/Button';

/** GraphQL */
import { createBrand, createSubBrand } from '../../graphql/mutations';
import { getCompanyBrandsAndSubBrandsByUser } from '../../graphql/custom-queries';
import {
  CreateBrandMutation,
  CreateBrandMutationVariables,
  CreateSubBrandMutation,
  CreateSubBrandMutationVariables
} from '../../API';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Local component */
import DropzoneContainer from './DropzoneContainer';

/** Custom types */
import { Error } from '../../CustomTypes';

/** Theme */
import { Colors } from '../../Themes';

/** Utils */
import { VALID_FILE_FORMATS } from '../../Utils/Consts';

type BrandWithSubBrands = { name: string; subBrands?: Array<string> };
type BrandWithSubBrandsCollection = Array<BrandWithSubBrands>;

type Props = {
  companyId: string;
  closeModal: () => void;
  notification(message: string, appearance?: string): void;
};

type State = {
  files: Array<File>;
  loading: boolean;
  error: Error;
};

class BrandDataImport extends React.Component<Props, State> {
  state: State = {
    files: [],
    loading: false,
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** On dropping file */
  onDrop = (acceptedFiles: any): void => {
    if (!acceptedFiles || !acceptedFiles.length) {
      this.setError('Sorry, there was a problem uploading your selected file.');
    }
    // Check if the uploaded file is a valid csv file
    const uploadedFile = acceptedFiles[0];

    if (uploadedFile.name.split('.').pop() === VALID_FILE_FORMATS.csv) {
      this.setState({ files: [...this.state.files, ...acceptedFiles] });
    } else {
      this.setError('Please upload a valid CSV file.');
    }
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Get valid data to be created in the system
   * @param validBrands - the valid brands/data that has been parsed and returned as an array
   */
  getBrandsToBeCreated = (
    validBrands: Array<Array<string>>
  ): Array<BrandWithSubBrands | { name: string }> => {
    const brandsData = validBrands.map((validBrand: Array<string>) => {
      return {
        name: validBrand[0],
        subBrands: validBrand[1] && validBrand[1].split(',')
      };
    });

    return brandsData;
  };

  render() {
    const { files, error } = this.state;
    const { notification, closeModal, companyId } = this.props;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <Mutation<CreateBrandMutation, CreateBrandMutationVariables>
              mutation={gql(createBrand)}
            >
              {createBrandMutation => (
                <Mutation<
                  CreateSubBrandMutation,
                  CreateSubBrandMutationVariables
                >
                  mutation={gql(createSubBrand)}
                >
                  {(createSubBrandMutation, subBrandMutation) => (
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                        if (!files.length) {
                          this.setError('Please upload a valid CSV file.');
                        } else {
                          /**
                           * `Papa.parse` will return an object with a data property which consists
                           *  of an array of all the rows in the uploaded csv file. Each row is also an array.
                           *  The elements of a row array is determined by the number of columns created
                           * by the separator in the csv file (e.g. a comma `,`).
                           */
                          Papa.parse(files[0], {
                            complete: (results: any) => {
                              // Check if there are rows of data in the CSV file
                              if (
                                results &&
                                results.data &&
                                results.data.length
                              ) {
                                // filter out the column headings from the data
                                const brandsDataToImport = results.data.slice(
                                  1
                                );
                                // filter rows/lines and obtain valid ones (Must have at least a brand name)
                                const validBrands = brandsDataToImport.filter(
                                  (resultRow: Array<string>) =>
                                    resultRow[0] !== ''
                                );

                                if (validBrands && validBrands.length) {
                                  /**
                                   * Use array of valid brand and sub brand data to retrieve array of
                                   * objects that match brand type to be created using
                                   * mutation
                                   */
                                  const brandsToBeCreated: BrandWithSubBrandsCollection = this.getBrandsToBeCreated(
                                    validBrands
                                  );

                                  brandsToBeCreated.forEach(
                                    (brand: BrandWithSubBrands) => {
                                      createBrandMutation({
                                        variables: {
                                          input: {
                                            name: brand.name,
                                            brandCompanyId: companyId
                                          }
                                        },
                                        refetchQueries: [
                                          {
                                            query: getCompanyBrandsAndSubBrandsByUser,
                                            variables: {
                                              id: userId
                                            }
                                          }
                                        ]
                                      })
                                        .then((res: any) => {
                                          notification(
                                            'Brand created successfully'
                                          );
                                          /**
                                           * If the brand has sub brands then create them
                                           *  in relation to the newly created brand
                                           */
                                          if (
                                            brand.subBrands &&
                                            brand.subBrands.length
                                          ) {
                                            const brandId =
                                              res.data.createBrand.id;
                                            const subBrands = brand.subBrands;
                                            subBrands.forEach(subBrand => {
                                              createSubBrandMutation({
                                                variables: {
                                                  input: {
                                                    name: subBrand,
                                                    subBrandBrandId: brandId
                                                  }
                                                },
                                                refetchQueries: [
                                                  {
                                                    query: getCompanyBrandsAndSubBrandsByUser,
                                                    variables: {
                                                      id: userId
                                                    }
                                                  }
                                                ]
                                              })
                                                .then(() => {
                                                  notification(
                                                    'Sub brand created successfully'
                                                  );
                                                })
                                                .catch(() => {
                                                  notification(
                                                    'There was a problem creating a sub brand',
                                                    'error'
                                                  );
                                                });
                                            });
                                          }
                                        })
                                        .catch(err => {
                                          notification(
                                            'There was a problem importing the brand',
                                            'error'
                                          );
                                        });
                                    }
                                  );
                                }
                                closeModal();
                              } else {
                                this.setError(
                                  'No rows were found in the CSV file you uploaded.'
                                );
                              }
                            }
                          });
                        }
                      }}
                    >
                      <DropzoneContainer>
                        <div className="dropzone">
                          {!files.length && (
                            <Dropzone onDrop={this.onDrop}>
                              {({
                                getRootProps,
                                getInputProps,
                                isDragActive
                              }) => {
                                return (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                      <p>Drop files here...</p>
                                    ) : (
                                      <p>
                                        Drop a file here, or download the sample
                                        file below
                                      </p>
                                    )}
                                  </div>
                                );
                              }}
                            </Dropzone>
                          )}
                          {files.map((f: File, i) => (
                            <React.Fragment key={f.size}>
                              <div
                                style={{
                                  textAlign: 'center',
                                  marginTop: '20px',
                                  marginBottom: '20px',
                                  background: Colors.snow
                                }}
                              >
                                Chosen file: {f.name} - {`${f.size} bytes`}
                              </div>
                            </React.Fragment>
                          ))}
                          <br />
                          {error && <ErrorMessage errorMessage={error} />}
                          <a
                            href={require('../../Assets/FileTemplates/SampleBrands.csv')}
                            download="SampleBrands.csv"
                          >
                            <StyledButton
                              type="button"
                              label={[
                                'Sample File',
                                {
                                  ...(
                                    <MdFileDownload
                                      size="1.3em"
                                      color={Colors.formWhite}
                                    />
                                  )
                                }
                              ]}
                              width="10rem"
                              color={Colors.formWhite}
                              background={Colors.grey}
                            />
                            <StyledButton
                              label="Import"
                              type="submit"
                              className="importFile"
                              background={Colors.flumeDarkGreen}
                            />
                          </a>
                        </div>
                      </DropzoneContainer>
                    </Form>
                  )}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default BrandDataImport;
