import * as React from 'react';
import ReactTable, { SubComponentFunction } from 'react-table';

/** Import CSS */
import 'react-table/react-table.css';
import './index.css';

/** Utils */
import { filterCaseInsensitive } from '../../Utils/Helpers';

interface IFilterProps {
  filter: {
    value: string;
  };
  onChange: (value: string) => void;
}

type TableProps = {
  data: any;
  columns: Array<{
    id?: string;
    Header?: string | React.ReactNode;
    accessor: any;
    sortable?: boolean;
    filterable?: boolean;
    width?: number;
    Placeholder?: string;
    Filter?: (props: IFilterProps) => React.ReactElement<any>;
  }>;
  defaultFilterMethod?: any;
  defaultSorted?: Array<{ id: string; desc?: boolean; asc?: boolean }>;
  defaultPageSize?: number;
  showPaginationTop?: boolean;
  showPaginationBottom?: boolean;
  showPagination?: boolean;
  SubComponent?: SubComponentFunction;
};

const Table: React.SFC<TableProps> = props => {
  const defaultFilterMethod = props.defaultFilterMethod
    ? props.defaultFilterMethod
    : filterCaseInsensitive;
  return (
    <ReactTable
      data={props.data}
      columns={props.columns}
      defaultPageSize={props.defaultPageSize}
      showPaginationTop={props.showPaginationTop}
      showPaginationBottom={props.showPaginationBottom}
      defaultFilterMethod={defaultFilterMethod}
      SubComponent={props.SubComponent}
    />
  );
};

export default Table;
