import * as React from 'react';
import { Row, Col, Form, Label, FormGroup, Input, Button } from 'reactstrap';

/* presentational components */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/* custom types */
import { Error } from '../../CustomTypes';

type Props = {
  loading: boolean;
  error: Error;
  question: string;
  answer: string;
  handleSubmit: (event: React.FormEvent) => void;
  handleChange: (event: React.FormEvent) => void;
};

const AddFAQForm = (props: Props) => {
  const { loading, error, question, answer, handleChange } = props;

  return (
    <Form onSubmit={props.handleSubmit}>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <FormGroup>
            <Label for="question">Question</Label>
            <Input
              type="text"
              name="question"
              value={question}
              id="question"
              placeholder="Question"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={12} lg={12}>
          <FormGroup>
            <Label for="answer">Answer</Label>
            <Input
              type="textarea"
              name="answer"
              value={answer}
              id="answer"
              placeholder="Answer"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={{ size: 12 }}>
          <FormGroup>
            <Button disabled={loading}>
              {!loading ? 'Add FAQ' : <Loader />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
      {error && <ErrorMessage errorMessage={error} />}
    </Form>
  );
};

export default AddFAQForm;
