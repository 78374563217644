import * as React from 'react';
import Helmet from 'react-helmet';
import * as moment from 'moment';

/** Local components */
import MainPlannerItemsChart from './MainPlannerItemsChart';

/** Utils */
import { returnFormattedDates } from '../../Utils/Helpers';

/** Custom types */
import { ChartData } from '../../CustomTypes';

/** GraphQL */
import {
  getCompanyBatchCardsForTheWeekByUser,
  getCompanyBatchCardsForTheMonthByUser
} from '../../graphql/custom-queries';

type Props = {
  userId: string;
  filter: string;
  filterPeriod: moment.Moment;
  currentWeek: Array<Date>;
  updateChartDataSetOne: (data: ChartData) => void;
  updateChartDataSetTwo: (data: ChartData) => void;
};

const ReportingMainPlanner = (props: Props) => {
  const {
    userId,
    filter,
    currentWeek,
    filterPeriod,
    updateChartDataSetTwo,
    updateChartDataSetOne
  } = props;

  const formattedDates = returnFormattedDates(currentWeek, 'YYYY-MM-DD');
  const formattedMonth = moment(filterPeriod).format('YYYY-MM');
  const currentYear = moment(filterPeriod).format('YYYY');

  let batchCardQuery = getCompanyBatchCardsForTheWeekByUser;
  let variables: object = { id: userId };

  if (filter === 'week') {
    variables = {
      id: userId,
      dayOne: formattedDates[0],
      dayTwo: formattedDates[1],
      dayThree: formattedDates[2],
      dayFour: formattedDates[3],
      dayFive: formattedDates[4],
      daySix: formattedDates[5],
      daySeven: formattedDates[6]
    };
  }

  if (filter === 'month') {
    batchCardQuery = getCompanyBatchCardsForTheMonthByUser;
    variables = {
      ...variables,
      month: formattedMonth
    };
  }

  if (filter === 'year') {
    batchCardQuery = getCompanyBatchCardsForTheMonthByUser;
    variables = {
      ...variables,
      month: currentYear
    };
  }

  return (
    <React.Fragment>
      <MainPlannerItemsChart
        query={batchCardQuery}
        filter={filter}
        filterPeriod={filterPeriod}
        formattedDates={formattedDates}
        variables={variables}
        title="Batch Cards in Planner"
        updateDataCallback={updateChartDataSetTwo}
      />
      <MainPlannerItemsChart
        query={batchCardQuery}
        filter={filter}
        filterPeriod={filterPeriod}
        formattedDates={formattedDates}
        variables={variables}
        title="Items in Planner"
        updateDataCallback={updateChartDataSetOne}
        itemsType="withProductItems"
      />
      <Helmet title="Reporting - Planner" />
    </React.Fragment>
  );
};

export default ReportingMainPlanner;
