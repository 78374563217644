import * as React from 'react';
import Helmet from 'react-helmet';
import * as moment from 'moment';

/** Local components */
import QualityControlChart from './QualityControlChart';

/** Utils */
import { returnFormattedDates } from '../../Utils/Helpers';

/** Custom types */
import { ChartData } from '../../CustomTypes';

/** GraphQL */
import {
  getCompletedProductItemsForTheWeekByUser,
  getCompletedProductItemsForTheMonthByUser
} from '../../graphql/custom-queries';

type Props = {
  userId: string;
  filter: string;
  filterPeriod: moment.Moment;
  currentWeek: Array<Date>;
  updateChartDataSetOne: (data: ChartData) => void;
};

const ReportingComplete = (props: Props) => {
  const {
    userId,
    filter,
    currentWeek,
    filterPeriod,
    updateChartDataSetOne
  } = props;

  const formattedDates = returnFormattedDates(currentWeek, 'YYYY-MM-DD');
  const formattedMonth = moment(filterPeriod).format('YYYY-MM');
  const currentYear = moment(filterPeriod).format('YYYY');

  let completedItemsQuery = getCompletedProductItemsForTheWeekByUser;
  let variables: object = { id: userId };

  if (filter === 'week') {
    variables = {
      id: userId,
      dayOne: formattedDates[0],
      dayTwo: formattedDates[1],
      dayThree: formattedDates[2],
      dayFour: formattedDates[3],
      dayFive: formattedDates[4],
      daySix: formattedDates[5],
      daySeven: formattedDates[6]
    };
  }

  if (filter === 'month') {
    completedItemsQuery = getCompletedProductItemsForTheMonthByUser;
    variables = {
      ...variables,
      month: formattedMonth
    };
  }

  if (filter === 'year') {
    completedItemsQuery = getCompletedProductItemsForTheMonthByUser;
    variables = {
      ...variables,
      month: currentYear
    };
  }

  return (
    <React.Fragment>
      <QualityControlChart
        query={completedItemsQuery}
        filter={filter}
        filterPeriod={filterPeriod}
        formattedDates={formattedDates}
        variables={variables}
        title="Completed Items"
        itemsType="completed"
        updateDataCallback={updateChartDataSetOne}
      />
      <Helmet title="Reporting - Completed" />
    </React.Fragment>
  );
};

export default ReportingComplete;
