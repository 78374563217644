import * as React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { withToastManager } from 'react-toast-notifications';

// GraphQL
import { getUser } from '../../graphql/queries';
// Presentation/UI
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import Loader from '../../Components/Loader';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

// container components
import Tabs from '../../Components/Tabs';
import SubscribeToProduct from './SubscribeToProduct';
import CompanyDetails from './CompanyDetails';
import PaymentsAndInvoices from './PaymentsAndInvoices';
import Cards from './Cards';

// Themes
import { Colors } from '../../Themes';

/** Custom types */
import { ToastNotificationType } from '../../CustomTypes';

interface ITabstitles {
  [key: number]: string;
}

interface ITabsContent {
  [key: number]: React.ReactNode;
}

type Props = {
  data: any;
  toastManager: ToastNotificationType;
};

type SelectType = {
  value: string;
  label: string;
};

const AccountsAndBilling: React.FC<Props> = props => {
  return (
    <UserSpaceContextConsumer>
      {({ userId }) => {
        return (
          <Query
            query={gql(getUser)}
            variables={{
              id: userId
            }}
            fetchPolicy="cache-and-network"
          >
            {({ loading, error, data }) => {
              const { toastManager } = props;

              if (loading) {
                return (
                  <FullWidthContainer align="center">
                    <Loader size={150} color={Colors.flumeGreen} />
                  </FullWidthContainer>
                );
              }

              if (error || !data || !data.getUser) {
                return <div>There was a problem loading your user data</div>;
              }

              const user = data.getUser;

              let companyId: string = '';
              let subscriptionPlanId: string | null = '';
              let stripeCustomerId: string | null = '';
              let name: string | null = '';
              let address: string | null = '';
              let state: string | null = '';
              let industry: SelectType = { value: '', label: '' };
              let companySize: SelectType = {
                value: '',
                label: ''
              };
              let country: SelectType = { value: '', label: '' };

              if (user && user.company) {
                companyId = user.company.id;
                subscriptionPlanId = user.company.stripeSubscriptionPlanId;
                stripeCustomerId = user.company.stripeCustomerId;
                name = user.company.name;
                address = user.company.address;
                state = user.company.state;
                industry = {
                  value: user.company.industry,
                  label: user.company.industry
                };
                companySize = {
                  value: user.company.companySize,
                  label: user.company.companySize
                };
                country = {
                  value: user.company.country,
                  label: user.company.country
                };
              } else {
                return <div>There was a problem loading your company data</div>;
              }

              const TABS_TITLES: ITabstitles = {
                1: 'Payments & Invoices',
                2: 'Cards',
                3: 'Company Details',
                4: 'Subscriptions'
              };

              const tabsContent: ITabsContent = {
                1: (
                  <PaymentsAndInvoices
                    companyId={companyId}
                    stripeSubscriptionPlanId={subscriptionPlanId}
                    stripeCustomerId={stripeCustomerId}
                  />
                ),
                2: (
                  <Cards
                    toastManager={toastManager}
                    stripeCustomerId={stripeCustomerId}
                  />
                ),
                3: (
                  <CompanyDetails
                    userId={userId}
                    name={name}
                    address={address}
                    state={state}
                    companySize={companySize}
                    industry={industry}
                    companyId={companyId}
                    country={country}
                  />
                ),
                4: (
                  <SubscribeToProduct
                    companyId={companyId}
                    stripeSubscriptionPlanId={subscriptionPlanId}
                    toastManager={toastManager}
                    userId={userId}
                  />
                )
              };

              return <Tabs titles={TABS_TITLES} tabsContent={tabsContent} />;
            }}
          </Query>
        );
      }}
    </UserSpaceContextConsumer>
  );
};

export default withToastManager(AccountsAndBilling);
