import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Form, FormGroup, Button } from 'reactstrap';

/** GraphQL */
import { deleteBatchCard } from '../../graphql/mutations';
import {
  getCompanyProductItemsByUser,
  getCompanyBatchCardsByUser
} from '../../graphql/custom-queries';

/** Presentation/UI */
import DialogModal from '../../Components/DialogModal';
import GlobalModalContainer from '../../Components/Modal';

/** Generated types */
import {
  DeleteBatchCardMutation,
  DeleteBatchCardMutationVariables
} from '../../API';

/** Custom types */
import { ModalWithMessageType } from '../../CustomTypes';

type Props = ModalWithMessageType & {
  batchCardId: string;
  userId: string;
};

type State = {
  dialogModal: boolean;
};

class DeleteBatchCard extends React.Component<Props, State> {
  state: State = {
    dialogModal: false
  };

  /** Close dialog modal */
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false
    });
  };

  /** Open dialog modal */
  openDialogModal = (): void => {
    this.setState({
      dialogModal: true
    });
  };

  render() {
    const { batchCardId, closeModal, userId, notification } = this.props;
    const { dialogModal } = this.state;
    return (
      <FormGroup>
        <GlobalModalContainer
          toggleModal={this.closeDialogModal}
          title="Delete batch card"
          modalDisplay={
            <Mutation<DeleteBatchCardMutation, DeleteBatchCardMutationVariables>
              mutation={gql(deleteBatchCard)}
            >
              {(deleteBatchCardMutation, deleteMutation) => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    deleteBatchCardMutation({
                      variables: {
                        input: {
                          id: batchCardId
                        }
                      },
                      refetchQueries: [
                        {
                          query: getCompanyProductItemsByUser,
                          variables: {
                            id: userId
                          }
                        },
                        {
                          query: getCompanyBatchCardsByUser,
                          variables: {
                            id: userId
                          }
                        }
                      ]
                    })
                      .then(() => {
                        closeModal();
                        notification(
                          'Batch card deleted successfully',
                          'success'
                        );
                      })
                      .catch(() => {
                        this.closeDialogModal();
                        notification(
                          'Sorry, there was a problem deleting the batch card',
                          'error'
                        );
                      });
                  }}
                >
                  <DialogModal
                    loading={deleteMutation.loading}
                    title="Are you sure you want to delete this batch card?"
                    toggleModal={this.closeDialogModal}
                  />
                </Form>
              )}
            </Mutation>
          }
          modal={dialogModal}
        />
        <Button type="button" onClick={this.openDialogModal}>
          Delete Batch Card
        </Button>
      </FormGroup>
    );
  }
}

export default DeleteBatchCard;
