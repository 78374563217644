import {
  CreateItemTemplateInput,
  UpdateServiceInput,
  UpdateSpaceInput,
  UpdateRoleInput
} from '../../../API';

/** Check if an account has completed the required setup tasks
 * @param itemTemplate - the company's product item template
 * @param roles - the company's roles that can be assigned to a user
 * @param services - the company's services that can be performed on product items
 * @param spaces - the company's spaces that users can be added to
 */
export const checkIfSetupIsComplete = (
  itemTemplate?: CreateItemTemplateInput,
  roles?: {
    items: Array<UpdateRoleInput>;
  },
  services?: {
    items: Array<UpdateServiceInput>;
  },
  spaces?: {
    items: Array<UpdateSpaceInput>;
  }
): boolean => {
  if (
    itemTemplate &&
    roles &&
    roles.items &&
    roles.items.length &&
    services &&
    services.items &&
    services.items.length &&
    spaces &&
    spaces.items &&
    spaces.items.length
  ) {
    return true;
  }
  return false;
};
