import * as React from 'react';

interface IAppContextInterface {
  uploadedFiles: Array<File>;
  uploadFiles: (acceptedFiles: Array<File>) => void;
}

interface IProps {
  children: React.ReactNode;
}

interface IState {
  uploadedFiles: Array<File>;
}

const ctxt = React.createContext<IAppContextInterface>({
  uploadedFiles: [],
  uploadFiles: (acceptedFiles: Array<File>): void => {
    console.log('Not yet initialized');
  }
});

const FileUploadContextProvider = ctxt.Provider;
export const FileUploadContextConsumer = ctxt.Consumer;

export default class Provider extends React.Component<IProps, IState> {
  state: IState = {
    uploadedFiles: []
  };

  uploadFiles = (acceptedFiles: Array<File>): void => {
    this.setState({ uploadedFiles: acceptedFiles });
  };

  render() {
    return (
      <FileUploadContextProvider
        value={{
          uploadedFiles: this.state.uploadedFiles,
          uploadFiles: (files: Array<File>) => this.uploadFiles(files)
        }}
      >
        {this.props.children}
      </FileUploadContextProvider>
    );
  }
}
