import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';

/** GraphQL */
import { createSubBrand } from '../../graphql/mutations';
import { getCompanyBrandsAndSubBrandsByUser } from '../../graphql/custom-queries';

import {
  CreateSubBrandMutation,
  CreateSubBrandMutationVariables
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Themes */
import { Colors } from '../../Themes';

/** Custom Types */
import { Error } from '../../CustomTypes';

type Props = {
  brandId: string;
  userId: string | null;
  closeModal(): void;
  notification(message: string, appearance?: string): void;
};

type State = {
  name: string;
  error: Error;
};

class AddBrandModal extends React.Component<Props, State> {
  state: State = {
    name: '',
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Validate Brand */
  validateBrand = (): boolean => {
    const { name } = this.state;

    if (!name) {
      this.setError('Please enter the name of the brand.');
      return false;
    }

    return true;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { name, error } = this.state;
    const { brandId, userId, notification, closeModal } = this.props;

    return (
      <Mutation<CreateSubBrandMutation, CreateSubBrandMutationVariables>
        mutation={gql(createSubBrand)}
      >
        {(createSubBrandMutation, subBrandMutation) => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateBrand()) {
                // Start by creating brand
                createSubBrandMutation({
                  variables: {
                    input: {
                      name,
                      subBrandBrandId: brandId
                    }
                  },
                  refetchQueries: [
                    {
                      query: getCompanyBrandsAndSubBrandsByUser,
                      variables: {
                        id: userId
                      }
                    }
                  ]
                })
                  .then(() => {
                    notification('Sub brand created successfully');
                    closeModal();
                  })
                  .catch(err => {
                    this.setState({
                      name: ''
                    });
                    this.setError(err.message);
                  });
              }
            }}
          >
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="name">Sub Brand Name</Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    id="name"
                    placeholder="Sub brand name"
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <StyledButton
                    type="submit"
                    label={subBrandMutation.loading ? <Loader /> : 'Create'}
                    color={Colors.flumeDarkGreen}
                    background={Colors.flumeGreen}
                  />
                </FormGroup>
              </Col>
            </Row>
            {error && <ErrorMessage errorMessage={error} />}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default AddBrandModal;
