import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Form, FormGroup } from 'reactstrap';

/** GraphQL */
import { deleteProductItem } from '../../graphql/mutations';
import { getCompanyProductItemsByUser } from '../../graphql/custom-queries';

/** Presentation/UI */
import DialogModal from '../../Components/DialogModal';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Generated types */
import {
  DeleteProductItemMutation,
  DeleteProductItemMutationVariables
} from '../../API';

type Props = {
  productItemId: string;
  updateDialogModal: React.Dispatch<React.SetStateAction<boolean>>;
  notification(message: string, appearance?: string | undefined): void;
};

const DeleteProductItem: React.FC<Props> = ({
  productItemId,
  notification,
  updateDialogModal
}) => {
  return (
    <UserSpaceContextConsumer>
      {({ userId }) => {
        return (
          <FormGroup>
            <Mutation<
              DeleteProductItemMutation,
              DeleteProductItemMutationVariables
            >
              mutation={gql(deleteProductItem)}
            >
              {(deleteProductMutation, deleteMutation) => (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    deleteProductMutation({
                      variables: {
                        input: {
                          id: productItemId
                        }
                      },
                      refetchQueries: [
                        {
                          query: getCompanyProductItemsByUser,
                          variables: {
                            id: userId
                          }
                        }
                      ]
                    })
                      .then(() => {
                        updateDialogModal(false);
                        notification(
                          'Product item deleted successfully',
                          'success'
                        );
                      })
                      .catch(() => {
                        updateDialogModal(false);
                        notification(
                          'Sorry, there was a problem deleting the product item',
                          'error'
                        );
                      });
                  }}
                >
                  <DialogModal
                    loading={deleteMutation.loading}
                    title="Are you sure you want to delete this product item?"
                    toggleModal={() => updateDialogModal(false)}
                  />
                </Form>
              )}
            </Mutation>
          </FormGroup>
        );
      }}
    </UserSpaceContextConsumer>
  );
};

export default DeleteProductItem;
