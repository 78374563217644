import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import * as moment from 'moment';

/** GraphQL */
import {
  getCompanyBatchCardsByUser,
  getCompanyTaggedProductItemsByUser,
  getCompanyCompletedProductItemsByUser,
  getCompanyProductItemsByUser,
  getCompanyBatchCardsByUserMainPlanner
} from '../../graphql/custom-queries';

/** Context API */
import DefaultWeekContextProvider from '../QualityControlCalendar/DefaultWeekContextProvider';
import { DefaultWeekContextConsumer } from '../QualityControlCalendar/DefaultWeekContextProvider';

/** Generated types */
import {
  UpdateProductItemMutation,
  UpdateProductItemMutationVariables
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Local components */
import PreviousTags from './PreviousTags';

/** Custom types */
import { Error, ModalWithMessageType } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { joinMutations } from '../../Utils/Helpers';

type Props = ModalWithMessageType & {
  batchCardId: string;
  bulkAction?: boolean;
  productItemId: string;
  productItemIds?: Array<string>;
  previousTagDescriptions?: Array<string>;
  userId: string;
};

type State = {
  error: Error;
  tagDescription: string;
};

class AddTagToProductItem extends React.Component<Props, State> {
  timeoutId: number = 0;
  state = {
    error: null,
    tagDescription: ''
  };

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Validation */
  validateForm = (tagDescription: string): boolean => {
    // Check for undefined or empty input fields
    if (!tagDescription) {
      this.setError('Please add a tag description');
      return false;
    }

    return true;
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Execute mutation to add a note to a product item(s)
   * @param updateProductItemMutation - graphql mutation for adding a tag description
   * @param closeModal - function to close modal
   * @param notification - function to display toast message
   */
  executeMutation = async (
    updateProductItemMutation: () => Promise<any>,
    closeModal: () => void,
    notification: (message: string, appearance?: string | undefined) => void
  ) => {
    await updateProductItemMutation();
    closeModal();
    notification(
      'Tag description has been added to the selected product items'
    );
  };

  /** Create mutation with alias mutations to update product items
   * @param selectedProductIems - ids of selected product items
   * @param previousTagDescriptions - previously added tags
   * @param tagDescription - tag description
   * @param productId - id of a product in the case that only one product is being updated
   * @param bulkAction - a flag that determines whether or not is it a bulk action being carried out or not
   */
  returnMutation = (
    selectedProductItems: Array<string>,
    previousTagDescriptions: Array<string>,
    tagDescription: string,
    productId: string,
    bulkAction?: boolean
  ) => {
    // combine all tag descriptions
    const allProductTags = [...previousTagDescriptions, tagDescription];

    if (bulkAction && selectedProductItems && selectedProductItems.length) {
      // Update multiple product items
      const updateProductItemMutations = selectedProductItems.map(
        productItemId => {
          const alias = `alias_updateProductItem_${Math.random()
            .toString(36)
            .slice(2)}`;

          return `
            ${alias}: updateProductItem(
              input: {
                id: "${productItemId}",
                taggedAt: "${moment().toISOString()}",
                tagged: true,
                returnedToItems: false,
                tagDescription: "${tagDescription}",
                previousTagDescriptions: "${allProductTags}",
                archived: false,
                complete: false,
                completedServices: [],
                productItemBatchCardId: null,
                qcApproved: false
              }
            ){
              id
            }
        `;
        }
      );

      const mutations = [...updateProductItemMutations];
      return joinMutations(mutations);
    } else {
      // Update a single product item
      return `
        mutation {
          updateProductItem(
            input: {
              id: "${productId}",
              taggedAt: "${moment().toISOString()}",
              tagged: true,
              returnedToItems: false,
              tagDescription: "${tagDescription}",
              previousTagDescriptions: "${allProductTags}",
              archived: false,
              complete: false,
              completedServices: [],
              productItemBatchCardId: null,
              qcApproved: false
            }
          ){
            id
          }
        }
      `;
    }
  };

  render() {
    const { error, tagDescription } = this.state;
    const {
      bulkAction,
      closeModal,
      notification,
      productItemId,
      productItemIds,
      previousTagDescriptions,
      userId
    } = this.props;

    return (
      <DefaultWeekContextProvider>
        <DefaultWeekContextConsumer>
          {({ currentWeekFormattedDates }) => {
            return (
              <Mutation<
                UpdateProductItemMutation,
                UpdateProductItemMutationVariables
              >
                mutation={gql(
                  this.returnMutation(
                    productItemIds || [],
                    previousTagDescriptions || [],
                    tagDescription,
                    productItemId,
                    bulkAction
                  )
                )}
                refetchQueries={[
                  {
                    query: getCompanyBatchCardsByUser,
                    variables: {
                      id: userId
                    }
                  },
                  {
                    query: getCompanyTaggedProductItemsByUser,
                    variables: {
                      id: userId
                    }
                  },
                  {
                    query: getCompanyCompletedProductItemsByUser,
                    variables: {
                      id: userId
                    }
                  },
                  {
                    query: getCompanyProductItemsByUser,
                    variables: {
                      id: userId
                    }
                  },
                  {
                    query: getCompanyBatchCardsByUserMainPlanner,
                    variables: {
                      id: userId,
                      dayOne: currentWeekFormattedDates[0],
                      dayTwo: currentWeekFormattedDates[1],
                      dayThree: currentWeekFormattedDates[2],
                      dayFour: currentWeekFormattedDates[3],
                      dayFive: currentWeekFormattedDates[4],
                      daySix: currentWeekFormattedDates[5],
                      daySeven: currentWeekFormattedDates[6]
                    }
                  }
                ]}
              >
                {(updateProductItemMutation, { loading }) => (
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      if (this.validateForm(tagDescription)) {
                        this.executeMutation(
                          updateProductItemMutation,
                          closeModal,
                          notification
                        );
                      }
                    }}
                  >
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <FormGroup>
                          <Label for="tag">Tag description</Label>
                          <Input
                            type="textarea"
                            name="tag"
                            value={tagDescription}
                            id="tag"
                            placeholder="Type description here..."
                            onChange={e =>
                              this.setState({ tagDescription: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <FormGroup>
                          <StyledButton
                            type="submit"
                            label={
                              !loading ? 'Move back to sorting' : <Loader />
                            }
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {error && <ErrorMessage errorMessage={error} />}
                    {previousTagDescriptions &&
                    previousTagDescriptions.length ? (
                      <PreviousTags
                        previousTags={previousTagDescriptions}
                        subHeading={true}
                        height={200}
                      />
                    ) : null}
                  </Form>
                )}
              </Mutation>
            );
          }}
        </DefaultWeekContextConsumer>
      </DefaultWeekContextProvider>
    );
  }
}

export default AddTagToProductItem;
