import * as React from 'react';
import { Row, Col, Button } from 'reactstrap';

/** Presentation/UI */
import Loader from '../Loader/';

/** Themes */
import { Colors } from '../../Themes';

interface IProps {
  loading: boolean;
  title: string;
  handleAccept?: (event: React.FormEvent) => void;
  toggleModal(): void;
}

const DialogModal: React.FC<IProps> = props => {
  return (
    <div>
      <p style={{ textAlign: 'center' }}>{props.title}</p>
      {props.loading ? (
        <Row style={{ marginTop: '30px', marginBottom: '20px' }}>
          <Col xs={12} md={12} lg={12} className="center-align">
            <Loader color={Colors.flumeGreen} />
          </Col>
        </Row>
      ) : (
        <Row style={{ marginTop: '30px', marginBottom: '20px' }}>
          <Col xs={12} md={6} lg={6} className="center-align">
            {props.handleAccept ? (
              <Button type="submit" onClick={props.handleAccept}>
                Yes
              </Button>
            ) : (
              <Button type="submit">Yes</Button>
            )}
          </Col>
          <Col xs={12} md={6} lg={6} className="center-align">
            <Button
              type="button"
              onClick={props.toggleModal}
              className="secondary-btn"
            >
              No
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DialogModal;
