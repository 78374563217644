import * as React from 'react';
import Helmet from 'react-helmet';
import * as moment from 'moment';

/** Local components */
import SpacesChart from './SpacesChart';
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';

/** Utils */
import { returnFormattedDates } from '../../Utils/Helpers';

/** Custom types */
import { ChartData } from '../../CustomTypes';

/** GraphQL */
import {
  getBatchCardsForTheMonthByUserAndSpace,
  getBatchCardsForTheWeekByUserAndSpace
} from '../../graphql/custom-queries';

type Props = {
  userId: string;
  spaceId: string;
  filter: string;
  filterPeriod: moment.Moment;
  currentWeek: Array<Date>;
  updateChartDataSetOne: (data: ChartData) => void;
  updateChartDataSetTwo: (data: ChartData) => void;
};

const ReportingSpaces = (props: Props) => {
  const {
    userId,
    spaceId,
    filter,
    currentWeek,
    filterPeriod,
    updateChartDataSetTwo,
    updateChartDataSetOne
  } = props;

  const formattedDates = returnFormattedDates(currentWeek, 'YYYY-MM-DD');
  const formattedMonth = moment(filterPeriod).format('YYYY-MM');
  const currentYear = moment(filterPeriod).format('YYYY');

  let query = getBatchCardsForTheWeekByUserAndSpace;
  let variables: object = { id: userId, spaceId };

  if (filter === 'week') {
    variables = {
      ...variables,
      dayOne: formattedDates[0],
      dayTwo: formattedDates[1],
      dayThree: formattedDates[2],
      dayFour: formattedDates[3],
      dayFive: formattedDates[4],
      daySix: formattedDates[5],
      daySeven: formattedDates[6]
    };
  }

  if (filter === 'month') {
    query = getBatchCardsForTheMonthByUserAndSpace;
    variables = {
      ...variables,
      month: formattedMonth
    };
  }

  if (filter === 'year') {
    query = getBatchCardsForTheMonthByUserAndSpace;
    variables = {
      ...variables,
      month: currentYear
    };
  }

  return (
    <div>
      {spaceId ? (
        <React.Fragment>
          <SpacesChart
            query={query}
            filter={filter}
            filterPeriod={filterPeriod}
            formattedDates={formattedDates}
            variables={variables}
            title="Batch Cards"
            itemsType="withBatchCard"
            updateDataCallback={updateChartDataSetTwo}
          />
          <SpacesChart
            query={query}
            filter={filter}
            filterPeriod={filterPeriod}
            formattedDates={formattedDates}
            variables={variables}
            title="Items"
            itemsType="items"
            updateDataCallback={updateChartDataSetOne}
          />
          <Helmet title="Reporting - Spaces" />
        </React.Fragment>
      ) : (
        <FullWidthContainer align="center" marginTop={100}>
          <p>Select a space above to view chart data</p>
        </FullWidthContainer>
      )}
    </div>
  );
};

export default ReportingSpaces;
