const colors = {
  aquaHaze: '#edf1f5',
  snow: 'white',
  coal: '#464e53',
  darkCoal: '#323639',
  coalLight: '#3A3D3F',
  coalLighter: ' #464e53',
  whiteSmoke: '#f7f7f8',
  lightBlueGrey: '#eaeff3',
  lightGray: '#d3d3d3',
  grey: '#7b8389',
  darkGrey: '#33353d',
  grayChateau: '#9ca3a7',
  transparent: 'rgba(0,0,0,0)',
  transparentWhite: 'rgba(255,255,255,0.1)',
  semiTransparentWhite: 'rgba(255,255,255, 0.5)',
  error: 'rgba(200, 0, 0, 0.8)',
  success: '#16a085',
  overlayBlack: 'rgba(0,0,0,0.4)',
  semiBlack: 'rgba(0,0,0,0.5)',
  galleryBlack: 'rgba(0,0,0,0.7)',
  darkGreen: '00b09f',
  flumeGreen: '#bee5e2',
  flumeDarkGreen: '#5b9d98',
  flumeGreenLight: '#DFF2F1',
  formWhite: 'rgba(255,255,255,0.9)',
  selectLabel: '#f0f3f8',
  lightBlue: '#e6f2ff',
  lime: '#83e6b4',
  pink: '#f98c8c',
  aquaGrey: 'rgba(188, 203, 214, 0.7)',
  lightCoal: '#ccc',
  paleCoal: '#f2f2f2',
  paleGrey: '#f0f3f9',
  lightPurple: '#e0b3ff',
  backendBackground: '#f5f8fA',
  blueGrey: '#9397ab',
  aquaMarine: '#00e2af',
  ligthishBlue: '#009bda',
  coralPink: '#ff6a70',
  green: '#69B253',
  divider: '#f5f8fa',
  osloGray: '#4f585d',
  catSkillWhite: '#e9eff4',
  mystic: '#EAEFF3',
  sirocco: '#7bb5b0'
};

export default colors;
