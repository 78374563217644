/* eslint no-empty-pattern: 0, jsx-a11y/alt-text: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { MdDone, MdSearch } from 'react-icons/md';
import Select from 'react-select';

/** GraphQL */
import { updateBatchCard, updateProductItem } from '../../graphql/mutations';
import { getCompanyBatchCardsByUserMainPlanner } from '../../graphql/custom-queries';

import Composed from './ComposedMutationsForBatchCardAllocations';

/** Generated types */
import {
  UpdateBatchCardMutation,
  UpdateBatchCardMutationVariables,
  UpdateProductItemInput,
  CreateItemTemplateInput,
  UpdateSpaceInput,
  UpdateProductItemMutation,
  UpdateProductItemMutationVariables
} from '../../API';

/** Presentation/UI */
import GlobalModalContainer from '../../Components/Modal';
import Loader from '../../Components/Loader';
import Table from '../../Components/Table';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';
import {
  CircleIcon,
  Checkbox,
  SelectedIcon,
  SelectedColorForBatchCard,
  TableHeaderContainer,
  ServiceIconsContainer
} from '../../Components/Styled/ListViewElements';
import {
  IconsContainer,
  ProductActionsContainer
} from '../../Components/Styled/MainPlanner';

/** Local components */
import AddNoteToProductItem from './AddNoteToProductItem';
import AddImageToProductItem from './AddImageToProductItem';
import AddTagToProductItem from './AddTagToProductItem';
import BatchCardImages from './BatchCardImages';
import RowActions from './ProductItemRowActions';
import AddTextToProductItem from './AddTextToProductItem';

/** Custom Types */
import {
  BatchCard,
  BatchCardServiceType,
  Error,
  ModalWithMessageType,
  SelectType,
  ValueType,
  BatchCardServices
} from '../../CustomTypes';

/** Themes */
import { Colors, ServiceIcons } from '../../Themes';

/** Utils */
import { BULK_ACTIONS, BULK_ACTION_OPTIONS } from '../../Utils/Consts';
import {
  returnNewProductItemColumns,
  checkIfArrayOfStrings,
  returnFormattedDates,
  sortArrayAlphabetically
} from '../../Utils/Helpers';
import {
  checkIfServiceIsCompleted,
  checkIfAllServicesAreComplete,
  loadSelectedSpaces,
  updateCompletedServicesOnProductItem,
  returnPreviousNotes,
  returnRelevantProductItems,
  returnPreviousTextContent,
  arrayofCompletedBatchCardServices
} from './BatchCardAllocationHelpers';

type Props = ModalWithMessageType & {
  batchCard: BatchCard;
  itemTemplate: CreateItemTemplateInput;
  userId: string;
  currentWeek: Array<Date>;
  batchCardServices: Array<BatchCardServices>;
};

type State = {
  id: string;
  name: string;
  description: string | null;
  batchCardServices: Array<BatchCardServiceType>;
  searchFilter: boolean;
  allSelected: boolean;
  bulkAction: boolean;
  imageModal: boolean;
  loading: boolean;
  noteModal: boolean;
  addTextModal: boolean;
  productItemId: string;
  previousTagDescriptions: Array<string>;
  productItemImageKeys: Array<string>;
  selectedProductItemIds: Array<string>;
  selectedProductItems: Array<UpdateProductItemInput>;
  selectedServices: Array<BatchCardServiceType>;
  selectedSpaces: Array<UpdateSpaceInput>;
  tagModal: boolean;
  error: Error;
};

class SavedBatchCard extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps && nextProps.batchCard) {
      const { productItems } = nextProps.batchCard;
      return {
        selectedProductItems: productItems.items
      };
    }
    return null;
  }

  timeoutId: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.batchCard.id || '',
      name: props.batchCard.name || '',
      description: props.batchCard.description || null,
      batchCardServices: props.batchCard.services.items,
      searchFilter: false,
      allSelected: false,
      bulkAction: false,
      noteModal: false,
      addTextModal: false,
      productItemId: '',
      previousTagDescriptions: [],
      productItemImageKeys: [],
      selectedProductItemIds: [],
      selectedProductItems: props.batchCard.productItems.items,
      selectedServices: props.batchCard.services.items,
      selectedSpaces: loadSelectedSpaces(
        props.batchCard.batchCardAllocations.items
      ),
      tagModal: false,
      imageModal: false,
      error: null,
      loading: false
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Close tag modal */
  closeTagModal = (): void => {
    this.setState({
      tagModal: false,
      productItemId: ''
    });
  };

  /** Close text modal */
  closeAddTextModal = (): void => {
    this.setState({
      addTextModal: false,
      productItemId: ''
    });
  };

  /** Open tag modal
   * @param productItemId - id for a product item
   * @param bulkAction - a flag for whether or not the action being carried out is a bulk action or not
   * @param previousTagDescriptions - previously added tags
   */
  openTagModal = (
    productItemId: string,
    bulkAction: boolean = false,
    previousTagDescriptions: Array<string> = []
  ): void => {
    this.setState({
      tagModal: true,
      productItemId,
      bulkAction,
      previousTagDescriptions
    });
  };

  /** Close image modal */
  closeImageModal = (): void => {
    this.setState({
      imageModal: false,
      productItemId: ''
    });
  };

  /** CLose image modal on successful upload */
  closeImageModalSuccess = (): void => {
    this.setState({
      imageModal: false,
      productItemId: '',
      selectedProductItemIds: []
    });
  };

  /** Open image modal
   * @param productItemImageKeys - image keys for product item
   * @param productItemId - id for a product item
   * @param bulkAction - a flag that for whether or not the action being carried out is a bulk action or not
   */
  openImageModal = (
    productItemImageKeys: Array<string>,
    productItemId?: string,
    bulkAction?: boolean
  ): void => {
    this.setState({
      imageModal: true,
      productItemImageKeys,
      productItemId: productItemId || '',
      bulkAction: bulkAction || false
    });
  };

  /** Close note modal */
  closeNoteModal = (): void => {
    this.setState({
      noteModal: false,
      productItemId: ''
    });
  };

  /** Open note modal
   * @param productItemId - id for a product item
   */
  openNoteModal = (productItemId?: string, bulkAction?: boolean): void => {
    this.setState({
      noteModal: true,
      productItemId: productItemId || '',
      bulkAction: bulkAction || false
    });
  };

  /** Open text modal
   * @param productItemId - id for a product item
   * @param bulkAction - whether its a bulk action
   * @param productItemImageKeys - image keys for product item
   */
  openAddTextModal = (
    productItemId?: string,
    bulkAction?: boolean,
    productItemImageKeys?: Array<string>
  ): void => {
    this.setState({
      addTextModal: true,
      productItemId: productItemId || '',
      bulkAction: bulkAction || false,
      productItemImageKeys: productItemImageKeys || []
    });
  };

  /** Select bulk action
   * @param bulkAction - bulk action to be carried out on product items
   * @param selectedProductItemIds - array of selected product item ids
   */
  selectBulkAction = (
    bulkAction: ValueType<SelectType>,
    selectedProductItemIds: Array<string>
  ): void => {
    if (selectedProductItemIds && selectedProductItemIds.length) {
      switch (bulkAction.value) {
        case BULK_ACTIONS.note:
          this.openNoteModal('', true);
          break;
        case BULK_ACTIONS.tag:
          this.openTagModal('', true);
          break;
        case BULK_ACTIONS.image:
          this.openImageModal([], '', true);
          break;
        case BULK_ACTIONS.content:
          this.openAddTextModal('', true, []);
          break;
        default:
          break;
      }
    }
  };

  /** Select/deselect product items from table. A product id can only appear once in the array of selected items
   * @param productItemId - the selected product item's id
   */
  selectProductItem = (productItemId: string): void => {
    const { selectedProductItemIds } = this.state;
    // Check if the selected item exists in the array before adding it
    const newSelectedProductItemIds = selectedProductItemIds.find(
      cProductItemId => cProductItemId === productItemId
    )
      ? selectedProductItemIds.filter(
          sProductItemId => sProductItemId !== productItemId
        )
      : [...selectedProductItemIds, productItemId];
    this.setState({ selectedProductItemIds: newSelectedProductItemIds });
  };

  /** Toggle main table search filter */
  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Toggle selection of all product items
   * @param productItems - array of all the product items listed
   */
  toggleSelectAll = (productItems: Array<UpdateProductItemInput>): void => {
    const { allSelected } = this.state;

    // Check whether all items have been selected
    if (allSelected) {
      // Add each item to the productItemsIds
      const productItemsIds = productItems.map(
        (productItem: UpdateProductItemInput) => {
          return productItem.id;
        }
      );
      this.setState({ selectedProductItemIds: productItemsIds });
    } else {
      this.setState({ selectedProductItemIds: [] });
    }
  };

  /** Return selected services as on batch card */
  returnSelectedServices = (): React.ReactNode => {
    const { selectedServices } = this.state;
    return selectedServices.map((batchCardService: BatchCardServiceType, i) => {
      const { service } = batchCardService;
      return (
        <SelectedIcon largeImage={true} background={Colors.snow} key={i}>
          <img
            alt="serviceIcon"
            src={service ? service.icon : ServiceIcons.Editing}
          />
        </SelectedIcon>
      );
    });
  };

  /** Return product item services
   * @param productItem - product item on batch card
   */
  returnProductItemServices = (
    productItem: UpdateProductItemInput
  ): React.ReactNode => {
    const { selectedServices, batchCardServices } = this.state;
    return selectedServices.map((batchCardService: BatchCardServiceType, i) => {
      const { service } = batchCardService;
      if (service) {
        // Get the current completed services (if any) on a product item
        const completedServices: Array<string | null> =
          productItem.completedServices && productItem.completedServices.length
            ? productItem.completedServices.filter(
                (completedService: string | null) => completedService !== null
              )
            : [];

        const completedProductItemServices = updateCompletedServicesOnProductItem(
          service.id,
          completedServices
        );

        return (
          <Mutation<
            UpdateProductItemMutation,
            UpdateProductItemMutationVariables
          >
            mutation={gql(updateProductItem)}
            key={i}
          >
            {updateProductItemMutation => (
              <Composed>
                {(composedMutations: {
                  updateBatchCardServiceWithAllocation: {
                    mutation: ({  }: {}) => Promise<any>;
                  };
                }) => (
                  <SelectedIcon
                    background={
                      checkIfServiceIsCompleted(service.id, completedServices)
                        ? Colors.flumeGreen
                        : Colors.snow
                    }
                    largeImage={true}
                    onClick={() =>
                      this.updateProductItemServiceStatus(
                        productItem.id,
                        completedProductItemServices,
                        batchCardServices,
                        updateProductItemMutation,
                        composedMutations.updateBatchCardServiceWithAllocation
                          .mutation
                      )
                    }
                  >
                    <img
                      src={service.icon ? service.icon : ServiceIcons.Editing}
                    />
                  </SelectedIcon>
                )}
              </Composed>
            )}
          </Mutation>
        );
      }
      return null;
    });
  };

  /** Update the completion status of services on a product item
   * @param productItemId - id of product item to be updated
   * @param completedProductItemServices - array of service id's which represent completed services on the product item
   * @param batchCardServices - services to be performed on each product item for a particular batch card
   * @param mutationToUpdateProductItemService - graphql mutation to update the product item
   */
  updateProductItemServiceStatus = (
    productItemId: string,
    completedServices: Array<string | null>,
    BatchCardProductItemServices: Array<BatchCardServiceType>,
    mutationToUpdateProductItemService: ({}) => Promise<any>,
    updateBatchCardServiceWithAllocationMutation: ({}) => Promise<any>
  ) => {
    const { notification, batchCardServices } = this.props;

    const complete = checkIfAllServicesAreComplete(
      completedServices,
      BatchCardProductItemServices
    );

    mutationToUpdateProductItemService({
      variables: {
        input: {
          id: productItemId,
          completedAt: new Date().toISOString(),
          completedServices,
          complete
        }
      }
    })
      .then(() => {
        const completedBatchCardService = arrayofCompletedBatchCardServices(
          batchCardServices,
          this.props.batchCard.productItems
        );
        const inCompleteBatchCardServices = batchCardServices.filter(
          serviceItem => !completedBatchCardService.includes(serviceItem)
        );

        completedBatchCardService &&
          completedBatchCardService.map(async service => {
            return await updateBatchCardServiceWithAllocationMutation({
              variables: {
                input: {
                  id: service.id,
                  isComplete: true
                }
              }
            });
          });
        inCompleteBatchCardServices &&
          inCompleteBatchCardServices.map(async service => {
            return await updateBatchCardServiceWithAllocationMutation({
              variables: {
                input: {
                  id: service.id,
                  isComplete: false
                }
              }
            });
          });
        notification('Product item service successfully updated');
      })
      .catch(err => {
        this.setError(err.message);
      });
  };

  /** Return colors of selected spaces as JSX */
  returnColorsOfSelectedSpaces = (): React.ReactNode => {
    const { selectedSpaces } = this.state;

    return selectedSpaces.map((selectedSpace: UpdateSpaceInput, i) => {
      return (
        <SelectedColorForBatchCard
          key={i}
          background={
            selectedSpace ? selectedSpace.colorCode : Colors.flumeGreen
          }
          small={true}
        >
          {selectedSpace.name}
        </SelectedColorForBatchCard>
      );
    });
  };

  /** Validate form */
  validateForm = (): boolean => {
    const { name } = this.state;
    // Check for undefined or empty input fields
    if (!name) {
      this.setError('Please fill in the name field');
      return false;
    }

    return true;
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Execute mutation to update general batch details
   * @param mutationToUpdateBatchCard - graphql mutation to update batch card
   */
  executeMutation = (mutationToUpdateBatchCard: ({}) => Promise<any>) => {
    const { id, name, description } = this.state;
    const { closeModal, notification, userId, currentWeek } = this.props;
    const formattedDates = returnFormattedDates(currentWeek, 'DD MMM YYYY');

    mutationToUpdateBatchCard({
      variables: {
        input: {
          id,
          name,
          description
        }
      },
      refetchQueries: [
        {
          query: getCompanyBatchCardsByUserMainPlanner,
          variables: {
            id: userId,
            dayOne: formattedDates[0],
            dayTwo: formattedDates[1],
            dayThree: formattedDates[2],
            dayFour: formattedDates[3],
            dayFive: formattedDates[4],
            daySix: formattedDates[5],
            daySeven: formattedDates[6]
          }
        }
      ]
    })
      .then(() => {
        closeModal();
        notification('Batch card successfully updated');
      })
      .catch(err => {
        this.setError(err.message);
      });
  };

  render() {
    const {
      id,
      name,
      description,
      allSelected,
      bulkAction,
      searchFilter,
      productItemId,
      productItemImageKeys,
      previousTagDescriptions,
      noteModal,
      addTextModal,
      imageModal,
      selectedProductItemIds,
      selectedProductItems,
      selectedServices,
      selectedSpaces,
      tagModal,
      error
    } = this.state;
    const {
      itemTemplate,
      notification,
      userId,
      closeModal,
      batchCard
    } = this.props;

    return (
      <Mutation<UpdateBatchCardMutation, UpdateBatchCardMutationVariables>
        mutation={gql(updateBatchCard)}
      >
        {(updateBatchCardMutation, updateBatchCardResponse) => (
          <div>
            <GlobalModalContainer
              toggleModal={this.closeTagModal}
              title="Add tag to a product item"
              modalDisplay={
                <AddTagToProductItem
                  batchCardId={id}
                  bulkAction={bulkAction}
                  productItemId={productItemId}
                  productItemIds={selectedProductItemIds}
                  previousTagDescriptions={previousTagDescriptions}
                  notification={notification}
                  userId={userId}
                  closeModal={closeModal}
                />
              }
              modal={tagModal}
            />
            <GlobalModalContainer
              toggleModal={this.closeNoteModal}
              title="Add note to a product item"
              modalDisplay={
                <AddNoteToProductItem
                  batchCardId={id}
                  bulkAction={bulkAction}
                  productItemId={productItemId}
                  productItemIds={selectedProductItemIds}
                  notification={notification}
                  previousNotes={returnPreviousNotes(
                    batchCard.productItems.items,
                    productItemId
                  )}
                  userId={userId}
                  closeModal={this.closeNoteModal}
                />
              }
              modal={noteModal}
            />
            <GlobalModalContainer
              toggleModal={this.closeAddTextModal}
              title="Add text to a product item"
              largeModal={true}
              modalDisplay={
                <AddTextToProductItem
                  batchCardId={id}
                  bulkAction={bulkAction}
                  productItemId={productItemId}
                  productItemIds={selectedProductItemIds}
                  productItemImageKeys={productItemImageKeys}
                  notification={notification}
                  previousContent={returnPreviousTextContent(
                    batchCard.productItems.items,
                    productItemId
                  )}
                  userId={userId}
                  closeModal={this.closeAddTextModal}
                />
              }
              modal={addTextModal}
            />
            <GlobalModalContainer
              toggleModal={this.closeImageModal}
              title={
                bulkAction ? 'Add images to items' : 'Add images to an item'
              }
              modalDisplay={
                <AddImageToProductItem
                  batchCardId={id}
                  bulkAction={bulkAction}
                  productItemId={productItemId}
                  productItemIds={selectedProductItemIds}
                  productItems={returnRelevantProductItems(
                    selectedProductItems,
                    selectedProductItemIds
                  )}
                  notification={notification}
                  productItemImageKeys={productItemImageKeys}
                  userId={userId}
                  closeModal={this.closeImageModalSuccess}
                />
              }
              modal={imageModal}
            />
            <Form
              onSubmit={e => {
                e.preventDefault();
                if (this.validateForm()) {
                  this.executeMutation(updateBatchCardMutation);
                }
              }}
            >
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <Label for="name">Batch Card z</Label>
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      id="name"
                      placeholder="Name"
                      onChange={e =>
                        this.setState({
                          name: e.target.value
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="spaces">Spaces</Label>
                    <br />
                    {selectedSpaces.length > 0 && (
                      <IconsContainer>
                        {this.returnColorsOfSelectedSpaces()}
                      </IconsContainer>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="services">Services</Label>
                    <br />
                    {selectedServices.length > 0 && (
                      <IconsContainer>
                        {this.returnSelectedServices()}
                      </IconsContainer>
                    )}
                  </FormGroup>
                </Col>

                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      value={description || ''}
                      id="description"
                      placeholder="Add a detailed description"
                      onChange={e =>
                        this.setState({
                          description: e.target.value || null
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="images">Images</Label>
                    <br />
                    <BatchCardImages
                      batchCard={batchCard}
                      notification={notification}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <TableHeaderContainer>
                    <ProductActionsContainer>
                      <StyledButton
                        type="button"
                        label={
                          <div>
                            Search{' '}
                            <MdSearch
                              size="1.3em"
                              color={Colors.flumeDarkGreen}
                            />
                          </div>
                        }
                        width="auto"
                        onClick={this.toggleSearchFilter}
                        color={Colors.flumeDarkGreen}
                        background={Colors.flumeGreen}
                      />
                      <Select
                        onChange={e =>
                          this.selectBulkAction(e, selectedProductItemIds)
                        }
                        options={BULK_ACTION_OPTIONS}
                        isSearchable={true}
                        placeholder="Bulk Actions"
                        className="select-styling"
                      />
                    </ProductActionsContainer>
                  </TableHeaderContainer>
                  <Table
                    data={sortArrayAlphabetically(selectedProductItems)}
                    columns={[
                      {
                        id: 'all-selected',
                        Header: () => (
                          <Checkbox
                            onClick={() => {
                              this.setState({ allSelected: !allSelected }, () =>
                                this.toggleSelectAll(selectedProductItems)
                              );
                            }}
                          >
                            {allSelected ? (
                              <MdDone size="0.95em" color={Colors.grey} />
                            ) : null}
                          </Checkbox>
                        ),
                        accessor: (productItem: UpdateProductItemInput) => {
                          const thisProductItem = selectedProductItemIds.find(
                            cProductItemId => cProductItemId === productItem.id
                          );

                          return (
                            <CircleIcon
                              selected={thisProductItem || false}
                              onClick={() =>
                                this.selectProductItem(productItem.id)
                              }
                            />
                          );
                        },
                        sortable: false,
                        filterable: false,
                        width: 60
                      },
                      ...returnNewProductItemColumns(
                        itemTemplate,
                        searchFilter
                      ),
                      {
                        id: 'product-services',
                        Header: 'SERVICES',
                        accessor: (productItem: UpdateProductItemInput) => {
                          return selectedServices.length > 0 ? (
                            <ServiceIconsContainer>
                              {this.returnProductItemServices(productItem)}
                            </ServiceIconsContainer>
                          ) : null;
                        },
                        sortable: false,
                        filterable: false
                      },
                      {
                        id: 'row-actions',
                        Header: '',
                        accessor: (productItem: UpdateProductItemInput) => {
                          return (
                            <RowActions
                              openModal={this.openTagModal}
                              openNoteModal={this.openNoteModal}
                              openAddTextModal={this.openAddTextModal}
                              openImageModal={this.openImageModal}
                              productItemId={productItem.id}
                              previousTagDescriptions={checkIfArrayOfStrings(
                                productItem.previousTagDescriptions
                              )}
                              productItemImageKeys={checkIfArrayOfStrings(
                                productItem.imageKeys
                              )}
                            />
                          );
                        },
                        sortable: false,
                        filterable: false,
                        width: 30
                      }
                    ]}
                    defaultPageSize={5}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} md={3} lg={3}>
                  <FormGroup>
                    <Button type="submit">
                      {updateBatchCardResponse.loading ? (
                        <Loader />
                      ) : (
                        'Update Batch Card'
                      )}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              {error && <ErrorMessage errorMessage={error} />}
            </Form>
          </div>
        )}
      </Mutation>
    );
  }
}

export default SavedBatchCard;
