import * as React from 'react';
import * as validator from 'validator';
import * as moment from 'moment';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { MdCopyright } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Presentation/UI */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Button from '../../Components/Styled/Button';
import Loader from '../../Components/Loader';
import GlobalModalContainer from '../../Components/Modal';
import TermsAndConditions from '../../Components/TermsAndConditions';
import Span from '../../Components/Styled/Span';
import StyledLink from '../../Components/Styled/Link';
import { Auth } from 'aws-amplify';

/** Local components */
import ForgotPassword from './ForgotPassword';
import SetNewPassword from './SetNewPassword';
import AccountVerificationForm from './AccountVerificationForm';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import {
  COGNITO_CHALLENGES,
  COGNITO_VERIFICATION_ATTRIBUTE,
  SUPER_USER_GROUP,
  DISABLED_USER_MSG
} from '../../Utils/Consts';
import { getGroupFromToken } from '../../Utils/Helpers';

type Props = RouteComponentProps & {
  history: History;
};

type State = {
  email: string;
  password: string;
  session: string;
  loading: boolean;
  modal: boolean;
  newPasswordModal: boolean;
  termsModal: boolean;
  error: string | null;
  verifyAccount: boolean;
};

class LoginForm extends React.Component<Props, State> {
  state: State = {
    email: '',
    password: '',
    session: '',
    loading: false,
    modal: false,
    newPasswordModal: false,
    termsModal: false,
    error: null,
    verifyAccount: false
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;
    if (name === 'email') {
      this.setState({
        email: value
      });
    } else if (name === 'password') {
      this.setState({
        password: value
      });
    }
  };

  /** Handle sign in
   * @param updateUserId - function used to update the user id in the app state
   * @param updateUserRole - function used to update the user's role in the app state
   * @param updateUserEmail - function used to update the user's email in the app state
   */
  signIn = (
    updateUserId: (jwtToken: string) => void,
    updateUserRole: (jwtToken: string) => void,
    updateUserEmail: (jwtToken: string) => void
  ) => {
    const { email, password } = this.state;

    Auth.signIn(email, password)
      .then(user => {
        const { history } = this.props;

        // Authenticated user
        if (user.signInUserSession) {
          Auth.currentUserInfo()
            .then(data => {
              if (!data.attributes.email_verified) {
                Auth.verifyCurrentUserAttribute(COGNITO_VERIFICATION_ATTRIBUTE)
                  .then(data => {
                    this.openAccountVerificationModal(user.session);
                  })
                  .catch(() => {
                    this.setError('Unable to send verification code');
                  });
                return false;
              }

              // Authorised user token
              updateUserId(user.signInUserSession.accessToken.jwtToken);
              updateUserRole(user.signInUserSession.accessToken.jwtToken);
              updateUserEmail(user.signInUserSession.accessToken.jwtToken);

              const userGroup = getGroupFromToken(
                user.signInUserSession.accessToken.jwtToken
              );
              const { from } =
                userGroup === SUPER_USER_GROUP
                  ? this.props.location.state || {
                      from: {
                        pathname: '/manage/teams-and-roles/roles'
                      }
                    }
                  : {
                      from: {
                        pathname: '/'
                      }
                    };

              history.push(from);
              return true;
            })
            .catch(err => {
              this.setError('Unable to send verification code');
            });
        }

        // New user with temporary password
        if (user.challengeName === COGNITO_CHALLENGES.newPasswordRequired) {
          this.openNewPasswordModal(user.Session);
        }
      })
      .catch(err => {
        if (
          err.code === 'NotAuthorizedException' &&
          err.message === DISABLED_USER_MSG
        ) {
          this.setError(
            'Your account is Inactive please contact your Administrator'
          );
        } else {
          this.setError('Please enter a valid email and password');
        }
        this.setState({ loading: false });
      });
  };

  validateForm = (): boolean => {
    const { email, password } = this.state;
    // Check for undefined or empty input fields
    if (!email || !password) {
      this.setError('Please enter a valid email and password.');
      return false;
    }

    // Validate email
    if (!validator.isEmail(email)) {
      this.setError('Please enter a valid email address.');
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState({
      error
    });
  };

  // Close modal
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  // Open modal
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  /** Close new password modal */
  closeNewPasswordModal = (): void => {
    this.setState({
      newPasswordModal: false,
      loading: false,
      email: '',
      password: '',
      session: ''
    });
  };

  /** Open modal */
  openNewPasswordModal = (session: string): void => {
    this.setState({
      session,
      newPasswordModal: true
    });
  };

  /** Open modal */
  openAccountVerificationModal = (session: string): void => {
    this.setState({
      session,
      verifyAccount: true
    });
  };

  /** Close new password modal */
  closeAccountVerificationModal = (): void => {
    this.setState({
      verifyAccount: false,
      loading: false,
      password: '',
      session: ''
    });
  };

  /** Open modal with terms and conditions */
  openTermsAndConditionsModal = (): void => {
    this.setState({
      termsModal: true
    });
  };

  /** Close modal with terms and conditions */
  closeTermsAndConditionsModal = (): void => {
    this.setState({
      termsModal: false
    });
  };

  render() {
    const {
      email,
      password,
      session,
      loading,
      error,
      modal,
      newPasswordModal,
      termsModal,
      verifyAccount
    } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ updateUserId, updateUserRole, updateUserEmail }) => {
          return (
            <FullWidthContainer>
              <GlobalModalContainer
                toggleModal={this.closeModal}
                title="Reset Password"
                modalDisplay={<ForgotPassword closeModal={this.closeModal} />}
                modal={modal}
              />
              <GlobalModalContainer
                toggleModal={this.closeNewPasswordModal}
                title="New Password Required"
                modalDisplay={
                  <SetNewPassword
                    closeModal={this.closeNewPasswordModal}
                    username={email}
                    session={session}
                    challengeName={COGNITO_CHALLENGES.newPasswordRequired}
                  />
                }
                modal={newPasswordModal}
              />
              <GlobalModalContainer
                toggleModal={this.closeAccountVerificationModal}
                title="Account Verification Required"
                modalDisplay={<AccountVerificationForm />}
                modal={verifyAccount}
              />
              <TermsAndConditions
                modal={termsModal}
                closeModal={this.closeTermsAndConditionsModal}
              />
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  if (this.validateForm()) {
                    this.setState({ loading: true });
                    this.signIn(updateUserId, updateUserRole, updateUserEmail);
                  }
                }}
              >
                <FormGroup>
                  <Label for="userEmail">Email Address</Label>
                  <Input
                    type="email"
                    name="email"
                    value={email.toLowerCase()}
                    id="userEmail"
                    placeholder="john@mail.com"
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="userPassword">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    value={password}
                    id="userPassword"
                    placeholder="Password"
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup className="right-align">
                  <Span
                    onClick={this.openModal}
                    text="Forgot Password?"
                    color={Colors.flumeGreen}
                    pointer={true}
                  />
                </FormGroup>
                <br />
                <br />
                <Button
                  type="submit"
                  disabled={loading}
                  label={!loading ? 'Sign In' : <Loader />}
                  background={Colors.flumeGreen}
                />
                <br />
                {error && <ErrorMessage errorMessage={error} />}
                <br />
                <br />
                <FullWidthContainer align="center">
                  <Span text="New To Flume?" style={{ marginRight: '10px' }} />
                  {'  '}
                  <StyledLink
                    to="/sign-up"
                    label="Sign Up"
                    color={Colors.flumeGreen}
                  />
                  <br />
                  <br />
                  <br />
                  <a
                    href="https://flume.cloud/flume-software-terms-conditions/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Span
                      text="Read Terms &amp; Conditions"
                      color={Colors.flumeGreen}
                      pointer={true}
                    />
                  </a>
                  <br />
                  <br />
                  <Span text="FLUME" />{' '}
                  <MdCopyright
                    size="1em"
                    style={{ position: 'relative', top: '-2px' }}
                  />{' '}
                  {moment().format('YYYY')}
                </FullWidthContainer>
              </Form>
            </FullWidthContainer>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withRouter(LoginForm);
