import * as React from 'react';
import { Row, Col, FormGroup, Input, Tooltip } from 'reactstrap';
import { IoIosTrash } from 'react-icons/io';

/** Presentation/UI */
import {
  SelectedIconServiceModal,
  IconLibraryContainer
} from '../../Components/Styled/ListViewElements';
import GlobalModalContainer from '../../Components/Modal';
import ChooseServiceIconModal from './ChooseServiceIconModal';

/** Themes */
import { Colors, ServiceIcons } from '../../Themes';

/** Custom Types */
import { ServiceType } from '../../CustomTypes';

/* helper methods */
import { convertPixelsToRem } from '../../Utils/Helpers';

type State = {
  renderTooltip: boolean;
  chooseIconModalOpen: boolean;
  modalOpenedOnce: boolean;
  activeIcon?: string;
};

type Props = {
  id: number;
  service: ServiceType;
  showToolTip?: boolean;
  updateService(id: number, name?: string, icon?: string): void;
  deleteServiceRow(id: number): void;
};

class ServiceRow extends React.Component<Props, State> {
  state = {
    renderTooltip: false,
    chooseIconModalOpen: false,
    modalOpenedOnce: false,
    activeIcon: this.props.service.icon
  };

  showTimeout = 0;

  componentDidMount() {
    /* Due to the animation in the reactstrap modal,
     the dom nodes position are not calculated properly if we render the tooltip 
     immidiately hence the timeout so as to render the tooltip after the animation is done
     */
    this.showTimeout = window.setTimeout(() => {
      this.setState({ renderTooltip: true });
    }, 800);
  }

  componentWillUnmount() {
    window.clearTimeout(this.showTimeout);
  }

  /** Open modal */
  openIconSelectModal = (): void => {
    this.setState({
      chooseIconModalOpen: true,
      modalOpenedOnce: true
    });
  };

  /** Close modal */
  closeIconSelectModal = (): void => {
    this.setState({
      chooseIconModalOpen: false
    });
  };

  handleIconSelect = (icon: string) => {
    this.setState({ activeIcon: icon, chooseIconModalOpen: false });

    this.props.updateService(this.props.id, undefined, icon);
  };

  render() {
    const {
      activeIcon,
      renderTooltip,
      chooseIconModalOpen,
      modalOpenedOnce
    } = this.state;
    const serviceIcons = Object.keys(ServiceIcons).map(
      key => ServiceIcons[key]
    );
    // hide the tooltip once icon is choosen
    const hideToolTipIfIconChoosen = !modalOpenedOnce && this.props.showToolTip;

    return (
      <Row>
        <GlobalModalContainer
          toggleModal={this.closeIconSelectModal}
          mediumModal={true}
          title="Choose an icon for your new service"
          modalDisplay={
            <ChooseServiceIconModal
              handleIconSelect={this.handleIconSelect}
              serviceIcons={serviceIcons}
              activeIcon={activeIcon}
            />
          }
          modal={chooseIconModalOpen}
        />
        <Col xs={12} md={8} lg={8}>
          <FormGroup>
            <Input
              type="text"
              placeholder="Service name"
              onChange={e =>
                this.props.updateService(this.props.id, e.target.value)
              }
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <FormGroup>
            <IconLibraryContainer onClick={this.openIconSelectModal}>
              <div
                style={{
                  display: 'flex'
                }}
              >
                {renderTooltip ? (
                  <Tooltip
                    placement="top"
                    isOpen={hideToolTipIfIconChoosen}
                    target="iconSelect"
                    style={{
                      backgroundColor: Colors.sirocco
                    }}
                  >
                    Choose icon here
                  </Tooltip>
                ) : null}
                <SelectedIconServiceModal
                  id="iconSelect"
                  highlighted={true}
                  background={Colors.mystic}
                >
                  <img alt="" src={this.props.service.icon} />
                </SelectedIconServiceModal>
                <IoIosTrash
                  viewBox="0 60 512 512"
                  size={convertPixelsToRem(42)}
                  color={Colors.pink}
                  onClick={() => this.props.deleteServiceRow(this.props.id)}
                  style={{
                    position: 'relative',
                    cursor: 'pointer'
                  }}
                />
              </div>
            </IconLibraryContainer>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default ServiceRow;
