import * as React from 'react';
import * as moment from 'moment';
import * as validator from 'validator';
import { TiMail, TiLockClosedOutline } from 'react-icons/ti';
import { MdCopyright } from 'react-icons/md';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';
import { Auth } from 'aws-amplify';

/** Presentation/UI */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import TermsAndConditions from '../../Components/TermsAndConditions';
import LottieWrapper from '../../Components/Anim/LottieWrapper';
import GlobalModalContainer from '../../Components/Modal';
import Loader from '../../Components/Loader';
import Span from '../../Components/Styled/Span';
import Link from '../../Components/Styled/Link';
import SignUpContainer from '../../Components/Styled/SignUpContainer';
import {
  StyledPopOver,
  StyledPopoverHeader,
  StyledPopOverBody,
  StyledList
} from '../../Components/Styled/PopOver';

/** Local components */
import ConfirmationCodeForm from './ConfirmationCodeForm';

/** Custom Types */
import { ConfirmationCodeError, SignUpViews, Error } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

/** API */
import { apiRequest } from '../../Utils/API';
import { COGNITO_ADD_USER_TO_GROUP } from '../../Utils/LambdaEndpoints';
import { HTTP_METHODS, SUPER_USER_GROUP } from '../../Utils/Consts';

/** AWS configå */
import AppSyncConfig from '../../aws-exports';

type Props = {
  createUserMutation: ({  }: {}) => Promise<any>; // eslint-disable-line
};

type State = {
  email: string;
  password: string;
  error: Error;
  validationError: string;
  confirmationCodeError: ConfirmationCodeError | null;
  loading: boolean;
  showPassword: boolean;
  showOptionToResendCode: boolean;
  modal: boolean;
  termsModal: boolean;
  view: SignUpViews;
  popoverOpen: boolean;
  isDirty: boolean;
  digits: boolean;
  uppercase: boolean;
  lowercase: boolean;
  symbols: boolean;
  min: boolean;
};

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema
  .is()
  .min(8) // Minimum length 8
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits() // Must have digits
  .has()
  .symbols(); // Must have symbols

class LoginForm extends React.Component<Props, State> {
  state: State = {
    view: 'form',
    email: '',
    password: '',
    loading: false,
    showPassword: false,
    showOptionToResendCode: false,
    modal: false,
    termsModal: false,
    error: null,
    validationError: '',
    confirmationCodeError: null,
    popoverOpen: false,
    isDirty: false,
    digits: false,
    uppercase: false,
    lowercase: false,
    symbols: false,
    min: false
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  // Sign up user
  signUp = () => {
    const { email, password } = this.state;
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email
      }
    })
      .then(() => {
        this.setState({ loading: false });
        this.openModal();
      })
      .catch(err => {
        this.setState({ loading: false });
        this.setError(err.message);
        if (
          err.code === 'UsernameExistsException' ||
          err.name === 'UsernameExistsException'
        ) {
          this.setState({
            showOptionToResendCode: true
          });
        }
      });
  };

  // Verify user email account and create user
  confirmSignUp = (confirmationCode: string) => {
    const { email, password } = this.state;
    const { createUserMutation } = this.props;

    Auth.confirmSignUp(email, confirmationCode)
      .then(data => {
        if (data === 'SUCCESS') {
          Auth.signIn(email, password)
            .then(async user => {
              const { sub } = user.signInUserSession.accessToken.payload;

              const bodyParams = {
                groupName: SUPER_USER_GROUP,
                userPoolId: AppSyncConfig.aws_user_pools_id,
                email
              };

              await apiRequest(
                COGNITO_ADD_USER_TO_GROUP,
                HTTP_METHODS.POST,
                bodyParams
              ).catch(e => {
                this.setState({ loading: false });
                this.setError(e.message);
              });

              createUserMutation({
                variables: {
                  input: {
                    id: sub,
                    createdAt: new Date().toISOString(),
                    email
                  }
                }
              });
              this.closeModal();
            })
            .catch(err => {
              this.setState({
                confirmationCodeError: err
              });
            });
          this.setState({ view: 'success' });
        }
      })
      .catch(err => {
        this.setState({
          confirmationCodeError: err
        });
      });
  };

  // Resend verification code
  resendConfirmSignUp = (email: string) => {
    Auth.resendSignUp(email)
      .then(() => {
        this.openModal();
      })
      .catch(err => {
        this.setError(err.message);
      });
  };

  // Close modal
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  // Open modal
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  /** Open modal with terms and conditions */
  openTermsAndConditionsModal = (): void => {
    this.setState({
      termsModal: true
    });
  };

  /** Close modal with terms and conditions */
  closeTermsAndConditionsModal = (): void => {
    this.setState({
      termsModal: false
    });
  };

  // Handle change
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {
      name,
      value
    }: {
      name: string;
      value: string;
    } = e.currentTarget;
    const validationRulesErrors = schema.validate(value, { list: true });

    switch (name) {
      case 'email':
        this.setState({ email: value });
        break;

      case 'password':
        if (validationRulesErrors.length > 0) {
          this.setState({
            validationError: this.formatPasswordValidateError(
              validationRulesErrors
            )
          });
        } else {
          this.setState({
            min: true,
            lowercase: true,
            uppercase: true,
            symbols: true,
            digits: true,
            validationError: ''
          });
        }
        this.setState({ password: value });
        break;
      default:
        break;
    }
  };

  validateForm = (): boolean => {
    const { email, password } = this.state;
    // Check for undefined or empty input fields
    if (!email || !password) {
      this.setError('Please enter a valid email and password.');
      return false;
    }

    // Validate email
    if (!validator.isEmail(email)) {
      this.setError('Please enter a valid email address.');
      return false;
    }

    return true;
  };

  formatPasswordValidateError = errors => {
    let validationError = '';

    for (let i = 0; i < errors.length; i++) {
      if (errors[i] === 'min') {
        validationError = 'password length should be a at least 8 characters';

        this.setState({ min: false });

        break;
      } else if (errors[i] === 'lowercase') {
        validationError = 'password should contain lowercase letters';

        this.setState({ min: true, lowercase: false });

        break;
      } else if (errors[i] === 'uppercase') {
        validationError = 'password should contain uppercase letters';

        this.setState({
          min: true,
          lowercase: true,
          uppercase: false
        });

        break;
      } else if (errors[i] === 'symbols') {
        validationError = 'password should contain symbols';

        this.setState({
          min: true,
          lowercase: true,
          uppercase: true,
          digits: true,
          symbols: false
        });

        break;
      } else if (errors[i] === 'digits') {
        validationError = 'password should contain digits';

        this.setState({
          min: true,
          lowercase: true,
          uppercase: true,
          digits: false
        });

        break;
      }
    }

    return validationError;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: '' });
        }, 3000);
      }
    );
  };

  // Toggle password visibility
  togglePasswordVisibility = (): void => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  togglePopOver = () => {
    const { min, digits, symbols, uppercase, lowercase } = this.state;
    this.setState({
      popoverOpen: !(min && digits && symbols && uppercase && lowercase)
    });
  };
  // Render eye icon
  renderEyeIcon = () => {
    const { showPassword } = this.state;

    if (showPassword) {
      return (
        <IoIosEye
          size="1.5em"
          style={{
            marginRight: '10px',
            marginTop: '10px'
          }}
        />
      );
    }

    return (
      <IoIosEyeOff
        size="1.5em"
        style={{
          marginRight: '10px',
          marginTop: '10px'
        }}
      />
    );
  };

  // Return Loader
  renderLoader = () => {
    return (
      <FullWidthContainer align="center">
        <Loader size={150} color={Colors.flumeGreen} />
        <br />
        <br />
        <p className="center-align">Please wait...</p>
      </FullWidthContainer>
    );
  };

  // Render view
  renderView = () => {
    const {
      email,
      password,
      view,
      showPassword,
      loading,
      popoverOpen,
      min,
      digits,
      uppercase,
      lowercase,
      symbols,
      validationError
    } = this.state;
    const fieldType = showPassword ? 'text' : 'password';

    if (view === 'success') {
      return (
        <FullWidthContainer>
          <LottieWrapper
            loop={false}
            width={280}
            height={220}
            anim={require('../../LottieFiles/success1.json')}
          />
          <br />
          <br />
          <p className="center-align">
            Your account has been verified.
            <br />
            <br />
            <Link
              to="/company-setup"
              label={<Button>Continue to company setup</Button>}
              color={Colors.flumeGreen}
            />
          </p>
        </FullWidthContainer>
      );
    }

    return (
      <Form
        onSubmit={e => {
          e.preventDefault();
          if (this.validateForm()) {
            this.setState({ loading: true });
            this.signUp();
          }
        }}
      >
        <FormGroup>
          <Label for="userEmail">Email Address</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <TiMail size="1.5em" />
            </InputGroupAddon>
            <Input
              type="email"
              name="email"
              value={email.toLowerCase()}
              id="userEmail"
              placeholder=""
              onChange={this.handleChange}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="userPassword">Password</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <TiLockClosedOutline size="1.5em" />
            </InputGroupAddon>
            <Input
              type={fieldType}
              name="password"
              value={password}
              id="userPassword"
              placeholder=""
              className={validationError && 'input-error'}
              onChange={this.handleChange}
              onFocus={this.togglePopOver}
              onBlur={this.togglePopOver}
            />
            <InputGroupAddon
              onClick={this.togglePasswordVisibility}
              addonType="append"
            >
              {this.renderEyeIcon()}
            </InputGroupAddon>
          </InputGroup>
          <StyledPopOver
            placement="top"
            isOpen={popoverOpen}
            target="userPassword"
          >
            <StyledPopoverHeader>Password Policy</StyledPopoverHeader>
            <StyledPopOverBody>
              <h6>Your password should contain: </h6>
              <StyledList>
                <li className={min ? 'strike-through' : ''}>
                  Minimum length of 8 characters
                </li>
                <li className={digits ? 'strike-through' : ''}>
                  Numerical characters (0-9)
                </li>
                <li className={symbols ? 'strike-through' : ''}>
                  Special characters
                </li>
                <li className={uppercase ? 'strike-through' : ''}>
                  Uppercase letter
                </li>
                <li className={lowercase ? 'strike-through' : ''}>
                  Lowercase letter
                </li>
              </StyledList>
            </StyledPopOverBody>
          </StyledPopOver>
          <div className={validationError ? 'input-group-error' : 'hidden'}>
            <span>{validationError}</span>
          </div>
        </FormGroup>
        <br />
        <FullWidthContainer align="center">
          By signing up, you are agreeing to our{' '}
          <Span
            onClick={this.openTermsAndConditionsModal}
            text="Terms &amp; Conditions"
            pointer={true}
            color={Colors.flumeGreen}
          />
        </FullWidthContainer>
        <br />
        <br />

        {this.state.showOptionToResendCode ? (
          <Button
            type="button"
            onClick={() => this.resendConfirmSignUp(this.state.email)}
          >
            {!loading ? <span>Resend Verification Code</span> : <Loader />}
          </Button>
        ) : (
          <Button disabled={loading}>
            {!loading ? 'Sign Up' : <Loader />}
          </Button>
        )}
        <br />
        <br />
        <br />
        <FullWidthContainer align="center">
          <Span
            text="Already have an account?"
            style={{ marginRight: '10px' }}
          />
          {'  '}
          <Link to="/sign-in" label="Sign In" color={Colors.flumeGreen} />
        </FullWidthContainer>
      </Form>
    );
  };

  render() {
    const { modal, termsModal, confirmationCodeError, error } = this.state;
    return (
      <SignUpContainer>
        <GlobalModalContainer
          toggleModal={this.closeModal}
          title="Please Check Your Email"
          modalDisplay={
            <ConfirmationCodeForm
              confirmSignUp={this.confirmSignUp}
              confirmationCodeError={confirmationCodeError}
            />
          }
          modal={modal}
        />
        <TermsAndConditions
          modal={termsModal}
          closeModal={this.closeTermsAndConditionsModal}
        />
        {this.renderView()}
        {error && <ErrorMessage errorMessage={error} />}
        <br />
        <FullWidthContainer align="center">
          <Span text="FLUME" />{' '}
          <MdCopyright
            size="1em"
            style={{
              position: 'relative',
              top: '-2px'
            }}
          />{' '}
          {moment().format('YYYY')}
        </FullWidthContainer>
      </SignUpContainer>
    );
  }
}

export default LoginForm;
