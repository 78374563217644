/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { adopt } from 'react-adopt';

/** GraphQL */
import {
  createBatchCardService,
  deleteBatchCardService,
  updateBatchCard
} from '../../graphql/mutations';

/** Types */
import {
  CreateBatchCardServiceMutation,
  CreateBatchCardServiceMutationVariables,
  DeleteBatchCardServiceMutation,
  DeleteBatchCardServiceMutationVariables,
  UpdateBatchCardMutation,
  UpdateBatchCardMutationVariables
} from '../../API';

/** Mutation component for updating backlog batch card */
const updateBacklogBatchCard = (props: { render: ({}) => Mutation }) => (
  <Mutation<UpdateBatchCardMutation, UpdateBatchCardMutationVariables>
    mutation={gql(updateBatchCard)}
  >
    {(mutation, result) => props.render({ mutation, result })}
  </Mutation>
);

/** Mutation components for creating a batch card service, removing a batch card service
 *  and creating relations between the batch card and company services
 */
const addBatchCardService = (props: { render: ({}) => Mutation }) => (
  <Mutation<
    CreateBatchCardServiceMutation,
    CreateBatchCardServiceMutationVariables
  >
    mutation={gql(createBatchCardService)}
  >
    {(mutation, result) => props.render({ mutation, result })}
  </Mutation>
);

const removeBatchCardService = (props: { render: ({}) => Mutation }) => (
  <Mutation<
    DeleteBatchCardServiceMutation,
    DeleteBatchCardServiceMutationVariables
  >
    mutation={gql(deleteBatchCardService)}
  >
    {(mutation, result) => props.render({ mutation, result })}
  </Mutation>
);

const Composed = adopt({
  addBatchCardService,
  removeBatchCardService,
  updateBacklogBatchCard
});

export default Composed;
