/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import * as validator from 'validator';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Select from 'react-select';
import {
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  Input,
  ButtonGroup
} from 'reactstrap';

/** GraphQL */
import { createUser } from '../../graphql/mutations';
import { UpdateRoleInput, Permission } from '../../API';
import { getCompanyUsersByUser } from '../../graphql/custom-queries';

import {
  CreateUserMutation,
  CreateUserMutationVariables,
  UserStatus
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Custom Types */
import { Error } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import {
  USER_STATUS_OPTIONS,
  USER_ROLES,
  USER_GROUP,
  NON_USER_GROUP,
  SUPER_USER_GROUP,
  HTTP_METHODS
} from '../../Utils/Consts';
import { sortArrayAlphabetically } from '../../Utils/Helpers';
import { COGNITO_CREATE_USER, SEND_EMAIL } from '../../Utils/LambdaEndpoints';

/** API */
import AppSyncConfig from '../../aws-exports';
import { apiRequest } from '../../Utils/API';

const ToggleButtonsContainer = styled.div`
  & .leftToggleBtn {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  & .rightToggleBtn {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`;

type SelectType = { value: UserStatus; label: string };
type SelectRoleType = { value: string; label: string; permission: string };

type Props = {
  companyId: string;
  userEmail: string;
  userId: string;
  roles?: Array<UpdateRoleInput>;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  username: string;
  userStatus: SelectType;
  userRole: SelectRoleType;
  inviteUser: boolean;
  loading: boolean;
  error: Error;
};

class AddUserForm extends React.Component<Props, State> {
  state: State = {
    username: '',
    userStatus: { value: UserStatus.INACTIVE, label: 'Inactive' },
    userRole: { value: '', label: '', permission: '' },
    inviteUser: true,
    loading: false,
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Select user status
   * @param userStatus - space status (active/inactive)
   */
  selectSpaceStatus = (userStatus: SelectType): void => {
    this.setState({ userStatus });
  };

  /** Select user role
   * @param userRole - user role based on created company roles
   */
  selectRoleType = (userRole: SelectRoleType): void => {
    this.setState({ userRole });
  };

  /** Company role options */
  listRoleOptions = (): Array<SelectRoleType> => {
    const { roles } = this.props;
    if (roles && roles.length) {
      const sortedRoles = sortArrayAlphabetically(roles);
      return sortedRoles.map(
        // @ts-ignore
        (role: { id: string; name: string; permission: Permission }) => {
          return {
            value: role.id,
            label: role.name,
            permission: role.permission
          };
        }
      );
    }

    return [];
  };

  /** Validation */
  validateForm = (): boolean => {
    const { username, userRole } = this.state;

    if (!userRole.value || userRole.value === '') {
      this.setError('Please select a company role');
      return false;
    }

    // Validate email
    if (!validator.isEmail(username)) {
      this.setError('Please enter a valid email address.');
      return false;
    }

    return true;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Return AWS Cognito Group */
  returnCognitoPoolUserGroup = (permission: string): string => {
    switch (permission) {
      case USER_ROLES.user:
        return USER_GROUP;
      case USER_ROLES.superUser:
        return SUPER_USER_GROUP;
      case USER_ROLES.nonUser:
        return NON_USER_GROUP;
      default:
        return USER_GROUP;
    }
  };

  /** Create a new user */
  createNewUser = async (createUserMutation: ({}) => Promise<any>) => {
    const {
      companyId,
      closeModal,
      notification,
      userEmail,
      userId
    } = this.props;
    const { username, userRole, userStatus, inviteUser } = this.state;

    this.setState({ loading: true });
    const bodyParams = {
      groupName: this.returnCognitoPoolUserGroup(userRole.permission),
      userPoolId: AppSyncConfig.aws_user_pools_id,
      email: username,
      inviteUser
    };

    // create user in cognito pool
    const user = await apiRequest(
      COGNITO_CREATE_USER,
      HTTP_METHODS.POST,
      bodyParams
    ).catch(e => {
      this.setState({ loading: false });
      this.setError(e.message);
    });

    // create the user in dynamodb
    if (user && user.Attributes && user.Attributes.length) {
      const id = user.Attributes[0].Value;

      createUserMutation({
        variables: {
          input: {
            id,
            createdAt: new Date().toISOString(),
            email: username,
            status: userStatus.value,
            userUserRoleId: userRole.value,
            userCompanyId: companyId,
            inviteAccepted: false,
            invitedBy: userEmail,
            inviteLater: !inviteUser
          }
        },
        refetchQueries: [
          {
            query: getCompanyUsersByUser,
            variables: {
              id: userId
            }
          }
        ]
      })
        .then(async () => {
          // send an email to admin about the invitation sent
          await apiRequest(SEND_EMAIL, HTTP_METHODS.POST, {
            recipients: [userEmail],
            subject: `User Invite Sent To ${username}`,
            message: `You just invited ${username} to your workspace on Flume.`
          }).catch(e => {
            this.setError(e.message);
          });

          this.setState({ loading: false });
          closeModal();
          notification(`Added ${username} as a ${userRole.label}`);
        })
        .catch(err => {
          this.setState({ loading: false });
          this.setError(err.message);
        });
    }

    if (typeof user === 'string') {
      // cognito response
      this.setState({ loading: false });
      this.setError('User account already exists');
    }
  };

  /**
   * Toggle whether or not to invite a user to the platform via email
   */
  toggleInviteUser = (inviteUser: boolean) => {
    this.setState({ inviteUser });
  };

  render() {
    const {
      username,
      userStatus,
      userRole,
      inviteUser,
      error,
      loading
    } = this.state;

    return (
      <Mutation<CreateUserMutation, CreateUserMutationVariables>
        mutation={gql(createUser)}
      >
        {createUserMutation => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateForm()) {
                this.createNewUser(createUserMutation);
              }
            }}
          >
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="username">Email Address</Label>
                  <Input
                    type="email"
                    name="username"
                    value={username.toLowerCase()}
                    id="username"
                    placeholder="Email"
                    onChange={e => this.setState({ username: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="userRole">User Role</Label>
                  <Select
                    // @ts-ignore
                    onChange={this.selectRoleType}
                    options={this.listRoleOptions()}
                    value={userRole}
                    isSearchable={true}
                    className="select-styling"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="userStatus">User Status</Label>
                  <Select
                    // @ts-ignore
                    onChange={this.selectSpaceStatus}
                    options={USER_STATUS_OPTIONS}
                    value={userStatus}
                    isSearchable={true}
                    className="select-styling"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6} lg={6}>
                <FormGroup>
                  <Label>Do you want to invite this user?</Label>
                  <ToggleButtonsContainer>
                    <ButtonGroup>
                      <StyledButton
                        type="button"
                        label="Yes"
                        width="100px"
                        className="leftToggleBtn"
                        onClick={() => this.toggleInviteUser(true)}
                        color={inviteUser ? Colors.snow : Colors.flumeDarkGreen}
                        background={
                          inviteUser ? Colors.flumeDarkGreen : Colors.aquaHaze
                        }
                      />
                      <StyledButton
                        type="button"
                        label="No"
                        width="100px"
                        className="rightToggleBtn"
                        onClick={() => this.toggleInviteUser(false)}
                        color={
                          !inviteUser ? Colors.snow : Colors.flumeDarkGreen
                        }
                        background={
                          !inviteUser ? Colors.flumeDarkGreen : Colors.aquaHaze
                        }
                      />
                    </ButtonGroup>
                  </ToggleButtonsContainer>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <StyledButton
                    type="submit"
                    disabled={loading}
                    label={!loading ? 'Add' : <Loader />}
                    color={Colors.flumeDarkGreen}
                    background={Colors.flumeGreen}
                  />
                </FormGroup>
              </Col>
            </Row>
            {error && <ErrorMessage errorMessage={error} />}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default AddUserForm;
