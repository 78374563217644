import * as React from 'react';
import styled from 'styled-components';
import { MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { UpdateRoleInput } from '../../API';

type State = {
  dropdownOpen: boolean;
};

type Props = {
  role: any;
  openDialogModal(role: UpdateRoleInput): void;
  openModal(role: UpdateRoleInput): void;
};

const StyledDropdownMenu = styled(DropdownMenu)`
  padding-top: 16px;
  padding-bottom: 16px;
`;

class RoleActions extends React.Component<Props, State> {
  state: State = {
    dropdownOpen: false
  };

  toggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  render() {
    const { dropdownOpen } = this.state;
    const { openModal, openDialogModal, role } = this.props;

    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={this.toggle}
        style={{ position: 'absolute' }}
      >
        <DropdownToggle
          tag="span"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
        </DropdownToggle>
        <StyledDropdownMenu>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openModal(role)}
          >
            Edit Role
          </div>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openDialogModal(role)}
          >
            Delete Role
          </div>
        </StyledDropdownMenu>
      </Dropdown>
    );
  }
}

export default RoleActions;
