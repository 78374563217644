import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Select from 'react-select';
import {
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  Input,
  Dropdown,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { SketchPicker } from 'react-color';

/** GraphQL */
import { updateSpace } from '../../graphql/mutations';
import { getCompany } from '../../graphql/queries';
import {
  UpdateSpaceInput,
  UpdateSpaceMutation,
  UpdateSpaceMutationVariables,
  SpaceStatus
} from '../../API';

/** Utils */
import { SPACE_STATUS_OPTIONS } from '../../Utils/Consts';
import { returnSpaceStatus } from '../../Utils/Helpers';

/** Custom Types */
import { Error } from '../../CustomTypes';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';
import { SelectedColor } from '../../Components/Styled/ListViewElements';

/** Themes */
import { Colors } from '../../Themes';

type SelectType = { value: SpaceStatus; label: string };

type Props = {
  companyId: string;
  space: UpdateSpaceInput;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  id: string;
  spaceName: string;
  spaceStatus: SelectType;
  colorCode: string;
  error: Error;
  dropdownOpen: boolean;
};

class EditSpaceModal extends React.Component<Props, State> {
  timeoutId: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.space.id || '',
      spaceName: props.space.name || '',
      spaceStatus: !props.space.status
        ? { value: SpaceStatus.INACTIVE, label: 'Inactive' }
        : {
            value: props.space.status,
            label: returnSpaceStatus(props.space.status)
          },
      colorCode: props.space.colorCode || Colors.flumeGreen,
      dropdownOpen: false,
      error: null
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Validation */
  validateForm = (): boolean => {
    // Check for undefined or empty input fields
    if (!this.state.spaceName) {
      this.setError('Please complete the form.');
      return false;
    }

    return true;
  };

  /** Select space status
   * @param spaceStatus - space status (active/inactive)
   */
  selectSpaceStatus = (spaceStatus: SelectType): void => {
    this.setState({ spaceStatus });
  };

  /** Select a space color */
  handleChangeComplete = (color: any) => {
    this.setState({ colorCode: color.hex });
  };

  /** Toggle color picker dropdown */
  toggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const {
      id,
      spaceName,
      spaceStatus,
      colorCode,
      error,
      dropdownOpen
    } = this.state;
    const { notification, closeModal, companyId } = this.props;
    const name = spaceName ? spaceName : '';

    return (
      <Mutation<UpdateSpaceMutation, UpdateSpaceMutationVariables>
        mutation={gql(updateSpace)}
      >
        {(updateSpaceMutation, { loading }) => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateForm()) {
                updateSpaceMutation({
                  variables: {
                    input: {
                      id,
                      name: spaceName,
                      colorCode,
                      status: spaceStatus.value
                    }
                  },
                  refetchQueries: [
                    {
                      query: gql(getCompany),
                      variables: {
                        id: companyId
                      }
                    }
                  ]
                })
                  .then(res => {
                    closeModal();
                    notification('Space updated successfully');
                  })
                  .catch(err => {
                    this.setError(err.message);
                  });
              }
            }}
          >
            <Row>
              <Col xs={12} md={8} lg={8}>
                <FormGroup>
                  <Label for="spaceName">Space Name</Label>
                  <Input
                    type="text"
                    name="spaceName"
                    value={name}
                    id="spaceName"
                    placeholder="Space name"
                    onChange={e => this.setState({ spaceName: e.target.value })}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <FormGroup>
                  <Label>Color</Label>

                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={this.toggle}
                    style={{ position: 'absolute' }}
                  >
                    <DropdownToggle
                      tag="span"
                      onClick={this.toggle}
                      data-toggle="dropdown"
                      aria-expanded={dropdownOpen}
                    >
                      <SelectedColor background={colorCode} />
                    </DropdownToggle>
                    <DropdownMenu>
                      <SketchPicker
                        color={colorCode}
                        onChangeComplete={this.handleChangeComplete}
                      />
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="spaceStatus">Status</Label>
                  <Select
                    // @ts-ignore
                    onChange={this.selectSpaceStatus}
                    options={SPACE_STATUS_OPTIONS}
                    value={spaceStatus}
                    isSearchable={true}
                    className="select-styling"
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <StyledButton
                    type="submit"
                    label={!loading ? 'Update' : <Loader />}
                    color={Colors.flumeDarkGreen}
                    background={Colors.flumeGreen}
                  />
                </FormGroup>
              </Col>
            </Row>
            {error && <ErrorMessage errorMessage={error} />}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default EditSpaceModal;
