import * as React from 'react';
import * as Loadable from 'react-loadable';

/** Cognito user groups */
import {
  OWNER_USER_GROUP,
  SUPER_USER_GROUP,
  SUBSCRIPTION_PACKAGES,
  USER_GROUP
} from '../Utils/Consts';

/** Parent nav items */
import * as ParentNavItems from './Consts';

/** Presentation/UI */
import PageLoader from '../Components/PageLoader';

/** Web App Containers */
import AccountsAndBilling from '../Containers/AccountsAndBilling';
import ArchivedProductItems from '../Containers/ArchivedProductItems';
import Brands from '../Containers/Brands';
import CompanySetup from '../Containers/CompanySetup';
import Dashboard from '../Containers/Dashboard';
import CompleteProductItems from '../Containers/CompleteProductItems';
import ItemTemplates from '../Containers/ItemTemplates';
import MainPlanner from '../Containers/MainPlanner';
import NewProductItems from '../Containers/NewProductItems';
import QualityControl from '../Containers/QualityControl';
import QualityControlCalendar from '../Containers/QualityControlCalendar';
import Reporting from '../Containers/Reporting';
import Roles from '../Containers/Roles';
import Services from '../Containers/Services';
import SignIn from '../Containers/Login';
import SignUp from '../Containers/SignUp';
import Spaces from '../Containers/Spaces';
import SubscriptionCancelled from '../Containers/SubscriptionCancelled';
import Support from '../Containers/Support';
import TaggedProductItems from '../Containers/TaggedProductItems';
import Team from '../Containers/Team';

/** Admin Portal Containers */
import AdminDashboard from '../Containers/AdminPortal/Dashboard';
import AdminInvoices from '../Containers/AdminPortal/AdminInvoices';
import AdminLogin from '../Containers/AdminPortal/Login';

const AsyncAdminSignIn = Loadable({
  loader: () => Promise.resolve(AdminLogin),
  loading: () => <PageLoader />
});

const AsyncAdminDashboard = Loadable({
  loader: () => Promise.resolve(AdminDashboard),
  loading: () => <PageLoader />
});

const AsyncAdminInvoices = Loadable({
  loader: () => Promise.resolve(AdminInvoices),
  loading: () => <PageLoader />
});

const AsyncArchivedProductItems = Loadable({
  loader: () => Promise.resolve(ArchivedProductItems),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncBrands = Loadable({
  loader: () => Promise.resolve(Brands),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncCompanySetup = Loadable({
  loader: () => Promise.resolve(CompanySetup),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncDashboard = Loadable({
  loader: () => Promise.resolve(Dashboard),
  loading: () => <PageLoader />
});

const AsyncCompleteProductItems = Loadable({
  loader: () => Promise.resolve(CompleteProductItems),
  loading: () => <PageLoader />
});

const AsyncItemTemplates = Loadable({
  loader: () => Promise.resolve(ItemTemplates),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncMainPlanner = Loadable({
  loader: () => Promise.resolve(MainPlanner),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncNewProductItems = Loadable({
  loader: () => Promise.resolve(NewProductItems),
  loading: () => <PageLoader />
});

const AsyncQualityControl = Loadable({
  loader: () => Promise.resolve(QualityControl),
  loading: () => <PageLoader />
});

const AsyncQualityControlCalendar = Loadable({
  loader: () => Promise.resolve(QualityControlCalendar),
  loading: () => <PageLoader />
});

const AsyncRoles = Loadable({
  loader: () => Promise.resolve(Roles),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncServices = Loadable({
  loader: () => Promise.resolve(Services),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncSignIn = Loadable({
  loader: () => Promise.resolve(SignIn),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncSignUp = Loadable({
  loader: () => Promise.resolve(SignUp),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncSpaces = Loadable({
  loader: () => Promise.resolve(Spaces),
  loading: () => <PageLoader />
});

const AsyncSubscriptionCancelled = Loadable({
  loader: () => Promise.resolve(SubscriptionCancelled),
  loading: () => <PageLoader />
});

const AsyncSupport = Loadable({
  loader: () => Promise.resolve(Support),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncTaggedProductItems = Loadable({
  loader: () => Promise.resolve(TaggedProductItems),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncTeam = Loadable({
  loader: () => Promise.resolve(Team),
  loading: () => <PageLoader />
});

// @ts-ignore
const AsyncAccountsAndBilling = Loadable({
  loader: () => Promise.resolve(AccountsAndBilling),
  loading: () => <PageLoader />
});

const AsyncReporting = Loadable({
  loader: () => Promise.resolve(Reporting),
  loading: () => <PageLoader />
});

const routes = [
  {
    title: 'Accounts & Billing',
    path: '/manage/accounts-and-billing',
    parent: ParentNavItems.MANAGE,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncAccountsAndBilling,
    exact: true,
    private: true
  },
  {
    title: 'Admin Dashboard',
    path: '/admin/dashboard',
    component: AsyncAdminDashboard,
    cognitoGroups: [OWNER_USER_GROUP],
    exact: true,
    private: true
  },
  {
    title: 'Admin Sign In',
    path: '/admin/sign-in',
    component: AsyncAdminSignIn,
    exact: true,
    private: false
  },
  {
    title: 'Archived Items',
    path: '/production/archived-product-items',
    parent: ParentNavItems.PRODUCTION,
    component: AsyncArchivedProductItems,
    cognitoGroups: [USER_GROUP, SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    exact: true,
    private: true
  },
  {
    title: 'Brands',
    path: '/manage/brands',
    parent: ParentNavItems.MANAGE,
    component: AsyncBrands,
    cognitoGroups: [USER_GROUP, SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    exact: true,
    private: true
  },
  {
    title: 'Company Setup',
    path: '/company-setup',
    component: AsyncCompanySetup,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    exact: true,
    private: false
  },
  {
    title: 'Dashboard',
    path: '/',
    parent: ParentNavItems.DASHBOARD,
    cognitoGroups: [USER_GROUP, SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncDashboard,
    exact: true,
    private: true
  },
  {
    title: 'Complete Items',
    path: '/production/complete-product-items',
    parent: ParentNavItems.PRODUCTION,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncCompleteProductItems,
    exact: true,
    private: true
  },
  {
    title: 'Item Templates',
    path: '/manage/item-templates',
    parent: ParentNavItems.MANAGE,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncItemTemplates,
    exact: true,
    private: true
  },
  {
    title: 'Invoices',
    path: '/admin/invoices',
    component: AsyncAdminInvoices,
    cognitoGroups: [OWNER_USER_GROUP],
    exact: true,
    private: true
  },
  {
    title: 'Planner',
    path: '/production/main-planner',
    parent: ParentNavItems.PRODUCTION,
    cognitoGroups: [USER_GROUP, SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncMainPlanner,
    exact: true,
    private: true
  },
  {
    title: 'New Items',
    path: '/production/sorting/new-items',
    parent: ParentNavItems.PRODUCTION,
    cognitoGroups: [USER_GROUP, SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncNewProductItems,
    exact: true,
    private: true
  },
  {
    title: 'Quality Control Items',
    path: '/production/quality-control/items',
    parent: ParentNavItems.PRODUCTION,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [SUBSCRIPTION_PACKAGES.premium],
    component: AsyncQualityControl,
    exact: true,
    private: true
  },
  {
    title: 'Quality Control Calendar',
    path: '/production/quality-control/calendar',
    parent: ParentNavItems.PRODUCTION,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [SUBSCRIPTION_PACKAGES.premium],
    component: AsyncQualityControlCalendar,
    exact: true,
    private: true
  },
  {
    title: 'Reporting',
    path: '/reporting/sorting',
    parent: ParentNavItems.REPORTING,
    cognitoGroups: [SUPER_USER_GROUP],
    component: AsyncReporting,
    exact: true,
    private: true
  },
  {
    title: 'Reporting',
    path: '/reporting/main-planner',
    parent: ParentNavItems.REPORTING,
    cognitoGroups: [SUPER_USER_GROUP],
    component: AsyncReporting,
    exact: true,
    private: true
  },
  {
    title: 'Reporting',
    path: '/reporting/quality-control',
    parent: ParentNavItems.REPORTING,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [SUBSCRIPTION_PACKAGES.premium],
    component: AsyncReporting,
    exact: true,
    private: true
  },
  {
    title: 'Reporting',
    path: '/reporting/complete',
    parent: ParentNavItems.REPORTING,
    cognitoGroups: [SUPER_USER_GROUP],
    component: AsyncReporting,
    exact: true,
    private: true
  },
  {
    title: 'Reporting',
    path: '/reporting/perfomance-spaces',
    parent: ParentNavItems.REPORTING,
    cognitoGroups: [SUPER_USER_GROUP],
    component: AsyncReporting,
    exact: true,
    private: true
  },
  {
    title: 'Reporting',
    path: '/reporting/perfomance-services',
    parent: ParentNavItems.REPORTING,
    cognitoGroups: [SUPER_USER_GROUP],
    component: AsyncReporting,
    exact: true,
    private: true
  },
  {
    title: 'Roles',
    path: '/manage/teams-and-roles/roles',
    parent: ParentNavItems.MANAGE,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncRoles,
    exact: true,
    private: true
  },
  {
    title: 'Services',
    path: '/manage/services',
    parent: ParentNavItems.MANAGE,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncServices,
    exact: true,
    private: true
  },
  {
    title: 'Sign In',
    path: '/sign-in',
    component: AsyncSignIn,
    exact: true,
    private: false
  },
  {
    title: 'Sign Up',
    path: '/sign-up',
    component: AsyncSignUp,
    exact: true,
    private: false
  },
  {
    title: 'Subscription Cancelled',
    path: '/subscription-cancelled',
    component: AsyncSubscriptionCancelled,
    exact: true,
    private: false
  },
  {
    title: 'Support',
    path: '/support',
    parent: ParentNavItems.MANAGE,
    cognitoGroups: [SUPER_USER_GROUP, USER_GROUP],
    component: AsyncSupport,
    exact: true,
    private: true
  },
  {
    title: 'Support',
    path: '/admin/support',
    parent: ParentNavItems.MANAGE,
    cognitoGroups: [OWNER_USER_GROUP],
    component: AsyncSupport,
    exact: true,
    private: true
  },
  {
    title: 'Spaces',
    path: '/manage/spaces',
    parent: ParentNavItems.MANAGE,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncSpaces,
    exact: true,
    private: true
  },
  {
    title: 'Tagged Items',
    path: '/production/sorting/tagged-items',
    parent: ParentNavItems.PRODUCTION,
    cognitoGroups: [USER_GROUP, SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncTaggedProductItems,
    exact: true,
    private: true
  },
  {
    title: 'Team',
    path: '/manage/teams-and-roles/team',
    parent: ParentNavItems.MANAGE,
    cognitoGroups: [SUPER_USER_GROUP],
    subscriptionPlans: [
      SUBSCRIPTION_PACKAGES.standard,
      SUBSCRIPTION_PACKAGES.premium
    ],
    component: AsyncTeam,
    exact: true,
    private: true
  }
];

export default routes;
