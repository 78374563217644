import styled from 'styled-components';

/* Themes */
import { Colors } from '../../../Themes';

/* Utils */
import { convertPixelsToRem } from '../../../Utils/Helpers';

type CardTextProps = {
  uppercase?: boolean;
  cursorPointer?: boolean;
  expired?: boolean;
};

type CardInfoProps = {
  bgColor?: string;
  marginBottom?: number;
  expired?: boolean;
};

export const CardInfoContainer = styled.div<CardInfoProps>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 95%;
  margin: auto 2.5%
    ${(props: CardInfoProps) =>
      props.marginBottom
        ? convertPixelsToRem(props.marginBottom)
        : convertPixelsToRem(20)}
    0;
  padding: ${convertPixelsToRem(16)};
  border-radius: ${convertPixelsToRem(5)};
  background-color: ${props => props.bgColor || Colors.lightBlueGrey};
  box-shadow: ${props =>
    props.expired &&
    `0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)`};
`;

export const CardFooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
  border-radius: ${convertPixelsToRem(5)};
`;

export const CardText = styled.span<CardTextProps>`
  color: ${(props: CardTextProps) =>
    props.expired ? Colors.error : Colors.grey};
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'capitalize')};
  cursor: ${props => (props.cursorPointer ? 'pointer' : 'default')};
`;

export const CardFooterButtons = styled.div`
  position: absolute;
  flex-direction: row;
  display: flex;
  align-self: center;
  right: ${convertPixelsToRem(50)};
`;
