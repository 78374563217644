import * as React from 'react';
import { Query } from 'react-apollo';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import { IoMdCalendar } from 'react-icons/io';
import styled from 'styled-components';
import Select from 'react-select';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import SVG from 'react-inlinesvg';

/** GraphQL */
import { getCompanySpacesByUser } from '../../graphql/custom-queries';
import { Permission, UpdateSpaceInput } from '../../API';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import GlobalModalContainer from '../../Components/Modal';
import { TableHeaderContainer } from '../../Components/Styled/ListViewElements';
import StyledButton from '../../Components/Styled/Button';
import {
  HeaderWrapper,
  ViewWrapper,
  ToggleWeekContainer,
  SelectContainer,
  ArrowContainer,
  DateContainer
} from '../../Components/Styled/MainPlanner';
import Loader from '../../Components/Loader';

/** Local components */
import BatchCardInProgress from './BatchCardInProgress';
import CalendarView from './CalendarView';
import SpaceView from './SpaceView';

/** Themes */
import { Colors } from '../../Themes';

/** Custom Types */
import {
  SelectOptionsType,
  SelectType,
  ValueType,
  BatchCardServices
} from '../../CustomTypes';

/** Utils */
import {
  MAIN_PLANNER_VIEWS,
  USER_ROLES,
  SUPER_USER_GROUP
} from '../../Utils/Consts';
import {
  returnDatePeriod,
  sortDropDownDataAlphabetically
} from '../../Utils/Helpers';

const Wrapper = styled.div`
  height: 1200px;
  width: 100%;
`;

const Container = styled.div`
  width: calc(100% - 30px);
  margin: 0 auto;
`;

type Props = {
  calFocused: boolean;
  currentWeek: Array<Date>;
  currentView: string;
  date: moment.Moment | null;
  spaceId: string;
  spaceIds: Array<string>;
  userPermission: Permission | string;
  nextWeek: () => void;
  prevWeek: () => void;
  updateCurrentView(currentView: string): void;
  updateCurrentWeekByDate(date: Date | null): void;
};

type State = {
  batchCardId: string;
  batchCardModal: boolean;
  spaceId: string;
  spaceIds: Array<string>;
  userPermission: Permission | string;
  batchCardServices: Array<BatchCardServices>;
};

export default class ContainerForViews extends React.Component<Props, State> {
  static async getDerivedStateFromProps(props: Props) {
    if (props && props.spaceIds) {
      const { spaceIds, userPermission } = props;
      return {
        spaceIds,
        userPermission
      };
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      batchCardId: '',
      batchCardModal: false,
      spaceId: props.spaceId,
      spaceIds: props.spaceIds,
      userPermission: props.userPermission,
      batchCardServices: []
    };
  }

  /** Open batch card modal
   * @param batchCardId - id of selected batch card
   */
  openBatchCard = (batchCardId: string, batchCardServices: any) => {
    this.setState({ batchCardId, batchCardServices, batchCardModal: true });
  };

  /** Close batch card modal */
  closeBatchCardModal = () => {
    this.setState({ batchCardModal: false, batchCardId: '' });
  };

  /** Render the view
   * @param userId - id of authenticated user
   */
  renderView = (userId: string): React.ReactNode => {
    const { currentView, currentWeek } = this.props;
    const { spaceId, spaceIds, userPermission } = this.state;
    if (currentView === MAIN_PLANNER_VIEWS.calendar) {
      return (
        <CalendarView
          currentWeek={currentWeek}
          spaceId={spaceId}
          spaceIds={spaceIds}
          userPermission={userPermission}
          openBatchCard={this.openBatchCard}
        />
      );
    }

    return (
      <SpaceView
        currentWeek={currentWeek}
        spaceId={spaceId}
        spaceIds={spaceIds}
        userPermission={userPermission}
        userId={userId}
        openBatchCard={this.openBatchCard}
      />
    );
  };

  /** Return company spaces as react select options
   * @param spaces - created company spaces
   */
  returnCompanySpaces = (
    spaces: Array<UpdateSpaceInput>
  ): SelectOptionsType => {
    if (spaces && spaces.length) {
      // Prepare to hold an array of spaces
      let selectOptions: any[] = [];
      // Filter only spaces that are active
      spaces.map((space: any) => {
        if (space.status === 'ACTIVE') {
          return selectOptions.push(space);
        }
        return {};
      });
      // return list of available spaces for select dropdown
      const spacesSelectOptions = selectOptions.map(
        (space: UpdateSpaceInput) => {
          return {
            label: space.name || space.id,
            value: space.id,
            object: space
          };
        }
      );
      // Sort spaces in alphabetical order
      return sortDropDownDataAlphabetically(spacesSelectOptions);
    }

    return [];
  };

  /** Select space to filter by
   * @param space - space to filter calendar view by
   */
  selectSpace = (space: ValueType<SelectType>) => {
    this.setState({ spaceId: space.value });
  };

  /** Return dropdown select with company spaces
   * @param userId - id of authenticated user
   */
  returnSpaceOptions = (userId: string): React.ReactNode => {
    return (
      <Query query={getCompanySpacesByUser} variables={{ id: userId }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <span>Loading spaces...</span>;
          }
          if (error || !data || !data.getUser) {
            return (
              <ErrorMessage errorMessage="There was a problem loading company spaces" />
            );
          }

          const company = data.getUser.company;

          if (
            company.spaces &&
            company.spaces.items &&
            company.spaces.items.length
          ) {
            const createdCompanySpaces = company.spaces.items;

            return (
              <Select
                // @ts-ignore
                onChange={this.selectSpace}
                options={[
                  { value: '', label: 'All' },
                  ...this.returnCompanySpaces(createdCompanySpaces)
                ]}
                isSearchable={true}
                placeholder="Filter Spaces"
                className="select-styling"
              />
            );
          } else {
            return (
              <Select
                // @ts-ignore
                onChange={this.selectSpace}
                options={[]}
                isSearchable={true}
                placeholder="Filter Spaces"
                className="select-styling"
              />
            );
          }
        }}
      </Query>
    );
  };

  render() {
    const { currentWeek, updateCurrentWeekByDate } = this.props;
    const {
      batchCardModal,
      batchCardId,
      userPermission,
      batchCardServices
    } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId, userRole }) => {
          return (
            <Wrapper>
              <GlobalModalContainer
                toggleModal={this.closeBatchCardModal}
                largeModal={true}
                title="Batch Card"
                modalDisplay={
                  <BatchCardInProgress
                    batchCardServices={batchCardServices}
                    currentWeek={currentWeek}
                    batchCardId={batchCardId}
                    userId={userId}
                    closeModal={this.closeBatchCardModal}
                  />
                }
                modal={batchCardModal}
              />
              <Container>
                <HeaderWrapper>
                  <TableHeaderContainer>
                    <StyledButton
                      type="button"
                      width="150px"
                      onClick={() =>
                        this.props.updateCurrentView(
                          MAIN_PLANNER_VIEWS.calendar
                        )
                      }
                      label={
                        <span>
                          <IoMdCalendar size="1.5em" viewBox="0 60 512 512" />{' '}
                          Calendar View
                        </span>
                      }
                      color={Colors.flumeDarkGreen}
                      background={Colors.flumeGreen}
                    />
                    <StyledButton
                      type="button"
                      width="150px"
                      onClick={() =>
                        this.props.updateCurrentView(MAIN_PLANNER_VIEWS.space)
                      }
                      label={
                        <span>
                          <SVG
                            preloader={<Loader />}
                            src="../../Assets/Images/ServiceIcons/photography.svg"
                          />
                          Space View
                        </span>
                      }
                      color={Colors.flumeGreen}
                      background={Colors.grey}
                    />
                    <ToggleWeekContainer>
                      <ArrowContainer onClick={this.props.prevWeek}>
                        <MdNavigateBefore size="1.5em" color={Colors.grey} />
                      </ArrowContainer>
                      <DatePicker
                        onChange={date => updateCurrentWeekByDate(date)}
                        customInput={
                          <DateContainer>
                            {returnDatePeriod(currentWeek)}
                          </DateContainer>
                        }
                        dateFormat="dd/MM/yyyy"
                      />
                      <ArrowContainer onClick={this.props.nextWeek}>
                        <MdNavigateNext size="1.5em" color={Colors.grey} />
                      </ArrowContainer>
                    </ToggleWeekContainer>
                    {(userPermission === USER_ROLES.superUser ||
                      userRole === SUPER_USER_GROUP) && (
                      <SelectContainer>
                        {this.returnSpaceOptions(userId)}
                      </SelectContainer>
                    )}
                  </TableHeaderContainer>
                </HeaderWrapper>
                <ViewWrapper>{this.renderView(userId)}</ViewWrapper>
              </Container>
            </Wrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}
