import * as React from 'react';
import { Query } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { MdSearch } from 'react-icons/md';

/** GraphQL */
import { getBatchCardServicesAndCompanySpaces } from '../../graphql/custom-queries';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import StyledButton from '../../Components/Styled/Button';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import { TableHeaderContainer } from '../../Components/Styled/ListViewElements';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Custom types */
import { ToastNotificationType } from '../../CustomTypes';

/** Local components */
import CreateSpaceCardAllocations from './CreateSpaceCardAllocations';

/** Themes */
import { Colors } from '../../Themes';

type Props = {
  batchCardId: string;
  defaultSpace: string;
  toastManager: ToastNotificationType;
  closeModal: () => void;
};

type State = {
  searchFilter: boolean;
};

const errorMessage: string = 'There was a problem loading company data';

class SpaceBatchCardAllocation extends React.Component<Props> {
  state: State = {
    searchFilter: false
  };

  /** Toggle search filter */
  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Success notification
   * @param message - message on notification
   * @param appearance - type of message (success/error)
   */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const { searchFilter } = this.state;
    const { batchCardId, closeModal, defaultSpace } = this.props;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <Query
              query={getBatchCardServicesAndCompanySpaces}
              variables={{ userId, batchCardId }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <Loader color={Colors.flumeGreen} />;
                }
                if (error) {
                  return <ErrorMessage errorMessage={errorMessage} />;
                }

                if (!data || !data.getUser) {
                  return (
                    <ErrorMessage errorMessage="There was a problem loading your company data" />
                  );
                }

                const company = data.getUser.company;

                if (
                  company.batchCards &&
                  company.batchCards.items.length &&
                  company.spaces.items &&
                  company.spaces.items.length
                ) {
                  const batchCardServices =
                    company.batchCards.items[0].services.items;

                  return (
                    <div>
                      <TableHeaderContainer>
                        <StyledButton
                          type="button"
                          label={
                            <MdSearch
                              size="1.3em"
                              color={Colors.flumeDarkGreen}
                            />
                          }
                          width="auto"
                          onClick={this.toggleSearchFilter}
                          color={Colors.flumeDarkGreen}
                          background={Colors.flumeGreen}
                        />
                      </TableHeaderContainer>
                      <CreateSpaceCardAllocations
                        batchCardId={batchCardId}
                        batchCardServices={batchCardServices}
                        companySpaces={company.spaces.items}
                        closeModal={closeModal}
                        defaultSpace={defaultSpace}
                        notification={this.toastNotification}
                        searchFilter={searchFilter}
                        userId={userId}
                      />
                    </div>
                  );
                }
                return <ErrorMessage errorMessage={errorMessage} />;
              }}
            </Query>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(SpaceBatchCardAllocation);
