import * as React from 'react';
import styled from 'styled-components';

/** Local components */
import ContactForm from './ContactForm';

/** Theme */
import { Colors } from '../../Themes';
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import SpanWrapper from '../../Components/Styled/Span';

/** Utils */
import { SUPPORT_CONTACT_EMAIL } from '../../Utils/Consts';

const SupportWrapper = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;
`;

const Container = styled.div`
  padding: 20px;
  background: ${Colors.snow};
`;

const Contact: React.FC<{}> = () => {
  return (
    <SupportWrapper>
      <Container>
        <h5>Contact Us</h5>
        <FullWidthContainer>
          <SpanWrapper
            text={SUPPORT_CONTACT_EMAIL}
            color={Colors.flumeDarkGreen}
          />
          <br />
          <br />
          <p>
            Please send us a message if you require help and we shall respond
            within 24 hours except weekends and public holidays.
          </p>
        </FullWidthContainer>
        <SpanWrapper text="Send A Message" />
        <br />
        <br />
        <ContactForm />
      </Container>
    </SupportWrapper>
  );
};

export default Contact;
