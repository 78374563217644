import * as React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { MdCameraAlt } from 'react-icons/md';
import * as uuid from 'uuid';
import { Storage } from 'aws-amplify';

/** GraphQL */
import { getUser } from '../../graphql/queries';

/** Presentation/UI */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import { Avatar } from './StyledElements';

/** Custom types */
import { Error } from '../../CustomTypes';

/**  Themes */
import { Colors, Images } from '../../Themes';

/** Utils */
import { returnImageFromStorage } from '../../Utils/Helpers';

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const Label = styled.label`
  padding: 8px;
  background: ${Colors.flumeGreen};
  display: table;
  margin: 0 auto;
  color: ${Colors.snow} !important;
  border-radius: 5px;
  width: 60px;
  text-align: center;
  cursor: pointer;
  & input[type='file'] {
    display: none;
  }
`;

type Props = {
  userId: string;
  avatarUrl?: string | null;
  avatarKey?: string | null;
  updateUserMutation: any;
};

type State = {
  error: Error;
  avatarUrl: object | string;
  loading: boolean;
};

class UploadAvatar extends React.Component<Props, State> {
  static async getDerivedStateFromProps(props: Props) {
    if (props && props.avatarKey) {
      const response = await returnImageFromStorage(props.avatarKey);
      const avatarUrl = response || '';
      return {
        avatarUrl
      };
    }
    return {
      avatarUrl: Images.defaultAvatar
    };
  }

  state = {
    error: null,
    avatarUrl: Images.defaultAvatar,
    loading: false
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  async componentDidMount() {
    const { avatarKey } = this.props;

    if (avatarKey) {
      const response = await returnImageFromStorage(avatarKey);
      const avatarUrl = response || '';
      this.setState({ avatarUrl });
    }
  }

  /** Upload image file to S3 bucket */
  uploadFile(selectorFiles: FileList | null) {
    if (selectorFiles) {
      const file = selectorFiles[0];

      if (!file || !file.name) {
        this.setError('Please upload a valid file');
      } else {
        this.setState({ loading: true });
        // @ts-ignore
        Storage.put(`${uuid()}${file.name}`, file, {
          contentType: 'image/png'
        })
          // @ts-ignore
          .then((result: { key: string }) => {
            this.getAvatarURL(result.key);
          })
          // @ts-ignore
          .catch(err => {
            this.setState({ loading: false });
            this.setError(err.message);
          });
      }
    }
  }

  /** Get avatar image using key */
  getAvatarURL = (key: string): void => {
    const { userId, updateUserMutation } = this.props;
    Storage.get(key)
      .then(result => {
        this.setState({ loading: true });
        updateUserMutation({
          variables: {
            input: {
              id: userId,
              avatarKey: key,
              avatarUrl: result
            }
          },
          refetchQueries: [
            {
              query: gql(getUser),
              variables: {
                id: userId
              }
            }
          ]
        }).then((response: any) => {
          this.setState({
            avatarUrl: response.data.updateUser.avatarUrl,
            loading: false
          });
        });
      })
      .catch(err => {
        this.setError(err.message);
        this.setState({ loading: false });
      });
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { error, avatarUrl, loading } = this.state;
    const avatar = avatarUrl || Images.defaultAvatar;

    return (
      <Wrapper>
        <FullWidthContainer>
          <Avatar
            style={{
              margin: '0 auto',
              display: 'block',
              width: '100px',
              height: '100px',
              backgroundSize: avatarUrl ? 'cover' : '50%',
              backgroundImage: loading ? 'none' : `url(${avatar})`,
              animation: loading
                ? 'animation-7qzzer 0.75s 0s infinite linear'
                : 'none'
            }}
          />
          <br />
          <br />
          <Label>
            {' '}
            <MdCameraAlt size="1.8em" color={Colors.snow} />
            <input
              type="file"
              accept="image/*"
              onChange={e => this.uploadFile(e.target.files)}
            />
          </Label>
        </FullWidthContainer>
        {error && <ErrorMessage errorMessage={error} />}
      </Wrapper>
    );
  }
}

export default UploadAvatar;
