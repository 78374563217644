import * as React from 'react';
import * as moment from 'moment';
import { Col } from 'reactstrap';

/** Custom types */
import { FilterRangeType } from '../../../CustomTypes';

/** Presentation/UI */
import {
  DashboardSummaryCardContainer,
  DashboardSummaryCardHeader,
  DashboardSummaryCardNumberContainer
} from '../../../Components/Styled/Dashboard';

/** Utils */
import { TIME_RANGE } from '../../../Utils/Consts';
import { convertPixelsToRem } from '../../../Utils/Helpers';

/** Local components */
import SpacesCreatedInSelectedWeek from './Spaces/SelectedWeek';
import SpacesCreatedInSelectedMonth from './Spaces/SelectedMonth';
import SpacesCreatedInSelectedYear from './Spaces/SelectedYear';

type Props = {
  currentMonth: string;
  currentYear: string;
  formattedDates: Array<string>;
  range: FilterRangeType;
};

const Spaces: React.FC<Props> = ({
  currentMonth,
  currentYear,
  range,
  formattedDates
}) => {
  /** Return company spaces summary data based on selected range
   * @param itemStatus
   * @param range
   */
  const spacesBasedOnSelectedRange = (
    filterRange: FilterRangeType
  ): React.ReactNode => {
    switch (filterRange) {
      case TIME_RANGE.week:
        // Week
        return <SpacesCreatedInSelectedWeek formattedDates={formattedDates} />;
      case TIME_RANGE.month:
        // Month
        return (
          <SpacesCreatedInSelectedMonth
            month={moment(currentMonth, 'MMM YYYY').format('YYYY-MM')}
          />
        );
      case TIME_RANGE.year:
        // Year
        return <SpacesCreatedInSelectedYear year={currentYear} />;
      default:
        // Week
        return <SpacesCreatedInSelectedWeek formattedDates={formattedDates} />;
    }
  };

  return (
    <Col xs={12} md={6} lg={6}>
      <DashboardSummaryCardContainer height={convertPixelsToRem(200)}>
        <DashboardSummaryCardHeader>Spaces</DashboardSummaryCardHeader>
        <DashboardSummaryCardNumberContainer height="auto">
          {spacesBasedOnSelectedRange(range)}
        </DashboardSummaryCardNumberContainer>
      </DashboardSummaryCardContainer>
    </Col>
  );
};

export default Spaces;
