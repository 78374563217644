import * as React from 'react';
import { Query } from 'react-apollo';

/** GraphQL */
import { getCompanyBatchCardsByUserMainPlanner } from '../../graphql/custom-queries';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import { Wrapper } from '../../Components/Styled/MainPlanner';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Local components */
import MainPlannerCalendarWeekView from './MainPlannerCalendarWeekView';

/** Utils */
import { returnPlaceholderWeek } from './QCBatchCardHelpers';
import {
  returnFormattedDates,
  checkForUndefinedObjectValues
} from '../../Utils/Helpers';
import { Permission } from '../../API';

/** Custom types */
import { BatchCardServices } from '../../CustomTypes';

type Props = {
  currentWeek: Array<Date>;
  spaceId: string;
  spaceIds: Array<string>;
  userPermission: Permission | string;
  openBatchCard(
    batchCardId: string,
    batchCardServices: Array<BatchCardServices>
  ): void;
};

const CalendarView: React.FC<Props> = ({
  currentWeek,
  openBatchCard,
  spaceId,
  spaceIds,
  userPermission
}) => {
  const formattedDates = returnFormattedDates(currentWeek, 'DD MMM YYYY');

  return (
    <UserSpaceContextConsumer>
      {({ userId }) => {
        const variables = {
          id: userId,
          dayOne: formattedDates[0],
          dayTwo: formattedDates[1],
          dayThree: formattedDates[2],
          dayFour: formattedDates[3],
          dayFive: formattedDates[4],
          daySix: formattedDates[5],
          daySeven: formattedDates[6]
        };

        return (
          <React.Fragment>
            {checkForUndefinedObjectValues(variables) && (
              <Query
                query={getCompanyBatchCardsByUserMainPlanner}
                variables={variables}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return <Wrapper>{returnPlaceholderWeek()}</Wrapper>;
                  }

                  if (error || !data) {
                    return (
                      <ErrorMessage errorMessage="There was a problem loading your company batch card allocations" />
                    );
                  }

                  return (
                    <Wrapper>
                      <MainPlannerCalendarWeekView
                        currentWeek={currentWeek}
                        data={data}
                        openBatchCard={openBatchCard}
                        spaceId={spaceId}
                        spaceIds={spaceIds}
                        userPermission={userPermission}
                        userId={userId}
                      />
                    </Wrapper>
                  );
                }}
              </Query>
            )}
          </React.Fragment>
        );
      }}
    </UserSpaceContextConsumer>
  );
};

export default CalendarView;
