import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Form } from 'reactstrap';
import styled from 'styled-components';
import { IoIosTrash } from 'react-icons/io';

/** GraphQL */
import { deleteProductItemNote } from '../../graphql/mutations';
import {
  getCompanyBatchCardByUser,
  getCompanyTaggedProductItemsByUser
} from '../../graphql/custom-queries';

/** Generated types */
import {
  DeleteProductItemNoteMutation,
  DeleteProductItemNoteMutationVariables
} from '../../API';

/** Presentation/UI */
import DialogModal from '../../Components/DialogModal';
import GlobalModalContainer from '../../Components/Modal';

/** Themes */
import { Colors } from '../../Themes';

/* helper methods */
import { convertPixelsToRem } from '../../Utils/Helpers';

type Props = {
  batchCardId?: string;
  id: string;
  userId: string;
  notification(message: string, appearance?: string): void;
};

type State = {
  dialogModal: boolean;
};

const DeleteIconContainer = styled.span`
  cursor: pointer;
`;

class DeleteProductItemNote extends React.Component<Props, State> {
  state = {
    dialogModal: false
  };

  /** Close dialog modal */
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false
    });
  };

  /** Open dialog modal */
  openDialogModal = (): void => {
    this.setState({
      dialogModal: true
    });
  };

  /** Return refetch queries
   * @param userId - id of logged in user
   * @param batchCardId - id of batch card that the product item may be related to
   */
  returnRefetchQueries = (userId: string, batchCardId?: string) => {
    return batchCardId && batchCardId !== ''
      ? [
          {
            query: getCompanyTaggedProductItemsByUser,
            variables: {
              id: userId
            }
          },
          {
            query: getCompanyBatchCardByUser,
            variables: {
              userId,
              batchCardId
            }
          }
        ]
      : [
          {
            query: getCompanyTaggedProductItemsByUser,
            variables: {
              id: userId
            }
          }
        ];
  };

  render() {
    const { batchCardId, id, userId, notification } = this.props;
    const { dialogModal } = this.state;
    return (
      <Mutation<
        DeleteProductItemNoteMutation,
        DeleteProductItemNoteMutationVariables
      >
        mutation={gql(deleteProductItemNote)}
      >
        {(deleteProductItemNoteMutation, deleteMutation) => (
          <div>
            <GlobalModalContainer
              toggleModal={this.closeDialogModal}
              title="Add tag to a product item"
              modalDisplay={
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    deleteProductItemNoteMutation({
                      variables: {
                        input: {
                          id
                        }
                      },
                      refetchQueries: this.returnRefetchQueries(
                        userId,
                        batchCardId
                      )
                    })
                      .then(() => {
                        this.closeDialogModal();
                        notification(
                          'Product item note deleted successfully',
                          'success'
                        );
                      })
                      .catch(() => {
                        this.closeDialogModal();
                        notification(
                          'Sorry, there was a problem deleting the product item note',
                          'error'
                        );
                      });
                  }}
                >
                  <DialogModal
                    loading={deleteMutation.loading}
                    title="Are you sure you want to delete this product item note?"
                    toggleModal={this.closeDialogModal}
                  />
                </Form>
              }
              modal={dialogModal}
            />
            <DeleteIconContainer onClick={this.openDialogModal}>
              <IoIosTrash size={convertPixelsToRem(20)} color={Colors.pink} />
            </DeleteIconContainer>
          </div>
        )}
      </Mutation>
    );
  }
}

export default DeleteProductItemNote;
