import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';

/** GraphQL */
import { createService } from '../../graphql/mutations';
import { getCompanyServicesByUser } from '../../graphql/custom-queries';

import {
  CreateServiceMutation,
  CreateServiceMutationVariables
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';
import Span from '../../Components/Styled/Span';

/** Themes */
import { Colors, ServiceIcons } from '../../Themes';

/** Custom Types */
import { Error, ServiceType } from '../../CustomTypes';

/** Utils */
import { defaultService } from '../../Utils/Consts';

/** Local components */
import ServiceRow from './ServiceRow';

type Props = {
  companyId: string;
  userId: string | null;
  closeModal(): void;
  notification(message: string, appearance?: string): void;
};

type State = {
  name: string;
  services: Array<ServiceType>;
  error: Error;
};

class AddServiceModal extends React.Component<Props, State> {
  state: State = {
    name: '',
    services: [defaultService],
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Validate services - Each service added to local state should hhave a service name */
  validateServices = (): boolean => {
    const { services } = this.state;

    const noNamedServices = services.filter(
      (service: ServiceType) => service.name === ''
    );

    if (noNamedServices && noNamedServices.length) {
      this.setError('Please name every service.');
      return false;
    }

    return true;
  };

  /** Add a service */
  addService = (): void => {
    this.setState({
      services: [
        ...this.state.services,
        { id: '', name: '', icon: ServiceIcons.Editing }
      ]
    });
  };

  /** Update service
   * @param id - unique identifier (based on index) for service
   * @param name - text for service name
   * @param icon - icon for service
   */
  updateService = (id: number, name?: string, icon?: string): void => {
    const { services } = this.state;
    const updatedServices = services.map((service: ServiceType, i) => {
      if (id === i) {
        service.name = name || service.name;
        service.icon = icon || service.icon;
      }
      return service;
    });
    this.setState({ services: updatedServices });
  };

  /**
   * Delete service
   * @param id - unique id for specific service row to be deleted
   */
  deleteServiceRow = (id: number): void => {
    const services = this.state.services.filter(
      (service: ServiceType, i) => i !== id
    );
    this.setState({ services });
  };

  /** Return services in local state */
  returnServices = (): React.ReactNode | null => {
    const { services } = this.state;

    if (services.length) {
      return services.map((service: ServiceType, i: number) => {
        return i === 0 ? (
          <ServiceRow
            key={i}
            id={i}
            service={service}
            updateService={this.updateService}
            deleteServiceRow={this.deleteServiceRow}
            showToolTip={true}
          />
        ) : (
          <ServiceRow
            key={i}
            id={i}
            service={service}
            updateService={this.updateService}
            deleteServiceRow={this.deleteServiceRow}
          />
        );
      });
    }

    return null;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { services, error } = this.state;
    const { companyId, userId, notification, closeModal } = this.props;
    return (
      <Mutation<CreateServiceMutation, CreateServiceMutationVariables>
        mutation={gql(createService)}
      >
        {(createServiceMutation, { loading }) => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (services.length && this.validateServices()) {
                services.forEach(service => {
                  const { name, icon } = service;

                  createServiceMutation({
                    variables: {
                      input: {
                        name,
                        icon,
                        serviceCompanyId: companyId
                      }
                    },
                    refetchQueries: [
                      {
                        query: getCompanyServicesByUser,
                        variables: {
                          id: userId
                        }
                      }
                    ]
                  })
                    .then(() => {
                      notification('Service created successfully');
                    })
                    .catch(() => {
                      notification(
                        'There was a problem creating a service',
                        'error'
                      );
                    });
                });
                closeModal();
              }
            }}
          >
            <React.Fragment>{this.returnServices()}</React.Fragment>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <FaPlusCircle size="1.15em" />
                  &nbsp;&nbsp;
                  <Span
                    onClick={this.addService}
                    pointer={true}
                    text="Add Service"
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <StyledButton
                    type="submit"
                    label={loading ? <Loader /> : 'Create'}
                    color={Colors.flumeDarkGreen}
                    background={Colors.flumeGreen}
                  />
                </FormGroup>
              </Col>
            </Row>
            {error && <ErrorMessage errorMessage={error} />}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default AddServiceModal;
