import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../Themes';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background: ${Colors.backendBackground};
  min-height: 100vh;
  padding: 20px;
  flex-direction: column;
`;

export default class BackendWrapper extends React.Component<{
  children: React.ReactNode;
}> {
  render() {
    const { children } = this.props;
    return <Wrapper>{children}</Wrapper>;
  }
}
