import * as React from 'react';
import { Query } from 'react-apollo';
import * as moment from 'moment';

/** Custom queries */
import { getUsersInSpacesQuery } from './Utils/custom-queries';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Generated types */
import { UpdateUserInput } from '../../API';

/** Presentation/UI */
import {
  UserInitials,
  UserInitialsWrapper
} from '../../Components/Styled/MainPlanner';
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader/';

/** Themes */
import { Colors } from '../../Themes';

const ViewUsersInSpace: React.FC<{
  spaceIds: Array<string>;
  date?: string;
}> = ({ spaceIds, date }) => {
  /** Return space members initials
   * @param users - users in this space
   */
  const returnSpaceMembers = (spaces): React.ReactNode => {
    const spaceUsers: Array<UpdateUserInput> = [];
    spaces.map(space => {
      if (space.spaceAllocation && space.spaceAllocation.items.length) {
        /* eslint-disable */
        return space.spaceAllocation.items.map((allocation, i) => {
          if (date && moment(allocation.date).format('DD MMM YYYY') !== date) {
            return;
          }
          const { user } = allocation;
          spaceUsers.push(user);
        });
      } else {
        return <div />;
      }
    });

    const uniqueUsersToDisplay = [...new Set(spaceUsers)];
    const usersToDisplay = uniqueUsersToDisplay.map((user, i) => {
      if (!user.firstName && !user.lastName) {
        return (
          <UserInitials key={i}>
            {user.email ? user.email.charAt(0) : ''}
          </UserInitials>
        );
      } else {
        return (
          <UserInitials key={i}>{`${
            user.firstName ? user.firstName.charAt(0).toLocaleUpperCase() : ''
          }${
            user.lastName ? user.lastName.charAt(0).toLocaleUpperCase() : ''
          }`}</UserInitials>
        );
      }
    });

    return <div>{usersToDisplay}</div>;
  };

  return (
    <UserSpaceContextConsumer>
      {({ userId }) => {
        if (spaceIds && spaceIds.length) {
          return (
            <Query query={getUsersInSpacesQuery(userId, spaceIds)}>
              {({ loading, error, data }) => {
                if (loading) {
                  return (
                    <FullWidthContainer align="center">
                      <Loader color={Colors.flumeGreen} />
                    </FullWidthContainer>
                  );
                }
                if (error || !data || !data.getUser) {
                  return (
                    <ErrorMessage errorMessage="There was a problem loading users" />
                  );
                }

                const { spaces } = data.getUser.company;

                if (spaces && spaces.items.length) {
                  return (
                    <UserInitialsWrapper>
                      {returnSpaceMembers(spaces.items)}
                    </UserInitialsWrapper>
                  );
                } else {
                  return <div />;
                }
              }}
            </Query>
          );
        }

        return <div />;
      }}
    </UserSpaceContextConsumer>
  );
};

export default ViewUsersInSpace;
