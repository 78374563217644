import * as React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

/** Custom Types */
import { UpdateUserInput, Permission } from '../../API';

type UserType = UpdateUserInput & {
  company?: {
    id?: string;
  };
  userRole?: {
    id: string;
    name: string;
    permission: Permission;
  };
};

type State = {
  dropdownOpen: boolean;
};

type Props = {
  user: UserType;
  openModal(user: UserType): void;
};

class RoleActions extends React.Component<Props, State> {
  state: State = {
    dropdownOpen: false
  };

  toggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  render() {
    const { dropdownOpen } = this.state;
    const { openModal, user } = this.props;
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={this.toggle}
        style={{ position: 'absolute' }}
      >
        <DropdownToggle
          tag="span"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
        </DropdownToggle>
        <DropdownMenu>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openModal(user)}
          >
            Edit User
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default RoleActions;
