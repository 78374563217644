import * as React from 'react';
import * as moment from 'moment';
import { Col } from 'reactstrap';

/** Custom types */
import { FilterRangeType } from '../../../CustomTypes';

/** Presentation/UI */
import {
  DashboardSummaryCardContainer,
  DashboardSummaryCardHeader,
  DashboardSummaryCardNumberContainer
} from '../../../Components/Styled/Dashboard';

/** Utils */
import { TIME_RANGE } from '../../../Utils/Consts';
import { convertPixelsToRem } from '../../../Utils/Helpers';

/** Local components */
import ProductItemsCreatedInSelectedWeek from './ProductItems/SelectedWeek';
import ProductItemsCreatedInSelectedMonth from './ProductItems/SelectedMonth';
import ProductItemsCreatedInSelectedYear from './ProductItems/SelectedYear';

type Props = {
  currentMonth: string;
  currentYear: string;
  formattedDates: Array<string>;
  range: FilterRangeType;
};

const ProductItems: React.FC<Props> = ({
  currentMonth,
  currentYear,
  range,
  formattedDates
}) => {
  /** Return items card summary data based on selected range
   * @param itemStatus
   * @param range
   */
  const productItemsBasedOnSelectedRange = (
    filterRange: FilterRangeType
  ): React.ReactNode => {
    switch (filterRange) {
      case TIME_RANGE.week:
        // Week
        return (
          <ProductItemsCreatedInSelectedWeek formattedDates={formattedDates} />
        );
      case TIME_RANGE.month:
        // Month
        return (
          <ProductItemsCreatedInSelectedMonth
            month={moment(currentMonth, 'MMM YYYY').format('YYYY-MM')}
          />
        );
      case TIME_RANGE.year:
        // Year
        return <ProductItemsCreatedInSelectedYear year={currentYear} />;
      default:
        // Week
        return (
          <ProductItemsCreatedInSelectedWeek formattedDates={formattedDates} />
        );
    }
  };

  return (
    <Col xs={12} md={6} lg={6}>
      <DashboardSummaryCardContainer height={convertPixelsToRem(200)}>
        <DashboardSummaryCardHeader>Items</DashboardSummaryCardHeader>
        <DashboardSummaryCardNumberContainer height="auto">
          {productItemsBasedOnSelectedRange(range)}
        </DashboardSummaryCardNumberContainer>
      </DashboardSummaryCardContainer>
    </Col>
  );
};

export default ProductItems;
