import * as React from 'react';
import styled from 'styled-components';

/** Presentational components */
import StyledDivider from './Divider';

/** App theme */
import { Colors } from '../../Themes';

/** Utils */
import { convertPixelsToRem } from '../../Utils/Helpers';

const StyledCardContainer = styled.div`
  position: relative;
  max-width: 100%;
  min-height: ${convertPixelsToRem(200)};
  height: ${(props: { height?: string }) =>
    props.height ? props.height : 'auto'};
  background-color: ${Colors.snow};
  padding: 0;
  margin: ${convertPixelsToRem(20)};
  border-radius: ${convertPixelsToRem(5)} ${convertPixelsToRem(5)} 0px 0px;
`;

const StyledCardHeader = styled.div`
  height: ${convertPixelsToRem(60)}
  width: 100%;
  border-radius: 0 0 ${convertPixelsToRem(5)} ${convertPixelsToRem(5)};
  background-color: ${Colors.snow};
  box-shadow: 0 -2px 0 0 ${Colors.backendBackground};
  text-transform: uppercase;
`;

const StyledCardHeaderTitle = styled.span`
  width: 50%;
  position: relative;
  top: ${convertPixelsToRem(8)};
  left: ${convertPixelsToRem(16)};
  font-size: ${convertPixelsToRem(12)};
`;

const StyledCardContent = styled.div`
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  max-width: 100%;
`;

type Props = {
  title: string;
  children: React.ReactNode;
  ref?: any;
  height?: string;
  id?: string;
};

const CardWithTitleAndContent = (props: Props) => {
  const { height, title, children, id } = props;

  return (
    <StyledCardContainer height={height} id={id}>
      <StyledCardHeader>
        <StyledCardHeaderTitle>{title}</StyledCardHeaderTitle>
        <StyledDivider borderSize={1} borderColor={Colors.divider} />
      </StyledCardHeader>
      <StyledCardContent>{children}</StyledCardContent>
    </StyledCardContainer>
  );
};

export default CardWithTitleAndContent;
