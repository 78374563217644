import * as React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Button } from 'reactstrap';

/** GraphQL */
import { updateCompany } from '../../graphql/mutations';
import { UpdateCompanyMutationVariables } from '../../API';

/** Presentation/UI */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import GlobalModalContainer from '../../Components/Modal';
import DialogModal from '../../Components/DialogModal';

/** Custom types */
import { Error, ToastNotificationType } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

/** Stripe API Utils */
import { CUSTOMER_SUBSCRIPTIONS } from '../../Utils/LambdaEndpoints';

/** Stripe Products */
import {
  STANDARD_PRODUCT_ID,
  PREMIUM_PRODUCT_ID
} from '../../Utils/StripeProducts';

/** Utils */
import {
  HTTP_METHODS,
  SUBSCRIPTION_PACKAGES,
  FREE_TRIAL,
  STANDARD_PACKAGE,
  PREMIUM_PACKAGE
} from '../../Utils/Consts';
import { convertPixelsToRem } from '../../Utils/Helpers';

/** API */
import { apiRequest } from '../../Utils/API';
import { getUserDetails } from '../../graphql/custom-queries';
import CancelSubscription from './CancelSubscription';

import {
  SubscriptionPlansWrapper,
  SubscriptionPlanCardSubHeading,
  SubscriptionPlanCardPrice,
  SubscriptionPlanCard,
  SubscriptionPlanCardHeading,
  CurrencySymbol
} from '../../Components/Styled/ProductSubscription';

type Props = {
  companyId: string;
  stripeSubscriptionPlanId: string | null;
  toastManager: ToastNotificationType;
  userId: string;
};

type SubscribeToProductProps = Props & {
  mutate: any;
};

type State = {
  loading: boolean; // request to fetch data
  loadingRequest: boolean; // request to change subscription
  dialogModal: boolean;
  error: Error;
  subscriptionId: string;
  subscriptionPlanId: string;
  subscriptionType: SUBSCRIPTION_PACKAGES;
  productId: string;
};

class SubscribeToProduct extends React.Component<
  SubscribeToProductProps,
  State
> {
  state: State = {
    loading: false,
    loadingRequest: false,
    dialogModal: false,
    error: null,
    subscriptionId: '',
    subscriptionPlanId: '',
    subscriptionType: SUBSCRIPTION_PACKAGES.standard,
    productId: ''
  };

  timeoutId: number = 0;

  componentDidMount() {
    const { stripeSubscriptionPlanId } = this.props;
    this.getCustomerSubscription(stripeSubscriptionPlanId);
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /**
   * Get a customers' current subscription
   *
   * @param {string | null} subscriptionId - ID of the users' current plan
   *
   * @returns {void}
   */
  getCustomerSubscription = async (subscriptionId: string | null) => {
    this.setState({ loading: true });

    const response = await apiRequest(
      `${CUSTOMER_SUBSCRIPTIONS}/${subscriptionId}`,
      HTTP_METHODS.GET
    ).catch(e => {
      this.setState({ loading: false });
      this.setError(e.message);
    });

    if (response) {
      this.setState({
        loading: false,
        subscriptionId: response.id,
        subscriptionPlanId: response.plan ? response.plan.id : null
      });
    }
  };

  /** Close dialog modal for brand */
  closeDialogModal = (): void => {
    this.setState({ dialogModal: false });
  };

  /** Open dialog modal for brand
   * @param {React.FormEvent} event - browser event
   * @param {string} productId - stripe product id
   *
   * @returns {void}
   */
  openDialogModal = (
    event: React.FormEvent,
    productId: string,
    subscriptionType: SUBSCRIPTION_PACKAGES
  ): void => {
    this.setState({ dialogModal: true, productId, subscriptionType });
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  /**
   * Change a customers' subscription
   *
   * @param {string} currentPlanId - ID of the users' current plan
   * @param {string} newPlanId - ID of the new plan user is changing to
   *
   * @returns {void}
   */
  changeCustomerSubscription = async (event: React.SyntheticEvent) => {
    const { companyId, mutate, userId } = this.props;
    const { subscriptionId, subscriptionType, productId } = this.state;

    this.setState({ loadingRequest: true });

    const response = await apiRequest(
      `${CUSTOMER_SUBSCRIPTIONS}/upgrade/${subscriptionId}`,
      HTTP_METHODS.POST,
      {
        newPlanId: productId
      }
    ).catch(e => {
      this.setState({ loadingRequest: false });
      this.setError(e.message);
    });

    if (response) {
      if (response && response.id && response.customer) {
        const stripeSubscriptionPlanId = response.id;
        const stripeCustomerId = response.customer;

        mutate({
          variables: {
            input: {
              id: companyId,
              stripeSubscriptionPlanId,
              stripeCustomerId,
              subscriptionType
            }
          },
          refetchQueries: [
            {
              query: getUserDetails,
              variables: {
                id: userId
              }
            }
          ]
        })
          .then(() => {
            this.setState({
              loadingRequest: false,
              dialogModal: false,
              subscriptionId: response.id,
              subscriptionPlanId: response.plan.id
            });
            this.toastNotification(
              'subscription changed successfully',
              'success'
            );
          })
          .catch((err: any) => {
            this.setError(err.message);
          });
      }
    }
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const {
      loading,
      loadingRequest,
      dialogModal,
      error,
      subscriptionId,
      subscriptionPlanId
    } = this.state;
    const { companyId } = this.props;

    if (loading) {
      return (
        <FullWidthContainer align="center">
          <Loader size={150} color={Colors.flumeGreen} />
        </FullWidthContainer>
      );
    }

    return (
      <SubscriptionPlansWrapper>
        <GlobalModalContainer
          toggleModal={this.closeDialogModal}
          title=""
          modalDisplay={
            <DialogModal
              loading={loadingRequest}
              title="Are you sure you want to change your subscription?"
              toggleModal={this.closeDialogModal}
              handleAccept={this.changeCustomerSubscription}
            />
          }
          modal={dialogModal}
        />
        {error && <ErrorMessage errorMessage={error} />}
        <Row>
          <Col xs={12} md={4} lg={4}>
            <SubscriptionPlanCard style={{ height: 'relative' }}>
              <SubscriptionPlanCardHeading>
                {FREE_TRIAL.name}
              </SubscriptionPlanCardHeading>
              <SubscriptionPlanCardPrice>
                <CurrencySymbol>
                  <sup>$</sup>
                </CurrencySymbol>
                {FREE_TRIAL.price}
              </SubscriptionPlanCardPrice>
              <SubscriptionPlanCardSubHeading>
                {FREE_TRIAL.period}
              </SubscriptionPlanCardSubHeading>
              <SubscriptionPlanCardSubHeading
                style={{ height: convertPixelsToRem(230) }}
              >
                {FREE_TRIAL.description}
              </SubscriptionPlanCardSubHeading>
              <SubscriptionPlanCardSubHeading style={{ borderBottom: 'none' }}>
                4 users
                <br />
                <br />
                10 batch cards
              </SubscriptionPlanCardSubHeading>
              <br />
              <br />
              <Button>Select This Plan</Button>
            </SubscriptionPlanCard>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <SubscriptionPlanCard style={{ height: 'relative' }}>
              <SubscriptionPlanCardHeading>
                {STANDARD_PACKAGE.name}
              </SubscriptionPlanCardHeading>
              <SubscriptionPlanCardPrice>
                <CurrencySymbol>
                  <sup>$</sup>
                </CurrencySymbol>
                {STANDARD_PACKAGE.price}
              </SubscriptionPlanCardPrice>
              <SubscriptionPlanCardSubHeading>
                {STANDARD_PACKAGE.period}
              </SubscriptionPlanCardSubHeading>
              <SubscriptionPlanCardSubHeading
                style={{ height: convertPixelsToRem(230) }}
              >
                {STANDARD_PACKAGE.description}
              </SubscriptionPlanCardSubHeading>
              <SubscriptionPlanCardSubHeading style={{ borderBottom: 'none' }}>
                Per user
                <br />
                <br />
                Unlimited batch cards
              </SubscriptionPlanCardSubHeading>
              <br />
              <br />
              {subscriptionPlanId === STANDARD_PRODUCT_ID ? (
                <Button
                  type="button"
                  disabled={true}
                  style={{
                    background: Colors.osloGray,
                    cursor: 'not-allowed'
                  }}
                >
                  Current Plan
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={(event: React.SyntheticEvent) =>
                    this.openDialogModal(
                      event,
                      STANDARD_PRODUCT_ID,
                      SUBSCRIPTION_PACKAGES.standard
                    )
                  }
                >
                  Select This Plan
                </Button>
              )}
            </SubscriptionPlanCard>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <SubscriptionPlanCard style={{ height: 'relative' }}>
              <SubscriptionPlanCardHeading>
                {PREMIUM_PACKAGE.name}
              </SubscriptionPlanCardHeading>
              <SubscriptionPlanCardPrice>
                <CurrencySymbol>
                  <sup>$</sup>
                </CurrencySymbol>
                {PREMIUM_PACKAGE.price}
              </SubscriptionPlanCardPrice>
              <SubscriptionPlanCardSubHeading>
                {PREMIUM_PACKAGE.period}
              </SubscriptionPlanCardSubHeading>
              <SubscriptionPlanCardSubHeading
              // style={{ height: convertPixelsToRem(120) }}
              >
                {PREMIUM_PACKAGE.description}
              </SubscriptionPlanCardSubHeading>
              <SubscriptionPlanCardSubHeading style={{ borderBottom: 'none' }}>
                Per user
                <br />
                <br />
                Quality control
              </SubscriptionPlanCardSubHeading>
              <br />
              <br />
              {subscriptionPlanId === PREMIUM_PRODUCT_ID ? (
                <Button
                  type="button"
                  disabled={true}
                  style={{
                    background: Colors.osloGray,
                    cursor: 'not-allowed'
                  }}
                >
                  Current Plan
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={(event: React.SyntheticEvent) =>
                    this.openDialogModal(
                      event,
                      PREMIUM_PRODUCT_ID,
                      SUBSCRIPTION_PACKAGES.premium
                    )
                  }
                >
                  Upgrade to pro
                </Button>
              )}
            </SubscriptionPlanCard>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} md={12} lg={12}>
            <CancelSubscription
              companyId={companyId}
              subscriptionId={subscriptionId}
            />
          </Col>
        </Row>
      </SubscriptionPlansWrapper>
    );
  }
}

export default graphql<Props, Response, UpdateCompanyMutationVariables>(
  gql(updateCompany)
)(SubscribeToProduct as any);
