import styled from 'styled-components';

interface IContainerProps {
  textAlign?: 'right' | 'center' | 'left';
  width?: string;
  height?: string;
  maxHeight?: string;
  margin?: string;
  left?: string;
}

const CustomWidthContainer = styled.div<IContainerProps>`
  position: relative;
  width: ${props => (props.width ? `${props.width}` : '100%')};
  height: ${props => (props.height ? `${props.height}` : '100%')};
  left: ${props => (props.left ? `${props.left}` : '0')};
  max-height: ${props => (props.maxHeight ? `${props.maxHeight}` : '100%')};
  text-align: ${props => (props.textAlign ? `${props.textAlign}` : 'center')};
  margin: ${props => props.margin || 'auto'};
`;

export default CustomWidthContainer;
