/* eslint array-callback-return: 0 */
import * as jwtDecode from 'jwt-decode';
import * as moment from 'moment';

import { AUTH_USER_TOKEN_KEY } from './LocalStorage';

import { Storage } from 'aws-amplify';

/** API */
import {
  SpaceStatus,
  CreateItemTemplateInput,
  UpdateUserInput,
  CreateProductItemInput,
  Permission
} from '../API';

/** Custom Types */
import {
  UserRoleType,
  SpaceStatusType,
  SelectType,
  TableColumnType,
  ChartData,
  CurrencyType,
  ServiceType,
  SelectOptionsType
} from '../CustomTypes';

/** Consts */
import {
  SPACE_STATUS,
  USER_ROLES,
  CURRENCY_SYMBOL,
  USER_GROUP,
  SUPER_USER_GROUP,
  NON_USER_GROUP
} from './Consts';

export const validateToken = (token: any): boolean => {
  if (!token) {
    return false;
  }
  try {
    // @ts-ignore
    const decodedJwt: any = jwtDecode(token);
    return decodedJwt.exp >= Date.now() / 1000;
  } catch (e) {
    return false;
  }
};

/** Return user id from JWT */
export const getUserId = (): string => {
  const token = window.localStorage.getItem(AUTH_USER_TOKEN_KEY);
  if (token) {
    // @ts-ignore
    const decodedJwt: any = jwtDecode(token);
    return decodedJwt.sub;
  }
  return '';
};

/** Convert text to a selector */
export const convertToSelector = (text: string): string => {
  return text.toLowerCase().replace(' ', '-');
};

/** Get array from object */
export const getArrayFromObject = (objectToConvert: any) => {
  const arrayFromObject: any = [];

  for (const item in objectToConvert) {
    if (Object.keys(objectToConvert[item]).length > 0) {
      arrayFromObject.push({
        ...objectToConvert[item]
      } as any);
    }
  }

  return arrayFromObject;
};

/**
 * Filter regardless of letter casing. Used by react table filter
 */
export const filterCaseInsensitive = (filter: any, row: any) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined && row[id] !== null
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true;
};

/** Return image from storage */
export const returnImageFromStorage = async (
  avatarKey: string
): Promise<object | string> => {
  const result = await Storage.get(avatarKey).catch(err => {
    return '';
  });
  return result;
};

/** Return space status */
export const returnSpaceStatus = (status: SpaceStatus): SpaceStatusType => {
  switch (status) {
    case SPACE_STATUS.active:
      return 'Active';
    case SPACE_STATUS.inactive:
      return 'Inactive';
    default:
      return 'Inactive';
  }
};

/** Return user roles
 * @param role - user role
 */
export const returnUserRole = (role: UserRoleType): string => {
  switch (role) {
    case USER_ROLES.nonUser:
      return 'Non User';
    case USER_ROLES.user:
      return 'User';
    case USER_ROLES.superUser:
      return 'Super User';
    default:
      return 'Non-User';
  }
};

/** Check if user is part of the group authorized to access this route
 * @param token - auth token for logged in user
 * @param cognitoUserGroups - user groups from cognito pool
 */
export const checkUserGroup = (
  token: string | null,
  cognitoUserGroups: Array<string>
) => {
  if (token) {
    const userCognitoGroup: string | null = getGroupFromToken(token);

    return (
      userCognitoGroup && cognitoUserGroups.indexOf(userCognitoGroup) !== -1
    );
  }
  return false;
};

/** Get the signed in user's email on auth token */
export const getUserEmailFromToken = (): string => {
  const token = window.localStorage.getItem(AUTH_USER_TOKEN_KEY);
  if (token) {
    const decodedJwt: any = jwtDecode(token);
    return decodedJwt && decodedJwt.username ? decodedJwt.username : '';
  }
  return '';
};

/** Get the signed in user's cognito group based on auth token */
export const getUserGroup = (token?: string | null): string | null => {
  token = token || window.localStorage.getItem(AUTH_USER_TOKEN_KEY);

  if (typeof token === 'string') {
    return getGroupFromToken(token);
  }
  return null;
};

/** Decode the auth token and retrieve the user group
 * @param token - JWT auth token of signed in user
 */
export const getGroupFromToken = (token: string): string | null => {
  const decodedJwt: any = jwtDecode(token);
  const userCognitoGroup: string | null =
    decodedJwt['cognito:groups'] && decodedJwt['cognito:groups'].length
      ? decodedJwt['cognito:groups'][0]
      : null;
  return userCognitoGroup;
};

/**
 * Return data columns for downloadable csv template
 *
 * @param {CreateItemTemplateInput} itemTemplate
 * @returns {Array<Array<string>>}
 *
 */
export const returnNewProductItemCSVData = (
  itemTemplate: CreateItemTemplateInput
): Array<Array<string>> => {
  const CSV_COLUMNS: Array<string> = [];
  const validItemTemplate = returnValidProductItem(itemTemplate);

  for (let key in validItemTemplate) {
    CSV_COLUMNS.push(key);
  }

  return [
    CSV_COLUMNS,
    ...createArraysOfArraysWithEmptyStrings(CSV_COLUMNS.length, 1)
  ];
};

/**
 * Return keys for product item template
 *
 * @param {CreateItemTemplateInput} itemTemplate
 * @returns {object}
 *
 */
export const returnValidProductItem = (
  itemTemplate: CreateItemTemplateInput
): object => {
  Object.keys(itemTemplate).map(key => {
    if (key === 'id' || key === '__typename') {
      delete itemTemplate[key];
    }

    if (!itemTemplate[key]) {
      delete itemTemplate[key];
    }
  });

  return itemTemplate;
};

/**
 * Create an array of empty strings for use as empty rows in csv templates
 *
 * @param {number} arraySize
 * @returns {Array<string>}
 */
export const createArraysOfArraysWithEmptyStrings = (
  arraySize: number,
  totalColumns: number
): Array<Array<string>> => {
  return new Array(arraySize).fill(new Array(totalColumns).fill(''));
};

/** Return table columns for product items based on template */
export const returnNewProductItemColumns = (
  itemTemplate: CreateItemTemplateInput,
  searchFilter: boolean
): Array<TableColumnType> => {
  let tableColumns: Array<TableColumnType> = [];

  // If brand exists on selected template item, include it in the table columns
  if (itemTemplate.brand) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Brand',
        accessor: 'brand.name',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If sub brand exists on selected template item, include it in the table columns
  if (itemTemplate.subBrand) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Sub Brand',
        accessor: 'subBrand.name',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If client name exists on selected template item, include it in the table columns
  if (itemTemplate.clientName) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Client Name',
        accessor: 'clientName',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If product name exists on selected template item, include it in the table columns
  if (itemTemplate.productName) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Product Name',
        accessor: 'productName',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If service name exists on selected template item, include it in the table columns
  if (itemTemplate.serviceName) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Service Name',
        accessor: 'serviceName',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If category exists on selected template item, include it in the table columns
  if (itemTemplate.category) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Category',
        accessor: 'category',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If sub category exists on selected template item, include it in the table columns
  if (itemTemplate.subCategory) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Sub Category',
        accessor: 'subCategory',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If type exists on selected template item, include it in the table columns
  if (itemTemplate.type) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Type',
        accessor: 'type',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If gender exists on selected template item, include it in the table columns
  if (itemTemplate.gender) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Gender',
        accessor: 'gender',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If age exists on selected template item, include it in the table columns
  if (itemTemplate.age) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Age',
        accessor: 'age',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If look exists on selected template item, include it in the table columns
  if (itemTemplate.look) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Look',
        accessor: 'look',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If size exists on selected template item, include it in the table columns
  if (itemTemplate.size) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Size',
        accessor: 'size',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If quantity exists on selected template item, include it in the table columns
  if (itemTemplate.quantity) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Quantity',
        accessor: 'quantity',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If unit exists on selected template item, include it in the table columns
  if (itemTemplate.unit) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Unit',
        accessor: 'unit',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If other1 exists on selected template item, include it in the table columns
  if (itemTemplate.other1) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Other 1',
        accessor: 'other1',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If other2 exists on selected template item, include it in the table columns
  if (itemTemplate.other2) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Other 2',
        accessor: 'other2',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If other3 exists on selected template item, include it in the table columns
  if (itemTemplate.other3) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'Other 3',
        accessor: 'other3',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }
  // If uic exists on selected template item, include it in the table columns
  if (itemTemplate.uic) {
    tableColumns = [
      ...tableColumns,
      {
        Header: 'UIC',
        accessor: 'uic',
        sortable: false,
        filterable: searchFilter
      }
    ];
  }

  return tableColumns;
};

/** This function returns an array of product item keys
 * based on the item template that the company selected.
 * @param itemTemplate - company product item template
 */
export const returnProductItemKeysBasedOnCompanyTemplate = (
  itemTemplate: CreateItemTemplateInput
): Array<string> => {
  let productItemKeys: Array<string> = [];
  // If brand exists on selected template item, include it in the product item keys
  if (itemTemplate.brand) {
    productItemKeys = [...productItemKeys, 'brand'];
  }
  // If sub brand exists on selected template item, include it in the product item keys
  if (itemTemplate.subBrand) {
    productItemKeys = [...productItemKeys, 'subBrand'];
  }
  // If client name exists on selected template item, include it in the product item keys
  if (itemTemplate.clientName) {
    productItemKeys = [...productItemKeys, 'clientName'];
  }
  // If product name exists on selected template item, include it in the product item keys
  if (itemTemplate.productName) {
    productItemKeys = [...productItemKeys, 'productName'];
  }
  // If service name exists on selected template item, include it in the product item keys
  if (itemTemplate.serviceName) {
    productItemKeys = [...productItemKeys, 'serviceName'];
  }
  // If category exists on selected template item, include it in the product item keys
  if (itemTemplate.category) {
    productItemKeys = [...productItemKeys, 'category'];
  }
  // If sub category exists on selected template item, include it in the product item keys
  if (itemTemplate.subCategory) {
    productItemKeys = [...productItemKeys, 'subCategory'];
  }
  // If type exists on selected template item, include it in the product item keys
  if (itemTemplate.type) {
    productItemKeys = [...productItemKeys, 'type'];
  }
  // If gender exists on selected template item, include it in the product item keys
  if (itemTemplate.gender) {
    productItemKeys = [...productItemKeys, 'gender'];
  }
  // If age exists on selected template item, include it in the product item keys
  if (itemTemplate.age) {
    productItemKeys = [...productItemKeys, 'age'];
  }
  // If look exists on selected template item, include it in the product item keys
  if (itemTemplate.look) {
    productItemKeys = [...productItemKeys, 'look'];
  }
  // If size exists on selected template item, include it in the product item keys
  if (itemTemplate.size) {
    productItemKeys = [...productItemKeys, 'size'];
  }
  // If quantity exists on selected template item, include it in the product item keys
  if (itemTemplate.quantity) {
    productItemKeys = [...productItemKeys, 'quantity'];
  }
  // If unit exists on selected template item, include it in the product item keys
  if (itemTemplate.unit) {
    productItemKeys = [...productItemKeys, 'unit'];
  }
  // If other1 exists on selected template item, include it in the product item keys
  if (itemTemplate.other1) {
    productItemKeys = [...productItemKeys, 'other1'];
  }
  // If other2 exists on selected template item, include it in the product item keys
  if (itemTemplate.other2) {
    productItemKeys = [...productItemKeys, 'other2'];
  }
  // If other3 exists on selected template item, include it in the product item keys
  if (itemTemplate.other3) {
    productItemKeys = [...productItemKeys, 'other3'];
  }
  // If uic exists on selected template item, include it in the product item keys
  if (itemTemplate.uic) {
    productItemKeys = [...productItemKeys, 'uic'];
  }
  return productItemKeys;
};

/**
 *
 * Converts pixels to rems with base pixel unit of 16px
 *
 * @param  {number} unitValue - number of pixels
 * @returns string - rem value
 */
export const convertPixelsToRem = (unitValue: number): string => {
  return `${(unitValue / 16).toFixed(2)}rem`;
};

/** Return an array with objects from nested array
 * @param nestedArray
 */
export const returnFlattenedArray = (nestedArray: Array<any>) => {
  const flatten = (arr: any) =>
    arr.reduce((flat: any, next: any) => flat.concat(next.items), []);

  const merged = Array.prototype.concat.apply([], nestedArray);
  return flatten(merged);
};

/** Return date period in string format for a given week */
export const returnDatePeriod = (calendarWeek: Array<Date>) => {
  return `${moment(calendarWeek[0]).format('DD')} - ${moment(
    calendarWeek[6]
  ).format('DD')} ${moment(calendarWeek[6]).format('MMMM')}`;
};

/**
 *  return an incremented time value for a moment object
 *
 * @param {moment.Moment} currentTime
 * @param {moment.DurationInputArg2} duration - amount to increment by
 * @param {string} period - seconds, week, year etc
 * @returns
 */
export const returnIncrementedTimeValue = (
  currentTime: moment.Moment,
  amount: number,
  period: moment.unitOfTime.DurationConstructor
) => {
  return moment(currentTime).add(amount, period);
};

/**
 *  return an decremented time value for a moment object
 *
 * @param {moment.Moment} currentTime
 * @param {moment.DurationInputArg2} duration - amount to increment by
 * @param {string} period - seconds, week, year etc
 * @returns
 */
export const returnDecrementedTimeValue = (
  currentTime: moment.Moment,
  amount: number,
  period: moment.unitOfTime.DurationConstructor
) => {
  return moment(currentTime).subtract(amount, period);
};

/** Get the total/sum. Used with reduce() function */
export const getTotal = (total: number, num: number) => {
  return total + num;
};

/** Return array of dates with the specifed format
 * @param dates - array of date objects
 * @param formate - format to be used
 */
export const returnFormattedDates = (
  dates: Array<Date>,
  chosenFormat: string
) => {
  if (dates && dates.length) {
    return dates.map((date: Date) => {
      return moment(date).format(chosenFormat);
    });
  }
  return [];
};

/** Remove undefined elements from an array
 * @param arrayToBeFiltered
 */
export const removeUndefinedElementsFromArray = (
  arrayToBeFiltered: Array<any>
) => {
  return arrayToBeFiltered.filter((element: any) => element !== undefined);
};

export function sortSubBrandsAlphabetically(
  data: Array<{ id: string; name: string }>
) {
  if (Array.isArray(data) && data.length > 0) {
    return data.sort((a, b) => {
      return ('' + a.name).localeCompare(b.name);
    });
  }
  return data;
}

/**
 * Sort Array Alphabetically using the name key
 *
 * @param {Array<object>} data
 *
 *
 * @returns {Array<object>} - array sort alphabetically
 */
export function sortArrayAlphabetically(data: Array<any>) {
  if (Array.isArray(data) && data.length > 0) {
    return data.sort((a, b) => {
      if (a['uic']) {
        return ('' + a['uic']).localeCompare(b['uic']);
      }
      return ('' + a['name']).localeCompare(b['name']);
    });
  }

  return data;
}

/**
 * Sort Array Alphabetically using the name key
 *
 * @param {Array<{ value: string; label: string }>} data
 *
 *
 * @returns {Array<{ value: string; label: string }>} - array sort alphabetically
 */
export function sortDropDownDataAlphabetically(
  data: Array<{ value: string; label: string }>
) {
  if (Array.isArray(data) && data.length > 0) {
    return data.sort((a, b) => {
      return ('' + a.label).localeCompare(b.label);
    });
  }

  return data;
}

/** Return display name for a user
 * If first name and last name are not both
 * set, display email
 * @param user - system user type
 */
export const returnDisplayName = (user: UpdateUserInput): string => {
  const displayName =
    !user.firstName && !user.lastName
      ? `${user.email}`
      : `${user.firstName} ${user.lastName}`;
  return displayName;
};

/** Return sorted array based on date
 * @param {Arrray} arrayOfObjects - array of objects with a date property
 * @param {string} order - the sorting order (desc/asc)
 * @param {string} filterField - the field to sort by
 *
 *
 * @returns {Array} - array of sorted items
 */
export const returnSortedArrayByDate = (
  arrayOfObjects: Array<any>,
  order?: string,
  filterField?: string
): Array<any> => {
  // The array object literal should have a timestamp key but you can parse a
  // custom sort field if not timestamp is available
  const filteredArrayOfObjects = arrayOfObjects.filter(
    (object: any) => object[filterField || 'timestamp']
  );

  if (filteredArrayOfObjects && filteredArrayOfObjects.length) {
    return filteredArrayOfObjects.sort((a, b) => {
      const dateA: any = moment(a[filterField || 'timestamp']).toDate();
      const dateB: any = moment(b[filterField || 'timestamp']).toDate();
      return order && order === 'desc' ? dateB - dateA : dateA - dateB;
    });
  }
  return [];
};

/** Return array of unique values
 * @param arrayWithDuplicates - an array with duplicate values
 */
export const returnArrayWithUniqValues = (arrayWithDuplicates: Array<any>) => [
  ...new Set(arrayWithDuplicates)
];

/** Return the chosen week */
export const returnWeek = (): Array<Date> => {
  const startOfWeek = moment().startOf('isoWeek');
  const weekArray = moment.weekdays();

  const daysOfCurrentWeek = weekArray.map((d, i) => {
    return startOfWeek
      .clone()
      .add(i, 'd')
      .toDate();
  });

  return daysOfCurrentWeek;
};

/** Update calendar to the week of a selected date
 * @param date - the chosen date
 * @param currentWeek - array of a date objects for a week
 */
export const updateCurrentWeekByDate = (
  date: Date | null,
  currentWeek: Array<Date>
) => {
  if (date) {
    const startOfWeek = moment(date).startOf('isoWeek');
    const weekArray = moment.weekdays();
    const daysOfWeekInSelectedDate = generateWeek(startOfWeek, weekArray);
    return daysOfWeekInSelectedDate;
  } else {
    return currentWeek;
  }
};

/** Generate a week (seven date objects) starting from a particular date
 * @param startOfWeek - a moment object of the first business day of the week
 * @param daysOfWeek - an array of the days of the week in string format (i.e. Sunday, Monday, etc.)
 */
export const generateWeek = (
  startOfWeek: moment.Moment,
  daysOfWeek: Array<string>
): Array<Date> => {
  if (startOfWeek && daysOfWeek.length) {
    return daysOfWeek.map((d, i) => {
      return startOfWeek
        .clone()
        .add(i, 'd')
        .toDate();
    });
  }
  return [];
};

/** Toggle calendar to next week
 * @param currentWeek - array of a date objects for a week
 */
export const nextWeek = (currentWeek: Array<Date>) => {
  const endOfCurrentWeek = moment(currentWeek[0]).endOf('isoWeek');
  const startOfNewWeek = endOfCurrentWeek.add(1, 'days');

  const weekArray = moment.weekdays();
  const daysOfNextWeek = weekArray.map((d, i) => {
    return startOfNewWeek
      .clone()
      .add(i, 'd')
      .toDate();
  });

  return daysOfNextWeek;
};

/** Toggle calendar to previous week
 * @param currentWeek - array of a date objects for a week
 */
export const prevWeek = (currentWeek: Array<Date>) => {
  const startOfCurrentWeek = moment(currentWeek[0]).startOf('isoWeek');
  const startOfPreviousWeek = startOfCurrentWeek.subtract(7, 'days');

  const weekArray = moment.weekdays();
  const daysOfPreviousWeek = weekArray.map((d, i) => {
    return startOfPreviousWeek
      .clone()
      .add(i, 'd')
      .toDate();
  });

  return daysOfPreviousWeek;
};

/** Check if object is a non empty array only containing string values
 * @param arrayToCheck - object to be checked
 */
export const checkIfArrayOfStrings = (arrayToCheck: any): Array<string> => {
  if (arrayToCheck && arrayToCheck instanceof Array && arrayToCheck.length) {
    const arrayOfNonStringValues = arrayToCheck.filter((value: any) => {
      return typeof value !== 'string';
    });

    if (arrayOfNonStringValues && arrayOfNonStringValues.length) {
      return [];
    }
    return arrayToCheck;
  }
  return [];
};

/**
 * Return the date for a particular day in the week
 * @param day - day of the week represented by an index
 * @param withYear - an optional flag if the year should also be returned
 */
export const returnDateInGivenWeek = (
  day: number,
  currentWeek: Array<Date>,
  withYear?: boolean
): string => {
  return withYear
    ? moment(currentWeek[day]).format('DD MMM YYYY')
    : moment(currentWeek[day]).format('DD MMM');
};

/** Return joint GraphQL mutation
 * @param mutations - array of mutations to be joined
 */
export const joinMutations = (mutations: Array<string>): string => {
  const joinedMutations = mutations.join('\n');

  return `
    mutation {
      ${joinedMutations}
    }
  `;
};

/** Return joint GraphQL mutation
 * @param mutations - array of mutations to be joined
 */
export const joinQueries = (queries: Array<string>): string => {
  const joinedQueries = queries.join('\n');

  return `
    query {
      ${joinedQueries}
    }
  `;
};

/**
 * Return appropriate timestamp for product items
 *
 * @param {(string | undefined)} key
 * @param {{ createdAt: Date; taggedAt: Date; completedAt: Date; qcApprovedAt: Date; batchCard: { createdAt: Date };}} - item
 *
 * @returns {Date} - timestamp
 */
export const returnTimeStampAccordingToKey = (
  key: string | undefined,
  item: {
    createdAt: Date | string;
    taggedAt: Date | string;
    completedAt: Date | string;
    qcApprovedAt: Date | string;
    batchCard: { createdAt: Date | string };
  }
) => {
  switch (key) {
    case 'withBatchCard':
      return item.batchCard.createdAt;
    case 'tagged':
      return item.taggedAt;
    case 'completed':
      return item.completedAt;
    case 'qcApproved':
      return item.qcApprovedAt;
    default:
      return item.createdAt;
  }
};

/**
 *  Get array of object keys for chart data
 *
 * @param {Array<{ name: string; count: number }>} data
 * @param {string} filter
 * @returns {Array<string>}
 */
export const returnDataKeys = (
  data: Array<{ name: string; count: number }>,
  filter: string,
  filterPeriod: moment.Moment,
  filterPeriodName: React.ReactNode
): Array<string> => {
  const keys: Array<string> = [];

  if (filter === 'week') {
    data.map(day => keys.push(day.name));
  }

  if (filter === 'month') {
    data.map(day =>
      keys.push(
        `${moment(new Date(`${day.name}-${filterPeriodName}`)).format(
          'Do MMM'
        )}`
      )
    );
  }

  if (filter === 'year') {
    data.map(day => keys.push(`${day.name} ${filterPeriod.format('YYYY')}`));
  }

  return keys;
};

/**
 *  Get array of the data count
 *
 * @param {Array<{ name: string; count: number }>} data
 * @returns {Array<number>}
 */
export const returnDataCount = (
  data: Array<{ name: string; count: number }>
): Array<number> => {
  const count: Array<number> = [];
  data.map(day => count.push(day.count));

  return count;
};

export const returnItemCount = (
  currentDate: number | string,
  timestamp: Date | string,
  dateFormat: string,
  count: number,
  monthOrYear?: string
) => {
  const itemDate = moment(new Date(timestamp)).format(dateFormat);
  if (
    (monthOrYear === 'month' && currentDate === parseInt(itemDate)) ||
    (monthOrYear === 'year' && currentDate === itemDate) ||
    moment(currentDate).isSame(itemDate)
  ) {
    count += 1;
  }
  return count;
};

/**
 * Get chart data filtered using specified month
 *
 * @param {Array<{ createdAt: Date; taggedAt: Date; batchCard: object }>} data
 * @param {moment.Moment} filterPeriod
 *
 * @returns {ChartData}
 */
export const returnItemsFilteredUsingMonth = (
  items: Array<{
    createdAt: Date;
    taggedAt: Date;
    completedAt: Date;
    qcApprovedAt: Date;
    productItems: { items: Array<CreateProductItemInput> };
    batchCard: { createdAt: Date; id: string };
  }>,
  filterPeriod: moment.Moment,
  itemsType?: string
): Array<{
  name: string;
  count: number;
}> => {
  const daysInMonth = moment(filterPeriod).daysInMonth();
  const chartData: ChartData = [];
  const month = 'month';

  for (let i = 1; i <= daysInMonth; i++) {
    let totalItems = 0;
    switch (itemsType) {
      case 'withBatchCard':
        // remove duplicate batch cards
        Array.from(new Set(items.map(item => item.batchCard))).map(
          batchCard => {
            totalItems = returnItemCount(
              i,
              batchCard.createdAt,
              'D',
              totalItems,
              month
            );
          }
        );
        break;
      case 'withProductItems':
        items.map(item => {
          item.productItems &&
            item.productItems.items.map(productItem => {
              totalItems = returnItemCount(
                i,
                productItem.createdAt,
                'D',
                totalItems,
                month
              );
            });
        });
        break;
      default:
        items.map(item => {
          const timestamp: Date | string = returnTimeStampAccordingToKey(
            itemsType,
            item
          );
          totalItems = returnItemCount(i, timestamp, 'D', totalItems, month);
        });
    }

    chartData.push({
      name: i.toString(),
      count: totalItems
    });
  }

  return chartData;
};

/**
 * Get chart data filtered using specified week
 *
 * @param {Array<{ createdAt: Date; taggedAt: Date; batchCard: object }>} data
 * @param {Array<string>} formattedDates
 *
 * @returns {ChartData}
 *
 */
export const returnItemsFilteredUsingWeek = (
  items: Array<{
    createdAt: Date;
    taggedAt: Date;
    completedAt: Date;
    qcApprovedAt: Date;
    productItems?: { items: Array<CreateProductItemInput> };
    batchCard: { createdAt: Date; id: string };
  }>,
  formattedDates: Array<string>,
  itemsType?: string
): Array<{
  name: string;
  count: number;
}> => {
  const chartData: ChartData = [];
  formattedDates.map(date => {
    let totalItems = 0;
    const currentDate = moment(new Date(date)).format('YYYY/MM/DD');

    if (items && Object.keys(items).length) {
      switch (itemsType) {
        case 'withBatchCard':
          Array.from(new Set(items.map(item => item.batchCard))).map(
            batchCard => {
              totalItems = returnItemCount(
                currentDate,
                batchCard.createdAt,
                'YYYY/MM/DD',
                totalItems
              );
            }
          );
          break;
        case 'withProductItems':
          items.map(item => {
            item.productItems &&
              item.productItems.items.map(productItem => {
                totalItems = returnItemCount(
                  currentDate,
                  productItem.createdAt,
                  'YYYY/MM/DD',
                  totalItems
                );
              });
          });
          break;
        default:
          items.map(item => {
            const timestamp: Date | string = returnTimeStampAccordingToKey(
              itemsType,
              item
            );
            totalItems = returnItemCount(
              currentDate,
              timestamp,
              'YYYY/MM/DD',
              totalItems
            );
          });
      }
    }

    chartData.push({
      name: moment(new Date(currentDate)).format('D MMM'),
      count: totalItems
    });
  });

  return chartData;
};

/**
 * Get chart data filtered using specified year
 *
 * @param {Array<{ createdAt: Date; taggedAt: Date; batchCard: object }>} data
 *
 * @returns {ChartData}
 */
export const returnItemsFilteredUsingYear = (
  items: Array<{
    createdAt: Date;
    taggedAt: Date;
    completedAt: Date;
    qcApprovedAt: Date;
    batchCard: { createdAt: Date };
    productItems: { items: Array<CreateProductItemInput> };
  }>,
  itemsType?: string
): Array<{
  name: string;
  count: number;
}> => {
  const chartData: ChartData = [];
  const allMonths = moment.monthsShort();
  const year = 'year';

  allMonths.map(date => {
    let totalItems = 0;

    if (items && Object.keys(items).length) {
      switch (itemsType) {
        case 'withBatchCard':
          // remove duplicate batch cards
          Array.from(new Set(items.map(item => item.batchCard))).map(
            batchCard => {
              totalItems = returnItemCount(
                date,
                batchCard.createdAt,
                'MMM',
                totalItems,
                year
              );
            }
          );
          break;
        case 'withProductItems':
          items.map(item => {
            item.productItems &&
              item.productItems.items.map(productItem => {
                totalItems = returnItemCount(
                  date,
                  productItem.createdAt,
                  'MMM',
                  totalItems,
                  year
                );
              });
          });
          break;
        default:
          items.map(item => {
            const timestamp: Date | string = returnTimeStampAccordingToKey(
              itemsType,
              item
            );
            totalItems = returnItemCount(
              date,
              timestamp,
              'MMM',
              totalItems,
              year
            );
          });
      }
    }

    chartData.push({
      name: date,
      count: totalItems
    });
  });

  return chartData;
};

/** Return the selected value from a list of options
 * @param valueId - the id of the selected value
 * @param options - the list of options from a dropdown menu
 */
export const selectedOption = (
  valueId: string,
  options: Array<SelectType>
): SelectType => {
  if (valueId !== '' && options && options.length) {
    return options.filter(option => option.value === valueId)[0];
  }
  return { value: '', label: '' };
};

/**
 * check if any value is undefined in a given object
 *
 * @param {object} obj
 * @returns {boolean}
 */
export const checkForUndefinedObjectValues = (obj: object): boolean => {
  return Object.keys(obj).every((value: string) => obj[value] !== undefined);
};

/**
 * check if any value is undefined, empty or null in a given array
 *
 * @param {Array<any>} arrayToCheck
 * @returns {boolean}
 */
export const checkForInvalidArrayValues = (
  arrayToCheck: Array<any>
): boolean => {
  return arrayToCheck.every((value: any) => !!value);
};

/**
 * Check if a card has reached it's expiry date
 *
 * @param {Date} cardExpiryDate
 * @returns {boolean}
 */
export const checkIfCardIsExpired = (
  cardExpiryDate: moment.Moment
): boolean => {
  return moment().diff(cardExpiryDate, 'seconds') > 0;
};

/** Convert number to price format
 * @param value - value to be converted to price format
 * @param currency - currency to be used
 */
export const getPriceFromNumber = (
  value: number,
  currency: CurrencyType
): string => {
  if (value) {
    return `${getCurrencySymbol(currency)} ${numberWithCommas(
      (value / 100).toFixed(2)
    )}`;
  }

  return `${getCurrencySymbol(currency)} 0.00`;
};

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Return the currency symbol
 * @param currency
 */
export const getCurrencySymbol = (currency: CurrencyType): CURRENCY_SYMBOL => {
  switch (currency) {
    case 'USD':
      return CURRENCY_SYMBOL.usd;
    case 'EURO':
      return CURRENCY_SYMBOL.euro;
    case 'GBP':
      return CURRENCY_SYMBOL.gbp;
    default:
      return CURRENCY_SYMBOL.usd;
  }
};

/**
 * Get array of services from array of the dropdown select options
 *
 * @param {Array<{ object: ServiceType }>} servicesSelect
 * @returns {Array<ServiceType>}
 */
export const returnServicesFromDropDownOptions = (
  servicesSelect: SelectOptionsType
): Array<ServiceType> => {
  const services: Array<ServiceType> = [];

  servicesSelect.map(service => services.push(service.object.name));
  return services;
};

/** Return filter conditions for a GraphQL query that checks which users are part of certain spaces
 * @param spaceIds - array of space id's
 */
export const getUserSpaceFilters = (spaceIds: Array<string>): string => {
  const filterConditions = spaceIds.map(spaceId => {
    return `
      {
        id: {
          eq: "${spaceId}"
        }
      }
    `;
  });

  return filterConditions.join('\n');
};

/** Check if user is admin */
export const isOwner = (path: string): boolean => path.includes('/admin');

export const mergeItemTemplateObjectWithCSVValues = (
  itemTemplate: object,
  csvValues: Array<string>
): any => {
  const mergedObject: object = {};

  Object.keys(itemTemplate).map((key, index) => {
    mergedObject[key] = csvValues[index];
  });

  return mergedObject;
};

export const checkForNullBrandAndSubBrandCsvData = (
  createdProductItems: Array<object>
) => {
  const formatCreatedProductItems = createdProductItems.map((item: object) => {
    if (!item['brand']) {
      item['brand'] = { name: '' };
    }
    if (!item['subBrand']) {
      item['subBrand'] = { name: '' };
    }
    return item;
  });
  return formatCreatedProductItems;
};

export const mapUserPermissionToCognitoGroup = (userPermission: string) => {
  switch (userPermission) {
    case Permission.NON_USER:
      return NON_USER_GROUP;
    case Permission.SUPER_USER:
      return SUPER_USER_GROUP;
    default:
      return USER_GROUP;
  }
};
