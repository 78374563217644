import * as React from 'react';
import * as validator from 'validator';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, Label, FormGroup, Input, Button } from 'reactstrap';
import ReactPhoneInput from 'react-phone-input-2';

/** GraphQL */
import { updateUser } from '../../graphql/mutations';

import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserInput
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Local components */
import UploadAvatar from './UploadAvatar';

/** Customer Types */
import { Error, UpdatePersonalProfileViews } from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

interface IProps {
  user: UpdateUserInput;
  closeModal(): void;
  updateView(view: UpdatePersonalProfileViews): void;
  notification(message: string): void;
}

type State = {
  id: string;
  firstName: string;
  lastName?: string | null;
  email: string;
  phoneNumber?: string | null;
  loading: boolean;
  error: Error;
};

class EditUserForm extends React.Component<IProps, State> {
  timeoutId: number = 0;

  constructor(props: IProps) {
    super(props);
    this.state = {
      id: props.user.id,
      firstName: props.user.firstName || '',
      lastName: props.user.lastName || null,
      email: props.user.email || '',
      phoneNumber: props.user.phoneNumber || null,
      loading: false,
      error: null
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  // Handle change
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    switch (name) {
      case 'firstName':
        this.setState({ firstName: value });
        break;
      case 'lastName':
        this.setState({ lastName: value || null });
        break;
      case 'email':
        this.setState({ email: value });
        break;
      default:
        break;
    }
  };

  validateForm = (): boolean => {
    const { firstName, email } = this.state;
    // Check for undefined or empty input field
    if (!firstName) {
      this.setError('Please fill in the first name field');
      return false;
    }

    // Validate email
    if (!validator.isEmail(email)) {
      this.setError('Please enter a valid email address.');
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { id, firstName, lastName, email, phoneNumber } = this.state;
    const { notification, closeModal, updateView, user } = this.props;

    return (
      <Mutation<UpdateUserMutation, UpdateUserMutationVariables>
        mutation={gql(updateUser)}
      >
        {updateUserMutation => (
          <div>
            <UploadAvatar
              userId={id}
              avatarUrl={user.avatarUrl}
              avatarKey={user.avatarKey}
              updateUserMutation={updateUserMutation}
            />
            <Form
              onSubmit={e => {
                e.preventDefault();
                if (this.validateForm()) {
                  this.setState({ loading: true });

                  updateUserMutation({
                    variables: {
                      input: {
                        id,
                        firstName,
                        lastName,
                        phoneNumber
                      }
                    }
                  })
                    .then(res => {
                      this.setState({ loading: false });
                      closeModal();
                      notification('Profile updated successfully');
                    })
                    .catch(err => {
                      this.setState({ loading: false });
                      this.setError(err.message);
                    });
                }
              }}
            >
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      value={firstName}
                      id="firstName"
                      placeholder="First name"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      value={lastName || ''}
                      id="lastName"
                      placeholder="Last name"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <Label for="userEmail">Email Address</Label>
                    <Input
                      type="email"
                      name="email"
                      value={email}
                      id="userEmail"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <ReactPhoneInput
                      value={phoneNumber || ''}
                      defaultCountry={'za'}
                      onChange={(userPhoneNumber: any) =>
                        this.setState({ phoneNumber: userPhoneNumber || null })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <StyledButton
                      label="Change Password"
                      type="button"
                      onClick={() => updateView('changePassword')}
                      background={Colors.grey}
                      color={Colors.flumeGreen}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup>
                    <Button>
                      {!this.state.loading ? 'Update' : <Loader />}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              {this.state.error && (
                <ErrorMessage errorMessage={this.state.error} />
              )}
            </Form>
          </div>
        )}
      </Mutation>
    );
  }
}

export default EditUserForm;
