import styled from 'styled-components';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

/** App theme */
import { Colors } from '../../Themes';

export const StyledPopOver = styled(Popover)`
  border-radius: none !important;
  font-family: Gibson !important;
`;

export const StyledPopOverBody = styled(PopoverBody)`
  padding: 12px 16px !important;
`;

export const StyledPopoverHeader = styled(PopoverHeader)`
  padding: 5px 16px 4px;
  min-height: 32px;
  border-bottom: 1px solid #e8e8e8;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  background-color: ${Colors.snow} !important;
  border-radius: none !important;
`;

export const StyledList = styled('ul')`
  color: ${Colors.grey};
`;
