import * as React from 'react';

/** Presentation/UI */
import GlobalModalContainer from '../../Components/Modal';
import StyledButton from '../../Components/Styled/Button';

/** Local components */
import CancelSubscriptionPlanModal from './CancelSubscriptionPlanModal';

/** Theme */
import { Colors } from '../../Themes';
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import { convertPixelsToRem } from '../../Utils/Helpers';

type Props = {
  companyId: string;
  subscriptionId: string;
};

const CancelSubscription: React.FC<Props> = ({ companyId, subscriptionId }) => {
  const [modal, toggleModal] = React.useState(false);

  return (
    <section>
      <GlobalModalContainer
        toggleModal={() => toggleModal(false)}
        title="Cancel Subscription Plan"
        modalDisplay={
          <CancelSubscriptionPlanModal
            companyId={companyId}
            subscriptionId={subscriptionId}
            closeDialogModal={() => toggleModal(!modal)}
          />
        }
        modal={modal}
      />
      <br />
      <FullWidthContainer>
        <p>
          Cancelling your company's subscription plan will stop all billing and
          invoicing on the current plan that you are subscribed to. Furthermore,
          your company's account will be disabled and all associated users will
          be unable to access their account's under this company.
        </p>
      </FullWidthContainer>
      <StyledButton
        label="Cancel Subscription"
        type="button"
        width={convertPixelsToRem(200)}
        onClick={() => toggleModal(true)}
        background={Colors.error}
        color={Colors.snow}
      />
    </section>
  );
};

export default CancelSubscription;
