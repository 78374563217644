/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import * as moment from 'moment';
/** GraphQL */
import { getUsersInSpacesQuery } from './custom-queries';

/** Custom types */
import { SelectType, SpacesWithUserAllocationType } from '../../../CustomTypes';

/** Update users by adding them to a space or removing them based on selections
 * @param spaceId - id for the space that members (users) are being added to
 * @param setLoading - function to toggle the loading state value
 * @param selectedUsers new selection of users to be added to the space
 * @param userId - id for the authenticated user
 * @param users - existing users currently in the space
 * @param updateUserMutation - mutation to update the users in a current space
 * @param closeModal - function to close modal
 * @param notification - function to display success/error response notification
 */
export const executeMutations = async (
  spaceId: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  selectedUsers: Array<SelectType>,
  userId: string,
  users: Array<SelectType>,
  createUserAllocationMutation: ({}) => Promise<any>,
  closeModal: () => void,
  notification: (message: string, appearance?: string) => void,
  selectedSpaceId: string,
  allocationDate: string,
  space: SpacesWithUserAllocationType,
  existingUsers: Array<SelectType>,
  deleteUserAllocationMutation: ({}) => Promise<any>
) => {
  // const userSpaceId = spaceId;
  setLoading(true);

  try {
    // add users to a space
    const newUsers = selectedUsers.filter(user => {
      return !existingUsers.find(existingUser => {
        return existingUser.value === user.value;
      });
    });
    if (newUsers && newUsers.length) {
      await Promise.all(
        newUsers.map(async (user: SelectType) => {
          await createUserAllocationMutation({
            variables: {
              input: {
                userAllocationUserId: user.value,
                userAllocationSpaceId: selectedSpaceId,
                date: allocationDate
              }
            },
            refetchQueries: [
              {
                query: getUsersInSpacesQuery(userId, [spaceId])
              }
            ]
          });
        })
      );
    }

    // remove deselected users from space
    const deselectedUsersAllocations = space.spaceAllocation.items.filter(
      allocation => {
        if (
          moment(allocation.date).format('DD MMM YYYY') ===
          moment(allocationDate).format('DD MMM YYYY')
        ) {
          return !selectedUsers.find((selectedUser: SelectType) => {
            return allocation.user.id === selectedUser.value;
          });
        }
        return; // eslint-disable-line
      }
    );

    if (deselectedUsersAllocations && deselectedUsersAllocations.length) {
      await Promise.all(
        deselectedUsersAllocations.map(async deletedAllocation => {
          await deleteUserAllocationMutation({
            variables: {
              input: {
                id: deletedAllocation.id
              }
            },
            refetchQueries: [
              {
                query: getUsersInSpacesQuery(userId, [spaceId])
              }
            ]
          });
        })
      );
    }

    closeModal();
    notification('Space members updated successfully');
  } catch (e) {
    closeModal();
    notification(e.message, 'error');
  }
};
