import * as React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

// GraphQL
import { getUser } from '../../graphql/queries';
import { GetUserQuery, GetUserQueryVariables } from '../../API';

// Presentation/UI
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import Loader from '../../Components/Loader';

// Local components
import SubscribeToProduct from './SubscribeToProduct';

// Themes
import { Colors } from '../../Themes';

type Props = {
  userId: string;
  updateView(view: string): void;
};

class ChooseProductSubscriptionPlan extends React.Component<Props> {
  render() {
    const { userId, updateView } = this.props;
    return (
      <Query<GetUserQuery, GetUserQueryVariables>
        query={gql(getUser)}
        variables={{ id: userId }}
      >
        {getUserQuery => {
          if (getUserQuery.loading) {
            return (
              <FullWidthContainer align="center">
                <Loader size={150} color={Colors.flumeGreen} />
              </FullWidthContainer>
            );
          }
          if (getUserQuery.error) {
            return <div>There was a problem loading your user data</div>;
          }

          if (!getUserQuery.data) {
            return <div>There was a problem loading your user data</div>;
          }

          const user = getUserQuery.data.getUser;
          let companyId: string = '';

          if (user && user.company) {
            companyId = user.company.id;
          } else {
            return <div>There was a problem loading your company data</div>;
          }

          return (
            <SubscribeToProduct companyId={companyId} updateView={updateView} />
          );
        }}
      </Query>
    );
  }
}

export default ChooseProductSubscriptionPlan;
