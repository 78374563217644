import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Form, Button } from 'reactstrap';

/** Context API */
import { FileUploadContextConsumer } from '../../Containers/NewProductItems/FileUploadContextProvider';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';

/** Local component */
import DropzoneContainer from './DropzoneContainer';

/** Custom types */
import { Error } from '../../CustomTypes';

/** Theme */
import { Colors } from '../../Themes';

/** Utils */
import { VALID_FILE_FORMATS } from '../../Utils/Consts';

type Props = {
  closeModal: () => void;
};

type State = {
  files: Array<File>;
  loading: boolean;
  error: Error;
};

class UploadImages extends React.Component<Props, State> {
  state: State = {
    files: [],
    loading: false,
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** On dropping file
   * @param acceptedFiles - files dropped in dropzone
   */
  onDrop = (acceptedFiles: Array<File>): void => {
    if (!acceptedFiles || !acceptedFiles.length) {
      this.setError('Sorry, there was a problem uploading your selected file.');
    }
    // Get uploaded files of a valid type (jpg or png)
    const files = acceptedFiles.filter(
      (acceptedFile: File) =>
        acceptedFile.type === VALID_FILE_FORMATS.png ||
        acceptedFile.type === VALID_FILE_FORMATS.jpg
    );
    this.setState({ files });
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { files, error } = this.state;
    const { closeModal } = this.props;

    return (
      <FileUploadContextConsumer>
        {({ uploadFiles }) => {
          return (
            <Form
              onSubmit={e => {
                e.preventDefault();
                uploadFiles(files);
                closeModal();
              }}
            >
              <DropzoneContainer>
                <div className="dropzone">
                  {!files.length && (
                    <Dropzone onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>Drop files here...</p>
                            ) : (
                              <p>
                                Drop an image file here, or click to select an
                                image from your local disk.
                              </p>
                            )}
                          </div>
                        );
                      }}
                    </Dropzone>
                  )}
                  {files.map((f: File, i) => (
                    <React.Fragment key={i}>
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: '20px',
                          marginBottom: '20px',
                          background: Colors.snow
                        }}
                      >
                        Chosen file: {f.name} - {`${f.size / 1000} kb`}
                      </div>
                    </React.Fragment>
                  ))}
                  <br />
                  {error && <ErrorMessage errorMessage={error} />}
                  <Button block={true} type="submit">
                    Upload
                  </Button>
                </div>
              </DropzoneContainer>
            </Form>
          );
        }}
      </FileUploadContextConsumer>
    );
  }
}

export default UploadImages;
