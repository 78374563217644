import styled from 'styled-components';

/** Theme */
import { Colors } from '../../Themes';

const DropzoneContainer = styled.section`
  & .dropzone div {
    width: 100% !important;
    height: 100px;
    line-height: 100px;
    background-color: ${Colors.paleGrey};
  }

  & .dropzone:hover {
    cursor: pointer;
  }

  & .dropzone p {
    text-align: center;
  }

  & .dropzone div input {
    width: 100%;
  }

  & .dropzone-content {
    position: relative;
    top: 45%;
  }

  & .choose-files-container {
    margin: 30px auto;
  }
  & .importFile {
    width: 10rem !important;
    margin: 0px 0px 0px 125px !important;
  }
`;

export default DropzoneContainer;
