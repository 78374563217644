/* eslint react-hooks/exhaustive-deps: 0 */
import * as React from 'react';

/** Custom Hooks */
import useErrorHandler from '../../../CustomHooks/ErrorHandler';

/** Presentation/UI */
import BackendWrapper from '../../../Components/Layouts/BackendWrapper';
import FullWidthContainer from '../../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../../Components/Styled/ErrorMessage';
import Loader from '../../../Components/Loader';

/** Themes */
import { Colors } from '../../../Themes';

/** Local components */
import InvoicesTable from './InvoicesTable';

/** Utils */
import { getCustomerInvoices } from '../Utils/Helpers';

const AdminInvoices: React.FC<{}> = () => {
  const [loading, setLoading] = React.useState(false);
  const [invoices, setInvoices] = React.useState([]);
  const { error, showError } = useErrorHandler(null);

  React.useEffect(() => {
    const getInvoices = async () => {
      const customerInvoices = await getCustomerInvoices(setLoading, showError);
      setInvoices(customerInvoices);
    };
    getInvoices();
  }, []);

  if (loading) {
    return (
      <BackendWrapper>
        <FullWidthContainer align="center">
          <Loader size={50} color={Colors.flumeGreen} />
        </FullWidthContainer>
      </BackendWrapper>
    );
  }

  return (
    <BackendWrapper>
      {error && <ErrorMessage errorMessage={error} />}
      <InvoicesTable invoices={invoices} />
    </BackendWrapper>
  );
};

export default AdminInvoices;
