import * as React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

type Props = {
  batchCardId: string;
  productItemId: string;
  previousTagDescriptions: Array<string>;
  productItemImageKeys: Array<string>;
  openImageModal(
    productItemImageKeys: Array<string>,
    productItemId: string,
    batchCardId: string
  ): void;
  openNoteModal(productItemId: string, batchCardId: string): void;
  openAddTextModal(
    productItemId: string,
    bulkAction?: boolean,
    productItemImageKeys?: Array<string>
  ): void;
  openTagsModal(previousTagDescriptions: Array<string>): void;
};

const RowActions: React.FC<Props> = ({
  batchCardId,
  openImageModal,
  openNoteModal,
  openAddTextModal,
  openTagsModal,
  productItemId,
  productItemImageKeys,
  previousTagDescriptions
}) => {
  const [dropdownOpen, toggleDropdown] = React.useState(false);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => toggleDropdown(!dropdownOpen)}
      style={{ position: 'absolute' }}
    >
      <DropdownToggle
        tag="span"
        onClick={() => toggleDropdown(!dropdownOpen)}
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
      </DropdownToggle>
      <DropdownMenu data-testid="dropdown-menu">
        <div
          className="avatar-dropdown-menu-item"
          onClick={() => openNoteModal(productItemId, batchCardId)}
        >
          View Notes
        </div>
        <div
          className="avatar-dropdown-menu-item"
          onClick={() =>
            openImageModal(productItemImageKeys, productItemId, batchCardId)
          }
        >
          View Images
        </div>
        <div
          className="avatar-dropdown-menu-item"
          onClick={() =>
            openAddTextModal(productItemId, false, productItemImageKeys || [])
          }
        >
          View Text
        </div>
        <div
          className="avatar-dropdown-menu-item"
          onClick={() => openTagsModal(previousTagDescriptions)}
        >
          View Tags
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default RowActions;
