/* eslint jsx-a11y/alt-text: 0 */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Query } from 'react-apollo';
import { MdSearch, MdDone } from 'react-icons/md';
import { withToastManager } from 'react-toast-notifications';

/** GraphQL */
import { getCompanyTaggedProductItemsByUser } from '../../graphql/custom-queries';

/** Generated types */
import { UpdateProductItemInput, UpdateServiceInput } from '../../API';

/** Presentation/UI */
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import GlobalModalContainer from '../../Components/Modal';
import PageLoader from '../../Components/PageLoader';
import StyledButton from '../../Components/Styled/Button';
import Table from '../../Components/Table';
import {
  CircleIcon,
  Checkbox,
  SelectedIcon,
  TableHeader,
  TableHeaderContainer,
  ServiceIconsContainer
} from '../../Components/Styled/ListViewElements';

/** Local components */
import AddNoteToProductItem from '../MainPlanner/AddNoteToProductItem';
import DeleteProductItem from './DeleteProductItem';
import RowActions from './RowActions';
import UntagProductItem from './UntagProductItem';
import MoveItemToItemsList from './MoveItemToItemsList';

import { ToastNotificationType } from '../../CustomTypes';

/** Themes */
import { Colors, ServiceIcons } from '../../Themes';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Utils */
import {
  returnNewProductItemColumns,
  checkIfArrayOfStrings,
  sortArrayAlphabetically
} from '../../Utils/Helpers';
import {
  checkIfServiceIsCompleted,
  returnPreviousNotes,
  returnPreviousTextContent
} from '../MainPlanner/BatchCardAllocationHelpers';
import UpdateTagDescription from './UpdateTagDescription';
import AddTextToProductItem from '../MainPlanner/AddTextToProductItem';

type Props = {
  toastManager: ToastNotificationType;
};

type State = {
  allSelected: boolean;
  dialogModal: boolean;
  productItemId: string;
  previousTagDescriptions: Array<string>;
  selectedProductItems: Array<string> | Array<null>;
  searchFilter: boolean;
  noteModal: boolean;
  updateTagModal: boolean;
  untagModal: boolean;
  addTextModal: boolean;
  tagDescription?: string | null;
  productItemImageKeys?: Array<string | null> | null;
};

class TaggedProductItems extends React.Component<Props, State> {
  state = {
    allSelected: false,
    dialogModal: false,
    searchFilter: false,
    noteModal: false,
    updateTagModal: false,
    untagModal: false,
    addTextModal: false,
    productItemId: '',
    previousTagDescriptions: [],
    selectedProductItems: [],
    tagDescription: '',
    productItemImageKeys: []
  };

  /** Close tag modal */
  closeUpdateTagModal = (): void => {
    this.setState({
      updateTagModal: false
    });
  };

  /** Open tag modal
   * @param productItemId - id for a product item
   * @param tagDescription - tag description for tagged product item
   * @param previousTagDescriptions - previously added tags
   */
  openUpdateTagModal = (
    productItemId: string,
    tagDescription?: string | null,
    previousTagDescriptions: Array<string> = []
  ): void => {
    this.setState({
      updateTagModal: true,
      productItemId,
      tagDescription,
      previousTagDescriptions
    });
  };

  /** Close note modal */
  closeNoteModal = (): void => {
    this.setState({
      noteModal: false,
      productItemId: ''
    });
  };

  /** Open note modal
   * @param productItemId - id for a product item
   */
  openNoteModal = (productItemId: string): void => {
    this.setState({
      noteModal: true,
      productItemId
    });
  };

  /** Close dialog modal for tagged product item */
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false,
      productItemId: ''
    });
  };

  /** Open dialog modal for tagged product item
   * @param productItemId - id for selected product item
   */
  openDialogModal = (productItemId: string): void => {
    this.setState({
      dialogModal: true,
      productItemId
    });
  };

  /** Close untag modal for tagged product item */
  closeUntagModal = (): void => {
    this.setState({
      untagModal: false,
      productItemId: ''
    });
  };

  /** Open untag modal for tagged product item
   * @param productItemId - id for selected product item
   */
  openUntagModal = (productItemId: string): void => {
    this.setState({
      untagModal: true,
      productItemId
    });
  };

  /** Open text modal
   * @param productItemId - id for a product item
   * @param bulkAction - whether its a bulk action
   */
  openAddTextModal = (
    productItemId?: string,
    productItemImageKeys?: Array<string>
  ): void => {
    this.setState({
      addTextModal: true,
      productItemId: productItemId || '',
      productItemImageKeys: productItemImageKeys || []
    });
  };

  /** Close text modal */
  closeAddTextModal = (): void => {
    this.setState({
      addTextModal: false,
      productItemId: ''
    });
  };
  /** Toggle main table search filter */
  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Select/deselect product items from table. A product id can only appear once in the array of selected items
   * @param productItemId - the selected product item's id
   */
  selectProductItem = (productItemId: string): void => {
    const { selectedProductItems } = this.state;
    // Check if the selected item exists in the array before adding it
    const newSelectedProductItems = selectedProductItems.find(
      cProductItemId => cProductItemId === productItemId
    )
      ? selectedProductItems.filter(
          sProductItemId => sProductItemId !== productItemId
        )
      : [...selectedProductItems, productItemId];
    this.setState({ selectedProductItems: newSelectedProductItems });
  };

  /** Toggle selection of all product items
   * @param productItems - array of all the product items listed
   */
  toggleSelectAll = (productItems: Array<UpdateProductItemInput>): void => {
    const { selectedProductItems } = this.state;
    // the local state currently consists of all product items, then deselect each of them
    if (selectedProductItems.length === productItems.length) {
      this.setState({ selectedProductItems: [], allSelected: false });
    } else {
      const productItemsIds = productItems.map(
        (productItem: UpdateProductItemInput) => {
          return productItem.id;
        }
      );
      this.setState({
        selectedProductItems: productItemsIds,
        allSelected: true
      });
    }
  };

  /** Return product item services
   * @param productItem - product item on batch card
   */
  returnProductItemServices = (
    productItem: UpdateProductItemInput,
    companyServices: Array<UpdateServiceInput>
  ): React.ReactNode => {
    return companyServices.map((service: UpdateServiceInput, i) => {
      if (service) {
        // Get the current completed services (if any) on a product item
        const completedServices: Array<string | null> =
          productItem.completedServices && productItem.completedServices.length
            ? productItem.completedServices.filter(
                (completedService: string | null) => completedService !== null
              )
            : [];

        return (
          <SelectedIcon
            key={i}
            background={
              checkIfServiceIsCompleted(service.id, completedServices)
                ? Colors.flumeGreen
                : Colors.snow
            }
            small={true}
          >
            <img src={service.icon ? service.icon : ServiceIcons.Editing} />
          </SelectedIcon>
        );
      }
      return null;
    });
  };

  /** Toast notification
   * @param message - message to be displayed
   * @param appearance - type of notification (success/error)
   */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const {
      allSelected,
      dialogModal,
      productItemId,
      previousTagDescriptions,
      searchFilter,
      selectedProductItems,
      tagDescription,
      noteModal,
      updateTagModal,
      untagModal,
      addTextModal,
      productItemImageKeys
    } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <BackendWrapper>
              <Query
                query={getCompanyTaggedProductItemsByUser}
                variables={{ id: userId }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (error) {
                    return (
                      <ErrorMessage errorMessage="There was a problem loading your company's items" />
                    );
                  }

                  if (!data || !data.getUser) {
                    return (
                      <ErrorMessage errorMessage="There was a problem loading your company data" />
                    );
                  }

                  const company = data.getUser.company;
                  const services = data.getUser.company.services.items;
                  const itemTemplate = data.getUser.company.itemTemplate;

                  if (
                    company.productItems &&
                    company.productItems.items &&
                    company.productItems.items.length
                  ) {
                    const taggedProductItems = company.productItems.items;

                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeNoteModal}
                          title="Add note to an item"
                          modalDisplay={
                            <AddNoteToProductItem
                              productItemId={productItemId}
                              notification={this.toastNotification}
                              previousNotes={returnPreviousNotes(
                                taggedProductItems,
                                productItemId
                              )}
                              userId={userId}
                              closeModal={this.closeNoteModal}
                            />
                          }
                          modal={noteModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeAddTextModal}
                          title="Add text to an item"
                          largeModal={true}
                          modalDisplay={
                            <AddTextToProductItem
                              productItemId={productItemId}
                              notification={this.toastNotification}
                              productItemImageKeys={productItemImageKeys}
                              previousContent={returnPreviousTextContent(
                                taggedProductItems,
                                productItemId
                              )}
                              userId={userId}
                              closeModal={this.closeAddTextModal}
                            />
                          }
                          modal={addTextModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeUpdateTagModal}
                          title="Update Item Tag Description"
                          modalDisplay={
                            <UpdateTagDescription
                              closeModal={this.closeUpdateTagModal}
                              notification={this.toastNotification}
                              previousTagDescriptions={previousTagDescriptions}
                              productItemId={productItemId}
                              userId={userId}
                              tagDescription={tagDescription}
                            />
                          }
                          modal={updateTagModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeUntagModal}
                          title="Untag Item"
                          modalDisplay={
                            <UntagProductItem
                              closeModal={this.closeUntagModal}
                              notification={this.toastNotification}
                              productItemId={productItemId}
                              userId={userId}
                            />
                          }
                          modal={untagModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeDialogModal}
                          title="Delete Item"
                          modalDisplay={
                            <DeleteProductItem
                              closeModal={this.closeDialogModal}
                              notification={this.toastNotification}
                              productItemId={productItemId}
                              userId={userId}
                            />
                          }
                          modal={dialogModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeDialogModal}
                          title="Return Item Back To New Items Lists"
                          modalDisplay={
                            <MoveItemToItemsList
                              closeModal={this.closeDialogModal}
                              notification={this.toastNotification}
                              productItemId={productItemId}
                              userId={userId}
                            />
                          }
                          modal={dialogModal}
                        />
                        <TableHeaderContainer>
                          <TableHeader>
                            <span>Tagged Items</span>
                          </TableHeader>
                          <StyledButton
                            type="button"
                            label={
                              <MdSearch
                                size="1.3em"
                                color={Colors.flumeDarkGreen}
                              />
                            }
                            width="auto"
                            onClick={this.toggleSearchFilter}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                        </TableHeaderContainer>
                        <Table
                          data={sortArrayAlphabetically(taggedProductItems)}
                          columns={[
                            {
                              id: 'checkbox-column',
                              Header: () => (
                                <Checkbox
                                  onClick={() =>
                                    this.toggleSelectAll(taggedProductItems)
                                  }
                                >
                                  {allSelected ? (
                                    <MdDone size="0.95em" color={Colors.grey} />
                                  ) : null}
                                </Checkbox>
                              ),
                              accessor: (
                                productItem: UpdateProductItemInput
                              ) => {
                                const thisProductItem = selectedProductItems.find(
                                  tProductItemId =>
                                    tProductItemId === productItem.id
                                );

                                return (
                                  <CircleIcon
                                    selected={thisProductItem || false}
                                    onClick={() =>
                                      this.selectProductItem(productItem.id)
                                    }
                                  />
                                );
                              },
                              sortable: false,
                              filterable: false,
                              width: 60
                            },
                            ...returnNewProductItemColumns(
                              itemTemplate,
                              searchFilter
                            ),
                            {
                              id: 'product-item-services',
                              Header: 'Services',
                              accessor: (
                                productItem: UpdateProductItemInput
                              ) => {
                                return (
                                  <ServiceIconsContainer>
                                    {this.returnProductItemServices(
                                      productItem,
                                      services
                                    )}
                                  </ServiceIconsContainer>
                                );
                              },
                              sortable: false,
                              filterable: false
                            },
                            {
                              id: 'row-actions',
                              Header: '',
                              accessor: (
                                productItem: UpdateProductItemInput
                              ) => {
                                return (
                                  <RowActions
                                    openUpdateModal={this.openUpdateTagModal}
                                    openDeleteModal={this.openDialogModal}
                                    openNoteModal={this.openNoteModal}
                                    openUntagModal={this.openUntagModal}
                                    openAddTextModal={this.openAddTextModal}
                                    openMoveItemToItemsList={
                                      this.openDialogModal
                                    }
                                    previousTagDescriptions={checkIfArrayOfStrings(
                                      productItem.previousTagDescriptions
                                    )}
                                    productItemId={productItem.id}
                                    tagDescription={
                                      productItem.tagDescription || ''
                                    }
                                    productItemImageKeys={productItem.imageKeys}
                                  />
                                );
                              },
                              sortable: false,
                              filterable: false,
                              width: 30
                            }
                          ]}
                          defaultPageSize={5}
                          showPaginationTop={false}
                          showPaginationBottom={true}
                        />
                        <Helmet title="Tagged Items" />
                      </div>
                    );
                  }

                  return <div>There are no tagged items</div>;
                }}
              </Query>
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(TaggedProductItems);
