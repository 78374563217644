import * as React from 'react';
import { Query } from 'react-apollo';

/** GraphQL */
import { getCompanyBatchCardAllocationsForTheYearByUser } from '../../graphql/custom-queries';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { returnNumberOfItemsInProgressForTheYear } from './DashboardHelpers';

const ProductItemsYearProgress: React.FC<{
  year: string;
  userId: string;
  productItemStatus: 'complete' | 'incomplete';
}> = ({ productItemStatus, year, userId }) => {
  return (
    <Query
      query={getCompanyBatchCardAllocationsForTheYearByUser}
      variables={{
        id: userId,
        year
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loader color={Colors.flumeGreen} />;
        }
        if (error || !data || !data.getUser) {
          return <ErrorMessage errorMessage="Error" />;
        }

        const company = data.getUser.company;

        if (
          company.batchCards &&
          company.batchCards.items &&
          company.batchCards.items.length
        ) {
          const batchCards = company.batchCards.items;

          const productItemsForSelectedStatus = returnNumberOfItemsInProgressForTheYear(
            batchCards,
            year,
            productItemStatus
          );

          return <span>{productItemsForSelectedStatus}</span>;
        }

        return <span>0</span>;
      }}
    </Query>
  );
};

export default ProductItemsYearProgress;
