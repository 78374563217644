/* eslint react/jsx-no-target-blank: 0 */
import * as React from 'react';
import * as moment from 'moment';
import { MdSearch } from 'react-icons/md';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

/** Presentation/UI */
import StyledButton from '../../../Components/Styled/Button';
import Table from '../../../Components/Table';
import {
  TableHeader,
  TableHeaderContainer
} from '../../../Components/Styled/ListViewElements';
import FullWidthContainer from '../../../Components/Layouts/FullWidthContainer';
import Loader from '../../../Components/Loader';

/** Custom types */
import { CustomerInvoice } from '../../../CustomTypes';

/** GrahpQL */
import { listUsers } from '../../../graphql/queries';

/** Themes */
import { Colors } from '../../../Themes';

/** Helpers */
import { getPriceFromNumber } from '../../../Utils/Helpers';

const InvoicesTable: React.FC<{ invoices: Array<CustomerInvoice> }> = ({
  invoices
}) => {
  const [searchFilter, toggleSearchFilter] = React.useState(false);

  return (
    <Query
      query={gql(listUsers)}
      variables={{
        limit: 1000
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <FullWidthContainer align="center">
              <Loader size={50} color={Colors.flumeGreen} />
            </FullWidthContainer>
          );
        }
        if (error) {
          return <div>There was a problem loading invoice data</div>;
        }

        if (!data) {
          return <div>There was a problem loading invoice data</div>;
        }

        const users = data.listUsers.items;

        return (
          <React.Fragment>
            <TableHeaderContainer>
              <TableHeader lineHeight={40}>
                <span>Subscription Invoices</span>
              </TableHeader>
              <StyledButton
                type="button"
                label={<MdSearch size="1.3em" color={Colors.flumeDarkGreen} />}
                width="auto"
                onClick={() => toggleSearchFilter(!searchFilter)}
                color={Colors.flumeDarkGreen}
                background={Colors.flumeGreen}
              />
            </TableHeaderContainer>
            <Table
              data={invoices}
              columns={[
                {
                  Header: 'Invoice #',
                  accessor: 'number',
                  sortable: false,
                  filterable: searchFilter
                },
                {
                  id: 'date',
                  Header: 'Date',
                  accessor: (invoice: CustomerInvoice) => {
                    return moment.unix(invoice.created).format('DD/MM/YYYY');
                  },
                  sortable: false,
                  filterable: searchFilter
                },
                {
                  Header: 'Customer Email',
                  accessor: 'customer_email',
                  sortable: false,
                  filterable: searchFilter
                },
                {
                  id: 'company-name',
                  Header: 'Company Name',
                  accessor: (invoice: CustomerInvoice) => {
                    const user = users.filter(
                      user => user.email === invoice.customer_email
                    )[0];

                    if (user && user.company) {
                      return user.company.name;
                    }
                  },
                  sortable: false,
                  filterable: searchFilter
                },
                {
                  id: 'invoice-total',
                  Header: 'Amount',
                  accessor: (invoice: CustomerInvoice) => {
                    return getPriceFromNumber(invoice.total, 'USD');
                  },
                  sortable: false,
                  filterable: searchFilter
                },
                {
                  id: 'invoice-status',
                  Header: 'Status',
                  accessor: (invoice: CustomerInvoice) => {
                    return invoice.status ? 'Invoice paid' : 'Invoice not paid';
                  },
                  sortable: false,
                  filterable: searchFilter
                },
                {
                  id: 'invoice-pdf-link',
                  Header: '',
                  accessor: (invoice: CustomerInvoice) => {
                    return invoice.invoice_pdf ? (
                      <a
                        style={{
                          textDecoration: 'none',
                          color: Colors.grey
                        }}
                        href={invoice.invoice_pdf}
                        target="_blank"
                      >
                        Download
                      </a>
                    ) : (
                      <span>Invoice not finalized yet</span>
                    );
                  },
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={5}
              showPaginationTop={false}
              showPaginationBottom={true}
            />
          </React.Fragment>
        );
      }}
    </Query>
  );
};

export default InvoicesTable;
