/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { MdSearch } from 'react-icons/md';
import Select from 'react-select';
import * as uuid from 'uuid';
import { Storage } from 'aws-amplify';

/** GraphQL */
import { getCompanyBatchCardsByUser } from '../../graphql/custom-queries';
import Composed from './ComposedMutationsForBacklogBatchCard';

/** Context API */
import FileUploadContextProvider from '../NewProductItems/FileUploadContextProvider';
import { FileUploadContextConsumer } from '../NewProductItems/FileUploadContextProvider';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import StyledButton from '../../Components/Styled/Button';
import Table from '../../Components/Table';
import {
  TableHeaderContainer,
  SelectedIcon,
  ServiceIconsContainer
} from '../../Components/Styled/ListViewElements';
import { IconsContainer } from '../../Components/Styled/MainPlanner';

/** Generated types */
import {
  CreateItemTemplateInput,
  UpdateProductItemInput,
  UpdateServiceInput
} from '../../API';

/** Custom Types */
import {
  BacklogCard,
  Error,
  ModalWithMessageType,
  SelectOptionsType,
  SelectType
} from '../../CustomTypes';

/** Themes */
import { Colors, ServiceIcons } from '../../Themes';

/** Utils */
import { arrayOfActiveBatchCardServices } from './BatchCardAllocationHelpers';
import {
  returnNewProductItemColumns,
  sortDropDownDataAlphabetically,
  sortArrayAlphabetically
} from '../../Utils/Helpers';

/** Local components */
import BatchCardImages from './BatchCardImages';
import DeleteBatchCard from './DeleteBatchCard';
import UploadImages from './BatchCardImageUpload';

type Props = ModalWithMessageType & {
  batchCard: BacklogCard | null;
  itemTemplate: CreateItemTemplateInput;
  services: {
    items: Array<UpdateServiceInput>;
  };
  userId: string;
};

type State = {
  id: string;
  name: string;
  description: string | null;
  error: Error;
  loading: boolean;
  searchFilter: boolean;
  selectedProductItemIds: Array<string>;
  selectedProductItems: Array<UpdateProductItemInput>;
  selectedServices: SelectOptionsType;
  services: SelectOptionsType;
};

class BacklogBatchCard extends React.Component<Props, State> {
  timeoutId: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.batchCard ? props.batchCard.id || '' : '',
      name: props.batchCard ? props.batchCard.name || '' : '',
      description: props.batchCard ? props.batchCard.description || null : null,
      loading: false,
      selectedProductItemIds: [],
      selectedProductItems: props.batchCard
        ? props.batchCard.productItems.items.filter(
            item => item.complete !== true
          )
        : [],
      services: this.returnCompanyServices(props.services.items),
      selectedServices: props.batchCard
        ? this.loadSelectedServices(props.batchCard.services.items)
        : [],
      searchFilter: false,
      error: null
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Toggle main table search filter */
  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Return company services as react select options
   * @param services - created company services
   */
  returnCompanyServices = (
    services: Array<UpdateServiceInput>
  ): SelectOptionsType => {
    if (services && services.length) {
      return services.map((service: UpdateServiceInput) => {
        return {
          label: service.name || service.id,
          value: service.id,
          object: service
        };
      });
    }

    return [];
  };

  /** Return selected services as JSX */
  returnSelectedServices = (
    selectedServices: Array<SelectType>
  ): React.ReactNode => {
    if (selectedServices && selectedServices.length) {
      return selectedServices.map((selectedService: SelectType) => {
        return (
          <SelectedIcon
            background={Colors.snow}
            largeImage={true}
            key={selectedService.value}
          >
            <img
              alt=""
              src={
                selectedService.object
                  ? selectedService.object.icon
                  : ServiceIcons.Editing
              }
            />
          </SelectedIcon>
        );
      });
    }
    return null;
  };

  /** Select services
   * @param selectedServices - selected services
   */
  selectServices = (selectedServices: SelectOptionsType): void => {
    this.setState({ selectedServices });
  };

  /** Load pre selected services related to batch card
   * @param preSelectedServices
   */
  loadSelectedServices = (
    preSelectedServices: Array<{
      id: string;
      service: UpdateServiceInput;
    }>
  ): SelectOptionsType => {
    const activeBatchCardServices = arrayOfActiveBatchCardServices(
      preSelectedServices
    );

    return activeBatchCardServices.map(batchCardService => {
      return {
        value: batchCardService.service.id,
        label: batchCardService.service.name || batchCardService.service.id,
        object: batchCardService.service
      };
    });
  };

  /** Validate form
   * @param name - batch card name
   * @param description - batch card description
   */
  validateForm = (name: string): boolean => {
    // Check for undefined or empty input fields
    if (!name) {
      this.setError('Please fill in the name and description fields');
      return false;
    }

    return true;
  };

  /** Execute mutation to update general batch details
   * @param mutationToAddBatchCardService - graphql mutation to add batch card service
   * @param mutationToDeleteBatchCardService - graphql mutation to remove batch card service
   * @param mutationToUpdateBatchCard - graphql mutation to update batch card
   * @param filesToUpload
   */
  executeMutation = async (
    mutationToAddBatchCardService: ({}) => Promise<any>,
    mutationToDeleteBatchCardService: ({}) => Promise<any>,
    mutationToUpdateBatchCard: ({}) => Promise<any>,
    filesToUpload: Array<File>
  ) => {
    const { batchCard, closeModal, notification, userId } = this.props;
    const { id, name, description, selectedServices } = this.state;
    const currentBatchCardServices = batchCard ? batchCard.services.items : [];

    this.setState({ loading: true });

    // An array of ids for newly selected services to be added to the batch card
    const selectedServicesIds = selectedServices.map(selectedService => {
      return selectedService.value;
    });
    // An array of the ids for services currently added to the batch card
    const currentBatchCardServicesIds = currentBatchCardServices.map(
      (batchCardService: { id: string; service: UpdateServiceInput }) => {
        return batchCardService.service.id;
      }
    );

    try {
      const servicesToDelete = currentBatchCardServices.filter(
        (batchCardService: { id: string; service: UpdateServiceInput }) => {
          return !(
            selectedServicesIds.indexOf(batchCardService.service.id) !== -1
          );
        }
      );
      // batch card services to delete
      if (servicesToDelete && servicesToDelete.length) {
        await Promise.all(
          servicesToDelete.map(
            async (batchCardService: {
              id: string;
              service: UpdateServiceInput;
            }) => {
              await mutationToDeleteBatchCardService({
                variables: {
                  input: {
                    id: batchCardService.id
                  }
                }
              });
            }
          )
        );
      }

      const servicesToAdd = selectedServicesIds.filter((serviceId: string) => {
        return !(currentBatchCardServicesIds.indexOf(serviceId) !== -1);
      });
      // batch card services to add
      if (batchCard && servicesToAdd && servicesToAdd.length) {
        await Promise.all(
          servicesToAdd.map(async (serviceId: string) => {
            await mutationToAddBatchCardService({
              variables: {
                input: {
                  batchCardServiceBatchCardId: batchCard.id,
                  batchCardServiceServiceId: serviceId,
                  isComplete: false
                }
              }
            });
          })
        );
      }

      /** Handle image file uploads to S3, and subsequently save the
       * updated image keys for the batch card based on added or removed
       * images.
       */
      let newImageKeys: Array<string> = [];
      let existingImageKeys: Array<string> = [];

      // Upload image files to S3 bucket
      const uploadedFiles = await Promise.all(
        filesToUpload.map(async file => {
          return await Storage.put(`${uuid()}_${file.name}`, file, {
            contentType: file.type
          });
        })
      );
      // If image files were uploaded successfully, get the keys for each file
      if (uploadedFiles && uploadedFiles.length) {
        // @ts-ignore
        newImageKeys = uploadedFiles.map((image: { key: string }) => {
          return image.key;
        });
      }

      // Get all current/existing image keys
      if (batchCard && batchCard.images && batchCard.images.length) {
        existingImageKeys = batchCard.images.map(
          // @ts-ignore
          (batchCardImageKey: string) => {
            return batchCardImageKey;
          }
        );
      }

      const images: Array<string> = [...newImageKeys, ...existingImageKeys];

      mutationToUpdateBatchCard({
        variables: {
          input: {
            id,
            name,
            description,
            images
          }
        },
        refetchQueries: [
          {
            query: getCompanyBatchCardsByUser,
            variables: {
              id: userId
            }
          }
        ]
      })
        .then(() => {
          closeModal();
          notification('Batch card successfully updated');
        })
        .catch(err => {
          this.setError(err.message);
        });
    } catch (err) {
      this.setState({ loading: false });
      this.setError(err.message);
    }
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const {
      batchCard,
      closeModal,
      itemTemplate,
      notification,
      userId
    } = this.props;
    const {
      name,
      error,
      description,
      loading,
      searchFilter,
      selectedProductItems,
      selectedServices,
      services
    } = this.state;

    return (
      <FileUploadContextProvider>
        <FileUploadContextConsumer>
          {({ uploadedFiles }) => {
            return (
              <Composed>
                {(composedMutations: {
                  addBatchCardService: {
                    mutation: ({  }: {}) => Promise<any>;
                  };
                  removeBatchCardService: {
                    mutation: ({  }: {}) => Promise<any>;
                  };
                  updateBacklogBatchCard: {
                    mutation: ({  }: {}) => Promise<any>;
                  };
                }) => (
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      if (this.validateForm(name)) {
                        this.executeMutation(
                          composedMutations.addBatchCardService.mutation,
                          composedMutations.removeBatchCardService.mutation,
                          composedMutations.updateBacklogBatchCard.mutation,
                          uploadedFiles
                        );
                      }
                    }}
                  >
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <FormGroup>
                          <Label for="name">Batch Card Name</Label>
                          <Input
                            type="text"
                            name="name"
                            value={name}
                            id="name"
                            placeholder="Name"
                            onChange={e =>
                              this.setState({
                                name: e.target.value
                              })
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="services">Services</Label>
                          <br />
                          {selectedServices.length > 0 && (
                            <IconsContainer>
                              {this.returnSelectedServices(selectedServices)}
                            </IconsContainer>
                          )}
                          <Select
                            // @ts-ignore
                            onChange={this.selectServices}
                            options={sortDropDownDataAlphabetically(services)}
                            value={selectedServices}
                            isMulti={true}
                            isSearchable={true}
                            placeholder="Services"
                            className="select-styling"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <FormGroup>
                          <Label for="description">Description</Label>
                          <Input
                            type="textarea"
                            name="description"
                            value={description || ''}
                            id="description"
                            placeholder="Add a detailed description"
                            onChange={e =>
                              this.setState({
                                description: e.target.value || null
                              })
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="images">Images</Label>
                          <br />
                          <UploadImages bulkAction={false} />
                          {batchCard && (
                            <BatchCardImages
                              batchCard={batchCard}
                              notification={notification}
                            />
                          )}

                          <br />
                          {uploadedFiles.length ? (
                            <div>
                              Selected files to upload:
                              {uploadedFiles.map((f: File, i) => (
                                <React.Fragment key={i}>
                                  <div>
                                    {i + 1}. {f.name} - {`${f.size / 1000} kb`}
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <TableHeaderContainer>
                          <StyledButton
                            type="button"
                            label={
                              <div>
                                Search{' '}
                                <MdSearch
                                  size="1.3em"
                                  color={Colors.flumeDarkGreen}
                                />
                              </div>
                            }
                            width="auto"
                            onClick={this.toggleSearchFilter}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                        </TableHeaderContainer>
                        <Table
                          data={sortArrayAlphabetically(selectedProductItems)}
                          columns={[
                            ...returnNewProductItemColumns(
                              itemTemplate,
                              searchFilter
                            ),
                            {
                              id: 'batch-card-services',
                              Header: 'SERVICES',
                              accessor: () => {
                                return selectedServices.length > 0 ? (
                                  <ServiceIconsContainer>
                                    {this.returnSelectedServices(
                                      selectedServices
                                    )}
                                  </ServiceIconsContainer>
                                ) : null;
                              },
                              sortable: false,
                              filterable: false
                            }
                          ]}
                          defaultPageSize={5}
                          showPaginationTop={false}
                          showPaginationBottom={true}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={12} md={3} lg={3}>
                        <FormGroup>
                          <Button type="submit">
                            {loading ? <Loader /> : 'Update Batch Card'}
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3} lg={3}>
                        <DeleteBatchCard
                          batchCardId={batchCard ? batchCard.id : ''}
                          closeModal={closeModal}
                          notification={notification}
                          userId={userId}
                        />
                      </Col>
                    </Row>
                    {error && <ErrorMessage errorMessage={error} />}
                  </Form>
                )}
              </Composed>
            );
          }}
        </FileUploadContextConsumer>
      </FileUploadContextProvider>
    );
  }
}

export default BacklogBatchCard;
