/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { MdDone, MdSearch } from 'react-icons/md';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import { MutationResult } from 'react-apollo';

/** Custom types */
import {
  BatchCard,
  BatchCardServiceType,
  Error,
  SelectType,
  ValueType
} from '../../CustomTypes';
/** Generated types */
import {
  CreateItemTemplateInput,
  UpdateBatchCardMutation,
  UpdateProductItemInput,
  UpdateSpaceInput
} from '../../API';

/** Local components */
import BatchCardImages from './BatchCardImages';
import RowActions from './ProductItemRowActions';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import Table from '../../Components/Table';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';
import {
  CircleIcon,
  Checkbox,
  TableHeaderContainer,
  ServiceIconsContainer
} from '../../Components/Styled/ListViewElements';
import {
  IconsContainer,
  ProductActionsContainer
} from '../../Components/Styled/MainPlanner';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import {
  returnSelectedServices,
  returnColorsOfSelectedSpaces
} from './QCBatchCardHelpers';
import {
  checkIfArrayOfStrings,
  returnNewProductItemColumns,
  returnProductItemKeysBasedOnCompanyTemplate
} from '../../Utils/Helpers';
import {
  returnProductItemsBasedOnProductItemKeys,
  returnProductItemsBasedOnIds
} from './BatchCardAllocationHelpers';
import { QC_OPTION, QC_DISAPPROVAL_OPTION } from '../../Utils/Consts';

type Props = {
  allSelected: boolean;
  batchCard: BatchCard;
  currentWeekFormattedDates: Array<string>;
  description: string;
  error: Error;
  itemTemplate: CreateItemTemplateInput;
  name: string;
  searchFilter: boolean;
  selectedProductItems: Array<UpdateProductItemInput>;
  selectedProductItemIds: Array<string>;
  selectedSpaces: Array<UpdateSpaceInput>;
  selectedServices: Array<BatchCardServiceType>;
  updateBatchCardMutation: ({}) => Promise<any>;
  updateBatchCardResponse: MutationResult<UpdateBatchCardMutation>;
  executeMutation: (
    currentWeekFormattedDates: Array<string>,
    mutationToUpdateBatchCard: ({}) => Promise<any>
  ) => void;
  notification: (message: string, appearance?: string | undefined) => void;
  openTagModal?: (productItemId: string, bulkAction?: boolean) => void;
  openQCApproveModal?: (
    productItemId: string,
    bultAction?: boolean,
    qcApproval?: boolean
  ) => void;
  openAddTextModal: (productItemId?: string, bulkAction?: boolean) => void;
  selectBulkAction: (
    bulkAction: ValueType<SelectType>,
    selectedProductItemIds: Array<string>
  ) => void;
  selectProductItem: (productItemId: string) => void;
  setName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setDescription: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleSearchFilter: () => void;
  toggleSelectAll: (productItems: Array<UpdateProductItemInput>) => void;
  validateForm: () => boolean;
};

const QCBatchCardForm: React.FC<Props> = ({
  allSelected,
  batchCard,
  currentWeekFormattedDates,
  description,
  error,
  executeMutation,
  name,
  itemTemplate,
  searchFilter,
  selectBulkAction,
  selectProductItem,
  selectedProductItems,
  selectedProductItemIds,
  selectedServices,
  selectedSpaces,
  setName,
  setDescription,
  notification,
  openTagModal,
  openQCApproveModal,
  openAddTextModal,
  toggleSearchFilter,
  toggleSelectAll,
  updateBatchCardMutation,
  updateBatchCardResponse,
  validateForm
}) => {
  // Product item keys
  const productItemKeys = returnProductItemKeysBasedOnCompanyTemplate(
    itemTemplate
  );

  // Product items to be exported
  const productItemsToExport = returnProductItemsBasedOnIds(
    selectedProductItemIds,
    selectedProductItems
  );

  // CSV data of product items to be exported
  const csvData = [
    productItemKeys,
    ...returnProductItemsBasedOnProductItemKeys(
      productItemsToExport,
      productItemKeys
    )
  ];

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        if (validateForm()) {
          executeMutation(currentWeekFormattedDates, updateBatchCardMutation);
        }
      }}
    >
      <Row>
        <Col xs={12} md={6} lg={6}>
          <FormGroup>
            <Label for="name">Batch Card Name</Label>
            <Input
              type="text"
              name="name"
              value={name}
              id="name"
              placeholder="Name"
              onChange={setName}
            />
          </FormGroup>
          <FormGroup>
            <Label for="spaces">Spaces</Label>
            <br />
            {selectedSpaces.length > 0 && (
              <IconsContainer>
                {returnColorsOfSelectedSpaces(selectedSpaces)}
              </IconsContainer>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="services">Services</Label>
            <br />
            {selectedServices.length > 0 && (
              <IconsContainer>
                {returnSelectedServices(selectedServices)}
              </IconsContainer>
            )}
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              name="description"
              value={description}
              id="description"
              placeholder="Add a detailed description"
              onChange={setDescription}
            />
          </FormGroup>
          <FormGroup>
            <Label for="images">Images</Label>
            <br />
            <BatchCardImages
              batchCard={batchCard}
              notification={notification}
            />
          </FormGroup>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12} md={12} lg={12}>
          <TableHeaderContainer>
            <ProductActionsContainer>
              <StyledButton
                type="button"
                label={
                  <div>
                    Search{' '}
                    <MdSearch size="1.3em" color={Colors.flumeDarkGreen} />
                  </div>
                }
                width="auto"
                margin="0px 10px"
                onClick={toggleSearchFilter}
                color={Colors.flumeDarkGreen}
                background={Colors.flumeGreen}
              />
              <CSVLink
                data={csvData}
                target="_blank"
                filename="complete-product-items.csv"
              >
                <StyledButton
                  type="button"
                  label="Export to CSV"
                  width="120px"
                  color={Colors.flumeGreen}
                  background={Colors.flumeDarkGreen}
                />
              </CSVLink>
              <Select
                onChange={e => selectBulkAction(e, selectedProductItemIds)}
                options={[QC_OPTION, QC_DISAPPROVAL_OPTION]}
                isSearchable={true}
                placeholder="Bulk Actions"
                className="select-styling"
              />
            </ProductActionsContainer>
          </TableHeaderContainer>
          <Table
            data={selectedProductItems}
            columns={[
              {
                id: 'all-selected',
                Header: () => (
                  <Checkbox
                    onClick={() => toggleSelectAll(selectedProductItems)}
                  >
                    {allSelected ? (
                      <MdDone size="0.95em" color={Colors.grey} />
                    ) : null}
                  </Checkbox>
                ),
                accessor: (productItem: UpdateProductItemInput) => {
                  const thisProductItem = selectedProductItemIds.find(
                    cProductItemId => cProductItemId === productItem.id
                  );

                  return (
                    <CircleIcon
                      selected={thisProductItem || false}
                      onClick={() => selectProductItem(productItem.id)}
                    />
                  );
                },
                sortable: false,
                filterable: false,
                width: 60
              },
              ...returnNewProductItemColumns(itemTemplate, searchFilter),
              {
                id: 'service-icons',
                Header: 'SERVICES',
                accessor: (productItem: UpdateProductItemInput) => {
                  return selectedServices.length > 0 ? (
                    <ServiceIconsContainer>
                      {returnSelectedServices(selectedServices, productItem)}
                    </ServiceIconsContainer>
                  ) : null;
                },
                sortable: false,
                filterable: false
              },
              {
                id: 'row-actions',
                Header: '',
                accessor: (productItem: UpdateProductItemInput) => {
                  return (
                    <RowActions
                      openModal={openTagModal}
                      openQCApproveModal={openQCApproveModal}
                      openAddTextModal={openAddTextModal}
                      previousTagDescriptions={checkIfArrayOfStrings(
                        productItem.previousTagDescriptions
                      )}
                      productItemId={productItem.id}
                      productItemImageKeys={checkIfArrayOfStrings(
                        productItem.imageKeys
                      )}
                      qcForm={true}
                    />
                  );
                },
                sortable: false,
                filterable: false,
                width: 30
              }
            ]}
            defaultPageSize={5}
            showPaginationTop={false}
            showPaginationBottom={true}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12} md={3} lg={3}>
          <FormGroup>
            <Button type="submit">
              {updateBatchCardResponse.loading ? (
                <Loader />
              ) : (
                'Update Batch Card'
              )}
            </Button>
          </FormGroup>
        </Col>
      </Row>
      {error && <ErrorMessage errorMessage={error} />}
    </Form>
  );
};

export default QCBatchCardForm;
