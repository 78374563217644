import * as React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { ServiceType } from '../../CustomTypes';

type State = {
  dropdownOpen: boolean;
};

type Props = {
  service: ServiceType;
  openDialogModal(service: ServiceType): void;
  openModal(service: ServiceType): void;
};

class RowActions extends React.Component<Props, State> {
  state: State = {
    dropdownOpen: false
  };

  toggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  render() {
    const { dropdownOpen } = this.state;
    const { openModal, openDialogModal, service } = this.props;
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={this.toggle}
        style={{ position: 'absolute' }}
      >
        <DropdownToggle
          tag="span"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
        </DropdownToggle>
        <DropdownMenu>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openModal(service)}
          >
            Edit Service
          </div>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openDialogModal(service)}
          >
            Delete Service
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default RowActions;
