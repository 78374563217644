import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

// GraphQL
import { getUser } from '../../graphql/queries';

import { GetUserQuery, GetUserQueryVariables } from '../../API';

// Presentation/UI
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import PageWrapper from '../../Components/Layouts/PageWrapper';
import SignUpWrapper from '../../Components/Layouts/SignUpWrapper';
import Logo from '../../Components/Layouts/LogoWrapper';
import Loader from '../../Components/Loader';

// Themes
import { Images, Colors } from '../../Themes';

// Amplify
import { Auth } from 'aws-amplify';

// Local components
import CompanySetupForm from './CompanySetupForm';
import ChooseProductSubscriptionPlan from './ChooseProductSubscriptionPlan';
import Success from './Success';

type StateType = {
  userId: string;
  view: 'companySetup' | 'chooseProduct' | 'success';
  loading: boolean;
};

class CompanySetup extends React.Component<{}> {
  state: StateType = {
    userId: '',
    view: 'companySetup',
    loading: false
  };

  componentDidMount() {
    this.setState({ loading: true });
    Auth.currentAuthenticatedUser({
      bypassCache: false
    })
      .then(user => {
        const userId = user.attributes.sub;
        this.setState({ loading: false, userId });
      })
      .catch(err => console.log(err));
  }

  // render view based on state
  renderView = (view: string): React.ReactNode => {
    const { userId } = this.state;

    switch (view) {
      case 'companySetup':
        return (
          <CompanySetupForm userId={userId} updateView={this.updateView} />
        );

      case 'chooseProduct':
        return (
          <ChooseProductSubscriptionPlan
            userId={userId}
            updateView={this.updateView}
          />
        );

      case 'success':
        return <Success />;

      default:
        return (
          <CompanySetupForm userId={userId} updateView={this.updateView} />
        );
    }
  };

  // update the view
  updateView = (view: string): void => {
    this.setState({ view });
  };

  render() {
    const { loading, userId } = this.state;
    let { view } = this.state;

    if (loading) {
      return (
        <PageWrapper>
          <SignUpWrapper width="697px" height="auto">
            <FullWidthContainer align="center">
              <Loader size={150} color={Colors.flumeGreen} />
            </FullWidthContainer>
          </SignUpWrapper>
          <Helmet title="Company Setup" />
        </PageWrapper>
      );
    }

    return (
      <Query<GetUserQuery, GetUserQueryVariables>
        query={gql(getUser)}
        variables={{ id: userId }}
      >
        {getUserQuery => {
          if (getUserQuery.loading) {
            return (
              <FullWidthContainer align="center">
                <Loader size={150} />
              </FullWidthContainer>
            );
          }
          if (getUserQuery.error) {
            return <div>There was a problem loading your user data</div>;
          }

          if (!getUserQuery.data) {
            return <div>There was a problem loading your user data</div>;
          }

          const user = getUserQuery.data.getUser;
          /**
           * If a company has already been created, check if it has
           * a product subscription plan
           */

          if (user && user.company) {
            const company = user.company;

            if (company.stripeSubscriptionPlanId) {
              view = 'success';
            } else {
              view = 'chooseProduct';
            }
          }

          return (
            <PageWrapper>
              <SignUpWrapper width="850px" height="auto">
                <React.Fragment>
                  <FullWidthContainer align="center">
                    <Logo image={Images.flumeLogo} />
                  </FullWidthContainer>
                  {this.renderView(view)}
                </React.Fragment>
              </SignUpWrapper>
              <Helmet title="Company Setup" />
            </PageWrapper>
          );
        }}
      </Query>
    );
  }
}

export default CompanySetup;
