/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

/** GraphQL */
import { updateBatchCard } from '../../graphql/mutations';
import {
  getCompanyBatchCardByUser,
  getCompanyBatchCardsByUser,
  getCompanyBatchCardAllocationsForTheWeekByUser
} from '../../graphql/custom-queries';

/** Generated types */
import {
  UpdateBatchCardMutation,
  UpdateBatchCardMutationVariables,
  UpdateProductItemInput,
  CreateItemTemplateInput,
  UpdateSpaceInput
} from '../../API';

/** Presentation/UI */
import GlobalModalContainer from '../../Components/Modal';

/** Context API */
import DefaultWeekContextProvider from '../QualityControlCalendar/DefaultWeekContextProvider';
import { DefaultWeekContextConsumer } from '../QualityControlCalendar/DefaultWeekContextProvider';

/** Local components */
import AddTagToProductItem from './AddTagToProductItem';
import QCapproveItems from './QCapproveItems';
import AddTextToProductItem from './AddTextToProductItem';

/** Custom Types */
import {
  BatchCard,
  BatchCardServiceType,
  Error,
  ModalWithMessageType,
  SelectType,
  ValueType
} from '../../CustomTypes';

/** Utils */
import {
  loadSelectedSpaces,
  returnArrayOfCompletedProductItems,
  returnPreviousTextContent
} from './BatchCardAllocationHelpers';
import { BULK_ACTIONS } from '../../Utils/Consts';
import QCBatchCardForm from './QCBatchCardForm';

type Props = ModalWithMessageType & {
  batchCard: BatchCard;
  itemTemplate: CreateItemTemplateInput;
  userId: string;
};

type State = {
  id: string;
  name: string;
  description: string;
  batchCardServices: Array<BatchCardServiceType>;
  searchFilter: boolean;
  allSelected: boolean;
  bulkAction: boolean;
  imageModal: boolean;
  loading: boolean;
  noteModal: boolean;
  addTextModal: boolean;
  qcModal: boolean;
  qcApproval: boolean;
  productItemId: string;
  previousTagDescriptions: Array<string>;
  productItemImageKeys: Array<string>;
  selectedProductItemIds: Array<string>;
  selectedProductItems: Array<UpdateProductItemInput>;
  selectedServices: Array<BatchCardServiceType>;
  selectedSpaces: Array<UpdateSpaceInput>;
  tagModal: boolean;
  error: Error;
  tagItemsModalTitle: string;
};

class QCBatchCard extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps && nextProps.batchCard) {
      const { productItems } = nextProps.batchCard;
      return {
        selectedProductItems: productItems.items
      };
    }
    return null;
  }

  timeoutId: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.batchCard.id || '',
      name: props.batchCard.name || '',
      description: props.batchCard.description || '',
      batchCardServices: props.batchCard.services.items,
      searchFilter: false,
      allSelected: false,
      bulkAction: false,
      noteModal: false,
      addTextModal: false,
      qcModal: false,
      qcApproval: false,
      productItemId: '',
      previousTagDescriptions: [],
      productItemImageKeys: [],
      selectedProductItemIds: [],
      selectedProductItems: props.batchCard.productItems.items,
      selectedServices: props.batchCard.services.items,
      selectedSpaces: loadSelectedSpaces(
        props.batchCard.batchCardAllocations.items
      ),
      tagModal: false,
      imageModal: false,
      error: null,
      loading: false,
      tagItemsModalTitle: ''
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Close tag modal */
  closeTagModal = (): void => {
    this.setState({
      tagModal: false,
      productItemId: '',
      tagItemsModalTitle: ''
    });
  };

  /** Open tag modal
   * @param productItemId - id for a product item
   * @param bulkAction - a flag for whether or not the action being carried out is a bulk action or not
   * @param previousTagDescriptions - previously added tags
   */
  openTagModal = (
    productItemId: string,
    bulkAction: boolean = false,
    qcReject: boolean = false,
    previousTagDescriptions: Array<string> = []
  ): void => {
    this.setState({
      tagModal: true,
      productItemId,
      bulkAction,
      previousTagDescriptions,
      tagItemsModalTitle:
        qcReject && bulkAction ? 'QC Reject Items' : 'Add Tag To items'
    });
  };

  /** Close QC modal */
  closeQCmodal = (): void => {
    this.setState({
      qcModal: false,
      productItemId: ''
    });
  };

  /** Open QC modal
   * @param productItemId - id for a product item
   * @param bulkAction - a flag for whether or not the action being carried out is a bulk action or not
   * @param qcApproval - a flag which determines whether or not the product item(s) are being approved or disapproved
   */
  openQCmodal = (
    productItemId: string,
    bulkAction?: boolean,
    qcApproval?: boolean
  ): void => {
    this.setState({
      qcApproval: qcApproval || false,
      qcModal: true,
      productItemId,
      bulkAction: bulkAction || false
    });
  };

  /** Open text modal
   * @param productItemId - id for a product item
   * @param bulkAction - whether its a bulk action
   */
  openAddTextModal = (
    productItemId?: string,
    bulkAction?: boolean,
    productItemImageKeys?: Array<string>
  ): void => {
    this.setState({
      addTextModal: true,
      productItemId: productItemId || '',
      bulkAction: bulkAction || false,
      productItemImageKeys: productItemImageKeys || []
    });
  };

  /** Close text modal */
  closeAddTextModal = (): void => {
    this.setState({
      addTextModal: false,
      productItemId: ''
    });
  };

  /** Select bulk action
   * @param bulkAction - bulk action to be carried out on product items
   * @param selectedProductItemIds - array of selected product item ids
   */
  selectBulkAction = (
    bulkAction: ValueType<SelectType>,
    selectedProductItemIds: Array<string>
  ): void => {
    if (selectedProductItemIds && selectedProductItemIds.length) {
      switch (bulkAction.value) {
        case BULK_ACTIONS.content:
          this.openAddTextModal('', true, []);
          break;
        case BULK_ACTIONS.qc:
          this.openQCmodal('', true, true);
          break;
        case BULK_ACTIONS.qcDisapprove:
          this.openTagModal('', true, true);
          break;
        default:
          break;
      }
    }
  };

  /** Select/deselect product items from table. A product id can only appear once in the array of selected items
   * @param productItemId - the selected product item's id
   */
  selectProductItem = (productItemId: string): void => {
    const { selectedProductItemIds } = this.state;
    // Check if the selected item exists in the array before adding it
    const newSelectedProductItemIds = selectedProductItemIds.find(
      cProductItemId => cProductItemId === productItemId
    )
      ? selectedProductItemIds.filter(
          sProductItemId => sProductItemId !== productItemId
        )
      : [...selectedProductItemIds, productItemId];
    this.setState({ selectedProductItemIds: newSelectedProductItemIds });
  };

  /** Toggle main table search filter */
  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Toggle selection of all product items
   * @param productItems - array of all the product items listed
   */
  toggleSelectAll = (productItems: Array<UpdateProductItemInput>): void => {
    const { selectedProductItemIds } = this.state;
    // the local state currently consists of all product items, then deselect each of them
    if (selectedProductItemIds.length === productItems.length) {
      this.setState({ selectedProductItemIds: [], allSelected: false });
    } else {
      const productItemsIds = productItems.map(
        (productItem: UpdateProductItemInput) => {
          return productItem.id;
        }
      );
      this.setState({
        selectedProductItemIds: productItemsIds,
        allSelected: true
      });
    }
  };

  /** Validate form */
  validateForm = (): boolean => {
    const { name } = this.state;
    // Check for undefined or empty input fields
    if (!name) {
      this.setError('Please fill in the name field');
      return false;
    }

    return true;
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Execute mutation to update general batch details
   * @param mutationToUpdateBatchCard - graphql mutation to update batch card
   * @param defaultWeek - array of dates for the current week
   */
  executeMutation = (
    defaultWeek: Array<string>,
    mutationToUpdateBatchCard: ({}) => Promise<any>
  ) => {
    const { id, name, description } = this.state;
    const { closeModal, notification, userId } = this.props;

    mutationToUpdateBatchCard({
      variables: {
        input: {
          id,
          name,
          description
        }
      },
      refetchQueries: [
        {
          query: getCompanyBatchCardsByUser,
          variables: {
            id: userId
          }
        },
        {
          query: getCompanyBatchCardByUser,
          variables: {
            userId,
            batchCardId: id
          }
        },
        {
          query: getCompanyBatchCardAllocationsForTheWeekByUser,
          variables: {
            id: userId,
            dayOne: defaultWeek[0],
            dayTwo: defaultWeek[1],
            dayThree: defaultWeek[2],
            dayFour: defaultWeek[3],
            dayFive: defaultWeek[4],
            daySix: defaultWeek[5],
            daySeven: defaultWeek[6]
          }
        }
      ]
    })
      .then(() => {
        closeModal();
        notification('Batch card successfully updated');
      })
      .catch(err => {
        this.setError(err.message);
      });
  };

  render() {
    const {
      id,
      name,
      description,
      allSelected,
      bulkAction,
      searchFilter,
      productItemId,
      previousTagDescriptions,
      productItemImageKeys,
      addTextModal,
      qcModal,
      qcApproval,
      selectedProductItemIds,
      selectedProductItems,
      selectedServices,
      selectedSpaces,
      tagModal,
      error,
      tagItemsModalTitle
    } = this.state;
    const {
      itemTemplate,
      notification,
      userId,
      closeModal,
      batchCard
    } = this.props;

    return (
      <DefaultWeekContextProvider>
        <DefaultWeekContextConsumer>
          {({ currentWeekFormattedDates }) => {
            return (
              <Mutation<
                UpdateBatchCardMutation,
                UpdateBatchCardMutationVariables
              >
                mutation={gql(updateBatchCard)}
              >
                {(updateBatchCardMutation, updateBatchCardResponse) => (
                  <div>
                    <GlobalModalContainer
                      toggleModal={this.closeAddTextModal}
                      largeModal={true}
                      title="View text and images in an item"
                      modalDisplay={
                        <AddTextToProductItem
                          batchCardId={id}
                          bulkAction={bulkAction}
                          productItemId={productItemId}
                          productItemIds={selectedProductItemIds}
                          productItemImageKeys={productItemImageKeys}
                          notification={notification}
                          previousContent={returnPreviousTextContent(
                            batchCard.productItems.items,
                            productItemId
                          )}
                          userId={userId}
                          closeModal={this.closeAddTextModal}
                        />
                      }
                      modal={addTextModal}
                    />
                    <GlobalModalContainer
                      toggleModal={this.closeQCmodal}
                      title="QC approve product item"
                      modalDisplay={
                        <QCapproveItems
                          batchCardId={id}
                          bulkAction={bulkAction}
                          productItemId={productItemId}
                          productItemIds={selectedProductItemIds}
                          qcApproval={qcApproval}
                          selectedProductItems={selectedProductItemIds}
                          notification={notification}
                          userId={userId}
                          closeModal={this.closeQCmodal}
                        />
                      }
                      modal={qcModal}
                    />
                    <GlobalModalContainer
                      toggleModal={this.closeTagModal}
                      title={tagItemsModalTitle}
                      modalDisplay={
                        <AddTagToProductItem
                          batchCardId={id}
                          bulkAction={bulkAction}
                          previousTagDescriptions={previousTagDescriptions}
                          productItemId={productItemId}
                          productItemIds={selectedProductItemIds}
                          notification={notification}
                          userId={userId}
                          closeModal={closeModal}
                        />
                      }
                      modal={tagModal}
                    />
                    <QCBatchCardForm
                      allSelected={allSelected}
                      batchCard={batchCard}
                      currentWeekFormattedDates={currentWeekFormattedDates}
                      description={description}
                      error={error}
                      itemTemplate={itemTemplate}
                      name={name}
                      searchFilter={searchFilter}
                      selectedProductItems={returnArrayOfCompletedProductItems(
                        selectedProductItems
                      )}
                      selectedProductItemIds={selectedProductItemIds}
                      selectedSpaces={selectedSpaces}
                      selectedServices={selectedServices}
                      updateBatchCardMutation={updateBatchCardMutation}
                      updateBatchCardResponse={updateBatchCardResponse}
                      executeMutation={this.executeMutation}
                      notification={notification}
                      openTagModal={this.openTagModal}
                      openQCApproveModal={this.openQCmodal}
                      openAddTextModal={this.openAddTextModal}
                      selectBulkAction={this.selectBulkAction}
                      selectProductItem={this.selectProductItem}
                      setName={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.setState({
                          name: e.target.value
                        })
                      }
                      setDescription={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) =>
                        this.setState({
                          description: e.target.value
                        })
                      }
                      toggleSearchFilter={this.toggleSearchFilter}
                      toggleSelectAll={this.toggleSelectAll}
                      validateForm={this.validateForm}
                    />
                  </div>
                )}
              </Mutation>
            );
          }}
        </DefaultWeekContextConsumer>
      </DefaultWeekContextProvider>
    );
  }
}

export default QCBatchCard;
