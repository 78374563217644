import * as React from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Helmet } from 'react-helmet';
import { MdCameraAlt, MdSearch, MdFileDownload } from 'react-icons/md';
import { withToastManager } from 'react-toast-notifications';
import * as uuid from 'uuid';
import { Form } from 'reactstrap';
import { CSVLink } from 'react-csv';

/** GraphQL */
import { getCompanySpacesByUser } from '../../graphql/custom-queries';

import {
  SpaceStatus,
  DeleteSpaceMutationVariables,
  DeleteSpaceMutation,
  UpdateSpaceInput
} from '../../API';

/** Presentation/UI */
import PlaceholderWrapper from '../../Components/Layouts/PlaceholderWrapper';
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import GlobalModalContainer from '../../Components/Modal';
import Table from '../../Components/Table';
import PageLoader from '../../Components/PageLoader';
import StyledButton from '../../Components/Styled/Button';
import { SelectedColor } from '../../Components/Styled/ListViewElements';
import DataImport from '../../Components/DataImport/SpaceDataImport';

import {
  TableHeader,
  TableHeaderContainer
} from '../../Components/Styled/ListViewElements';

/** Local components */
import AddSpaceModal from './AddSpaceModal';
import EditSpaceModal from './EditSpaceModal';
import RowActions from './RowActions';
import DialogModal from '../../Components/DialogModal';

/** Themes */
import { Colors } from '../../Themes';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Utils */
import { SPACES_DESCRIPTION, SPACES_CSV_HEADINGS } from '../../Utils/Consts';
import {
  returnSpaceStatus,
  sortArrayAlphabetically
} from '../../Utils/Helpers';

/** Custom Types */
import { ToastNotificationType, SpaceType } from '../../CustomTypes';

/** Mutations */
import { deleteSpace } from '../../graphql/mutations';

type Props = {
  toastManager: ToastNotificationType;
};

type State = {
  spaceId: string;
  modal: boolean;
  importModal: boolean;
  editModal: boolean;
  searchFilter: boolean;
  dialogModal: boolean;
  space: SpaceType;
};

class Spaces extends React.Component<Props, State> {
  state: State = {
    spaceId: '',
    modal: false,
    importModal: false,
    editModal: false,
    searchFilter: false,
    dialogModal: false,
    space: {
      id: '',
      name: '',
      colorCode: Colors.flumeGreen,
      status: SpaceStatus.INACTIVE
    }
  };

  /** Open dialog modal for brand */
  openDialogModal = (space: UpdateSpaceInput): void => {
    const spaceId = space ? space.id : '';

    this.setState({
      dialogModal: true,
      spaceId
    });
  };

  /** Close dialog modal for brand */
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false
    });
  };

  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Close import file modal */
  closeImportModal = (): void => {
    this.setState({
      importModal: false
    });
  };

  /** Open import file modal */
  openImportModal = (): void => {
    this.setState({
      importModal: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  /** Close edit modal */
  closeEditModal = (): void => {
    this.setState({
      editModal: false,
      space: {
        id: '',
        name: '',
        colorCode: Colors.flumeGreen,
        status: SpaceStatus.INACTIVE
      }
    });
  };

  /** Open modal */
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  /** Open edit modal */
  openEditModal = (space: SpaceType): void => {
    this.setState({
      editModal: true,
      space
    });
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const {
      modal,
      editModal,
      importModal,
      space,
      searchFilter,
      spaceId,
      dialogModal
    } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <BackendWrapper>
              <Query query={getCompanySpacesByUser} variables={{ id: userId }}>
                {({ loading, error, data }) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (error) {
                    return (
                      <div>There was a problem loading your company data</div>
                    );
                  }

                  if (!data || !data.getUser) {
                    return (
                      <div>There was a problem loading your company data</div>
                    );
                  }

                  const company = data.getUser.company;
                  const companyId = data.getUser.company.id;

                  if (
                    company.spaces &&
                    company.spaces.items &&
                    company.spaces.items.length
                  ) {
                    const createdCompanySpaces = sortArrayAlphabetically(
                      company.spaces.items
                    );

                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeModal}
                          title="Create Spaces"
                          modalDisplay={
                            <AddSpaceModal
                              companyId={companyId}
                              closeModal={this.closeModal}
                              notification={this.toastNotification}
                            />
                          }
                          modal={modal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeImportModal}
                          title="Import Spaces"
                          modalDisplay={
                            <DataImport
                              companyId={companyId}
                              closeModal={this.closeImportModal}
                              notification={this.toastNotification}
                            />
                          }
                          modal={importModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeEditModal}
                          title="Edit Space"
                          modalDisplay={
                            <EditSpaceModal
                              closeModal={this.closeEditModal}
                              notification={this.toastNotification}
                              companyId={companyId}
                              space={space}
                            />
                          }
                          modal={editModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeDialogModal}
                          title=""
                          modalDisplay={
                            <Mutation<
                              DeleteSpaceMutation,
                              DeleteSpaceMutationVariables
                            >
                              mutation={gql(deleteSpace)}
                            >
                              {(deleteBrandMutation, deleteMutation) => (
                                <Form
                                  onSubmit={e => {
                                    e.preventDefault();
                                    deleteBrandMutation({
                                      variables: {
                                        input: {
                                          id: spaceId
                                        }
                                      },
                                      refetchQueries: [
                                        {
                                          query: getCompanySpacesByUser,
                                          variables: {
                                            id: userId
                                          }
                                        }
                                      ]
                                    })
                                      .then(res => {
                                        this.closeDialogModal();
                                        this.toastNotification(
                                          'Space deleted successfully',
                                          'success'
                                        );
                                      })
                                      .catch(err => {
                                        this.toastNotification(
                                          'Sorry, there was a problem deleting the space',
                                          'error'
                                        );
                                      });
                                  }}
                                >
                                  <DialogModal
                                    loading={deleteMutation.loading}
                                    title="Are you sure you want to delete this space?"
                                    toggleModal={this.closeDialogModal}
                                  />
                                </Form>
                              )}
                            </Mutation>
                          }
                          modal={dialogModal}
                        />
                        <TableHeaderContainer>
                          <TableHeader>
                            <span>Manage Spaces</span>
                          </TableHeader>
                          <StyledButton
                            type="button"
                            label="Add Space"
                            width="120px"
                            onClick={this.openModal}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                          <StyledButton
                            type="button"
                            label="Import Spaces"
                            width="120px"
                            onClick={this.openImportModal}
                            color={Colors.flumeGreen}
                            background={Colors.grey}
                          />
                          <CSVLink
                            data={createdCompanySpaces}
                            headers={SPACES_CSV_HEADINGS}
                            target="_blank"
                            filename="Spaces.csv"
                          >
                            <StyledButton
                              type="button"
                              label={
                                <MdFileDownload
                                  size="1.3em"
                                  color={Colors.flumeDarkGreen}
                                />
                              }
                              width="auto"
                              color={Colors.flumeDarkGreen}
                              background={Colors.flumeGreen}
                            />
                          </CSVLink>
                          <StyledButton
                            type="button"
                            label={
                              <MdSearch
                                size="1.3em"
                                color={Colors.flumeDarkGreen}
                              />
                            }
                            width="auto"
                            onClick={this.toggleSearchFilter}
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                          />
                        </TableHeaderContainer>
                        <Table
                          data={createdCompanySpaces}
                          columns={[
                            {
                              Header: 'Space Name',
                              accessor: 'name',
                              sortable: false,
                              filterable: searchFilter
                            },
                            {
                              id: uuid(),
                              Header: 'Color',
                              accessor: (companySpace: SpaceType) => {
                                if (
                                  companySpace.colorCode.includes('white') ||
                                  companySpace.colorCode.includes('ffffff')
                                ) {
                                  return (
                                    <SelectedColor
                                      style={{
                                        margin: 'inherit',
                                        border: `0.5px solid ${Colors.coal}`
                                      }}
                                      background={companySpace.colorCode}
                                      small={true}
                                    />
                                  );
                                }

                                return (
                                  <SelectedColor
                                    style={{
                                      margin: 'inherit',
                                      border: `0.5px solid ${
                                        Colors.catSkillWhite
                                      }`
                                    }}
                                    background={companySpace.colorCode}
                                    small={true}
                                  />
                                );
                              },
                              sortable: false,
                              filterable: false
                            },
                            {
                              id: uuid(),
                              Header: 'Status',
                              accessor: (companySpace: SpaceType) => {
                                return returnSpaceStatus(companySpace.status);
                              },
                              sortable: false,
                              filterable: searchFilter
                            },
                            {
                              id: uuid(),
                              Header: '',
                              accessor: (companySpace: SpaceType) => {
                                return (
                                  <RowActions
                                    openModal={this.openEditModal}
                                    openDialogModal={this.openDialogModal}
                                    space={companySpace}
                                  />
                                );
                              },
                              sortable: false,
                              filterable: false,
                              width: 30
                            }
                          ]}
                          defaultPageSize={5}
                          showPaginationTop={false}
                          showPaginationBottom={true}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeModal}
                          title="Create Spaces"
                          modalDisplay={
                            <AddSpaceModal
                              companyId={companyId}
                              closeModal={this.closeModal}
                              notification={this.toastNotification}
                            />
                          }
                          modal={modal}
                        />
                        <PlaceholderWrapper
                          icon={
                            <MdCameraAlt
                              size="8.5em"
                              color={Colors.flumeGreen}
                            />
                          }
                          title="Build your Spaces"
                          text={SPACES_DESCRIPTION}
                          buttonLabel="Add Space"
                          buttonAction={this.openModal}
                        />
                      </div>
                    );
                  }
                }}
              </Query>
              <Helmet title="Manage Spaces" />
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(Spaces);
