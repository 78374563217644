/* eslint array-callback-return: 0 */
import * as React from 'react';
import * as uuid from 'uuid';
import * as moment from 'moment';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FiLayers } from 'react-icons/fi';
import { FaPlusCircle } from 'react-icons/fa';

/** Presentation/UI */
import {
  BatchCardContainer,
  BatchCardHeading,
  BatchCardSpaceIdentifier,
  DayContainer,
  DayHeader,
  DayWrapper
} from '../../Components/Styled/MainPlanner';
import { ServiceIconsContainer } from '../../Components/Styled/ListViewElements';
import GlobalModalContainer from '../../Components/Modal';

/** Local components */
import AddMembersToSpace from './AddMembersToSpace';
import ViewUsersInSpace from './ViewUsersInSpace';

/** Custom types */
import {
  BatchCard,
  SavedBatchCardAllocation,
  BatchCardServices
} from '../../CustomTypes';
/** Generated types */
import { Permission } from '../../API';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import {
  returnAllocationsFromBatchCards,
  returnDayOfTheWeek,
  returnNumberOfBatchCardsOnDate,
  returnNumberOfItemsOnDate,
  arrayOfActiveBatchCardServices,
  numberOfItemsWithCompletedServices
} from './BatchCardAllocationHelpers';
import {
  selectedBatchCardServices,
  returnCompanyBatchCardsFromResponseData
} from './QCBatchCardHelpers';
import {
  CALENDAR_WEEK,
  MAIN_PLANNER_VIEWS,
  BATCH_TYPE,
  USER_ROLES
} from '../../Utils/Consts';
import {
  returnFlattenedArray,
  returnArrayWithUniqValues
} from '../../Utils/Helpers';

type Props = {
  currentWeek: Array<Date>;
  data: any;
  spaceId: string;
  spaceIds: Array<string>;
  userId: string;
  userPermission: Permission | string;
  openBatchCard(
    batchCardId: string,
    batchCardServices: Array<BatchCardServices>
  ): void;
};

type State = {
  batchCards: Array<BatchCard>;
  membersModal: boolean;
  dayNumber: number;
};

class MainPlannerCalendarWeekView extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps && nextProps.data && nextProps.data.getUser) {
      const { data } = nextProps;
      return {
        batchCards: returnCompanyBatchCardsFromResponseData(data)
      };
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      batchCards: returnCompanyBatchCardsFromResponseData(props.data),
      membersModal: false,
      dayNumber: 0
    };
  }

  /** Close members view modal */
  closeMembersModal = (): void => {
    this.setState({
      membersModal: false
    });
  };

  /** Open members view  modal */
  openMembersModal = (dayNumber: number): void => {
    this.setState({
      membersModal: true,
      dayNumber
    });
  };

  /**
   * Return the date for a particular day in the week
   * @param day - day of the week represented by an index
   * @param withYear - an optional flag if the year should also be returned
   */
  returnDate = (day: number, withYear?: boolean): string => {
    const { currentWeek } = this.props;
    return withYear
      ? moment(currentWeek[day]).format('DD MMM YYYY')
      : moment(currentWeek[day]).format('DD MMM');
  };

  /** Return batch card allocations
   * @param batchCards
   * @param date
   */
  returnAllocationsFromBatchCardsOnADate = (
    batchCards: Array<BatchCard>,
    date: string
  ): React.ReactNode => {
    const batchCardAllocations = returnAllocationsFromBatchCards(batchCards);

    if (batchCardAllocations && batchCardAllocations.length) {
      const mergedAllocations = returnFlattenedArray(batchCardAllocations);
      const savedBatchCardAllocations = mergedAllocations.filter(
        (batchCardAllocation: SavedBatchCardAllocation) =>
          batchCardAllocation.date === date
      );

      if (savedBatchCardAllocations && savedBatchCardAllocations.length) {
        const { spaceId, openBatchCard, spaceIds, userPermission } = this.props;

        /** Check if there are space id's set and if the account is for a normal user */
        if (USER_ROLES.user === userPermission && spaceIds.length) {
          const filteredBatchCardAllocations = savedBatchCardAllocations.filter(
            (bcAllocation: SavedBatchCardAllocation) =>
              spaceIds.indexOf(bcAllocation.space.id) > -1
          );

          if (
            filteredBatchCardAllocations &&
            filteredBatchCardAllocations.length
          ) {
            return filteredBatchCardAllocations.map(
              (bcAllocation: SavedBatchCardAllocation, i: number) => {
                return (
                  bcAllocation.batchCardServices &&
                  arrayOfActiveBatchCardServices(
                    bcAllocation.batchCardServices.items
                  ).length > 0 && (
                    <Draggable
                      key={bcAllocation.id}
                      draggableId={`${BATCH_TYPE.allocation}_${bcAllocation.id}`}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <BatchCardContainer
                          key={bcAllocation.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={() =>
                            openBatchCard(
                              bcAllocation.batchCard.id,
                              bcAllocation.batchCardServices.items
                            )
                          }
                        >
                          <BatchCardHeading>
                            {bcAllocation.batchCard.name}
                          </BatchCardHeading>
                          <span>
                            {bcAllocation.batchCard.productItems.items.length}
                          </span>
                          {'   '}
                          <FiLayers size="1em" color={Colors.lightGray} />
                          <br />
                          <br />
                          <ServiceIconsContainer>
                            {bcAllocation.batchCardServices &&
                              selectedBatchCardServices(
                                bcAllocation.batchCardServices.items
                              )}
                          </ServiceIconsContainer>
                          <br />
                          <BatchCardSpaceIdentifier
                            background={bcAllocation.space.colorCode}
                          />
                        </BatchCardContainer>
                      )}
                    </Draggable>
                  )
                );
              }
            );
          }

          return null;
        }

        /**
         * Check if the space id is set, if so, filter by spaces
         */
        if (spaceId !== '') {
          const filteredBatchCardAllocations = savedBatchCardAllocations.filter(
            (bcAllocation: SavedBatchCardAllocation) =>
              bcAllocation.space.id === spaceId
          );

          return filteredBatchCardAllocations.map(
            (bcAllocation: SavedBatchCardAllocation, i: number) => {
              return (
                bcAllocation.batchCardServices &&
                arrayOfActiveBatchCardServices(
                  bcAllocation.batchCardServices.items
                ).length > 0 && (
                  <Draggable
                    key={bcAllocation.id}
                    draggableId={`${BATCH_TYPE.allocation}_${bcAllocation.id}`}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <BatchCardContainer
                        key={bcAllocation.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() =>
                          openBatchCard(
                            bcAllocation.batchCard.id,
                            bcAllocation.batchCardServices.items
                          )
                        }
                      >
                        <BatchCardHeading>
                          {bcAllocation.batchCard.name}
                        </BatchCardHeading>
                        <span>
                          {bcAllocation.batchCard.productItems.items.length}
                        </span>
                        {'   '}
                        <FiLayers size="1em" color={Colors.lightGray} />
                        <br />
                        <br />
                        <ServiceIconsContainer>
                          {bcAllocation.batchCardServices &&
                            selectedBatchCardServices(
                              bcAllocation.batchCardServices.items
                            )}
                        </ServiceIconsContainer>
                        <br />
                        <BatchCardSpaceIdentifier
                          background={bcAllocation.space.colorCode}
                        />
                      </BatchCardContainer>
                    )}
                  </Draggable>
                )
              );
            }
          );
        }

        return savedBatchCardAllocations.map(
          (bcAllocation: SavedBatchCardAllocation, i: number) => {
            if (!bcAllocation.batchCard.productItems.items.length) {
              return;
            }
            const numberOfProductItems =
              bcAllocation.batchCard.productItems.items.length;
            const activeBatchCardServices =
              bcAllocation.batchCardServices &&
              arrayOfActiveBatchCardServices(
                bcAllocation.batchCardServices.items
              );

            return (
              activeBatchCardServices.length > 0 && (
                <Draggable
                  key={bcAllocation.id}
                  draggableId={`${BATCH_TYPE.allocation}_${bcAllocation.id}`}
                  index={i}
                >
                  {(provided, snapshot) => (
                    <BatchCardContainer
                      key={bcAllocation.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() =>
                        openBatchCard(
                          bcAllocation.batchCard.id,
                          bcAllocation.batchCardServices.items
                        )
                      }
                    >
                      <BatchCardHeading>
                        {bcAllocation.batchCard.name}
                      </BatchCardHeading>
                      <span>
                        {numberOfItemsWithCompletedServices(
                          bcAllocation.batchCard.productItems.items,
                          activeBatchCardServices
                        )}{' '}
                        /{numberOfProductItems}
                      </span>
                      {'   '}
                      <FiLayers size="1em" color={Colors.lightGray} />
                      <br />
                      <br />
                      <ServiceIconsContainer>
                        {bcAllocation.batchCardServices &&
                          selectedBatchCardServices(
                            bcAllocation.batchCardServices.items
                          )}
                      </ServiceIconsContainer>
                      <br />
                      <BatchCardSpaceIdentifier
                        background={bcAllocation.space.colorCode}
                      />
                    </BatchCardContainer>
                  )}
                </Draggable>
              )
            );
          }
        );
      }
      return null;
    }
    return null;
  };

  /** Return users/members in spaces in batch cards on particular date
   * @param batchCards - allocated batch cards
   * @param date - particular date of the week
   */
  returnMembersInBatchCardSpaces = (
    batchCards: Array<BatchCard>,
    date: string
  ): React.ReactNode => {
    const batchCardAllocations = returnAllocationsFromBatchCards(batchCards);

    if (batchCardAllocations && batchCardAllocations.length) {
      const mergedAllocations = returnFlattenedArray(batchCardAllocations);
      const savedBatchCardAllocations = mergedAllocations.filter(
        (batchCardAllocation: SavedBatchCardAllocation) =>
          batchCardAllocation.date === date
      );

      const spacesFromBatchCardAllocations = savedBatchCardAllocations.map(
        (savedBcAllocation: SavedBatchCardAllocation) => {
          return savedBcAllocation.space && savedBcAllocation.space.id;
        }
      );

      return (
        <ViewUsersInSpace
          spaceIds={returnArrayWithUniqValues(spacesFromBatchCardAllocations)}
          date={date}
        />
      );
    }

    return null;
  };

  /**
   * Return the week dates
   * @param batchCards - company batch cards that have been allocated
   */
  returnWeek = (batchCards: Array<BatchCard>) => {
    const calendarWeekKeys = Object.keys(CALENDAR_WEEK).map(
      key => CALENDAR_WEEK[key]
    );

    return calendarWeekKeys.map((dayKey: string, i) => {
      return (
        <DayContainer key={dayKey}>
          <Droppable
            droppableId={`${
              MAIN_PLANNER_VIEWS.calendar
            }_${uuid()}_${this.returnDate(parseInt(dayKey, 10))}`}
          >
            {(provided, snapshot) => (
              <DayWrapper
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <DayHeader>
                  <span className="day-header">
                    {returnDayOfTheWeek(parseInt(dayKey, 10))}
                  </span>
                  <br />
                  <span className="date-header">{this.returnDate(i)}</span>
                </DayHeader>
                <DayHeader height="2.30rem">
                  {this.returnMembersInBatchCardSpaces(
                    batchCards,
                    this.returnDate(i, true)
                  )}
                  <FaPlusCircle
                    size="1.15em"
                    className="add-members-to-space"
                    onClick={() => this.openMembersModal(i)}
                  />
                </DayHeader>
                <DayHeader>
                  <div>
                    {returnNumberOfBatchCardsOnDate(
                      batchCards,
                      this.returnDate(i, true)
                    )}{' '}
                    batch cards
                  </div>
                  <div>
                    {returnNumberOfItemsOnDate(
                      batchCards,
                      this.returnDate(i, true)
                    )}{' '}
                    items
                  </div>
                </DayHeader>
                {this.returnAllocationsFromBatchCardsOnADate(
                  batchCards,
                  this.returnDate(i, true)
                )}
                {provided.placeholder}
              </DayWrapper>
            )}
          </Droppable>
        </DayContainer>
      );
    });
  };

  render() {
    const { userId, currentWeek } = this.props;
    const { batchCards, membersModal, dayNumber } = this.state;

    return (
      <section>
        <GlobalModalContainer
          toggleModal={this.closeMembersModal}
          title="Add Members To Space"
          modalDisplay={
            <AddMembersToSpace
              closeModal={this.closeMembersModal}
              spaces={[]}
              userId={userId}
              allocationDate={currentWeek[dayNumber]}
            />
          }
          modal={membersModal}
        />{' '}
        {this.returnWeek(batchCards)}
      </section>
    );
  }
}

export default MainPlannerCalendarWeekView;
