import { ServiceType } from '../CustomTypes';
import { ServiceIcons } from '../Themes';
import { Permission, SpaceStatus, UserStatus } from '../API';
/** Stripe Products */
import { STANDARD_PRODUCT_ID, PREMIUM_PRODUCT_ID } from './StripeProducts';

export const SUPPORT_CONTACT_NUMBER = '+27 76 167 3031';
export const SUPPORT_CONTACT_EMAIL = 'support@flume.cloud';

export const COMPANY_SIZE = [
  {
    value: '1 - 5',
    label: '1 - 5'
  },
  {
    value: '15 - 30',
    label: '15 - 30'
  },
  {
    value: '30 - 50',
    label: '30 - 50'
  },
  {
    value: '50+',
    label: '50+'
  }
];

const CONSUMER_SERVICES = [
  {
    value: '5333 Drug Retailers',
    label: '5333 Drug Retailers'
  },
  {
    value: '5337 Food Retailers & Wholesalers',
    label: '5337 Food Retailers & Wholesalers'
  },
  {
    value: '5371 Apparel Retailers',
    label: '5371 Apparel Retailers'
  },
  {
    value: '5373 Broadline Retailers',
    label: '5373 Broadline Retailers'
  },
  {
    value: '5375 Home Improvement Retailers',
    label: '5375 Home Improvement Retailers'
  },
  {
    value: '5377 Specialized Consumer Services',
    label: '5377 Specialized Consumer Services'
  },
  {
    value: '5379 Specialty Retailers',
    label: '5379 Specialty Retailers'
  },
  {
    value: '5553 Broadcasting & Entertainment',
    label: '5553 Broadcasting & Entertainment'
  },
  {
    value: '5555 Media Agencies',
    label: '5555 Media Agencies'
  },
  {
    value: '5557 Publishing',
    label: '5557 Publishing'
  },
  {
    value: '5751 Airlines',
    label: '5751 Airlines'
  },
  {
    value: '5752 Gambling',
    label: '5752 Gambling'
  },
  {
    value: '5753 Hotels',
    label: '5753 Hotels'
  },
  {
    value: '5755 Recreational Services',
    label: '5755 Recreational Services'
  },
  {
    value: '5757 Restaurants & Bars',
    label: '5757 Restaurants & Bars'
  },
  {
    value: '5759 Travel & Tourism',
    label: '5759 Travel & Tourism'
  }
];

const TELECOMMUNICATIONS = [
  {
    value: '6500 Telecommunications',
    label: '6500 Telecommunications'
  }
];

const TECHNOLOGY = [
  {
    value: '9533 Computer Services',
    label: '9533 Computer Services'
  },
  {
    value: '9535 Internet',
    label: '9535 Internet'
  },
  {
    value: '9537 Software',
    label: '9537 Software'
  },
  {
    value: '9572 Computer Hardware',
    label: '9572 Computer Hardware'
  },
  {
    value: '9574 Electronic Office Equipment',
    label: '9574 Electronic Office Equipment'
  },
  {
    value: '9576 Semiconductors',
    label: '9576 Semiconductors'
  },
  {
    value: '9578 Telecommunications Equipment',
    label: '9578 Telecommunications Equipment'
  }
];

export const PRODUCTION_INDUSTRY = [
  {
    label: '1000 Basic Materials Producers & Distributors',
    options: [
      {
        value: '1000 Basic Materials Producers & Distributors',
        label: '1000 Basic Materials Producers & Distributors'
      }
    ]
  },
  {
    label: '2000 Industrials - Producers & Manufacturers',
    options: [
      {
        value: '2000 Industrials - Producers & Manufacturers',
        label: '2000 Industrials - Producers & Manufacturers'
      }
    ]
  },
  {
    label: '3000 Consumer Goods producers & manufacturers',
    options: [
      {
        value: '3000 Consumer Goods producers & manufacturers',
        label: '3000 Consumer Goods producers & manufacturers'
      }
    ]
  },
  {
    label: '4000 Health Care',
    options: [
      {
        value: '4000 Health Care',
        label: '4000 Health Care'
      }
    ]
  },
  {
    label: '5000 Consumer Services',
    options: CONSUMER_SERVICES
  },
  {
    label: '6000 Telecommunications',
    options: TELECOMMUNICATIONS
  },
  {
    label: '7000 Utilities',
    options: [
      {
        value: '7000 Utilities',
        label: '7000 Utilities'
      }
    ]
  },
  {
    label: '8000 Financials',
    options: [
      {
        value: '8000 Financials',
        label: '8000 Financials'
      }
    ]
  },
  {
    label: '9000 Technology',
    options: TECHNOLOGY
  }
];

export const GENDER = [
  {
    value: 'Male',
    label: 'Male'
  },
  {
    value: 'Female',
    label: 'Female'
  },
  {
    value: 'Girl',
    label: 'Girl'
  },
  {
    value: 'Boy',
    label: 'Boy'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

export const SORTING_OPTIONS = [
  { value: 'asc', label: 'Newest' },
  { value: 'desc', label: 'Oldest' }
];

/** User Roles - used for dropdown selection */
export const USER_PERMISSIONS = [
  {
    value: Permission.NON_USER,
    label: 'Non User'
  },
  {
    value: Permission.USER,
    label: 'User'
  },
  {
    value: Permission.SUPER_USER,
    label: 'Super User'
  }
];

/** User permission roles */
const USER_PERMISSION_ROLES = {
  user: 'USER',
  nonUser: 'NON_USER',
  superUser: 'SUPER_USER'
};

/** Cognito User Groups */
export const NON_USER_GROUP = 'NonUser';
export const USER_GROUP = 'User';
export const SUPER_USER_GROUP = 'SuperUser';
export const OWNER_USER_GROUP = 'OwnerUser';

/** Cognito challenge requests */
export const COGNITO_CHALLENGES = {
  newPasswordRequired: 'NEW_PASSWORD_REQUIRED'
};

export const USER_ROLES = Object.freeze(USER_PERMISSION_ROLES);

/** Space status options for dropdown select */
export const SPACE_STATUS_OPTIONS = [
  {
    value: SpaceStatus.ACTIVE,
    label: 'Active'
  },
  {
    value: SpaceStatus.INACTIVE,
    label: 'Inactive'
  }
];

/** User status options for dropdown select */
export const USER_STATUS_OPTIONS = [
  {
    value: UserStatus.ACTIVE,
    label: 'Active'
  },
  {
    value: UserStatus.INACTIVE,
    label: 'Inactive'
  }
];
/** Time period options for filtering on dashboard */
export const TIME_PERIOD_FILTER = [
  {
    value: 'Week',
    label: 'Week'
  },
  {
    value: 'Month',
    label: 'Month'
  },
  {
    value: 'Year',
    label: 'Year'
  }
];

/** Space statuses */
const SPACE_STATUSES = {
  active: 'ACTIVE',
  inactive: 'INACTIVE'
};

export const SPACE_STATUS = Object.freeze(SPACE_STATUSES);

/** Descriptions */
export const TEAM_DESCRIPTION = `Set up your Team in flume by adding each Team Member individually or by uploading a team list in bulk.
Once you have set up your Team you will need to assign the Roles and Permissions created in the previous step, to each Team Member, to ensure accurate access for them throughout flume.
New Team Members can be added or removed and permissions can be amended or updated at any time in the 'Manage' area of flume
`;

export const ROLES_DESCRIPTION = `To get started you'll need to create Roles in flume. Once you have created your Roles, the next step will be to set up your Team Members.
Each Team Member will then be assigned a Role as well as a specific Permission level to ensure accurate access for them in flume.
New roles can be added or removed and permissions can be amended or updated at any time in the 'Manage' area of flume.
`;

export const SPACES_DESCRIPTION =
  "This is where you will create your environments, in which your services will be carried out, whether they are physical or virtual spaces. \n For example, 'Photography' might be carried out in 'Studio A' which is a physical space while your 'Retouching' might be carried out in 'Retouching Area 1' which is actually a person at a desk in another location from the physical operation.\n \n" +
  'Simply give your spaces a name and assign a unique colour, from the colour wheel, to represent that particular space.' +
  " If, at any time, you would like to change the colour you assigned to a space, this can be done seamlessly in the 'Manage' area of flume.";

export const BRANDS_DESCRIPTION = `Whether you manage a single brand or a multibrand operation with several sub brand layers, this is where you will map out your brand framework. Add each brand individually or upload a list in bulk. 
This framework can be added to and updated at any time in the 'Manage' area of flume.
 `;

export const SERVICES_DESCRIPTION = `Here is where you'll create each of your unique services in flume. 
These services represent your workflow process for your operation.
Once you have given your service a name, make sure to select an appropriate icon from the Icon Library to accurately depict your service. 
For example, if your service is Photography, the camera icon would clearly represent this. 
These names and icons will be used throughout flume to represent your services and should you want to add a new or update an existing service, this can easily be done in the 'Manage' area of flume.
`;

export const ITEM_TEMPLATE_DESCRIPTION = `
This is where you will find a selection of item specific templates. 
Select the template that will best display all of your unique item information in the most clear, accurate and informative way.
For example, if you plan your Production Operation based on Brand, Category and Size, these would be key columns to have included in the template you select. \n \n
Once you have selected a suitable template, it will be used throughout flume to consistently display your unique item information and will be the basis for how item information and lists are uploaded into flume. This template will also define what information is represented in the dashboard.
Please note, once you have selected an item template you will not be able to change it. 
`;

export const PRODUCT_ITEM_DESCRIPTION = `
This is where you will add items using the item template you selected.
`;

/** Acceptable file formats */
const FILE_UPLOAD_FORMATS = {
  csv: 'csv',
  pdf: 'application/pdf',
  png: 'image/png',
  jpg: 'image/jpeg'
};

export const VALID_FILE_FORMATS = Object.freeze(FILE_UPLOAD_FORMATS);

/** Default service */
export const defaultService: ServiceType = {
  id: '',
  name: '',
  icon: ServiceIcons.Editing
};

/** Http Methods */
const HTTP_METHOD_OPTIONS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export const HTTP_METHODS = Object.freeze(HTTP_METHOD_OPTIONS);

export enum MAIN_PLANNER_VIEWS {
  calendar = 'CALENDAR',
  space = 'SPACE'
}

/** Calendar week order */
export enum CALENDAR_WEEK {
  Monday = '1',
  Tuesday = '2',
  Wednesday = '3',
  Thursday = '4',
  Friday = '5',
  Saturday = '6',
  Sunday = '0'
}

/** Subscription packages */
export enum SUBSCRIPTION_PACKAGES {
  free = 'FREE',
  standard = 'STANDARD',
  premium = 'PREMIUM'
}

/** Batch card and Batch card allocation types */
export enum BATCH_TYPE {
  card = 'CARD',
  allocation = 'ALLOCATION'
}

export enum TIME_RANGE {
  week = 'Week',
  month = 'Month',
  year = 'Year'
}

export const CURRENCY = '$';

export enum BULK_ACTIONS {
  note = 'Note',
  tag = 'Tag',
  image = 'Image',
  content = 'Content',
  qc = 'QC',
  qcDisapprove = 'Disapprove'
}

export enum CURRENCY_SYMBOL {
  usd = '$',
  euro = '€',
  gbp = '£'
}

export const BULK_ACTION_OPTIONS = [
  {
    label: 'Add Notes',
    value: BULK_ACTIONS.note
  },
  {
    label: 'Add Tags',
    value: BULK_ACTIONS.tag
  },
  {
    label: 'Add Images',
    value: BULK_ACTIONS.image
  },
  {
    label: 'Add Text',
    value: BULK_ACTIONS.content
  }
];

/** Exclusive for premium account */
export const QC_OPTION = {
  label: 'QC Approve',
  value: BULK_ACTIONS.qc
};

export const QC_DISAPPROVAL_OPTION = {
  label: 'QC Reject',
  value: BULK_ACTIONS.qcDisapprove
};

/** Archive/Complete product items */
export const archiveDialogMessage =
  'Are you sure you want to archive the selected items?';
export const completeDialogMessage =
  'Are you sure you want to move the selected items to done?';
export const archiveSuccessMessage = 'Items have successfully been archived';
export const completeSuccessMessage =
  'Items have successfully been moved to complete';

export const FILTER_REPORT_OPTIONS = [
  {
    label: 'Week',
    value: 'week'
  },
  {
    label: 'Month',
    value: 'month'
  },
  {
    label: 'Year',
    value: 'year'
  }
];
/** QC approve/disapproves product items */
export const qcApproveDialogMessage =
  'Are you sure you want to QC approve the selected items?';
export const qcDisapproveDialogMessage =
  'Are you sure you want to QC disapprove the selected items?';
export const qcApproveDialogSuccessMessage =
  'Items have successfully been QC approved';
export const qcDisapproveDialogSuccessMessage =
  'Items have successfully been QC disapproved';

/** reporting path */
export const REPORTING_PATH = '/reporting';

/** Item template for Flume owner/admin to view all product item attributes */
export const ADMIN_PRODUCT_ITEM_KEYS: Array<string> = [
  'id',
  'uic',
  'collectionName',
  'productName',
  'clientName',
  'serviceName',
  'company',
  'brand',
  'subBrand',
  'type',
  'category',
  'subCategory',
  'gender',
  'age',
  'size',
  'quantity',
  'unit',
  'look',
  'other1',
  'other2',
  'other3'
];

export const PRODUCT_ITEM_HEADINGS: Array<string> = [
  'ID',
  'UIC',
  'CLIENT NAME',
  'PRODUCT NAME',
  'SERVICE NAME',
  'COLLECTION NAME',
  'COMPANY',
  'BRAND',
  'SUB BRAND',
  'TYPE',
  'CATEGORY',
  'SUB CATEGORY',
  'GENDER',
  'AGE',
  'SIZE',
  'QUANTITY',
  'UNIT',
  'LOOK',
  'OTHER 1',
  'OTHER 2',
  'OTHER 3'
];

export const TEAM_MEMBERS_CSV_HEADINGS = [
  { label: 'FIRST NAME', key: 'firstName' },
  { label: 'LAST NAME', key: 'lastName' },
  { label: 'EMAIL', key: 'email' },
  { label: 'ROLE', key: 'userRole.name' },
  { label: 'PERMISSION', key: 'userRole.permission' },
  { label: 'STATUS', key: 'status' },
  { label: 'INVITE USER', key: 'invited' }
];

export const SPACES_CSV_HEADINGS = [
  { label: 'SPACE NAMES', key: 'name' },
  { label: 'COLOR', key: 'colorCode' }
];

export const PRODUCT_ITEMS_CSV_HEADINGS = [
  { label: 'UIC', key: 'uic' },
  { label: 'CLIENT NAME', key: 'clientName' },
  { label: 'PRODUCT NAME', key: 'productName' },
  { label: 'SERVICE NAME', key: 'serviceName' },
  { label: 'COLLECTION NAME', key: 'collectionName' },
  { label: 'COMPANY', key: 'company' },
  { label: 'BRAND', key: 'brand.name' },
  { label: 'SUB BRAND', key: 'subBrand.name' },
  { label: 'TYPE', key: 'type' },
  { label: 'CATEGORY', key: 'category' },
  { label: 'SUB CATEGORY', key: 'subCategory' },
  { label: 'GENDER', key: 'gender' },
  { label: 'AGE', key: 'age' },
  { label: 'SIZE', key: 'size' },
  { label: 'QUANTITY', key: 'quantity' },
  { label: 'UNIT', key: 'unit' },
  { label: 'LOOK', key: 'look' },
  { label: 'OTHER 1', key: 'other1' },
  { label: 'OTHER 2', key: 'other2' },
  { label: 'OTHER 3', key: 'other3' }
];

export const BRANDS_CSV_HEADINGS = ['BRAND', 'SUB BRAND'];

export const DEFAULT_SPACE_COLOR = '#bee5e2';

export const FREE_TRIAL = {
  id: STANDARD_PRODUCT_ID,
  name: 'Free Trial',
  description: `Try Flume free for 30 days.  We will not bill you in the first 30 days
    unless you want to take advantage of the great features of the paid Standard or Premium packages.`,
  users: '5 users',
  package: SUBSCRIPTION_PACKAGES.standard,
  price: 0,
  period: '30 days'
};

export const STANDARD_PACKAGE = {
  id: STANDARD_PRODUCT_ID,
  name: 'Standard',
  description: `Flume’s standard end-to-end package including the production planner, real-time dashboard,
    reporting and management of unlimited spaces, services, brands and products.`,
  users: 'Per user',
  package: SUBSCRIPTION_PACKAGES.standard,
  price: 47,
  period: 'billing monthly'
};

export const PREMIUM_PACKAGE = {
  id: PREMIUM_PRODUCT_ID,
  name: 'Premium',
  description: `Flume’s standard end-to-end package including the production planner,
    real-time dashboard, reporting and management of unlimited spaces, services and products PLUS the comprehensive QC module.`,
  users: 'Per user',
  package: SUBSCRIPTION_PACKAGES.premium,
  price: 59,
  period: 'billing monthly'
};

export const COGNITO_VERIFICATION_ATTRIBUTE = 'email';

export const DEFAULT_USER_ROLE = {
  id: '',
  name: '',
  permission: Permission.USER
};

export enum APOLLO_FETCH_POLICY {
  CACHE_FIRST = 'cache-first',
  CACHE_AND_NETWORK = 'cache-and-network',
  NETWORK_ONLY = 'network-only',
  CACHE_ONLY = 'cache-only',
  NO_CACHE = 'no-cache',
  STANDBY = 'standby'
}

export const DISABLED_USER_MSG = 'User is disabled';
