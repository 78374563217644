import * as React from 'react';
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Helmet from 'react-helmet';

/** Utils */
import { checkUserGroup, validateToken } from '../Utils/Helpers';
import { AUTH_USER_TOKEN_KEY } from '../Utils/LocalStorage';

/** Config */
import AppConfig from '../Config/AppConfig';

/** Routes */
import Routes from './Routes';
import NoMatch from './NoMatch';

/** Nav items */
import { NAV_ITEMS } from './NavItems';

/** Sidebar nav */
import SideBar from '../Components/Sidebar';

const AuthRoute = (props: any) => {
  const Comp = props.component;

  return props.private &&
    !validateToken(window.localStorage.getItem(AUTH_USER_TOKEN_KEY)) ? (
    <Redirect
      to={{
        pathname: '/sign-in',
        state: { from: props.path }
      }}
    />
  ) : props.private &&
    validateToken(window.localStorage.getItem(AUTH_USER_TOKEN_KEY)) &&
    checkUserGroup(
      window.localStorage.getItem(AUTH_USER_TOKEN_KEY),
      props.cognitoGroups
    ) ? (
    <SideBar
      navItems={NAV_ITEMS}
      {...props}
      render={<Route render={data => <Comp {...data} {...props} />} />}
    />
  ) : props.private &&
    validateToken(window.localStorage.getItem(AUTH_USER_TOKEN_KEY)) &&
    !checkUserGroup(
      window.localStorage.getItem(AUTH_USER_TOKEN_KEY),
      props.cognitoGroups
    ) ? (
    <Redirect to="/sign-in" />
  ) : (
    <Route render={data => <Comp {...data} {...props} />} />
  );
};

const AppNavigation = () => (
  <Router>
    <React.Fragment>
      <Helmet
        defaultTitle={AppConfig.appName}
        titleTemplate={`${AppConfig.appName} | %s`}
      />
      <Switch>
        {Routes.map((route, i) => {
          return <AuthRoute key={i} {...route} />;
        })}
        <Route component={NoMatch} />
      </Switch>
    </React.Fragment>
  </Router>
);

export default AppNavigation;
