/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { adopt } from 'react-adopt';

/** GraphQL */
import {
  createBatchCardAllocation,
  updateBatchCardService
} from '../../graphql/mutations';

/** Types */
import {
  CreateBatchCardAllocationMutation,
  CreateBatchCardAllocationMutationVariables,
  UpdateBatchCardServiceMutation,
  UpdateBatchCardServiceMutationVariables
} from '../../API';

/** Mutation components for creating batch card allocations and creating
 * relations between the allocations and the batch card services
 */
const addBatchCardAllocation = (props: { render: ({}) => Mutation }) => (
  <Mutation<
    CreateBatchCardAllocationMutation,
    CreateBatchCardAllocationMutationVariables
  >
    mutation={gql(createBatchCardAllocation)}
  >
    {(mutation, result) => props.render({ mutation, result })}
  </Mutation>
);

const updateBatchCardServiceWithAllocation = (props: {
  render: ({}) => Mutation;
}) => (
  <Mutation<
    UpdateBatchCardServiceMutation,
    UpdateBatchCardServiceMutationVariables
  >
    mutation={gql(updateBatchCardService)}
  >
    {(mutation, result) => props.render({ mutation, result })}
  </Mutation>
);

const Composed = adopt({
  addBatchCardAllocation,
  updateBatchCardServiceWithAllocation
});

export default Composed;
