/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Form, FormGroup, Button } from 'reactstrap';
import * as uuid from 'uuid';
import { Storage } from 'aws-amplify';

/** GraphQL */
import { updateProductItem } from '../../graphql/mutations';
import {
  getCompanyBatchCardByUser,
  getCompanyBatchCardsByUser,
  getCompanyCompletedProductItemsByUser
} from '../../graphql/custom-queries';

/** Context API */
import FileUploadContextProvider from '../NewProductItems/FileUploadContextProvider';
import { FileUploadContextConsumer } from '../NewProductItems/FileUploadContextProvider';

/** Generated types */
import {
  UpdateProductItemMutation,
  UpdateProductItemMutationVariables,
  UpdateProductItemInput
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';

/** Local components */
import UploadImages from './BatchCardImageUpload';

/** Custom types */
import { Error, ModalWithMessageType } from '../../CustomTypes';
import ProductItemImages from './ProductItemImages';

type Props = ModalWithMessageType & {
  batchCardId?: string;
  bulkAction: boolean;
  productItemId: string;
  productItemIds?: Array<string>;
  productItemImageKeys: Array<string>;
  productItems: Array<UpdateProductItemInput>;
  readOnly?: boolean;
  userId: string;
};

type State = {
  error: Error;
  imagesWithIncorrectNaming: Array<File>;
  loading: boolean;
  uploadSuccessful: boolean;
};

class AddImageToProductItem extends React.Component<Props, State> {
  timeoutId: number = 0;
  state = {
    error: null,
    imagesWithIncorrectNaming: [],
    loading: false,
    uploadSuccessful: false
  };

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Validate form
   * @param uploadedFiles - image files that have been uploaded
   */
  validateForm = (uploadedFiles: Array<File>): boolean => {
    // Check for undefined or empty input fields
    if (!uploadedFiles || !uploadedFiles.length) {
      return false;
    }

    return true;
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Execute mutation to update general batch details
   * @param batchCardId - id of batch card
   * @param productItemId - id of product item
   * @param productItemImageKeys - existing image keys for product item
   * @param filesToUpload - image files to be uploaded
   * @param mutationToUpdateProductItem - graphql mutation to update product item
   */
  executeMutation = async (
    batchCardId: string | undefined,
    bulkAction: boolean,
    productItemId: string,
    productItemImageKeys: Array<string>,
    filesToUpload: Array<File>,
    userId: string,
    productItems: Array<UpdateProductItemInput>,
    mutationToUpdateProductItem: ({}) => Promise<any>,
    closeModal: () => void,
    notification: (message: string, appearance?: string | undefined) => void
  ) => {
    // Upload images to a single product item
    let newImageKeys: Array<string> = [];
    let existingImageKeys: Array<string> = [];

    this.setState({ loading: true });

    try {
      /** Handle image file uploads to S3, and subsequently save the
       * updated image keys for the batch card based on added or removed
       * images.
       */

      if (bulkAction) {
        // Upload image(s) to different product items based on UIC code

        // Create an array of the image files that have correct UIC's
        const imageFilesCorrectlyNamed = filesToUpload.filter(
          (imageFile: File) => {
            const { name } = imageFile;
            const uic = name.split('_')[0];
            if (uic && typeof uic === 'string') {
              // Check if the UIC matches the UIC of any of the selected product items
              return productItems.find(
                (productItem: UpdateProductItemInput) => productItem.uic === uic
              );
            }
            return false;
          }
        );

        // Create an array of the image files that have incorrect UIC's
        const imagesWithIncorrectNaming = filesToUpload.filter(
          (imageFile: File) => {
            return !imageFilesCorrectlyNamed.find(
              (correctImageFile: File) =>
                correctImageFile.name === imageFile.name
            );
          }
        );

        this.setState({ imagesWithIncorrectNaming });

        try {
          await Promise.all(
            productItems.map(async (productItem: UpdateProductItemInput) => {
              // Upload image files that match the uic of the product item
              const productItemFilesToUpload = filesToUpload.filter(
                (imageFile: File) => {
                  const { name } = imageFile;
                  const uic = name.split('_')[0];
                  if (uic && typeof uic === 'string') {
                    // Check if the UIC matches the UIC of the current product item
                    return productItem.uic === uic;
                  }
                  return false;
                }
              );

              const uploadedProductItemFiles = await Promise.all(
                productItemFilesToUpload.map(async file => {
                  return await Storage.put(`${uuid()}_${file.name}`, file, {
                    contentType: file.type
                  });
                })
              );

              // If image files were uploaded successfully, get the keys for each file
              if (uploadedProductItemFiles && uploadedProductItemFiles.length) {
                newImageKeys = uploadedProductItemFiles.map(
                  // @ts-ignore
                  (image: { key: string }) => {
                    return image.key;
                  }
                );
              }

              // Get all current/existing image keys
              if (productItem.imageKeys && productItem.imageKeys.length) {
                existingImageKeys = productItem.imageKeys.map(
                  // @ts-ignore
                  (imageKey: string) => {
                    return imageKey;
                  }
                );
              }

              const imageKeys: Array<string> = [
                ...newImageKeys,
                ...existingImageKeys
              ];

              // Update product item with new image keys
              await mutationToUpdateProductItem({
                variables: {
                  input: {
                    id: productItem.id,
                    imageKeys
                  }
                },
                refetchQueries: [
                  {
                    query: getCompanyBatchCardByUser,
                    variables: {
                      userId,
                      batchCardId
                    }
                  },
                  {
                    query: getCompanyBatchCardsByUser,
                    variables: {
                      id: userId
                    }
                  }
                ]
              });
            })
          );
          newImageKeys = [];
          existingImageKeys = [];
          // Notify user that uploads and upates are complete
          this.setState({ loading: false, uploadSuccessful: true });
          notification('Images successfully added');
          closeModal();
        } catch (err) {
          this.setState({ loading: false });
          closeModal();
          this.setError(err.message);
        }
      } else {
        // Upload image files to S3 bucket
        const uploadedFiles = await Promise.all(
          filesToUpload.map(async file => {
            // @ts-ignore
            return await Storage.put(`${uuid()}_${file.name}`, file, {
              contentType: file.type
            });
          })
        );
        // If image files were uploaded successfully, get the keys for each file
        if (uploadedFiles && uploadedFiles.length) {
          // @ts-ignore
          newImageKeys = uploadedFiles.map((image: { key: string }) => {
            return image.key;
          });
        }

        // Get all current/existing image keys
        if (productItemImageKeys && productItemImageKeys.length) {
          // @ts-ignore
          existingImageKeys = productItemImageKeys.map((imageKey: string) => {
            return imageKey;
          });
        }

        const imageKeys: Array<string> = [
          ...newImageKeys,
          ...existingImageKeys
        ];

        mutationToUpdateProductItem({
          variables: {
            input: {
              id: productItemId,
              imageKeys
            }
          },
          refetchQueries: [
            {
              query: getCompanyBatchCardByUser,
              variables: {
                userId,
                batchCardId
              }
            },
            {
              query: getCompanyBatchCardsByUser,
              variables: {
                id: userId
              }
            },
            {
              query: getCompanyCompletedProductItemsByUser,
              variables: {
                id: userId
              }
            }
          ]
        })
          .then(() => {
            newImageKeys = [];
            existingImageKeys = [];
            this.setState({ loading: false });
            closeModal();
            notification('Images successfully added');
          })
          .catch(err => {
            this.setError(err.message);
          });
      }
      newImageKeys = [];
      existingImageKeys = [];
    } catch (err) {
      this.setState({ loading: false });
      closeModal();
      this.setError(err.message);
    }
  };

  render() {
    const {
      error,
      imagesWithIncorrectNaming,
      loading,
      uploadSuccessful
    } = this.state;
    const {
      batchCardId,
      bulkAction,
      closeModal,
      notification,
      productItemId,
      productItemImageKeys,
      productItems,
      readOnly,
      userId
    } = this.props;

    return (
      <Mutation<UpdateProductItemMutation, UpdateProductItemMutationVariables>
        mutation={gql(updateProductItem)}
      >
        {(updateProductItemMutation, updateProductItemResponse) => (
          <FileUploadContextProvider>
            <FileUploadContextConsumer>
              {({ uploadedFiles }) => {
                return (
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      if (this.validateForm(uploadedFiles)) {
                        this.executeMutation(
                          batchCardId,
                          bulkAction,
                          productItemId,
                          productItemImageKeys,
                          uploadedFiles,
                          userId,
                          productItems,
                          updateProductItemMutation,
                          closeModal,
                          notification
                        );
                      }
                    }}
                  >
                    {!bulkAction && (
                      <div>
                        <ProductItemImages
                          batchCardId={batchCardId}
                          closeModal={closeModal}
                          notification={notification}
                          productItemId={productItemId}
                          productItemImageKeys={productItemImageKeys}
                          userId={userId}
                        />
                        <br />
                        <br />
                      </div>
                    )}
                    {!readOnly && (
                      <div>
                        <UploadImages bulkAction={bulkAction} />
                        {uploadedFiles.length ? (
                          <div>
                            Selected files to upload:
                            {uploadedFiles.map((f: File, i) => (
                              <React.Fragment key={i}>
                                <div>
                                  {i + 1}. {f.name} - {`${f.size / 1000} kb`}
                                </div>
                              </React.Fragment>
                            ))}
                            <br />
                          </div>
                        ) : null}
                        {imagesWithIncorrectNaming.length ? (
                          <div>
                            The following files are incorrectly named and will
                            not be uploaded:
                            {imagesWithIncorrectNaming.map((f: File, i) => (
                              <React.Fragment key={i}>
                                <div>
                                  {i + 1}. {f.name} - {`${f.size / 1000} kb`}
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        ) : null}
                        <br />
                        {uploadSuccessful ? (
                          <div>File Upload Process Complete</div>
                        ) : (
                          <FormGroup>
                            <Button type="submit" block={true}>
                              {loading ? <Loader /> : 'Save Images'}
                            </Button>
                          </FormGroup>
                        )}
                      </div>
                    )}
                    {error && <ErrorMessage errorMessage={error} />}
                  </Form>
                );
              }}
            </FileUploadContextConsumer>
          </FileUploadContextProvider>
        )}
      </Mutation>
    );
  }
}

export default AddImageToProductItem;
