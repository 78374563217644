import * as React from 'react';
import Helmet from 'react-helmet';
import * as moment from 'moment';

/** Local components */
import ServicesChart from './ServicesChart';
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';

/** Utils */
import {
  returnFormattedDates,
  checkForUndefinedObjectValues
} from '../../Utils/Helpers';

/** Custom types */
import { ChartData } from '../../CustomTypes';

/** GraphQL */
import {
  getProductItemServicesForTheMonthByUser,
  getProductItemServicesForTheWeekByUser
} from '../../graphql/custom-queries';

type Props = {
  userId: string;
  serviceName: string;
  filter: string;
  filterPeriod: moment.Moment;
  currentWeek: Array<Date>;
  updateChartDataSetOne: (data: ChartData) => void;
};

const ReportingServices = (props: Props) => {
  const {
    userId,
    serviceName,
    filter,
    currentWeek,
    filterPeriod,
    updateChartDataSetOne
  } = props;

  const formattedDates = returnFormattedDates(currentWeek, 'YYYY-MM-DD');
  const formattedMonth = moment(filterPeriod).format('YYYY-MM');
  const currentYear = moment(filterPeriod).format('YYYY');

  let query = getProductItemServicesForTheWeekByUser;
  let variables: object = { id: userId };

  if (filter === 'week') {
    variables = {
      ...variables,
      dayOne: formattedDates[0],
      dayTwo: formattedDates[1],
      dayThree: formattedDates[2],
      dayFour: formattedDates[3],
      dayFive: formattedDates[4],
      daySix: formattedDates[5],
      daySeven: formattedDates[6]
    };
  }

  if (filter === 'month') {
    query = getProductItemServicesForTheMonthByUser;
    variables = {
      ...variables,
      month: formattedMonth
    };
  }

  if (filter === 'year') {
    query = getProductItemServicesForTheMonthByUser;
    variables = {
      ...variables,
      month: currentYear
    };
  }

  return (
    <div>
      {serviceName ? (
        <React.Fragment>
          {checkForUndefinedObjectValues(variables) && (
            <React.Fragment>
              <ServicesChart
                query={query}
                variables={variables}
                serviceName={serviceName}
                filter={filter}
                filterPeriod={filterPeriod}
                formattedDates={formattedDates}
                title="Items"
                itemsType="items"
                updateDataCallback={updateChartDataSetOne}
              />
            </React.Fragment>
          )}
          <Helmet title="Reporting - Spaces" />
        </React.Fragment>
      ) : (
        <FullWidthContainer align="center" marginTop={100}>
          <p>Select a service above to view chart data</p>
        </FullWidthContainer>
      )}
    </div>
  );
};

export default ReportingServices;
