import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';

/** GraphQL */
import { createCompany, updateUser } from '../../graphql/mutations';
import {
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables
} from '../../API';

/** Custom types */
import { ValueType } from '../../CustomTypes';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import StyledOptions from '../../Components/Styled/ProductionIndustryDropdDown';

/** Utils */
import { COMPANY_SIZE, PRODUCTION_INDUSTRY } from '../../Utils/Consts';
import { sortDropDownDataAlphabetically } from '../../Utils/Helpers';

// list all countries
const COUNTRIES = countryList().getData();

type SelectType = {
  value: string;
  label: string;
};

type State = {
  name: string;
  address: string;
  state: string;
  country: SelectType;
  industry: SelectType;
  companySize: SelectType;
  loading: boolean;
  error: string | null;
  errors: {
    name: string;
    address: string;
    state: string;
    country: string;
    industry: string;
    companySize: string;
  };
};

type Props = {
  userId: string;
  updateView(view: string): void;
};

class CompanySetupForm extends React.Component<Props, State> {
  state: State = {
    name: '',
    address: '',
    state: '',
    country: { value: '', label: '' },
    industry: { value: '', label: '' },
    companySize: { value: '', label: '' },
    loading: false,
    error: null,
    errors: {
      name: '',
      address: '',
      state: '',
      country: '',
      industry: '',
      companySize: ''
    }
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  // Select country
  selectCountry = (country: SelectType): void => {
    this.setState(prevState => ({
      country,
      errors: {
        ...prevState.errors,
        country: ''
      }
    }));
  };

  // Select production industry
  selectIndustry = (value: ValueType<string>): void => {
    // @ts-ignore
    this.setState(prevState => ({
      industry: value,
      errors: {
        ...prevState.errors,
        industry: ''
      }
    }));
  };

  // Select company size
  selectCompanySize = (companySize: SelectType): void => {
    this.setState(prevState => ({
      companySize,
      errors: {
        ...prevState.errors,
        companySize: ''
      }
    }));
  };

  // Handle changeto input field
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    switch (name) {
      case 'name':
        this.setState(prevState => ({
          name: value,
          errors: {
            ...prevState.errors,
            name: ''
          }
        }));
        break;

      case 'address':
        this.setState(prevState => ({
          address: value,
          errors: {
            ...prevState.errors,
            address: ''
          }
        }));
        break;

      case 'state':
        this.setState(prevState => ({
          state: value,
          errors: {
            ...prevState.errors,
            state: ''
          }
        }));
        break;

      default:
        break;
    }
  };

  // Validation
  validateForm = (): boolean => {
    const { name, address, state, country, industry, companySize } = this.state;
    let valid = false;

    // Check for undefined or empty input fields
    /* eslint-disable */
    [name, address, state, country, industry, companySize].map((input, key) => {
      if (key === 0 && !input) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            name: 'Please fill in the company name.'
          }
        }));
      } else if (key === 1 && !input) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            address: 'Please fill in the company address.'
          }
        }));
      } else if (key === 2 && !input) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            state: 'Please fill in the state.'
          }
        }));
      } else if (key === 3 && !input['value']) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            country: 'Please select a country.'
          }
        }));
      } else if (key === 4 && !input['value']) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            industry: 'Please select the company industry.'
          }
        }));
      } else if (key === 5 && !input['value']) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            companySize: 'Please select the company size.'
          }
        }));
      } else {
        valid = true;
      }
    });

    return valid;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const {
      name,
      address,
      state,
      country,
      industry,
      companySize,
      errors
    } = this.state;

    const { updateView, userId } = this.props;

    return (
      <Mutation<CreateCompanyMutation, CreateCompanyMutationVariables>
        mutation={gql(createCompany)}
      >
        {(createCompanyMutation, response) => (
          <Mutation<UpdateUserMutation, UpdateUserMutationVariables>
            mutation={gql(updateUser)}
          >
            {updateUserMutation => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  if (this.validateForm()) {
                    createCompanyMutation({
                      variables: {
                        input: {
                          createdAt: new Date().toISOString(),
                          name,
                          address,
                          state,
                          country: country.label,
                          industry: industry.label,
                          companySize: companySize.label
                        }
                      }
                    })
                      .then((res: any) => {
                        const { id } = res.data.createCompany;
                        // update user by adding them to newly created company
                        updateUserMutation({
                          variables: {
                            input: {
                              id: userId,
                              userCompanyId: id
                            }
                          }
                        })
                          .then((updateUserRes: any) => {
                            // change view to choose product subscription plan
                            updateView('chooseProduct');
                          })
                          .catch((updateUserErr: any) => {
                            this.setError(updateUserErr.message);
                          });
                      })
                      .catch((err: any) => {
                        this.setError(err.message);
                      });
                  }
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <Label for="name">Company Name</Label>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        placeholder="Company name"
                        onChange={this.handleChange}
                        className={errors.name && 'input-error'}
                      />
                      <div
                        className={errors.name ? 'input-group-error' : 'hidden'}
                      >
                        <span>{errors.name}</span>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <Label for="address">Company Address</Label>
                      <Input
                        type="text"
                        name="address"
                        id="address"
                        value={address}
                        placeholder="Company address"
                        onChange={this.handleChange}
                        className={errors.address && 'input-error'}
                      />
                      <div
                        className={
                          errors.address ? 'input-group-error' : 'hidden'
                        }
                      >
                        <span>{errors.address}</span>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Label for="state">State</Label>
                      <Input
                        type="text"
                        name="state"
                        id="state"
                        value={state}
                        placeholder="State"
                        onChange={this.handleChange}
                        className={errors.state && 'input-error'}
                      />
                      <div
                        className={
                          errors.state ? 'input-group-error' : 'hidden'
                        }
                      >
                        <span>{errors.state}</span>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Label>Country</Label>
                      <Select
                        // @ts-ignore
                        onChange={this.selectCountry}
                        options={sortDropDownDataAlphabetically(COUNTRIES)}
                        isSearchable={true}
                        placeholder=""
                        className={
                          errors.country
                            ? 'select-styling input-error'
                            : 'select-styling'
                        }
                      />
                      <div
                        className={
                          errors.country ? 'input-group-error' : 'hidden'
                        }
                      >
                        <span>{errors.country}</span>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Label>Production Industry</Label>
                      <Select
                        // @ts-ignore
                        onChange={this.selectIndustry}
                        options={PRODUCTION_INDUSTRY}
                        value={industry}
                        formatGroupLabel={StyledOptions}
                        isSearchable={true}
                        placeholder=""
                        className={
                          errors.industry
                            ? 'select-styling input-error'
                            : 'select-styling'
                        }
                      />
                      <div
                        className={
                          errors.industry ? 'input-group-error' : 'hidden'
                        }
                      >
                        <span>{errors.industry}</span>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Label>Company Size</Label>
                      <Select
                        // @ts-ignore
                        onChange={this.selectCompanySize}
                        options={COMPANY_SIZE}
                        isSearchable={true}
                        placeholder=""
                        className={
                          errors.companySize
                            ? 'select-styling input-error'
                            : 'select-styling'
                        }
                      />
                      <div
                        className={
                          errors.companySize ? 'input-group-error' : 'hidden'
                        }
                      >
                        <span>{errors.companySize}</span>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <br />
                <Button>
                  {!response.loading ? <span>Next</span> : <Loader />}
                </Button>
              </Form>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default CompanySetupForm;
