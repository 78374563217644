/* eslint array-callback-return: 0 */
import * as React from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import Dropzone from 'react-dropzone';
import { Form } from 'reactstrap';
import * as Papa from 'papaparse';
import * as moment from 'moment';
import { CSVLink } from 'react-csv';
import { MdFileDownload } from 'react-icons/md';

/** GraphQL */
import { createProductItem } from '../../graphql/mutations';
import {
  getCompanyProductItemsByUser,
  getCompanyProductItemsByUserAndUic
} from '../../graphql/custom-queries';

import { CreateProductItemInput, CreateItemTemplateInput } from '../../API';

/** Presentation/UI */
import ErrorMessage from '../../Components/Styled/ErrorMessage';

import StyledButton from '../../Components/Styled/Button';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Local component */
import DropzoneContainer from './DropzoneContainer';

/** Custom types */
import { Error, BrandType } from '../../CustomTypes';

/** Theme */
import { Colors } from '../../Themes';

/** Utils */
import { VALID_FILE_FORMATS, APOLLO_FETCH_POLICY } from '../../Utils/Consts';
import {
  returnValidProductItem,
  mergeItemTemplateObjectWithCSVValues,
  returnNewProductItemCSVData
} from '../../Utils/Helpers';

type Props = {
  companyId: string;
  brands: Array<BrandType>;
  itemTemplate: CreateItemTemplateInput;
  closeModal: () => void;
  notification(message: string, appearance?: string): void;
};

type State = {
  files: Array<File>;
  loading: boolean;
  error: Error;
};

class ProductItemsDataImport extends React.Component<
  WithApolloClient<Props>,
  State
> {
  state: State = {
    files: [],
    loading: false,
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** On dropping file */
  onDrop = (acceptedFiles: any): void => {
    if (!acceptedFiles || !acceptedFiles.length) {
      this.setError('Sorry, there was a problem uploading your selected file.');
    }
    // Check if the uploaded file is a valid csv file
    const uploadedFile = acceptedFiles[0];
    if (uploadedFile.name.split('.').pop() === VALID_FILE_FORMATS.csv) {
      this.setState({ files: [...this.state.files, ...acceptedFiles] });
    } else {
      this.setError('Please upload a valid CSV file.');
    }
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /** Parse uploaded data from file */
  parseFileData = (userId: string, files: Array<File>) => {
    const { notification, closeModal } = this.props;

    Papa.parse(files[0], {
      complete: (results: any) => {
        // Check if there are rows of data in the CSV file
        if (results && results.data && results.data.length) {
          // filter out the column headings from the data
          const productItemsData = results.data.slice(1);
          /**
           * Use array of valid product item data to retrieve array of
           * objects that match product item type to be created using
           * mutation
           */
          const productItems: Array<
            CreateProductItemInput
          > = this.getItemsToBeCreated(productItemsData);
          const productItemsToBeCreated = this.removeItemsWithSimilarUICCode(
            productItems
          );

          // Loop through product items to be created
          productItemsToBeCreated.forEach(
            async (input: CreateProductItemInput) => {
              // Only create product items with more than just the company id property
              if (Object.keys(input).length > 1) {
                const result: any =
                  input.uic &&
                  (await this.props.client
                    .query({
                      query: getCompanyProductItemsByUserAndUic,
                      variables: {
                        userId,
                        uic: input.uic
                      },
                      fetchPolicy: APOLLO_FETCH_POLICY.NO_CACHE
                    })
                    .catch(err => {
                      notification('An error occured.', 'error');
                    }));
                if (
                  !input.uic ||
                  (result &&
                    result.data &&
                    result.data.getUser.company.productItems.items.length === 0)
                ) {
                  // Create product item
                  this.props.client
                    .mutate({
                      variables: { input },
                      mutation: gql(createProductItem),
                      refetchQueries: [
                        {
                          query: getCompanyProductItemsByUser,
                          variables: {
                            id: userId
                          }
                        }
                      ]
                    })
                    .then((res: any) => {
                      notification('Product saved successfully', 'success');
                      closeModal();
                    })
                    .catch(err => {
                      notification(
                        'Encountered an error whilst importing product item',
                        'error'
                      );
                    });
                } else {
                  notification(
                    input.uic + ' UIC code already exists.',
                    'error'
                  );
                }
              }
            }
          );
          closeModal();
          notification('Importing items');
        } else {
          this.setError('No rows were found in the CSV file you uploaded.');
        }
      }
    });
  };

  /** Get valid data to be created in the system
   * @param productItemsData - the valid product items/data that has been parsed and returned as an array
   */
  getItemsToBeCreated = (productItemsData: Array<Array<string>>) => {
    const { companyId, brands, itemTemplate } = this.props;

    return productItemsData.map((productItem: Array<string>) => {
      const item = mergeItemTemplateObjectWithCSVValues(
        returnValidProductItem(itemTemplate),
        productItem
      );

      // Get the product item brand id based on the name of the brand in the data field
      const productItemBrand = brands.filter(
        brand =>
          item.brand && brand.name.toLowerCase() === item.brand.toLowerCase()
      );
      const productItemBrandId =
        productItemBrand && productItemBrand.length
          ? productItemBrand[0].id
          : '';
      // Check for the product item sub brand if the brand id is not an empty string
      const productItemBrandSubBrands =
        productItemBrand && productItemBrand.length
          ? productItemBrand[0].subBrands
          : null;
      const productItemSubBrand =
        productItemBrandSubBrands &&
        productItemBrandSubBrands.items &&
        productItemBrandSubBrands.items.length &&
        productItemBrandSubBrands.items.filter(
          subBrand =>
            item.subBrand &&
            subBrand.name.toLowerCase() === item.subBrand.toLowerCase()
        );
      const productItemSubBrandId =
        productItemSubBrand && productItemSubBrand.length
          ? productItemSubBrand[0].id
          : '';
      const productItemMutationInput = {
        ...item,
        createdAt: moment().toISOString(),
        productItemBrandId,
        productItemSubBrandId,
        productItemCompanyId: companyId
      };

      /**
       * Return object without empty string properties
       * DynamoDB does not support empty string properties, so
       * the input being passed in the mutation must only
       * include properties with values
       */
      for (const prop in productItemMutationInput) {
        if (productItemMutationInput[prop] === '') {
          delete productItemMutationInput[prop];
        } else if (prop === 'brand' || prop === 'subBrand') {
          delete productItemMutationInput[prop];
        }
      }

      return productItemMutationInput;
    });
  };

  removeItemsWithSimilarUICCode = (
    productItems: Array<CreateProductItemInput>
  ) => {
    const uniqueUicCodes = [
      ...new Set(
        productItems.map(item => {
          return item.uic;
        })
      )
    ];
    const itemsWithOutUicCode = productItems.filter(item => {
      if (!item.uic) {
        item.uic = null;
        return item;
      }
      return;
    });
    const uniqueUicProductitems = productItems.filter(item => {
      if (item.uic && uniqueUicCodes.includes(item.uic)) {
        uniqueUicCodes.splice(uniqueUicCodes.indexOf(item.uic), 1);
        return item;
      }
      return;
    });

    return [...uniqueUicProductitems, ...itemsWithOutUicCode];
  };

  render() {
    const { files, error } = this.state;
    const { itemTemplate } = this.props;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <Form
              onSubmit={e => {
                e.preventDefault();
                if (!files.length) {
                  this.setError('Please upload a valid CSV file.');
                } else {
                  /**
                   * `Papa.parse` will return an object with a data property which consists
                   *  of an array of all the rows in the uploaded csv file. Each row is also an array.
                   *  The elements of a row array is determined by the number of columns created
                   * by the separator in the csv file (e.g. a comma `,`).
                   */
                  this.parseFileData(userId, files);
                }
              }}
            >
              <DropzoneContainer>
                <div className="dropzone">
                  {!files.length && (
                    <Dropzone onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>Drop files here...</p>
                            ) : (
                              <p>
                                Drop a file here, or download the sample file
                                below
                              </p>
                            )}
                          </div>
                        );
                      }}
                    </Dropzone>
                  )}
                  {files.map((f: File, i) => (
                    <React.Fragment key={f.size}>
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: '20px',
                          marginBottom: '20px',
                          background: Colors.snow
                        }}
                      >
                        Chosen file: {f.name} - {`${f.size} bytes`}
                      </div>
                    </React.Fragment>
                  ))}
                  <br />
                  {error && <ErrorMessage errorMessage={error} />}
                  <CSVLink
                    data={returnNewProductItemCSVData(itemTemplate)}
                    target="_blank"
                    filename="SampleItems.csv"
                  >
                    <StyledButton
                      type="button"
                      width="10rem"
                      label={[
                        'Sample File',
                        {
                          ...(
                            <MdFileDownload
                              size="1.3em"
                              color={Colors.formWhite}
                            />
                          )
                        }
                      ]}
                      color={Colors.formWhite}
                      background={Colors.grey}
                    />
                  </CSVLink>
                  <StyledButton
                    label="Import"
                    type="submit"
                    className="importFile"
                    background={Colors.flumeDarkGreen}
                  />
                </div>
              </DropzoneContainer>
            </Form>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withApollo(ProductItemsDataImport);
