import * as React from 'react';
import PageWrapper from '../Layouts/PageWrapper';
import LottieWrapper from '../Anim/LottieWrapper';

const ErrorBoundaryFallback: React.FC<{}> = () => {
  return (
    <PageWrapper>
      <LottieWrapper
        width={400}
        height={'auto'}
        loop={true}
        anim={require('../../LottieFiles/me_at_office.json')}
      />
      Oops, looks like something went wrong. Our team has been notified of this
      error and will have it resolved as soon as possible.
    </PageWrapper>
  );
};

export default ErrorBoundaryFallback;
