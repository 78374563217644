import * as React from 'react';
import styled from 'styled-components';

/** Utils */
import { convertPixelsToRem } from '../../Utils/Helpers';

interface IProps {
  image: string;
}

const Logo = styled.img`
  display: inline-block;
  max-width: 100%;
  max-height: ${convertPixelsToRem(70)}
  height: 100%;
  margin-bottom: ${convertPixelsToRem(50)};
`;

const LogoWrapper: React.SFC<IProps> = (props: IProps) => {
  return <Logo src={props.image} />;
};

export default LogoWrapper;
