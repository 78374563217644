import * as React from 'react';
import { Link } from 'react-router-dom';
import StyledButton from '../../Components/Styled/Button';

/* Themes */
import { Colors } from '../../Themes';

/** Presentation/UI */
import {
  PlaceholderContainer,
  PlaceholderIconContainer,
  PlaceholderHeading,
  PlaceholderText
} from '../Styled/Placeholder';

type Props = {
  icon: React.ReactNode;
  title: string;
  text: string;
  buttonLabel: string;
  path?: string;
  buttonLink?: boolean;
  buttonAction?: () => void;
};

const PlaceholderWrapper: React.FC<Props> = props => {
  return (
    <PlaceholderContainer>
      <PlaceholderIconContainer>{props.icon}</PlaceholderIconContainer>
      <br />
      <PlaceholderHeading>{props.title}</PlaceholderHeading>
      <br />
      <PlaceholderText>{props.text}</PlaceholderText>
      <br />
      {props.buttonLink && props.path ? (
        <Link to={props.path}>
          <StyledButton
            type="button"
            width="150px"
            onClick={props.buttonAction}
            label={props.buttonLabel}
            color={Colors.flumeDarkGreen}
            background={Colors.flumeGreen}
          />
        </Link>
      ) : (
        <StyledButton
          type="button"
          width="150px"
          onClick={props.buttonAction}
          label={props.buttonLabel}
          color={Colors.flumeDarkGreen}
          background={Colors.flumeGreen}
        />
      )}
    </PlaceholderContainer>
  );
};

export default PlaceholderWrapper;
