import gql from 'graphql-tag';
import { getUserSpaceFilters } from '../../../Utils/Helpers';

export const getUsersInSpacesQuery = (
  userId: string,
  spaceIds: Array<string>
) => {
  if (spaceIds && spaceIds.length) {
    return gql(`
        query GetUsersInSpaces {
          getUser(id: "${userId}") {
            id
            company {
              id
              users{
                items {
                  id
                  firstName
                  lastName
                  email
                }
              }
              spaces (
                filter: {
                  and: [${getUserSpaceFilters(spaceIds)}]
                }
                ){
                  items {
                    id
                  colorCode
                  name
                  spaceAllocation {
                    items {
                      id
                      date
                      user {
                        id
                        firstName
                        lastName
                        email
                        status
                        userRole {
                          id
                          name
                          permission
                        }
                      }
                    }
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
        }
      `);
  }
  return gql(`
      query GetUsersInSpaces {
        getUser(id: "${userId}") {
          id
          company {
            id
            users {
              items {
                id
                firstName
                lastName
                email
              }
              nextToken
            }
            spaces {
              items {
                id
              colorCode
              name
              spaceAllocation {
                items {
                  id
                  date
                  user {
                    id
                    firstName
                    lastName
                    email
                    status
                    userRole {
                      id
                      name
                      permission
                    }
                  }
                }
                nextToken
              }
              }
              nextToken
            }
          }
        }
      }
    `);
};
