import * as React from 'react';
import styled from 'styled-components';
import * as moment from 'moment';
import { withToastManager } from 'react-toast-notifications';

/** Local components */
import DeleteNote from './DeleteNote';

/** Custom types */
import { ProductItemNoteType, ToastNotificationType } from '../../CustomTypes';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import {
  returnDisplayName,
  returnSortedArrayByDate
} from '../../Utils/Helpers';

const PreviousNotesContainer = styled.div`
  height: 200px;
  overflow: auto;
`;
const AuthorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  & div {
    display: inline-flex;
  }
`;

const Note = styled.div`
  background: ${Colors.lightBlueGrey};
  color: ${Colors.grey};
  padding: 0.375rem 0.75rem;
  border: none !important;
  border-radius: 5px;
`;

const NoteContainer = styled.div`
  margin-bottom: 30px;
`;

const PreviousNotes: React.FC<{
  batchCardId?: string;
  previousNotes: Array<ProductItemNoteType>;
  toastManager: ToastNotificationType;
}> = ({ batchCardId, previousNotes, toastManager }) => {
  /** Return previous notes
   * @param previousNotes - previous notes by users
   */
  const returnPreviousNotesByUsers = (
    userId: string,
    previousUserNotes: Array<ProductItemNoteType>
  ) => {
    return previousUserNotes.map(
      ({ id, author, note, timestamp }: ProductItemNoteType) => {
        return (
          <NoteContainer key={id}>
            <AuthorContainer>
              <div>
                {returnDisplayName(author)} -{' '}
                {moment(timestamp).format('MMMM Do YYYY, h:mm a')}
              </div>
              <div>
                {userId === author.id ? (
                  <DeleteNote
                    batchCardId={batchCardId}
                    id={id}
                    userId={userId}
                    notification={toastNotification}
                  />
                ) : null}
              </div>
            </AuthorContainer>
            <Note>{note}</Note>
          </NoteContainer>
        );
      }
    );
  };

  /** Success notification
   * @param message - message to be displayed on notification
   * @param appearance - notification type
   */
  const toastNotification = (message: string, appearance?: string) => {
    toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  return (
    <UserSpaceContextConsumer>
      {({ userId }) => {
        return (
          <PreviousNotesContainer>
            {returnPreviousNotesByUsers(
              userId,
              returnSortedArrayByDate(previousNotes, 'desc')
            )}
          </PreviousNotesContainer>
        );
      }}
    </UserSpaceContextConsumer>
  );
};

export default withToastManager(PreviousNotes);
