import * as React from 'react';

/** Utils */
import { returnWeek, returnFormattedDates } from '../../Utils/Helpers';

interface IAppContextInterface {
  currentWeekFormattedDates: Array<string>;
}

type Props = {
  children: React.ReactNode;
};

const ctxt = React.createContext<IAppContextInterface>({
  currentWeekFormattedDates: returnFormattedDates(returnWeek(), 'DD MMM YYYY')
});

const DefaultWeekContextProvider = ctxt.Provider;
export const DefaultWeekContextConsumer = ctxt.Consumer;

const Provider: React.FC<Props> = props => {
  return (
    <DefaultWeekContextProvider
      value={{
        currentWeekFormattedDates: returnFormattedDates(
          returnWeek(),
          'DD MMM YYYY'
        )
      }}
    >
      {props.children}
    </DefaultWeekContextProvider>
  );
};

export default Provider;
