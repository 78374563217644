/* eslint no-empty-pattern: 0, array-callback-return: 0 */
import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { MdAddCircle } from 'react-icons/md';
import { IoIosTrash } from 'react-icons/io';

/** GraphQL */
import { createProductItemContent } from '../../graphql/mutations';
import {
  getCompanyBatchCardByUser,
  getCompanyTaggedProductItemsByUser,
  getCompanyCompletedProductItemsByUser
} from '../../graphql/custom-queries';

/** Generated types */
import {
  CreateProductItemNoteMutation,
  CreateProductItemNoteMutationVariables
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';
import SplitWrapper from '../../Components/Styled/SplitWrapper';
import StyledDivider from '../../Components/Styled/Divider';
import EditProductItemText from './EditProductItemText';
import { ImagesWrapper } from '../../Components/Styled/ImageContainer';

/** Custom types */
import {
  Error,
  ModalWithMessageType,
  ProductItemContentType,
  ImageType
} from '../../CustomTypes';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import {
  convertPixelsToRem,
  returnImageFromStorage
} from '../../Utils/Helpers';
import { displayProductItemImages } from './Utils';

type Props = ModalWithMessageType & {
  batchCardId?: string;
  bulkAction?: boolean;
  productItemId: string;
  productItemIds?: Array<string>;
  productItemImageKeys?: Array<string>;
  previousContent: Array<ProductItemContentType>;
  readOnly?: boolean;
  userId: string;
};

type TextType = {
  [index: number]: {
    header: {
      value: string;
      include: boolean;
    };
    text: {
      value: string;
      include: boolean;
    };
  };
};

type InputError = {
  [index: number]: {
    header: string;
    text: string;
  };
};

type State = {
  error: Error;
  text: TextType;
  inputError: InputError;
  totalText: number;
  productItemImages: Array<ImageType>;
};

class AddTextToProductItem extends React.Component<Props, State> {
  timeoutId: number = 0;
  previousContentCount: number =
    Object.keys(this.props.previousContent).length > 0
      ? Object.keys(this.props.previousContent).length + 1
      : 1;
  state = {
    error: null,
    text: {
      [this.previousContentCount]: {
        header: { value: '', include: true },
        text: { value: '', include: true }
      }
    },
    inputError: {
      [this.previousContentCount]: {
        header: '',
        text: ''
      }
    },
    totalText: this.previousContentCount,
    productItemImages: []
  };

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  async componentDidMount() {
    const { productItemImageKeys } = this.props;
    if (productItemImageKeys && productItemImageKeys.length) {
      const productItemImages = await Promise.all(
        productItemImageKeys.map(async (imageKey: string) => {
          const img = await returnImageFromStorage(imageKey);
          const imgUrl = typeof img === 'string' ? img : '';
          return {
            key: imageKey,
            url: imgUrl
          };
        })
      );

      this.setState({ productItemImages });
    }
  }

  handleChange = (
    event: React.FormEvent<HTMLInputElement>,
    contentIndex: string,
    type: string
  ) => {
    const { text } = this.state;
    const { value }: { value: string } = event.currentTarget;

    this.setState((prevState, nextProps) => ({
      text: {
        ...prevState.text,
        [contentIndex]: {
          ...text[contentIndex],
          [type]: {
            ...text[contentIndex][type],
            value
          }
        }
      }
    }));
  };

  /**
   * Add a new header section
   *
   * The logic behind this function is that you can create either a header
   * section or content section in no specified order since they are both optional
   * when either of the sections is created, we increase the totalText count and
   * we in turn check that below so that if the increased count already exists
   * then that means there is an existing section and we just have to change the
   * include attribute to be true
   *
   *
   * @param {React.FormEvent} event
   */
  handleAddHeader = (event: React.FormEvent) => {
    const { totalText, text } = this.state;

    if (!text[totalText].header.include) {
      // content was created before header
      this.setState((prevState, nextProps) => ({
        text: {
          ...prevState.text,
          [totalText]: {
            ...prevState.text[totalText],
            header: { value: '', include: true }
          },
          inputError: {
            ...prevState.inputError,
            [totalText]: {
              header: '',
              text: ''
            }
          }
        }
      }));
    } else {
      this.setState((prevState, nextProps) => ({
        text: {
          ...prevState.text,
          [totalText + 1]: {
            header: { value: '', include: true },
            text: { value: '', include: false }
          }
        },
        inputError: {
          ...prevState.inputError,
          [totalText + 1]: {
            header: '',
            text: ''
          }
        },
        totalText: totalText + 1
      }));
    }
  };

  handleDeleteHeader = (event: React.FormEvent, contentIndex: string) => {
    const { text, inputError } = this.state;

    if (contentIndex === '1') {
      return;
    }

    if (text[contentIndex].footer) {
      // hide input
      this.setState((prevState, nextProps) => ({
        text: {
          ...text,
          [contentIndex]: {
            ...text[contentIndex],
            header: {
              ...text[contentIndex].header,
              include: false
            }
          }
        }
      }));
    } else {
      // delete input if header has already been deleted
      const textCopy = { ...text };
      const errorsCopy = { ...inputError };

      delete textCopy[contentIndex];
      delete errorsCopy[contentIndex];

      this.setState((prevState, nextProps) => ({
        text: textCopy,
        inputError: errorsCopy,
        totalText: prevState.totalText - 1
      }));
    }
  };

  handleAddContent = (event: React.FormEvent) => {
    const { totalText, text } = this.state;

    if (!text[totalText].text.include) {
      // header was created before content
      this.setState((prevState, nextProps) => ({
        text: {
          ...prevState.text,
          [totalText]: {
            ...prevState.text[totalText],
            text: { value: '', include: true }
          }
        },
        inputError: {
          ...prevState.inputError,
          [totalText]: {
            header: '',
            text: ''
          }
        }
      }));
    } else {
      this.setState((prevState, nextProps) => ({
        text: {
          ...prevState.text,
          [totalText + 1]: {
            header: { value: '', include: false },
            text: { value: '', include: true }
          }
        },
        inputError: {
          ...prevState.inputError,
          [totalText + 1]: {
            header: '',
            text: ''
          }
        },
        totalText: totalText + 1
      }));
    }
  };

  handleDeleteContent = (event: React.FormEvent, contentIndex: string) => {
    const { text, inputError } = this.state;

    if (contentIndex === '1') {
      return;
    }

    if (text[contentIndex].header) {
      // hide input
      this.setState((prevState, nextProps) => ({
        text: {
          ...text,
          [contentIndex]: {
            ...text[contentIndex],
            text: {
              ...text[contentIndex].text,
              include: false
            }
          }
        }
      }));
    } else {
      // delete input if header has already been deleted
      const textCopy = { ...text };
      const errorsCopy = { ...inputError };

      delete textCopy[contentIndex];
      delete errorsCopy[contentIndex];

      this.setState((prevState, nextProps) => ({
        text: textCopy,
        inputError: errorsCopy,
        totalText: prevState.totalText - 1
      }));
    }
  };

  /** Execute mutation to add content to a product item(s)
   * @param event - browser event
   * @param productItemTextAuthorId - author id (the logged in user's id)
   * @param createProductItemTextMutation - graphql mutation for creating the content
   * @param productItemId - product item id
   * @param batchCardId - batch card id
   * @param closeModal - function to close modal
   * @param notification - function to display toast message
   * @param bulkAction - flag to determine whether or not it's a bulk action being carried out
   * @param productIds - array of ids for selected product items
   */
  handleSubmit = async (
    event: React.FormEvent,
    productItemTextAuthorId: string,
    createProductItemTextMutation: ({}) => Promise<any>,
    productItemId: string,
    batchCardId: string | undefined,
    closeModal: () => void,
    notification: (message: string, appearance?: string | undefined) => void,
    bulkAction?: boolean,
    productIds?: Array<string>
  ) => {
    event.preventDefault();

    const text = { ...this.state.text };

    if (this.validateForm(text)) {
      const validItems: Array<{
        [index: string]: { value: string; include: boolean };
      }> = [];

      Object.keys(text).map((index: string) => {
        if (text[index].header && text[index].header.include === false) {
          delete text[index].header;
          delete text[index].text;
        }

        validItems.push(text[index]);
      });

      if (bulkAction && productIds && productIds.length) {
        // Add bulk text to multiple product items
        await Promise.all(
          productIds.map(async (ItemId: string) => {
            validItems.map(async item => {
              await createProductItemTextMutation({
                variables: {
                  input: {
                    header: item.header ? item.header.value : null,
                    content: item.text ? item.text.value : null,
                    productItemContentProductId: ItemId,
                    createdAt: new Date().toISOString()
                  }
                },
                refetchQueries: this.returnRefetchQuery(
                  productItemTextAuthorId,
                  batchCardId
                )
              });
              closeModal();
              notification('Item content has been added to the selected items');
            });
          })
        );
      } else {
        try {
          await Promise.all(
            validItems.map(async item => {
              await createProductItemTextMutation({
                variables: {
                  input: {
                    header: item.header ? item.header.value : null,
                    content: item.text ? item.text.value : null,
                    productItemContentProductId: productItemId,
                    createdAt: new Date().toISOString()
                  }
                },
                refetchQueries: this.returnRefetchQuery(
                  productItemTextAuthorId,
                  batchCardId
                )
              });
              closeModal();
              notification(
                'Item content has been added to the selected product item'
              );
            })
          );
        } catch (e) {
          this.setError(e.message);
        }
      }
    }
  };

  /** A product item isn't always associated with a batch card (i.e. tagged item).
   * In the case that a new note has been added to a product item, the refetch query
   * will be based on the tagged items
   * @param userId - id for signed in user
   * @param batchCardId - id for batch that the product item may be related to
   */
  returnRefetchQuery = (userId: string, batchCardId?: string) => {
    return batchCardId && batchCardId !== ''
      ? [
          {
            query: getCompanyBatchCardByUser,
            variables: {
              userId,
              batchCardId
            }
          }
        ]
      : [
          {
            query: getCompanyCompletedProductItemsByUser,
            variables: {
              id: userId
            }
          },
          {
            query: getCompanyTaggedProductItemsByUser,
            variables: {
              id: userId
            }
          }
        ];
  };

  /** Validation */
  validateForm = (text: TextType): boolean => {
    // Check for undefined or empty input fields
    let valid = false;

    Object.keys(text).map((key: string) => {
      if (text[key].header && text[key].header.include) {
        valid = text[key].header.value ? true : false;

        if (!valid) {
          this.setState((prevState, nextProps) => ({
            inputError: {
              ...prevState.inputError,
              [key]: {
                ...prevState.inputError[key],
                header: 'This field is required'
              }
            }
          }));
        }
      }

      if (text[key].text && text[key].text.include && !text[key].text.value) {
        text[key].text.value = null;
      }
    });

    return valid;
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { error, inputError, text, productItemImages } = this.state;
    const {
      closeModal,
      notification,
      productItemId,
      productItemIds,
      previousContent,
      batchCardId,
      bulkAction,
      userId
    } = this.props;

    return (
      <div>
        <Mutation<
          CreateProductItemNoteMutation,
          CreateProductItemNoteMutationVariables
        >
          mutation={gql(createProductItemContent)}
        >
          {(createProductItemNoteMutation, { loading }) => (
            <Form
              onSubmit={event =>
                this.handleSubmit(
                  event,
                  userId,
                  createProductItemNoteMutation,
                  productItemId,
                  batchCardId,
                  closeModal,
                  notification,
                  bulkAction,
                  productItemIds
                )
              }
            >
              <Row>
                <Col>
                  <ImagesWrapper>
                    {displayProductItemImages(productItemImages)}
                  </ImagesWrapper>
                </Col>
                {/* Headers and text */}
                <Col xs={6} md={6} lg={6}>
                  {Object.keys(previousContent).length > 0 && (
                    <React.Fragment>
                      <StyledDivider />
                      <Col>
                        <EditProductItemText
                          content={previousContent}
                          userId={userId}
                          productItemId={productItemId}
                          returnRefetchQuery={this.returnRefetchQuery}
                          batchCardId={batchCardId}
                          notification={notification}
                          closeModal={closeModal}
                        />
                      </Col>
                    </React.Fragment>
                  )}
                  <StyledDivider />
                  {Object.keys(text).map(
                    (contentIndex: string, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <Col>
                            {text[contentIndex].header &&
                              text[contentIndex].header.include && (
                                <FormGroup>
                                  <SplitWrapper>
                                    <Label for={`header-${contentIndex}`}>
                                      Header {contentIndex}:
                                    </Label>
                                    {index >= 1 && (
                                      <IoIosTrash
                                        size={convertPixelsToRem(30)}
                                        color={Colors.pink}
                                        className="pointer-cursor"
                                        onClick={event =>
                                          this.handleDeleteHeader(
                                            event,
                                            contentIndex
                                          )
                                        }
                                        style={{
                                          marginBottom: convertPixelsToRem(5)
                                        }}
                                      />
                                    )}
                                  </SplitWrapper>
                                  <Input
                                    type="text"
                                    name="header"
                                    value={text[contentIndex].header.value}
                                    id={`header-${contentIndex}`}
                                    placeholder={`Header ${contentIndex}`}
                                    className={
                                      inputError[contentIndex].header &&
                                      'input-error'
                                    }
                                    onChange={event =>
                                      this.handleChange(
                                        event,
                                        contentIndex,
                                        'header'
                                      )
                                    }
                                  />
                                  <div
                                    className={
                                      inputError[contentIndex].header
                                        ? 'input-group-error'
                                        : 'hidden'
                                    }
                                  >
                                    <span>
                                      {inputError[contentIndex].header}
                                    </span>
                                  </div>
                                </FormGroup>
                              )}
                            {text[contentIndex].text &&
                              text[contentIndex].text.include && (
                                <FormGroup>
                                  <SplitWrapper>
                                    <Label for={`text-${contentIndex}`}>
                                      Content:
                                    </Label>
                                    {index >= 1 && (
                                      <IoIosTrash
                                        size={convertPixelsToRem(30)}
                                        color={Colors.pink}
                                        className="pointer-cursor"
                                        onClick={event =>
                                          this.handleDeleteHeader(
                                            event,
                                            contentIndex
                                          )
                                        }
                                        style={{
                                          marginBottom: convertPixelsToRem(5)
                                        }}
                                      />
                                    )}
                                  </SplitWrapper>{' '}
                                  <Input
                                    type="textarea"
                                    name="text"
                                    value={text[contentIndex].text.value}
                                    id={`text-${contentIndex}`}
                                    placeholder="Content"
                                    className={
                                      inputError[contentIndex].text &&
                                      'input-error'
                                    }
                                    onChange={event =>
                                      this.handleChange(
                                        event,
                                        contentIndex,
                                        'text'
                                      )
                                    }
                                  />
                                  <div
                                    className={
                                      inputError[contentIndex].text
                                        ? 'input-group-error'
                                        : 'hidden'
                                    }
                                  >
                                    <span>{inputError[contentIndex].text}</span>
                                  </div>
                                </FormGroup>
                              )}
                          </Col>
                        </React.Fragment>
                      );
                    }
                  )}
                  <Row>
                    <Col xs={12} md={6} lg={12}>
                      <p
                        className="pointer-cursor"
                        onClick={this.handleAddHeader}
                      >
                        <MdAddCircle
                          style={{ marginRight: convertPixelsToRem(8) }}
                        />{' '}
                        Add header section
                      </p>
                      <p
                        className="pointer-cursor"
                        onClick={this.handleAddContent}
                      >
                        <MdAddCircle
                          style={{ marginRight: convertPixelsToRem(8) }}
                        />{' '}
                        Add content section
                      </p>
                    </Col>
                    <Col xs={12} md={6} lg={12}>
                      <StyledButton
                        type="submit"
                        disabled={loading}
                        label={
                          loading ? (
                            <Loader color={Colors.flumeGreen} />
                          ) : (
                            'save'
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {error && <ErrorMessage errorMessage={error} />}
            </Form>
          )}
        </Mutation>
      </div>
    );
  }
}

export default AddTextToProductItem;
