import * as React from 'react';
import Helmet from 'react-helmet';
import * as moment from 'moment';

/** Local components */
import QualityControlChart from './QualityControlChart';

/** Utils */
import {
  returnFormattedDates,
  checkForUndefinedObjectValues
} from '../../Utils/Helpers';

/** Custom types */
import { ChartData } from '../../CustomTypes';

/** GraphQL */
import {
  getQCApprovedProductItemsForTheWeekByUser,
  getQCApprovedProductItemsForTheMonthByUser
} from '../../graphql/custom-queries';

type Props = {
  userId: string;
  filter: string;
  filterPeriod: moment.Moment;
  currentWeek: Array<Date>;
  updateChartDataSetOne: (data: ChartData) => void;
  updateChartDataSetTwo: (data: ChartData) => void;
};

const ReportingQualityControl = (props: Props) => {
  const {
    userId,
    filter,
    currentWeek,
    filterPeriod,
    updateChartDataSetOne,
    updateChartDataSetTwo
  } = props;

  const formattedDates = returnFormattedDates(currentWeek, 'YYYY-MM-DD');
  const formattedMonth = moment(filterPeriod).format('YYYY-MM');
  const currentYear = moment(filterPeriod).format('YYYY');

  let qcApprovedItemsQuery = getQCApprovedProductItemsForTheWeekByUser;
  let variables: object = { id: userId };

  if (filter === 'week') {
    variables = {
      ...variables,
      dayOne: formattedDates[0],
      dayTwo: formattedDates[1],
      dayThree: formattedDates[2],
      dayFour: formattedDates[3],
      dayFive: formattedDates[4],
      daySix: formattedDates[5],
      daySeven: formattedDates[6]
    };
  }

  if (filter === 'month') {
    qcApprovedItemsQuery = getQCApprovedProductItemsForTheMonthByUser;
    variables = {
      ...variables,
      month: formattedMonth
    };
  }

  if (filter === 'year') {
    qcApprovedItemsQuery = getQCApprovedProductItemsForTheMonthByUser;

    variables = {
      ...variables,
      month: currentYear
    };
  }

  return (
    <React.Fragment>
      {/*
       * The current week prop has undefined values on initial load which leads
       * to two queries been made, one with undefined values and second with actual values
       * after loading is done, to prevent this we run a check for undefined values
       */}
      {checkForUndefinedObjectValues(variables) && (
        <React.Fragment>
          <QualityControlChart
            query={qcApprovedItemsQuery}
            filter={filter}
            filterPeriod={filterPeriod}
            formattedDates={formattedDates}
            variables={variables}
            title="QC Approved Batch Cards"
            itemsType="withBatchCard"
            updateDataCallback={updateChartDataSetTwo}
          />
          <QualityControlChart
            query={qcApprovedItemsQuery}
            filter={filter}
            filterPeriod={filterPeriod}
            formattedDates={formattedDates}
            variables={variables}
            title="QC Approved Items"
            itemsType="qcApproved"
            updateDataCallback={updateChartDataSetOne}
          />
        </React.Fragment>
      )}
      <Helmet title="Reporting - Quality Control" />
    </React.Fragment>
  );
};

export default ReportingQualityControl;
