import * as React from 'react';
import { Query } from 'react-apollo';

/** GraphQL */
import { getCompanyBatchCardAllocationsForTheWeekByUser } from '../../graphql/custom-queries';

/** Presentation/UI */
import BatchCardInProgress from '../MainPlanner/BatchCardInProgress';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import GlobalModalContainer from '../../Components/Modal';
import QCCalendarWrapper from '../../Components/Layouts/QCCalendarWrapper';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Utils */
import { returnPlaceholderWeek } from '../MainPlanner/QCBatchCardHelpers';
import { returnFormattedDates } from '../../Utils/Helpers';
import CalendarWeekView from './CalendarWeekView';

type Props = {
  currentWeek: Array<Date>;
};

type State = {
  batchCardId: string;
  batchCardModal: boolean;
};

class CalendarView extends React.Component<Props, State> {
  state: State = {
    batchCardId: '',
    batchCardModal: false
  };

  /** Open batch card modal
   * @param batchCardId - id of selected batch card
   */
  openBatchCard = (batchCardId: string) => {
    this.setState({ batchCardId, batchCardModal: true });
  };

  /** Close batch card modal */
  closeBatchCardModal = () => {
    this.setState({ batchCardModal: false, batchCardId: '' });
  };

  render() {
    const { currentWeek } = this.props;
    const { batchCardModal, batchCardId } = this.state;
    const formattedDates = returnFormattedDates(currentWeek, 'DD MMM YYYY');

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <Query
              query={getCompanyBatchCardAllocationsForTheWeekByUser}
              variables={{
                id: userId,
                dayOne: formattedDates[0],
                dayTwo: formattedDates[1],
                dayThree: formattedDates[2],
                dayFour: formattedDates[3],
                dayFive: formattedDates[4],
                daySix: formattedDates[5],
                daySeven: formattedDates[6]
              }}
              fetchPolicy="cache-and-network"
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return (
                    <QCCalendarWrapper>
                      {returnPlaceholderWeek()}
                    </QCCalendarWrapper>
                  );
                }

                if (error || !data) {
                  return <ErrorMessage errorMessage="Error" />;
                }

                return (
                  <QCCalendarWrapper>
                    <GlobalModalContainer
                      toggleModal={this.closeBatchCardModal}
                      largeModal={true}
                      title="Batch Card"
                      modalDisplay={
                        <BatchCardInProgress
                          batchCardId={batchCardId}
                          qualityAssurance={true}
                          userId={userId}
                          closeModal={this.closeBatchCardModal}
                        />
                      }
                      modal={batchCardModal}
                    />
                    <CalendarWeekView
                      currentWeek={currentWeek}
                      data={data}
                      openBatchCard={this.openBatchCard}
                    />
                  </QCCalendarWrapper>
                );
              }}
            </Query>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default CalendarView;
