import * as React from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { withToastManager } from 'react-toast-notifications';
import { Row, Col } from 'reactstrap';
import { Accordion } from 'react-accessible-accordion';
import { RouteComponentProps } from 'react-router-dom';

/** Presentational components */
import CustomWidthContainer from '../../Components/Layouts/CustomWidthContainer';
import GlobalModalContainer from '../../Components/Modal';
import StyledButton from '../../Components/Styled/Button';
import Contact from './Contact';
import AddFAQForm from '../Support/AddFAQForm';
import PageLoader from '../../Components/PageLoader';
import StyledDivider from '../../Components/Styled/Divider';
import {
  StyledAccordionItem,
  StyledAccordionItemHeading,
  StyledAccordionItemButton,
  StyledAccordionItemPanel,
  StyledAccordionHeader
} from '../../Components/Styled/Accordion';
import { StyledTabContainer } from '../../Components/Styled/TabContainers';

/** Helpers */
import { convertPixelsToRem, isOwner } from '../../Utils/Helpers';

/* custom types */
import { Error, ToastNotificationType } from '../../CustomTypes';
import { CreateFaqMutation, CreateFaqMutationVariables } from '../../API';

/** GraphQL */
import { createFaq } from '../../graphql/mutations';
import { listFaqs } from '../../graphql/queries';

type State = {
  addFAQModal: boolean;
  error: Error;
  question: string;
  answer: string;
};

type Props = RouteComponentProps & {
  toastManager: ToastNotificationType;
};

class Support extends React.Component<Props, State> {
  state: State = {
    addFAQModal: false,
    error: null,
    question: '',
    answer: ''
  };

  timeoutId: number = 0;

  /** Open card modal */
  openAddFAQModal = () => {
    this.setState({ addFAQModal: true });
  };

  /** Close card modal */
  closeAddFAQModal = () => {
    this.setState({ addFAQModal: false });
  };

  /** toast notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  /* Handle input change */
  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.currentTarget;

    switch (name) {
      case 'answer':
        this.setState({ answer: value });
        break;
      case 'question':
        this.setState({ question: value });
        break;

      default:
        break;
    }
  };

  /** Validate FAQ - Each faq should have a question and answer */
  validateInputFields = (): boolean => {
    const { question, answer } = this.state;

    const formFieldsFilled = [question, answer].some(value => value === '');

    if (formFieldsFilled && !Object.keys(formFieldsFilled).length) {
      this.setError('Please fill in all the fields');
      return false;
    }

    return true;
  };

  /**
   * display an error
   *
   * @param {string} error - error to display
   *
   * @returns {void}
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  /**
   * Submit form data
   *
   * @param {React.FormEvent} event
   * @param {*} createFaqMutation
   */
  handleSubmit = (event: React.FormEvent, createFaqMutation: any) => {
    event.preventDefault();

    if (this.validateInputFields()) {
      const { question, answer } = this.state;

      createFaqMutation({
        variables: {
          input: {
            question,
            answer
          }
        },
        refetchQueries: [
          {
            query: gql(listFaqs)
          }
        ]
      })
        .then(() => {
          this.toastNotification('FAQ created successfully');
          this.closeAddFAQModal();
        })
        .catch(() => {
          this.toastNotification(
            'There was a problem creating the FAQ',
            'error'
          );
        });
    }
  };

  render() {
    const { error, question, answer, addFAQModal } = this.state;
    const { location } = this.props;

    return (
      <Mutation<CreateFaqMutation, CreateFaqMutationVariables>
        mutation={gql(createFaq)}
      >
        {(createFaqMutation, { loading }) => (
          <React.Fragment>
            <GlobalModalContainer
              toggleModal={this.closeAddFAQModal}
              title="Add new FAQ"
              modalDisplay={
                <React.Fragment>
                  <AddFAQForm
                    loading={loading}
                    error={error}
                    // @ts-ignore
                    handleChange={this.handleChange}
                    handleSubmit={(event: React.FormEvent) =>
                      this.handleSubmit(event, createFaqMutation)
                    }
                    question={question}
                    answer={answer}
                  />
                </React.Fragment>
              }
              modal={addFAQModal}
            />
            <CustomWidthContainer
              width="90%"
              left="5%"
              maxHeight="50vh"
              textAlign="left"
              margin={`${convertPixelsToRem(50)} 0 0 0`}
            >
              <Row
                style={{
                  position: 'relative',
                  height: '100%'
                }}
              >
                <Query query={gql(listFaqs)}>
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <PageLoader />;
                    }
                    if (error) {
                      return <div>There was a problem loading FAQ data</div>;
                    }

                    if (!data || !data.listFaqs) {
                      return <div>There was a problem loading FAQ data</div>;
                    }

                    const { items } = data.listFaqs;

                    return (
                      <StyledTabContainer
                        xs="8"
                        sm="7"
                        md="7"
                        marginleft="-5%"
                        height="80vh"
                      >
                        {isOwner(location.pathname) && (
                          <React.Fragment>
                            <Col
                              xs="12"
                              sm="12"
                              md="12"
                              style={{
                                padding: convertPixelsToRem(15),
                                textAlign: 'right'
                              }}
                            >
                              <StyledButton
                                onClick={this.openAddFAQModal}
                                type="button"
                                label="Add New"
                                width="200px"
                              />
                            </Col>
                            <StyledDivider />
                          </React.Fragment>
                        )}
                        <StyledAccordionHeader>
                          <span>FAQ</span>
                          <span>s</span>
                        </StyledAccordionHeader>
                        <Accordion allowZeroExpanded={true}>
                          {Object.keys(items).length ? (
                            items.map(item => (
                              <StyledAccordionItem key={item.id}>
                                <StyledAccordionItemHeading>
                                  <StyledAccordionItemButton>
                                    {item.question}
                                  </StyledAccordionItemButton>
                                </StyledAccordionItemHeading>
                                <StyledAccordionItemPanel>
                                  <p>{item.answer}</p>
                                </StyledAccordionItemPanel>
                              </StyledAccordionItem>
                            ))
                          ) : (
                            <p>No Faqs have been added yet</p>
                          )}
                        </Accordion>
                      </StyledTabContainer>
                    );
                  }}
                </Query>
                <StyledTabContainer
                  xs="4"
                  sm="4"
                  md="4"
                  marginleft="4%"
                  height="80vh"
                >
                  <Contact />
                </StyledTabContainer>
              </Row>
            </CustomWidthContainer>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

export default withToastManager(Support);
