import * as React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import StripeCheckout from 'react-stripe-checkout';
import { Row, Col, Button } from 'reactstrap';

/** GraphQL */
import { updateCompany } from '../../graphql/mutations';
import { UpdateCompanyMutationVariables } from '../../API';

/** Presentation/UI */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import {
  SubscriptionPlansWrapper,
  SubscriptionPlanCardSubHeading,
  SubscriptionPlanCardPrice,
  SubscriptionPlanCard,
  SubscriptionPlanCardHeading,
  CurrencySymbol
} from '../../Components/Styled/ProductSubscription';

/** Themes */
import { Images, Colors } from '../../Themes';

/** Custom Hooks */
import useErrorHandler from '../../CustomHooks/ErrorHandler';

/** Stripe API Utils */
import {
  STRIPE_PUBLISHABLE_KEY,
  CREATE_CUSTOMER_AND_SUBSCRIPTION
} from '../../Utils/LambdaEndpoints';

/** Utils */
import { HTTP_METHODS, SUBSCRIPTION_PACKAGES } from '../../Utils/Consts';
import { Product, PRODUCT_PLANS } from './Consts';
import { convertPixelsToRem } from '../../Utils/Helpers';

/** API */
import { apiRequest } from '../../Utils/API';

type StripeToken = {
  card: {};
  client_ip: string;
  created: number;
  email: string;
  id: string;
  object: string;
  type: string;
  used: boolean;
  livemode: boolean;
};

type Props = {
  companyId: string;
  updateView(view: string): void;
};

type SubscribeToProductProps = Props & {
  mutate: any;
};

const SubscribeToProduct: React.FC<SubscribeToProductProps> = ({
  companyId,
  mutate,
  updateView
}) => {
  const [loading, setLoading] = React.useState(false);
  const { error, showError } = useErrorHandler(null);

  /**
   * Make request to AWS lambda function that handles creating
   * a customer and a subscription plan on stripe
   * @param token - token with stripe key and details entered in stripe form
   * @param productPlan - the id of the product plan being subscribed to
   * @param subscriptionType - the type of package being subscribed to (Standard or Premium)
   */
  const createStripeAccountAndSubscription = async (
    token: StripeToken,
    productPlan: string,
    subscriptionType: SUBSCRIPTION_PACKAGES
  ) => {
    setLoading(true);

    const bodyParams = {
      stripeToken: token.id,
      email: token.email,
      productPlan
    };

    const response = await apiRequest(
      CREATE_CUSTOMER_AND_SUBSCRIPTION,
      HTTP_METHODS.POST,
      bodyParams
    ).catch(e => {
      setLoading(false);
      showError(e.message);
    });

    if (response && response.id && response.customer) {
      const stripeSubscriptionPlanId = response.id;
      const stripeCustomerId = response.customer;

      mutate({
        variables: {
          input: {
            id: companyId,
            stripeSubscriptionPlanId,
            stripeCustomerId,
            subscriptionType
          }
        }
      })
        .then(() => {
          updateView('success');
        })
        .catch((err: any) => {
          showError(err.message);
        });
    }
  };

  /**
   * List product plans
   * @param productPlans - array of product plans created in Stripe account that a user can subscribe to
   */
  const displayProductPlans = (
    productPlans: Array<Product>
  ): React.ReactNode => {
    if (productPlans && productPlans.length) {
      return productPlans.map((product: Product, i: number) => {
        return (
          <Col xs={12} md={4} lg={4} key={i}>
            <SubscriptionPlanCard>
              <SubscriptionPlanCardHeading>
                {product.name}
              </SubscriptionPlanCardHeading>
              <SubscriptionPlanCardPrice>
                <CurrencySymbol>
                  <sup>$</sup>
                </CurrencySymbol>
                {product.price}
              </SubscriptionPlanCardPrice>
              <SubscriptionPlanCardSubHeading>
                {product.period}
              </SubscriptionPlanCardSubHeading>
              <SubscriptionPlanCardSubHeading
                style={{ height: convertPixelsToRem(180) }}
              >
                {product.description}
              </SubscriptionPlanCardSubHeading>
              <SubscriptionPlanCardSubHeading style={{ borderBottom: 'none' }}>
                {product.users}
              </SubscriptionPlanCardSubHeading>
              <br />
              <br />
              <StripeCheckout
                name="Flume"
                description={product.name}
                image={Images.flumeLogo}
                token={token =>
                  createStripeAccountAndSubscription(
                    token,
                    product.id,
                    product.package
                  )
                }
                billingAddress={true}
                zipCode={true}
                panelLabel="Subscribe"
                stripeKey={STRIPE_PUBLISHABLE_KEY}
              >
                <Button type="button">Select This Plan</Button>
              </StripeCheckout>
            </SubscriptionPlanCard>
          </Col>
        );
      });
    }
    return 'No existing product plans';
  };

  if (loading) {
    return (
      <FullWidthContainer align="center">
        <Loader size={150} color={Colors.flumeGreen} />
      </FullWidthContainer>
    );
  }

  return (
    <SubscriptionPlansWrapper>
      {error && <ErrorMessage errorMessage={error} />}
      <Row>{displayProductPlans(PRODUCT_PLANS)}</Row>
    </SubscriptionPlansWrapper>
  );
};

export default graphql<Props, Response, UpdateCompanyMutationVariables>(
  gql(updateCompany)
)(SubscribeToProduct as any);
