const BASE_URL = 'https://p94p3b872m.execute-api.eu-west-1.amazonaws.com/dev';

/** Stripe publishable key */
export const STRIPE_PUBLISHABLE_KEY = `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`;

/** Stripe AWS lambda function endpoints */
export const CREATE_CUSTOMER_AND_SUBSCRIPTION = `${BASE_URL}/create-customer`;

/** AWS Cognito Pool */

/** Create user and add them to a group */
export const COGNITO_CREATE_USER = `${BASE_URL}/create-user`;

/** Invite a user created with invite set to false */
export const COGNITO_INVITE_USER = `${BASE_URL}/invite-user`;

/** Add a user to a group */
export const COGNITO_ADD_USER_TO_GROUP = `${BASE_URL}/add-user-to-group`;

/** remove a user from a group */
export const COGNITO_REMOVE_USER_FROM_GROUP = `${BASE_URL}/remove-user-from-group`;

/** Confirm new created user */
export const COGNITO_CONFIRM_USER = `${BASE_URL}/confirm-new-user`;

/** Disable user account */
export const COGNITO_DISABLE_USER = `${BASE_URL}/disable-user-account`;

/** Enable user account */
export const COGNITO_ENABLE_USER = `${BASE_URL}/enable-user-account`;

/** Get customer active subscription */
export const CUSTOMER_SUBSCRIPTIONS = `${BASE_URL}/customer-subscriptions`;

/** Cancel customer's active subscription */
export const CANCEL_CUSTOMER_SUBSCRIPTIONS = `${BASE_URL}/cancel-subscription`;

/** Get customer's invoices */
export const CUSTOMER_INVOICES = `${BASE_URL}/customer-subscriptions/invoices`;

/** Add a card */
export const CUSTOMER_ADD_CARD = `${BASE_URL}/add-card`;

/** Delete a card */
export const CUSTOMER_DELETE_CARD = `${BASE_URL}/delete-card`;

/** Get customer data */
export const CUSTOMER_STRIPE_DATA = `${BASE_URL}/customer-data`;

/** Send email */
export const SEND_EMAIL = `${BASE_URL}/send-email`;
