import * as React from 'react';
import { Form, Label, FormGroup, Input } from 'reactstrap';
import { Auth } from 'aws-amplify';

// Presentation/UI
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import LottieWrapper from '../../Components/Anim/LottieWrapper';
import Loader from '../../Components/Loader';
import Button from '../../Components/Styled/Button';

// Custom Types
import { Error } from '../../CustomTypes';

/** Utils */
import { convertPixelsToRem } from '../../Utils/Helpers';

// Themes
import { Colors } from '../../Themes';

type IState = {
  confirmationCode: string;
  loading: boolean;
  error: Error;
  success: boolean;
};

class AccountVerificationForm extends React.Component<{}, IState> {
  state: IState = {
    confirmationCode: '',
    loading: false,
    error: null,
    success: false
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  validateForm = (): boolean => {
    const { confirmationCode } = this.state;

    // Check for undefined or empty input fields
    if (!confirmationCode) {
      this.setError('Please enter the confirmation code');
      return false;
    }
    return true;
  };

  verifyAccount = (confirmationCode: string) => {
    // To verify attribute with the code
    Auth.verifyCurrentUserAttributeSubmit('email', confirmationCode)
      .then(() => {
        this.setState({ loading: false, success: true });
      })
      .catch(e => {
        this.setError('Invalid confirmation code');
        return false;
      });
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error,
        loading: false
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  renderForm = (success: boolean): React.ReactNode => {
    const { confirmationCode, loading } = this.state;

    return (
      <div>
        {success ? (
          <FullWidthContainer align="center">
            <LottieWrapper
              loop={false}
              width={140}
              height={110}
              anim={require('../.././LottieFiles/success1.json')}
            />
            <br />
            Your account has been verified
          </FullWidthContainer>
        ) : (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateForm()) {
                this.setState({ loading: true });
                this.verifyAccount(confirmationCode);
              }
            }}
          >
            <FormGroup>
              <Label for="AccountVerificationCode">
                A verification code has been sent to your email account
              </Label>
              <Input
                type="text"
                name="AccountVerificationCode"
                id="AccountVerificationCode"
                placeholder="Enter verification code"
                onChange={e =>
                  this.setState({ confirmationCode: e.target.value })
                }
              />
            </FormGroup>
            <Button
              type="submit"
              label={!loading ? <span>Verify Account</span> : <Loader />}
              background={Colors.flumeGreen}
            />
            {this.state.error && (
              <p
                style={{
                  textAlign: 'center',
                  color: Colors.error,
                  marginTop: convertPixelsToRem(10)
                }}
              >
                {this.state.error}
              </p>
            )}
          </Form>
        )}
      </div>
    );
  };
  render() {
    const { success } = this.state;
    return <FullWidthContainer>{this.renderForm(success)}</FullWidthContainer>;
  }
}

export default AccountVerificationForm;
