import * as React from 'react';
import styled from 'styled-components';

// Themes
import { Colors } from '../../Themes';
import { convertPixelsToRem } from '../../Utils/Helpers';

type IProps = {
  text: any;
  color?: string;
  pointer?: boolean;
  size?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

type ISpan = {
  color?: string;
  pointer?: boolean;
  size?: string;
  style?: React.CSSProperties;
};

const Span = styled.span`
  color: ${(props: ISpan) => (props.color ? props.color : `${Colors.grey}`)};
  cursor: ${(props: ISpan) => (props.pointer ? 'pointer' : 'auto')};
  font-size: ${(props: ISpan) =>
    props.size ? `${props.size}` : convertPixelsToRem(15)};
`;

const SpanWrapper: React.SFC<IProps> = (props: IProps) => {
  return (
    <Span
      color={props.color}
      size={props.size}
      pointer={props.pointer}
      style={props.style}
      onClick={props.onClick}
    >
      {props.text}
    </Span>
  );
};

export default SpanWrapper;
