import * as React from 'react';
import { Query } from 'react-apollo';

/** GraphQL */
import { listCompaniesCreatedInSelectedMonth } from '../../../../graphql/custom-queries';

/** Presentation/UI */
import ErrorMessage from '../../../../Components/Styled/ErrorMessage';
import Loader from '../../../../Components/Loader';
import CompanyDataAndExport from '../DataAndExport';

/** Themes */
import { Colors } from '../../../../Themes';

/** Utils */
import { getCompanyDataFromKeys } from '../Utils/Helpers';
import { COMPANY_HEADINGS, COMPANY_KEYS } from '../Utils/Consts';

const CompaniesCreatedInSelectedMonth: React.FC<{
  month: string;
}> = ({ month }) => {
  return (
    <Query
      query={listCompaniesCreatedInSelectedMonth}
      variables={{
        month
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loader color={Colors.flumeGreen} />;
        }

        if (error || !data || !data.listCompanys) {
          return (
            <ErrorMessage errorMessage={error ? error.message : 'Error'} />
          );
        }

        const companies = data.listCompanys;

        if (companies && companies.items && companies.items.length) {
          // CSV data of users to be exported
          const csvData = [
            COMPANY_HEADINGS,
            ...getCompanyDataFromKeys(companies.items, COMPANY_KEYS)
          ];

          return (
            <CompanyDataAndExport
              dataTotal={companies.items.length}
              filename="companies.csv"
              csvData={csvData}
            />
          );
        }

        return (
          <CompanyDataAndExport
            dataTotal={0}
            csvData={[]}
            filename="companies.csv"
          />
        );
      }}
    </Query>
  );
};

export default CompaniesCreatedInSelectedMonth;
