/** User headings and keys for data exported to CSV */
export const USER_HEADINGS = [
  'ID',
  'CREATED',
  'EMIAL',
  'FIRST NAME',
  'LAST NAME',
  'PHONE NUMBER',
  'COMPANY',
  'STATUS'
];

export const USER_KEYS = [
  'id',
  'createdAt',
  'email',
  'firstName',
  'lastName',
  'phoneNumber',
  'company',
  'status'
];

/** Company headings and keys for data exported to CSV */
export const COMPANY_HEADINGS = [
  'ID',
  'CREATED',
  'NAME',
  'ADDRESS',
  'STATE',
  'COUNTRY',
  'COMPANY SIZE',
  'SUBSCRIPTION TYPE',
  'TOTAL USERS'
];

export const COMPANY_KEYS = [
  'id',
  'createdAt',
  'name',
  'address',
  'state',
  'country',
  'companySize',
  'subscriptionType',
  'users'
];

/** Company headings and keys for data exported to CSV */
export const SPACE_HEADINGS = ['ID', 'CREATED', 'NAME', 'COMPANY', 'STATUS'];

export const SPACE_KEYS = ['id', 'createdAt', 'name', 'company', 'status'];
