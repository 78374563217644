import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { IoIosTrash } from 'react-icons/io';

/** GraphQL */
import { createBrand, createSubBrand } from '../../graphql/mutations';
import { getCompanyBrandsAndSubBrandsByUser } from '../../graphql/custom-queries';

import {
  CreateBrandMutation,
  CreateBrandMutationVariables,
  CreateSubBrandMutation,
  CreateSubBrandMutationVariables
} from '../../API';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';
import Span from '../../Components/Styled/Span';

/** Themes */
import { Colors } from '../../Themes';

/** Custom Types */
import { Error } from '../../CustomTypes';

/* helper methods */
import { convertPixelsToRem } from '../../Utils/Helpers';

type Props = {
  companyId: string;
  userId: string | null;
  closeModal(): void;
  notification(message: string, appearance?: string): void;
};

type State = {
  name: string;
  subBrands: Array<string>;
  error: Error;
};

class AddBrandModal extends React.Component<Props, State> {
  state: State = {
    name: '',
    subBrands: [],
    error: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Validate Brand */
  validateBrand = (): boolean => {
    const { name } = this.state;

    if (!name) {
      this.setError('Please enter the name of the brand.');
      return false;
    }

    return true;
  };

  /** Validate sub brands */
  validateSubBrand = (): boolean => {
    const { subBrands } = this.state;

    const noNamedSubBrands = subBrands.filter(
      (subBrand: string) => subBrand === ''
    );

    if (noNamedSubBrands && noNamedSubBrands.length) {
      this.setError('Please name every sub brand.');
      return false;
    }

    return true;
  };

  /** Add a sub brand */
  addSubBrand = (): void => {
    this.setState({
      subBrands: [...this.state.subBrands, '']
    });
  };

  /** Update sub brand
   * @param id - unique identifier (based on index) for sub brand
   * @param name - text for sub brand
   */
  updateSubBrand = (id: number, name: string): void => {
    const { subBrands } = this.state;
    const updateSubBrands = subBrands.map((subBrand: string, i) => {
      if (id === i) {
        subBrand = name;
      }
      return subBrand;
    });
    this.setState({ subBrands: updateSubBrands });
  };

  /**
   * Delete sub brand
   * @param id - unique id for specific sub brand row to be deleted
   */
  deleteRow = (id: number): void => {
    const subBrands = this.state.subBrands.filter(
      (subBrand: string, i) => i !== id
    );
    this.setState({ subBrands });
  };

  /** Render sub brands */
  renderSubBrands = (): React.ReactNode | null => {
    const { subBrands } = this.state;

    if (subBrands.length) {
      return subBrands.map((subBrand: string, i) => {
        return (
          <Row key={i}>
            <Col xs={12} md={10} lg={10}>
              <FormGroup>
                <Input
                  type="text"
                  value={subBrand}
                  placeholder="Sub brand"
                  onChange={e => this.updateSubBrand(i, e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col className="display-flex" xs={12} md={2} lg={2}>
              <FormGroup className="align-items-center">
                <Label />
                <IoIosTrash
                  viewBox="0 60 512 512"
                  size={convertPixelsToRem(30)}
                  color={Colors.pink}
                  onClick={() => this.deleteRow(i)}
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    top: convertPixelsToRem(4)
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        );
      });
    }

    return null;
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { name, subBrands, error } = this.state;
    const { companyId, userId, notification, closeModal } = this.props;

    return (
      <Mutation<CreateBrandMutation, CreateBrandMutationVariables>
        mutation={gql(createBrand)}
      >
        {(createBrandMutation, brandMutation) => (
          <Mutation<CreateSubBrandMutation, CreateSubBrandMutationVariables>
            mutation={gql(createSubBrand)}
          >
            {(createSubBrandMutation, subBrandMutation) => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  if (this.validateBrand()) {
                    // Start by creating brand
                    createBrandMutation({
                      variables: {
                        input: {
                          name,
                          brandCompanyId: companyId
                        }
                      },
                      refetchQueries: [
                        {
                          query: getCompanyBrandsAndSubBrandsByUser,
                          variables: {
                            id: userId
                          }
                        }
                      ]
                    })
                      .then((res: any) => {
                        notification('Brand created successfully');
                        const brandId = res.data.createBrand.id;
                        /**
                         * If sub brands were dynamically added to the form, validate
                         * and then create them in relation to the newly created brand
                         */
                        if (subBrands.length && this.validateSubBrand()) {
                          subBrands.forEach(subBrand => {
                            createSubBrandMutation({
                              variables: {
                                input: {
                                  name: subBrand,
                                  subBrandBrandId: brandId
                                }
                              },
                              refetchQueries: [
                                {
                                  query: getCompanyBrandsAndSubBrandsByUser,
                                  variables: {
                                    id: userId
                                  }
                                }
                              ]
                            })
                              .then(() => {
                                notification('Sub brand created successfully');
                              })
                              .catch(() => {
                                notification(
                                  'There was a problem creating a sub brand',
                                  'error'
                                );
                              });
                          });
                          closeModal();
                        } else {
                          closeModal();
                        }
                      })
                      .catch(err => {
                        this.setState({
                          name: '',
                          subBrands: []
                        });
                        this.setError(err.message);
                      });
                  }
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <Label for="name">Brand Name</Label>
                      <Input
                        type="text"
                        name="name"
                        value={name}
                        id="name"
                        placeholder="Brand name"
                        onChange={e => this.setState({ name: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <React.Fragment>{this.renderSubBrands()}</React.Fragment>
                <br />
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <FaPlusCircle size="1.15em" />
                      &nbsp;&nbsp;
                      <Span
                        onClick={this.addSubBrand}
                        pointer={true}
                        text="Add Sub Brand"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <StyledButton
                        type="submit"
                        label={
                          brandMutation.loading || subBrandMutation.loading ? (
                            <Loader />
                          ) : (
                            'Create'
                          )
                        }
                        color={Colors.flumeDarkGreen}
                        background={Colors.flumeGreen}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {error && <ErrorMessage errorMessage={error} />}
              </Form>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default AddBrandModal;
