import * as React from 'react';

import { CUSTOMER_INVOICES } from '../../../Utils/LambdaEndpoints';
import { HTTP_METHODS } from '../../../Utils/Consts';

/** API */
import { apiRequest } from '../../../Utils/API';

/** Retrieve all customer invoices from Stripe
 * @param setLoading
 * @param showError
 */
export const getCustomerInvoices = async (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  showError: (errorMessage: string | null) => void
) => {
  setLoading(true);
  const response = await apiRequest(CUSTOMER_INVOICES, HTTP_METHODS.GET).catch(
    e => {
      setLoading(false);
      showError(e.message);
    }
  );

  if (response && response.data && response.data.length) {
    setLoading(false);
    const customerInvoices = response.data;
    return customerInvoices.map(
      ({
        id,
        customer_email,
        created,
        invoice_pdf,
        total,
        paid,
        number,
        status
      }) => {
        return {
          id,
          customer_email,
          created,
          invoice_pdf,
          total,
          paid,
          number,
          status
        };
      }
    );
  }
  return [];
};
