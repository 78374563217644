import * as React from 'react';
import * as validator from 'validator';
import { Form, Label, FormGroup, Input } from 'reactstrap';
import { Auth } from 'aws-amplify';

// Presentation/UI
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import LottieWrapper from '../../Components/Anim/LottieWrapper';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import Button from '../../Components/Styled/Button';

// Custom Types
import {
  ConfirmationCodeError,
  ForgotPasswordViews,
  Error
} from '../../CustomTypes';

// Themes
import { Colors } from '../../Themes';

type IProps = {
  closeModal: () => void;
};

type IState = {
  view: ForgotPasswordViews;
  email: string;
  password: string;
  passwordConfirmation: string;
  confirmationCode: string;
  loading: boolean;
  error: Error;
  confirmationCodeError: ConfirmationCodeError | null;
  forgotPasswordError: Error;
};

class ForgotPasswordForm extends React.Component<IProps, IState> {
  state: IState = {
    view: 'forgotPassword',
    email: '',
    password: '',
    passwordConfirmation: '',
    confirmationCode: '',
    loading: false,
    error: null,
    confirmationCodeError: null,
    forgotPasswordError: null
  };

  timeoutId: number = 0;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  validateEmailForm = (): boolean => {
    const { email } = this.state;

    // Validate email
    if (!validator.isEmail(email)) {
      this.setError('Please enter a valid email address.');
      return false;
    }

    return true;
  };

  validateNewPasswordForm = (): boolean => {
    const { confirmationCode, password, passwordConfirmation } = this.state;
    // Check for undefined or empty input fields
    if (!confirmationCode || !password) {
      this.setError(
        'Please enter a valid new password and the correct confirmation code'
      );
      return false;
    }

    if (password !== passwordConfirmation) {
      this.setError('Your password needs to match');
      return false;
    }

    return true;
  };

  // Error
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  // Handle forgot password
  forgotPassword = (username: string): void => {
    this.setState({ loading: true });
    Auth.forgotPassword(username)
      .then(data => {
        this.setState({ loading: false, view: 'newPassword' });
      })
      .catch(err => {
        this.setState({ forgotPasswordError: err.message, loading: false });
      });
  };

  // Handle new password and confirmation code
  confirmForgotPassword = (
    username: string,
    confirmationCode: string,
    newPassword: string
  ): void => {
    Auth.forgotPasswordSubmit(username, confirmationCode, newPassword)
      .then(data => {
        this.setState({ loading: false, view: 'success' });
      })
      .catch(err => {
        this.setState({ confirmationCodeError: err, loading: false });
      });
  };

  renderForm = (view: ForgotPasswordViews): React.ReactNode => {
    const {
      loading,
      error,
      email,
      confirmationCode,
      password,
      passwordConfirmation,
      forgotPasswordError,
      confirmationCodeError
    } = this.state;

    switch (view) {
      case 'forgotPassword':
        return (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateEmailForm()) {
                this.setState({ loading: true });
                this.forgotPassword(email);
              }
            }}
          >
            <FormGroup>
              <Label for="email">Email Address</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={email.toLowerCase()}
                placeholder="john@mail.com"
                onChange={e => this.setState({ email: e.target.value })}
              />
            </FormGroup>
            <Button
              type="submit"
              label={!loading ? <span>Send</span> : <Loader />}
              background={Colors.flumeGreen}
            />
            {error && <ErrorMessage errorMessage={error} />}
            {forgotPasswordError && (
              <ErrorMessage errorMessage={forgotPasswordError} />
            )}
          </Form>
        );
      case 'newPassword':
        return (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateNewPasswordForm()) {
                this.setState({ loading: true });
                this.confirmForgotPassword(email, confirmationCode, password);
              }
            }}
          >
            <FormGroup>
              <Label for="confirmationCode">Enter Confirmation Code</Label>
              <Input
                type="text"
                name="confirmationCode"
                id="confirmationCode"
                value={confirmationCode}
                placeholder="Confirmation code"
                onChange={e =>
                  this.setState({ confirmationCode: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="userPassword">New Password</Label>
              <Input
                type="password"
                name="password"
                value={password}
                id="userPassword"
                placeholder="Password"
                onChange={e => this.setState({ password: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="userpasswordConfirmation">Confirm New Password</Label>
              <Input
                type="password"
                name="passwordConfirmation"
                value={passwordConfirmation}
                id="userpasswordConfirmation"
                placeholder="Confirm Password"
                onChange={e =>
                  this.setState({ passwordConfirmation: e.target.value })
                }
              />
            </FormGroup>
            <Button
              type="submit"
              label={!loading ? <span>Update Password</span> : <Loader />}
              background={Colors.flumeGreen}
            />
            {error && <ErrorMessage errorMessage={error} />}
            {confirmationCodeError && (
              <ErrorMessage errorMessage={confirmationCodeError.message} />
            )}
          </Form>
        );
      case 'success':
        return (
          <FullWidthContainer align="center">
            <LottieWrapper
              loop={false}
              width={140}
              height={110}
              anim={require('../../LottieFiles/success1.json')}
            />
            <br />
            Password reset successful
          </FullWidthContainer>
        );
      default:
        return (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateEmailForm()) {
                this.forgotPassword(email);
              }
            }}
          >
            <FormGroup>
              <Label for="email">Email Address</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={email}
                placeholder="john@mail.com"
                onChange={e => this.setState({ email: e.target.value })}
              />
            </FormGroup>
            <Button
              type="submit"
              label={!loading ? <span>Send</span> : <Loader />}
              background={Colors.flumeGreen}
            />
            {error && <ErrorMessage errorMessage={error} />}
            {forgotPasswordError && (
              <ErrorMessage errorMessage={forgotPasswordError} />
            )}
          </Form>
        );
    }
  };

  render() {
    const { view } = this.state;
    return <FullWidthContainer>{this.renderForm(view)}</FullWidthContainer>;
  }
}

export default ForgotPasswordForm;
