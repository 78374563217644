import * as React from 'react';
import Lottie from 'react-lottie';

interface IProps {
  anim: object;
  loop: boolean;
  width: number | string;
  height: number | string;
}

export default class LottieControl extends React.Component<IProps> {
  render() {
    const { loop, anim, width, height } = this.props;
    const options = {
      loop,
      autoplay: true,
      animationData: anim,
      rendererSettings: {
        preserveAspectRatio: true
      } as any
    };
    return <Lottie options={options} height={height} width={width} />;
  }
}
