import * as React from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Helmet } from 'react-helmet';
import { IoIosStar } from 'react-icons/io';
import { withToastManager } from 'react-toast-notifications';
import { Form } from 'reactstrap';
import * as uuid from 'uuid';

/** GraphQL */
import { getCompanyServicesByUser } from '../../graphql/custom-queries';
import { deleteService } from '../../graphql/mutations';

import {
  DeleteServiceMutation,
  DeleteServiceMutationVariables
} from '../../API';

/** Context API */
import { UserSpaceContextConsumer } from '../../Components/UserSpaceContextProvider';

/** Presentation/UI */
import PlaceholderWrapper from '../../Components/Layouts/PlaceholderWrapper';
import BackendWrapper from '../../Components/Layouts/BackendWrapper';
import GlobalModalContainer from '../../Components/Modal';
import DialogModal from '../../Components/DialogModal';
import Table from '../../Components/Table';
import PageLoader from '../../Components/PageLoader';
import StyledButton from '../../Components/Styled/Button';

import {
  TableHeader,
  TableHeaderContainer
} from '../../Components/Styled/ListViewElements';

/** Local components */
import AddServiceModal from './AddServiceModal';
import EditServiceModal from './EditServiceModal';
import RowActions from './RowActions';

/** Themes */
import { Colors } from '../../Themes';

/** Utils */
import { SERVICES_DESCRIPTION, defaultService } from '../../Utils/Consts';
import {
  convertPixelsToRem,
  sortArrayAlphabetically
} from '../../Utils/Helpers';

/** Custom Types */
import { ToastNotificationType, ServiceType } from '../../CustomTypes';

type Props = {
  toastManager: ToastNotificationType;
};

type State = {
  serviceId: string;
  modal: boolean;
  editModal: boolean;
  dialogModal: boolean;
  searchFilter: boolean;
  service: ServiceType;
};

class Services extends React.Component<Props, State> {
  state: State = {
    serviceId: '',
    modal: false,
    editModal: false,
    dialogModal: false,
    searchFilter: false,
    service: defaultService
  };

  toggleSearchFilter = (): void => {
    this.setState({ searchFilter: !this.state.searchFilter });
  };

  /** Close dialog modal */
  closeDialogModal = (): void => {
    this.setState({
      dialogModal: false
    });
  };

  /** Open dialog modal */
  openDialogModal = (service: ServiceType): void => {
    const serviceId = !service ? '' : service.id;
    this.setState({
      dialogModal: true,
      service,
      serviceId
    });
  };

  /** Close edit modal */
  closeEditModal = (): void => {
    this.setState({
      editModal: false,
      service: defaultService
    });
  };

  /** Open edit modal */
  openEditModal = (service: ServiceType): void => {
    this.setState({
      editModal: true,
      service
    });
  };

  /** Open modal */
  openModal = (): void => {
    this.setState({
      modal: true
    });
  };

  /** Close modal */
  closeModal = (): void => {
    this.setState({
      modal: false
    });
  };

  /** Success notification */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const { dialogModal, modal, editModal, serviceId, service } = this.state;

    return (
      <UserSpaceContextConsumer>
        {({ userId }) => {
          return (
            <BackendWrapper>
              <Query
                query={getCompanyServicesByUser}
                variables={{ id: userId }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return <PageLoader />;
                  }
                  if (error) {
                    return (
                      <div>There was a problem loading your company data</div>
                    );
                  }

                  if (!data || !data.getUser) {
                    return (
                      <div>There was a problem loading your company data</div>
                    );
                  }

                  const company = data.getUser.company;
                  const companyId = data.getUser.company.id;

                  if (
                    company.services &&
                    company.services.items &&
                    company.services.items.length
                  ) {
                    const createdCompanyServices = sortArrayAlphabetically(
                      company.services.items
                    );

                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeModal}
                          title="Create Service"
                          modalDisplay={
                            <AddServiceModal
                              companyId={companyId}
                              userId={userId}
                              closeModal={this.closeModal}
                              notification={this.toastNotification}
                            />
                          }
                          modal={modal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeEditModal}
                          title="Edit Service"
                          modalDisplay={
                            <EditServiceModal
                              companyId={companyId}
                              userId={userId}
                              closeModal={this.closeEditModal}
                              notification={this.toastNotification}
                              service={service}
                            />
                          }
                          modal={editModal}
                        />
                        <GlobalModalContainer
                          toggleModal={this.closeDialogModal}
                          title=""
                          modalDisplay={
                            <Mutation<
                              DeleteServiceMutation,
                              DeleteServiceMutationVariables
                            >
                              mutation={gql(deleteService)}
                            >
                              {(deleteServiceMutation, deleteMutation) => (
                                <Form
                                  onSubmit={e => {
                                    e.preventDefault();

                                    deleteServiceMutation({
                                      variables: {
                                        input: {
                                          id: serviceId
                                        }
                                      },
                                      refetchQueries: [
                                        {
                                          query: getCompanyServicesByUser,
                                          variables: {
                                            id: userId
                                          }
                                        }
                                      ]
                                    })
                                      .then(res => {
                                        this.closeDialogModal();
                                        this.toastNotification(
                                          'Service delete successfully',
                                          'success'
                                        );
                                      })
                                      .catch(err => {
                                        this.toastNotification(
                                          'Sorry, there was a problem deleting the space',
                                          'error'
                                        );
                                      });
                                  }}
                                >
                                  <DialogModal
                                    loading={deleteMutation.loading}
                                    title="Are you sure you want to delete this service?"
                                    toggleModal={this.closeDialogModal}
                                  />
                                </Form>
                              )}
                            </Mutation>
                          }
                          modal={dialogModal}
                        />
                        <TableHeaderContainer>
                          <TableHeader>
                            <span>Manage Services</span>
                          </TableHeader>
                          <StyledButton
                            type="button"
                            label="Add Service"
                            width="120px"
                            color={Colors.flumeDarkGreen}
                            background={Colors.flumeGreen}
                            onClick={this.openModal}
                          />
                        </TableHeaderContainer>
                        <Table
                          data={createdCompanyServices}
                          columns={[
                            {
                              id: uuid(),
                              Header: '',
                              accessor: (companyService: ServiceType) => {
                                return (
                                  <div
                                    style={{
                                      backgroundColor: Colors.snow
                                    }}
                                  >
                                    <img
                                      style={{
                                        maxHeight: convertPixelsToRem(32),
                                        maxWidth: convertPixelsToRem(32),
                                        width: '100%',
                                        zoom: '2'
                                      }}
                                      src={companyService.icon}
                                      alt="serviceIcon"
                                    />
                                  </div>
                                );
                              },
                              sortable: false,
                              filterable: false,
                              width: 100
                            },
                            {
                              Header: 'Service Name',
                              accessor: 'name',
                              sortable: false,
                              filterable: false
                            },
                            {
                              id: uuid(),
                              Header: '',
                              accessor: (companyService: ServiceType) => {
                                return (
                                  <RowActions
                                    openModal={this.openEditModal}
                                    openDialogModal={this.openDialogModal}
                                    service={companyService}
                                  />
                                );
                              },
                              sortable: false,
                              filterable: false,
                              width: 30
                            }
                          ]}
                          defaultPageSize={5}
                          showPaginationTop={false}
                          showPaginationBottom={true}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <GlobalModalContainer
                          toggleModal={this.closeModal}
                          title="Create Service"
                          modalDisplay={
                            <AddServiceModal
                              companyId={companyId}
                              userId={userId}
                              closeModal={this.closeModal}
                              notification={this.toastNotification}
                            />
                          }
                          modal={modal}
                        />
                        <PlaceholderWrapper
                          icon={
                            <IoIosStar size="8.5em" color={Colors.flumeGreen} />
                          }
                          title="Create your Services"
                          text={SERVICES_DESCRIPTION}
                          buttonLabel="Add Service"
                          buttonAction={this.openModal}
                        />
                      </div>
                    );
                  }
                }}
              </Query>
              <Helmet title="Manage Services" />
            </BackendWrapper>
          );
        }}
      </UserSpaceContextConsumer>
    );
  }
}

export default withToastManager(Services);
