import { HTTP_METHODS } from './Consts';
import { AUTH_USER_TOKEN_KEY } from './LocalStorage';

/**
 * API Request
 * @param endPoint - api endpoint
 * @param httpMethod - the http method defining the type of request (POST/GET/PUT/PATCH)
 * @param bodyParams - object with properties being passed with the request
 */

export const apiRequest = async (
  endPoint: string,
  httpMethod: string,
  bodyParams?: object
): Promise<any> => {
  let response;
  const token = window.localStorage.getItem(AUTH_USER_TOKEN_KEY);

  if (httpMethod === HTTP_METHODS.GET || httpMethod === HTTP_METHODS.DELETE) {
    response = await fetch(endPoint, {
      method: httpMethod,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
  } else {
    response = await fetch(endPoint, {
      method: httpMethod,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyParams)
    });
  }

  return await response.json();
};
