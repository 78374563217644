import styled from 'styled-components';

/** Themes */
import { Colors } from '../../Themes';

/** Helpers */
import { convertPixelsToRem } from '../../Utils/Helpers';

export const BatchCardContainer = styled.div`
  background: ${Colors.snow};
  border-radius: 5px;
  cursor: pointer;
  height: ${convertPixelsToRem(160)};
  box-shadow: 0 2px 5px 0 rgba(176, 31, 31, 0.02);
  width: calc(100% - 20px);
  margin: 0 auto;
  margin-top: 10px;
  padding: 9px;
  & span {
    color: ${Colors.lightGray};
  }
`;

export const BatchCardHeading = styled.h3`
  text-transform: uppercase;
  font-size: 1em;
  letter-spaceing: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const BatchCardSpaceIdentifier = styled.div`
  width: 100%;
  height: 7px;
  border-radius: 3px;
  margin-top: ${convertPixelsToRem(10)};
  background: ${(props: { background?: string }) =>
    props.background ? props.background : Colors.paleCoal};
`;

export const CalendarControls = styled.div`
  margin: 0px 20px 20px 15px;
  margin-bottom: 20px;
  display: inline-flex;
  & .select-styling > div {
    width: 200px;
    margin-right: 20px;
  }
`;

export const ProductActionsContainer = styled.div`
  display: inline-flex;
  & .select-styling > div {
    width: 200px;
  }
`;

export const SelectedBatchCardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: ${Colors.snow};
  & img {
    width: 20px;
  }
  & svg {
    color: ${Colors.lightGray};
  }
`;

export const Wrapper = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  height: 100%;
`;

export const DayContainer = styled.div`
  overflow-y: hidden;
  width: 230px;
  background: ${Colors.transparent};
  border-radius: 4px;
  padding-bottom: 20px;
  margin-right: 10px;
  float: none;
  display: inline-flex;
  flex-direction: column;
`;

export const DayWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: background-color 0.2s ease;
  flex-grow: 1;
  background-color: ${(props: { isDraggingOver: boolean }) =>
    props.isDraggingOver ? Colors.flumeGreen : 'rgba(204, 204, 204, 0.2)'};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
  border-radius: 4px;
`;

export const DayHeader = styled.div`
  color: #666;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: ${Colors.snow};
  letter-spacing: 1px;
  font-size: 0.88em;
  text-transform: capitalize;
  text-align: left;
  border-bottom: 1px solid ${Colors.paleCoal};
  padding: 10px;
  display: flex;
  align-items: center;
  height: ${(props: { height?: string }) =>
    props.height ? props.height : 'auto'};
  justify-content: space-between;
  & span.day-header {
    font-size: 1.28em;
    color: ${Colors.grey};
  }
  & span.space-header {
    font-size: 1.28em;
    color: ${Colors.grey};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
  }
  & span.date-header {
    color: ${Colors.lightGray};
    text-transform: uppercase;
  }
  & div {
    display: inline-flex;
  }
  & .add-members-to-space {
    cursor: pointer;
  }
`;

export const HeaderWrapper = styled.div`
  height: 70px;
`;

export const ViewWrapper = styled.div`
  height: 1130px;
`;

export const ToggleWeekContainer = styled.div`
  width: 200px;
  height: 40px;
  padding: 8px;
  border-radius: 5px;
  background: ${Colors.lightBlueGrey};
  display: inline-flex;
  margin: 0px 10px 0px 0px;
`;

export const SelectContainer = styled.div`
  display: inline-flex;
  width: 200px;
  height: 40px;
  & .select-styling {
    width: 100%;
    margin-left: 10px;
  }
`;

export const ArrowContainer = styled.div`
  flex: 1;
  text-align: center;
  cursor: pointer;
`;

export const DateContainer = styled.div`
  flex: 5;
  text-align: center;
  cursor: pointer;
`;

export const UserInitials = styled.span`
  width: 22px;
  height: 22px;
  padding: 3px;
  margin: 2px;
  font-size: 0.85em;
  display: inline-block !important;
  text-align: center;
  border-radius: 50%;
  background: ${Colors.flumeGreen};
  color: ${Colors.flumeDarkGreen};
`;

export const UserInitialsWrapper = styled.div`
  display: initial;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
`;

export const IconsContainer = styled.div`
  display: inline-flex;
  & div {
    margin-right: 10px;
  }
  margin-top: 5px;
  margin-bottom: 10px;
`;
