import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Select from 'react-select';
import { Row, Col, Form, Label, FormGroup, Input } from 'reactstrap';

/** GraphQL */
import { updateRole } from '../../graphql/mutations';
import { getCompany } from '../../graphql/queries';
import {
  UpdateRoleInput,
  UpdateRoleMutation,
  UpdateRoleMutationVariables,
  Permission
} from '../../API';

/** Utils */
import { USER_ROLES, USER_PERMISSIONS } from '../../Utils/Consts';

/** Custom Types */
import { Error } from '../../CustomTypes';

/** Presentation/UI */
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import StyledButton from '../../Components/Styled/Button';

/** Themes */
import { Colors } from '../../Themes';

type SelectType = { value: Permission; label: string };

type Props = {
  companyId: string;
  role: UpdateRoleInput;
  closeModal(): void;
  notification(message: string): void;
};

type State = {
  id: string;
  roleName: string;
  rolePermission: SelectType;
  error: Error;
};

class EditRoleModal extends React.Component<Props, State> {
  timeoutId: number = 0;
  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.role.id || '',
      roleName: props.role.name || '',
      rolePermission: !props.role.permission
        ? { value: Permission.USER, label: 'User' }
        : {
            value: props.role.permission,
            label: this.returnUserRole(props.role.permission)
          },
      error: null
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /** Return user roles */
  returnUserRole = (role: Permission | null | undefined): string => {
    switch (role) {
      case USER_ROLES.nonUser:
        return 'Non User';
      case USER_ROLES.user:
        return 'User';
      case USER_ROLES.superUser:
        return 'Super User';
      default:
        return 'Non-User';
    }
  };

  /** Validation */
  validateForm = (): boolean => {
    // Check for undefined or empty input fields
    if (!this.state.roleName) {
      this.setError('Please complete the form.');
      return false;
    }

    return true;
  };

  /** Select permission
   * @param rolePermission - user permission for the role being created
   */
  selectPermission = (rolePermission: SelectType): void => {
    this.setState({ rolePermission });
  };

  /** Error */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { id, roleName, rolePermission, error } = this.state;
    const { notification, closeModal, companyId } = this.props;
    const name = !roleName ? '' : roleName;

    return (
      <Mutation<UpdateRoleMutation, UpdateRoleMutationVariables>
        mutation={gql(updateRole)}
      >
        {(updateRoleMutation, { loading }) => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.validateForm()) {
                updateRoleMutation({
                  variables: {
                    input: {
                      id,
                      name: roleName,
                      permission: rolePermission.value
                    }
                  },
                  refetchQueries: [
                    {
                      query: gql(getCompany),
                      variables: {
                        id: companyId
                      }
                    }
                  ]
                })
                  .then(res => {
                    closeModal();
                    notification('Role updated successfully');
                  })
                  .catch(err => {
                    this.setError(err.message);
                  });
              }
            }}
          >
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="roleName">Role Name</Label>
                  <Input
                    type="text"
                    name="roleName"
                    value={name}
                    id="roleName"
                    placeholder="Role name"
                    onChange={e => this.setState({ roleName: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="permissionType">Permission Type</Label>
                  <Select
                    // @ts-ignore
                    onChange={this.selectPermission}
                    options={USER_PERMISSIONS}
                    value={rolePermission}
                    isSearchable={true}
                    className="select-styling"
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <StyledButton
                    type="submit"
                    label={!loading ? 'Update' : <Loader />}
                    color={Colors.flumeDarkGreen}
                    background={Colors.flumeGreen}
                  />
                </FormGroup>
              </Col>
            </Row>
            {error && <ErrorMessage errorMessage={error} />}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default EditRoleModal;
