// tslint:disable
// this is an auto generated file. This will be overwritten

export const getTask = `query GetTask($id: ID!) {
  getTask(id: $id) {
    id
    title
    description
    status
    assignedTo {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
  }
}
`;
export const listTasks = `query ListTasks(
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      status
      assignedTo {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
    }
    nextToken
  }
}
`;
export const getPrivateNote = `query GetPrivateNote($id: ID!) {
  getPrivateNote(id: $id) {
    id
    content
    author {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
  }
}
`;
export const listPrivateNotes = `query ListPrivateNotes(
  $filter: ModelPrivateNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrivateNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      content
      author {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
    }
    nextToken
  }
}
`;
export const getCompany = `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    createdAt
    name
    address
    state
    industry
    country
    companySize
    stripeCustomerId
    stripeSubscriptionPlanId
    subscriptionType
    accountDisabled
    itemTemplate {
      id
      uic
      brand
      subBrand
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      season
      collectionName
      productName
      quantity
      clientName
      serviceName
      unit
      itemTemplateType
      companies {
        nextToken
      }
    }
    roles {
      items {
        id
        name
        permission
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
      nextToken
    }
    spaces {
      items {
        id
        createdAt
        name
        colorCode
        status
      }
      nextToken
    }
    brands {
      items {
        id
        name
      }
      nextToken
    }
    services {
      items {
        id
        name
        icon
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
    batchCards {
      items {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      nextToken
    }
  }
}
`;
export const listCompanys = `query ListCompanys(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    createdAt
    firstName
    lastName
    email
    phoneNumber
    avatarKey
    avatarUrl
    archived
    verified
    inviteLater
    inviteAccepted
    invitedBy
    status
    userRole {
      id
      name
      permission
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      users {
        nextToken
      }
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    spaceAllocation {
      items {
        id
        date
        createdAt
      }
      nextToken
    }
    productItemNotes {
      items {
        id
        note
        timestamp
      }
      nextToken
    }
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getUserAllocation = `query GetUserAllocation($id: ID!) {
  getUserAllocation(id: $id) {
    id
    date
    createdAt
    user {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
  }
}
`;
export const listUserAllocations = `query ListUserAllocations(
  $filter: ModelUserAllocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserAllocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      createdAt
      user {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
      space {
        id
        createdAt
        name
        colorCode
        status
      }
    }
    nextToken
  }
}
`;
export const getRole = `query GetRole($id: ID!) {
  getRole(id: $id) {
    id
    name
    permission
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    users {
      items {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
      nextToken
    }
  }
}
`;
export const listRoles = `query ListRoles(
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      permission
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      users {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getSpace = `query GetSpace($id: ID!) {
  getSpace(id: $id) {
    id
    createdAt
    name
    colorCode
    status
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    batchCards {
      items {
        id
      }
      nextToken
    }
    batchCardAllocations {
      items {
        id
        createdAt
        date
        obsolete
      }
      nextToken
    }
    spaceAllocation {
      items {
        id
        date
        createdAt
      }
      nextToken
    }
  }
}
`;
export const listSpaces = `query ListSpaces(
  $filter: ModelSpaceFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getBrand = `query GetBrand($id: ID!) {
  getBrand(id: $id) {
    id
    name
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    subBrands {
      items {
        id
        name
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
  }
}
`;
export const listBrands = `query ListBrands(
  $filter: ModelBrandFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      subBrands {
        nextToken
      }
      productItems {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getSubBrand = `query GetSubBrand($id: ID!) {
  getSubBrand(id: $id) {
    id
    name
    brand {
      id
      name
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      subBrands {
        nextToken
      }
      productItems {
        nextToken
      }
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
  }
}
`;
export const listSubBrands = `query ListSubBrands(
  $filter: ModelSubBrandFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      brand {
        id
        name
      }
      productItems {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getService = `query GetService($id: ID!) {
  getService(id: $id) {
    id
    name
    icon
    batchCards {
      items {
        id
        isComplete
      }
      nextToken
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
  }
}
`;
export const listServices = `query ListServices(
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      icon
      batchCards {
        nextToken
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
    }
    nextToken
  }
}
`;
export const getItemTemplate = `query GetItemTemplate($id: ID!) {
  getItemTemplate(id: $id) {
    id
    uic
    brand
    subBrand
    category
    subCategory
    type
    gender
    age
    size
    look
    other1
    other2
    other3
    season
    collectionName
    productName
    quantity
    clientName
    serviceName
    unit
    itemTemplateType
    companies {
      items {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      nextToken
    }
  }
}
`;
export const listItemTemplates = `query ListItemTemplates(
  $filter: ModelItemTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listItemTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      uic
      brand
      subBrand
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      season
      collectionName
      productName
      quantity
      clientName
      serviceName
      unit
      itemTemplateType
      companies {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getBatchCard = `query GetBatchCard($id: ID!) {
  getBatchCard(id: $id) {
    id
    createdAt
    name
    description
    inBacklog
    images
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    spaces {
      items {
        id
      }
      nextToken
    }
    services {
      items {
        id
        isComplete
      }
      nextToken
    }
    productItems {
      items {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      nextToken
    }
    batchCardAllocations {
      items {
        id
        createdAt
        date
        obsolete
      }
      nextToken
    }
  }
}
`;
export const listBatchCards = `query ListBatchCards(
  $filter: ModelBatchCardFilterInput
  $limit: Int
  $nextToken: String
) {
  listBatchCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getBatchCardAllocation = `query GetBatchCardAllocation($id: ID!) {
  getBatchCardAllocation(id: $id) {
    id
    createdAt
    date
    obsolete
    space {
      id
      createdAt
      name
      colorCode
      status
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      batchCards {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
      spaceAllocation {
        nextToken
      }
    }
    batchCardServices {
      items {
        id
        isComplete
      }
      nextToken
    }
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
  }
}
`;
export const listBatchCardAllocations = `query ListBatchCardAllocations(
  $filter: ModelBatchCardAllocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listBatchCardAllocations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      date
      obsolete
      space {
        id
        createdAt
        name
        colorCode
        status
      }
      batchCardServices {
        nextToken
      }
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
    }
    nextToken
  }
}
`;
export const getProductItem = `query GetProductItem($id: ID!) {
  getProductItem(id: $id) {
    id
    createdAt
    uic
    category
    subCategory
    type
    gender
    age
    size
    look
    other1
    other2
    other3
    productName
    clientName
    serviceName
    quantity
    unit
    season
    collectionName
    tagDescription
    taggedAt
    tagged
    previousTagDescriptions
    archived
    complete
    completedAt
    qcApproved
    returnedToItems
    qcApprovedAt
    completedServices
    productItemServices
    imageKeys
    batchCard {
      id
      createdAt
      name
      description
      inBacklog
      images
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaces {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCardAllocations {
        nextToken
      }
    }
    brand {
      id
      name
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      subBrands {
        nextToken
      }
      productItems {
        nextToken
      }
    }
    subBrand {
      id
      name
      brand {
        id
        name
      }
      productItems {
        nextToken
      }
    }
    company {
      id
      createdAt
      name
      address
      state
      industry
      country
      companySize
      stripeCustomerId
      stripeSubscriptionPlanId
      subscriptionType
      accountDisabled
      itemTemplate {
        id
        uic
        brand
        subBrand
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        season
        collectionName
        productName
        quantity
        clientName
        serviceName
        unit
        itemTemplateType
      }
      roles {
        nextToken
      }
      users {
        nextToken
      }
      spaces {
        nextToken
      }
      brands {
        nextToken
      }
      services {
        nextToken
      }
      productItems {
        nextToken
      }
      batchCards {
        nextToken
      }
    }
    notes {
      items {
        id
        note
        timestamp
      }
      nextToken
    }
    content {
      items {
        id
        header
        content
        createdAt
      }
      nextToken
    }
  }
}
`;
export const listProductItems = `query ListProductItems(
  $filter: ModelProductItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      uic
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      productName
      clientName
      serviceName
      quantity
      unit
      season
      collectionName
      tagDescription
      taggedAt
      tagged
      previousTagDescriptions
      archived
      complete
      completedAt
      qcApproved
      returnedToItems
      qcApprovedAt
      completedServices
      productItemServices
      imageKeys
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      notes {
        nextToken
      }
      content {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getProductItemNote = `query GetProductItemNote($id: ID!) {
  getProductItemNote(id: $id) {
    id
    note
    timestamp
    author {
      id
      createdAt
      firstName
      lastName
      email
      phoneNumber
      avatarKey
      avatarUrl
      archived
      verified
      inviteLater
      inviteAccepted
      invitedBy
      status
      userRole {
        id
        name
        permission
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      spaceAllocation {
        nextToken
      }
      productItemNotes {
        nextToken
      }
    }
    product {
      id
      createdAt
      uic
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      productName
      clientName
      serviceName
      quantity
      unit
      season
      collectionName
      tagDescription
      taggedAt
      tagged
      previousTagDescriptions
      archived
      complete
      completedAt
      qcApproved
      returnedToItems
      qcApprovedAt
      completedServices
      productItemServices
      imageKeys
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      notes {
        nextToken
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const listProductItemNotes = `query ListProductItemNotes(
  $filter: ModelProductItemNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductItemNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      note
      timestamp
      author {
        id
        createdAt
        firstName
        lastName
        email
        phoneNumber
        avatarKey
        avatarUrl
        archived
        verified
        inviteLater
        inviteAccepted
        invitedBy
        status
      }
      product {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
    }
    nextToken
  }
}
`;
export const getProductItemContent = `query GetProductItemContent($id: ID!) {
  getProductItemContent(id: $id) {
    id
    header
    content
    product {
      id
      createdAt
      uic
      category
      subCategory
      type
      gender
      age
      size
      look
      other1
      other2
      other3
      productName
      clientName
      serviceName
      quantity
      unit
      season
      collectionName
      tagDescription
      taggedAt
      tagged
      previousTagDescriptions
      archived
      complete
      completedAt
      qcApproved
      returnedToItems
      qcApprovedAt
      completedServices
      productItemServices
      imageKeys
      batchCard {
        id
        createdAt
        name
        description
        inBacklog
        images
      }
      brand {
        id
        name
      }
      subBrand {
        id
        name
      }
      company {
        id
        createdAt
        name
        address
        state
        industry
        country
        companySize
        stripeCustomerId
        stripeSubscriptionPlanId
        subscriptionType
        accountDisabled
      }
      notes {
        nextToken
      }
      content {
        nextToken
      }
    }
    createdAt
  }
}
`;
export const listProductItemContents = `query ListProductItemContents(
  $filter: ModelProductItemContentFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductItemContents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      header
      content
      product {
        id
        createdAt
        uic
        category
        subCategory
        type
        gender
        age
        size
        look
        other1
        other2
        other3
        productName
        clientName
        serviceName
        quantity
        unit
        season
        collectionName
        tagDescription
        taggedAt
        tagged
        previousTagDescriptions
        archived
        complete
        completedAt
        qcApproved
        returnedToItems
        qcApprovedAt
        completedServices
        productItemServices
        imageKeys
      }
      createdAt
    }
    nextToken
  }
}
`;
export const getFaq = `query GetFaq($id: ID!) {
  getFaq(id: $id) {
    id
    question
    answer
  }
}
`;
export const listFaqs = `query ListFaqs($filter: ModelFaqFilterInput, $limit: Int, $nextToken: String) {
  listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      question
      answer
    }
    nextToken
  }
}
`;
