const serviceIcons = {
  Calculation: require('../Assets/Images/ServiceIcons/Calculation.svg'),
  Coding: require('../Assets/Images/ServiceIcons/Coding.svg'),
  Content_Writing: require('../Assets/Images/ServiceIcons/Content_Writing.svg'),
  Delivering: require('../Assets/Images/ServiceIcons/Delivering.svg'),
  Editing: require('../Assets/Images/ServiceIcons/Editing.svg'),
  Entry: require('../Assets/Images/ServiceIcons/Entry.svg'),
  Failure: require('../Assets/Images/ServiceIcons/Failure.svg'),
  Food_Recipe: require('../Assets/Images/ServiceIcons/Food_Recipe.svg'),
  Hair_and_makeup: require('../Assets/Images/ServiceIcons/Hair_and_makeup.svg'),
  Image_Retouching: require('../Assets/Images/ServiceIcons/Image_Retouching.svg'),
  Implementation: require('../Assets/Images/ServiceIcons/Implementation.svg'),
  Individuals: require('../Assets/Images/ServiceIcons/Individuals.svg'),
  Mannequin_Photography: require('../Assets/Images/ServiceIcons/Mannequin_Photography.svg'),
  Packing: require('../Assets/Images/ServiceIcons/Packing.svg'),
  Photography: require('../Assets/Images/ServiceIcons/Photography.svg'),
  Preparation: require('../Assets/Images/ServiceIcons/Preparation.svg'),
  Prototyping: require('../Assets/Images/ServiceIcons/Prototyping.svg'),
  Purchasing: require('../Assets/Images/ServiceIcons/Purchasing.svg'),
  Quality_Control: require('../Assets/Images/ServiceIcons/Quality_Control.svg'),
  Risk_Assessment: require('../Assets/Images/ServiceIcons/Risk_Assessment.svg'),
  Saving: require('../Assets/Images/ServiceIcons/Saving.svg'),
  Storing: require('../Assets/Images/ServiceIcons/Storing.svg'),
  Styling: require('../Assets/Images/ServiceIcons/Styling.svg'),
  Tagging: require('../Assets/Images/ServiceIcons/Tagging.svg'),
  Video_Editing: require('../Assets/Images/ServiceIcons/Video_Editing.svg'),
  Videography: require('../Assets/Images/ServiceIcons/Videography.svg'),
  Website: require('../Assets/Images/ServiceIcons/Website.svg'),
  Writing: require('../Assets/Images/ServiceIcons/Writing.svg'),
  Budget: require('../Assets/Images/ServiceIcons/Budget.svg'),
  Buying: require('../Assets/Images/ServiceIcons/Buying.svg'),
  Dollar: require('../Assets/Images/ServiceIcons/Dollar.svg'),
  Euro: require('../Assets/Images/ServiceIcons/Euro.svg'),
  Pound: require('../Assets/Images/ServiceIcons/Pound.svg'),
  'Co-ordination': require('../Assets/Images/ServiceIcons/Co-ordination.svg'),
  Connectivity: require('../Assets/Images/ServiceIcons/Connectivity.svg'),
  Continuity: require('../Assets/Images/ServiceIcons/Continuity.svg'),
  Groups: require('../Assets/Images/ServiceIcons/Groups.svg'),
  Hierarchy: require('../Assets/Images/ServiceIcons/Hierarchy.svg'),
  Planning: require('../Assets/Images/ServiceIcons/Planning.svg'),
  Sharing: require('../Assets/Images/ServiceIcons/Sharing.svg'),
  Catalogue: require('../Assets/Images/ServiceIcons/Catalogue.svg'),
  Definition: require('../Assets/Images/ServiceIcons/Definition.svg'),
  Lines: require('../Assets/Images/ServiceIcons/Lines.svg'),
  Results: require('../Assets/Images/ServiceIcons/Results.svg'),
  Testing: require('../Assets/Images/ServiceIcons/Testing.svg'),
  requirements: require('../Assets/Images/ServiceIcons/requirements.svg'),
  Aligning: require('../Assets/Images/ServiceIcons/Aligning.svg'),
  Attributes: require('../Assets/Images/ServiceIcons/Attributes.svg'),
  Circle: require('../Assets/Images/ServiceIcons/Circle.svg'),
  Heart: require('../Assets/Images/ServiceIcons/Heart.svg'),
  Square: require('../Assets/Images/ServiceIcons/Square.svg'),
  Star: require('../Assets/Images/ServiceIcons/Star.svg'),
  Triangle: require('../Assets/Images/ServiceIcons/Triangle.svg'),
  Changing: require('../Assets/Images/ServiceIcons/Changing.svg'),
  Conception: require('../Assets/Images/ServiceIcons/Conception.svg'),
  Cycle: require('../Assets/Images/ServiceIcons/Cycle.svg'),
  Deployment: require('../Assets/Images/ServiceIcons/Deployment.svg'),
  Left_Arrow: require('../Assets/Images/ServiceIcons/Left_Arrow.svg'),
  Downward_Arrow: require('../Assets/Images/ServiceIcons/Downward_Arrow.svg'),
  Right_Arrow: require('../Assets/Images/ServiceIcons/Right_Arrow.svg'),
  Upward_Arrow: require('../Assets/Images/ServiceIcons/Upward_Arrow.svg'),
  Lock: require('../Assets/Images/ServiceIcons/Lock.svg'),
  Unlock: require('../Assets/Images/ServiceIcons/Unlock.svg'),
  File_Download: require('../Assets/Images/ServiceIcons/File_Download.svg'),
  File_Upload: require('../Assets/Images/ServiceIcons/File_Upload.svg'),
  Innovation: require('../Assets/Images/ServiceIcons/Innovation.svg'),
  Inspection: require('../Assets/Images/ServiceIcons/Inspection.svg'),
  Integration: require('../Assets/Images/ServiceIcons/Integration.svg'),
  Phasing: require('../Assets/Images/ServiceIcons/Phasing.svg'),
  Rejection: require('../Assets/Images/ServiceIcons/Rejection.svg'),
  Update: require('../Assets/Images/ServiceIcons/Update.svg'),
  Assembly: require('../Assets/Images/ServiceIcons/Assembly.svg'),
  Improvement: require('../Assets/Images/ServiceIcons/Improvement.svg'),
  Library: require('../Assets/Images/ServiceIcons/Library.svg'),
  Variables: require('../Assets/Images/ServiceIcons/Variables.svg'),
  Analysing: require('../Assets/Images/ServiceIcons/Analysing.svg'),
  Bicycle: require('../Assets/Images/ServiceIcons/Bicycle.svg'),
  Building: require('../Assets/Images/ServiceIcons/Building.svg'),
  Bus: require('../Assets/Images/ServiceIcons/Bus.svg'),
  Car: require('../Assets/Images/ServiceIcons/Car.svg'),
  Collection: require('../Assets/Images/ServiceIcons/Collection.svg'),
  Construction: require('../Assets/Images/ServiceIcons/Construction.svg'),
  Cooking: require('../Assets/Images/ServiceIcons/Cooking.svg'),
  Creation: require('../Assets/Images/ServiceIcons/Creation.svg'),
  Engineering: require('../Assets/Images/ServiceIcons/Engineering.svg'),
  Estimation: require('../Assets/Images/ServiceIcons/Estimation.svg'),
  Finishing: require('../Assets/Images/ServiceIcons/Finishing.svg'),
  Food_Ingredients: require('../Assets/Images/ServiceIcons/Food_Ingredients.svg'),
  Food_Service: require('../Assets/Images/ServiceIcons/Food_Service.svg'),
  Launching: require('../Assets/Images/ServiceIcons/Launching.svg'),
  Maintaining: require('../Assets/Images/ServiceIcons/Maintaining.svg'),
  Motorbike: require('../Assets/Images/ServiceIcons/Motorbike.svg'),
  Preservation: require('../Assets/Images/ServiceIcons/Preservation.svg'),
  Receiving: require('../Assets/Images/ServiceIcons/Receiving.svg'),
  Servicing: require('../Assets/Images/ServiceIcons/Servicing.svg'),
  Target: require('../Assets/Images/ServiceIcons/Target.svg'),
  Unpacking: require('../Assets/Images/ServiceIcons/Unpacking.svg'),
  benchmarking: require('../Assets/Images/ServiceIcons/benchmarking.svg'),
  Baking: require('../Assets/Images/ServiceIcons/Baking.svg')
};

export default serviceIcons;
