import * as React from 'react';
import styled from 'styled-components';
import {
  FiLayers,
  FiFolder,
  FiGrid,
  FiCheckSquare,
  FiDollarSign,
  FiTrash
} from 'react-icons/fi';
import { MdHome, MdPeople } from 'react-icons/md';
import { IoMdPricetag, IoIosStar, IoIosList, IoIosTimer } from 'react-icons/io';

/** Cognito user groups */
import {
  USER_GROUP,
  SUPER_USER_GROUP,
  OWNER_USER_GROUP
} from '../Utils/Consts';

/** Custom types */
import { NavItemType } from '../CustomTypes';

/** App theme */
import { Colors } from '../Themes';

const StyledDash = styled.span`
  position: relative;
  color: ${Colors.snow};
  bottom: 0.2rem;
  left: 0.9rem;
`;

export const NAV_ITEMS: Array<NavItemType> = [
  /** Flume Admin/Owner */
  {
    to: '/admin/dashboard',
    title: 'Dashboard',
    icon: <FiGrid size="1.3em" />,
    cognitoGroups: [OWNER_USER_GROUP]
  },
  {
    to: '/admin/invoices',
    title: 'Invoices',
    icon: <FiDollarSign size="1.3em" />,
    cognitoGroups: [OWNER_USER_GROUP]
  },
  /** Manage/Setup */
  {
    to: '#',
    parent: 'MANAGE',
    title: 'Team',
    icon: <MdPeople size="1.3em" />,
    cognitoGroups: [SUPER_USER_GROUP],
    subMenu: [
      {
        to: '/manage/teams-and-roles/roles',
        title: 'Roles'
      },
      {
        to: '/manage/teams-and-roles/team',
        title: 'Team Members'
      }
    ]
  },
  {
    to: '/manage/services',
    parent: 'MANAGE',
    title: 'Services',
    icon: <IoIosStar size="1.3em" />,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  {
    to: '/manage/spaces',
    parent: 'MANAGE',
    title: 'Spaces',
    icon: <MdHome size="1.3em" />,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  {
    to: '/manage/brands',
    parent: 'MANAGE',
    title: 'Brands',
    icon: <IoMdPricetag size="1.3em" />,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  {
    to: '/manage/item-templates',
    parent: 'MANAGE',
    title: 'Item Templates',
    icon: <IoIosList size="1.3em" />,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  /** Production */
  {
    to: '#',
    parent: 'PRODUCTION',
    title: 'Sorting',
    icon: <FiLayers size="1.3em" />,
    subMenu: [
      {
        to: '/production/sorting/new-items',
        title: 'New Items'
      },
      {
        to: '/production/sorting/tagged-items',
        title: 'Tagged Items'
      }
    ],
    cognitoGroups: [USER_GROUP, SUPER_USER_GROUP]
  },
  {
    to: '/production/main-planner',
    parent: 'PRODUCTION',
    title: 'Planner',
    icon: <FiFolder size="1.3em" />,
    cognitoGroups: [USER_GROUP, SUPER_USER_GROUP]
  },
  {
    to: '/production/complete-product-items',
    parent: 'PRODUCTION',
    title: 'Complete Items',
    icon: <FiCheckSquare size="1.3em" />,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  {
    to: '#',
    parent: 'PRODUCTION',
    title: 'Quality Control',
    icon: <IoIosTimer size="1.3em" />,
    subMenu: [
      {
        to: '/production/quality-control/calendar',
        title: 'Calendar View'
      },
      {
        to: '/production/quality-control/items',
        title: 'Items View'
      }
    ],
    cognitoGroups: [SUPER_USER_GROUP]
  },
  {
    to: '/production/archived-product-items',
    parent: 'PRODUCTION',
    title: 'Archived Items',
    icon: <FiTrash size="1.3em" />,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  /** Accounts & Billing */
  {
    to: '/manage/accounts-and-billing',
    parent: 'MANAGE',
    title: 'Accounts & Billing',
    footer: true,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  /** Reporting */
  {
    to: '/reporting/sorting',
    parent: 'REPORTING',
    title: 'In Sorting',
    icon: <StyledDash>&mdash;</StyledDash>,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  {
    to: '/reporting/main-planner',
    parent: 'REPORTING',
    title: 'In Planner',
    icon: <StyledDash>&mdash;</StyledDash>,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  {
    to: '/reporting/complete',
    parent: 'REPORTING',
    title: 'Complete',
    icon: <StyledDash>&mdash;</StyledDash>,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  {
    to: '/reporting/quality-control',
    parent: 'REPORTING',
    title: 'In QC',
    icon: <StyledDash>&mdash;</StyledDash>,
    cognitoGroups: [SUPER_USER_GROUP]
  },
  {
    to: '/reporting/perfomance-services',
    parent: 'REPORTING',
    title: 'By Service',
    icon: <StyledDash>&mdash;</StyledDash>,
    cognitoGroups: [SUPER_USER_GROUP],
    footer: true,
    showHeader: true
  },
  {
    to: '/reporting/perfomance-spaces',
    parent: 'REPORTING',
    title: 'By Space',
    icon: <StyledDash>&mdash;</StyledDash>,
    cognitoGroups: [SUPER_USER_GROUP],
    footer: true
  }
];
