/* eslint react/jsx-no-target-blank: 0 */
import * as React from 'react';
import styled from 'styled-components';
import * as moment from 'moment';
import { Jumbotron, Container } from 'reactstrap';

/* presentation components */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader';
import StyledDivider from '../../Components/Styled/Divider';

/* Themes */
import { Colors } from '../../Themes';

/* Stripe API Utils */
import {
  CUSTOMER_SUBSCRIPTIONS,
  CUSTOMER_INVOICES
} from '../../Utils/LambdaEndpoints';

/* Custom types */
import { Error } from '../../CustomTypes';

/* Utils */
import { HTTP_METHODS } from '../../Utils/Consts';
import { convertPixelsToRem, getPriceFromNumber } from '../../Utils/Helpers';

/* API */
import { apiRequest } from '../../Utils/API';

type Props = {
  companyId: string;
  stripeSubscriptionPlanId: string | null;
  stripeCustomerId: string | null;
};

type State = {
  loading: boolean;
  error: Error;
  amount: number;
  currency: string;
  planNickName: string;
  interval: string;
  invoices: Array<{}>;
};

type InvoiceTextProps = {
  uppercase?: boolean;
  cursorPointer?: boolean;
};

type PlanInfoProps = {
  bgColor?: string;
  marginBottom?: number;
};

const PlanInfoContainer = styled.div<PlanInfoProps>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 95%;
  margin: auto auto
    ${(props: PlanInfoProps) =>
      props.marginBottom
        ? convertPixelsToRem(props.marginBottom)
        : convertPixelsToRem(20)}
    auto;
  padding: ${convertPixelsToRem(20)};
  border-radius: ${convertPixelsToRem(5)};
  background-color: ${(props: PlanInfoProps) =>
    props.bgColor || Colors.lightBlueGrey};

  &:last-of-type {
    margin-bottom: ${convertPixelsToRem(100)} !important;
  }
`;

const InvoiceText = styled.span<InvoiceTextProps>`
  color: ${Colors.grey};
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: ${(props: InvoiceTextProps) =>
    props.uppercase ? 'uppercase' : 'capitalize'};
  cursor: ${(props: InvoiceTextProps) =>
    props.cursorPointer ? 'pointer' : 'default'};
`;

class PaymentsAndInvoices extends React.Component<Props, State> {
  state: State = {
    loading: false,
    error: null,
    amount: 0,
    currency: '',
    planNickName: '',
    interval: '',
    invoices: []
  };

  timeoutId: number = 0;

  componentDidMount() {
    const { stripeSubscriptionPlanId, stripeCustomerId } = this.props;

    this.getCustomerInvoices(stripeCustomerId);
    this.getCustomerSubscription(stripeSubscriptionPlanId);
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  /**
   * Get a customer's current subscription
   *
   *  TODO: Refactor this into one request since subscribeToProduct component has a similar request
   *  TODO: Determine currency support for stripe, working with usd for now
   *
   * @param {string | null} subscriptionId - ID of the users' current plan
   *
   * @returns {void}
   */
  getCustomerSubscription = async (subscriptionId: string | null) => {
    this.setState({ loading: true });

    const response = await apiRequest(
      `${CUSTOMER_SUBSCRIPTIONS}/${subscriptionId}`,
      HTTP_METHODS.GET
    ).catch(e => {
      this.setState({ loading: false });
      this.setError(e.message);
    });

    if (response) {
      if (!response.plan) {
        this.setError('No subscription plan found');
      } else {
        const { amount, currency, nickname, interval } = response.plan;

        this.setState({
          loading: false,
          amount,
          currency,
          planNickName: nickname,
          interval
        });
      }
    }
  };

  /**
   * Get a customer's invoices from stripe API - default returned invoices is 10
   *
   *  TODO: Add pagination for subscriptions
   *
   * @param {string | null} stripeCustomerId - stripe customer id for the user
   *
   * @returns {void}
   */
  getCustomerInvoices = async (stripeCustomerId: string | null) => {
    this.setState({ loading: true });

    const response = await apiRequest(
      `${CUSTOMER_INVOICES}/${stripeCustomerId}`,
      HTTP_METHODS.GET
    ).catch(e => {
      this.setState({ loading: false });
      this.setError(e.message);
    });

    if (response) {
      this.setState({
        invoices: response.data,
        loading: false
      });
    }
  };

  /**
   * display an error
   *
   * @param {string} error - error to display
   *
   * @returns {void}
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const {
      loading,
      error,
      planNickName,
      amount,
      interval,
      invoices
    } = this.state;

    if (loading) {
      return (
        <FullWidthContainer align="center">
          <Loader size={150} color={Colors.flumeGreen} />
        </FullWidthContainer>
      );
    }

    return (
      <React.Fragment>
        <Container
          style={{
            marginBottom: convertPixelsToRem(20),
            padding: convertPixelsToRem(20)
          }}
        >
          <Jumbotron
            style={{
              backgroundColor: Colors.flumeGreen,
              textAlign: 'center'
            }}
          >
            {error && <ErrorMessage errorMessage={error} />}
            <h4>{planNickName} Plan</h4>
            <h6>{`${getPriceFromNumber(amount, 'USD')} per ${interval}`}</h6>
          </Jumbotron>
        </Container>
        <StyledDivider borderSize={1} borderColor={Colors.flumeGreenLight} />
        <PlanInfoContainer bgColor={Colors.snow} marginBottom={10}>
          <InvoiceText>Invoice Number</InvoiceText>
          <InvoiceText>Due Date</InvoiceText>
          <InvoiceText>Status</InvoiceText>
          <InvoiceText>Get Invoice</InvoiceText>
        </PlanInfoContainer>
        {invoices && invoices.length ? (
          invoices.map(
            // @ts-ignore
            (invoice: {
              id: string;
              number: string;
              created: number;
              invoice_pdf: string;
              status: string;
            }) => (
              <PlanInfoContainer key={invoice.id}>
                <InvoiceText uppercase={true}>{invoice.number}</InvoiceText>
                <InvoiceText>
                  {moment.unix(invoice.created).format('DD/MM/YYYY')}
                </InvoiceText>
                <InvoiceText>{invoice.status}</InvoiceText>
                <InvoiceText cursorPointer={true}>
                  {invoice.invoice_pdf ? (
                    <a
                      style={{
                        textDecoration: 'none',
                        color: Colors.grey
                      }}
                      href={invoice.invoice_pdf}
                      target="_blank"
                    >
                      Download
                    </a>
                  ) : (
                    <span>Invoice not finalized yet</span>
                  )}
                </InvoiceText>
              </PlanInfoContainer>
            )
          )
        ) : (
          <h5 style={{ textAlign: 'center' }}>No invoices found</h5>
        )}
      </React.Fragment>
    );
  }
}

export default PaymentsAndInvoices;
