/* eslint no-empty-pattern: 0 */
import * as React from 'react';
import { FormGroup, Label, Input, Form } from 'reactstrap';
import { IoIosTrash } from 'react-icons/io';

/** Presentational components */
import SplitWrapper from '../../Components/Styled/SplitWrapper';
import StyledButton from '../../Components/Styled/Button';
import Loader from '../../Components/Loader';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import GlobalModalContainer from '../../Components/Modal';

/** Custom Types */
import { Error, ProductItemContentType } from '../../CustomTypes';

/** App theme */
import { Colors } from '../../Themes';
import { convertPixelsToRem } from '../../Utils/Helpers';
import DialogModal from '../../Components/DialogModal';

import Composed from './ComposedMutationsForItemContent';

type Props = {
  content: Array<ProductItemContentType>;
  productItemId: string;
  userId: string;
  batchCardId: string | undefined;
  returnRefetchQuery: (userId: string, batchCardId?: string) => Array<any>;
  notification: (message: string, appearance?: string | undefined) => void;
  closeModal: () => void;
};

type State = {
  error: Error;
  dialogModalOpen: boolean;
  loading: boolean;
  itemContentId: string;
  itemType: string;
  itemContent: {
    [index: string]: {
      header: string;
      content: string | null;
    };
  };
};

class EditProductItemText extends React.Component<Props, State> {
  timeoutId: number = 0;

  state = {
    error: '',
    dialogModalOpen: false,
    loading: false,
    itemContentId: '',
    itemType: '',
    itemContent: {}
  };

  componentDidMount() {
    const { content } = this.props;

    /* eslint-disable */
    content.map(item => {
      if (item.header) {
        this.setState(prevState => ({
          itemContent: {
            ...prevState.itemContent,
            [item.id]: {
              ...prevState.itemContent[item.id],
              header: item.header
            }
          }
        }));
      }

      this.setState(prevState => ({
        itemContent: {
          ...prevState.itemContent,
          [item.id]: {
            ...prevState.itemContent[item.id],
            content: item.content
          }
        }
      }));
    });
  }

  /** Open dialog modal
   * @param {React.FormEvent} event - browser event
   * @param {string} itemId - id for the card been deleted
   *
   * @returns {void}
   */
  openDialogModal = (
    event: React.FormEvent,
    itemContentId: string,
    itemType: string
  ): void => {
    this.setState({ dialogModalOpen: true, itemContentId, itemType });
  };

  /** Close dialog modal  */
  closeDialogModal = (): void => {
    this.setState({ dialogModalOpen: false, itemContentId: '', itemType: '' });
  };

  handleChange = (
    event: React.FormEvent<HTMLInputElement>,
    itemContentId: string,
    type: string
  ) => {
    const { value }: { value: string } = event.currentTarget;

    this.setState(prevState => ({
      itemContent: {
        ...prevState.itemContent,
        [itemContentId]: {
          ...prevState.itemContent[itemContentId],
          [type]: value
        }
      }
    }));
  };

  /**
   *  Update product item text content
   *
   * @param {React.FormEvent} event - form event
   * @param {({}) => Promise<any>} updateProductItemContentMutation - mutation to update content
   * @param {string} userId
   * @param {string} productItemId - product item id
   * @param {(string | undefined)} batchCardId - batch card i
   */
  handleSubmit = async (
    event: React.FormEvent,
    updateProductItemContentMutation: ({}) => Promise<any>,
    userId: string,
    productItemId: string,
    batchCardId: string | undefined
  ) => {
    const { itemContent } = this.state;

    try {
      this.setState({ loading: true });
      const result = await Promise.all(
        Object.keys(itemContent).map(async itemContentKey => {
          await updateProductItemContentMutation({
            variables: {
              input: {
                id: itemContentKey,
                header: itemContent[itemContentKey].header,
                content: itemContent[itemContentKey].content,
                productItemContentProductId: productItemId
              }
            },
            refetchQueries: this.props.returnRefetchQuery(userId, batchCardId)
          });
        })
      );
      if (result) {
        this.setState({ loading: false });
        this.props.closeModal();
        this.props.notification('Content updated successfully');
      }
    } catch (e) {
      this.setState({ loading: false });
      this.setError(e.message);
    }
  };

  /**
   * Delete item content from a product item
   *
   * Since we want to keep the bulk section dynamic, if an item has both header
   * and footer then we soft delete and leave either the header or footer
   * otherwise we hard delete the product item content
   *
   * @param {React.FormEvent} event
   * @param {({}) => Promise<any>} deleteItemContentMutation - mutation to delete
   * @param {string} userId - user id
   * @param {(string | undefined)} batchCardId - id for batch card
   */
  handleDeleteItemContent = async (
    event: React.FormEvent,
    deleteItemContentMutation: ({}) => Promise<any>,
    updateProductItemContentMutation: ({}) => Promise<any>,
    userId: string,
    batchCardId: string | undefined
  ) => {
    const { itemContentId, itemContent, itemType } = this.state;
    let result;
    this.setState({ loading: true });

    const hasBothHeaderAndContent =
      itemContent[itemContentId].header && itemContent[itemContentId].content
        ? true
        : false;

    if (!hasBothHeaderAndContent) {
      // header of footer is missing, delete entire content item
      result = await deleteItemContentMutation({
        variables: {
          input: {
            id: itemContentId
          }
        },
        refetchQueries: this.props.returnRefetchQuery(userId, batchCardId)
      });
    } else {
      result = await updateProductItemContentMutation({
        variables: {
          input: {
            id: itemContentId,
            [itemType]: null
          }
        },
        refetchQueries: this.props.returnRefetchQuery(userId, batchCardId)
      });
    }

    if (result) {
      this.setState({ loading: false });
      this.props.closeModal();
      this.props.notification('Content deleted successfully');
    }
  };

  /** Error
   * @param error - error message to be displayed
   */
  setError = (error: string): void => {
    this.setState(
      {
        error
      },
      () => {
        this.timeoutId = window.setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    );
  };

  render() {
    const { content, productItemId, userId, batchCardId } = this.props;
    const { error, dialogModalOpen, loading } = this.state;

    return (
      <Composed>
        {(composedMutations: {
          deleteItemContent: { mutation: () => Promise<any> };
          updateProductItem: { mutation: () => Promise<any> };
        }) => (
          <Form
            onSubmit={event => {
              event.preventDefault();

              this.handleSubmit(
                event,
                composedMutations.updateProductItem.mutation,
                userId,
                productItemId,
                batchCardId
              );
            }}
          >
            <GlobalModalContainer
              toggleModal={this.closeDialogModal}
              title=""
              modalDisplay={
                <DialogModal
                  loading={loading}
                  title="Are you sure you want to delete this content?"
                  toggleModal={this.closeDialogModal}
                  handleAccept={event =>
                    this.handleDeleteItemContent(
                      event,
                      composedMutations.deleteItemContent.mutation,
                      composedMutations.updateProductItem.mutation,
                      userId,
                      batchCardId
                    )
                  }
                />
              }
              modal={dialogModalOpen}
            />
            {content.map((item: ProductItemContentType, index: number) => {
              let itemObject = this.state.itemContent[item.id];

              return (
                itemObject && (
                  <div key={item.id}>
                    {item.header && (
                      <FormGroup>
                        <SplitWrapper>
                          <Label for={`header-${index}`}>
                            Header {index + 1}:
                          </Label>
                          <IoIosTrash
                            size={convertPixelsToRem(30)}
                            color={Colors.pink}
                            className="pointer-cursor"
                            onClick={event =>
                              this.openDialogModal(event, item.id, 'header')
                            }
                            style={{
                              marginBottom: convertPixelsToRem(5)
                            }}
                          />
                        </SplitWrapper>
                        <Input
                          type="text"
                          name={`header-${index}`}
                          value={itemObject.header}
                          onChange={event =>
                            this.handleChange(event, item.id, 'header')
                          }
                          id={`header-${index}`}
                        />
                      </FormGroup>
                    )}

                    <FormGroup>
                      <SplitWrapper>
                        <Label for={`content-${index}`}>Content:</Label>
                        <IoIosTrash
                          size={convertPixelsToRem(30)}
                          color={Colors.pink}
                          className="pointer-cursor"
                          onClick={event =>
                            this.openDialogModal(event, item.id, 'content')
                          }
                          style={{
                            marginBottom: convertPixelsToRem(5)
                          }}
                        />
                      </SplitWrapper>{' '}
                      <Input
                        type="textarea"
                        name={`content-${index}`}
                        value={itemObject.content}
                        onChange={event =>
                          this.handleChange(event, item.id, 'content')
                        }
                        id={`content-${index}`}
                      />
                    </FormGroup>
                  </div>
                )
              );
            })}
            {error && <ErrorMessage errorMessage={error} />}

            <StyledButton
              type="submit"
              disabled={loading}
              color={Colors.snow}
              background={Colors.coal}
              label={
                loading ? <Loader color={Colors.flumeGreen} /> : 'save edits'
              }
            />
          </Form>
        )}
      </Composed>
    );
  }
}

export default EditProductItemText;
