import * as moment from 'moment';
import {
  UpdateCompanyInput,
  UpdateUserInput,
  UpdateSpaceInput
} from '../../../../API';

/** Function returns the users with the relevant object properties
 * @param users - an array of users
 * @param userKeys - array of keys for user objects
 */
export const getUserDataFromKeys = (
  users: Array<UpdateUserInput & { company: UpdateCompanyInput }>,
  userKeys: Array<string>
): Array<Array<string>> => {
  if (users && users.length && userKeys && userKeys.length) {
    return getDataFromKeys(users, userKeys);
  }

  return [];
};

/** Function returns the companies with the relevant object properties
 * @param companies - an array of companies
 * @param companyKeys - array of keys for company objects
 */
export const getCompanyDataFromKeys = (
  companies: Array<UpdateCompanyInput>,
  companyKeys: Array<string>
): Array<Array<string>> => {
  if (companies && companies.length && companyKeys && companyKeys.length) {
    return getDataFromKeys(companies, companyKeys);
  }

  return [];
};

/** Function returns the spaces with the relevant object properties
 * @param spaces - an array of spaces
 * @param spaceKeys - array of keys for space objects
 */
export const getSpaceDataFromKeys = (
  spaces: Array<UpdateSpaceInput & { company: UpdateCompanyInput }>,
  spaceKeys: Array<string>
): Array<Array<string>> => {
  if (spaces && spaces.length && spaceKeys && spaceKeys.length) {
    return getDataFromKeys(spaces, spaceKeys);
  }

  return [];
};

/** Get data from collection of objects based on key - value pairs
 * @param collectionOfData - array of objects
 * @param dataKeys - array of keys used to get values from objects
 */
export const getDataFromKeys = (
  collectionOfData: Array<any>,
  dataKeys: Array<string>
) => {
  return collectionOfData.map(data => {
    return dataKeys.map(dataKey => {
      if (dataKey === 'createdAt') {
        return moment(data[dataKey]).format('DD/MM/YYYY');
      }
      if (dataKey === 'company') {
        return data[dataKey] ? data[dataKey].name : '';
      }
      if (dataKey === 'users') {
        return data[dataKey] ? Object.keys(data[dataKey].items).length : 0;
      }
      return data[dataKey] || '';
    });
  });
};
