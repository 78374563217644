import styled from 'styled-components';

// App theme
import { Colors } from '../../Themes';

const SignUpContainer = styled.div`
  width: 100%;
  & input {
    background: transparent !important;
  }
  & .input-group {
    background: rgba(214, 219, 230, 0.36) !important;
    border-radius: 0.25rem;
  }
  & a {
    color: ${Colors.flumeGreen};
    text-decoration: none;
  }
`;

export default SignUpContainer;
