import * as React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

/** Custom Types */
import { BrandType } from '../../CustomTypes';

type Props = {
  brand: BrandType;
  openDialogModal(brand: BrandType): void;
  openModal(brand: BrandType): void;
};

const RowActions: React.FC<Props> = ({ openModal, openDialogModal, brand }) => {
  const [dropdownOpen, toggleDropdown] = React.useState(false);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => toggleDropdown(!dropdownOpen)}
      style={{ position: 'absolute' }}
    >
      <DropdownToggle
        tag="span"
        onClick={() => toggleDropdown(!dropdownOpen)}
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
      </DropdownToggle>
      <DropdownMenu>
        <div
          className="avatar-dropdown-menu-item"
          onClick={() => openModal(brand)}
        >
          Edit Brand
        </div>
        {brand.subBrands &&
        brand.subBrands.items &&
        brand.subBrands.items.length ? null : (
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openDialogModal(brand)}
          >
            Delete Brand
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default RowActions;
