// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:b8bfecc0-3b32-4c59-85ea-d2d6f2344892",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_PCyp8LK9m",
    "aws_user_pools_web_client_id": "71tdc6neg9nbhop8bs1t0bt6be",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://lecucsmyejdrfo2n6erfhstf3y.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "flume-web-app-20191015134547-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d3ev12uaytdoty.cloudfront.net",
    "aws_user_files_s3_bucket": "flume-web-app9527112a5fcb423ebdf18c86e35cf684-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
