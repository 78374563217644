/* tslint:disable */
//  This file was automatically generated and should not be edited.

export enum UserStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE'
}

export enum Permission {
  NON_USER = 'NON_USER',
  USER = 'USER',
  SUPER_USER = 'SUPER_USER',
  SUPER_ADMIN = 'SUPER_ADMIN'
}

export enum SpaceStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE'
}

export type CreateTaskInput = {
  id?: string | null;
  title: string;
  description?: string | null;
  status?: string | null;
};

export type UpdateTaskInput = {
  id: string;
  title?: string | null;
  description?: string | null;
  status?: string | null;
};

export type DeleteTaskInput = {
  id?: string | null;
};

export type CreatePrivateNoteInput = {
  id?: string | null;
  content: string;
};

export type UpdatePrivateNoteInput = {
  id: string;
  content?: string | null;
};

export type DeletePrivateNoteInput = {
  id?: string | null;
};

export type CreateCompanyInput = {
  id?: string | null;
  createdAt: string;
  name: string;
  address?: string | null;
  state?: string | null;
  industry?: string | null;
  country?: string | null;
  companySize?: string | null;
  stripeCustomerId?: string | null;
  stripeSubscriptionPlanId?: string | null;
  subscriptionType?: string | null;
  accountDisabled?: boolean | null;
  companyItemTemplateId?: string | null;
};

export enum ItemTemplateType {
  PRODUCTION_STUDIOS = 'PRODUCTION_STUDIOS',
  RUNWAY_SHOWS = 'RUNWAY_SHOWS',
  MANUFACTURING = 'MANUFACTURING',
  SERVICES = 'SERVICES',
  UNIVERSAL = 'UNIVERSAL'
}

export type UpdateCompanyInput = {
  id: string;
  createdAt?: string | null;
  name?: string | null;
  address?: string | null;
  state?: string | null;
  industry?: string | null;
  country?: string | null;
  companySize?: string | null;
  stripeCustomerId?: string | null;
  stripeSubscriptionPlanId?: string | null;
  subscriptionType?: string | null;
  accountDisabled?: boolean | null;
  companyItemTemplateId?: string | null;
};

export type DeleteCompanyInput = {
  id?: string | null;
};

export type CreateUserInput = {
  id?: string | null;
  createdAt: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  avatarKey?: string | null;
  avatarUrl?: string | null;
  archived?: boolean | null;
  verified?: boolean | null;
  inviteLater?: boolean | null;
  inviteAccepted?: boolean | null;
  invitedBy?: string | null;
  status?: UserStatus | null;
  userSpaces?: Array<string | null> | null;
  userUserRoleId?: string | null;
  userCompanyId?: string | null;
};

export type UpdateUserInput = {
  id: string;
  createdAt?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  avatarKey?: string | null;
  avatarUrl?: string | null;
  archived?: boolean | null;
  verified?: boolean | null;
  inviteLater?: boolean | null;
  inviteAccepted?: boolean | null;
  invitedBy?: string | null;
  status?: UserStatus | null;
  userSpaces?: Array<string | null> | null;
  userUserRoleId?: string | null;
  userCompanyId?: string | null;
};

export type DeleteUserInput = {
  id?: string | null;
};

export type CreateUserAllocationInput = {
  id?: string | null;
  date: string;
  createdAt?: string | null;
  userAllocationUserId?: string | null;
  userAllocationSpaceId?: string | null;
};

export type UpdateUserAllocationInput = {
  id: string;
  date?: string | null;
  createdAt?: string | null;
  userAllocationUserId?: string | null;
  userAllocationSpaceId?: string | null;
};

export type DeleteUserAllocationInput = {
  id?: string | null;
};

export type CreateRoleInput = {
  id?: string | null;
  name: string;
  permission: Permission;
  roleCompanyId?: string | null;
};

export type UpdateRoleInput = {
  id: string;
  name?: string | null;
  permission?: Permission | null;
  roleCompanyId?: string | null;
};

export type DeleteRoleInput = {
  id?: string | null;
};

export type CreateSpaceInput = {
  id?: string | null;
  createdAt: string;
  name: string;
  colorCode: string;
  status: SpaceStatus;
  spaceCompanyId?: string | null;
};

export type UpdateSpaceInput = {
  id: string;
  createdAt?: string | null;
  name?: string | null;
  colorCode?: string | null;
  status?: SpaceStatus | null;
  spaceCompanyId?: string | null;
};

export type DeleteSpaceInput = {
  id?: string | null;
};

export type CreateBrandInput = {
  id?: string | null;
  name: string;
  brandCompanyId?: string | null;
};

export type UpdateBrandInput = {
  id: string;
  name?: string | null;
  brandCompanyId?: string | null;
};

export type DeleteBrandInput = {
  id?: string | null;
};

export type CreateSubBrandInput = {
  id?: string | null;
  name: string;
  subBrandBrandId?: string | null;
};

export type UpdateSubBrandInput = {
  id: string;
  name?: string | null;
  subBrandBrandId?: string | null;
};

export type DeleteSubBrandInput = {
  id?: string | null;
};

export type CreateServiceInput = {
  id?: string | null;
  name: string;
  icon?: string | null;
  serviceCompanyId?: string | null;
};

export type UpdateServiceInput = {
  id: string;
  name?: string | null;
  icon?: string | null;
  serviceCompanyId?: string | null;
};

export type DeleteServiceInput = {
  id?: string | null;
};

export type CreateItemTemplateInput = {
  id?: string | null;
  uic?: boolean | null;
  brand?: boolean | null;
  subBrand?: boolean | null;
  category?: boolean | null;
  subCategory?: boolean | null;
  type?: boolean | null;
  gender?: boolean | null;
  age?: boolean | null;
  size?: boolean | null;
  look?: boolean | null;
  other1?: boolean | null;
  other2?: boolean | null;
  other3?: boolean | null;
  season?: boolean | null;
  collectionName?: boolean | null;
  productName?: boolean | null;
  quantity?: boolean | null;
  clientName?: boolean | null;
  serviceName?: boolean | null;
  unit?: boolean | null;
  itemTemplateType?: ItemTemplateType | null;
};

export type UpdateItemTemplateInput = {
  id: string;
  uic?: boolean | null;
  brand?: boolean | null;
  subBrand?: boolean | null;
  category?: boolean | null;
  subCategory?: boolean | null;
  type?: boolean | null;
  gender?: boolean | null;
  age?: boolean | null;
  size?: boolean | null;
  look?: boolean | null;
  other1?: boolean | null;
  other2?: boolean | null;
  other3?: boolean | null;
  season?: boolean | null;
  collectionName?: boolean | null;
  productName?: boolean | null;
  quantity?: boolean | null;
  clientName?: boolean | null;
  serviceName?: boolean | null;
  unit?: boolean | null;
  itemTemplateType?: ItemTemplateType | null;
};

export type DeleteItemTemplateInput = {
  id?: string | null;
};

export type CreateBatchCardInput = {
  id?: string | null;
  createdAt: string;
  name?: string | null;
  description?: string | null;
  inBacklog?: boolean | null;
  images?: Array<string | null> | null;
  batchCardCompanyId?: string | null;
};

export type UpdateBatchCardInput = {
  id: string;
  createdAt?: string | null;
  name?: string | null;
  description?: string | null;
  inBacklog?: boolean | null;
  images?: Array<string | null> | null;
  batchCardCompanyId?: string | null;
};

export type DeleteBatchCardInput = {
  id?: string | null;
};

export type CreateBatchCardAllocationInput = {
  id?: string | null;
  createdAt: string;
  date?: string | null;
  obsolete?: boolean | null;
  batchCardAllocationSpaceId?: string | null;
  batchCardAllocationBatchCardId?: string | null;
};

export type UpdateBatchCardAllocationInput = {
  id: string;
  createdAt?: string | null;
  date?: string | null;
  obsolete?: boolean | null;
  batchCardAllocationSpaceId?: string | null;
  batchCardAllocationBatchCardId?: string | null;
};

export type DeleteBatchCardAllocationInput = {
  id?: string | null;
};

export type CreateProductItemInput = {
  id?: string | null;
  createdAt: string;
  uic?: string | null;
  category?: string | null;
  subCategory?: string | null;
  type?: string | null;
  gender?: string | null;
  age?: string | null;
  size?: string | null;
  look?: string | null;
  other1?: string | null;
  other2?: string | null;
  other3?: string | null;
  productName?: string | null;
  clientName?: string | null;
  serviceName?: string | null;
  quantity?: string | null;
  unit?: string | null;
  season?: string | null;
  collectionName?: string | null;
  tagDescription?: string | null;
  taggedAt?: string | null;
  tagged?: boolean | null;
  previousTagDescriptions?: Array<string | null> | null;
  archived?: boolean | null;
  complete?: boolean | null;
  completedAt?: string | null;
  qcApproved?: boolean | null;
  returnedToItems?: boolean | null;
  qcApprovedAt?: string | null;
  completedServices?: Array<string | null> | null;
  productItemServices?: Array<string | null> | null;
  imageKeys?: Array<string | null> | null;
  productItemBatchCardId?: string | null;
  productItemBrandId?: string | null;
  productItemSubBrandId?: string | null;
  productItemCompanyId?: string | null;
};

export type UpdateProductItemInput = {
  id: string;
  createdAt?: string | null;
  uic?: string | null;
  category?: string | null;
  subCategory?: string | null;
  type?: string | null;
  gender?: string | null;
  age?: string | null;
  size?: string | null;
  look?: string | null;
  other1?: string | null;
  other2?: string | null;
  other3?: string | null;
  productName?: string | null;
  clientName?: string | null;
  serviceName?: string | null;
  quantity?: string | null;
  unit?: string | null;
  season?: string | null;
  collectionName?: string | null;
  tagDescription?: string | null;
  taggedAt?: string | null;
  tagged?: boolean | null;
  previousTagDescriptions?: Array<string | null> | null;
  archived?: boolean | null;
  complete?: boolean | null;
  completedAt?: string | null;
  qcApproved?: boolean | null;
  returnedToItems?: boolean | null;
  qcApprovedAt?: string | null;
  completedServices?: Array<string | null> | null;
  productItemServices?: Array<string | null> | null;
  imageKeys?: Array<string | null> | null;
  productItemBatchCardId?: string | null;
  productItemBrandId?: string | null;
  productItemSubBrandId?: string | null;
  productItemCompanyId?: string | null;
};

export type DeleteProductItemInput = {
  id?: string | null;
};

export type CreateProductItemNoteInput = {
  id?: string | null;
  note?: string | null;
  timestamp?: string | null;
  productItemNoteAuthorId: string;
  productItemNoteProductId?: string | null;
};

export type UpdateProductItemNoteInput = {
  id: string;
  note?: string | null;
  timestamp?: string | null;
  productItemNoteAuthorId?: string | null;
  productItemNoteProductId?: string | null;
};

export type DeleteProductItemNoteInput = {
  id?: string | null;
};

export type CreateProductItemContentInput = {
  id?: string | null;
  header?: string | null;
  content?: string | null;
  createdAt: string;
  productItemContentProductId?: string | null;
};

export type UpdateProductItemContentInput = {
  id: string;
  header?: string | null;
  content?: string | null;
  createdAt?: string | null;
  productItemContentProductId?: string | null;
};

export type DeleteProductItemContentInput = {
  id?: string | null;
};

export type CreateBatchCardSpaceInput = {
  id?: string | null;
  batchCardSpaceBatchCardId?: string | null;
  batchCardSpaceSpaceId?: string | null;
};

export type UpdateBatchCardSpaceInput = {
  id: string;
  batchCardSpaceBatchCardId?: string | null;
  batchCardSpaceSpaceId?: string | null;
};

export type DeleteBatchCardSpaceInput = {
  id?: string | null;
};

export type CreateBatchCardServiceInput = {
  id?: string | null;
  isComplete: boolean;
  batchCardServiceBatchCardId?: string | null;
  batchCardServiceBatchCardAllocationId?: string | null;
  batchCardServiceServiceId?: string | null;
};

export type UpdateBatchCardServiceInput = {
  id: string;
  isComplete?: boolean | null;
  batchCardServiceBatchCardId?: string | null;
  batchCardServiceBatchCardAllocationId?: string | null;
  batchCardServiceServiceId?: string | null;
};

export type DeleteBatchCardServiceInput = {
  id?: string | null;
};

export type CreateFaqInput = {
  id?: string | null;
  question: string;
  answer: string;
};

export type UpdateFaqInput = {
  id: string;
  question?: string | null;
  answer?: string | null;
};

export type DeleteFaqInput = {
  id?: string | null;
};

export type ModelTaskFilterInput = {
  id?: ModelIDFilterInput | null;
  title?: ModelStringFilterInput | null;
  description?: ModelStringFilterInput | null;
  status?: ModelStringFilterInput | null;
  and?: Array<ModelTaskFilterInput | null> | null;
  or?: Array<ModelTaskFilterInput | null> | null;
  not?: ModelTaskFilterInput | null;
};

export type ModelIDFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelStringFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelPrivateNoteFilterInput = {
  id?: ModelIDFilterInput | null;
  content?: ModelStringFilterInput | null;
  and?: Array<ModelPrivateNoteFilterInput | null> | null;
  or?: Array<ModelPrivateNoteFilterInput | null> | null;
  not?: ModelPrivateNoteFilterInput | null;
};

export type ModelCompanyFilterInput = {
  id?: ModelIDFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  name?: ModelStringFilterInput | null;
  address?: ModelStringFilterInput | null;
  state?: ModelStringFilterInput | null;
  industry?: ModelStringFilterInput | null;
  country?: ModelStringFilterInput | null;
  companySize?: ModelStringFilterInput | null;
  stripeCustomerId?: ModelStringFilterInput | null;
  stripeSubscriptionPlanId?: ModelStringFilterInput | null;
  subscriptionType?: ModelStringFilterInput | null;
  accountDisabled?: ModelBooleanFilterInput | null;
  and?: Array<ModelCompanyFilterInput | null> | null;
  or?: Array<ModelCompanyFilterInput | null> | null;
  not?: ModelCompanyFilterInput | null;
};

export type ModelBooleanFilterInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  firstName?: ModelStringFilterInput | null;
  lastName?: ModelStringFilterInput | null;
  email?: ModelStringFilterInput | null;
  phoneNumber?: ModelStringFilterInput | null;
  avatarKey?: ModelStringFilterInput | null;
  avatarUrl?: ModelStringFilterInput | null;
  archived?: ModelBooleanFilterInput | null;
  verified?: ModelBooleanFilterInput | null;
  inviteLater?: ModelBooleanFilterInput | null;
  inviteAccepted?: ModelBooleanFilterInput | null;
  invitedBy?: ModelStringFilterInput | null;
  status?: ModelUserStatusFilterInput | null;
  userSpaces?: ModelStringFilterInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelUserStatusFilterInput = {
  eq?: UserStatus | null;
  ne?: UserStatus | null;
};

export type ModelUserAllocationFilterInput = {
  id?: ModelIDFilterInput | null;
  date?: ModelStringFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  and?: Array<ModelUserAllocationFilterInput | null> | null;
  or?: Array<ModelUserAllocationFilterInput | null> | null;
  not?: ModelUserAllocationFilterInput | null;
};

export type ModelRoleFilterInput = {
  id?: ModelIDFilterInput | null;
  name?: ModelStringFilterInput | null;
  permission?: ModelPermissionFilterInput | null;
  and?: Array<ModelRoleFilterInput | null> | null;
  or?: Array<ModelRoleFilterInput | null> | null;
  not?: ModelRoleFilterInput | null;
};

export type ModelPermissionFilterInput = {
  eq?: Permission | null;
  ne?: Permission | null;
};

export type ModelSpaceFilterInput = {
  id?: ModelIDFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  name?: ModelStringFilterInput | null;
  colorCode?: ModelStringFilterInput | null;
  status?: ModelSpaceStatusFilterInput | null;
  and?: Array<ModelSpaceFilterInput | null> | null;
  or?: Array<ModelSpaceFilterInput | null> | null;
  not?: ModelSpaceFilterInput | null;
};

export type ModelSpaceStatusFilterInput = {
  eq?: SpaceStatus | null;
  ne?: SpaceStatus | null;
};

export type ModelBrandFilterInput = {
  id?: ModelIDFilterInput | null;
  name?: ModelStringFilterInput | null;
  and?: Array<ModelBrandFilterInput | null> | null;
  or?: Array<ModelBrandFilterInput | null> | null;
  not?: ModelBrandFilterInput | null;
};

export type ModelSubBrandFilterInput = {
  id?: ModelIDFilterInput | null;
  name?: ModelStringFilterInput | null;
  and?: Array<ModelSubBrandFilterInput | null> | null;
  or?: Array<ModelSubBrandFilterInput | null> | null;
  not?: ModelSubBrandFilterInput | null;
};

export type ModelServiceFilterInput = {
  id?: ModelIDFilterInput | null;
  name?: ModelStringFilterInput | null;
  icon?: ModelStringFilterInput | null;
  and?: Array<ModelServiceFilterInput | null> | null;
  or?: Array<ModelServiceFilterInput | null> | null;
  not?: ModelServiceFilterInput | null;
};

export type ModelItemTemplateFilterInput = {
  id?: ModelIDFilterInput | null;
  uic?: ModelBooleanFilterInput | null;
  brand?: ModelBooleanFilterInput | null;
  subBrand?: ModelBooleanFilterInput | null;
  category?: ModelBooleanFilterInput | null;
  subCategory?: ModelBooleanFilterInput | null;
  type?: ModelBooleanFilterInput | null;
  gender?: ModelBooleanFilterInput | null;
  age?: ModelBooleanFilterInput | null;
  size?: ModelBooleanFilterInput | null;
  look?: ModelBooleanFilterInput | null;
  other1?: ModelBooleanFilterInput | null;
  other2?: ModelBooleanFilterInput | null;
  other3?: ModelBooleanFilterInput | null;
  season?: ModelBooleanFilterInput | null;
  collectionName?: ModelBooleanFilterInput | null;
  productName?: ModelBooleanFilterInput | null;
  quantity?: ModelBooleanFilterInput | null;
  clientName?: ModelBooleanFilterInput | null;
  serviceName?: ModelBooleanFilterInput | null;
  unit?: ModelBooleanFilterInput | null;
  itemTemplateType?: ModelItemTemplateTypeFilterInput | null;
  and?: Array<ModelItemTemplateFilterInput | null> | null;
  or?: Array<ModelItemTemplateFilterInput | null> | null;
  not?: ModelItemTemplateFilterInput | null;
};

export type ModelItemTemplateTypeFilterInput = {
  eq?: ItemTemplateType | null;
  ne?: ItemTemplateType | null;
};

export type ModelBatchCardFilterInput = {
  id?: ModelIDFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  name?: ModelStringFilterInput | null;
  description?: ModelStringFilterInput | null;
  inBacklog?: ModelBooleanFilterInput | null;
  images?: ModelStringFilterInput | null;
  and?: Array<ModelBatchCardFilterInput | null> | null;
  or?: Array<ModelBatchCardFilterInput | null> | null;
  not?: ModelBatchCardFilterInput | null;
};

export type ModelBatchCardAllocationFilterInput = {
  id?: ModelIDFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  date?: ModelStringFilterInput | null;
  obsolete?: ModelBooleanFilterInput | null;
  and?: Array<ModelBatchCardAllocationFilterInput | null> | null;
  or?: Array<ModelBatchCardAllocationFilterInput | null> | null;
  not?: ModelBatchCardAllocationFilterInput | null;
};

export type ModelProductItemFilterInput = {
  id?: ModelIDFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  uic?: ModelStringFilterInput | null;
  category?: ModelStringFilterInput | null;
  subCategory?: ModelStringFilterInput | null;
  type?: ModelStringFilterInput | null;
  gender?: ModelStringFilterInput | null;
  age?: ModelStringFilterInput | null;
  size?: ModelStringFilterInput | null;
  look?: ModelStringFilterInput | null;
  other1?: ModelStringFilterInput | null;
  other2?: ModelStringFilterInput | null;
  other3?: ModelStringFilterInput | null;
  productName?: ModelStringFilterInput | null;
  clientName?: ModelStringFilterInput | null;
  serviceName?: ModelStringFilterInput | null;
  quantity?: ModelStringFilterInput | null;
  unit?: ModelStringFilterInput | null;
  season?: ModelStringFilterInput | null;
  collectionName?: ModelStringFilterInput | null;
  tagDescription?: ModelStringFilterInput | null;
  taggedAt?: ModelStringFilterInput | null;
  tagged?: ModelBooleanFilterInput | null;
  previousTagDescriptions?: ModelStringFilterInput | null;
  archived?: ModelBooleanFilterInput | null;
  complete?: ModelBooleanFilterInput | null;
  completedAt?: ModelStringFilterInput | null;
  qcApproved?: ModelBooleanFilterInput | null;
  returnedToItems?: ModelBooleanFilterInput | null;
  qcApprovedAt?: ModelStringFilterInput | null;
  completedServices?: ModelStringFilterInput | null;
  productItemServices?: ModelStringFilterInput | null;
  imageKeys?: ModelStringFilterInput | null;
  and?: Array<ModelProductItemFilterInput | null> | null;
  or?: Array<ModelProductItemFilterInput | null> | null;
  not?: ModelProductItemFilterInput | null;
};

export type ModelProductItemNoteFilterInput = {
  id?: ModelIDFilterInput | null;
  note?: ModelStringFilterInput | null;
  timestamp?: ModelStringFilterInput | null;
  and?: Array<ModelProductItemNoteFilterInput | null> | null;
  or?: Array<ModelProductItemNoteFilterInput | null> | null;
  not?: ModelProductItemNoteFilterInput | null;
};

export type ModelProductItemContentFilterInput = {
  id?: ModelIDFilterInput | null;
  header?: ModelStringFilterInput | null;
  content?: ModelStringFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  and?: Array<ModelProductItemContentFilterInput | null> | null;
  or?: Array<ModelProductItemContentFilterInput | null> | null;
  not?: ModelProductItemContentFilterInput | null;
};

export type ModelFaqFilterInput = {
  id?: ModelIDFilterInput | null;
  question?: ModelStringFilterInput | null;
  answer?: ModelStringFilterInput | null;
  and?: Array<ModelFaqFilterInput | null> | null;
  or?: Array<ModelFaqFilterInput | null> | null;
  not?: ModelFaqFilterInput | null;
};

export type GetUserDetailsQueryVariables = {
  id: string;
};

export type GetUserDetailsQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    avatarUrl: string | null;
    avatarKey: string | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    invitedBy: string | null;
    inviteAccepted: boolean | null;
    userSpaces: Array<string | null> | null;
    status: UserStatus | null;
    userRole: {
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      name: string;
      accountDisabled: boolean | null;
      address: string | null;
      state: string | null;
      country: string | null;
      industry: string | null;
      companySize: string | null;
      subscriptionType: string | null;
      roles: {
        __typename: 'ModelRoleConnection';
        items: Array<{
          __typename: 'Role';
          id: string;
        } | null> | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
        } | null> | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
        } | null> | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyByUserQueryVariables = {
  id: string;
};

export type GetCompanyByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      name: string;
      address: string | null;
      state: string | null;
      country: string | null;
      industry: string | null;
      companySize: string | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type GetBatchCardServicesAndCompanySpacesQueryVariables = {
  batchCardId: string;
  userId: string;
};

export type GetBatchCardServicesAndCompanySpacesQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        items: Array<{
          __typename: 'BatchCard';
          id: string;
          name: string | null;
          description: string | null;
          inBacklog: boolean | null;
          services: {
            __typename: 'ModelBatchCardServiceConnection';
            items: Array<{
              __typename: 'BatchCardService';
              id: string;
              service: {
                __typename: 'Service';
                id: string;
                name: string;
                icon: string | null;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyRolesUsersAndSpaceByUserQueryVariables = {
  spaceId: string;
  userId: string;
};

export type GetCompanyRolesUsersAndSpaceByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      roles: {
        __typename: 'ModelRoleConnection';
        items: Array<{
          __typename: 'Role';
          id: string;
          name: string;
          permission: Permission;
        } | null> | null;
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string | null;
          phoneNumber: string | null;
          avatarKey: string | null;
          avatarUrl: string | null;
          archived: boolean | null;
          verified: boolean | null;
          status: UserStatus | null;
          userRole: {
            __typename: 'Role';
            id: string;
            name: string;
            permission: Permission;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
          spaceAllocation: {
            __typename: 'ModelUserAllocationConnection';
            items: Array<{
              __typename: 'UserAllocation';
              id: string;
              date: string;
              user: {
                __typename: 'User';
                id: string;
                firstName: string | null;
                lastName: string | null;
                email: string | null;
                status: UserStatus | null;
                userRole: {
                  __typename: 'Role';
                  id: string;
                  name: string;
                  permission: Permission;
                } | null;
              } | null;
            } | null> | null;
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyBatchCardByUserQueryVariables = {
  userId: string;
  batchCardId: string;
};

export type GetCompanyBatchCardByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        items: Array<{
          __typename: 'BatchCard';
          id: string;
          name: string | null;
          description: string | null;
          images: Array<string | null> | null;
          inBacklog: boolean | null;
          batchCardAllocations: {
            __typename: 'ModelBatchCardAllocationConnection';
            items: Array<{
              __typename: 'BatchCardAllocation';
              id: string;
              date: string | null;
              obsolete: boolean | null;
              batchCardServices: {
                __typename: 'ModelBatchCardServiceConnection';
                items: Array<{
                  __typename: 'BatchCardService';
                  id: string;
                  isComplete: boolean;
                  service: {
                    __typename: 'Service';
                    id: string;
                    icon: string | null;
                    name: string;
                  } | null;
                } | null> | null;
              } | null;
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
              } | null;
            } | null> | null;
          } | null;
          productItems: {
            __typename: 'ModelProductItemConnection';
            items: Array<{
              __typename: 'ProductItem';
              id: string;
              uic: string | null;
              brand: {
                __typename: 'Brand';
                id: string;
                name: string;
              } | null;
              subBrand: {
                __typename: 'SubBrand';
                id: string;
                name: string;
              } | null;
              content: {
                __typename: 'ModelProductItemContentConnection';
                items: Array<{
                  __typename: 'ProductItemContent';
                  id: string;
                  header: string | null;
                  content: string | null;
                  createdAt: string;
                } | null> | null;
              } | null;
              category: string | null;
              completedServices: Array<string | null> | null;
              subCategory: string | null;
              type: string | null;
              gender: string | null;
              age: string | null;
              size: string | null;
              look: string | null;
              other1: string | null;
              other2: string | null;
              other3: string | null;
              season: string | null;
              collectionName: string | null;
              productName: string | null;
              clientName: string | null;
              serviceName: string | null;
              quantity: string | null;
              unit: string | null;
              complete: boolean | null;
              imageKeys: Array<string | null> | null;
              qcApproved: boolean | null;
              previousTagDescriptions: Array<string | null> | null;
              notes: {
                __typename: 'ModelProductItemNoteConnection';
                items: Array<{
                  __typename: 'ProductItemNote';
                  id: string;
                  note: string | null;
                  timestamp: string | null;
                  author: {
                    __typename: 'User';
                    id: string;
                    firstName: string | null;
                    lastName: string | null;
                    email: string | null;
                  };
                } | null> | null;
              } | null;
            } | null> | null;
            nextToken: string | null;
          } | null;
          services: {
            __typename: 'ModelBatchCardServiceConnection';
            items: Array<{
              __typename: 'BatchCardService';
              service: {
                __typename: 'Service';
                id: string;
                name: string;
                icon: string | null;
              } | null;
            } | null> | null;
          } | null;
          spaces: {
            __typename: 'ModelBatchCardSpaceConnection';
            items: Array<{
              __typename: 'BatchCardSpace';
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyBatchCardsByUserQueryVariables = {
  id: string;
};

export type GetCompanyBatchCardsByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
          spaceAllocation: {
            __typename: 'ModelUserAllocationConnection';
            items: Array<{
              __typename: 'UserAllocation';
              id: string;
              date: string;
              user: {
                __typename: 'User';
                id: string;
                firstName: string | null;
                lastName: string | null;
                email: string | null;
                status: UserStatus | null;
                userRole: {
                  __typename: 'Role';
                  id: string;
                  name: string;
                  permission: Permission;
                } | null;
              } | null;
            } | null> | null;
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        items: Array<{
          __typename: 'BatchCard';
          id: string;
          createdAt: string;
          name: string | null;
          description: string | null;
          images: Array<string | null> | null;
          inBacklog: boolean | null;
          batchCardAllocations: {
            __typename: 'ModelBatchCardAllocationConnection';
            items: Array<{
              __typename: 'BatchCardAllocation';
              id: string;
              date: string | null;
              obsolete: boolean | null;
              batchCard: {
                __typename: 'BatchCard';
                id: string;
                name: string | null;
                productItems: {
                  __typename: 'ModelProductItemConnection';
                  items: Array<{
                    __typename: 'ProductItem';
                    id: string;
                    completedServices: Array<string | null> | null;
                  } | null> | null;
                } | null;
              } | null;
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
                status: SpaceStatus;
                spaceAllocation: {
                  __typename: 'ModelUserAllocationConnection';
                  items: Array<{
                    __typename: 'UserAllocation';
                    id: string;
                    date: string;
                    user: {
                      __typename: 'User';
                      id: string;
                      firstName: string | null;
                      lastName: string | null;
                      email: string | null;
                      status: UserStatus | null;
                      userRole: {
                        __typename: 'Role';
                        id: string;
                        name: string;
                        permission: Permission;
                      } | null;
                    } | null;
                  } | null> | null;
                  nextToken: string | null;
                } | null;
              } | null;
              batchCardServices: {
                __typename: 'ModelBatchCardServiceConnection';
                items: Array<{
                  __typename: 'BatchCardService';
                  id: string;
                  service: {
                    __typename: 'Service';
                    id: string;
                    icon: string | null;
                    name: string;
                  } | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
          productItems: {
            __typename: 'ModelProductItemConnection';
            items: Array<{
              __typename: 'ProductItem';
              id: string;
              uic: string | null;
              brand: {
                __typename: 'Brand';
                id: string;
                name: string;
              } | null;
              subBrand: {
                __typename: 'SubBrand';
                id: string;
                name: string;
              } | null;
              category: string | null;
              subCategory: string | null;
              type: string | null;
              gender: string | null;
              imageKeys: Array<string | null> | null;
              age: string | null;
              size: string | null;
              look: string | null;
              other1: string | null;
              other2: string | null;
              other3: string | null;
              season: string | null;
              collectionName: string | null;
              productName: string | null;
              clientName: string | null;
              serviceName: string | null;
              quantity: string | null;
              unit: string | null;
              complete: boolean | null;
              previousTagDescriptions: Array<string | null> | null;
              content: {
                __typename: 'ModelProductItemContentConnection';
                items: Array<{
                  __typename: 'ProductItemContent';
                  id: string;
                  header: string | null;
                  content: string | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
          services: {
            __typename: 'ModelBatchCardServiceConnection';
            items: Array<{
              __typename: 'BatchCardService';
              id: string;
              service: {
                __typename: 'Service';
                id: string;
                name: string;
                icon: string | null;
              } | null;
            } | null> | null;
          } | null;
          spaces: {
            __typename: 'ModelBatchCardSpaceConnection';
            items: Array<{
              __typename: 'BatchCardSpace';
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetBatchCardAllocationByIdQueryVariables = {
  id: string;
};

export type GetBatchCardAllocationByIdQuery = {
  getBatchCardAllocation: {
    __typename: 'BatchCardAllocation';
    id: string;
    date: string | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        items: Array<{
          __typename: 'BatchCardAllocation';
          id: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyBatchCardAllocationsByUserQueryVariables = {
  id: string;
};

export type GetCompanyBatchCardAllocationsByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
          spaceAllocation: {
            __typename: 'ModelUserAllocationConnection';
            items: Array<{
              __typename: 'UserAllocation';
              id: string;
              date: string;
              user: {
                __typename: 'User';
                id: string;
                firstName: string | null;
                lastName: string | null;
                email: string | null;
                status: UserStatus | null;
                userRole: {
                  __typename: 'Role';
                  id: string;
                  name: string;
                  permission: Permission;
                } | null;
              } | null;
            } | null> | null;
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        items: Array<{
          __typename: 'BatchCard';
          id: string;
          name: string | null;
          description: string | null;
          inBacklog: boolean | null;
          batchCardAllocations: {
            __typename: 'ModelBatchCardAllocationConnection';
            items: Array<{
              __typename: 'BatchCardAllocation';
              id: string;
              date: string | null;
              obsolete: boolean | null;
              batchCard: {
                __typename: 'BatchCard';
                id: string;
                name: string | null;
                productItems: {
                  __typename: 'ModelProductItemConnection';
                  items: Array<{
                    __typename: 'ProductItem';
                    id: string;
                  } | null> | null;
                } | null;
              } | null;
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
                status: SpaceStatus;
                spaceAllocation: {
                  __typename: 'ModelUserAllocationConnection';
                  items: Array<{
                    __typename: 'UserAllocation';
                    id: string;
                    date: string;
                    user: {
                      __typename: 'User';
                      id: string;
                      firstName: string | null;
                      lastName: string | null;
                      email: string | null;
                      status: UserStatus | null;
                      userRole: {
                        __typename: 'Role';
                        id: string;
                        name: string;
                        permission: Permission;
                      } | null;
                    } | null;
                  } | null> | null;
                  nextToken: string | null;
                } | null;
              } | null;
              batchCardServices: {
                __typename: 'ModelBatchCardServiceConnection';
                items: Array<{
                  __typename: 'BatchCardService';
                  id: string;
                  service: {
                    __typename: 'Service';
                    id: string;
                    icon: string | null;
                    name: string;
                  } | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
          productItems: {
            __typename: 'ModelProductItemConnection';
            items: Array<{
              __typename: 'ProductItem';
              id: string;
              uic: string | null;
              brand: {
                __typename: 'Brand';
                id: string;
                name: string;
              } | null;
              subBrand: {
                __typename: 'SubBrand';
                id: string;
                name: string;
              } | null;
              category: string | null;
              subCategory: string | null;
              type: string | null;
              gender: string | null;
              age: string | null;
              size: string | null;
              look: string | null;
              other1: string | null;
              other2: string | null;
              other3: string | null;
              season: string | null;
              collectionName: string | null;
              productName: string | null;
              clientName: string | null;
              serviceName: string | null;
              quantity: string | null;
              unit: string | null;
              complete: boolean | null;
            } | null> | null;
          } | null;
          services: {
            __typename: 'ModelBatchCardServiceConnection';
            items: Array<{
              __typename: 'BatchCardService';
              service: {
                __typename: 'Service';
                id: string;
                name: string;
                icon: string | null;
              } | null;
            } | null> | null;
          } | null;
          spaces: {
            __typename: 'ModelBatchCardSpaceConnection';
            items: Array<{
              __typename: 'BatchCardSpace';
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyBatchCardsByUserMainPlannerQueryVariables = {
  id: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetCompanyBatchCardsByUserMainPlannerQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
          spaceAllocation: {
            __typename: 'ModelUserAllocationConnection';
            items: Array<{
              __typename: 'UserAllocation';
              id: string;
              date: string;
              user: {
                __typename: 'User';
                id: string;
                firstName: string | null;
                lastName: string | null;
                email: string | null;
                status: UserStatus | null;
                userRole: {
                  __typename: 'Role';
                  id: string;
                  name: string;
                  permission: Permission;
                } | null;
              } | null;
            } | null> | null;
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        items: Array<{
          __typename: 'BatchCard';
          id: string;
          createdAt: string;
          name: string | null;
          description: string | null;
          images: Array<string | null> | null;
          inBacklog: boolean | null;
          batchCardAllocations: {
            __typename: 'ModelBatchCardAllocationConnection';
            items: Array<{
              __typename: 'BatchCardAllocation';
              id: string;
              date: string | null;
              obsolete: boolean | null;
              batchCard: {
                __typename: 'BatchCard';
                id: string;
                name: string | null;
                productItems: {
                  __typename: 'ModelProductItemConnection';
                  items: Array<{
                    __typename: 'ProductItem';
                    id: string;
                    complete: boolean | null;
                    completedServices: Array<string | null> | null;
                  } | null> | null;
                } | null;
              } | null;
              batchCardServices: {
                __typename: 'ModelBatchCardServiceConnection';
                items: Array<{
                  __typename: 'BatchCardService';
                  id: string;
                  isComplete: boolean;
                  service: {
                    __typename: 'Service';
                    id: string;
                    icon: string | null;
                    name: string;
                  } | null;
                } | null> | null;
              } | null;
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
                status: SpaceStatus;
                spaceAllocation: {
                  __typename: 'ModelUserAllocationConnection';
                  items: Array<{
                    __typename: 'UserAllocation';
                    id: string;
                    date: string;
                    user: {
                      __typename: 'User';
                      id: string;
                      firstName: string | null;
                      lastName: string | null;
                      email: string | null;
                      status: UserStatus | null;
                      userRole: {
                        __typename: 'Role';
                        id: string;
                        name: string;
                        permission: Permission;
                      } | null;
                    } | null;
                  } | null> | null;
                  nextToken: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          productItems: {
            __typename: 'ModelProductItemConnection';
            items: Array<{
              __typename: 'ProductItem';
              id: string;
              uic: string | null;
              brand: {
                __typename: 'Brand';
                id: string;
                name: string;
              } | null;
              subBrand: {
                __typename: 'SubBrand';
                id: string;
                name: string;
              } | null;
              category: string | null;
              subCategory: string | null;
              type: string | null;
              gender: string | null;
              imageKeys: Array<string | null> | null;
              age: string | null;
              size: string | null;
              look: string | null;
              other1: string | null;
              other2: string | null;
              other3: string | null;
              season: string | null;
              collectionName: string | null;
              productName: string | null;
              clientName: string | null;
              serviceName: string | null;
              quantity: string | null;
              unit: string | null;
              complete: boolean | null;
              content: {
                __typename: 'ModelProductItemContentConnection';
                items: Array<{
                  __typename: 'ProductItemContent';
                  id: string;
                  header: string | null;
                  content: string | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
          services: {
            __typename: 'ModelBatchCardServiceConnection';
            items: Array<{
              __typename: 'BatchCardService';
              id: string;
              service: {
                __typename: 'Service';
                id: string;
                name: string;
                icon: string | null;
              } | null;
            } | null> | null;
          } | null;
          spaces: {
            __typename: 'ModelBatchCardSpaceConnection';
            items: Array<{
              __typename: 'BatchCardSpace';
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyBatchCardAllocationsForTheWeekByUserQueryVariables = {
  id: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetCompanyBatchCardAllocationsForTheWeekByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
          spaceAllocation: {
            __typename: 'ModelUserAllocationConnection';
            items: Array<{
              __typename: 'UserAllocation';
              id: string;
              date: string;
              user: {
                __typename: 'User';
                id: string;
                firstName: string | null;
                lastName: string | null;
                email: string | null;
                status: UserStatus | null;
                userRole: {
                  __typename: 'Role';
                  id: string;
                  name: string;
                  permission: Permission;
                } | null;
              } | null;
            } | null> | null;
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        items: Array<{
          __typename: 'BatchCard';
          id: string;
          name: string | null;
          description: string | null;
          inBacklog: boolean | null;
          services: {
            __typename: 'ModelBatchCardServiceConnection';
            items: Array<{
              __typename: 'BatchCardService';
              id: string;
              isComplete: boolean;
              service: {
                __typename: 'Service';
                id: string;
                name: string;
                icon: string | null;
              } | null;
            } | null> | null;
          } | null;
          batchCardAllocations: {
            __typename: 'ModelBatchCardAllocationConnection';
            items: Array<{
              __typename: 'BatchCardAllocation';
              id: string;
              date: string | null;
              obsolete: boolean | null;
              batchCard: {
                __typename: 'BatchCard';
                id: string;
                name: string | null;
                productItems: {
                  __typename: 'ModelProductItemConnection';
                  items: Array<{
                    __typename: 'ProductItem';
                    id: string;
                    complete: boolean | null;
                    qcApproved: boolean | null;
                  } | null> | null;
                } | null;
              } | null;
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
                status: SpaceStatus;
                spaceAllocation: {
                  __typename: 'ModelUserAllocationConnection';
                  items: Array<{
                    __typename: 'UserAllocation';
                    id: string;
                    date: string;
                    user: {
                      __typename: 'User';
                      id: string;
                      firstName: string | null;
                      lastName: string | null;
                      email: string | null;
                      status: UserStatus | null;
                      userRole: {
                        __typename: 'Role';
                        id: string;
                        name: string;
                        permission: Permission;
                      } | null;
                    } | null;
                  } | null> | null;
                  nextToken: string | null;
                } | null;
              } | null;
              batchCardServices: {
                __typename: 'ModelBatchCardServiceConnection';
                items: Array<{
                  __typename: 'BatchCardService';
                  id: string;
                  service: {
                    __typename: 'Service';
                    id: string;
                    icon: string | null;
                    name: string;
                  } | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
          productItems: {
            __typename: 'ModelProductItemConnection';
            items: Array<{
              __typename: 'ProductItem';
              id: string;
              uic: string | null;
              brand: {
                __typename: 'Brand';
                id: string;
                name: string;
              } | null;
              subBrand: {
                __typename: 'SubBrand';
                id: string;
                name: string;
              } | null;
              category: string | null;
              subCategory: string | null;
              type: string | null;
              gender: string | null;
              age: string | null;
              size: string | null;
              look: string | null;
              other1: string | null;
              other2: string | null;
              other3: string | null;
              season: string | null;
              collectionName: string | null;
              productName: string | null;
              clientName: string | null;
              serviceName: string | null;
              quantity: string | null;
              unit: string | null;
              complete: boolean | null;
            } | null> | null;
          } | null;
          spaces: {
            __typename: 'ModelBatchCardSpaceConnection';
            items: Array<{
              __typename: 'BatchCardSpace';
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyBatchCardAllocationsForTheMonthByUserQueryVariables = {
  id: string;
  month?: string | null;
};

export type GetCompanyBatchCardAllocationsForTheMonthByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
          spaceAllocation: {
            __typename: 'ModelUserAllocationConnection';
            items: Array<{
              __typename: 'UserAllocation';
              id: string;
              date: string;
              user: {
                __typename: 'User';
                id: string;
                firstName: string | null;
                lastName: string | null;
                email: string | null;
                status: UserStatus | null;
                userRole: {
                  __typename: 'Role';
                  id: string;
                  name: string;
                  permission: Permission;
                } | null;
              } | null;
            } | null> | null;
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        items: Array<{
          __typename: 'BatchCard';
          id: string;
          name: string | null;
          description: string | null;
          inBacklog: boolean | null;
          services: {
            __typename: 'ModelBatchCardServiceConnection';
            items: Array<{
              __typename: 'BatchCardService';
              id: string;
              isComplete: boolean;
              service: {
                __typename: 'Service';
                id: string;
                name: string;
                icon: string | null;
              } | null;
            } | null> | null;
          } | null;
          batchCardAllocations: {
            __typename: 'ModelBatchCardAllocationConnection';
            items: Array<{
              __typename: 'BatchCardAllocation';
              id: string;
              date: string | null;
              obsolete: boolean | null;
              batchCard: {
                __typename: 'BatchCard';
                id: string;
                name: string | null;
                productItems: {
                  __typename: 'ModelProductItemConnection';
                  items: Array<{
                    __typename: 'ProductItem';
                    id: string;
                    complete: boolean | null;
                  } | null> | null;
                } | null;
              } | null;
              space: {
                __typename: 'Space';
                id: string;
                colorCode: string;
                name: string;
                spaceAllocation: {
                  __typename: 'ModelUserAllocationConnection';
                  items: Array<{
                    __typename: 'UserAllocation';
                    id: string;
                    date: string;
                    user: {
                      __typename: 'User';
                      id: string;
                      firstName: string | null;
                      lastName: string | null;
                      email: string | null;
                      status: UserStatus | null;
                      userRole: {
                        __typename: 'Role';
                        id: string;
                        name: string;
                        permission: Permission;
                      } | null;
                    } | null;
                  } | null> | null;
                  nextToken: string | null;
                } | null;
              } | null;
              batchCardServices: {
                __typename: 'ModelBatchCardServiceConnection';
                items: Array<{
                  __typename: 'BatchCardService';
                  id: string;
                  service: {
                    __typename: 'Service';
                    id: string;
                    icon: string | null;
                    name: string;
                  } | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
          productItems: {
            __typename: 'ModelProductItemConnection';
            items: Array<{
              __typename: 'ProductItem';
              id: string;
              uic: string | null;
              brand: {
                __typename: 'Brand';
                id: string;
                name: string;
              } | null;
              subBrand: {
                __typename: 'SubBrand';
                id: string;
                name: string;
              } | null;
              category: string | null;
              subCategory: string | null;
              type: string | null;
              gender: string | null;
              age: string | null;
              size: string | null;
              look: string | null;
              other1: string | null;
              other2: string | null;
              other3: string | null;
              season: string | null;
              collectionName: string | null;
              productName: string | null;
              clientName: string | null;
              serviceName: string | null;
              quantity: string | null;
              unit: string | null;
              complete: boolean | null;
            } | null> | null;
          } | null;
          spaces: {
            __typename: 'ModelBatchCardSpaceConnection';
            items: Array<{
              __typename: 'BatchCardSpace';
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyBatchCardAllocationsForTheYearByUserQueryVariables = {
  id: string;
  year?: string | null;
};

export type GetCompanyBatchCardAllocationsForTheYearByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
          spaceAllocation: {
            __typename: 'ModelUserAllocationConnection';
            items: Array<{
              __typename: 'UserAllocation';
              id: string;
              date: string;
              user: {
                __typename: 'User';
                id: string;
                firstName: string | null;
                lastName: string | null;
                email: string | null;
                status: UserStatus | null;
                userRole: {
                  __typename: 'Role';
                  id: string;
                  name: string;
                  permission: Permission;
                } | null;
              } | null;
            } | null> | null;
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        items: Array<{
          __typename: 'BatchCard';
          id: string;
          name: string | null;
          description: string | null;
          inBacklog: boolean | null;
          services: {
            __typename: 'ModelBatchCardServiceConnection';
            items: Array<{
              __typename: 'BatchCardService';
              id: string;
              isComplete: boolean;
              service: {
                __typename: 'Service';
                id: string;
                name: string;
                icon: string | null;
              } | null;
            } | null> | null;
          } | null;
          batchCardAllocations: {
            __typename: 'ModelBatchCardAllocationConnection';
            items: Array<{
              __typename: 'BatchCardAllocation';
              id: string;
              date: string | null;
              obsolete: boolean | null;
              batchCard: {
                __typename: 'BatchCard';
                id: string;
                name: string | null;
                productItems: {
                  __typename: 'ModelProductItemConnection';
                  items: Array<{
                    __typename: 'ProductItem';
                    id: string;
                    complete: boolean | null;
                  } | null> | null;
                } | null;
              } | null;
              space: {
                __typename: 'Space';
                id: string;
                colorCode: string;
                name: string;
                spaceAllocation: {
                  __typename: 'ModelUserAllocationConnection';
                  items: Array<{
                    __typename: 'UserAllocation';
                    id: string;
                    date: string;
                    user: {
                      __typename: 'User';
                      id: string;
                      firstName: string | null;
                      lastName: string | null;
                      email: string | null;
                      status: UserStatus | null;
                      userRole: {
                        __typename: 'Role';
                        id: string;
                        name: string;
                        permission: Permission;
                      } | null;
                    } | null;
                  } | null> | null;
                  nextToken: string | null;
                } | null;
              } | null;
              batchCardServices: {
                __typename: 'ModelBatchCardServiceConnection';
                items: Array<{
                  __typename: 'BatchCardService';
                  id: string;
                  service: {
                    __typename: 'Service';
                    id: string;
                    icon: string | null;
                    name: string;
                  } | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
          productItems: {
            __typename: 'ModelProductItemConnection';
            items: Array<{
              __typename: 'ProductItem';
              id: string;
              uic: string | null;
              brand: {
                __typename: 'Brand';
                id: string;
                name: string;
              } | null;
              subBrand: {
                __typename: 'SubBrand';
                id: string;
                name: string;
              } | null;
              category: string | null;
              subCategory: string | null;
              type: string | null;
              gender: string | null;
              age: string | null;
              size: string | null;
              look: string | null;
              other1: string | null;
              other2: string | null;
              other3: string | null;
              season: string | null;
              collectionName: string | null;
              productName: string | null;
              clientName: string | null;
              serviceName: string | null;
              quantity: string | null;
              unit: string | null;
              complete: boolean | null;
            } | null> | null;
          } | null;
          spaces: {
            __typename: 'ModelBatchCardSpaceConnection';
            items: Array<{
              __typename: 'BatchCardSpace';
              space: {
                __typename: 'Space';
                id: string;
                name: string;
                colorCode: string;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type getCompanyProductItemsByUserQueryVariables = {
  id: string;
};

export type getCompanyProductItemsByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          category: string | null;
          subCategory: string | null;
          type: string | null;
          tagged: boolean | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          gender: string | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          returnedToItems: boolean | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
            name: string | null;
            description: string | null;
          } | null;
          content: {
            __typename: 'ModelProductItemContentConnection';
            items: Array<{
              __typename: 'ProductItemContent';
              id: string;
              header: string | null;
              content: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyProductItemsForTheWeekByUserQueryVariables = {
  id: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetCompanyProductItemsForTheWeekByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          category: string | null;
          subCategory: string | null;
          type: string | null;
          tagged: boolean | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          gender: string | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
            name: string | null;
            description: string | null;
          } | null;
          content: {
            __typename: 'ModelProductItemContentConnection';
            items: Array<{
              __typename: 'ProductItemContent';
              id: string;
              header: string | null;
              content: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListProductItemsCreatedInSelectedWeekQueryVariables = {
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type ListProductItemsCreatedInSelectedWeekQuery = {
  listProductItems: {
    __typename: 'ModelProductItemConnection';
    items: Array<{
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      season: string | null;
      collectionName: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      uic: string | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
      } | null;
    } | null> | null;
  } | null;
};

export type ListProductItemsCreatedInSelectedMonthQueryVariables = {
  month?: string | null;
};

export type ListProductItemsCreatedInSelectedMonthQuery = {
  listProductItems: {
    __typename: 'ModelProductItemConnection';
    items: Array<{
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      season: string | null;
      collectionName: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      uic: string | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
      } | null;
    } | null> | null;
  } | null;
};

export type ListProductItemsCreatedInSelectedYearQueryVariables = {
  year?: string | null;
};

export type ListProductItemsCreatedInSelectedYearQuery = {
  listProductItems: {
    __typename: 'ModelProductItemConnection';
    items: Array<{
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      season: string | null;
      collectionName: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      uic: string | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
      } | null;
    } | null> | null;
  } | null;
};

export type ListSpacesCreatedInSelectedWeekQueryVariables = {
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type ListSpacesCreatedInSelectedWeekQuery = {
  listSpaces: {
    __typename: 'ModelSpaceConnection';
    items: Array<{
      __typename: 'Space';
      id: string;
      createdAt: string;
      colorCode: string;
      name: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
      } | null;
    } | null> | null;
  } | null;
};

export type ListSpacesCreatedInSelectedMonthQueryVariables = {
  month?: string | null;
};

export type ListSpacesCreatedInSelectedMonthQuery = {
  listSpaces: {
    __typename: 'ModelSpaceConnection';
    items: Array<{
      __typename: 'Space';
      id: string;
      createdAt: string;
      colorCode: string;
      name: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
      } | null;
    } | null> | null;
  } | null;
};

export type ListSpacesCreatedInSelectedYearQueryVariables = {
  year?: string | null;
};

export type ListSpacesCreatedInSelectedYearQuery = {
  listSpaces: {
    __typename: 'ModelSpaceConnection';
    items: Array<{
      __typename: 'Space';
      id: string;
      createdAt: string;
      colorCode: string;
      name: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
      } | null;
    } | null> | null;
  } | null;
};

export type ListUsersCreatedInSelectedWeekQueryVariables = {
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type ListUsersCreatedInSelectedWeekQuery = {
  listUsers: {
    __typename: 'ModelUserConnection';
    items: Array<{
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
      } | null;
      status: UserStatus | null;
    } | null> | null;
  } | null;
};

export type ListUsersCreatedInSelectedMonthQueryVariables = {
  month?: string | null;
};

export type ListUsersCreatedInSelectedMonthQuery = {
  listUsers: {
    __typename: 'ModelUserConnection';
    items: Array<{
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
      } | null;
      status: UserStatus | null;
    } | null> | null;
  } | null;
};

export type ListUsersCreatedInSelectedYearQueryVariables = {
  year?: string | null;
};

export type ListUsersCreatedInSelectedYearQuery = {
  listUsers: {
    __typename: 'ModelUserConnection';
    items: Array<{
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
      } | null;
      status: UserStatus | null;
    } | null> | null;
  } | null;
};

export type ListCompaniesCreatedInSelectedWeekQueryVariables = {
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type ListCompaniesCreatedInSelectedWeekQuery = {
  listCompanys: {
    __typename: 'ModelCompanyConnection';
    items: Array<{
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      subscriptionType: string | null;
      users: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListCompaniesCreatedInSelectedMonthQueryVariables = {
  month?: string | null;
};

export type ListCompaniesCreatedInSelectedMonthQuery = {
  listCompanys: {
    __typename: 'ModelCompanyConnection';
    items: Array<{
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      subscriptionType: string | null;
      users: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListCompaniesCreatedInSelectedYearQueryVariables = {
  year?: string | null;
};

export type ListCompaniesCreatedInSelectedYearQuery = {
  listCompanys: {
    __typename: 'ModelCompanyConnection';
    items: Array<{
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      subscriptionType: string | null;
      users: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          email: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCompanyProductItemsForTheMonthByUserQueryVariables = {
  id: string;
  month?: string | null;
};

export type GetCompanyProductItemsForTheMonthByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          category: string | null;
          subCategory: string | null;
          type: string | null;
          tagged: boolean | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          gender: string | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
            name: string | null;
            description: string | null;
          } | null;
          content: {
            __typename: 'ModelProductItemContentConnection';
            items: Array<{
              __typename: 'ProductItemContent';
              id: string;
              header: string | null;
              content: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetAllCompanyProductItemsForTheWeekByUserQueryVariables = {
  id: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetAllCompanyProductItemsForTheWeekByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          category: string | null;
          subCategory: string | null;
          type: string | null;
          tagged: boolean | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          gender: string | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
            name: string | null;
            description: string | null;
          } | null;
          content: {
            __typename: 'ModelProductItemContentConnection';
            items: Array<{
              __typename: 'ProductItemContent';
              id: string;
              header: string | null;
              content: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetAllCompanyProductItemsForTheMonthByUserQueryVariables = {
  id: string;
  month?: string | null;
};

export type GetAllCompanyProductItemsForTheMonthByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          category: string | null;
          subCategory: string | null;
          type: string | null;
          tagged: boolean | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          gender: string | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
            name: string | null;
            description: string | null;
          } | null;
          content: {
            __typename: 'ModelProductItemContentConnection';
            items: Array<{
              __typename: 'ProductItemContent';
              id: string;
              header: string | null;
              content: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type getCompanyTaggedProductItemsByUserQueryVariables = {
  id: string;
};

export type getCompanyTaggedProductItemsByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          imageKeys: Array<string | null> | null;
          category: string | null;
          completedServices: Array<string | null> | null;
          subCategory: string | null;
          tagged: boolean | null;
          taggedAt: string | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          type: string | null;
          gender: string | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          qcApproved: boolean | null;
          returnedToItems: boolean | null;
          notes: {
            __typename: 'ModelProductItemNoteConnection';
            items: Array<{
              __typename: 'ProductItemNote';
              id: string;
              note: string | null;
              timestamp: string | null;
              author: {
                __typename: 'User';
                id: string;
                firstName: string | null;
                lastName: string | null;
                email: string | null;
              };
            } | null> | null;
            nextToken: string | null;
          } | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            name: string | null;
            description: string | null;
            createdAt: string;
          } | null;
          content: {
            __typename: 'ModelProductItemContentConnection';
            items: Array<{
              __typename: 'ProductItemContent';
              id: string;
              header: string | null;
              content: string | null;
              createdAt: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetTaggedCompanyProductItemsForTheWeekByUserQueryVariables = {
  id: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetTaggedCompanyProductItemsForTheWeekByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          category: string | null;
          subCategory: string | null;
          type: string | null;
          tagged: boolean | null;
          taggedAt: string | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          gender: string | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
            name: string | null;
            description: string | null;
          } | null;
          content: {
            __typename: 'ModelProductItemContentConnection';
            items: Array<{
              __typename: 'ProductItemContent';
              id: string;
              header: string | null;
              content: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetTaggedCompanyProductItemsForTheMonthByUserQueryVariables = {
  id: string;
  month?: string | null;
};

export type GetTaggedCompanyProductItemsForTheMonthByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          category: string | null;
          subCategory: string | null;
          type: string | null;
          tagged: boolean | null;
          taggedAt: string | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          gender: string | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
            name: string | null;
            description: string | null;
          } | null;
          content: {
            __typename: 'ModelProductItemContentConnection';
            items: Array<{
              __typename: 'ProductItemContent';
              id: string;
              header: string | null;
              content: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyTaggedProductItemsForTheWeekByUserQueryVariables = {
  id: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetCompanyTaggedProductItemsForTheWeekByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          category: string | null;
          subCategory: string | null;
          type: string | null;
          tagged: boolean | null;
          taggedAt: string | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          gender: string | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
            name: string | null;
            description: string | null;
          } | null;
          content: {
            __typename: 'ModelProductItemContentConnection';
            items: Array<{
              __typename: 'ProductItemContent';
              id: string;
              header: string | null;
              content: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type getCompanyCompletedProductItemsByUserQueryVariables = {
  id: string;
};

export type getCompanyCompletedProductItemsByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          category: string | null;
          completedServices: Array<string | null> | null;
          subCategory: string | null;
          tagged: boolean | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          type: string | null;
          gender: string | null;
          imageKeys: Array<string | null> | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          qcApproved: boolean | null;
          content: {
            __typename: 'ModelProductItemContentConnection';
            items: Array<{
              __typename: 'ProductItemContent';
              id: string;
              header: string | null;
              content: string | null;
            } | null> | null;
          } | null;
          notes: {
            __typename: 'ModelProductItemNoteConnection';
            items: Array<{
              __typename: 'ProductItemNote';
              id: string;
              note: string | null;
              timestamp: string | null;
              author: {
                __typename: 'User';
                id: string;
                firstName: string | null;
                lastName: string | null;
                email: string | null;
              };
            } | null> | null;
            nextToken: string | null;
          } | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            name: string | null;
            description: string | null;
            productItems: {
              __typename: 'ModelProductItemConnection';
              items: Array<{
                __typename: 'ProductItem';
                id: string;
                complete: boolean | null;
                qcApproved: boolean | null;
                content: {
                  __typename: 'ModelProductItemContentConnection';
                  items: Array<{
                    __typename: 'ProductItemContent';
                    id: string;
                    header: string | null;
                    content: string | null;
                  } | null> | null;
                } | null;
              } | null> | null;
            } | null;
            services: {
              __typename: 'ModelBatchCardServiceConnection';
              items: Array<{
                __typename: 'BatchCardService';
                service: {
                  __typename: 'Service';
                  id: string;
                  name: string;
                  icon: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type getCompanyArchivedProductItemsByUserQueryVariables = {
  id: string;
};

export type getCompanyArchivedProductItemsByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        quantity: boolean | null;
        unit: boolean | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          uic: string | null;
          brand: {
            __typename: 'Brand';
            id: string;
            name: string;
          } | null;
          subBrand: {
            __typename: 'SubBrand';
            id: string;
            name: string;
          } | null;
          category: string | null;
          completedServices: Array<string | null> | null;
          subCategory: string | null;
          tagged: boolean | null;
          tagDescription: string | null;
          previousTagDescriptions: Array<string | null> | null;
          type: string | null;
          gender: string | null;
          imageKeys: Array<string | null> | null;
          age: string | null;
          size: string | null;
          look: string | null;
          other1: string | null;
          other2: string | null;
          other3: string | null;
          season: string | null;
          collectionName: string | null;
          productName: string | null;
          clientName: string | null;
          serviceName: string | null;
          quantity: string | null;
          unit: string | null;
          complete: boolean | null;
          notes: {
            __typename: 'ModelProductItemNoteConnection';
            items: Array<{
              __typename: 'ProductItemNote';
              id: string;
              note: string | null;
              timestamp: string | null;
              author: {
                __typename: 'User';
                id: string;
                firstName: string | null;
                lastName: string | null;
                email: string | null;
              };
            } | null> | null;
            nextToken: string | null;
          } | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            name: string | null;
            description: string | null;
            services: {
              __typename: 'ModelBatchCardServiceConnection';
              items: Array<{
                __typename: 'BatchCardService';
                service: {
                  __typename: 'Service';
                  id: string;
                  name: string;
                  icon: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyServicesByUserQueryVariables = {
  id: string;
};

export type GetCompanyServicesByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      services: {
        __typename: 'ModelServiceConnection';
        items: Array<{
          __typename: 'Service';
          id: string;
          name: string;
          icon: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanySpacesByUserQueryVariables = {
  id: string;
};

export type GetCompanySpacesByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyActiveSpacesByUserQueryVariables = {
  id: string;
};

export type GetCompanyActiveSpacesByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      spaces: {
        __typename: 'ModelSpaceConnection';
        items: Array<{
          __typename: 'Space';
          id: string;
          name: string;
          colorCode: string;
          status: SpaceStatus;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyRolesByUserQueryVariables = {
  id: string;
};

export type GetCompanyRolesByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      roles: {
        __typename: 'ModelRoleConnection';
        items: Array<{
          __typename: 'Role';
          id: string;
          name: string;
          permission: Permission;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyUsersByUserQueryVariables = {
  id: string;
};

export type GetCompanyUsersByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    userRole: {
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      roles: {
        __typename: 'ModelRoleConnection';
        items: Array<{
          __typename: 'Role';
          id: string;
          name: string;
          permission: Permission;
        } | null> | null;
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string | null;
          phoneNumber: string | null;
          avatarKey: string | null;
          avatarUrl: string | null;
          archived: boolean | null;
          verified: boolean | null;
          status: UserStatus | null;
          inviteLater: boolean | null;
          userRole: {
            __typename: 'Role';
            id: string;
            name: string;
            permission: Permission;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyActiveUsersByUserQueryVariables = {
  id: string;
};

export type GetCompanyActiveUsersByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    userRole: {
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      roles: {
        __typename: 'ModelRoleConnection';
        items: Array<{
          __typename: 'Role';
          id: string;
          name: string;
          permission: Permission;
        } | null> | null;
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstName: string | null;
          lastName: string | null;
          email: string | null;
          phoneNumber: string | null;
          avatarKey: string | null;
          avatarUrl: string | null;
          archived: boolean | null;
          verified: boolean | null;
          status: UserStatus | null;
          userRole: {
            __typename: 'Role';
            id: string;
            name: string;
            permission: Permission;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyBrandsAndSubBrandsByUserQueryVariables = {
  id: string;
};

export type GetCompanyBrandsAndSubBrandsByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      brands: {
        __typename: 'ModelBrandConnection';
        items: Array<{
          __typename: 'Brand';
          id: string;
          name: string;
          subBrands: {
            __typename: 'ModelSubBrandConnection';
            items: Array<{
              __typename: 'SubBrand';
              id: string;
              name: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyBatchCardsForTheWeekByUserQueryVariables = {
  id: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetCompanyBatchCardsForTheWeekByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        items: Array<{
          __typename: 'BatchCard';
          id: string;
          createdAt: string;
          productItems: {
            __typename: 'ModelProductItemConnection';
            items: Array<{
              __typename: 'ProductItem';
              createdAt: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompanyBatchCardsForTheMonthByUserQueryVariables = {
  id: string;
  month?: string | null;
};

export type GetCompanyBatchCardsForTheMonthByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        items: Array<{
          __typename: 'BatchCard';
          id: string;
          createdAt: string;
          productItems: {
            __typename: 'ModelProductItemConnection';
            items: Array<{
              __typename: 'ProductItem';
              createdAt: string;
            } | null> | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompletedProductItemsForTheWeekByUserQueryVariables = {
  id: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetCompletedProductItemsForTheWeekByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          completedAt: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCompletedProductItemsForTheMonthByUserQueryVariables = {
  id: string;
  month?: string | null;
};

export type GetCompletedProductItemsForTheMonthByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          completedAt: string | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetQCApprovedProductItemsForTheWeekByUserQueryVariables = {
  id: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetQCApprovedProductItemsForTheWeekByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          qcApprovedAt: string | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetQCApprovedProductItemsForTheMonthByUserQueryVariables = {
  id: string;
  month?: string | null;
};

export type GetQCApprovedProductItemsForTheMonthByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          qcApprovedAt: string | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetBatchCardsForTheMonthByUserAndSpaceQueryVariables = {
  spaceId: string;
  month?: string | null;
};

export type GetBatchCardsForTheMonthByUserAndSpaceQuery = {
  listSpaces: {
    __typename: 'ModelSpaceConnection';
    items: Array<{
      __typename: 'Space';
      id: string;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        items: Array<{
          __typename: 'BatchCardAllocation';
          id: string;
          createdAt: string;
          space: {
            __typename: 'Space';
            id: string;
            name: string;
          } | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
            productItems: {
              __typename: 'ModelProductItemConnection';
              items: Array<{
                __typename: 'ProductItem';
                id: string;
                createdAt: string;
              } | null> | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetBatchCardsForTheWeekByUserAndSpaceQueryVariables = {
  spaceId: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetBatchCardsForTheWeekByUserAndSpaceQuery = {
  listSpaces: {
    __typename: 'ModelSpaceConnection';
    items: Array<{
      __typename: 'Space';
      id: string;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        items: Array<{
          __typename: 'BatchCardAllocation';
          id: string;
          createdAt: string;
          space: {
            __typename: 'Space';
            id: string;
            name: string;
          } | null;
          batchCard: {
            __typename: 'BatchCard';
            id: string;
            createdAt: string;
            productItems: {
              __typename: 'ModelProductItemConnection';
              items: Array<{
                __typename: 'ProductItem';
                id: string;
                createdAt: string;
              } | null> | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetProductItemServicesForTheMonthByUserQueryVariables = {
  id: string;
  month: string;
};

export type GetProductItemServicesForTheMonthByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          productItemServices: Array<string | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type GetProductItemServicesForTheWeekByUserQueryVariables = {
  id: string;
  dayOne?: string | null;
  dayTwo?: string | null;
  dayThree?: string | null;
  dayFour?: string | null;
  dayFive?: string | null;
  daySix?: string | null;
  daySeven?: string | null;
};

export type GetProductItemServicesForTheWeekByUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          createdAt: string;
          productItemServices: Array<string | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type getCompanyProductItemsByUserAndUicQueryVariables = {
  userId: string;
  uic: string;
};

export type getCompanyProductItemsByUserAndUicQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    company: {
      __typename: 'Company';
      id: string;
      productItems: {
        __typename: 'ModelProductItemConnection';
        items: Array<{
          __typename: 'ProductItem';
          id: string;
          uic: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type CreateTaskMutationVariables = {
  input: CreateTaskInput;
};

export type CreateTaskMutation = {
  createTask: {
    __typename: 'Task';
    id: string;
    title: string;
    description: string | null;
    status: string | null;
    assignedTo: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateTaskMutationVariables = {
  input: UpdateTaskInput;
};

export type UpdateTaskMutation = {
  updateTask: {
    __typename: 'Task';
    id: string;
    title: string;
    description: string | null;
    status: string | null;
    assignedTo: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteTaskMutationVariables = {
  input: DeleteTaskInput;
};

export type DeleteTaskMutation = {
  deleteTask: {
    __typename: 'Task';
    id: string;
    title: string;
    description: string | null;
    status: string | null;
    assignedTo: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreatePrivateNoteMutationVariables = {
  input: CreatePrivateNoteInput;
};

export type CreatePrivateNoteMutation = {
  createPrivateNote: {
    __typename: 'PrivateNote';
    id: string;
    content: string;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdatePrivateNoteMutationVariables = {
  input: UpdatePrivateNoteInput;
};

export type UpdatePrivateNoteMutation = {
  updatePrivateNote: {
    __typename: 'PrivateNote';
    id: string;
    content: string;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeletePrivateNoteMutationVariables = {
  input: DeletePrivateNoteInput;
};

export type DeletePrivateNoteMutation = {
  deletePrivateNote: {
    __typename: 'PrivateNote';
    id: string;
    content: string;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput;
};

export type CreateCompanyMutation = {
  createCompany: {
    __typename: 'Company';
    id: string;
    createdAt: string;
    name: string;
    address: string | null;
    state: string | null;
    industry: string | null;
    country: string | null;
    companySize: string | null;
    stripeCustomerId: string | null;
    stripeSubscriptionPlanId: string | null;
    subscriptionType: string | null;
    accountDisabled: boolean | null;
    itemTemplate: {
      __typename: 'ItemTemplate';
      id: string;
      uic: boolean | null;
      brand: boolean | null;
      subBrand: boolean | null;
      category: boolean | null;
      subCategory: boolean | null;
      type: boolean | null;
      gender: boolean | null;
      age: boolean | null;
      size: boolean | null;
      look: boolean | null;
      other1: boolean | null;
      other2: boolean | null;
      other3: boolean | null;
      season: boolean | null;
      collectionName: boolean | null;
      productName: boolean | null;
      quantity: boolean | null;
      clientName: boolean | null;
      serviceName: boolean | null;
      unit: boolean | null;
      itemTemplateType: ItemTemplateType | null;
      companies: {
        __typename: 'ModelCompanyConnection';
        nextToken: string | null;
      } | null;
    } | null;
    roles: {
      __typename: 'ModelRoleConnection';
      items: Array<{
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null> | null;
      nextToken: string | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaces: {
      __typename: 'ModelSpaceConnection';
      items: Array<{
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null> | null;
      nextToken: string | null;
    } | null;
    brands: {
      __typename: 'ModelBrandConnection';
      items: Array<{
        __typename: 'Brand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelServiceConnection';
      items: Array<{
        __typename: 'Service';
        id: string;
        name: string;
        icon: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardConnection';
      items: Array<{
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput;
};

export type UpdateCompanyMutation = {
  updateCompany: {
    __typename: 'Company';
    id: string;
    createdAt: string;
    name: string;
    address: string | null;
    state: string | null;
    industry: string | null;
    country: string | null;
    companySize: string | null;
    stripeCustomerId: string | null;
    stripeSubscriptionPlanId: string | null;
    subscriptionType: string | null;
    accountDisabled: boolean | null;
    itemTemplate: {
      __typename: 'ItemTemplate';
      id: string;
      uic: boolean | null;
      brand: boolean | null;
      subBrand: boolean | null;
      category: boolean | null;
      subCategory: boolean | null;
      type: boolean | null;
      gender: boolean | null;
      age: boolean | null;
      size: boolean | null;
      look: boolean | null;
      other1: boolean | null;
      other2: boolean | null;
      other3: boolean | null;
      season: boolean | null;
      collectionName: boolean | null;
      productName: boolean | null;
      quantity: boolean | null;
      clientName: boolean | null;
      serviceName: boolean | null;
      unit: boolean | null;
      itemTemplateType: ItemTemplateType | null;
      companies: {
        __typename: 'ModelCompanyConnection';
        nextToken: string | null;
      } | null;
    } | null;
    roles: {
      __typename: 'ModelRoleConnection';
      items: Array<{
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null> | null;
      nextToken: string | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaces: {
      __typename: 'ModelSpaceConnection';
      items: Array<{
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null> | null;
      nextToken: string | null;
    } | null;
    brands: {
      __typename: 'ModelBrandConnection';
      items: Array<{
        __typename: 'Brand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelServiceConnection';
      items: Array<{
        __typename: 'Service';
        id: string;
        name: string;
        icon: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardConnection';
      items: Array<{
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput;
};

export type DeleteCompanyMutation = {
  deleteCompany: {
    __typename: 'Company';
    id: string;
    createdAt: string;
    name: string;
    address: string | null;
    state: string | null;
    industry: string | null;
    country: string | null;
    companySize: string | null;
    stripeCustomerId: string | null;
    stripeSubscriptionPlanId: string | null;
    subscriptionType: string | null;
    accountDisabled: boolean | null;
    itemTemplate: {
      __typename: 'ItemTemplate';
      id: string;
      uic: boolean | null;
      brand: boolean | null;
      subBrand: boolean | null;
      category: boolean | null;
      subCategory: boolean | null;
      type: boolean | null;
      gender: boolean | null;
      age: boolean | null;
      size: boolean | null;
      look: boolean | null;
      other1: boolean | null;
      other2: boolean | null;
      other3: boolean | null;
      season: boolean | null;
      collectionName: boolean | null;
      productName: boolean | null;
      quantity: boolean | null;
      clientName: boolean | null;
      serviceName: boolean | null;
      unit: boolean | null;
      itemTemplateType: ItemTemplateType | null;
      companies: {
        __typename: 'ModelCompanyConnection';
        nextToken: string | null;
      } | null;
    } | null;
    roles: {
      __typename: 'ModelRoleConnection';
      items: Array<{
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null> | null;
      nextToken: string | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaces: {
      __typename: 'ModelSpaceConnection';
      items: Array<{
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null> | null;
      nextToken: string | null;
    } | null;
    brands: {
      __typename: 'ModelBrandConnection';
      items: Array<{
        __typename: 'Brand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelServiceConnection';
      items: Array<{
        __typename: 'Service';
        id: string;
        name: string;
        icon: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardConnection';
      items: Array<{
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateUserMutationVariables = {
  input: CreateUserInput;
};

export type CreateUserMutation = {
  createUser: {
    __typename: 'User';
    id: string;
    createdAt: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    avatarKey: string | null;
    avatarUrl: string | null;
    archived: boolean | null;
    verified: boolean | null;
    inviteLater: boolean | null;
    inviteAccepted: boolean | null;
    invitedBy: string | null;
    status: UserStatus | null;
    userRole: {
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    userSpaces: Array<string | null> | null;
    productItemNotes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput;
};

export type UpdateUserMutation = {
  updateUser: {
    __typename: 'User';
    id: string;
    createdAt: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    avatarKey: string | null;
    avatarUrl: string | null;
    archived: boolean | null;
    verified: boolean | null;
    inviteLater: boolean | null;
    inviteAccepted: boolean | null;
    invitedBy: string | null;
    status: UserStatus | null;
    userRole: {
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    userSpaces: Array<string | null> | null;
    productItemNotes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput;
};

export type DeleteUserMutation = {
  deleteUser: {
    __typename: 'User';
    id: string;
    createdAt: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    avatarKey: string | null;
    avatarUrl: string | null;
    archived: boolean | null;
    verified: boolean | null;
    inviteLater: boolean | null;
    inviteAccepted: boolean | null;
    invitedBy: string | null;
    status: UserStatus | null;
    userRole: {
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    userSpaces: Array<string | null> | null;
    productItemNotes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateUserAllocationMutationVariables = {
  input: CreateUserAllocationInput;
};

export type CreateUserAllocationMutation = {
  createUserAllocation: {
    __typename: 'UserAllocation';
    id: string;
    date: string;
    createdAt: string | null;
    user: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateUserAllocationMutationVariables = {
  input: UpdateUserAllocationInput;
};

export type UpdateUserAllocationMutation = {
  updateUserAllocation: {
    __typename: 'UserAllocation';
    id: string;
    date: string;
    createdAt: string | null;
    user: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteUserAllocationMutationVariables = {
  input: DeleteUserAllocationInput;
};

export type DeleteUserAllocationMutation = {
  deleteUserAllocation: {
    __typename: 'UserAllocation';
    id: string;
    date: string;
    createdAt: string | null;
    user: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateRoleMutationVariables = {
  input: CreateRoleInput;
};

export type CreateRoleMutation = {
  createRole: {
    __typename: 'Role';
    id: string;
    name: string;
    permission: Permission;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateRoleMutationVariables = {
  input: UpdateRoleInput;
};

export type UpdateRoleMutation = {
  updateRole: {
    __typename: 'Role';
    id: string;
    name: string;
    permission: Permission;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteRoleMutationVariables = {
  input: DeleteRoleInput;
};

export type DeleteRoleMutation = {
  deleteRole: {
    __typename: 'Role';
    id: string;
    name: string;
    permission: Permission;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateSpaceMutationVariables = {
  input: CreateSpaceInput;
};

export type CreateSpaceMutation = {
  createSpace: {
    __typename: 'Space';
    id: string;
    createdAt: string;
    name: string;
    colorCode: string;
    status: SpaceStatus;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateSpaceMutationVariables = {
  input: UpdateSpaceInput;
};

export type UpdateSpaceMutation = {
  updateSpace: {
    __typename: 'Space';
    id: string;
    createdAt: string;
    name: string;
    colorCode: string;
    status: SpaceStatus;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteSpaceMutationVariables = {
  input: DeleteSpaceInput;
};

export type DeleteSpaceMutation = {
  deleteSpace: {
    __typename: 'Space';
    id: string;
    createdAt: string;
    name: string;
    colorCode: string;
    status: SpaceStatus;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateBrandMutationVariables = {
  input: CreateBrandInput;
};

export type CreateBrandMutation = {
  createBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrands: {
      __typename: 'ModelSubBrandConnection';
      items: Array<{
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateBrandMutationVariables = {
  input: UpdateBrandInput;
};

export type UpdateBrandMutation = {
  updateBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrands: {
      __typename: 'ModelSubBrandConnection';
      items: Array<{
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteBrandMutationVariables = {
  input: DeleteBrandInput;
};

export type DeleteBrandMutation = {
  deleteBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrands: {
      __typename: 'ModelSubBrandConnection';
      items: Array<{
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateSubBrandMutationVariables = {
  input: CreateSubBrandInput;
};

export type CreateSubBrandMutation = {
  createSubBrand: {
    __typename: 'SubBrand';
    id: string;
    name: string;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateSubBrandMutationVariables = {
  input: UpdateSubBrandInput;
};

export type UpdateSubBrandMutation = {
  updateSubBrand: {
    __typename: 'SubBrand';
    id: string;
    name: string;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteSubBrandMutationVariables = {
  input: DeleteSubBrandInput;
};

export type DeleteSubBrandMutation = {
  deleteSubBrand: {
    __typename: 'SubBrand';
    id: string;
    name: string;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateServiceMutationVariables = {
  input: CreateServiceInput;
};

export type CreateServiceMutation = {
  createService: {
    __typename: 'Service';
    id: string;
    name: string;
    icon: string | null;
    batchCards: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateServiceMutationVariables = {
  input: UpdateServiceInput;
};

export type UpdateServiceMutation = {
  updateService: {
    __typename: 'Service';
    id: string;
    name: string;
    icon: string | null;
    batchCards: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteServiceMutationVariables = {
  input: DeleteServiceInput;
};

export type DeleteServiceMutation = {
  deleteService: {
    __typename: 'Service';
    id: string;
    name: string;
    icon: string | null;
    batchCards: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateItemTemplateMutationVariables = {
  input: CreateItemTemplateInput;
};

export type CreateItemTemplateMutation = {
  createItemTemplate: {
    __typename: 'ItemTemplate';
    id: string;
    uic: boolean | null;
    brand: boolean | null;
    subBrand: boolean | null;
    category: boolean | null;
    subCategory: boolean | null;
    type: boolean | null;
    gender: boolean | null;
    age: boolean | null;
    size: boolean | null;
    look: boolean | null;
    other1: boolean | null;
    other2: boolean | null;
    other3: boolean | null;
    season: boolean | null;
    collectionName: boolean | null;
    productName: boolean | null;
    quantity: boolean | null;
    clientName: boolean | null;
    serviceName: boolean | null;
    unit: boolean | null;
    itemTemplateType: ItemTemplateType | null;
    companies: {
      __typename: 'ModelCompanyConnection';
      items: Array<{
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateItemTemplateMutationVariables = {
  input: UpdateItemTemplateInput;
};

export type UpdateItemTemplateMutation = {
  updateItemTemplate: {
    __typename: 'ItemTemplate';
    id: string;
    uic: boolean | null;
    brand: boolean | null;
    subBrand: boolean | null;
    category: boolean | null;
    subCategory: boolean | null;
    type: boolean | null;
    gender: boolean | null;
    age: boolean | null;
    size: boolean | null;
    look: boolean | null;
    other1: boolean | null;
    other2: boolean | null;
    other3: boolean | null;
    season: boolean | null;
    collectionName: boolean | null;
    productName: boolean | null;
    quantity: boolean | null;
    clientName: boolean | null;
    serviceName: boolean | null;
    unit: boolean | null;
    itemTemplateType: ItemTemplateType | null;
    companies: {
      __typename: 'ModelCompanyConnection';
      items: Array<{
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteItemTemplateMutationVariables = {
  input: DeleteItemTemplateInput;
};

export type DeleteItemTemplateMutation = {
  deleteItemTemplate: {
    __typename: 'ItemTemplate';
    id: string;
    uic: boolean | null;
    brand: boolean | null;
    subBrand: boolean | null;
    category: boolean | null;
    subCategory: boolean | null;
    type: boolean | null;
    gender: boolean | null;
    age: boolean | null;
    size: boolean | null;
    look: boolean | null;
    other1: boolean | null;
    other2: boolean | null;
    other3: boolean | null;
    season: boolean | null;
    collectionName: boolean | null;
    productName: boolean | null;
    quantity: boolean | null;
    clientName: boolean | null;
    serviceName: boolean | null;
    unit: boolean | null;
    itemTemplateType: ItemTemplateType | null;
    companies: {
      __typename: 'ModelCompanyConnection';
      items: Array<{
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateBatchCardMutationVariables = {
  input: CreateBatchCardInput;
};

export type CreateBatchCardMutation = {
  createBatchCard: {
    __typename: 'BatchCard';
    id: string;
    createdAt: string;
    name: string | null;
    description: string | null;
    inBacklog: boolean | null;
    images: Array<string | null> | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaces: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateBatchCardMutationVariables = {
  input: UpdateBatchCardInput;
};

export type UpdateBatchCardMutation = {
  updateBatchCard: {
    __typename: 'BatchCard';
    id: string;
    createdAt: string;
    name: string | null;
    description: string | null;
    inBacklog: boolean | null;
    images: Array<string | null> | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaces: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteBatchCardMutationVariables = {
  input: DeleteBatchCardInput;
};

export type DeleteBatchCardMutation = {
  deleteBatchCard: {
    __typename: 'BatchCard';
    id: string;
    createdAt: string;
    name: string | null;
    description: string | null;
    inBacklog: boolean | null;
    images: Array<string | null> | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaces: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateBatchCardAllocationMutationVariables = {
  input: CreateBatchCardAllocationInput;
};

export type CreateBatchCardAllocationMutation = {
  createBatchCardAllocation: {
    __typename: 'BatchCardAllocation';
    id: string;
    createdAt: string;
    date: string | null;
    obsolete: boolean | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardServices: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateBatchCardAllocationMutationVariables = {
  input: UpdateBatchCardAllocationInput;
};

export type UpdateBatchCardAllocationMutation = {
  updateBatchCardAllocation: {
    __typename: 'BatchCardAllocation';
    id: string;
    createdAt: string;
    date: string | null;
    obsolete: boolean | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardServices: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteBatchCardAllocationMutationVariables = {
  input: DeleteBatchCardAllocationInput;
};

export type DeleteBatchCardAllocationMutation = {
  deleteBatchCardAllocation: {
    __typename: 'BatchCardAllocation';
    id: string;
    createdAt: string;
    date: string | null;
    obsolete: boolean | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardServices: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateProductItemMutationVariables = {
  input: CreateProductItemInput;
};

export type CreateProductItemMutation = {
  createProductItem: {
    __typename: 'ProductItem';
    id: string;
    createdAt: string;
    uic: string | null;
    category: string | null;
    subCategory: string | null;
    type: string | null;
    gender: string | null;
    age: string | null;
    size: string | null;
    look: string | null;
    other1: string | null;
    other2: string | null;
    other3: string | null;
    productName: string | null;
    clientName: string | null;
    serviceName: string | null;
    quantity: string | null;
    unit: string | null;
    season: string | null;
    collectionName: string | null;
    tagDescription: string | null;
    taggedAt: string | null;
    tagged: boolean | null;
    previousTagDescriptions: Array<string | null> | null;
    archived: boolean | null;
    complete: boolean | null;
    completedAt: string | null;
    qcApproved: boolean | null;
    returnedToItems: boolean | null;
    qcApprovedAt: string | null;
    completedServices: Array<string | null> | null;
    productItemServices: Array<string | null> | null;
    imageKeys: Array<string | null> | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrand: {
      __typename: 'SubBrand';
      id: string;
      name: string;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    notes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    content: {
      __typename: 'ModelProductItemContentConnection';
      items: Array<{
        __typename: 'ProductItemContent';
        id: string;
        header: string | null;
        content: string | null;
        createdAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateProductItemMutationVariables = {
  input: UpdateProductItemInput;
};

export type UpdateProductItemMutation = {
  updateProductItem: {
    __typename: 'ProductItem';
    id: string;
    createdAt: string;
    uic: string | null;
    category: string | null;
    subCategory: string | null;
    type: string | null;
    gender: string | null;
    age: string | null;
    size: string | null;
    look: string | null;
    other1: string | null;
    other2: string | null;
    other3: string | null;
    productName: string | null;
    clientName: string | null;
    serviceName: string | null;
    quantity: string | null;
    unit: string | null;
    season: string | null;
    collectionName: string | null;
    tagDescription: string | null;
    taggedAt: string | null;
    tagged: boolean | null;
    previousTagDescriptions: Array<string | null> | null;
    archived: boolean | null;
    complete: boolean | null;
    completedAt: string | null;
    qcApproved: boolean | null;
    returnedToItems: boolean | null;
    qcApprovedAt: string | null;
    completedServices: Array<string | null> | null;
    productItemServices: Array<string | null> | null;
    imageKeys: Array<string | null> | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrand: {
      __typename: 'SubBrand';
      id: string;
      name: string;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    notes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    content: {
      __typename: 'ModelProductItemContentConnection';
      items: Array<{
        __typename: 'ProductItemContent';
        id: string;
        header: string | null;
        content: string | null;
        createdAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteProductItemMutationVariables = {
  input: DeleteProductItemInput;
};

export type DeleteProductItemMutation = {
  deleteProductItem: {
    __typename: 'ProductItem';
    id: string;
    createdAt: string;
    uic: string | null;
    category: string | null;
    subCategory: string | null;
    type: string | null;
    gender: string | null;
    age: string | null;
    size: string | null;
    look: string | null;
    other1: string | null;
    other2: string | null;
    other3: string | null;
    productName: string | null;
    clientName: string | null;
    serviceName: string | null;
    quantity: string | null;
    unit: string | null;
    season: string | null;
    collectionName: string | null;
    tagDescription: string | null;
    taggedAt: string | null;
    tagged: boolean | null;
    previousTagDescriptions: Array<string | null> | null;
    archived: boolean | null;
    complete: boolean | null;
    completedAt: string | null;
    qcApproved: boolean | null;
    returnedToItems: boolean | null;
    qcApprovedAt: string | null;
    completedServices: Array<string | null> | null;
    productItemServices: Array<string | null> | null;
    imageKeys: Array<string | null> | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrand: {
      __typename: 'SubBrand';
      id: string;
      name: string;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    notes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    content: {
      __typename: 'ModelProductItemContentConnection';
      items: Array<{
        __typename: 'ProductItemContent';
        id: string;
        header: string | null;
        content: string | null;
        createdAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateProductItemNoteMutationVariables = {
  input: CreateProductItemNoteInput;
};

export type CreateProductItemNoteMutation = {
  createProductItemNote: {
    __typename: 'ProductItemNote';
    id: string;
    note: string | null;
    timestamp: string | null;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    };
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateProductItemNoteMutationVariables = {
  input: UpdateProductItemNoteInput;
};

export type UpdateProductItemNoteMutation = {
  updateProductItemNote: {
    __typename: 'ProductItemNote';
    id: string;
    note: string | null;
    timestamp: string | null;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    };
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteProductItemNoteMutationVariables = {
  input: DeleteProductItemNoteInput;
};

export type DeleteProductItemNoteMutation = {
  deleteProductItemNote: {
    __typename: 'ProductItemNote';
    id: string;
    note: string | null;
    timestamp: string | null;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    };
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateProductItemContentMutationVariables = {
  input: CreateProductItemContentInput;
};

export type CreateProductItemContentMutation = {
  createProductItemContent: {
    __typename: 'ProductItemContent';
    id: string;
    header: string | null;
    content: string | null;
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
    createdAt: string;
  } | null;
};

export type UpdateProductItemContentMutationVariables = {
  input: UpdateProductItemContentInput;
};

export type UpdateProductItemContentMutation = {
  updateProductItemContent: {
    __typename: 'ProductItemContent';
    id: string;
    header: string | null;
    content: string | null;
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
    createdAt: string;
  } | null;
};

export type DeleteProductItemContentMutationVariables = {
  input: DeleteProductItemContentInput;
};

export type DeleteProductItemContentMutation = {
  deleteProductItemContent: {
    __typename: 'ProductItemContent';
    id: string;
    header: string | null;
    content: string | null;
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
    createdAt: string;
  } | null;
};

export type CreateBatchCardSpaceMutationVariables = {
  input: CreateBatchCardSpaceInput;
};

export type CreateBatchCardSpaceMutation = {
  createBatchCardSpace: {
    __typename: 'BatchCardSpace';
    id: string;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateBatchCardSpaceMutationVariables = {
  input: UpdateBatchCardSpaceInput;
};

export type UpdateBatchCardSpaceMutation = {
  updateBatchCardSpace: {
    __typename: 'BatchCardSpace';
    id: string;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteBatchCardSpaceMutationVariables = {
  input: DeleteBatchCardSpaceInput;
};

export type DeleteBatchCardSpaceMutation = {
  deleteBatchCardSpace: {
    __typename: 'BatchCardSpace';
    id: string;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateBatchCardServiceMutationVariables = {
  input: CreateBatchCardServiceInput;
};

export type CreateBatchCardServiceMutation = {
  createBatchCardService: {
    __typename: 'BatchCardService';
    id: string;
    isComplete: boolean;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardAllocation: {
      __typename: 'BatchCardAllocation';
      id: string;
      createdAt: string;
      date: string | null;
      obsolete: boolean | null;
      space: {
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null;
      batchCardServices: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
    } | null;
    service: {
      __typename: 'Service';
      id: string;
      name: string;
      icon: string | null;
      batchCards: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateBatchCardServiceMutationVariables = {
  input: UpdateBatchCardServiceInput;
};

export type UpdateBatchCardServiceMutation = {
  updateBatchCardService: {
    __typename: 'BatchCardService';
    id: string;
    isComplete: boolean;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardAllocation: {
      __typename: 'BatchCardAllocation';
      id: string;
      createdAt: string;
      date: string | null;
      obsolete: boolean | null;
      space: {
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null;
      batchCardServices: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
    } | null;
    service: {
      __typename: 'Service';
      id: string;
      name: string;
      icon: string | null;
      batchCards: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteBatchCardServiceMutationVariables = {
  input: DeleteBatchCardServiceInput;
};

export type DeleteBatchCardServiceMutation = {
  deleteBatchCardService: {
    __typename: 'BatchCardService';
    id: string;
    isComplete: boolean;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardAllocation: {
      __typename: 'BatchCardAllocation';
      id: string;
      createdAt: string;
      date: string | null;
      obsolete: boolean | null;
      space: {
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null;
      batchCardServices: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
    } | null;
    service: {
      __typename: 'Service';
      id: string;
      name: string;
      icon: string | null;
      batchCards: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type CreateFaqMutationVariables = {
  input: CreateFaqInput;
};

export type CreateFaqMutation = {
  createFaq: {
    __typename: 'Faq';
    id: string;
    question: string;
    answer: string;
  } | null;
};

export type UpdateFaqMutationVariables = {
  input: UpdateFaqInput;
};

export type UpdateFaqMutation = {
  updateFaq: {
    __typename: 'Faq';
    id: string;
    question: string;
    answer: string;
  } | null;
};

export type DeleteFaqMutationVariables = {
  input: DeleteFaqInput;
};

export type DeleteFaqMutation = {
  deleteFaq: {
    __typename: 'Faq';
    id: string;
    question: string;
    answer: string;
  } | null;
};

export type GetTaskQueryVariables = {
  id: string;
};

export type GetTaskQuery = {
  getTask: {
    __typename: 'Task';
    id: string;
    title: string;
    description: string | null;
    status: string | null;
    assignedTo: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListTasksQuery = {
  listTasks: {
    __typename: 'ModelTaskConnection';
    items: Array<{
      __typename: 'Task';
      id: string;
      title: string;
      description: string | null;
      status: string | null;
      assignedTo: {
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetPrivateNoteQueryVariables = {
  id: string;
};

export type GetPrivateNoteQuery = {
  getPrivateNote: {
    __typename: 'PrivateNote';
    id: string;
    content: string;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListPrivateNotesQueryVariables = {
  filter?: ModelPrivateNoteFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListPrivateNotesQuery = {
  listPrivateNotes: {
    __typename: 'ModelPrivateNoteConnection';
    items: Array<{
      __typename: 'PrivateNote';
      id: string;
      content: string;
      author: {
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetCompanyQueryVariables = {
  id: string;
};

export type GetCompanyQuery = {
  getCompany: {
    __typename: 'Company';
    id: string;
    createdAt: string;
    name: string;
    address: string | null;
    state: string | null;
    industry: string | null;
    country: string | null;
    companySize: string | null;
    stripeCustomerId: string | null;
    stripeSubscriptionPlanId: string | null;
    subscriptionType: string | null;
    accountDisabled: boolean | null;
    itemTemplate: {
      __typename: 'ItemTemplate';
      id: string;
      uic: boolean | null;
      brand: boolean | null;
      subBrand: boolean | null;
      category: boolean | null;
      subCategory: boolean | null;
      type: boolean | null;
      gender: boolean | null;
      age: boolean | null;
      size: boolean | null;
      look: boolean | null;
      other1: boolean | null;
      other2: boolean | null;
      other3: boolean | null;
      season: boolean | null;
      collectionName: boolean | null;
      productName: boolean | null;
      quantity: boolean | null;
      clientName: boolean | null;
      serviceName: boolean | null;
      unit: boolean | null;
      itemTemplateType: ItemTemplateType | null;
      companies: {
        __typename: 'ModelCompanyConnection';
        nextToken: string | null;
      } | null;
    } | null;
    roles: {
      __typename: 'ModelRoleConnection';
      items: Array<{
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null> | null;
      nextToken: string | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaces: {
      __typename: 'ModelSpaceConnection';
      items: Array<{
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null> | null;
      nextToken: string | null;
    } | null;
    brands: {
      __typename: 'ModelBrandConnection';
      items: Array<{
        __typename: 'Brand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelServiceConnection';
      items: Array<{
        __typename: 'Service';
        id: string;
        name: string;
        icon: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardConnection';
      items: Array<{
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListCompanysQueryVariables = {
  filter?: ModelCompanyFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCompanysQuery = {
  listCompanys: {
    __typename: 'ModelCompanyConnection';
    items: Array<{
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetUserQueryVariables = {
  id: string;
};

export type GetUserQuery = {
  getUser: {
    __typename: 'User';
    id: string;
    createdAt: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    avatarKey: string | null;
    avatarUrl: string | null;
    archived: boolean | null;
    verified: boolean | null;
    inviteLater: boolean | null;
    inviteAccepted: boolean | null;
    invitedBy: string | null;
    status: UserStatus | null;
    userRole: {
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    userSpaces: Array<string | null> | null;
    productItemNotes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUsersQuery = {
  listUsers: {
    __typename: 'ModelUserConnection';
    items: Array<{
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetUserAllocationQueryVariables = {
  id: string;
};

export type GetUserAllocationQuery = {
  getUserAllocation: {
    __typename: 'UserAllocation';
    id: string;
    date: string;
    createdAt: string | null;
    user: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListUserAllocationsQueryVariables = {
  filter?: ModelUserAllocationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUserAllocationsQuery = {
  listUserAllocations: {
    __typename: 'ModelUserAllocationConnection';
    items: Array<{
      __typename: 'UserAllocation';
      id: string;
      date: string;
      createdAt: string | null;
      user: {
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null;
      space: {
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetRoleQueryVariables = {
  id: string;
};

export type GetRoleQuery = {
  getRole: {
    __typename: 'Role';
    id: string;
    name: string;
    permission: Permission;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListRolesQueryVariables = {
  filter?: ModelRoleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListRolesQuery = {
  listRoles: {
    __typename: 'ModelRoleConnection';
    items: Array<{
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetSpaceQueryVariables = {
  id: string;
};

export type GetSpaceQuery = {
  getSpace: {
    __typename: 'Space';
    id: string;
    createdAt: string;
    name: string;
    colorCode: string;
    status: SpaceStatus;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListSpacesQueryVariables = {
  filter?: ModelSpaceFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSpacesQuery = {
  listSpaces: {
    __typename: 'ModelSpaceConnection';
    items: Array<{
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetBrandQueryVariables = {
  id: string;
};

export type GetBrandQuery = {
  getBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrands: {
      __typename: 'ModelSubBrandConnection';
      items: Array<{
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListBrandsQueryVariables = {
  filter?: ModelBrandFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListBrandsQuery = {
  listBrands: {
    __typename: 'ModelBrandConnection';
    items: Array<{
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetSubBrandQueryVariables = {
  id: string;
};

export type GetSubBrandQuery = {
  getSubBrand: {
    __typename: 'SubBrand';
    id: string;
    name: string;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListSubBrandsQueryVariables = {
  filter?: ModelSubBrandFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSubBrandsQuery = {
  listSubBrands: {
    __typename: 'ModelSubBrandConnection';
    items: Array<{
      __typename: 'SubBrand';
      id: string;
      name: string;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetServiceQueryVariables = {
  id: string;
};

export type GetServiceQuery = {
  getService: {
    __typename: 'Service';
    id: string;
    name: string;
    icon: string | null;
    batchCards: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListServicesQueryVariables = {
  filter?: ModelServiceFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListServicesQuery = {
  listServices: {
    __typename: 'ModelServiceConnection';
    items: Array<{
      __typename: 'Service';
      id: string;
      name: string;
      icon: string | null;
      batchCards: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetItemTemplateQueryVariables = {
  id: string;
};

export type GetItemTemplateQuery = {
  getItemTemplate: {
    __typename: 'ItemTemplate';
    id: string;
    uic: boolean | null;
    brand: boolean | null;
    subBrand: boolean | null;
    category: boolean | null;
    subCategory: boolean | null;
    type: boolean | null;
    gender: boolean | null;
    age: boolean | null;
    size: boolean | null;
    look: boolean | null;
    other1: boolean | null;
    other2: boolean | null;
    other3: boolean | null;
    season: boolean | null;
    collectionName: boolean | null;
    productName: boolean | null;
    quantity: boolean | null;
    clientName: boolean | null;
    serviceName: boolean | null;
    unit: boolean | null;
    itemTemplateType: ItemTemplateType | null;
    companies: {
      __typename: 'ModelCompanyConnection';
      items: Array<{
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListItemTemplatesQueryVariables = {
  filter?: ModelItemTemplateFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListItemTemplatesQuery = {
  listItemTemplates: {
    __typename: 'ModelItemTemplateConnection';
    items: Array<{
      __typename: 'ItemTemplate';
      id: string;
      uic: boolean | null;
      brand: boolean | null;
      subBrand: boolean | null;
      category: boolean | null;
      subCategory: boolean | null;
      type: boolean | null;
      gender: boolean | null;
      age: boolean | null;
      size: boolean | null;
      look: boolean | null;
      other1: boolean | null;
      other2: boolean | null;
      other3: boolean | null;
      season: boolean | null;
      collectionName: boolean | null;
      productName: boolean | null;
      quantity: boolean | null;
      clientName: boolean | null;
      serviceName: boolean | null;
      unit: boolean | null;
      itemTemplateType: ItemTemplateType | null;
      companies: {
        __typename: 'ModelCompanyConnection';
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetBatchCardQueryVariables = {
  id: string;
};

export type GetBatchCardQuery = {
  getBatchCard: {
    __typename: 'BatchCard';
    id: string;
    createdAt: string;
    name: string | null;
    description: string | null;
    inBacklog: boolean | null;
    images: Array<string | null> | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaces: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListBatchCardsQueryVariables = {
  filter?: ModelBatchCardFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListBatchCardsQuery = {
  listBatchCards: {
    __typename: 'ModelBatchCardConnection';
    items: Array<{
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetBatchCardAllocationQueryVariables = {
  id: string;
};

export type GetBatchCardAllocationQuery = {
  getBatchCardAllocation: {
    __typename: 'BatchCardAllocation';
    id: string;
    createdAt: string;
    date: string | null;
    obsolete: boolean | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardServices: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListBatchCardAllocationsQueryVariables = {
  filter?: ModelBatchCardAllocationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListBatchCardAllocationsQuery = {
  listBatchCardAllocations: {
    __typename: 'ModelBatchCardAllocationConnection';
    items: Array<{
      __typename: 'BatchCardAllocation';
      id: string;
      createdAt: string;
      date: string | null;
      obsolete: boolean | null;
      space: {
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null;
      batchCardServices: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetProductItemQueryVariables = {
  id: string;
};

export type GetProductItemQuery = {
  getProductItem: {
    __typename: 'ProductItem';
    id: string;
    createdAt: string;
    uic: string | null;
    category: string | null;
    subCategory: string | null;
    type: string | null;
    gender: string | null;
    age: string | null;
    size: string | null;
    look: string | null;
    other1: string | null;
    other2: string | null;
    other3: string | null;
    productName: string | null;
    clientName: string | null;
    serviceName: string | null;
    quantity: string | null;
    unit: string | null;
    season: string | null;
    collectionName: string | null;
    tagDescription: string | null;
    taggedAt: string | null;
    tagged: boolean | null;
    previousTagDescriptions: Array<string | null> | null;
    archived: boolean | null;
    complete: boolean | null;
    completedAt: string | null;
    qcApproved: boolean | null;
    returnedToItems: boolean | null;
    qcApprovedAt: string | null;
    completedServices: Array<string | null> | null;
    productItemServices: Array<string | null> | null;
    imageKeys: Array<string | null> | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrand: {
      __typename: 'SubBrand';
      id: string;
      name: string;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    notes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    content: {
      __typename: 'ModelProductItemContentConnection';
      items: Array<{
        __typename: 'ProductItemContent';
        id: string;
        header: string | null;
        content: string | null;
        createdAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListProductItemsQueryVariables = {
  filter?: ModelProductItemFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListProductItemsQuery = {
  listProductItems: {
    __typename: 'ModelProductItemConnection';
    items: Array<{
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetProductItemNoteQueryVariables = {
  id: string;
};

export type GetProductItemNoteQuery = {
  getProductItemNote: {
    __typename: 'ProductItemNote';
    id: string;
    note: string | null;
    timestamp: string | null;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    };
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListProductItemNotesQueryVariables = {
  filter?: ModelProductItemNoteFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListProductItemNotesQuery = {
  listProductItemNotes: {
    __typename: 'ModelProductItemNoteConnection';
    items: Array<{
      __typename: 'ProductItemNote';
      id: string;
      note: string | null;
      timestamp: string | null;
      author: {
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      };
      product: {
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetProductItemContentQueryVariables = {
  id: string;
};

export type GetProductItemContentQuery = {
  getProductItemContent: {
    __typename: 'ProductItemContent';
    id: string;
    header: string | null;
    content: string | null;
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
    createdAt: string;
  } | null;
};

export type ListProductItemContentsQueryVariables = {
  filter?: ModelProductItemContentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListProductItemContentsQuery = {
  listProductItemContents: {
    __typename: 'ModelProductItemContentConnection';
    items: Array<{
      __typename: 'ProductItemContent';
      id: string;
      header: string | null;
      content: string | null;
      product: {
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null;
      createdAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetFaqQueryVariables = {
  id: string;
};

export type GetFaqQuery = {
  getFaq: {
    __typename: 'Faq';
    id: string;
    question: string;
    answer: string;
  } | null;
};

export type ListFaqsQueryVariables = {
  filter?: ModelFaqFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListFaqsQuery = {
  listFaqs: {
    __typename: 'ModelFaqConnection';
    items: Array<{
      __typename: 'Faq';
      id: string;
      question: string;
      answer: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnCreateTaskSubscription = {
  onCreateTask: {
    __typename: 'Task';
    id: string;
    title: string;
    description: string | null;
    status: string | null;
    assignedTo: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateTaskSubscription = {
  onUpdateTask: {
    __typename: 'Task';
    id: string;
    title: string;
    description: string | null;
    status: string | null;
    assignedTo: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteTaskSubscription = {
  onDeleteTask: {
    __typename: 'Task';
    id: string;
    title: string;
    description: string | null;
    status: string | null;
    assignedTo: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreatePrivateNoteSubscription = {
  onCreatePrivateNote: {
    __typename: 'PrivateNote';
    id: string;
    content: string;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdatePrivateNoteSubscription = {
  onUpdatePrivateNote: {
    __typename: 'PrivateNote';
    id: string;
    content: string;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeletePrivateNoteSubscription = {
  onDeletePrivateNote: {
    __typename: 'PrivateNote';
    id: string;
    content: string;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateCompanySubscription = {
  onCreateCompany: {
    __typename: 'Company';
    id: string;
    createdAt: string;
    name: string;
    address: string | null;
    state: string | null;
    industry: string | null;
    country: string | null;
    companySize: string | null;
    stripeCustomerId: string | null;
    stripeSubscriptionPlanId: string | null;
    subscriptionType: string | null;
    accountDisabled: boolean | null;
    itemTemplate: {
      __typename: 'ItemTemplate';
      id: string;
      uic: boolean | null;
      brand: boolean | null;
      subBrand: boolean | null;
      category: boolean | null;
      subCategory: boolean | null;
      type: boolean | null;
      gender: boolean | null;
      age: boolean | null;
      size: boolean | null;
      look: boolean | null;
      other1: boolean | null;
      other2: boolean | null;
      other3: boolean | null;
      season: boolean | null;
      collectionName: boolean | null;
      productName: boolean | null;
      quantity: boolean | null;
      clientName: boolean | null;
      serviceName: boolean | null;
      unit: boolean | null;
      itemTemplateType: ItemTemplateType | null;
      companies: {
        __typename: 'ModelCompanyConnection';
        nextToken: string | null;
      } | null;
    } | null;
    roles: {
      __typename: 'ModelRoleConnection';
      items: Array<{
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null> | null;
      nextToken: string | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaces: {
      __typename: 'ModelSpaceConnection';
      items: Array<{
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null> | null;
      nextToken: string | null;
    } | null;
    brands: {
      __typename: 'ModelBrandConnection';
      items: Array<{
        __typename: 'Brand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelServiceConnection';
      items: Array<{
        __typename: 'Service';
        id: string;
        name: string;
        icon: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardConnection';
      items: Array<{
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany: {
    __typename: 'Company';
    id: string;
    createdAt: string;
    name: string;
    address: string | null;
    state: string | null;
    industry: string | null;
    country: string | null;
    companySize: string | null;
    stripeCustomerId: string | null;
    stripeSubscriptionPlanId: string | null;
    subscriptionType: string | null;
    accountDisabled: boolean | null;
    itemTemplate: {
      __typename: 'ItemTemplate';
      id: string;
      uic: boolean | null;
      brand: boolean | null;
      subBrand: boolean | null;
      category: boolean | null;
      subCategory: boolean | null;
      type: boolean | null;
      gender: boolean | null;
      age: boolean | null;
      size: boolean | null;
      look: boolean | null;
      other1: boolean | null;
      other2: boolean | null;
      other3: boolean | null;
      season: boolean | null;
      collectionName: boolean | null;
      productName: boolean | null;
      quantity: boolean | null;
      clientName: boolean | null;
      serviceName: boolean | null;
      unit: boolean | null;
      itemTemplateType: ItemTemplateType | null;
      companies: {
        __typename: 'ModelCompanyConnection';
        nextToken: string | null;
      } | null;
    } | null;
    roles: {
      __typename: 'ModelRoleConnection';
      items: Array<{
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null> | null;
      nextToken: string | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaces: {
      __typename: 'ModelSpaceConnection';
      items: Array<{
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null> | null;
      nextToken: string | null;
    } | null;
    brands: {
      __typename: 'ModelBrandConnection';
      items: Array<{
        __typename: 'Brand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelServiceConnection';
      items: Array<{
        __typename: 'Service';
        id: string;
        name: string;
        icon: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardConnection';
      items: Array<{
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany: {
    __typename: 'Company';
    id: string;
    createdAt: string;
    name: string;
    address: string | null;
    state: string | null;
    industry: string | null;
    country: string | null;
    companySize: string | null;
    stripeCustomerId: string | null;
    stripeSubscriptionPlanId: string | null;
    subscriptionType: string | null;
    accountDisabled: boolean | null;
    itemTemplate: {
      __typename: 'ItemTemplate';
      id: string;
      uic: boolean | null;
      brand: boolean | null;
      subBrand: boolean | null;
      category: boolean | null;
      subCategory: boolean | null;
      type: boolean | null;
      gender: boolean | null;
      age: boolean | null;
      size: boolean | null;
      look: boolean | null;
      other1: boolean | null;
      other2: boolean | null;
      other3: boolean | null;
      season: boolean | null;
      collectionName: boolean | null;
      productName: boolean | null;
      quantity: boolean | null;
      clientName: boolean | null;
      serviceName: boolean | null;
      unit: boolean | null;
      itemTemplateType: ItemTemplateType | null;
      companies: {
        __typename: 'ModelCompanyConnection';
        nextToken: string | null;
      } | null;
    } | null;
    roles: {
      __typename: 'ModelRoleConnection';
      items: Array<{
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null> | null;
      nextToken: string | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaces: {
      __typename: 'ModelSpaceConnection';
      items: Array<{
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null> | null;
      nextToken: string | null;
    } | null;
    brands: {
      __typename: 'ModelBrandConnection';
      items: Array<{
        __typename: 'Brand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelServiceConnection';
      items: Array<{
        __typename: 'Service';
        id: string;
        name: string;
        icon: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardConnection';
      items: Array<{
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateUserSubscription = {
  onCreateUser: {
    __typename: 'User';
    id: string;
    createdAt: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    avatarKey: string | null;
    avatarUrl: string | null;
    archived: boolean | null;
    verified: boolean | null;
    inviteLater: boolean | null;
    inviteAccepted: boolean | null;
    invitedBy: string | null;
    status: UserStatus | null;
    userRole: {
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    userSpaces: Array<string | null> | null;
    productItemNotes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateUserSubscription = {
  onUpdateUser: {
    __typename: 'User';
    id: string;
    createdAt: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    avatarKey: string | null;
    avatarUrl: string | null;
    archived: boolean | null;
    verified: boolean | null;
    inviteLater: boolean | null;
    inviteAccepted: boolean | null;
    invitedBy: string | null;
    status: UserStatus | null;
    userRole: {
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    userSpaces: Array<string | null> | null;
    productItemNotes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteUserSubscription = {
  onDeleteUser: {
    __typename: 'User';
    id: string;
    createdAt: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    avatarKey: string | null;
    avatarUrl: string | null;
    archived: boolean | null;
    verified: boolean | null;
    inviteLater: boolean | null;
    inviteAccepted: boolean | null;
    invitedBy: string | null;
    status: UserStatus | null;
    userRole: {
      __typename: 'Role';
      id: string;
      name: string;
      permission: Permission;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    userSpaces: Array<string | null> | null;
    productItemNotes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateUserAllocationSubscription = {
  onCreateUserAllocation: {
    __typename: 'UserAllocation';
    id: string;
    date: string;
    createdAt: string | null;
    user: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateUserAllocationSubscription = {
  onUpdateUserAllocation: {
    __typename: 'UserAllocation';
    id: string;
    date: string;
    createdAt: string | null;
    user: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteUserAllocationSubscription = {
  onDeleteUserAllocation: {
    __typename: 'UserAllocation';
    id: string;
    date: string;
    createdAt: string | null;
    user: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateRoleSubscription = {
  onCreateRole: {
    __typename: 'Role';
    id: string;
    name: string;
    permission: Permission;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateRoleSubscription = {
  onUpdateRole: {
    __typename: 'Role';
    id: string;
    name: string;
    permission: Permission;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteRoleSubscription = {
  onDeleteRole: {
    __typename: 'Role';
    id: string;
    name: string;
    permission: Permission;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    users: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        createdAt: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phoneNumber: string | null;
        avatarKey: string | null;
        avatarUrl: string | null;
        archived: boolean | null;
        verified: boolean | null;
        inviteLater: boolean | null;
        inviteAccepted: boolean | null;
        invitedBy: string | null;
        status: UserStatus | null;
        userSpaces: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateSpaceSubscription = {
  onCreateSpace: {
    __typename: 'Space';
    id: string;
    createdAt: string;
    name: string;
    colorCode: string;
    status: SpaceStatus;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateSpaceSubscription = {
  onUpdateSpace: {
    __typename: 'Space';
    id: string;
    createdAt: string;
    name: string;
    colorCode: string;
    status: SpaceStatus;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteSpaceSubscription = {
  onDeleteSpace: {
    __typename: 'Space';
    id: string;
    createdAt: string;
    name: string;
    colorCode: string;
    status: SpaceStatus;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCards: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    spaceAllocation: {
      __typename: 'ModelUserAllocationConnection';
      items: Array<{
        __typename: 'UserAllocation';
        id: string;
        date: string;
        createdAt: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateBrandSubscription = {
  onCreateBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrands: {
      __typename: 'ModelSubBrandConnection';
      items: Array<{
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateBrandSubscription = {
  onUpdateBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrands: {
      __typename: 'ModelSubBrandConnection';
      items: Array<{
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteBrandSubscription = {
  onDeleteBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrands: {
      __typename: 'ModelSubBrandConnection';
      items: Array<{
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateSubBrandSubscription = {
  onCreateSubBrand: {
    __typename: 'SubBrand';
    id: string;
    name: string;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateSubBrandSubscription = {
  onUpdateSubBrand: {
    __typename: 'SubBrand';
    id: string;
    name: string;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteSubBrandSubscription = {
  onDeleteSubBrand: {
    __typename: 'SubBrand';
    id: string;
    name: string;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateServiceSubscription = {
  onCreateService: {
    __typename: 'Service';
    id: string;
    name: string;
    icon: string | null;
    batchCards: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateServiceSubscription = {
  onUpdateService: {
    __typename: 'Service';
    id: string;
    name: string;
    icon: string | null;
    batchCards: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteServiceSubscription = {
  onDeleteService: {
    __typename: 'Service';
    id: string;
    name: string;
    icon: string | null;
    batchCards: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateItemTemplateSubscription = {
  onCreateItemTemplate: {
    __typename: 'ItemTemplate';
    id: string;
    uic: boolean | null;
    brand: boolean | null;
    subBrand: boolean | null;
    category: boolean | null;
    subCategory: boolean | null;
    type: boolean | null;
    gender: boolean | null;
    age: boolean | null;
    size: boolean | null;
    look: boolean | null;
    other1: boolean | null;
    other2: boolean | null;
    other3: boolean | null;
    season: boolean | null;
    collectionName: boolean | null;
    productName: boolean | null;
    quantity: boolean | null;
    clientName: boolean | null;
    serviceName: boolean | null;
    unit: boolean | null;
    itemTemplateType: ItemTemplateType | null;
    companies: {
      __typename: 'ModelCompanyConnection';
      items: Array<{
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateItemTemplateSubscription = {
  onUpdateItemTemplate: {
    __typename: 'ItemTemplate';
    id: string;
    uic: boolean | null;
    brand: boolean | null;
    subBrand: boolean | null;
    category: boolean | null;
    subCategory: boolean | null;
    type: boolean | null;
    gender: boolean | null;
    age: boolean | null;
    size: boolean | null;
    look: boolean | null;
    other1: boolean | null;
    other2: boolean | null;
    other3: boolean | null;
    season: boolean | null;
    collectionName: boolean | null;
    productName: boolean | null;
    quantity: boolean | null;
    clientName: boolean | null;
    serviceName: boolean | null;
    unit: boolean | null;
    itemTemplateType: ItemTemplateType | null;
    companies: {
      __typename: 'ModelCompanyConnection';
      items: Array<{
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteItemTemplateSubscription = {
  onDeleteItemTemplate: {
    __typename: 'ItemTemplate';
    id: string;
    uic: boolean | null;
    brand: boolean | null;
    subBrand: boolean | null;
    category: boolean | null;
    subCategory: boolean | null;
    type: boolean | null;
    gender: boolean | null;
    age: boolean | null;
    size: boolean | null;
    look: boolean | null;
    other1: boolean | null;
    other2: boolean | null;
    other3: boolean | null;
    season: boolean | null;
    collectionName: boolean | null;
    productName: boolean | null;
    quantity: boolean | null;
    clientName: boolean | null;
    serviceName: boolean | null;
    unit: boolean | null;
    itemTemplateType: ItemTemplateType | null;
    companies: {
      __typename: 'ModelCompanyConnection';
      items: Array<{
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateBatchCardSubscription = {
  onCreateBatchCard: {
    __typename: 'BatchCard';
    id: string;
    createdAt: string;
    name: string | null;
    description: string | null;
    inBacklog: boolean | null;
    images: Array<string | null> | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaces: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateBatchCardSubscription = {
  onUpdateBatchCard: {
    __typename: 'BatchCard';
    id: string;
    createdAt: string;
    name: string | null;
    description: string | null;
    inBacklog: boolean | null;
    images: Array<string | null> | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaces: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteBatchCardSubscription = {
  onDeleteBatchCard: {
    __typename: 'BatchCard';
    id: string;
    createdAt: string;
    name: string | null;
    description: string | null;
    inBacklog: boolean | null;
    images: Array<string | null> | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    spaces: {
      __typename: 'ModelBatchCardSpaceConnection';
      items: Array<{
        __typename: 'BatchCardSpace';
        id: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    services: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    productItems: {
      __typename: 'ModelProductItemConnection';
      items: Array<{
        __typename: 'ProductItem';
        id: string;
        createdAt: string;
        uic: string | null;
        category: string | null;
        subCategory: string | null;
        type: string | null;
        gender: string | null;
        age: string | null;
        size: string | null;
        look: string | null;
        other1: string | null;
        other2: string | null;
        other3: string | null;
        productName: string | null;
        clientName: string | null;
        serviceName: string | null;
        quantity: string | null;
        unit: string | null;
        season: string | null;
        collectionName: string | null;
        tagDescription: string | null;
        taggedAt: string | null;
        tagged: boolean | null;
        previousTagDescriptions: Array<string | null> | null;
        archived: boolean | null;
        complete: boolean | null;
        completedAt: string | null;
        qcApproved: boolean | null;
        returnedToItems: boolean | null;
        qcApprovedAt: string | null;
        completedServices: Array<string | null> | null;
        productItemServices: Array<string | null> | null;
        imageKeys: Array<string | null> | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCardAllocations: {
      __typename: 'ModelBatchCardAllocationConnection';
      items: Array<{
        __typename: 'BatchCardAllocation';
        id: string;
        createdAt: string;
        date: string | null;
        obsolete: boolean | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateBatchCardAllocationSubscription = {
  onCreateBatchCardAllocation: {
    __typename: 'BatchCardAllocation';
    id: string;
    createdAt: string;
    date: string | null;
    obsolete: boolean | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardServices: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateBatchCardAllocationSubscription = {
  onUpdateBatchCardAllocation: {
    __typename: 'BatchCardAllocation';
    id: string;
    createdAt: string;
    date: string | null;
    obsolete: boolean | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardServices: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteBatchCardAllocationSubscription = {
  onDeleteBatchCardAllocation: {
    __typename: 'BatchCardAllocation';
    id: string;
    createdAt: string;
    date: string | null;
    obsolete: boolean | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardServices: {
      __typename: 'ModelBatchCardServiceConnection';
      items: Array<{
        __typename: 'BatchCardService';
        id: string;
        isComplete: boolean;
      } | null> | null;
      nextToken: string | null;
    } | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateProductItemSubscription = {
  onCreateProductItem: {
    __typename: 'ProductItem';
    id: string;
    createdAt: string;
    uic: string | null;
    category: string | null;
    subCategory: string | null;
    type: string | null;
    gender: string | null;
    age: string | null;
    size: string | null;
    look: string | null;
    other1: string | null;
    other2: string | null;
    other3: string | null;
    productName: string | null;
    clientName: string | null;
    serviceName: string | null;
    quantity: string | null;
    unit: string | null;
    season: string | null;
    collectionName: string | null;
    tagDescription: string | null;
    taggedAt: string | null;
    tagged: boolean | null;
    previousTagDescriptions: Array<string | null> | null;
    archived: boolean | null;
    complete: boolean | null;
    completedAt: string | null;
    qcApproved: boolean | null;
    returnedToItems: boolean | null;
    qcApprovedAt: string | null;
    completedServices: Array<string | null> | null;
    productItemServices: Array<string | null> | null;
    imageKeys: Array<string | null> | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrand: {
      __typename: 'SubBrand';
      id: string;
      name: string;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    notes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    content: {
      __typename: 'ModelProductItemContentConnection';
      items: Array<{
        __typename: 'ProductItemContent';
        id: string;
        header: string | null;
        content: string | null;
        createdAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateProductItemSubscription = {
  onUpdateProductItem: {
    __typename: 'ProductItem';
    id: string;
    createdAt: string;
    uic: string | null;
    category: string | null;
    subCategory: string | null;
    type: string | null;
    gender: string | null;
    age: string | null;
    size: string | null;
    look: string | null;
    other1: string | null;
    other2: string | null;
    other3: string | null;
    productName: string | null;
    clientName: string | null;
    serviceName: string | null;
    quantity: string | null;
    unit: string | null;
    season: string | null;
    collectionName: string | null;
    tagDescription: string | null;
    taggedAt: string | null;
    tagged: boolean | null;
    previousTagDescriptions: Array<string | null> | null;
    archived: boolean | null;
    complete: boolean | null;
    completedAt: string | null;
    qcApproved: boolean | null;
    returnedToItems: boolean | null;
    qcApprovedAt: string | null;
    completedServices: Array<string | null> | null;
    productItemServices: Array<string | null> | null;
    imageKeys: Array<string | null> | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrand: {
      __typename: 'SubBrand';
      id: string;
      name: string;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    notes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    content: {
      __typename: 'ModelProductItemContentConnection';
      items: Array<{
        __typename: 'ProductItemContent';
        id: string;
        header: string | null;
        content: string | null;
        createdAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteProductItemSubscription = {
  onDeleteProductItem: {
    __typename: 'ProductItem';
    id: string;
    createdAt: string;
    uic: string | null;
    category: string | null;
    subCategory: string | null;
    type: string | null;
    gender: string | null;
    age: string | null;
    size: string | null;
    look: string | null;
    other1: string | null;
    other2: string | null;
    other3: string | null;
    productName: string | null;
    clientName: string | null;
    serviceName: string | null;
    quantity: string | null;
    unit: string | null;
    season: string | null;
    collectionName: string | null;
    tagDescription: string | null;
    taggedAt: string | null;
    tagged: boolean | null;
    previousTagDescriptions: Array<string | null> | null;
    archived: boolean | null;
    complete: boolean | null;
    completedAt: string | null;
    qcApproved: boolean | null;
    returnedToItems: boolean | null;
    qcApprovedAt: string | null;
    completedServices: Array<string | null> | null;
    productItemServices: Array<string | null> | null;
    imageKeys: Array<string | null> | null;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      subBrands: {
        __typename: 'ModelSubBrandConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    subBrand: {
      __typename: 'SubBrand';
      id: string;
      name: string;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
    } | null;
    company: {
      __typename: 'Company';
      id: string;
      createdAt: string;
      name: string;
      address: string | null;
      state: string | null;
      industry: string | null;
      country: string | null;
      companySize: string | null;
      stripeCustomerId: string | null;
      stripeSubscriptionPlanId: string | null;
      subscriptionType: string | null;
      accountDisabled: boolean | null;
      itemTemplate: {
        __typename: 'ItemTemplate';
        id: string;
        uic: boolean | null;
        brand: boolean | null;
        subBrand: boolean | null;
        category: boolean | null;
        subCategory: boolean | null;
        type: boolean | null;
        gender: boolean | null;
        age: boolean | null;
        size: boolean | null;
        look: boolean | null;
        other1: boolean | null;
        other2: boolean | null;
        other3: boolean | null;
        season: boolean | null;
        collectionName: boolean | null;
        productName: boolean | null;
        quantity: boolean | null;
        clientName: boolean | null;
        serviceName: boolean | null;
        unit: boolean | null;
        itemTemplateType: ItemTemplateType | null;
      } | null;
      roles: {
        __typename: 'ModelRoleConnection';
        nextToken: string | null;
      } | null;
      users: {
        __typename: 'ModelUserConnection';
        nextToken: string | null;
      } | null;
      spaces: {
        __typename: 'ModelSpaceConnection';
        nextToken: string | null;
      } | null;
      brands: {
        __typename: 'ModelBrandConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardConnection';
        nextToken: string | null;
      } | null;
    } | null;
    notes: {
      __typename: 'ModelProductItemNoteConnection';
      items: Array<{
        __typename: 'ProductItemNote';
        id: string;
        note: string | null;
        timestamp: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    content: {
      __typename: 'ModelProductItemContentConnection';
      items: Array<{
        __typename: 'ProductItemContent';
        id: string;
        header: string | null;
        content: string | null;
        createdAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateProductItemNoteSubscription = {
  onCreateProductItemNote: {
    __typename: 'ProductItemNote';
    id: string;
    note: string | null;
    timestamp: string | null;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    };
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateProductItemNoteSubscription = {
  onUpdateProductItemNote: {
    __typename: 'ProductItemNote';
    id: string;
    note: string | null;
    timestamp: string | null;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    };
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteProductItemNoteSubscription = {
  onDeleteProductItemNote: {
    __typename: 'ProductItemNote';
    id: string;
    note: string | null;
    timestamp: string | null;
    author: {
      __typename: 'User';
      id: string;
      createdAt: string;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phoneNumber: string | null;
      avatarKey: string | null;
      avatarUrl: string | null;
      archived: boolean | null;
      verified: boolean | null;
      inviteLater: boolean | null;
      inviteAccepted: boolean | null;
      invitedBy: string | null;
      status: UserStatus | null;
      userRole: {
        __typename: 'Role';
        id: string;
        name: string;
        permission: Permission;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
      userSpaces: Array<string | null> | null;
      productItemNotes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
    };
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateProductItemContentSubscription = {
  onCreateProductItemContent: {
    __typename: 'ProductItemContent';
    id: string;
    header: string | null;
    content: string | null;
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
    createdAt: string;
  } | null;
};

export type OnUpdateProductItemContentSubscription = {
  onUpdateProductItemContent: {
    __typename: 'ProductItemContent';
    id: string;
    header: string | null;
    content: string | null;
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
    createdAt: string;
  } | null;
};

export type OnDeleteProductItemContentSubscription = {
  onDeleteProductItemContent: {
    __typename: 'ProductItemContent';
    id: string;
    header: string | null;
    content: string | null;
    product: {
      __typename: 'ProductItem';
      id: string;
      createdAt: string;
      uic: string | null;
      category: string | null;
      subCategory: string | null;
      type: string | null;
      gender: string | null;
      age: string | null;
      size: string | null;
      look: string | null;
      other1: string | null;
      other2: string | null;
      other3: string | null;
      productName: string | null;
      clientName: string | null;
      serviceName: string | null;
      quantity: string | null;
      unit: string | null;
      season: string | null;
      collectionName: string | null;
      tagDescription: string | null;
      taggedAt: string | null;
      tagged: boolean | null;
      previousTagDescriptions: Array<string | null> | null;
      archived: boolean | null;
      complete: boolean | null;
      completedAt: string | null;
      qcApproved: boolean | null;
      returnedToItems: boolean | null;
      qcApprovedAt: string | null;
      completedServices: Array<string | null> | null;
      productItemServices: Array<string | null> | null;
      imageKeys: Array<string | null> | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
      } | null;
      subBrand: {
        __typename: 'SubBrand';
        id: string;
        name: string;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      notes: {
        __typename: 'ModelProductItemNoteConnection';
        nextToken: string | null;
      } | null;
      content: {
        __typename: 'ModelProductItemContentConnection';
        nextToken: string | null;
      } | null;
    } | null;
    createdAt: string;
  } | null;
};

export type OnCreateBatchCardSpaceSubscription = {
  onCreateBatchCardSpace: {
    __typename: 'BatchCardSpace';
    id: string;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateBatchCardSpaceSubscription = {
  onUpdateBatchCardSpace: {
    __typename: 'BatchCardSpace';
    id: string;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteBatchCardSpaceSubscription = {
  onDeleteBatchCardSpace: {
    __typename: 'BatchCardSpace';
    id: string;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    space: {
      __typename: 'Space';
      id: string;
      createdAt: string;
      name: string;
      colorCode: string;
      status: SpaceStatus;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      batchCards: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
      spaceAllocation: {
        __typename: 'ModelUserAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateBatchCardServiceSubscription = {
  onCreateBatchCardService: {
    __typename: 'BatchCardService';
    id: string;
    isComplete: boolean;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardAllocation: {
      __typename: 'BatchCardAllocation';
      id: string;
      createdAt: string;
      date: string | null;
      obsolete: boolean | null;
      space: {
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null;
      batchCardServices: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
    } | null;
    service: {
      __typename: 'Service';
      id: string;
      name: string;
      icon: string | null;
      batchCards: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateBatchCardServiceSubscription = {
  onUpdateBatchCardService: {
    __typename: 'BatchCardService';
    id: string;
    isComplete: boolean;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardAllocation: {
      __typename: 'BatchCardAllocation';
      id: string;
      createdAt: string;
      date: string | null;
      obsolete: boolean | null;
      space: {
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null;
      batchCardServices: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
    } | null;
    service: {
      __typename: 'Service';
      id: string;
      name: string;
      icon: string | null;
      batchCards: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteBatchCardServiceSubscription = {
  onDeleteBatchCardService: {
    __typename: 'BatchCardService';
    id: string;
    isComplete: boolean;
    batchCard: {
      __typename: 'BatchCard';
      id: string;
      createdAt: string;
      name: string | null;
      description: string | null;
      inBacklog: boolean | null;
      images: Array<string | null> | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
      spaces: {
        __typename: 'ModelBatchCardSpaceConnection';
        nextToken: string | null;
      } | null;
      services: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      productItems: {
        __typename: 'ModelProductItemConnection';
        nextToken: string | null;
      } | null;
      batchCardAllocations: {
        __typename: 'ModelBatchCardAllocationConnection';
        nextToken: string | null;
      } | null;
    } | null;
    batchCardAllocation: {
      __typename: 'BatchCardAllocation';
      id: string;
      createdAt: string;
      date: string | null;
      obsolete: boolean | null;
      space: {
        __typename: 'Space';
        id: string;
        createdAt: string;
        name: string;
        colorCode: string;
        status: SpaceStatus;
      } | null;
      batchCardServices: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      batchCard: {
        __typename: 'BatchCard';
        id: string;
        createdAt: string;
        name: string | null;
        description: string | null;
        inBacklog: boolean | null;
        images: Array<string | null> | null;
      } | null;
    } | null;
    service: {
      __typename: 'Service';
      id: string;
      name: string;
      icon: string | null;
      batchCards: {
        __typename: 'ModelBatchCardServiceConnection';
        nextToken: string | null;
      } | null;
      company: {
        __typename: 'Company';
        id: string;
        createdAt: string;
        name: string;
        address: string | null;
        state: string | null;
        industry: string | null;
        country: string | null;
        companySize: string | null;
        stripeCustomerId: string | null;
        stripeSubscriptionPlanId: string | null;
        subscriptionType: string | null;
        accountDisabled: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateFaqSubscription = {
  onCreateFaq: {
    __typename: 'Faq';
    id: string;
    question: string;
    answer: string;
  } | null;
};

export type OnUpdateFaqSubscription = {
  onUpdateFaq: {
    __typename: 'Faq';
    id: string;
    question: string;
    answer: string;
  } | null;
};

export type OnDeleteFaqSubscription = {
  onDeleteFaq: {
    __typename: 'Faq';
    id: string;
    question: string;
    answer: string;
  } | null;
};
