import styled from 'styled-components';

/** Themes */
import { Colors } from '../../Themes';

/* helper methods */
import { convertPixelsToRem } from '../../Utils/Helpers';

type SelectedColorType = {
  background?: string | null;
  small?: boolean;
  highlighted?: boolean;
  largeImage?: boolean;
};

export const TableHeaderContainer = styled.div`
  display: inline-flex;
  margin-bottom: ${(props: { noMargin?: boolean }) =>
    props.noMargin ? '0rem' : convertPixelsToRem(40)};
`;

export const TableHeader = styled.div`
  font-size: ${convertPixelsToRem(16)};
  margin-right: ${convertPixelsToRem(30)};
  display: inline;
  line-height: ${(props: { lineHeight?: number }) =>
    props.lineHeight ? convertPixelsToRem(props.lineHeight) : 'auto'};
  color: ${Colors.grey};
`;

export const CircleIcon = styled.div`
  height: ${convertPixelsToRem(22)};
  width: ${convertPixelsToRem(22)}
  cursor: pointer;
  border-radius: 50%;
  background-color: ${(props: { selected?: boolean | string }) =>
    props.selected ? Colors.flumeGreen : Colors.lightGray};
`;

export const Checkbox = styled.div`
  width: ${convertPixelsToRem(20)};
  height: ${convertPixelsToRem(20)};
  cursor: pointer;
  background: ${Colors.transparent};
  border: ${convertPixelsToRem(2)} solid ${Colors.blueGrey};
`;

export const SelectedColor = styled.div<SelectedColorType>`
  width: ${props =>
    props.small ? convertPixelsToRem(24) : convertPixelsToRem(38)};
  height: ${props =>
    props.small ? convertPixelsToRem(24) : convertPixelsToRem(38)};
  border-radius: ${convertPixelsToRem(5)};
  cursor: pointer;
  margin: 0 auto;
  background: ${(props: SelectedColorType) =>
    props.background ? props.background : Colors.lightBlueGrey};
`;

export const SelectedColorForBatchCard = styled.div`
  width: auto;
  height: ${convertPixelsToRem(24)};
  line-height: ${convertPixelsToRem(24)};
  border-radius: ${convertPixelsToRem(5)};
  cursor: pointer;
  padding-left: ${convertPixelsToRem(10)};
  padding-right: ${convertPixelsToRem(10)};
  margin: 0 auto;
  color: ${Colors.snow}
  background: ${(props: SelectedColorType) =>
    props.background ? props.background : Colors.lightBlueGrey};
`;

export const SelectedIcon = styled.div<SelectedColorType>`
  display: flex;
  width: ${(props: { small?: boolean }) =>
    props.small ? convertPixelsToRem(24) : convertPixelsToRem(38)};
  height: ${(props: { small?: boolean }) =>
    props.small ? convertPixelsToRem(24) : convertPixelsToRem(38)};
  border-radius: ${convertPixelsToRem(5)};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: ${(props: SelectedColorType) =>
    props.background ? props.background : Colors.flumeGreen};
  & img {
    height: ${(props: { largeImage?: boolean }) =>
      props.largeImage
        ? convertPixelsToRem(32)
        : convertPixelsToRem(16)} !important;
    width: ${(props: { largeImage?: boolean }) =>
      props.largeImage
        ? convertPixelsToRem(24)
        : convertPixelsToRem(16)} !important;
  }
`;

export const SelectedIconServiceModal = styled.div<SelectedColorType>`
  display: flex;
  width: ${(props: { small?: boolean }) =>
    props.small ? convertPixelsToRem(24) : convertPixelsToRem(52)};
  height: ${(props: { small?: boolean }) =>
    props.small ? convertPixelsToRem(24) : convertPixelsToRem(32)};
  border-radius: ${convertPixelsToRem(5)};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: ${(props: { highlighted?: boolean }) =>
    props.highlighted ? `2px solid #5b9d98` : 'none'}};
  padding: ${(props: { highlighted?: boolean }) =>
    props.highlighted ? `5px 10px 0px 10px` : '0'}};

  & img {
    position: relative;
    align-self: center;
    width: 100%;
    zoom: 2;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${convertPixelsToRem(56)};
  width: ${convertPixelsToRem(56)};
  border-radius: ${convertPixelsToRem(5)};
  background-color: ${Colors.flumeGreenLight};
  margin: ${convertPixelsToRem(8)};

  img {
    width: 100%;
    max-height: 70%;
    zoom: 2;
  }

  &.active {
    border: 2px solid ${Colors.flumeDarkGreen};
    background: ${Colors.snow};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const IconLibraryContainer = styled.div`
  & .dropdown-menu {
    min-width: 18rem;
    padding-top: ${convertPixelsToRem(15)};
    padding-bottom: ${convertPixelsToRem(15)};
    border: ${convertPixelsToRem(1)} solid ${Colors.lightGray};
  }
`;

export const TemplateContainer = styled.div`
  display: inline-flex;
  width: 100%;
  & div {
    flex: 2;
  }
`;

export const ListOfRadioButtons = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
  }

  ul li {
    color: #aaaaaa;
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: ${convertPixelsToRem(100)};
    border-bottom: ${convertPixelsToRem(1)} solid ${Colors.grey};
  }

  ul li input[type='radio'] {
    position: absolute;
    visibility: hidden;
  }

  ul li label {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 1.35em;
    padding: ${convertPixelsToRem(25)} ${convertPixelsToRem(25)}
      ${convertPixelsToRem(25)} ${convertPixelsToRem(80)};
    margin: ${convertPixelsToRem(10)} auto;
    height: ${convertPixelsToRem(30)};
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
  }

  ul li label span {
    color: ${Colors.flumeDarkGreen};
    font-size: 1.2em;
  }

  ul li:hover label {
    color: ${Colors.snow};
  }

  ul li .check {
    display: block;
    position: absolute;
    border: ${convertPixelsToRem(5)} solid ${Colors.lightGray};
    border-radius: 100%;
    height: ${convertPixelsToRem(25)};
    width: ${convertPixelsToRem(25)};
    top: ${convertPixelsToRem(30)};
    left: ${convertPixelsToRem(20)};
    z-index: 5;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;
  }

  ul li:hover .check {
    border: ${convertPixelsToRem(5)} solid ${Colors.snow};
  }

  ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: ${convertPixelsToRem(15)};
    width: ${convertPixelsToRem(15)};
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }

  input[type='radio']:checked ~ .check {
    border: ${convertPixelsToRem(5)} solid ${Colors.flumeGreen};
  }

  input[type='radio']:checked ~ .check::before {
    background: ${Colors.flumeGreen};
  }

  input[type='radio']:checked ~ label {
    color: ${Colors.flumeGreen};
  }
`;

export const ServiceIconsContainer = styled.div`
  display: flex;
  overflow: auto;
  & div {
    margin: ${convertPixelsToRem(5)};
  }
  & img {
    height: auto !important;
  }
`;
