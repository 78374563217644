import * as React from 'react';
import { Query } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

/** GraphQL */
import {
  getCompanyRolesUsersAndSpaceByUser,
  getCompanySpacesByUser
} from '../../graphql/custom-queries';

/** Generated types */
import { UpdateSpaceInput } from '../../API';

/** Presentation/UI */
import FullWidthContainer from '../../Components/Layouts/FullWidthContainer';
import ErrorMessage from '../../Components/Styled/ErrorMessage';
import Loader from '../../Components/Loader/';

/** Local components */
import AddMembersToSpaceForm from './AddMembersToSpaceForm';

/** Themes */
import { Colors } from '../../Themes';

/** Custom Types */
import {
  SelectOptionsType,
  ToastNotificationType,
  SelectType
} from '../../CustomTypes';

/** Utils */
import { sortDropDownDataAlphabetically } from '../../Utils/Helpers';

type Props = {
  spaceId?: string;
  spaces?: Array<UpdateSpaceInput>;
  toastManager: ToastNotificationType;
  userId: string;
  closeModal: () => void;
  allocationDate: string;
};

type State = {
  spaceId: string;
};

class AddMembersToSpace extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      spaceId: props.spaceId || ''
    };
  }

  /** Return company spaces as react select options
   * @param spaces - created company spaces
   */
  returnCompanySpaces = (
    spaces: Array<UpdateSpaceInput>
  ): SelectOptionsType => {
    if (spaces && spaces.length) {
      // Prepare to hold an array of spaces
      let selectOptions: any[] = [];
      // Filter only spaces that are active
      spaces.map((space: any) => {
        if (space.status === 'ACTIVE') {
          return selectOptions.push(space);
        }
        return {};
      });
      //  return list of active spaces to the select
      return selectOptions.map((space: UpdateSpaceInput) => {
        return {
          label: space.name || space.id,
          value: space.id,
          object: space
        };
      });
    }

    return [];
  };

  /** Toast notification
   * @param message - message to be displayed
   * @param appearance - type of notification (success/error)
   */
  toastNotification = (message: string, appearance?: string) => {
    this.props.toastManager.add(message, {
      appearance: appearance || 'success',
      autoDismiss: true
    });
  };

  render() {
    const { closeModal, userId, allocationDate } = this.props;
    const { spaceId } = this.state;

    if (spaceId && spaceId !== '') {
      return (
        <Query
          query={getCompanyRolesUsersAndSpaceByUser}
          variables={{ userId, spaceId }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <FullWidthContainer align="center">
                  <Loader color={Colors.flumeGreen} />
                </FullWidthContainer>
              );
            }
            if (error || !data || !data.getUser) {
              return (
                <ErrorMessage errorMessage="There was a problem loading data" />
              );
            }

            const { roles, users, spaces } = data.getUser.company;

            if (spaces && spaces.items.length) {
              return (
                <AddMembersToSpaceForm
                  closeModal={closeModal}
                  notification={this.toastNotification}
                  roles={roles.items}
                  space={spaces.items[0]}
                  selectedSpaceId={spaceId}
                  addedUsers={users.items}
                  userId={userId}
                  allocationDate={allocationDate}
                />
              );
            } else {
              return <ErrorMessage errorMessage="The space does not exist" />;
            }
          }}
        </Query>
      );
    }

    return (
      <Query query={getCompanySpacesByUser} variables={{ id: userId }}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <FullWidthContainer align="center">
                <Loader color={Colors.flumeGreen} />
              </FullWidthContainer>
            );
          }
          if (error || !data || !data.getUser) {
            return (
              <ErrorMessage errorMessage="There was a problem loading data" />
            );
          }

          const { spaces } = data.getUser.company;

          return (
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FormGroup>
                  <Label for="roles">Select a space</Label>
                  <Select
                    // @ts-ignore
                    onChange={(space: SelectType) => {
                      this.setState({ spaceId: space.value });
                    }}
                    options={sortDropDownDataAlphabetically(
                      this.returnCompanySpaces(spaces.items)
                    )}
                    isSearchable={true}
                    className="select-styling"
                  />
                </FormGroup>
              </Col>
            </Row>
          );
        }}
      </Query>
    );
  }
}

export default withToastManager(AddMembersToSpace);
