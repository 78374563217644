import * as React from 'react';

/** Custom Hooks */
import useUserId from '../CustomHooks/UserId';

/** Generated types */
import { Permission, CreateItemTemplateInput } from '../API';

/** Utils */
import { SUBSCRIPTION_PACKAGES, USER_GROUP } from '../Utils/Consts';
import {
  getUserId,
  getUserGroup,
  getUserEmailFromToken
} from '../Utils/Helpers';
import { CompanyType } from '../CustomTypes';

interface IAppContextInterface {
  company: CompanyType;
  itemTemplate: CreateItemTemplateInput;
  userId: string;
  userRole: string;
  userEmail: string;
  spaceId: string;
  spaceIds: Array<string>;
  subscriptionPlan: SUBSCRIPTION_PACKAGES | string;
  userPermission: Permission | string;
  updateCompany: React.Dispatch<React.SetStateAction<CompanyType>>;
  updateItemTemplate: React.Dispatch<
    React.SetStateAction<CreateItemTemplateInput>
  >;
  updateSpaceId: React.Dispatch<React.SetStateAction<string>>;
  updateSpaceIds: React.Dispatch<React.SetStateAction<Array<string>>>;
  updateUserPermission: React.Dispatch<
    React.SetStateAction<Permission | string>
  >;
  updateSubscriptionPlan: React.Dispatch<
    React.SetStateAction<Permission | string>
  >;
  updateUserId: (jwtToken: string) => void;
  updateUserRole: (jwtToken: string) => void;
  updateUserEmail: (jwtToken: string) => void;
}

type Props = {
  children: React.ReactNode;
};

const ctxt = React.createContext<IAppContextInterface>({
  company: { id: '' },
  itemTemplate: {},
  spaceId: '',
  spaceIds: [],
  subscriptionPlan: '',
  userId: '',
  userRole: '',
  userEmail: '',
  userPermission: '',
  updateCompany: () => {},
  updateItemTemplate: () => {},
  updateSpaceId: () => {},
  updateSpaceIds: () => {},
  updateUserPermission: () => {},
  updateUserId: () => {},
  updateUserRole: () => {},
  updateSubscriptionPlan: () => {},
  updateUserEmail: () => {}
});

const UserSpaceContextProvider = ctxt.Provider;
export const UserSpaceContextConsumer = ctxt.Consumer;

const MyProvider: React.FC<Props> = ({ children }) => {
  const [itemTemplate, updateItemTemplate] = React.useState({});
  const [spaceId, updateSpaceId] = React.useState('');
  const [spaceIds, updateSpaceIds] = React.useState(['']);
  const [subscriptionPlan, updateSubscriptionPlan] = React.useState('');
  const [userPermission, updateUserPermission] = React.useState('');
  const [company, updateCompany] = React.useState({ id: '' });
  const {
    userId,
    updateUserId,
    userRole,
    updateUserRole,
    userEmail,
    updateUserEmail
  } = useUserId(
    getUserId() || '',
    getUserGroup() || USER_GROUP,
    getUserEmailFromToken()
  );

  return (
    <UserSpaceContextProvider
      value={{
        company,
        itemTemplate,
        spaceId,
        spaceIds,
        subscriptionPlan,
        userId,
        userRole,
        userEmail,
        updateUserEmail,
        updateCompany,
        updateItemTemplate,
        updateSpaceId,
        updateSpaceIds,
        updateUserId,
        userPermission,
        updateUserRole,
        updateUserPermission,
        updateSubscriptionPlan
      }}
    >
      {children}
    </UserSpaceContextProvider>
  );
};

export default MyProvider;
