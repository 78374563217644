import * as moment from 'moment';

/** Generated types */
import { UpdateProductItemInput, UpdateSpaceInput } from '../../API';

/** Custom types */
import {
  BatchCard,
  BatchCardServiceType,
  BatchCardAllocationType,
  BatchCardAllocationsType,
  ProductItemWithNotes,
  ProductItemNoteType,
  SavedBatchCardAllocation,
  ProductItemWithContent,
  ProductItemContentType,
  BatchCardServices,
  ProductItemsType
} from '../../CustomTypes';

/** Utils */
import {
  removeUndefinedElementsFromArray,
  getTotal,
  returnArrayWithUniqValues,
  returnFlattenedArray,
  returnSortedArrayByDate
} from '../../Utils/Helpers';

/** Return default formatted date */
export const returnDefaultDate = () => {
  return moment().format('DD MMM YYYY');
};

/** Remove batch card allocations with a date and no service ids
 * @param batchCardAllocations - array of dates selected for performing particular services, and in which spaces they to be operated
 */
export const removeBatchCardAllocationsWithoutService = (
  batchCardAllocations: BatchCardAllocationsType
): BatchCardAllocationsType => {
  return batchCardAllocations.filter(
    (batchCardAllocation: BatchCardAllocationType) =>
      batchCardAllocation.batchCardServiceIds.length > 0
  );
};

/** Check if date exists in current allocations
 * @param batchCardAllocations - array of dates selected for performing particular services, and in which spaces they to be operated
 * @param formattedDate - date on which service is to be performed
 */
export const dateExistsInCurrentAllocations = (
  batchCardAllocations: BatchCardAllocationsType,
  formattedDate: string
): number => {
  const allocationWithSelectedDate = batchCardAllocations.filter(
    (batchCardAllocation: BatchCardAllocationType) =>
      batchCardAllocation.date === formattedDate
  );
  return allocationWithSelectedDate && allocationWithSelectedDate.length;
};

/** Check if space exists in current allocations
 * @param batchCardAllocations - array of dates selected for performing particular services, and in which spaces they to be operated
 * @param spaceId - space on which service is to be performed
 */
export const spaceExistsInCurrentAllocations = (
  batchCardAllocations: BatchCardAllocationsType,
  spaceId: string
): number => {
  const allocationWithSelectedDate = batchCardAllocations.filter(
    (batchCardAllocation: BatchCardAllocationType) =>
      batchCardAllocation.spaceId === spaceId
  );
  return allocationWithSelectedDate && allocationWithSelectedDate.length;
};

/** Add batch card service to existing date allocation
 * @param batchCardAllocations - array of dates selected for performing particular services, and in which spaces they to be operated
 * @param batchCardServiceId - id for the instance of the join model between a company service and a batch card
 * @param formattedDate - date on which service is to be performed
 */
export const addServiceIdToExistingDate = (
  batchCardAllocations: BatchCardAllocationsType,
  batchCardServiceId: string,
  formattedDate: string
): BatchCardAllocationsType => {
  return batchCardAllocations.map(
    (batchCardAllocation: BatchCardAllocationType) => {
      if (batchCardAllocation.date === formattedDate) {
        if (
          batchCardAllocation.batchCardServiceIds.includes(batchCardServiceId)
        ) {
          batchCardAllocation.batchCardServiceIds = batchCardAllocation.batchCardServiceIds.filter(
            serviceId => {
              return serviceId !== batchCardServiceId;
            }
          );
          return batchCardAllocation;
        }
        return {
          date: batchCardAllocation.date,
          batchCardServiceIds: [
            ...batchCardAllocation.batchCardServiceIds,
            batchCardServiceId
          ],
          spaceId: batchCardAllocation.spaceId
        };
      }

      return batchCardAllocation;
    }
  );
};

/** Add batch card service to existing date allocation
 * @param batchCardAllocations - array of dates selected for performing particular services, and in which spaces they to be operated
 * @param batchCardServiceId - id for the instance of the join model between a company service and a batch card
 * @param spaceId - space in which service is to be performed
 */
export const addServiceIdToExistingSpace = (
  batchCardAllocations: BatchCardAllocationsType,
  batchCardServiceId: string,
  spaceId: string
): BatchCardAllocationsType => {
  return batchCardAllocations.map(
    (batchCardAllocation: BatchCardAllocationType) => {
      if (batchCardAllocation.spaceId === spaceId) {
        return {
          date: batchCardAllocation.date,
          batchCardServiceIds: [
            ...batchCardAllocation.batchCardServiceIds,
            batchCardServiceId
          ],
          spaceId
        };
      }

      return batchCardAllocation;
    }
  );
};

/** Remove batch card service id from current allocation of service ids
 * @param batchCardAllocations - array of dates selected for performing particular services, and in which spaces they to be operated
 * @param batchCardServiceId - id for the instance of the join model between a company service and a batch card
 */
export const removeBatchCardServiceIdFromAllocation = (
  batchCardAllocations: BatchCardAllocationsType,
  batchCardServiceId: string
): BatchCardAllocationsType => {
  return batchCardAllocations.map(
    (batchCardAllocation: BatchCardAllocationType) => {
      if (
        batchCardAllocation.batchCardServiceIds.find(
          (bcsId: string) => bcsId === batchCardServiceId
        )
      ) {
        const batchCardServiceIds = batchCardAllocation.batchCardServiceIds.filter(
          (bcsId: string) => bcsId !== batchCardServiceId
        );

        return {
          date: batchCardAllocation.date,
          batchCardServiceIds,
          spaceId: batchCardAllocation.spaceId
        };
      }

      return batchCardAllocation;
    }
  );
};

/** Check if service has been selected on a particular date
 * @param batchCardServiceId - id for the instance of the join model between a company service and a batch card
 * @param formattedDate - date on which service is to be performed
 */
export const checkIfServiceHasBeenSelected = (
  batchCardAllocations: BatchCardAllocationsType,
  batchCardServiceId: string,
  formattedDate: string
): boolean | number => {
  // First check if the date exists in the current batch card allocations
  if (dateExistsInCurrentAllocations(batchCardAllocations, formattedDate)) {
    // Check if the date has the batchCardServiceId as a selection
    const allocationWithSelectedDate: BatchCardAllocationsType = batchCardAllocations.filter(
      (batchCardAllocation: BatchCardAllocationType) =>
        batchCardAllocation.date === formattedDate
    );

    const serviceIdOnSelectedDate = allocationWithSelectedDate[0].batchCardServiceIds.filter(
      (bcsId: string) => bcsId === batchCardServiceId
    );
    return serviceIdOnSelectedDate && serviceIdOnSelectedDate.length;
  }

  return false;
};

/** Check if service has been selected on a particular date
 * @param batchCardServiceId - id for the instance of the join model between a company service and a batch card
 * @param spaceId - date on which service is to be performed
 */
export const checkIfServiceHasBeenSelectedInSpace = (
  batchCardAllocations: BatchCardAllocationsType,
  batchCardServiceId: string,
  spaceId: string
): boolean | number => {
  // First check if the space exists in the current batch card allocations
  if (spaceExistsInCurrentAllocations(batchCardAllocations, spaceId)) {
    // Check if the space has the batchCardServiceId as a selection
    const allocationWithSelectedDate: BatchCardAllocationsType = batchCardAllocations.filter(
      (batchCardAllocation: BatchCardAllocationType) =>
        batchCardAllocation.spaceId === spaceId
    );

    const serviceIdOnSelectedDate = allocationWithSelectedDate[0].batchCardServiceIds.filter(
      (bcsId: string) => bcsId === batchCardServiceId
    );
    return serviceIdOnSelectedDate && serviceIdOnSelectedDate.length;
  }

  return false;
};

/** Return batch card allocations
 * @param batchCards - batch cards that have been allocated
 */
export const returnAllocationsFromBatchCards = (
  batchCards: Array<BatchCard>
) => {
  const batchCardsWithAllocations = batchCards.filter(
    (batchCard: BatchCard) =>
      batchCard.batchCardAllocations &&
      batchCard.productItems.items.length &&
      batchCard.batchCardAllocations.items.length
  );
  if (batchCardsWithAllocations && batchCardsWithAllocations.length) {
    return batchCardsWithAllocations.map((batchCard: BatchCard) => {
      return batchCard.batchCardAllocations;
    });
  }
  return [];
};

/** Check if batch card has allocations in a particular week
 * @param batchCard - batch to check
 * @param selectedWeek - selected range of dates
 */
export const checkIfBatchCardHasAllocationsInCurrentWeek = (
  batchCard: BatchCard,
  selectedWeek: Array<string>
): number => {
  const allocationsInCurrentWeek = batchCard.batchCardAllocations.items.map(
    (batchCarcAllocation: SavedBatchCardAllocation) => {
      return selectedWeek.find(
        (date: string) =>
          date === batchCarcAllocation.date && !batchCarcAllocation.obsolete
      );
    }
  );

  return removeUndefinedElementsFromArray(allocationsInCurrentWeek).length;
};

/** Check if batch card has allocations in a particular month
 * @param batchCard - batch to check
 * @param selectedMonth - selected range of dates
 */
export const checkIfBatchCardHasAllocationsInCurrentMonth = (
  batchCard: BatchCard,
  selectedMonth: string
): number => {
  const allocationsInCurrentMonth = batchCard.batchCardAllocations.items.map(
    (batchCarcAllocation: SavedBatchCardAllocation) => {
      if (batchCarcAllocation.date) {
        return (
          !batchCarcAllocation.obsolete &&
          batchCarcAllocation.date.indexOf(selectedMonth) !== -1
        );
      }
      return undefined;
    }
  );

  const arrayOfBooleanValuesForAllocationsInCurrentMonth = removeUndefinedElementsFromArray(
    allocationsInCurrentMonth
  );

  /** The const arrayOfBooleanValuesForAllocationsInCurrentMonth consists of boolean values
   * representing whether or not an allocation from a batch card has been assigned to a date in the
   * particular month being checked.
   */
  const filteredResults = arrayOfBooleanValuesForAllocationsInCurrentMonth.filter(
    (value: boolean) => value
  );

  return filteredResults.length;
};

/** Check if batch card has allocations in a particular year
 * @param batchCard - batch to check
 * @param selectedYear - selected range of dates (whole year)
 */
export const checkIfBatchCardHasAllocationsInSelectedYear = (
  batchCard: BatchCard,
  selectedYear: string
): number => {
  const allocationsInSelectedYear = batchCard.batchCardAllocations.items.map(
    (batchCarcAllocation: SavedBatchCardAllocation) => {
      if (batchCarcAllocation.date) {
        return (
          !batchCarcAllocation.obsolete &&
          batchCarcAllocation.date.indexOf(selectedYear) !== -1
        );
      }
      return undefined;
    }
  );

  const arrayOfBooleanValuesForAllocationsInSelectedYear = removeUndefinedElementsFromArray(
    allocationsInSelectedYear
  );

  /** The const arrayOfBooleanValuesForAllocationsInCurrentMonth consists of boolean values
   * representing whether or not an allocation from a batch card has been assigned to a date in the
   * particular year being checked.
   */
  const filteredResults = arrayOfBooleanValuesForAllocationsInSelectedYear.filter(
    (value: boolean) => value
  );

  return filteredResults.length;
};

/** Load spaces based on allocations from batch card
 * @param batchCardAllocations
 */
export const loadSelectedSpaces = (
  batchCardAllocations: Array<SavedBatchCardAllocation>
): Array<UpdateSpaceInput> => {
  if (batchCardAllocations && batchCardAllocations.length) {
    const batchCardAllocationSpaces = batchCardAllocations.map(
      (allocation: SavedBatchCardAllocation) => allocation.space
    );
    return returnArrayWithUniqValues(batchCardAllocationSpaces);
  }
  return [];
};

/** Update the status of a service on a particular product item
 * @param serviceId - id of the service to be marked as complete or incomplete
 * @param productCompletedServices - array of service id's that are marked as completed on the product item
 */
export const updateCompletedServicesOnProductItem = (
  serviceId: string,
  productCompletedServices: Array<string | null>
) => {
  if (productCompletedServices.indexOf(serviceId) > -1) {
    // Remove the service id to the list of completed service on this product item
    return productCompletedServices.filter(
      // @ts-ignore
      (completedService: string) => completedService !== serviceId
    );
  } else {
    // Add the service id to the list of completed service on this product item
    return [...productCompletedServices, serviceId];
  }
};

/** Check if service has been completed on this product item
 * @param serviceId - service id
 * @param completedServices - array of service id's representing completed services
 */
export const checkIfServiceIsCompleted = (
  serviceId: string,
  completedServices: Array<string | null>
): boolean => {
  return completedServices.indexOf(serviceId) > -1;
};

/** Return previous notes for a product item on a batch card
 * @param productItems - list of product items
 * @param productItemId - id for a product item
 */
export const returnPreviousNotes = (
  productItems: Array<ProductItemWithNotes>,
  productItemId: string
): Array<ProductItemNoteType> => {
  if (productItems && productItems.length) {
    const selectedProductItem = productItems.filter(
      (productItem: ProductItemWithNotes) => productItem.id === productItemId
    );
    if (selectedProductItem && selectedProductItem.length) {
      const { notes } = selectedProductItem[0];
      return notes && notes.items ? notes.items : [];
    }
    return [];
  }
  return [];
};

/** Return previous content for a product item on a batch card
 * @param productItems - list of product items
 * @param productItemId - id for a product item
 */
export const returnPreviousTextContent = (
  productItems: Array<ProductItemWithContent>,
  productItemId: string
): Array<ProductItemContentType> => {
  if (productItems && productItems.length) {
    const selectedProductItem = productItems.filter(
      (productItem: ProductItemWithContent) => productItem.id === productItemId
    );

    if (selectedProductItem && selectedProductItem.length) {
      const { content } = selectedProductItem[0];
      return content && content.items
        ? returnSortedArrayByDate(content.items, 'asc', 'createdAt')
        : [];
    }

    return [];
  }
  return [];
};

/** Return batch cards that don't have batch card allocations
 * @param batchCards - all company batch cards
 */
export const returnBatchCardsWithoutAllocations = (
  batchCards: Array<BatchCard>
): Array<BatchCard> => {
  if (batchCards && batchCards.length) {
    return batchCards.filter(
      (batchCard: BatchCard) => !batchCard.batchCardAllocations.items.length
    );
  }
  return [];
};

/** Return total number of items from batch cards in backlog
 * @param unallocatedBatchCards - batch cards still in backlog (services not allocated to spaces and dates)
 */
export const returnTotalItemsInBacklog = (
  unallocatedBatchCards: Array<BatchCard>
): number => {
  if (unallocatedBatchCards && unallocatedBatchCards.length) {
    const productItemsPerBatchCard = unallocatedBatchCards.map(
      (batchCard: BatchCard) => {
        return batchCard.productItems.items.length;
      }
    );

    return productItemsPerBatchCard.reduce(getTotal, 0);
  }
  return 0;
};

/** Return product items based on array with selected product item ids
 * @param productItems - array of product items
 * @param productItemsIds - array of product item id's
 */
export const returnRelevantProductItems = (
  productItems: Array<UpdateProductItemInput>,
  productItemsIds: Array<string>
): Array<UpdateProductItemInput> => {
  if (productItemsIds && productItemsIds.length) {
    return productItems.filter((productItem: UpdateProductItemInput) => {
      return productItemsIds.indexOf(productItem.id) > -1;
    });
  }
  return [];
};

/** Check if all batch card services are complete for a particular product item.
 * This function checks if the selected services (for a product item) all match up with
 * the batch card services
 * @param selectedServices
 * @param batchCardServices
 */
export const checkIfAllServicesAreComplete = (
  selectedServices: Array<string | null>,
  batchCardServices: Array<BatchCardServiceType>
): boolean => {
  const completedServices = batchCardServices.filter(
    (batchCardService: BatchCardServiceType) => {
      if (batchCardService.service) {
        return selectedServices.indexOf(batchCardService.service.id) > -1;
      }
      return false;
    }
  );

  return batchCardServices.length === completedServices.length;
};

/** Return allocations from batch card with at least one product item
 * that has all services marked as complete
 * @param batchCardAllocations - array of allocations from batch cards
 */
export const returnAllocationsWithAtLeastOneCompleteProduct = (
  batchCardAllocations: Array<SavedBatchCardAllocation>
): Array<SavedBatchCardAllocation> => {
  if (batchCardAllocations && batchCardAllocations.length) {
    const allocationsWithAtLeastOneCompleteProduct = batchCardAllocations.filter(
      batchCardAllocation => {
        const completeItems = batchCardAllocation.batchCard.productItems.items.filter(
          (productItem: UpdateProductItemInput) => productItem.complete
        );

        const qcApprovedItems = batchCardAllocation.batchCard.productItems.items.filter(
          (productItem: UpdateProductItemInput) => productItem.qcApproved
        );

        return completeItems.length !== qcApprovedItems.length;
      }
    );

    return allocationsWithAtLeastOneCompleteProduct;
  }
  return [];
};

/** Return no. of batch cards on particular date
 * @param batchCards - allocated batch cards
 * @param date - particular date of the week
 */
export const returnNumberOfBatchCardsOnDate = (
  batchCards: Array<BatchCard>,
  date: string
): number => {
  const batchCardAllocations = returnAllocationsFromBatchCards(batchCards);

  if (batchCardAllocations && batchCardAllocations.length) {
    const mergedAllocations = returnFlattenedArray(batchCardAllocations);
    const savedBatchCardAllocations = mergedAllocations.filter(
      (batchCardAllocation: SavedBatchCardAllocation) =>
        batchCardAllocation.date === date
    );

    return savedBatchCardAllocations.length;
  }

  return 0;
};

/** Return the total number of product items for a particular date
 * @param batchCards - allocated batch cards
 * @param date - particular date of the week
 */
export const returnNumberOfItemsOnDate = (
  batchCards: Array<BatchCard>,
  date: string
) => {
  const batchCardsWithAllocations = batchCards.filter(
    (batchCard: BatchCard) =>
      batchCard.batchCardAllocations &&
      batchCard.batchCardAllocations.items.length
  );

  const batchCardsWithAllocationsOnThisDate = batchCardsWithAllocations.filter(
    (batchCard: BatchCard) =>
      batchCard.batchCardAllocations.items.find(
        (batchCardAllocation: SavedBatchCardAllocation) =>
          batchCardAllocation.date === date
      )
  );

  const productItemsPerBatchCard = batchCardsWithAllocationsOnThisDate.map(
    (batchCard: BatchCard) => {
      return batchCard.productItems.items.length;
    }
  );

  return productItemsPerBatchCard.reduce(getTotal, 0);
};

/** Return day of the week
 * @param dayNumber - index to retrieve particular day of the week
 */
export const returnDayOfTheWeek = (dayNumber: number): string => {
  return moment.weekdays(dayNumber);
};

/** Return product items based on a given argument of product item ids
 * @param productItemIds - an array of ids of product items
 * @param productItems - an array of product items
 */
export const returnProductItemsBasedOnIds = (
  productItemIds: Array<string>,
  productItems: Array<UpdateProductItemInput>
): Array<UpdateProductItemInput> => {
  if (productItemIds.length && productItems.length) {
    return productItems.filter(productItem => {
      return productItemIds.indexOf(productItem.id) !== -1;
    });
  }
  return [];
};

/** Function returns the product items with the relevant object properties
 * based on the product item keys
 * @param productItems - an array of product items
 * @param productItemKeys - array of keys for product item objects
 */
export const returnProductItemsBasedOnProductItemKeys = (
  productItems: Array<UpdateProductItemInput>,
  productItemKeys: Array<string>
): Array<Array<string>> => {
  return productItems.map(productItem => {
    return productItemKeys.map(productItemKey => {
      if (productItemKey === 'company') {
        return productItem[productItemKey]
          ? productItem[productItemKey].name
          : '';
      }
      if (productItemKey === 'brand' || productItemKey === 'subBrand') {
        return productItem[productItemKey]
          ? productItem[productItemKey].name
          : '';
      }
      return productItem[productItemKey] || '';
    });
  });
};

/** Return array of completed product items
 * @param productItems - array of product items on a batch card
 */
export const returnArrayOfCompletedProductItems = (
  productItems: Array<UpdateProductItemInput>
) => {
  if (productItems && productItems.length) {
    return productItems.filter(productItem => productItem.complete);
  }
  return [];
};

/** Return array of valid/active batch card services
 * @param batchCardServices - array of services
 */
export const arrayOfActiveBatchCardServices = (
  batchCardServices: Array<BatchCardServices>
) => {
  return batchCardServices.filter(
    batchCardService => !batchCardService.isComplete && batchCardService.service
  );
};

export const arrayofCompletedBatchCardServices = (
  batchCardServices: Array<BatchCardServices>,
  productItems: ProductItemsType
): Array<BatchCardServices> => {
  let completedBatchCardServices: Array<BatchCardServices> = [];
  /* eslint-disable */
  batchCardServices.map(batchCardService => {
    let count: number = 0;
    productItems.items.forEach(product => {
      if (
        product.completedServices &&
        product.completedServices.includes(batchCardService.service.id)
      ) {
        count += 1;
        if (count === productItems.items.length) {
          return completedBatchCardServices.push(batchCardService);
        }
      }
      return completedBatchCardServices;
    });
  });
  return completedBatchCardServices;
};

/** Return no. of batch cards on particular date
 * @param batchCards - allocated batch cards
 * @param date - particular date of the week
 */
export const returnNumberOfBatchCardsOnDateInQC = (
  batchCards: Array<BatchCard>,
  date: string
): number => {
  const batchCardAllocations = returnAllocationsFromBatchCards(batchCards);

  if (batchCardAllocations && batchCardAllocations.length) {
    const mergedAllocations = returnFlattenedArray(batchCardAllocations);

    const savedBatchCardAllocations = mergedAllocations.filter(
      (batchCardAllocation: SavedBatchCardAllocation) =>
        batchCardAllocation.date === date && !batchCardAllocation.obsolete
    );

    const allocationsWithAtLeastOneCompleteProduct = returnAllocationsWithAtLeastOneCompleteProduct(
      savedBatchCardAllocations
    );

    return allocationsWithAtLeastOneCompleteProduct.length;
  }

  return 0;
};

/** Return the total number of product items for a particular date
 * @param batchCards - allocated batch cards
 * @param date - particular date of the week
 */
export const returnNumberOfItemsOnDateInQC = (
  batchCards: Array<BatchCard>,
  date: string
) => {
  const batchCardsWithAllocations = batchCards.filter(
    (batchCard: BatchCard) =>
      batchCard.batchCardAllocations &&
      batchCard.batchCardAllocations.items.length
  );

  const batchCardsWithAllocationsOnThisDate = batchCardsWithAllocations.filter(
    (batchCard: BatchCard) => {
      const QCBatchCardAllocation = returnAllocationsWithAtLeastOneCompleteProduct(
        batchCard.batchCardAllocations.items
      );

      return QCBatchCardAllocation.find(
        (batchCardAllocation: SavedBatchCardAllocation) =>
          batchCardAllocation.date === date && !batchCardAllocation.obsolete
      );
    }
  );

  const productItemsPerBatchCard = batchCardsWithAllocationsOnThisDate.map(
    (batchCard: BatchCard) => {
      return batchCard.productItems.items.filter(card => card.complete === true)
        .length;
    }
  );

  return productItemsPerBatchCard.reduce(getTotal, 0);
};

export const numberOfItemsWithCompletedServices = (
  items: Array<UpdateProductItemInput>,
  activeBatchCardServices: Array<BatchCardServices>
) => {
  return items.filter(item =>
    activeBatchCardServices.every(
      serviceItem =>
        (item.completedServices &&
          item.completedServices.includes(serviceItem.service.id)) ||
        false
    )
  ).length;
};
