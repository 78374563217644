import styled from 'styled-components';

/* app theme */
import colors from '../../Themes/Colors';

interface IStyledDivider {
  width?: string;
  height?: string;
  borderColor?: string;
  borderSize?: number;
}

const StyledDivider = styled.hr<IStyledDivider>`
  width: ${(props: IStyledDivider) => props.width || '100%'};
  border: ${(props: IStyledDivider) =>
    props.borderColor && props.borderSize
      ? `${props.borderSize}px solid ${props.borderColor}`
      : `0.7px solid ${colors.divider}`};
`;

export default StyledDivider;
