import styled from 'styled-components';
import { Col } from 'reactstrap';

/** App Theme */
import { Colors } from '../../Themes';

/** Helpers */
import { convertPixelsToRem } from '../../Utils/Helpers';

type Props = {
  height?: string;
  marginleft?: string;
  padding?: string;
  overflowy?: string;
  overflowX?: string;
};

export const StyledTabContainer = styled(Col)`
  height: ${(props: Props) => (props.height ? props.height : '100vh')};
  margin-left: ${(props: Props) => (props.marginleft ? props.marginleft : 0)};
  padding: ${(props: Props) => (props.padding ? props.padding : 0)};
  background-color: ${Colors.snow};
  border-radius: ${convertPixelsToRem(5)} ${convertPixelsToRem(5)} 0px 0px;
  overflow-y: ${(props: Props) =>
    props.overflowy ? props.overflowy : 'hidden'};
  overflow-x: ${(props: Props) =>
    props.overflowX ? props.overflowX : 'hidden'};
`;
