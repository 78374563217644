import * as React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Form } from 'reactstrap';
import DialogModal from '../../Components/DialogModal';

/** GraphQL */
import { deleteProductItem } from '../../graphql/mutations';
import { getCompanyTaggedProductItemsByUser } from '../../graphql/custom-queries';

/** Generated types */
import {
  DeleteProductItemMutation,
  DeleteProductItemMutationVariables
} from '../../API';

/** Custom types */
import { ModalWithMessageType } from '../../CustomTypes';

type Props = ModalWithMessageType & {
  productItemId: string;
  userId: string;
};

const DeleteProductItem: React.FC<Props> = props => {
  const { closeModal, userId, notification, productItemId } = props;
  return (
    <Mutation<DeleteProductItemMutation, DeleteProductItemMutationVariables>
      mutation={gql(deleteProductItem)}
    >
      {(deleteProductMutation, deleteMutation) => (
        <Form
          onSubmit={e => {
            e.preventDefault();
            deleteProductMutation({
              variables: {
                input: {
                  id: productItemId
                }
              },
              refetchQueries: [
                {
                  query: getCompanyTaggedProductItemsByUser,
                  variables: {
                    id: userId
                  }
                }
              ]
            })
              .then(() => {
                closeModal();
                notification('Product item deleted successfully', 'success');
              })
              .catch(() => {
                notification(
                  'Sorry, there was a problem deleting the product item',
                  'error'
                );
              });
          }}
        >
          <DialogModal
            loading={deleteMutation.loading}
            title="Are you sure you want to delete this product item?"
            toggleModal={closeModal}
          />
        </Form>
      )}
    </Mutation>
  );
};

export default DeleteProductItem;
