import * as React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

/** Custom Types */
import { SubBrandType } from '../../CustomTypes';

type State = {
  dropdownOpen: boolean;
};

type Props = {
  brand: SubBrandType;
  openDialogModal(brand: SubBrandType): void;
  openModal(brand: SubBrandType): void;
};

class RoleActions extends React.Component<Props, State> {
  state: State = {
    dropdownOpen: false
  };

  toggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  render() {
    const { dropdownOpen } = this.state;
    const { openModal, openDialogModal, brand } = this.props;
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={this.toggle}
        style={{ position: 'absolute' }}
      >
        <DropdownToggle
          tag="span"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          <MdMoreVert size="1.3em" style={{ cursor: 'pointer' }} />
        </DropdownToggle>
        <DropdownMenu>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openModal(brand)}
          >
            Edit Sub Brand
          </div>
          <div
            className="avatar-dropdown-menu-item"
            onClick={() => openDialogModal(brand)}
          >
            Delete Sub Brand
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default RoleActions;
